import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ApplicationModule, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { NgxMaskModule } from "ngx-mask";
import { NgxSpinnerModule } from "ngx-spinner";
import { NavPanelPersonaComponent } from "../views/personas/nav-panel-persona/nav-panel-persona.component";
import { ProgressModule } from "../views/progress/progress.module";
import { ScrollToTopComponent } from "../views/scroll-to-top/scroll-to-top.component";
import { DndDirective } from "../directives/dnd.directive";
import { SnackBarService } from "../services/snackBar/snack-bar-service.service";
import { MaterialAngularModule } from "./materialAngular.module";
import { BuscarContratoComponent } from "../views/busquedas/buscar-contrato/buscar-contrato.component";
import { NgxEditorModule } from "ngx-editor";
import { LoadingBtnDirective } from "../directives/loading-btn/loading-btn.directive";
import { AppRoleDirective } from "../directives/appRole/app-role.directive";
import { AppTestComponent } from "@views/app-test/app-test.component";
import { AlertsComponent } from "@views/alerts/alerts.component";

@NgModule({
  imports: [
    CommonModule,
    ApplicationModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialAngularModule,
    FormsModule,
    RouterModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    ProgressModule,
    NgxMaskModule,
    NgxEditorModule
  ],
  declarations: [
    NavPanelPersonaComponent,
    DndDirective,//DragAndDrop
    LoadingBtnDirective,
    AppRoleDirective,
    BuscarContratoComponent,
    AppTestComponent,
    AlertsComponent,

  ],
  providers: [
    SnackBarService,
    HttpClient
  ],
  exports: [
    CommonModule,
    ApplicationModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialAngularModule,
    FormsModule,
    RouterModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    NavPanelPersonaComponent,
    ProgressModule,
    AlertsComponent,
    NgxMaskModule,
    BuscarContratoComponent,
    NgxEditorModule,
    //Directivas
    LoadingBtnDirective,
    DndDirective,//DragAndDrop
    AppRoleDirective,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule { }

