import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { PropiedadCaracteristicaPropiedadDto } from '@models/propiedades/propiedadCaracteristicaPropiedadDto.model';
import { PropiedadesService } from '@services/propiedades/propiedades.service';

@Component({
  selector: 'app-delete-dialog-caracteristica-propiedad',
  templateUrl: './delete-dialog-caracteristica-propiedad.component.html',
  styleUrls: ['./delete-dialog-caracteristica-propiedad.component.css']
})
export class DeleteDialogCaracteristicaPropiedadComponent implements OnInit {
  propiedadCaracteristicaPropiedadDto: PropiedadCaracteristicaPropiedadDto = new PropiedadCaracteristicaPropiedadDto;

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogCaracteristicaPropiedadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PropiedadCaracteristicaPropiedadDto) {
  }

  ngOnInit(): void {
  }

  eliminar(): void {
    this.dialogRef.close({data:this.data, event:AccionesDialog.Eliminar});
  }

}

