<form [formGroup]="propietarioForm" *appRole="['Propietarios.Ver']">

    <ngx-spinner [zIndex]="100" name="spUpdatePropietario" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <div class="row">
        <div class="form-group col-md-3">
            <mat-form-field class="example-full-width">
                <span matPrefix>%&nbsp;</span>
                <mat-label>Comision</mat-label>
                <input matInput name="Comision" cdkFocusInitial appPercentageDirective min="1" max="100" [(ngModel)]="comision" type="text" formControlName="Comision" />
                <mat-error *ngIf="f.Comision.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-md-3">
            <mat-form-field>
                <ngx-spinner name="spTipoLiquidacion" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                <mat-label>Tipo Liquidación</mat-label>
                <mat-select placeholder="Seleccione opcion" formControlName="TipoLiquidacion" (selectionChange)="selectTipoLiquidacion($event)">
                    <mat-option [value]="tipoLiquidacion.id" *ngFor="let tipoLiquidacion of lstTipoLiquidacionModel;let i=index;trackBy:trackByItems">
                        {{tipoLiquidacion.descripcion}}</mat-option>
                </mat-select>

                <mat-error *ngIf="f.TipoLiquidacion.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-md-3">
            <mat-form-field class="example-full-width">
                <mat-label>CUIT/CUIL</mat-label>
                <input matInput name="CuitCuil" type="text" [mask]="'00-00000000-0'" placeholder="00-00000000-0" formControlName="CuitCuil" />
                <mat-error *ngIf="f.CuitCuil.errors?.pattern">Formato invalido.</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Atención!</strong> Los valores de comisión y de tipo de liquidación no son considerados para la liquidación. Solo se utilizan para la creación de un contrato.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span >&times;</span>
    </button>
    </div>
    <div class="row">
        <div>
            <div *appRole="['Propietarios.Modificar']">
                <button mat-raised-button color="primary" style="margin-right:3px;" value="Guardar" [disabled]="!propietarioForm.valid " (click)="onSubmit()"> <mat-icon>save</mat-icon>Guardar</button>
            </div>
        </div>

        <div>
            <div *appRole="['Propietarios.Modificar']">
                <button mat-stroked-button color="warn" (click)="eliminar()"><mat-icon>delete</mat-icon> Eliminar</button>
            </div>

        </div>
    </div>

</form>
