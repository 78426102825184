import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContadorComprobantePorEstadoResponse } from '@models/caja/contadorComprobantePorEstadoResponse.model';
import { ContadorComprobantePorTipoResponse } from '@models/caja/contadorComprobantePorTipoResponse.model';
import { ContadorComprobantePorTipoYEstadoResponse } from '@models/caja/contadorComprobantePorTipoYEstadoResponse.model';
import { ContadorMovimientoPorTipoResonse } from '@models/caja/contadorMovimientoPorTipoResonse.model';
import { ContadorMovimientoResponse } from '@models/caja/contadorMovimientoResponse.model';
import { ContadorPeriodoContratoResponse } from '@models/caja/contadorPeriodoContratoResponse.model';

@Injectable({
  providedIn: 'root'
})
export class CajaService {

  constructor(
    private http: HttpClient
  ) { }

  getContadorPeriodosContrato(fechaDesde: string, fechaHasta: string): Observable<ContadorPeriodoContratoResponse> {
    return this.http.get<ContadorPeriodoContratoResponse>('/api/Caja/GetContadorPeriodosContrato?fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta)
  }

  getContadorMovimientos(fechaDesde: string, fechaHasta: string): Observable<ContadorMovimientoResponse> {
    return this.http.get<ContadorMovimientoResponse>('/api/Caja/GetContadorMovimientos?fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta)
  }

  getContadoMovimientosPorTipo(fechaDesde: string, fechaHasta: string): Observable<ContadorMovimientoPorTipoResonse> {
    return this.http.get<ContadorMovimientoPorTipoResonse>('/api/Caja/GetContadoMovimientosPorTipo?fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta)
  }

  getContadorComprobantePorEstado(fechaDesde: string, fechaHasta: string): Observable<ContadorComprobantePorEstadoResponse> {
    return this.http.get<ContadorComprobantePorEstadoResponse>('/api/Caja/GetContadorComprobantePorEstado?fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta)
  }

  getContadorComprobantePorTipo(fechaDesde: string, fechaHasta: string): Observable<ContadorComprobantePorTipoResponse> {
    return this.http.get<ContadorComprobantePorTipoResponse>('/api/Caja/GetContadorComprobantePorTipo?fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta)
  }

  getContadorComprobantePorTipoYEstado(fechaDesde: string, fechaHasta: string): Observable<ContadorComprobantePorTipoYEstadoResponse> {
    return this.http.get<ContadorComprobantePorTipoYEstadoResponse>('/api/Caja/GetContadorComprobantePorTipoYEstado?fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta)
  }
}
