import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-panel-movimientos',
  templateUrl: './nav-panel-movimientos.component.html',
  styleUrls: ['./nav-panel-movimientos.component.css']
})
export class NavPanelMovimientosComponent implements OnInit {
  idMovimiento!:number
  constructor() { }

  ngOnInit(): void {
  }

}
