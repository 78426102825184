import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntidadDto } from '@models/base/entidadDto.model';
import { TiposEntidad } from '@models/base/identificadores.model';

@Component({
  selector: 'app-documentos-propiedades',
  templateUrl: './documentos-propiedades.component.html',
  styleUrls: ['./documentos-propiedades.component.css']
})
export class DocumentosPropiedadesComponent implements OnInit {

  entidad:EntidadDto = new EntidadDto
  idPropiedad!: number;
  idTipoEntidad!: number;
  idEntidad!: number;
  documentoChange!: number;
  constructor(
    private aRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idPropiedad = Number(routeParams.get('id'));
    this.idEntidad = this.idPropiedad
    this.idTipoEntidad = TiposEntidad.Propiedades
  }

  onDocumentoChange(documentoChange: number) {
    this.documentoChange = documentoChange;
  }

}
