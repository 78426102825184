import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { CreateUserRequest } from '@models/usuarios/createUserRequest.model';
import { UpdateUserRequest } from '@models/usuarios/updateUserRequest.model';
import { RolesService } from '@services/seguridad/roles/roles.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { UsuariosService } from '@services/usuarios/usuarios.service';
import { RolDto } from '@models/seguridad/roles/rolDto.model';
import { LoginService } from '@services/login/login.service';
import { JwtPayload } from '@models/login/token-model.model';
import { Roles } from '@models/base/identificadores.model';
import { UsuariosController } from 'src/app/controllers/usuarios.controller';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'app-form-usuario-create',
  templateUrl: './form-usuario-create.component.html',
  styleUrls: ['./form-usuario-create.component.css']
})
export class FormUsuarioCreateComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion = new MatAccordion;
  @Input() personaId!: number;
  public personaControl = new FormControl();
  titulo: string = '';
  subMenuActive: boolean = false;
  stringObject: any;
  stringJson: any;
  usuarioForm: FormGroup;
  public personaDetailDto: PersonaDetailDto = new PersonaDetailDto;
  public UsuarioDto: UsuarioDto = new UsuarioDto;
  isEdition: boolean = false;
  submitted = false;
  parametro: string = '';
  idRol: number = 0;
  idPersona: number = 0;
  valor: any
  errorMessage: string = "";
  hide = true;
  public filteredPersonas!: Observable<PersonaDetailDto[]>;
  createUserRequest: CreateUserRequest = new CreateUserRequest;
  updateUserRequest: UpdateUserRequest = new UpdateUserRequest;
  idUsuario!: number;
  formBuilder: FormBuilder = new FormBuilder;
  userSession!: UsuarioDto;
  roles!: RolDto[];
  get f() { return this.usuarioForm.controls; }
  lengthOfCode = 12;
  isDisabled: boolean = true;

  constructor(
    private usuariosService: UsuariosService,
    private rolesService: RolesService,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private loginService: LoginService
  ) {

    // Dentro del constructor del componente
    this.usuarioForm = this.formBuilder.group({
      IdPersona: ['', Validators.compose([Validators.required])],
      IdRol: ['', Validators.compose([Validators.required])],
      Rol: ['', Validators.compose([Validators.required])],
      NombreUsuario: ['', Validators.compose([Validators.required])],
      ClaveNueva: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{10,}$/)
      ])],
      ClaveRepetida: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(8)
      ])],
      NombreCompleto: [''],
      DNI: ['']
    }, {
      validator: this.passwordMatchValidator
    });

    this.usuarioForm.controls["NombreCompleto"].disable();
    this.usuarioForm.controls["DNI"].disable();

    // Suscribirse a cambios en ClaveNueva para revalidar
    this.usuarioForm.get('ClaveNueva')?.valueChanges.subscribe(() => {
      this.usuarioForm.get('ClaveRepetida')?.updateValueAndValidity();
    });
  }

  passwordMatchValidator() {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls['ClaveNueva'];
      const confirmPasswordControl = formGroup.controls['ClaveRepetida'];

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    };
  }

  ngOnInit(): void {
    this.userSession = this.loginService.getCurrentUser();
    this.getAllRoles();
  }

  selectRol(event: any) {
    this.idRol = parseInt(event)
    this.usuarioForm.controls["IdRol"].setValue(this.idRol)
  }


  makeRandom(lengthOfCode: number):string {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const characters = lowercase + uppercase + numbers;

    let password = '';
    let hasLowercase = false;
    let hasUppercase = false;
    let hasNumber = false;

    for (let i = 0; i < lengthOfCode; i++) {
      const char = characters.charAt(Math.floor(Math.random() * characters.length));
      password += char;

      if (lowercase.includes(char)) {
        hasLowercase = true;
      } else if (uppercase.includes(char)) {
        hasUppercase = true;
      } else if (numbers.includes(char)) {
        hasNumber = true;
      }
    }

    // Ensure the password includes at least one lowercase, one uppercase, and one number
    if (!hasLowercase || !hasUppercase || !hasNumber) {
      // Recursively call makeRandom until all criteria are met
      return this.makeRandom(lengthOfCode);
    }

    return password;
  }

  goToFormPersona() {
    if (this.idPersona != 0)
      this.router.navigate(["/personas", this.idPersona])
  }

  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    if (this.usuarioForm.valid == true) {
      this.setCreateUserRequest();
      this.usuariosService.addUser(this.createUserRequest)
        .subscribe(
          data => {
            this.spinner.hide();
            this.idUsuario = data.id
            this.snackBar.showSuccess("Se agrego el  registro correctamente.", "");
            this.router.navigate(["/usuarios"])

          },
          error => {
            this.spinner.hide();
            this.snackBar.showError(error, "Error");
          })
    }
  }
  cambiarPassword() {
    this.submitted = true;
  }

  getAllRoles() {
    this.spinner.show("spRol");
    this.rolesService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spRol");
          this.roles = data.filter(x => x.id != Roles.SuperAdministradores)
        },
        error => {
          this.spinner.hide("spRol");
          this.snackBar.showError(error, "Error");
        });
  }

  generarClaveAleatoria() {
    let claveAleatorioa = this.makeRandom(this.lengthOfCode)
    this.usuarioForm.controls["ClaveNueva"].setValue(claveAleatorioa)
    this.usuarioForm.controls["ClaveRepetida"].setValue(claveAleatorioa)
  }

  selectPersona(personaDetailDto: PersonaDetailDto) {
    this.idPersona = personaDetailDto.id;
    this.usuarioForm.controls["IdPersona"].setValue(personaDetailDto.id)
    this.usuarioForm.controls['NombreUsuario'].setValue(personaDetailDto.correo)
    this.usuarioForm.controls['NombreCompleto'].setValue(personaDetailDto.nombreCompleto)
    this.usuarioForm.controls['DNI'].setValue(personaDetailDto.nroDocumento)
    this.isDisabled = false;
  }

  setCreateUserRequest() {
    this.createUserRequest = new CreateUserRequest();
    this.createUserRequest.name = this.usuarioForm.controls['NombreUsuario'].value;
    this.createUserRequest.password = this.usuarioForm.controls['ClaveNueva'].value;
    this.createUserRequest.id_Rol = this.idRol//this.usuarioForm.controls["IdRol"].value;
    this.createUserRequest.id_Persona = this.usuarioForm.controls["IdPersona"].value;
  }



  onCancel() {
    this.usuarioForm.reset();
    this.router.navigate(["/usuarios"])
  }

  trackByItems(index: number, item: any): any { return item; }

}
