import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { DialogDeleteComponent } from '../../genericos/dialog-delete/dialog-delete.component';
import { Router } from '@angular/router';
import { RenterDeliveredNecessaryGuaranteesResponse } from '@models/inquilino/renterDeliveredNecessaryGuaranteesResponse.model';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'app-form-update-inquilino',
  templateUrl: './form-update-inquilino.component.html',
  styleUrls: ['./form-update-inquilino.component.css']
})
export class FormUpdateInquilinoComponent implements OnInit {

  personaDetailDto!: PersonaDetailDto
  idInquilino!: number
  actualizaGrantias!: number;
  renterDeliveredNecessaryGuaranteesResponse!: RenterDeliveredNecessaryGuaranteesResponse;
  appRecarga: boolean = false
  constructor(
    private personasService: PersonasService,
    public dialog: MatDialog,
    private inquilinosService: InquilinosService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private router: Router
  ) {

  }
  ngOnInit(): void {
    this.personaDetailDto = this.personasService.getPersona()
    if (this.personaDetailDto)
      this.getInquilinoByIdPersona(this.personaDetailDto.id)
  }
  nuevoInquilino($event: any) {
    if (this.personaDetailDto)
      this.personaDetailDto.esInquilino = $event
  }

  asignaGarantia(event: number) {
    this.actualizaGrantias = event
  }

  openDialogDelete(action: any, id: number) {
    let obj = { action: action, id: id };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        if (!isFalsy(result.data))
          this.getInquilinoByIdPersonaDelete(result.data)
      }
    });
  }

  getInquilinoByIdPersonaDelete(id: number) {
    this.spinner.show("spCargarElemento");
    this.inquilinosService.getInquilinoByIdPersona(id)
      .subscribe(
        data => {
          this.spinner.hide("spCargarElemento");
          this.delete(data.id);
        },
        error => {
          this.spinner.hide("spCargarElemento");
          this.snackBar.showError(error, "Error");
        })
  }

  getInquilinoByIdPersona(id: number) {
    this.spinner.show("spCargarElemento");
    this.inquilinosService.getInquilinoByIdPersona(id)
      .subscribe(
        data => {
          this.spinner.hide("spCargarElemento");
          this.idInquilino = data.id
          this.inquilinosService.inquilinoDto.next(data)
          this.inquilinosService.sendIdPersona(id)
          this.inquilinosService.sendInquilinoDto(data)
        },
        error => {
          this.spinner.hide("spCargarElemento");
          this.snackBar.showError(error, "Error");
        })
  }

  delete(id: number) {
    this.spinner.show("spCargarElemento");
    this.inquilinosService.delete(id)
      .subscribe(
        data => {
          this.snackBar.showSuccess("Inquilino eliminado correctamente.", "Exito");
          this.spinner.hide("spCargarElemento");
          this.personaDetailDto.esInquilino = false
          this.personasService.setPersona(this.personaDetailDto)
          this.router.navigate(["/inquilino"])
        },
        error => {
          this.spinner.hide("spCargarElemento");
          this.snackBar.showError(error, "Error");
        })
  }




}
