import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateGrupoTipoGarantiaInquilinoRequest } from '@models/grupos-tipo-garantia-inquilino/createGrupoTipoGarantiaInquilinoRequest.model';
import { GrupoTipoGarantiaInquilinoDto } from '@models/grupos-tipo-garantia-inquilino/grupoTipoGarantiaInquilinoDto.model';
import { UpdateGrupoTipoGarantiaInquilinoRequest } from '@models/grupos-tipo-garantia-inquilino/updateGrupoTipoGarantiaInquilinoRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class GruposTipoGarantiaInquilinoService {

  constructor(
    private http: HttpClient
  ) { }

  create(createGrupoTipoGarantiaInquilinoRequest: CreateGrupoTipoGarantiaInquilinoRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/GrupoTipoGarantiaInquilino/Create", createGrupoTipoGarantiaInquilinoRequest);
  }

  update(updateGrupoTipoGarantiaInquilinoRequest: UpdateGrupoTipoGarantiaInquilinoRequest): Observable<any> {
    return this.http.put<any>("/api/GrupoTipoGarantiaInquilino/Update", updateGrupoTipoGarantiaInquilinoRequest);
  }

  getAll(): Observable<GrupoTipoGarantiaInquilinoDto[]> {
    return this.http.get<GrupoTipoGarantiaInquilinoDto[]>("/api/GrupoTipoGarantiaInquilino/GetAll");
  }

  getById(id: number): Observable<GrupoTipoGarantiaInquilinoDto> {
    return this.http.get<GrupoTipoGarantiaInquilinoDto>("/api/GrupoTipoGarantiaInquilino/GetById?id=" + id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/GrupoTipoGarantiaInquilino/Delete?id=" + id);
    else
      return of(null)
  }

  getAllGrouped(): Observable<GrupoTipoGarantiaInquilinoDto[]> {
    return this.http.get<GrupoTipoGarantiaInquilinoDto[]>("/api/GrupoTipoGarantiaInquilino/GetAll");
  }

  getByIdGrouped(id: number): Observable<GrupoTipoGarantiaInquilinoDto> {
    return this.http.get<GrupoTipoGarantiaInquilinoDto>("/api/GrupoTipoGarantiaInquilino/GetById?id=" + id);
  }

  createGruposTipoGarantiaInquilinos(createGrupoTipoGarantiaInquilinoRequest: CreateGrupoTipoGarantiaInquilinoRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/GrupoTipoGarantiaInquilino/CreateGruposTipoGarantiaInquilinos", createGrupoTipoGarantiaInquilinoRequest);
  }

  updateGruposTipoGarantiaInquilinos(updateGrupoTipoGarantiaInquilinoRequest: UpdateGrupoTipoGarantiaInquilinoRequest): Observable<any> {
    return this.http.put<any>("/api/GrupoTipoGarantiaInquilino/UpdateGruposTipoGarantiaInquilinos", updateGrupoTipoGarantiaInquilinoRequest);
  }

  deleteGruposTipoGarantiaInquilinos(idInquilino: number, idGrupoTipoGarantia: number | null): Observable<any> {
    if (!isFalsy(idInquilino) && !isFalsy(idGrupoTipoGarantia))
      return this.http.delete<any>("/api/GrupoTipoGarantiaInquilino/DeleteGruposTipoGarantiaInquilinos?idInquilino=" + idInquilino + "&idGrupoTipoGarantia=" + idGrupoTipoGarantia);
    else if (!isFalsy(idInquilino))
      return this.http.delete<any>("/api/GrupoTipoGarantiaInquilino/DeleteGruposTipoGarantiaInquilinos?idInquilino=" + idInquilino);
    else
      return of(null)
  }

}
