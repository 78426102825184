<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div class="row">
    <h1 mat-dialog-title>
        <strong>Liquidaciones</strong></h1>
</div>
<mat-divider></mat-divider>
<br>
<div mat-dialog-content>
    <mat-card>
        <ngx-spinner [zIndex]=100 bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <table mat-table [dataSource]="dataSource" [hidden]="!data" table matSort matSortStart="asc" class="mat-elevation-z8">

            <!-- Id Column -->
            <ng-container matColumnDef="contrato">
                <th mat-header-cell *matHeaderCellDef> Contrato</th>
                <td mat-cell *matCellDef="let liq">{{liq.contrato.descripcion | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="propietario">
                <th mat-header-cell *matHeaderCellDef> Propietario</th>
                <td mat-cell *matCellDef="let liq">{{liq.propietario.descripcion | titlecase}} </td>
            </ng-container>

            <!-- Fallo envio Column -->
            <ng-container matColumnDef="envioCorrectoEmail">
                <th mat-header-cell *matHeaderCellDef> Estado envio</th>
                <td mat-cell *matCellDef="let liq">
                    <span><mat-icon matTooltip="Envio no realizado." *ngIf="liq.envioCorrectoEmail==true">cancel_schedule_send</mat-icon></span>
                    <span><mat-icon matTooltip="Envio realizado." *ngIf="liq.envioCorrectoEmail==false">send</mat-icon></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="archivo">
                <th mat-header-cell *matHeaderCellDef> Archivo</th>
                <td mat-cell *matCellDef="let liq">
                    <button mat-icon-button color="primary" matTooltip="Descargar archivo." class="button-container" (click)="descargarRecibo(liq.archivoLiquidacion)">
        <mat-icon>file_download
        </mat-icon>
      </button></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="no-data-table">
            <span class="with-icon" [hidden]=data><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
        </div>
        <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="10" [pageSizeOptions]="[5, 10, 20,50,100,200]">
        </mat-paginator>
    </mat-card>
</div>
