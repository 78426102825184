import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ServicioDto } from '@models/servicios/servicio/servicioDto.model';
import { CreateTipoContratoQuienPagaServicioRequest } from '@models/tipocontrato-quienpaga-servicio/createTipoContratoQuienPagaServicioRequest.model';
import { TipoContratoQuienPagaServicioDto } from '@models/tipocontrato-quienpaga-servicio/tipoContratoQuienPagaServicioDto.model';
import { UpdateTipoContratoQuienPagaServicioRequest } from '@models/tipocontrato-quienpaga-servicio/updateTipoContratoQuienPagaServicioRequest.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposContratoService } from '@services/tipos-contrato/tipos-contrato.service';
import { QuienPagaService } from '@services/quien-paga/quien-paga.service';

@Component({
  selector: 'app-dialog-tipocontrato-quienpaga-servicio',
  templateUrl: './dialog-tipocontrato-quienpaga-servicio.component.html',
  styleUrls: ['./dialog-tipocontrato-quienpaga-servicio.component.css']
})
export class DialogTipocontratoQuienpagaServicioComponent implements OnInit {
  lstTiposContrato: BaseDto[] = []
  lstQuienPaga: BaseDto[] = []
  lstServicios: ServicioDto[] = [];
  action: string;
  local_data: any;

  form: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  tipoContratoQuienPagaServicioDto!: TipoContratoQuienPagaServicioDto

  get f() { return this.form.controls; }

  constructor(
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private tipoContratoService: TiposContratoService,
    private quienPagaService: QuienPagaService,
    private serviciosService: ServiciosService,
    public dialogRef: MatDialogRef<DialogTipocontratoQuienpagaServicioComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.action = this.data.action;

    this.form = this.formBuilder.group({
      TiposContrato: ["", Validators.compose([Validators.required,])],
      QuienPaga: ["", Validators.compose([Validators.required,])],
      Servicios: ["", Validators.compose([Validators.required,])],
    })

  }

  ngOnInit(): void {
    this.getAllTiposContrato()
    this.getAll()
    this.getAllServicios()

    if (this.action == AccionesDialog.Modificar) {
      this.tipoContratoQuienPagaServicioDto = this.data
      this.form.controls["TiposContrato"].setValue(this.data.idTipoContrato)
      this.form.controls["QuienPaga"].setValue(this.data.idQuienPaga)
      this.form.controls["Servicios"].setValue(this.data.idServicio)
    }
  }

  doAction() {
    const tipoContrato = this.form.controls["TiposContrato"].value
    const quienPaga = this.form.controls["QuienPaga"].value
    const servicio = this.form.controls["Servicios"].value
    const tipoContratoQuienPagaServicioDto = this.tipoContratoQuienPagaServicioDto

    switch (this.action) {
      case AccionesDialog.Agregar:
        const createTipoContratoQuienPagaServicioRequest = new CreateTipoContratoQuienPagaServicioRequest
        createTipoContratoQuienPagaServicioRequest.idQuienPaga = quienPaga
        createTipoContratoQuienPagaServicioRequest.idServicio = servicio
        createTipoContratoQuienPagaServicioRequest.idTipoContrato = tipoContrato

        this.dialogRef.close({ event: this.action, data: createTipoContratoQuienPagaServicioRequest });
        break;
      case AccionesDialog.Modificar:
        const updateTipoContratoQuienPagaServicioRequest = new UpdateTipoContratoQuienPagaServicioRequest
        updateTipoContratoQuienPagaServicioRequest.idQuienPaga = quienPaga
        updateTipoContratoQuienPagaServicioRequest.idServicio = servicio
        updateTipoContratoQuienPagaServicioRequest.idTipoContrato = tipoContrato
        updateTipoContratoQuienPagaServicioRequest.id = tipoContratoQuienPagaServicioDto.id

        this.dialogRef.close({ event: this.action, data: updateTipoContratoQuienPagaServicioRequest });
        break;
      case AccionesDialog.Eliminar:
        this.dialogRef.close({ event: this.action, data: this.local_data });
        break;
      default:
        this.dialogRef.close({ event: this.action, data: this.local_data });
        break;
    }

  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  getAllTiposContrato() {
    this.spinner.show("spBusquedaTipoContrato")
    this.tipoContratoService.getAll().subscribe(
      data => {
        this.spinner.hide("spBusquedaTipoContrato")
        this.lstTiposContrato = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
      },
      error => {
        this.spinner.hide("spBusquedaTipoContrato")
        this.snackBar.showError(error, "Error");
      })
  }

  getAll() {
    this.spinner.show("spBusquedaQuienPaga");
    this.quienPagaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaQuienPaga");
          this.lstQuienPaga = data
        },
        error => {
          this.spinner.hide("spBusquedaQuienPaga");
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getAllServicios() {
    this.spinner.show("spBusquedaServicios");
    this.serviciosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaServicios");
          this.lstServicios = data
        },
        error => {
          this.spinner.hide("spBusquedaServicios");
          this.snackBar.showError(error, "Error");
        }
      )
  }

  trackByItems(index: number, item: any): any { return item; }
}
