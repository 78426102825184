import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TiposComprobantes, TiposMovimiento } from '@models/base/identificadores.model';
import { ComprobanteDetailDto } from '@models/comprobantes/comprobanteDetailDto.model';
import { DetalleComprobanteDetailDto } from '@models/comprobantes/detalleComprobanteDetailDto.model';
import { isFalsy } from 'utility-types';

export class Conceptos {
  public id!: number
  public importeDebe!: number
  public importeHaber!: number
  public periodo!: string
  public fechaPago!: string | null
  public tipoMovimiento!: string
  public concepto!: string
  public descripcion!: string
  public numeroCuenta!: string
}

@Component({
  selector: 'app-dialog-comprobante',
  templateUrl: './dialog-comprobante.component.html',
  styleUrls: ['./dialog-comprobante.component.css']
})
export class DialogComprobanteComponent implements OnInit {
  data: boolean = false
  comprobanteDetailDto!: ComprobanteDetailDto;
  dataSource = new MatTableDataSource<Conceptos>();
  displayedColumns: string[] = ['concepto', 'periodo', 'fechaPago', 'tipoMovimiento', 'numeroCuenta', 'descripcion', 'importeDebe', 'importeHaber',];
  displayedColumnsPagos: string[] = ['fechaPago', 'fechaAcreditacion', 'importe', 'formaPago', 'descripcion'];

  totalImporteDebe: number = 0;
  totalImporteHaber: number = 0;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public datos: ComprobanteDetailDto,
  ) { }

  ngOnInit(): void {
    this.comprobanteDetailDto = this.datos
    this.getConceptos(this.comprobanteDetailDto)
  }


  getConceptos(comprobanteDetailDto: ComprobanteDetailDto) {
    let lstDetalleComprobanteDetailDto: DetalleComprobanteDetailDto[] = comprobanteDetailDto.detalleComprobante
    let conceptos: Conceptos[] = []
    lstDetalleComprobanteDetailDto.forEach(element => {

      //DSP
      if (element.detalleServicioPropiedad != null) {
        let concepto = new Conceptos
        concepto.id = element.detalleServicioPropiedad.id
        concepto.concepto = "Detalle de servicio"
        concepto.importeDebe = element.detalleServicioPropiedad.importeATenerEnCuenta
        concepto.numeroCuenta = element.detalleServicioPropiedad.numeroCuenta
        concepto.tipoMovimiento = element.detalleServicioPropiedad.tipoServicio
        concepto.periodo = element.detalleServicioPropiedad.periodoMes + "/" + element.detalleServicioPropiedad.periodoAnio + "-" + element.detalleServicioPropiedad.periodo
        concepto.descripcion = element.detalleServicioPropiedad.descripcion
        this.totalImporteDebe += concepto.importeDebe
        conceptos.push(concepto)
      }
      //Periodos Contrato
      if (element.periodoContrato != null) {
        let concepto = new Conceptos
        concepto.id = element.periodoContrato.id
        concepto.concepto = "Periodo alquiler"
        if (comprobanteDetailDto.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Liquidación) {
          concepto.importeHaber = element.periodoContrato.importeAlquiler
          this.totalImporteHaber += concepto.importeHaber
        }
        else {
          concepto.importeDebe = element.periodoContrato.importeAlquiler
          this.totalImporteDebe += concepto.importeDebe
        }

        //concepto.importe = element.importeTotal
        concepto.fechaPago = element.periodoContrato.fechaPago
        concepto.periodo = element.periodoContrato.periodoMes + "/" + element.periodoContrato.periodoAnio + "-" + element.periodoContrato.periodo
        concepto.descripcion = element.periodoContrato.descripcion
        concepto.numeroCuenta = ""
        conceptos.push(concepto)
      }

      //Movimientos
      if (element.movimiento != null) {

        if (comprobanteDetailDto.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Recibo) {
          if (
            !isFalsy(element.movimiento) &&
            element.movimiento.tipoMovimiento.id != TiposMovimiento.EgresoCaja &&
            element.movimiento.tipoMovimiento.id != TiposMovimiento.IngresoCaja &&
            element.movimiento.tipoMovimiento.id != TiposMovimiento.DeudaBonificacion) {
            let concepto = this.nuevoConcepto(element)
            conceptos.push(concepto)
          }
        }
        if (comprobanteDetailDto.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Recibo) {
          if (
            !isFalsy(element.movimiento) &&
            element.movimiento.tipoMovimiento.id == TiposMovimiento.EgresoCaja ||
            element.movimiento.tipoMovimiento.id == TiposMovimiento.IngresoCaja ||
            element.movimiento.tipoMovimiento.id == TiposMovimiento.DeudaBonificacion ||
            element.movimiento.inquilino == null && element.movimiento.propietario == null
            ) {
            let concepto = this.nuevoConcepto(element)
            conceptos.push(concepto)
          }
        }
        if (comprobanteDetailDto.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Liquidación) {
          let concepto = this.nuevoConcepto(element)
          conceptos.push(concepto)
        }

      }
    });
    this.dataSource.data = conceptos
    this.data = this.dataSource.data.length >= 1 ? true : false
  }

  nuevoConcepto(element: DetalleComprobanteDetailDto): Conceptos {
    let concepto = new Conceptos
    concepto.id = element.movimiento.id
    concepto.concepto = "Movimiento"
    concepto.numeroCuenta = ""
    if (element.movimiento.tipoMovimiento.resta) {
      concepto.importeDebe = element.importeTotal
      this.totalImporteDebe += concepto.importeDebe
    }
    else {
      concepto.importeHaber = element.importeTotal
      this.totalImporteHaber += concepto.importeHaber
    }

    concepto.fechaPago = element.movimiento.fechaPago
    concepto.tipoMovimiento = element.movimiento.tipoMovimiento.descripcion
    concepto.periodo = element.movimiento.periodoMes + "/" + element.movimiento.periodoAnio + "-" + element.movimiento.periodo
    concepto.descripcion = element.movimiento.descripcion

    return concepto
  }

  getTotalPagos() {
    return this.comprobanteDetailDto.cabeceraComprobante.pagos.map(t => t.importe).reduce((acc, value) => acc + value, 0);
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }
}

