import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AccionesDialog, TiposMovimiento } from '@models/base/identificadores.model';
import { MovimientoDetailDto } from '@models/movimiento/movimientoDetailDto.model';
import { DialogNuevoMovimientoComponent } from './dialog-nuevo-movimiento/dialog-nuevo-movimiento.component';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-editar-movimientos',
  templateUrl: './dialog-editar-movimientos.component.html',
  styleUrls: ['./dialog-editar-movimientos.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({
        height: '*',
        minHeight: ''
      })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class DialogEditarMovimientosComponent implements OnInit {
  numSelected: number = 0;
  displayedColumns: string[] = ['select', 'periodo', 'tipoMovimiento', 'importe', 'descripcion', 'accion'];
  dataSource = new MatTableDataSource<MovimientoDetailDto>();
  selection = new SelectionModel<MovimientoDetailDto>(true, []);
  movimientos!: MovimientoDetailDto[]
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogEditarMovimientosComponent>,
  ) { }


  ngOnInit(): void {
    this.movimientos = this.data
    this.movimientos.forEach(element => {
      if (element.seleccionPago == true)
        this.selection.select(element)
    });
    this.dataSource.data = this.movimientos.filter(this.esMovimientoTipoSaldo)

  }

  esMovimientoTipoSaldo(elemento: MovimientoDetailDto) {
    return elemento.tipoMovimiento.id != TiposMovimiento.SaldoAFavor && elemento.tipoMovimiento.id != TiposMovimiento.SaldoAdeuda;
  }

  onSubmit() {
    this.movimientos.forEach(element => {
      element.seleccionPago = false
    })
    const movimientos = this.selection.selected
    movimientos.forEach(element => {
      this.movimientos.forEach(element2 => {
        if (element.id == element2.id)
          element2.seleccionPago = true
      })
    });
    this.dialogRef.close({ data: this.movimientos, event: AccionesDialog.Aceptar });
  }

  onCancel() {
    this.dialogRef.close({ data: this.movimientos, event: AccionesDialog.Cancelar });
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    this.numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numSelected === numRows;
  }


  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MovimientoDetailDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }


  openDialogNuevoMovimiento() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "40%"
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      event: AccionesDialog.Agregar,
      id: 0,
      importe: 0,
      periodo: 0,
      periodoMes: new Date().getMonth() + 1,
      periodoAnio: new Date().getFullYear(),
      tiposMovimiento: [],
    }

    const dialogRef = this.dialog.open(DialogNuevoMovimientoComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Cancelar) {
      }
      if (result.event == AccionesDialog.Agregar) {
        const tipoMovimiento = result.data.tipoMovimiento
        const importe = result.data.importe
        const movimiento = new MovimientoDetailDto
        movimiento.id = result.data.id
        movimiento.tipoMovimiento = tipoMovimiento
        movimiento.importe = importe
        movimiento.descripcion = result.data.descripcion
        movimiento.periodo = result.data.periodo
        movimiento.periodoMes = result.data.periodoMes
        movimiento.periodoAnio = result.data.periodoAnio
        this.agregarMovimiento(movimiento)
      }

    });
  }


  agregarMovimiento(movimiento: MovimientoDetailDto) {
    this.movimientos.push(movimiento)
    this.selection.select(movimiento)
    this.movimientos.forEach(element => {
      if (element.seleccionPago == true)
        this.selection.select(element)
    });
    this.dataSource.data = this.movimientos.filter(this.esMovimientoTipoSaldo)
  }

  eliminarMovimiento(element: any) {
    this.dataSource.data = this.dataSource.data.filter((value, key: any) => {
      return value != element
    });
    this.movimientos.forEach((movimiento, index) => {
      if (movimiento == element)
        this.movimientos.splice(index, 1);
    });
    this.data = this.dataSource.data.length >= 1 ? true : false
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }




}

