<div class="list-group" *appRole="['Personas.Ver']">
    <button type="button" [routerLinkActive]="['active']" routerLink='/personas/editar' class="list-group-item list-group-item-action">
<div class="with-icon">
  <mat-icon class="padding-icon">person_pin</mat-icon><span > Persona</span>
</div>
</button>

    <button type="button" *appRole="['Propietarios.Ver']" [routerLinkActive]="['active']" routerLink='/propietario'  class="list-group-item list-group-item-action">

<div class="with-icon">
  <mat-icon class="padding-icon">apartment</mat-icon><span > Propietario</span>
</div>
</button>

    <button type="button" [routerLinkActive]="['active']" routerLink='/inquilino' *appRole="['Propietarios.Ver']" class="list-group-item list-group-item-action">
      <div class="with-icon">
        <mat-icon class="padding-icon">vpn_key</mat-icon><span > Inquilino</span>
      </div>
</button>

    <button type="button" *appRole="['Garantias.Ver']" [routerLinkActive]="['active']" routerLink='/garantia'  class="list-group-item list-group-item-action">
<div class="with-icon">
  <mat-icon class="padding-icon">person</mat-icon><span > Garantia</span>
</div>
</button>

 <button type="button" *appRole="['Documentos.Ver']" [routerLinkActive]="['active']" routerLink='/documentospersona' class="list-group-item list-group-item-action">
      <div class="with-icon">
        <mat-icon class="padding-icon">folder</mat-icon> <span >Documentos</span>
      </div>
  </button>

  <button type="button" *appRole="['CuentasContable.Ver']" [routerLinkActive]="['active']" routerLink='/cuentascontablepersonas'  class="list-group-item list-group-item-action">
    <div class="with-icon">
      <mat-icon class="padding-icon">card_travel</mat-icon> <span >Cuentas Contables</span>
    </div>
</button>
</div>
