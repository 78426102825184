import { PersonaDetailDto } from "@models/personas/personaDetailDto.model";
import { BaseDto } from "../base/baseDto.model";
import { TipoGarantiaModel } from "../tipo-garantia/tipo-garantia.model";

export class GarantiaDto extends BaseDto{
    public persona : PersonaDetailDto = new PersonaDetailDto
    public tipoGarantia : TipoGarantiaModel = new TipoGarantiaModel
    public razonSocial : string="";
    public nroFolio : string="";
    public cuitCuil : string="";
    public sueldo : number=0;
    public activo : boolean=false;
    public id_PersonaInquilino: number=0;
    public idTipoFolio: number=0;
    public tomo: string = ""
    public numero: string = ""
    public ubicacion: string = ""
    public localidad: string = ""
    public indivisa: number = 100
    public horizontal : boolean=false;
}
