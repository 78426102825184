import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BuscarLocalidadComponent } from "../views/busquedas/buscar-localidad/buscar-localidad.component";
import { LocalidadesService } from "../services/localidades/localidades.service";
import { SharedModule } from "./shared.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        BuscarLocalidadComponent,],
    exports: [
        BuscarLocalidadComponent,
    ],
    providers: [
        LocalidadesService,
    ]

})

export class LocalidadModule { }
