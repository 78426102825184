import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-nueva-persona',
  templateUrl: './btn-nueva-persona.component.html',
  styleUrls: ['./btn-nueva-persona.component.css']
})
export class BtnNuevaPersonaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
