<div *appRole="['Documentos.Crear']">

<ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
</ngx-spinner>
<form [formGroup]="formDocuementos">
    <div class="row">
        <div class=" col-lg-4">
            <mat-form-field>
                <ngx-spinner [zIndex]=100 name="spBusqueda" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                <mat-label>Asunto</mat-label>
                <mat-select placeholder="Seleccione opcion" formControlName="Asuntos" (selectionChange)="selectAsunto($event)">
                    <mat-option [value]="asunto.id" *ngFor="let asunto of lstAsuntos ;let i=index;trackBy:trackByItems">
                        {{asunto.descripcion}}</mat-option>
                </mat-select>
                <mat-error *ngIf="f.Asuntos.errors?.required">Este campo es <strong>requerido</strong>.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6">
            <mat-form-field class="full-width">
                <mat-label>Nombre</mat-label>
                <input matInput name="NombreDocumento" type="text" formControlName="NombreDocumento" />
                <mat-error *ngIf="f.NombreDocumento.errors?.required">Este campo es <strong>requerido</strong>.
                </mat-error>
            </mat-form-field>

        </div>
    </div>
    <div class="row">
        <div class="col-lg-8">
            <mat-form-field class="max-width">
                <mat-label>Etiquetas</mat-label>
                <mat-chip-list #chipList aria-label="Etiquetas seleccionadas">
                    <mat-chip *ngFor="let etiqueta of lstEtiquetas;let i=index;trackBy:trackByItems" [selectable]="selectable" [removable]="removable" (removed)="remove(etiqueta)">
                        {{etiqueta}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Nueva etiqueta..." formControlName="Etiquetas" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <mat-form-field class="example-full-width col-md-12">
            <mat-label>Descripcion</mat-label>
            <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
            <mat-error *ngIf="f.Descripcion.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
            <mat-hint align="start"><strong>Amplie información</strong> </mat-hint>
            <mat-hint align="end">{{message.value.length}} / 200</mat-hint>
            <mat-error *ngIf="f.Descripcion.errors?.required">Este campo es <strong>requerido</strong>.
            </mat-error>
        </mat-form-field>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <mat-card>
                <mat-card-subtitle>Nuevo documento</mat-card-subtitle>
                <app-file-upload (eventoArchivo)="seleccionArchivo($event)"></app-file-upload>
                <input type="text" hidden formControlName="FileUpload">
                <!-- <div class="col-lg-4">
                    <div class="image-container">
                        <mat-icon color="primary" *ngIf="imageBaseData == ''">image</mat-icon>
                        <img src="{{imageBaseData}}" *ngIf="imageBaseData != ''" height="200" width="200">
                    </div> -->
            </mat-card>
        </div>
    </div>
    <br>
    <div class="col-auto my-1">
        <button mat-raised-button color="primary" mat-dialog-close style="margin-right:3px;" (click)="agregarDocumento()" name="Guardar" [disabled]="!formDocuementos.valid">Agregar</button>
        <button mat-button color="warn" style="margin-right:3px;" (click)="onReset()" mat-dialog-close name="Cancelar">Cancelar</button>
    </div>
</form>
</div>
