<div *appRole="['Servicios.Ver']">

<ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
<app-btn-nuevo-servicio-propiedad *appRole="['Servicios.Crear']" [idPropiedad]="idPropiedad"></app-btn-nuevo-servicio-propiedad>
<br>
<br>
<table mat-table [dataSource]="dataSource" [hidden]=!data matSort matSortStart="asc" class="mat-elevation-z8">


    <!-- Servicio  Propiedad-->
    <!-- <ng-container matColumnDef="servicioPropiedad">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> # </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let servicioPropiedad">{{servicioPropiedad.id }}</mat-cell>
    </ng-container> -->

    <!-- Servicio -->
    <ng-container matColumnDef="servicio">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Servicio </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let servicioPropiedad"> <a [routerLink]="['/servicios/servicio/editar/',servicioPropiedad.servicio.id]">{{servicioPropiedad.servicio.descripcion  }}</a>
        </mat-cell>
    </ng-container>

    <!-- Nro Cuenta-->
    <ng-container matColumnDef="nroCuenta">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Cuenta </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let servicioPropiedad"> {{servicioPropiedad.nroCuenta}}</mat-cell>
    </ng-container>

    <!-- Nro Gestion Personal-->
    <ng-container matColumnDef="nroGestionPersonal">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Nro. gestion personal</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let servicioPropiedad"> {{servicioPropiedad.nroGestionPersonal}}</mat-cell>
    </ng-container>

    <!-- Referencia Pago-->
    <ng-container matColumnDef="referenciaPago">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Referencia Pago </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let servicioPropiedad"> {{servicioPropiedad.referenciaPago}}</mat-cell>
    </ng-container>

    <!-- Acciones -->
    <ng-container matColumnDef="accion">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Acción</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let servicioPropiedad" class="action-link">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
            <mat-menu #menu="matMenu">
                <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                <mat-icon>visibility</mat-icon>
                <span>Ver</span>
              </button> -->
                <button *appRole="['Servicios.Modificar']" mat-menu-item [routerLink]="['/serviciospropiedad/servicio/'+servicioPropiedad.idPropiedad+'/editar/'+servicioPropiedad.id]">
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
                <button *appRole="['Servicios.Eliminar']" mat-menu-item (click)="openDialogDelete('Eliminar',servicioPropiedad.id)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>
        </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


</table>
<mat-paginator showFirstLastButtons [pageSize]="10" [hidden]=!data [pageSizeOptions]="[5, 10, 20,50,100,200]">
</mat-paginator>
<div class="no-data-table">
    <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
</div>
</div>
