import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateIndiceAjusteContratoRequest } from '@models/indice-ajuste-contrato/createIndiceAjusteContratoRequest.model';
import { IndiceAjusteContratoDto } from '@models/indice-ajuste-contrato/indiceAjusteContratoDto.model';
import { IndiceAjusteContratoFilter } from '@models/indice-ajuste-contrato/indiceAjusteContratoFilter.model';
import { UpdateIndiceAjusteContratoRequest } from '@models/indice-ajuste-contrato/updateIndiceAjusteContratoRequest.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class IndiceAjusteContratoService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient
  ) { }

  create(createIndiceAjusteContratoRequest:CreateIndiceAjusteContratoRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/IndiceAjusteContrato/Create",createIndiceAjusteContratoRequest);
  }

  update(updateIndiceAjusteContratoRequest:UpdateIndiceAjusteContratoRequest):Observable<any>
  {
    return  this.http.put<any>("/api/IndiceAjusteContrato/Update",updateIndiceAjusteContratoRequest);
  }

  getAll():Observable<IndiceAjusteContratoDto[]>
  {
    return  this.http.get<IndiceAjusteContratoDto[]>("/api/IndiceAjusteContrato/GetAll");
  }

  getByFechaYTipo(fechaConsulta:string,idTipoAjusteContrato:number):Observable<IndiceAjusteContratoDto>
  {
    return this.http.get<IndiceAjusteContratoDto>("/api/IndiceAjusteContrato/GetByFechaYTipo?fechaConsulta=" + fechaConsulta + "&idTipoAjusteContrato=" + idTipoAjusteContrato)
  }

  getUltimoValorPorTipo(idTipoAjusteContrato:number):Observable<IndiceAjusteContratoDto>
  {
    return this.http.get<IndiceAjusteContratoDto>("/api/IndiceAjusteContrato/GetUltimoValorPorTipo?idTipoAjusteContrato=" + idTipoAjusteContrato)
  }


  getAllByFilter(indiceAjusteContratoFilter: IndiceAjusteContratoFilter): Observable<IndiceAjusteContratoDto[]> {
    return this.http.post<IndiceAjusteContratoDto[]>("/api/IndiceAjusteContrato/GetAllByFilter", indiceAjusteContratoFilter)
  }

  getById(id:number):Observable<IndiceAjusteContratoDto>
  {
    return  this.http.get<IndiceAjusteContratoDto>("/api/IndiceAjusteContrato/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/IndiceAjusteContrato/Delete?id=" + id);
    else
      return of(null)
  }
}
