import { BaseDto } from "../base/baseDto.model"

export class TokenModel {
    id:string =""
    auth_Token:string=""
    expires_In:number=0
    refresh_Token:string=""
    expires_Time: Date =  new Date
}

export class JwtHead{
  alg:string =""
  typ:string=""
}

export class JwtPayload {
  nameid:string =""
  unique_name:string=""
  NombreApellido: string = ""
  Id_Persona:string=""
  role:string=""
  nbf:number=0
  exp:number=0
  iat:number=0
  empresa:BaseDto = {id:1, descripcion: "Massoud Inmobiliaria"}
}

