import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreatePeriodoContratoRequest } from '@models/periodos-contrato/createPeriodoContratoRequest.model';
import { CreatePeriodoContratoResponse } from '@models/periodos-contrato/createPeriodoContratoResponse.model';
import { isFalsy } from 'utility-types';
import { PeriodoContratoFilter } from '@models/periodos-contrato/periodoContratoFilter.model';
import { PeriodoContratoDetailDto } from '@models/periodos-contrato/periodoContratoDetailDto.model';


@Injectable({
  providedIn: 'root'
})
export class PeriodosContratoService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Procesa los ICL. Este proceso debe correrse el mismo dia que la fecha de resumen.
   * @param fecha
   * @returns
   */
  correrProcesoAjustePeriodosContrato(fecha: string): Observable<any> {
    const fechaEjecucion = { fechaEjecucion: fecha }
    return this.http.post<any>("/api/PeriodoContrato/CorrerProcesoAjustePeriodosContrato", fechaEjecucion)
  }

  getPeriodosContratoByFilter(periodoContratoFilter: PeriodoContratoFilter): Observable<PeriodoContratoDetailDto[]> {
    return this.http.post<PeriodoContratoDetailDto[]>("/api/PeriodoContrato/GetPeriodosContratoByFilter", periodoContratoFilter)
  }

  getPeriodosContratoListadoByFilter(periodoContratoFilter: PeriodoContratoFilter): Observable<PeriodoContratoDetailDto[]> {
    return this.http.post<PeriodoContratoDetailDto[]>("/api/PeriodoContrato/GetPeriodosContratoListadoByFilter", periodoContratoFilter)
  }


  /**
   *
   * @param createPeriodoContratoRequest: CreatePeriodoContratoRequest
   * @returns
   */
  createCollection(createPeriodoContratoRequest: CreatePeriodoContratoRequest): Observable<CreatePeriodoContratoResponse> {
    return this.http.post<CreatePeriodoContratoResponse>("/api/PeriodoContrato/CreateCollection", createPeriodoContratoRequest)
  }

  /**
   *
   * @param id: number
   * @returns
   */
  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/PeriodoContrato/Delete?id=" + id);
    else
      return of(null)
  }
}
