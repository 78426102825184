import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposMoviemientoService } from '@services/tipos-movimiento/tipos-movimiento.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogDeleteTipoMovimientoComponent } from './dialog-delete-tipo-movimiento/dialog-delete-tipo-movimiento.component';

@Component({
  selector: 'app-eliminar-tipo-movimiento',
  templateUrl: './eliminar-tipo-movimiento.component.html',
  styleUrls: ['./eliminar-tipo-movimiento.component.css']
})
export class EliminarTipoMovimientoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogDeleteTipoMovimientoComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private tiposMoviemientoService: TiposMoviemientoService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
  }

  doAction() {
    this.delete(this.data.id)
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  delete(id: number) {
    this.spinner.show("spEliminarTipoMovimiento")
    this.tiposMoviemientoService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide("spEliminarTipoMovimiento")
          this.snackBar.showSuccess("Registro eliminado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Eliminar });
        },
        error => {
          this.spinner.hide("spEliminarTipoMovimiento")
          this.snackBar.showError(error, "Error");
        }
      )
  }
  trackByItems(index: number, item: any): any { return item; }

}
