import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-update-tipo-movimiento',
  templateUrl: './dialog-update-tipo-movimiento.component.html',
  styleUrls: ['./dialog-update-tipo-movimiento.component.css']
})
export class DialogUpdateTipoMovimientoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
