import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { TipoMovimientoDto } from '@models/tipos-movimiento/tipoMovimientoDto.model';
import { UpdateTipoMovimientoRequest } from '@models/tipos-movimiento/updateTipoMovimientoRequest.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposMoviemientoService } from '@services/tipos-movimiento/tipos-movimiento.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogCreateTipoMovimientoComponent } from '../crear-tipo-movimiento/dialog-create-tipo-movimiento/dialog-create-tipo-movimiento.component';

@Component({
  selector: 'app-ver-tipo-movimiento',
  templateUrl: './ver-tipo-movimiento.component.html',
  styleUrls: ['./ver-tipo-movimiento.component.css']
})
export class VerTipoMovimientoComponent implements OnInit {

  formBuilder = new FormBuilder;
  form: any;
  tipoMovimientoDto!: TipoMovimientoDto
  get f() { return this.form.controls; }
  constructor(
    public dialogRef: MatDialogRef<DialogCreateTipoMovimientoComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private tiposMoviemientoService: TiposMoviemientoService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
      Resta: ["", Validators.compose([Validators.required,])],
    })
  }

  ngOnInit(): void {
    this.getById(this.data.id)
    this.form.disable()
  }

  doAction() {

    let updateTipoMovimientoRequest: UpdateTipoMovimientoRequest = new UpdateTipoMovimientoRequest
    updateTipoMovimientoRequest.id = this.data.id
    updateTipoMovimientoRequest.descripcion = this.form.controls["Descripcion"].value
    updateTipoMovimientoRequest.resta = this.form.controls["Resta"].value
    this.update(updateTipoMovimientoRequest)
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  update(updateTipoMovimientoRequest: UpdateTipoMovimientoRequest) {
    this.spinner.show("spVerTipoMovimiento")
    this.tiposMoviemientoService.update(updateTipoMovimientoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spVerTipoMovimiento")
          this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Ver });
        },
        error => {
          this.spinner.hide("spVerTipoMovimiento")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getById(id: number) {
    this.spinner.show("spVerTipoMovimiento")
    this.tiposMoviemientoService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spVerTipoMovimiento")
          this.tipoMovimientoDto = data
          this.seteaForm(data)
        },
        error => {
          this.spinner.hide("spVerTipoMovimiento")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  seteaForm(tipoMovimientoDto: TipoMovimientoDto) {
    this.form.controls["Descripcion"].setValue(tipoMovimientoDto.descripcion)
    this.form.controls["Resta"].setValue(tipoMovimientoDto.resta)
    this.form.disable()
  }


}
