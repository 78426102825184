import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CantidadTipoGarantiaDto } from '@models/cantidades-tipo-garantia/cantidadTipoGarantiaDto.model';
import { GrupoTipoGarantiaDto } from '@models/grupos-tipo-garantia/grupoTipoGarantiaDto.model';
import { GruposTipoGarantiaService } from '@services/grupos-tipo-garantia/grupos-tipo-garantia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogGruposTipoGarantiaComponent } from '../dialog-grupos-tipo-garantia/dialog-grupos-tipo-garantia.component';

@Component({
  selector: 'app-grupos-tipo-garantia',
  templateUrl: './grupos-tipo-garantia.component.html',
  styleUrls: ['./grupos-tipo-garantia.component.css']
})
export class GruposTipoGarantiaComponent implements OnInit {

  data = false
  dataSource = new MatTableDataSource<GrupoTipoGarantiaDto>();
  obj!: undefined
  displayedColumns: string[] = [];
  detalle!: string;

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  constructor(
    public dialog: MatDialog,
    private gruposTipoGarantiaService: GruposTipoGarantiaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['tipoGarantia', 'idGrupo', 'accion'] :
        ['tipoGarantia', 'idGrupo', 'accion'];
    });

    this.dataSource.filterPredicate = function(data:GrupoTipoGarantiaDto, filter: string): boolean {
      return data.idGrupo.toString().toLowerCase().includes(filter) || data.cantidadTipoGarantia.tipoGarantia.descripcion.toString().toLowerCase().includes(filter) ;
    };
   }


  ngOnInit() {
    this.getAllGruposTiposGarantia();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async getAllGruposTiposGarantia() {
    this.spinner.show()
    await this.gruposTipoGarantiaService.getAll()
      .subscribe(
         data => {
          this.spinner.hide()
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide()
        }
      )
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj
    dialogConfig.width = "30%"

    const dialogRef = this.dialog.open(DialogGruposTipoGarantiaComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar)
        this.getAllGruposTiposGarantia()
    });
  }

  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
}
  }
}
