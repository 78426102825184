import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EstadoCivilDto } from '@models/estado-civil/estadoCivilDto.model';
import { isFalsy } from 'utility-types';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class EstadosCivilService {

  EstadoCivilDto:EstadoCivilDto= new EstadoCivilDto() ;
  lstEstadoCivilDto: EstadoCivilDto[] = [];

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http:HttpClient,private loginService: LoginService) {
  }

   getAll():Observable<EstadoCivilDto[]> {
     return   this.http.get<EstadoCivilDto[]>("/api/EstadosCivil/GetAll");
   }

   delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/EstadoCivil/Delete?id=" + id);
    else
      return of(null)
  }

}
