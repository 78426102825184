import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PersonasService } from "@services/personas/personas.service"
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { PropietariosService } from '@services/propietarios/propietarios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PropietarioDto } from '@models/propietarios/propietarioDto.model';

@Component({
  selector: 'app-propietario',
  templateUrl: './propietario.component.html',
  styleUrls: ['./propietario.component.css']
})
export class PropietarioComponent implements OnInit {
  personaDetailDto!: PersonaDetailDto;
  propietarioDto!: PropietarioDto;
  enableReactivar = false;
  constructor(
    private personasService: PersonasService,
    private router: Router,
    private propietariosService: PropietariosService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit(): void {

    this.personaDetailDto = this.personasService.getPersona()
    this.getPropietarioByIdPersona(this.personaDetailDto.id)
  }

  async getPropietarioByIdPersona(idPersona: number) {
    this.spinner.show('spPropietario')
    await this.propietariosService.getByIdPersona(idPersona)
      .subscribe(
        data => {
          this.propietarioDto = data
          this.spinner.hide('spPropietario')

          if (data.activo)
          {
            this.propietariosService.setPropietario(data)
            this.router.navigate(['/propietario/editar']);
          }
          else
            this.enableReactivar = true
        },
        error => {
          this.spinner.hide('spPropietario')
          this.router.navigate(['/propietario/nuevo']);
        })
  }

  async reactivar() {
    this.spinner.show('spPropietario')
    await this.propietariosService.reactivate(this.propietarioDto.id)
      .subscribe(
        data => {
          this.spinner.hide('spPropietario')
          this.snackBar.showSuccess("Propietario reactivado", "Exito");
          this.enableReactivar = false
          this.router.navigate(['/propietario/editar']);
        },
        error => {
          this.spinner.hide('spPropietario')
          this.snackBar.showError(error, "Error");
        })
  }

}
