import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-servicios-propiedad',
  templateUrl: './servicios-propiedad.component.html',
  styleUrls: ['./servicios-propiedad.component.css']
})
export class ServiciosPropiedadComponent implements OnInit {
  idPropiedad!: number;

  constructor(
    private aRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idPropiedad = Number(routeParams.get('id'));
  }

}
