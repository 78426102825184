<div class="row content" *appRole="['Documentos.Ver']">
    <div class="col-sm-2">
        <app-nav-panel-persona [idPersona]="personaDetailDto.id"></app-nav-panel-persona>
    </div>
    <div class="col-sm-10">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false" #mapanel="matExpansionPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Documentos persona</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">folder</mat-icon>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <mat-card>
                        <mat-card-header>
                            <mat-card-subtitle>Nuevo documento</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <app-asocia-documento-nuevo-entidad (documentoChange)="documentoChange=$event" [idEntidad]="idEntidad" [idTipoEntidad]="idTipoEntidad"></app-asocia-documento-nuevo-entidad>
                        </mat-card-content>
                    </mat-card>
                    <br>
                    <mat-card *ngIf="this.idUserRol != this.roles.Clientes">
                        <mat-card-header>
                            <mat-card-subtitle>Asociar documento existente</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <app-asocia-documento-entidad (documentoChange)="documentoChange=$event" [idEntidad]="idEntidad" [idTipoEntidad]="idTipoEntidad"></app-asocia-documento-entidad>
                        </mat-card-content>
                    </mat-card>
                    <br>
                    <app-listado-documentos-entidad [documentoChange]="documentoChange" [idEntidad]="idEntidad" [idTipoEntidad]="idTipoEntidad"></app-listado-documentos-entidad>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
