import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { UpdateRequestBase } from '@models/base/updateRequestBase.model';
import { CreatePagoComprobanteRequest } from '@models/pagos-comprobantes/createPagoComprobanteRequest.model';
import { PagoComprobanteDetailDto } from '@models/pagos-comprobantes/pagoComprobanteDetailDto.model';
import { PagoComprobanteDto } from '@models/pagos-comprobantes/pagoComprobanteDtoPago.model';
import { UpdatePagoComprobanteRequest } from '@models/pagos-comprobantes/updatePagoComprobanteRequest.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PagoscomprobantesService {


  constructor(
    private http: HttpClient
  ) { }

  /**
   * *Crear pagos comprobante
   * @param createComprobanteRequest
   * @returns
   */
  create(createPagoComprobanteRequest: CreatePagoComprobanteRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/PagoComprobante/Create", createPagoComprobanteRequest);
  }

  /**
   * *Actualizar pagos comprobante.
   * @param updateRequestBase
   * @returns
   */
  update(updatePagoComprobanteRequest: UpdatePagoComprobanteRequest): Observable<any> {
    return this.http.put<UpdateRequestBase>("/api/PagoComprobante/Update", updatePagoComprobanteRequest);
  }

  /**
   * *Obtener todos los pagos comprobante.
   * @returns
   */
  getAll(): Observable<PagoComprobanteDto[]> {
    return this.http.get<PagoComprobanteDto[]>("/api/PagoComprobante/GetAll");
  }

  /**
   * *Obtener pagos comprobante. por Id de pagos comprobante.
   * @param id
   * @returns
   */
  getById(id: number): Observable<PagoComprobanteDto> {
    return this.http.get<PagoComprobanteDto>("/api/PagoComprobante/GetById?id=" + id);
  }


  /**
   * *Obtener Contratos segun filtros
   * @param dateFrom Fecha desde
   * @param dateTo Fecha hasta
   * @param idsFormaPago Listado de formas de pago
   * @param idsEstadoPago Listado de estados de pago
   * @param idContrato Id de un contrato
   * @returns Observable<PagoComprobanteDetailDto[]>
   */
  getPagosComprobantesByFilter(dateFrom: string, dateTo: string, idsFormaPago: number[] | null, idsEstadoPago: number[] | null, idContrato: number | null): Observable<PagoComprobanteDetailDto[]> {

    let filtro = ""

    if (dateFrom != null && dateFrom != "" && dateTo != null && dateTo != "")
      filtro += "?dateFrom=" + dateFrom + "&dateTo=" + dateTo

    if (idsFormaPago)
      idsFormaPago.forEach(element => {
        filtro += "&idsFormaPago=" + element
      });

    if (idsEstadoPago)
      idsEstadoPago.forEach(element => {
        filtro += "&idsEstadoPago=" + element
      });

    if (idContrato != null)
      filtro += "&idContrato=" + idContrato


    return this.http.get<PagoComprobanteDetailDto[]>("/api/PagoComprobante/GetPagosComprobantesByFilter" + filtro);
  }


}
