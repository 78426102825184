import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AccionesDialog, TiposMonedas, TiposMovimiento } from '@models/base/identificadores.model';
import { CreateComprobanteRequest } from '@models/comprobantes/createComprobanteRequest.model';
import { MovimientoBasicDto } from '@models/movimiento/movimientoBasicDto.model';
import { MovimientoDetailDto } from '@models/movimiento/movimientoDetailDto.model';
import { CreatePagoRequest } from '@models/pago/createPagoRequest.model';
import { PagoDetailDto } from '@models/pago/pagoDetailDto.model';
import { PagoDto } from '@models/pago/pagoDto.model';
import { ParametroSistemaDto } from '@models/parametros-sistema/parametroSistemaDto.model';
import { ComprobantesService } from '@services/comprobantes/comprobantes.service';
import { LoginService } from '@services/login/login.service';
import { ParametrosSistemaService } from '@services/parametro-sistema/parametros-sistema.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogEditarMovimientosComponent } from '@views/contratos/dialog-nuevo-recibo/dialog-editar-movimientos/dialog-editar-movimientos.component';
import { DialogNuevoMovimientoComponent } from '@views/contratos/dialog-nuevo-recibo/dialog-editar-movimientos/dialog-nuevo-movimiento/dialog-nuevo-movimiento.component';
import { DialogNuevoPagoComponent } from '@views/contratos/dialog-nuevo-recibo/dialog-nuevo-pago/dialog-nuevo-pago.component';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-nuevo-movimiento-caja',
  templateUrl: './dialog-nuevo-movimiento-caja.component.html',
  styleUrls: ['./dialog-nuevo-movimiento-caja.component.css']
})
export class DialogNuevoMovimientoCajaComponent implements OnInit {



  cantidadMovimientos: number = 0
  movimientosNuevos: any;
  showProgressBar: boolean = false;
  //Tab
  selected = new FormControl(0);


  //Movimientos Ingresos y Egresos
  selectionIngresoEgreso= new SelectionModel<MovimientoDetailDto>(true, []);
  displayedColumnsIngresoEgreso: string[] = ['periodo', 'tipoMovimiento', 'importe', 'descripcion', 'accion'];
  dataSourceIngresoEgreso = new MatTableDataSource<MovimientoDetailDto>();
  dataIngresoEgreso: boolean = false;
  movimientosIngresoEgreso: MovimientoDetailDto[] = [];

  //Pagos
  displayedColumnsPagos: string[] = ['formaPago', 'importe', 'fecha', 'detalle', 'accion'];
  lstPago: PagoDto[] = []
  dataSourcePagos = new MatTableDataSource<PagoDto>();
  dataPagos: boolean = false;
  totalPagos: number = 0
  cuentaContableDefecto!: ParametroSistemaDto;


  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogNuevoMovimientoCajaComponent>,
    private snackBar: SnackBarService,
    private parametrosSistemaService: ParametrosSistemaService,
    private loginService: LoginService,
    private comprobantesService: ComprobantesService
  ) { }

  ngOnInit(): void {
    this.getCuentaContableDefecto()
  }



  //#region  Ingresos y ENgresos
  agregar() {
    let totalPagos = 0
    let totalImporteMovimiento = 0
    const periodoAnio = new Date().getFullYear()
    const periodoMes = new Date().getMonth()
    const periodo= 0
    const lstMovimientoBasicDto: MovimientoBasicDto[] = [];

    //Movimientos Nuevos
    if (this.movimientosIngresoEgreso.length > 0)
      this.movimientosIngresoEgreso.forEach(element => {
        let movimientoBasicDto = new MovimientoBasicDto;
        movimientoBasicDto.importe = element.importe;
        movimientoBasicDto.descripcion = isFalsy(element.descripcion) ? "" : element.descripcion;
        movimientoBasicDto.idTipoMovimiento = element.tipoMovimiento.id;
        movimientoBasicDto.idBonificacion = element.bonificacion?.id;
        movimientoBasicDto.periodo = periodo
        movimientoBasicDto.periodoMes = periodoMes
        movimientoBasicDto.periodoAnio = periodoAnio
        movimientoBasicDto.idBonificacion = null
        movimientoBasicDto.idPeriodoContrato = null
        totalImporteMovimiento += element.importe;
        lstMovimientoBasicDto.push(movimientoBasicDto);
      });



    //Pagos
    const pagos: PagoDetailDto[] = []
    this.lstPago.forEach(element => {
      let pago = new PagoDetailDto
      pago.id = 0
      pago.descripcion = element.descripcion == "" ? "" : element.descripcion
      pago.idFormaPago = element.idFormaPago
      pago.idEstadoPago = element.idEstadoPago
      pago.importe = element.importe
      pago.fechaPago = element.fechaPago
      pago.fechaAcreditacion = element.fechaAcreditacion
      pago.fechaVencimiento = element.fechaVencimiento
      pago.idCuentaContableDestino = parseInt(this.cuentaContableDefecto.valor)
      pago.idCuentaContableOrigen =parseInt(this.cuentaContableDefecto.valor)
      pago.idTipoMoneda = TiposMonedas.Pesos
      totalPagos += element.importe;
      pagos.push(pago)
    });

    let createPagoRequest = new CreatePagoRequest
    createPagoRequest.pagos = pagos
    const idUsuario = this.loginService.getIdUser();
    createPagoRequest.idUsuario = idUsuario
    createPagoRequest.idComprobante = null


    if (totalImporteMovimiento == totalPagos && totalImporteMovimiento > 0 && totalPagos > 0) {
      {
        let user = this.loginService.getCurrentUser();
        let createComprobanteRequest : CreateComprobanteRequest = new CreateComprobanteRequest
        createComprobanteRequest.movimientosNuevos = lstMovimientoBasicDto
        createComprobanteRequest.pagos = pagos
        createComprobanteRequest.idUsuario = user.id
        createComprobanteRequest.idEmpresa = user.empresa.id
        this.createComprobante(createComprobanteRequest);
      }

    }
    else
      this.snackBar.showWarn("Los totales tanto de los pagos como de los movimientos deben ser iguales.", "Info");
  }

  eliminarMovimiento(element: any) {
    this.dataSourceIngresoEgreso.data = this.dataSourceIngresoEgreso.data.filter((value, key: any) => {
      return value != element
    });
    this.movimientosIngresoEgreso.forEach((movimiento, index) => {
      if (movimiento == element)
        this.movimientosIngresoEgreso.splice(index, 1);
    });
    this.dataIngresoEgreso = this.dataSourceIngresoEgreso.data.length >= 1 ? true : false
  }

  agregarMovimiento(movimiento: MovimientoDetailDto) {
    this.movimientosIngresoEgreso.push(movimiento)
    this.selectionIngresoEgreso.select(movimiento)
    this.movimientosIngresoEgreso.forEach(element => {
      if (element.seleccionPago == true)
        this.selectionIngresoEgreso.select(element)
    });
    this.dataSourceIngresoEgreso.data = this.movimientosIngresoEgreso
    this.dataIngresoEgreso = this.dataSourceIngresoEgreso.data.length >= 1 ? true : false
  }

  editarMovimientos() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = this.movimientosIngresoEgreso
    dialogConfig.width = "650px";
    const dialogRef = this.dialog.open(DialogEditarMovimientosComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.event == AccionesDialog.Aceptar) {
        this.movimientosIngresoEgreso = result.data
      }

      if (result.event == AccionesDialog.Cancelar) {
        this.movimientosIngresoEgreso = result.data
      }

    });
  }

  openDialogNuevoMovimiento() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "40%"
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      event: AccionesDialog.Agregar,
      id: 0,
      importe: 0,
      periodo: 0,
      periodoMes: new Date().getMonth(),
      periodoAnio: new Date().getFullYear(),
      movimiento: new MovimientoDetailDto,
      tipoMovimiento: [TiposMovimiento.IngresoCaja,TiposMovimiento.EgresoCaja]
    }

    const dialogRef = this.dialog.open(DialogNuevoMovimientoComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Cancelar) {
      }
      if (result.event == AccionesDialog.Agregar) {
        const tipoMovimiento = result.data.tipoMovimiento
        const importe = result.data.importe
        const movimiento = new MovimientoDetailDto
        movimiento.id = result.data.id
        movimiento.tipoMovimiento = tipoMovimiento
        movimiento.importe = importe
        movimiento.descripcion = result.data.descripcion
        movimiento.periodo = result.data.periodo
        movimiento.periodoMes = result.data.periodoMes
        movimiento.periodoAnio = result.data.periodoAnio
        movimiento.descripcion = result.data.descripcion
        this.agregarMovimiento(movimiento)
      }

    });
  }

  getCuentaContableDefecto(){
    this.parametrosSistemaService.getValueByName("CuentaContableDefecto").subscribe(
      data => {
        this.cuentaContableDefecto = data
      },
      error => {
        this.snackBar.showError(error, "Error");
      }
    )
  }

  //#endregion Ingresos y Egresos


  //#region Pagos
  agregarPago() {

    let montoActual = this.movimientosIngresoEgreso.map(t => t.importe).reduce((acc, value) => acc + value, 0);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "600px"
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      event: AccionesDialog.Agregar, saldo: montoActual
    }

    const dialogRef = this.dialog.open(DialogNuevoPagoComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.lstPago.push(result.data as PagoDto)
        this.dataSourcePagos.data.push(result.data as PagoDto)
        this.dataSourcePagos.data = this.dataSourcePagos.data.filter((value: any, key: any) => {
          return true;
        });
      }
      this.totalPagos = this.lstPago.map(t => t.importe).reduce((acc, value) => acc + value, 0);

      this.dataPagos = this.dataSourcePagos.data.length >= 1 ? true : false
    });
  }
  eliminarPago(pago: PagoDto) {
    this.dataSourcePagos.data = this.dataSourcePagos.data.filter((value, key: any) => {
      return value != pago
    });
    this.lstPago.forEach((element, index) => {
      if (element == pago)
        this.lstPago.splice(index, 1);
    });
    this.totalPagos = this.lstPago.map(t => t.importe).reduce((acc, value) => acc + value, 0);
    this.dataPagos = this.dataSourcePagos.data.length >= 1 ? true : false
  }

  //#endregion

  createComprobante(createComprobanteRequest: CreateComprobanteRequest ) {
    this.showProgressBar = true
    this.comprobantesService.createComprobanteRecibo(createComprobanteRequest).subscribe(
      data => {
        this.showProgressBar = false
        this.snackBar.showSuccess("Movimientos creados correctamente.","Exito")
        this.dialogRef.close({event: AccionesDialog.Agregar})
        //this.dialog.closeAll();
      },
      error => {
        this.showProgressBar = false
        this.snackBar.showError(error, "Error");
      }
    )
  }
}
