import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contratos-propiedad',
  templateUrl: './contratos-propiedad.component.html',
  styleUrls: ['./contratos-propiedad.component.css']
})
export class ContratosPropiedadComponent implements OnInit {

  idPropiedad!: number;

  constructor(
    private aRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idPropiedad = Number(routeParams.get('id'));
  }

}
