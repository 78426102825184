import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-create-menu',
  templateUrl: './dialog-create-menu.component.html',
  styleUrls: ['./dialog-create-menu.component.css']
})
export class DialogCreateMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
