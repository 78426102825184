export class CreateGarantiaWithPersona {

    public nombreCompleto !: string;
    public id_TipoDocumento !: number;
    public nroDocumento !: string;
    public correo !: string;
    public telefonoPrincipal!: string;
    public telefonoLaboral!: string;
    public ubicacion!: string;
    public id_Localidad!: number;
    public id_TipoGrantia!: number;
    public nroFolio!: string;
    public cuitCuil!: string;
    public razonSocial!: string;
    public sueldo!: string;
    public id_PersonaInquilino!: number;
    public idTipoFolio!:number;
    public tomo!: string;
    public numero!: string;
    public ubicacionPropiedad !: string;
    public localidad !: string;
    public indivisa: number = 100;
    public horizontal: boolean=false;
}
