<div class="position-relative">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title>Modificar <strong>movimiento</strong></h1>
  <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <mat-divider></mat-divider>
    <br>
    <form [formGroup]="movimientoForm">
      <div>
        <ngx-spinner [zIndex]=100 name="spMovimiento" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
          type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <div class="row">
          <div class="col-lg-2">
            <mat-form-field class="example-full-width">
              <ngx-spinner [zIndex]=100 name="spBusquedaTiposMovimiento" bdColor="rgba(255,255,255,0.8)" size="small"
                color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
              <mat-label>Tipo Movimiento</mat-label>
              <mat-select placeholder="Seleccione opcion"  formControlName="TipoMovimiento" >
                <mat-option [value]="tipoMovimiento.id"
                  *ngFor="let tipoMovimiento of lstTiposMovimiento;let i=index;trackBy:trackByItems">
                  {{tipoMovimiento.resta == true ? tipoMovimiento.descripcion + ' (-)' : tipoMovimiento.descripcion + ' (+)'}}</mat-option>
              </mat-select>
              <mat-error *ngIf="f.TipoMovimiento.errors?.required">Este campo es <strong>requerido</strong>.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-2">
            <mat-form-field>
              <mat-label>Mes y Año</mat-label>
              <input matInput [matDatepicker]="dp" formControlName="Periodo">
              <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
              <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
              </mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-lg-2">
            <mat-form-field class="example-full-width">
              <span matPrefix>$ &nbsp;</span>
              <mat-label>Importe</mat-label>
              <input type="text" mask="separator.2" thousandSeparator="," placeholder="Importe"
                formControlName="Importe" matInput>
              <mat-error *ngIf="f.Importe.errors?.required">Este campo es <strong>requerido</strong>.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-2">
            <mat-form-field class="example-full-width">
              <mat-label>Fecha Pago</mat-label>
              <input type="date" formControlName="FechaPago" matInput>
            </mat-form-field>
          </div>
          <div class="col-lg-2">
            <mat-form-field class="example-full-width">
              <mat-label>Fecha Emision</mat-label>
              <input type="date" formControlName="FechaEmision" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="full-width-x100">
              <mat-label>Contrato</mat-label>
              <input style="text-transform: capitalize;" type="text" formControlName="Contrato" matInput>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="full-width-x100">
              <mat-label>Propietario</mat-label>
              <input style="text-transform: capitalize;" type="text" formControlName="Propietario" matInput>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="full-width-x100">
              <mat-label>Inquilino</mat-label>
              <input style="text-transform: capitalize;" type="text" formControlName="Inquilino" matInput>
            </mat-form-field>
          </div>

        </div>
        <div class="row">

          <div class="col-lg-3" *ngIf="movimientoDetailDto">
            <strong *ngIf="movimientoDetailDto.anulado">Estado: Anulado</strong>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="full-width-x100">
              <mat-label>Descripción</mat-label>
              <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
              <mat-error *ngIf="f.Descripcion.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
              <mat-hint align="start"><strong>Amplie información</strong> </mat-hint>
              <mat-hint align="end">{{message.value.length}} / 2000</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <br>
        <mat-divider></mat-divider>
        <br>
        <div>
          <button mat-raised-button color="primary" *appRole="['Movimientos.Modificar']"
            [disabled]="!movimientoForm.valid || data.event =='Ver' || movimientoDetailDto.fechaPago != null" (click)="onSubmit()">Guardar</button>
          <button mat-button mat-dialog-close color="warn" (click)="closeDialog()">Cancelar</button>
        </div>
      </div>
    </form>
  </div>

</div>
