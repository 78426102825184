export class UpdateMontosPorBrocheRequest {
    idServicio: number = 0
    porcentaje: number = 0
    periodoOrigen: string = ""
    periodoDestino: string = ""
    fechaVencimiento1!: string
    fechaVencimiento2!: string
    fechaVencimiento3!: string
    fechaVencimiento4!: string
}
