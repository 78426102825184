import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-periodoshistoricos',
  templateUrl: './periodoshistoricos.component.html',
  styleUrls: ['./periodoshistoricos.component.css']
})
export class PeriodoshistoricosComponent implements OnInit {

  idContrato!: number;
  contratoDetailDto!: ContratoDetailDto;

  constructor(
    private aRoute: ActivatedRoute,
    private contratoService: ContratosService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
  ) { }


  ngOnInit() {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idContrato = Number(routeParams.get('id'));
    if (this.contratoService.getContratoDto() != null) {
      this.contratoDetailDto = this.contratoService.getContratoDto()
    }
    else
      this.getContratoById(this.idContrato)
  }


  getContratoById(idContrato: number) {
    this.spinner.show("spContrato");
    this.contratoService.getById(idContrato).subscribe(
      data => {
        this.spinner.hide("spContrato");
        this.contratoDetailDto = data
        this.contratoService.setContratoDto(data);

      },
      error => {
        this.spinner.hide("spContrato");
        this.snackBar.showError(error, "Error");
      }
    )
  }

}
