import { AutoMap } from "@automapper/classes";
import { BaseDto } from "@models/base/baseDto.model";
import { ProvinciaDto } from "@models/localidad-provincia/provinciaDto.model";

export class DepartamentoDto extends BaseDto {

  @AutoMap() public categoria: string = "";
  @AutoMap() public fuente: string = "";
  @AutoMap() public centroideLon: number = 0;
  @AutoMap() public centroideLat: number = 0;
  @AutoMap(() => ProvinciaDto) public provincia: ProvinciaDto = new ProvinciaDto
  @AutoMap() public nombreCompleto: string = "";
  @AutoMap() public nombre: string = "";
  @AutoMap() public provinciaInterseccion: number = 0;

  static fromDepartamentoDto(departamentoDto: DepartamentoDto) {
    const dto = new DepartamentoDto();
    dto.id = departamentoDto.id;
    dto.descripcion = departamentoDto.descripcion;
    dto.categoria = departamentoDto.categoria;
    dto.centroideLon = departamentoDto.centroideLon;
    dto.centroideLat = departamentoDto.centroideLat;
    dto.fuente = departamentoDto.fuente;
    dto.provincia = ProvinciaDto.fromProvinciaDto(departamentoDto.provincia);
    dto.nombreCompleto = departamentoDto.nombreCompleto;
    dto.descripcion = departamentoDto.descripcion;
    dto.nombre = departamentoDto.nombre;
    dto.provinciaInterseccion = departamentoDto.provinciaInterseccion;
    return dto;
  }


}
