<div class="row content" *appRole="['DetalleServicios.Ver']">
    <div class="col-sm-2">
        <app-nav-panel-propiedad [idPropiedad]="idPropiedad"></app-nav-panel-propiedad>
    </div>
    <div class="col-sm-10">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false" #mapanel="matExpansionPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Detalle servicio propiedad</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">doorbell</mat-icon>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <app-listado-detalle-servicio-por-propiedad [update]="update"></app-listado-detalle-servicio-por-propiedad>
                </ng-template>
            </mat-expansion-panel>

        </mat-accordion>
    </div>
</div>