import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateCantidadTipoGarantiaRequest } from '@models/cantidades-tipo-garantia/createCantidadTipoGarantiaRequest.model';
import { UpdateCantidadTipoGarantiaRequest } from '@models/cantidades-tipo-garantia/updateCantidadTipoGarantiaRequest.modle';
import { TipoGarantiaDto } from '@models/tipo-garantia/tipoGarantiaDto.model';
import { CantidadesTipoGarantiaService } from '@services/cantidades-tipo-garantia/cantidades-tipo-garantia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposGarantiaService } from '@services/tipos-garantia/tipos-garantia.service';

@Component({
  selector: 'app-dialog-cantidad-tipo-garantia',
  templateUrl: './dialog-cantidad-tipo-garantia.component.html',
  styleUrls: ['./dialog-cantidad-tipo-garantia.component.css']
})
export class DialogCantidadTipoGarantiaComponent implements OnInit {

  action: string;
  local_data: any;
  form: any;
  formBuilder: FormBuilder = new FormBuilder;
  lstTiposGarantia: BaseDto[] = []

  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogCantidadTipoGarantiaComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: BaseDto,
    private tiposGarantiaService: TiposGarantiaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private cantidadesTipoGarantiaService: CantidadesTipoGarantiaService,
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      TipoGarantia: ["", Validators.compose([Validators.required,])],
      Cantidad: ["", Validators.compose([Validators.required,])],
    })
  }

  ngOnInit(): void {
    if (this.local_data.action != AccionesDialog.Agregar) {
      this.form.controls["Cantidad"].setValue(this.local_data.cantidad)
      this.form.controls["TipoGarantia"].setValue(this.local_data.tipoGarantia.id)
    }

    this.getAllTiposGarantia()
  }

  doAction() {
    let cantidad = this.form.controls["Cantidad"].value
    let idTipoGarantia = this.form.controls["TipoGarantia"].value

     switch (this.local_data.action) {
      case AccionesDialog.Agregar:
        this.addRowData("", cantidad, idTipoGarantia)
        break;
      case AccionesDialog.Modificar:
        this.updateRowData(this.local_data.id, "", cantidad, idTipoGarantia)
        break;
      case AccionesDialog.Eliminar:
        this.deleteRowData(this.local_data.id)
        break;
      default:
        break;
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }



  getAllTiposGarantia() {
    this.spinner.show("spBusqueda")
    this.tiposGarantiaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda")
          this.lstTiposGarantia = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spBusqueda")
        }
      )
  }


  addRowData(descripcion: string, cantidad: number, idTipoGarantia: number) {
    this.spinner.show()
    let createCantidadTipoGarantiaRequest = new CreateCantidadTipoGarantiaRequest
    createCantidadTipoGarantiaRequest.descripcion = descripcion
    createCantidadTipoGarantiaRequest.cantidad = cantidad
    createCantidadTipoGarantiaRequest.idTipoGarantia = idTipoGarantia

    this.cantidadesTipoGarantiaService.create(createCantidadTipoGarantiaRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          this.dialogRef.close({ event: this.action, data: this.local_data });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )

  }
  updateRowData(id: number, descripcion: string, cantidad: any, tipoGarantia: any) {
    this.spinner.show()
    let updateCantidadTipoGarantiaRequest = new UpdateCantidadTipoGarantiaRequest
    updateCantidadTipoGarantiaRequest.id = id
    updateCantidadTipoGarantiaRequest.cantidad = cantidad
    updateCantidadTipoGarantiaRequest.idTipoGarantia = tipoGarantia

    this.cantidadesTipoGarantiaService.update(updateCantidadTipoGarantiaRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro actualizado correctamente.", "Exito");
          this.dialogRef.close({ event: this.action, data: this.local_data });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })
  }

  deleteRowData(id: number) {
    this.cantidadesTipoGarantiaService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.dialogRef.close({ event: this.action, data: this.local_data });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })
  }

  trackByItems(index: number, item: any): any { return item; }

}
