import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificacionDto } from '@models/notificaciones/notificacionDto.model';
import { NotificacionesService } from '@services/notificaciones/notificaciones.service';

@Component({
  selector: 'app-dialog-notificaciones',
  templateUrl: './dialog-notificaciones.component.html',
  styleUrls: ['./dialog-notificaciones.component.css']
})
export class DialogNotificacionesComponent implements OnInit {

  notificaciones: NotificacionDto[] = []
  constructor(
    public dialogRef: MatDialogRef<DialogNotificacionesComponent>,
    private notificacionesService: NotificacionesService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: NotificacionDto[],
  ) {

    this.notificaciones = data;
  }

  ngOnInit(): void {
  }

  eliminarNotificacion(item: any) {
    this.notificaciones = this.notificaciones.filter((value) => {
      return value.mensaje != item.mensaje;
    });
    this.notificacionesService.clearNotificacion()
  }

  trackByItems(index: number, item: any): any { return item; }


}
