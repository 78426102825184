import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { E } from '@angular/cdk/keycodes';
import { Component, Inject, OnChanges, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { DetalleServicioPropiedadDetailDto, Vencimiento } from '@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadDetailDto.model';
import { isFalsy } from 'utility-types';
import { DialogNuevoVencimientoServicioComponent } from './dialog-nuevo-vencimiento-servicio/dialog-nuevo-vencimiento-servicio.component';
import { helpers } from 'chart.js';
import { Helper } from '@helpers/helper';

@Component({
  selector: 'app-dialog-editar-adicionales-inquilino',
  templateUrl: './dialog-editar-adicionales-inquilino.component.html',
  styleUrls: ['./dialog-editar-adicionales-inquilino.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class DialogEditarAdicionalesInquilinoComponent implements OnInit, OnChanges {
  displayedColumns = ['select', 'periodo', 'servicio', 'desplegar'];
  dataSource = new MatTableDataSource<DetalleServicioPropiedadDetailDto>();
  dspdDtoSelection = new SelectionModel<DetalleServicioPropiedadDetailDto>(true, []);
  expandedElement!: DetalleServicioPropiedadDetailDto | null;
  active = false;
  vencimiento: boolean = false
  boxCheked = false
  lstServiciosAPagar: Vencimiento[] = [];
  periodosAPagar: Vencimiento[] = [];
  subtotal: number = 0
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  lstPeriodosContratosApagar!: DetalleServicioPropiedadDetailDto[];


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DetalleServicioPropiedadDetailDto[],
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogEditarAdicionalesInquilinoComponent>,
  ) {
  }

  ngOnInit(): void {
    //this.cargarListadoVencimientos()
    this.data.forEach((dspdDto: any) => {
      if (dspdDto.seleccionPago == true) {
        dspdDto.vencimientos.forEach((vencimiento: Vencimiento) => {
          if (vencimiento.seleccionPago == true) {
            this.dspdDtoSelection.select(dspdDto)
          }
        });
      }
      else
        this.dspdDtoSelection.deselect(dspdDto)
    });
    this.calculaSubtotal()
    this.actualizaYOrdenaDataSource()
  }

  actualizaYOrdenaDataSource() {
    this.dataSource.data = this.data.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
  }

  openDialogNuevoVencimiento(element: any) {
    this.procesarSeleccionados()
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { event: AccionesDialog.Agregar, detalleServicio: element }
    dialogConfig.autoFocus = false
    dialogConfig.disableClose = true

    const dialogRef = this.dialog.open(DialogNuevoVencimientoServicioComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Modificar) {
        this.data = this.data.filter((value: any, key: any) => {
          return value.id != result.data.id;
        });
        result.data.vencimientos = this.cargarVencimientos(result.data)
        this.dspdDtoSelection.select(result.data)
        this.data = [...this.data, result.data]
        //this.cargarListadoVencimientos()
        this.actualizaYOrdenaDataSource()
        if (element.id == result.data.id) {
          this.checkSelectServicio(true, result.data)
        }
        //this.selection.clear()
      }
    });
  }

  checkSelectServicio(checked: any, servicio: any) {
    if (checked == false) {
      this.data.forEach(element => {
        if (element.id == servicio.id) {
          element.vencimientos.forEach(ven => {
            if (ven.seleccionPago == true) {
              ven.seleccionPago = false
              element.seleccionPago = false
            }
          })
        }
      })
    }
    else {
      this.data.forEach(element => {
        var vencimientosActivos = 0
        element.vencimientos.forEach(vencimiento => {
          if (this.esFechaVencida(vencimiento) == false)
            vencimientosActivos++
        })
        if (element.id == servicio.id && vencimientosActivos == 1) {
          element.vencimientos.forEach(vencimiento => {
            if (this.esFechaVencida(vencimiento) == false) {
              vencimiento.seleccionPago = true
              element.seleccionPago = true
            }
          })
        }
      })
    }
    this.calculaSubtotal()
  }

  calculaSubtotal() {
    this.subtotal = 0
    this.data.forEach(element => {
      element.vencimientos.forEach(ven => {
        if (ven.seleccionPago == true) {
          this.subtotal += ven.importeVencimiento
        }
      })
    })
  }

  ngOnChanges() {
    //this.cargarListadoVencimientos()
  }


  cargarListadoVencimientos() {
    this.spinner.show()
    this.data.forEach(element => {
      const lstVencimientos: Vencimiento[] = []
      if (!isFalsy(element.fechaVencimiento1) && !isFalsy(element.importeVencimiento1)) {
        const vencimiento = new Vencimiento
        vencimiento.idDetalle = element.id
        vencimiento.fechaVencimiento = element.fechaVencimiento1
        vencimiento.importeVencimiento = isFalsy(element.importeVencimiento1) ? 0 : element.importeVencimiento1
        lstVencimientos.push(vencimiento)
        element.vencimientos = lstVencimientos
      }
      if (!isFalsy(element.fechaVencimiento2) && !isFalsy(element.importeVencimiento2)) {
        const vencimiento = new Vencimiento
        vencimiento.idDetalle = element.id
        vencimiento.fechaVencimiento = element.fechaVencimiento2
        vencimiento.importeVencimiento = isFalsy(element.importeVencimiento2) ? 0 : element.importeVencimiento2
        lstVencimientos.push(vencimiento)
        element.vencimientos = lstVencimientos
      }
      if (!isFalsy(element.fechaVencimiento3) && !isFalsy(element.importeVencimiento3)) {
        const vencimiento = new Vencimiento
        vencimiento.idDetalle = element.id
        vencimiento.fechaVencimiento = element.fechaVencimiento3
        vencimiento.importeVencimiento = isFalsy(element.importeVencimiento3) ? 0 : element.importeVencimiento3
        lstVencimientos.push(vencimiento)
        element.vencimientos = lstVencimientos
      }
      if (!isFalsy(element.fechaVencimiento4) && !isFalsy(element.importeVencimiento4)) {
        const vencimiento = new Vencimiento
        vencimiento.idDetalle = element.id
        vencimiento.fechaVencimiento = element.fechaVencimiento4
        vencimiento.importeVencimiento = isFalsy(element.importeVencimiento4) ? 0 : element.importeVencimiento4
        lstVencimientos.push(vencimiento)
        element.vencimientos = lstVencimientos
      }

    });
    this.spinner.hide()
  }
  cargarVencimientos(dspdDto: DetalleServicioPropiedadDetailDto) {
    const lstVencimientos: Vencimiento[] = []
    if (!isFalsy(dspdDto.fechaVencimiento1) && !isFalsy(dspdDto.importeVencimiento1)) {
      const vencimiento = new Vencimiento
      vencimiento.idDetalle = dspdDto.id
      vencimiento.fechaVencimiento = dspdDto.fechaVencimiento1
      vencimiento.importeVencimiento = isFalsy(dspdDto.importeVencimiento1) ? 0 : dspdDto.importeVencimiento1
      lstVencimientos.push(vencimiento)
      dspdDto.vencimientos = lstVencimientos
    }
    if (!isFalsy(dspdDto.fechaVencimiento2) && !isFalsy(dspdDto.importeVencimiento2)) {
      const vencimiento = new Vencimiento
      vencimiento.idDetalle = dspdDto.id
      vencimiento.fechaVencimiento = dspdDto.fechaVencimiento2
      vencimiento.importeVencimiento = isFalsy(dspdDto.importeVencimiento2) ? 0 : dspdDto.importeVencimiento2
      lstVencimientos.push(vencimiento)
      dspdDto.vencimientos = lstVencimientos
    }
    if (!isFalsy(dspdDto.fechaVencimiento3) && !isFalsy(dspdDto.importeVencimiento3)) {
      const vencimiento = new Vencimiento
      vencimiento.idDetalle = dspdDto.id
      vencimiento.fechaVencimiento = dspdDto.fechaVencimiento3
      vencimiento.importeVencimiento = isFalsy(dspdDto.importeVencimiento3) ? 0 : dspdDto.importeVencimiento3
      lstVencimientos.push(vencimiento)
      dspdDto.vencimientos = lstVencimientos
    }
    if (!isFalsy(dspdDto.fechaVencimiento4) && !isFalsy(dspdDto.importeVencimiento4)) {
      const vencimiento = new Vencimiento
      vencimiento.idDetalle = dspdDto.id
      vencimiento.fechaVencimiento = dspdDto.fechaVencimiento4
      vencimiento.importeVencimiento = isFalsy(dspdDto.importeVencimiento4) ? 0 : dspdDto.importeVencimiento4
      lstVencimientos.push(vencimiento)
      dspdDto.vencimientos = lstVencimientos
    }
    return lstVencimientos
  }


  esFechaVencida(vencimiento: Vencimiento) {
    return Helper.esFechaVencida(vencimiento)
  }

  onSubmit() {
    this.procesarSeleccionados()
    this.dialogRef.close({ data: this.data, event: AccionesDialog.Agregar });
  }

  procesarSeleccionados() {
    this.data.forEach(element => {
      element.seleccionPago = false
    })
    this.lstPeriodosContratosApagar = this.dspdDtoSelection.selected
    this.lstPeriodosContratosApagar.forEach(detalleServicio => {
      this.data.forEach(element => {
        if (element.id == detalleServicio.id) {
          element.vencimientos.forEach(vencimiento => {
            if (vencimiento.seleccionPago == true)
              element.seleccionPago = true
          });
        }

      })
    })
  }

  onCancel() {
    const periodosAPagar: Vencimiento[] = []
    this.dialogRef.close({ data: periodosAPagar, lstDetalleServicioPropiedadDto: this.data, event: AccionesDialog.Cancelar });
  }

  selectVencimientoPago(detalleSrvPropiedad: DetalleServicioPropiedadDetailDto, vencimiento: Vencimiento) {
    //Deseleccion
    this.data.forEach(element => {
      if (element.id == detalleSrvPropiedad.id) {
        element.vencimientos?.forEach(ven => {
          ven.seleccionPago = false
          element.seleccionPago = false
        })
      }
    })
    //Seleccion
    this.data.forEach(element => {
      if (element.id == detalleSrvPropiedad.id) {
        element.vencimientos?.forEach(ven => {
          if (ven == vencimiento) {
            ven.seleccionPago = true
            element.seleccionPago = true
            this.calculaSubtotal()
            this.dspdDtoSelection.select(element)
          }

        })
      }
    })
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.dspdDtoSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.dspdDtoSelection.clear();
      return;
    }

    this.dspdDtoSelection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: DetalleServicioPropiedadDetailDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.dspdDtoSelection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  toggleClass() {
    this.active = !this.active;
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
