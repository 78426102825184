import { DetalleServicioPropiedadDto } from "@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadDto.model";
import { isFalsy } from "utility-types";

export class DetalleServicioPropiedadController {

  static validaListadoImportesYVencimientos(lstDetalleServicioPropiedadDto: DetalleServicioPropiedadDto[]) {
    lstDetalleServicioPropiedadDto.forEach(detalleServicioPropiedadDto => {
      detalleServicioPropiedadDto = this.validaImportesYVencimientos(detalleServicioPropiedadDto)
    });

    return lstDetalleServicioPropiedadDto;
  }

  static validaImportesYVencimientos(detalleServicioPropiedadDto: DetalleServicioPropiedadDto) {
    if (isFalsy(detalleServicioPropiedadDto.importeVencimiento1) || isFalsy(detalleServicioPropiedadDto.fechaVencimiento1)) {
      detalleServicioPropiedadDto.importeVencimiento1 = null
      detalleServicioPropiedadDto.fechaVencimiento1 = null
    }
    if (isFalsy(detalleServicioPropiedadDto.importeVencimiento2) || isFalsy(detalleServicioPropiedadDto.fechaVencimiento2)) {
      detalleServicioPropiedadDto.importeVencimiento2 = null
      detalleServicioPropiedadDto.fechaVencimiento2 = null
    }
    if (isFalsy(detalleServicioPropiedadDto.importeVencimiento3) || isFalsy(detalleServicioPropiedadDto.fechaVencimiento3)) {
      detalleServicioPropiedadDto.importeVencimiento3 = null
      detalleServicioPropiedadDto.fechaVencimiento3 = null
    }
    if (isFalsy(detalleServicioPropiedadDto.importeVencimiento4) || isFalsy(detalleServicioPropiedadDto.fechaVencimiento4)) {
      detalleServicioPropiedadDto.importeVencimiento4 = null
      detalleServicioPropiedadDto.fechaVencimiento4 = null
    }
    return detalleServicioPropiedadDto;
  }


}
