import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-editar-documento',
  templateUrl: './dialog-editar-documento.component.html',
  styleUrls: ['./dialog-editar-documento.component.css']
})
export class DialogEditarDocumentoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
