<ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
</ngx-spinner>
<div *appRole="['Inquilinos.Crear']">

  <button mat-raised-button color="primary" *ngIf="!esInquilino" (click)="onSubmit()">
    <mat-icon>add</mat-icon>
    Agregar persona como inquilino/a
  </button>
</div>

<h3 *ngIf="esInquilino">Esta persona ya es un inquilino/a</h3>
