import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogGenerarResumenMensualComponent } from '../dialog-generar-resumen-mensual/dialog-generar-resumen-mensual.component';

@Component({
  selector: 'app-btn-generar-resumen-mensual',
  templateUrl: './btn-generar-resumen-mensual.component.html',
  styleUrls: ['./btn-generar-resumen-mensual.component.css']
})
export class BtnGenerarResumenMensualComponent implements OnInit {

  @Output() procesoFinalizado = new  EventEmitter()

  constructor(
    private dialog: MatDialog,
    private snackBar: SnackBarService
  ) { }

  ngOnInit(): void {
  }

  openDialogGenerarResumenMensual() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "90%"
    dialogConfig.data = { event: AccionesDialog.Agregar }
    dialogConfig.disableClose = true

    const dialogRef = this.dialog.open(DialogGenerarResumenMensualComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Aceptar){
        this.snackBar.showSuccess("Proceso realizado correctamente.", "Exito");
        this.procesoFinalizado.emit(true)
      }

      if (result.event == AccionesDialog.Cancelar)
        this.snackBar.showInfo("Proceso cancelado.", "Info");
    });
  }


}
