<mat-form-field class="full-width">
    <ngx-spinner [zIndex]="1" name="spBusquedaPropiedad" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
    <mat-label>Buscar propiedad</mat-label>
    <input type="search" tab-directive placeholder="Buscar por nombre." aria-label="Number" matInput [matAutocomplete]="auto" [formControl]="entityControl" />
    <button disabled mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option [disabled]="disable" *ngFor="let propiedad of filteredPropiedades | async ;let i=index;trackBy:trackByItems" [value]="propiedad.descripcion | titlecase" (click)="selectValue(propiedad)" (onSelectionChange)="selectValue(propiedad)">
            <span>{{propiedad.descripcion | titlecase}}</span>
        </mat-option>
    </mat-autocomplete>
    <button mat-icon-button matSuffix *ngIf="entitySelect" matTooltip="Ver" (click)="verPropiedad(entitySelect)">
      <mat-icon color="primary">info</mat-icon>
      </button>
    <button mat-icon-button matSuffix matTooltip="Limpiar" *ngIf="entitySelect" (click)="selectValue(null)">
        <mat-icon>clear</mat-icon>
        </button>
</mat-form-field>
