import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { DeletePersonaRequest } from '@models/personas/deletePersonaRequest.model';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { PersonaDto } from '@models/personas/personaDto.model';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-dialog-delete-persona',
  templateUrl: './dialog-delete-persona.component.html',
  styleUrls: ['./dialog-delete-persona.component.css']
})
export class DialogDeletePersonaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogDeletePersonaComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PersonaDetailDto,
    private personasService: PersonasService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,

  ) { }

  ngOnInit(): void {
  }

  eliminar(): void {
    let deletePersonaRequest = new DeletePersonaRequest
    deletePersonaRequest.id_Persona = this.data.id
    this.personasService.deletePersona(deletePersonaRequest)
      .subscribe(data => {
        if (data = true) {
          this.snackBar.showSuccess("Se elimino el registro correctamente.", "Exito")
          this.dialogRef.close({event:AccionesDialog.Eliminar});
        }
      },
        (error: any) => {
          this.snackBar.showError( error, "Error");
          this.dialogRef.close({event:AccionesDialog.Cancelar});
        }
      )
  }

  onNoClick(): void {
    this.dialogRef.close({event:AccionesDialog.Cancelar});
  }

}
