import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LocalidadModule } from "src/app/modules/localidad.module";
import { SharedModule } from "src/app/modules/shared.module";
import { PersonasService } from "@services/personas/personas.service";
import { BuscarPersonaGaranteComponent } from "../views/busquedas/buscar-persona-garante/buscar-persona-garante.component";
import { BuscarPersonaInquilinoComponent } from "../views/busquedas/buscar-persona-inquilino/buscar-persona-inquilino.component";
import { BuscarPersonaComponent } from "../views/busquedas/buscar-persona/buscar-persona.component";
import { BtnDeletePersonaComponent } from "../views/personas/btn-delete-persona/btn-delete-persona.component";
import { BtnNuevaPersonaComponent } from "../views/personas/btn-nueva-persona/btn-nueva-persona.component";
import { DialogDeletePersonaComponent } from "../views/personas/dialog-delete-persona/dialog-delete-persona.component";
import { FormPersonaCreateComponent } from "../views/personas/form-persona-create/form-persona-create.component";
import { FormPersonaUpdateComponent } from "../views/personas/form-persona-update/form-persona-update.component";
import { ListadoPersonasComponent } from "../views/personas/listado-personas/listado-personas.component";
import { PersonasComponent } from "../views/personas/personas/personas.component";
import { FormPersonaReactiveComponent } from "@views/personas/form-persona-reactive/form-persona-reactive.component";
import { FormOnlyPersonaUpdateComponent } from "@views/personas/form-only-persona-update/form-only-persona-update.component";
import { DialogFormOnlyPersonaUpdateComponent } from "@views/personas/dialog-form-only-persona-update/dialog-form-only-persona-update.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LocalidadModule
  ],
  declarations: [
    PersonasComponent,
    ListadoPersonasComponent,
    BuscarPersonaComponent,
    BtnNuevaPersonaComponent,
    BtnDeletePersonaComponent,
    FormPersonaCreateComponent,
    FormPersonaUpdateComponent,
    FormOnlyPersonaUpdateComponent,
    DialogDeletePersonaComponent,
    BuscarPersonaInquilinoComponent,
    BuscarPersonaComponent,
    BuscarPersonaGaranteComponent,
    FormPersonaReactiveComponent,
    DialogFormOnlyPersonaUpdateComponent
  ],
  providers: [
    PersonasService
  ],
  exports: [
    PersonasComponent,
    ListadoPersonasComponent,
    BuscarPersonaComponent,
    BtnNuevaPersonaComponent,
    BtnDeletePersonaComponent,
    FormPersonaCreateComponent,
    FormPersonaUpdateComponent,
    DialogDeletePersonaComponent,
    BuscarPersonaInquilinoComponent,
    BuscarPersonaComponent,
    BuscarPersonaGaranteComponent,
    FormPersonaReactiveComponent,
    FormOnlyPersonaUpdateComponent,
    DialogFormOnlyPersonaUpdateComponent
  ]
})

export class PersonasModule { }
