import { TipoGarantiaDto } from "@models/tipo-garantia/tipoGarantiaDto.model";
import { BaseDto } from "../base/baseDto.model";

export class GarantiaSummaryDto {

    public persona!: BaseDto;
    public tipoGarantia!: TipoGarantiaDto;
    public descripcion!: string;
    public inquilino!: string;
    public id!: number;
    public cuitCuil!:string;
    public sueldo!:number;
    public razonSocial!: string
    public activo!: boolean
    public id_PersonaInquilino!: number
    public idTipoFolio!: number
    public tomo!: string
    public numero!: string
    public nroFolio!:string
    public ubicacion!: string
    public localidad!: string
    public indivisa: number = 100
    public horizontal!: boolean;
}
