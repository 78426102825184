import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseDto } from '@models/base/baseDto.model';
import { FormasPagoService } from '@services/formas-pago/formas-pago.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-buscar-forma-pago',
  templateUrl: './buscar-forma-pago.component.html',
  styleUrls: ['./buscar-forma-pago.component.css']
})
export class BuscarFormaPagoComponent implements OnInit {

  bancoSelect: EventEmitter<BaseDto> = new EventEmitter()
  public filteredFormaPago!: Observable<BaseDto[]>;
  lstFormaPago:BaseDto[] = []
  formaPago: BaseDto = new BaseDto;
  public entityControl = new FormControl();
  @Output() eventEntity= new EventEmitter<BaseDto>();
  @Input() formaPagoEntrada: BaseDto = new BaseDto;
  entitySelect!: number | null;
  formaPagoDto!: BaseDto

  constructor(
    private formasPagoService: FormasPagoService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    this.getAll();
  }


  ngOnChanges(): void {
    if(this.formaPagoEntrada != undefined){
      this.entityControl.setValue(this.formaPagoEntrada.descripcion)
      this.formaPagoDto = this.formaPagoEntrada
    }
  }

  findOption(val: string) {
    for (let i = 0; i < this.lstFormaPago.length; i++) {
      if (this.lstFormaPago[i].descripcion.toUpperCase() == val.toUpperCase()) {
        this.entitySelect = this.lstFormaPago[i].id
        this.eventEntity.emit(this.lstFormaPago[i])
      }
    }
    return this.lstFormaPago.filter((formaPago) => new RegExp(val, 'gi').test(formaPago.descripcion));
  }

  selectValue(event: any) {
    if (isFalsy(event)){
      this.entitySelect=null
      this.entityControl.setValue("")
    }
    this.eventEntity.emit(event)
  }

  getAll() {
    this.spinner.show("spBusqueda")
    this.formasPagoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda")
          this.lstFormaPago = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
          this.filteredFormaPago = this.entityControl.valueChanges.pipe(
            startWith(''),
            map((term: any) => this.findOption(term))
          );
        },
        error => {
          this.spinner.hide("spBusqueda")
          this.snackBar.showError(error, "Error");
        });
  }

  getBydId(id:number) {
    this.spinner.show("spBusqueda")
    this.formasPagoService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda")
          this.formaPagoDto = data
        },
        error => {
          this.spinner.hide("spBusqueda")
          this.snackBar.showError(error, "Error");
        });
  }

  trackByItems(index: number, item: any): any { return item; }
}
