import { EmailDto } from "./emailDto.model";
import { ReceiverDto } from "./receiverDto.model";

export class SendEmailRequest{
  public destinatarios!: ReceiverDto[]
  public asunto!: string
  public body!:string
  public files!: EmailDto[]

}
