import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generar-resumen-mensual',
  templateUrl: './generar-resumen-mensual.component.html',
  styleUrls: ['./generar-resumen-mensual.component.css']
})
export class GenerarResumenMensualComponent implements OnInit {
  procesado: any;

  constructor() { }

  ngOnInit(): void {
  }

  procesoFinalizado($event: any) {
    this.procesado = $event
  }

}
