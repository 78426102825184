import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BuscarCuentaContableComponent } from "../views/busquedas/buscar-cuenta-contable/buscar-cuenta-contable.component";
import { BtnNuevoCuentaContableComponent } from "../views/cuentas-contables/btn-nuevo-cuenta-contable/btn-nuevo-cuenta-contable.component";
import { CuentasContablePersonaComponent } from "../views/cuentas-contables/cuentas-contable-persona/cuentas-contable-persona.component";
import { DialogCuentaContablePersonaComponent } from "../views/cuentas-contables/cuentas-contable-persona/dialog-cuenta-contable-persona/dialog-cuenta-contable-persona.component";
import { ListadoCuentasContablePersonaComponent } from "../views/cuentas-contables/cuentas-contable-persona/listado-cuentas-contable-persona/listado-cuentas-contable-persona.component";
import { CuentasContablesComponent } from "../views/cuentas-contables/cuentas-contables/cuentas-contables.component";
import { DialogCuentaContableComponent } from "../views/cuentas-contables/dialog-cuenta-contable/dialog-cuenta-contable.component";
import { BancosModule } from "./bancos.module";
import { SharedModule } from "./shared.module";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BancosModule,
  ],
  declarations: [
    BtnNuevoCuentaContableComponent,
    CuentasContablesComponent,
    DialogCuentaContableComponent,
    CuentasContablePersonaComponent,
    ListadoCuentasContablePersonaComponent,
    DialogCuentaContablePersonaComponent,
    BuscarCuentaContableComponent,
  ],
  exports: [
    BtnNuevoCuentaContableComponent,
    CuentasContablesComponent,
    DialogCuentaContableComponent,
    CuentasContablePersonaComponent,
    ListadoCuentasContablePersonaComponent,
    DialogCuentaContablePersonaComponent,
    BuscarCuentaContableComponent,
  ],
  providers: [
  ],
})

export class CuentasContableModule { }
