import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginRequest } from '@models/login/login-model.model copy';
import { TokenModel } from '@models/login/token-model.model';
import { LoginService } from '@services/login/login.service';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model'
import { Router } from '@angular/router';
import { UsuariosService } from '@services/usuarios/usuarios.service';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { Roles } from '@models/base/identificadores.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-iniciar-sesion',
  templateUrl: './iniciar-sesion.component.html',
  styleUrls: ['./iniciar-sesion.component.css']
})
export class IniciarSesionComponent implements OnInit {
  submitted = false;
  loginForm: FormGroup;
  tokenModel: TokenModel = new TokenModel
  loginRequest: LoginRequest = new LoginRequest
  public personaDetailDto: PersonaDetailDto = new PersonaDetailDto;
  UsuarioDto: UsuarioDto = new UsuarioDto;
  idPersona: number = 0;
  isLoading: boolean = false;
  loginTrue: boolean = false;
  formBuilder: FormBuilder = new FormBuilder;
  get f() { return this.loginForm.controls; }
  modoRecuperacion = false;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private userService: UsuariosService,
    private snackBar: SnackBarService
  ) {

    this.loginForm = this.formBuilder.group({
      Usuario: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      Clave: ['', Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
  }

  loguearse() {
    this.isLoading = true;
    this.submitted = true;

    if (this.loginForm.invalid) {
      this.snackBar.showWarn("Formulario invalido.", "Alerta")
      return;
    }

    if (this.loginForm.valid == true) {
      this.submitted = true;
      this.loginRequest.userName = this.loginForm.controls["Usuario"].value
      this.loginRequest.password = btoa(this.loginForm.controls["Clave"].value)//Encripta en base64

      this.loginService.login(this.loginRequest)
        .subscribe(
          tokenModel => {
            this.isLoading = false
            this.loginTrue = true
            this.recibirToken(tokenModel)
            this.obtenerUsuario(this.loginRequest.userName)
          },
          error => {
            this.isLoading = false
            this.snackBar.showError(error, "Error")
          }
        )

    }


  }

  async obtenerUsuario(userName: string) {
    await this.userService.getAllDataOfUserByUserName(userName).subscribe(
      data => {
        this.UsuarioDto = data

        if (this.UsuarioDto.estado == true || this.UsuarioDto.id == 0) {
          this.loginService.logout()
          this.router.navigate(['/login'])
          this.snackBar.showWarn("Usuario dado de baja.", "Error")
        }
        else {
          this.loginService.setCurrentUser(data)
          if (this.UsuarioDto.changePassword == true) {
            this.router.navigate(['/perfilUsuarios'])
            this.snackBar.showInfo("Asegurese de completar todos los datos personales y cambiar su contraseña.", "Info")
          }
          else {
            if (this.loginService.getIdUserRol() == Roles.Clientes) {
              this.router.navigate(['/miPanel'])
            }
            else
            {
              this.router.navigate(['/dashboard'])
            }

          }
        }
      })
  }
  recibirToken(tokenModel: TokenModel) {
    if (tokenModel != null) {
      this.loginService.setToken(tokenModel);
    }
  }
}

