import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateTipoContratoQuienPagaServicioRequest } from '@models/tipocontrato-quienpaga-servicio/createTipoContratoQuienPagaServicioRequest.model';
import { TipoContratoQuienPagaServicioDto } from '@models/tipocontrato-quienpaga-servicio/tipoContratoQuienPagaServicioDto.model';
import { UpdateTipoContratoQuienPagaServicioRequest } from '@models/tipocontrato-quienpaga-servicio/updateTipoContratoQuienPagaServicioRequest.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipocontratoQuienPagaServicioService } from '@services/tipocontrato-quienpaga-servicio/tipocontrato-quien-paga-servicio.service';
import { DialogTipocontratoQuienpagaServicioComponent } from '../dialog-tipocontrato-quienpaga-servicio/dialog-tipocontrato-quienpaga-servicio.component';

export class ViewnObjet {
  id!: number
  descripcion!: string
  quienPaga!: string
  idQuienPaga!: number
  servicio!: string
  idServicio!: number
  tipoContrato!: string
  idTipoContrato!: number
}
@Component({
  selector: 'app-tipocontrato-quienpaga-servicio',
  templateUrl: './tipocontrato-quienpaga-servicio.component.html',
  styleUrls: ['./tipocontrato-quienpaga-servicio.component.css']
})
export class TipocontratoQuienpagaServicioComponent implements OnInit {

  dataSource = new MatTableDataSource<ViewnObjet>();
  data = true
  displayedColumns: string[] = [];

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  constructor(
    private dialog: MatDialog,
    private tipocontratoQuienPagaServicioService: TipocontratoQuienPagaServicioService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
  ) {

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["tipoContrato", "quienPaga", "servicio", "descripcion", "accion"] :
        ["tipoContrato", "quienPaga", "servicio", "descripcion", "accion"];
    });
  }

  ngOnInit(): void {
    this.getAll()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAll() {
    this.spinner.show()
    this.tipocontratoQuienPagaServicioService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          let lstViewnObjet: ViewnObjet[] = []
          data.forEach(element => {
            let objet = new ViewnObjet
            objet.id = element.id
            objet.descripcion = element.descripcion
            objet.idQuienPaga = element.quienPaga.id
            objet.quienPaga = element.quienPaga.descripcion
            objet.servicio = element.servicio.descripcion
            objet.idServicio = element.servicio.id
            objet.tipoContrato = element.tipoContrato.descripcion
            objet.idTipoContrato = element.tipoContrato.id
            lstViewnObjet.push(objet)
          });
          this.dataSource.data = lstViewnObjet
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogTipocontratoQuienpagaServicioComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.addRowData(result.data);
      } else if (result.event == AccionesDialog.Modificar) {
        this.updateRowData(result.data);
      } else if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }
  updateRowData(updateTipoContratoQuienPagaServicioRequest: UpdateTipoContratoQuienPagaServicioRequest) {
    this.spinner.show()
    this.tipocontratoQuienPagaServicioService.update(updateTipoContratoQuienPagaServicioRequest)
      .subscribe(
        async data => {
          this.spinner.hide()
          await this.getAll()
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }
  addRowData(createTipoContratoQuienPagaServicioRequest: CreateTipoContratoQuienPagaServicioRequest) {
    this.spinner.show()
    this.tipocontratoQuienPagaServicioService.create(createTipoContratoQuienPagaServicioRequest)
      .subscribe(
        async data => {
          this.spinner.hide()
          await this.getAll()
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  deleteRowData(row_obj: { id: number; }) {
    this.tipocontratoQuienPagaServicioService.delete(row_obj.id)
      .subscribe(
        async data => {
          this.spinner.hide()
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          await this.getAll()
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError("El registro no se puede eliminar porque tiene referencias asignadas.", "Error");
        })
  }


  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

}


