<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
<h1 mat-dialog-title>Confirmar acción</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">Esta seguro que desea eliminar este item?</div>
<mat-divider></mat-divider>
<div mat-dialog-actions class="mt-15">
    <button mat-raised-button color="primary" *appRole="['Propiedades.Eliminar']" [mat-dialog-close]="true" (click)=eliminar() cdkFocusInitial>Si</button>
    <button mat-raised-button color="warn" mat-dialog-close>No</button>
</div>
