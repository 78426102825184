import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { AccionesDialog } from '@models/base/identificadores.model';
import { RolDto } from '@models/seguridad/roles/rolDto.model';
import { RolesService } from '@services/seguridad/roles/roles.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { DialogUpdateMenuComponent } from '@views/seguridad/menus/actualizar-menu/dialog-update-menu/dialog-update-menu.component';
import { DialogCreateMenuComponent } from '@views/seguridad/menus/crear-menu/dialog-create-menu/dialog-create-menu.component';
import { DialogViewMenuComponent } from '@views/seguridad/menus/ver-menu/dialog-view-menu/dialog-view-menu.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogUpdateRolComponent } from '../actualizar-rol/dialog-update-rol/dialog-update-rol.component';
import { DialogCreateRolComponent } from '../crear-rol/dialog-create-rol/dialog-create-rol.component';
import { DialogDeleteRolComponent } from '../eliminar-rol/dialog-delete-rol/dialog-delete-rol.component';
import { DialogViewRolComponent } from '../ver-rol/dialog-view-rol/dialog-view-rol.component';

@Component({
  selector: 'app-listado-rol',
  templateUrl: './listado-rol.component.html',
  styleUrls: ['./listado-rol.component.css']
})
export class ListadoRolComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private rolesService: RolesService
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['descripcion', 'accion'] :
        ['descripcion', 'accion'];
    });

  }

  data = false
  dataSource = new MatTableDataSource<RolDto>();
  obj!: undefined
  displayedColumns: string[] = [];

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  ngOnInit() {
    this.getAllRoles();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openDialogCreate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "30%"
    const dialogRef = this.dialog.open(DialogCreateRolComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.getAllRoles();
      }
    });
  }

  openDialogUpdate(rolDto:RolDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "30%"
    dialogConfig.data = rolDto
    const dialogRef = this.dialog.open(DialogUpdateRolComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Modificar) {
        this.getAllRoles();
      }
    });
  }

  openDialogDelete(rolDto:RolDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "30%"
    dialogConfig.data = rolDto
    const dialogRef = this.dialog.open(DialogDeleteRolComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.getAllRoles();
      }
    });
  }

  openDialogVer(rolDto:RolDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "30%"
    dialogConfig.data = rolDto
    const dialogRef = this.dialog.open(DialogViewRolComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Ver) {
        this.getAllRoles();
      }
    });
  }

  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
}
  }

  getAllRoles() {
    this.spinner.show()
    this.rolesService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

}
