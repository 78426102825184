import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCreateDetalleServicioComponent } from '../dialog-create-detalle-servicio/dialog-create-detalle-servicio.component';
@Component({
  selector: 'app-detalle-servicio-propiedad',
  templateUrl: './detalle-servicio-propiedad.component.html',
  styleUrls: ['./detalle-servicio-propiedad.component.css']
})
export class DetalleServicioPropiedadComponent implements OnInit {
  idPropiedad!: any;
  sub: any;
  update:boolean= false

  constructor(
    private dialog:MatDialog,
    private aroute:ActivatedRoute
  ) { }

  ngOnInit(): void {
     this.sub = this.aroute.params.subscribe(params => {
       this.idPropiedad = +params['id'];
    });
  }

  

}
