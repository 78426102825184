import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateAsuntoRequest } from '@models/asunto/createAsuntoRequest.model';
import { UpdateAsuntoRequest } from '@models/asunto/updateAsuntoRequest.model';
import { isFalsy } from 'utility-types';
import { CreateResponseBase } from '@models/base/createResponseBase.model';

@Injectable({
  providedIn: 'root'
})
export class AsuntosService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http:HttpClient)  { }


  create(createAsuntoRequest:CreateAsuntoRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/Asunto/Create",createAsuntoRequest);
  }

  update(updateAsuntoRequest:UpdateAsuntoRequest):Observable<any>
  {
    return  this.http.put<any>("/api/Asunto/Update",updateAsuntoRequest);
  }

  getAll():Observable<BaseDto[]>
  {
    return  this.http.get<BaseDto[]>("/api/Asunto/GetAll");
  }

  getById(id:number):Observable<BaseDto>
  {
    return  this.http.get<BaseDto>("/api/Asunto/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Asunto/Delete?id=" + id);
    else
      return of(null)
  }
}
