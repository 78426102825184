import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicioDto } from '@models/servicios/servicio/servicioDto.model';
import { UpdateServicioRequest } from '@models/servicios/servicio/updateServicioRequest.model';
import { TipoServicio } from '@models/servicios/tipo-servicios/tipoServicioDto.model';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { TipoServiciosService } from '@services/servicios/tipos-servicios/tipo-servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-form-servicio-update',
  templateUrl: './form-servicio-update.component.html',
  styleUrls: ['./form-servicio-update.component.css']
})
export class FormServicioUpdateComponent implements OnInit {

  formBuilder: FormBuilder = new FormBuilder;
  lstTiposServicio: TipoServicio[] = []
  errorMessage: string = "";
  updateServicioRequest: UpdateServicioRequest = new UpdateServicioRequest;
  servicioForm!: FormGroup;
  disableNroBroche: any;
  get f() { return this.servicioForm.controls; }
  idServicio!: any
  servicioDto!: ServicioDto

  constructor(
    private serviciosService: ServiciosService,
    private tipoServiciosService: TipoServiciosService,
    private snackBar: SnackBarService,
    private aRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {

    this.servicioForm = this.formBuilder.group({
      //Persona
      TiposServicio: ["", Validators.compose([Validators.required,])],
      Broche: [''],
      NroBroche: ['', Validators.compose([Validators.maxLength(50)])],
    })
  }

  ngOnInit(): void {
    this.getAllTipoServicios()
    this.idServicio = this.aRoute.snapshot.paramMap.get('id');
    if (this.idServicio > 0) {
      this.getServicioById(this.idServicio);
    }
  }

  habilitaNroBroche(evet: any) {
    this.disableNroBroche = evet
  }


  onReset() {
    this.servicioForm.reset();
  }

  onSubmit() {
    if (this.servicioForm.invalid) {
      return;
    }
    this.setServicioModel()
    this.updateServicio()
  }

  setServicioModel() {

    this.updateServicioRequest = new UpdateServicioRequest();

    this.updateServicioRequest.id = this.idServicio
    this.updateServicioRequest.id_TipoServicio = this.servicioForm.controls['TiposServicio'].value;
    let nroBroche = this.servicioForm.controls['NroBroche'].value;
    if (nroBroche === "0" || nroBroche === "" || !nroBroche) {
      this.updateServicioRequest.nroBroche = "0"
      this.updateServicioRequest.broche = false
    }
    else {
      this.updateServicioRequest.nroBroche = nroBroche
      this.updateServicioRequest.broche = true
    }
  }

  getServicioById(idServicio: number) {
    this.spinner.show()
    this.serviciosService.GetById(idServicio)
      .subscribe(data => {
        this.spinner.hide()
        this.servicioDto = data
        this.setServicioFormulario(data)
      },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        });
  }

  setServicioFormulario(servicioDto: ServicioDto) {

    this.idServicio = servicioDto.id
    this.servicioForm.controls['TiposServicio'].setValue(servicioDto.tipoServicio.id);
    this.servicioForm.controls['Broche'].setValue(servicioDto.broche);
    this.servicioForm.controls['NroBroche'].setValue(servicioDto.nroBroche);

  }

  updateServicio() {
    this.spinner.show()
    this.serviciosService.update(this.updateServicioRequest)
      .subscribe(data => {
        this.spinner.hide()
        this.snackBar.showSuccess("Servicio actualizado correctamente.", "Exito");
        this.router.navigate(["/servicios"])
      },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        });
  }

  getAllTipoServicios() {
    this.spinner.show()
    this.tipoServiciosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.lstTiposServicio = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  goBack() {
    window.history.back();
  }

  trackByItems(index: number, item: any): any { return item; }
}
