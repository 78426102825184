import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GarantiaSummaryDto } from '@models/garantia/garantiaSummaryDto.model';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { GarantiasService } from '@services/garantias/garantias.service';
import { LoginService } from '@services/login/login.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { AccionesDialog } from '@models/base/identificadores.model';
import { FormOnlyPersonaUpdateComponent } from '@views/personas/form-only-persona-update/form-only-persona-update.component';
import { DialogFormOnlyPersonaUpdateComponent } from '@views/personas/dialog-form-only-persona-update/dialog-form-only-persona-update.component';

@Component({
  selector: 'app-garantias-inquilino-public',
  templateUrl: './garantias-inquilino-public.component.html',
  styleUrls: ['./garantias-inquilino-public.component.css']
})
export class GarantiasInquilinoPublicComponent implements OnInit {
  data = false
  @Input() idPersonaInquilino: any
  idPersonaLocal!: number;
  dataSource = new MatTableDataSource<GarantiaSummaryDto>();
  displayedColumns: string[] = [];
  myTrackById: any;
  personaDetailDto!: PersonaDetailDto;
  user!: UsuarioDto;
  inquilinoDto!: InquilinoDto;

  constructor(
    private dialog: MatDialog,
    private garantiaService: GarantiasService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private personasService: PersonasService,
    private inquilinosService: InquilinosService,
    private router: Router,
    private loginService: LoginService
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["persona", "tipoGarantia", "validacion", "accion"] :
        ["persona", "tipoGarantia", "nroFolio", "razonSocial", "cuitCuil", "sueldo", "validacion", "accion"]
    });
  }

  ngOnInit(): void {

   this.user = this.loginService.getCurrentUser()
    this.getAllGarantias(this.user.persona.id)
    this.getInquilinoByIdPersona(this.user.persona.id)
  }

  editarGarantia(garantia: GarantiaSummaryDto) {
    this.garantiaService.setDatos(garantia); // Enviar objeto
    this.router.navigate(['/garantias/editar'])
  }
  verGarantia(garantia: GarantiaSummaryDto) {
    this.garantiaService.setDatos(garantia); // Enviar objeto
    this.router.navigate(['/garantias/ver'])
  }
  eliminarGarantia(garantia: GarantiaSummaryDto) {
    this.garantiaService.setDatos(garantia); // Enviar objeto

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = garantia

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.eliminarGarantiaById(garantia.id)
      }
    });

  }
  ngOnChange() {
  }

  eliminarGarantiaById(idPersona: number) {
    this.spinner.show()
    this.garantiaService.delete(idPersona)
      .subscribe(
        data => {
          this.getAllGarantias(this.user.persona.id)
          this.spinner.hide()
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getAllGarantias(idPersona: number) {
    this.spinner.show()
    this.garantiaService.getAllGarantiasByInquilino(idPersona)
      .subscribe(
        data => {
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.spinner.hide()
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getInquilinoByIdPersona(idPersona: number) {
    this.spinner.show()
    this.inquilinosService.getInquilinoByIdPersona(idPersona)
      .subscribe(
        data => {
          this.inquilinoDto = data
          this.spinner.hide()
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  editaPersonaGarante(idPersona: number) {
    this.getPersonaById(idPersona)
  }

  openDialog(data:PersonaDetailDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = data
 dialogConfig.width = "90%"
    dialogConfig.height = "90%"

    const dialogRef = this.dialog.open(DialogFormOnlyPersonaUpdateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getPersonaById(idPersona: number) {
    this.spinner.show("spPersona")
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.personasService.setPersona(data)
          //this.router.navigate(["/personas/editar"])
          this.openDialog(data)
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        });
  }
}
