import { BaseDto } from "../base/baseDto.model";

export class ArchivoDto  extends BaseDto implements IArchivoDto{
    public content:string= "";
    public fechaAlta:Date = new Date ;
    public encriptado:boolean= false;
    public contentType: string = "";
    public nombre: string = "";
}

interface IArchivoDto{
  content:string;
  fechaAlta:Date;
  encriptado:boolean;
  contentType: string;
  nombre: string;
}

