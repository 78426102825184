import { Component, OnInit } from '@angular/core';
//Servicios
import { PersonasService } from '@services/personas/personas.service';
import { EstadosCivilService } from "@services/estados-civil/estados-civil.service";
import { TiposDocumentoService } from "@services/tipos-documento/tipos-documento.service";
//Models
import { EstadoCivilDto } from "@models/estado-civil/estadoCivilDto.model";
import { TipoDocumentoDto } from "@models/tipo-documento/tipoDocumentoDto.model";
import { CreatePersonaRequest } from "@models/personas/createPersonaRequest.model"
//
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router';
import { forkJoin, Observable, Subject } from 'rxjs';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
//VAlidaciones
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { PaisService } from '@services/pais/pais.service';
import { TiposDocumentos } from '@models/base/identificadores.model';
import { isFalsy } from 'utility-types';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'app-form-persona-create',
  templateUrl: './form-persona-create.component.html',
  styleUrls: ['./form-persona-create.component.css']
})
export class FormPersonaCreateComponent implements OnInit {


  titulo: string = '';
  subMenuActive: boolean = false;
  stringObject: any;
  stringJson: any;
  personaForm!: FormGroup
  public personaDetailDto: PersonaDetailDto = new PersonaDetailDto;
  lstEstadosCivilModel: EstadoCivilDto[] = [];
  lstTipoDocumentoDto: TipoDocumentoDto[] = [];
  lstPaises: BaseDto[] = [];
  localidad!: BaseDto
  submitted = false;
  parametro: string = '';
  idTipoDocumento: number = 0;
  idLocalidad: number = 0;
  idEstadoCivil: number = 0;
  idPersona: number = 0;
  valor: any
  errorMessage: string = "";
  accordionList: any;
  formBuilder: FormBuilder = new FormBuilder;

  get f() { return this.personaForm.controls; }

  localidadSelec: number = 0;
  formLocalidad: FormControl = new FormControl("", Validators.compose([Validators.required]));
  filteredOptions?: Observable<any[]>;


  constructor(
    private personasService: PersonasService,
    private tipoDocumentoService: TiposDocumentoService,
    private estadoCivilService: EstadosCivilService,
    private paisService: PaisService,
    private router: Router,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {

    this.personaForm = this.formBuilder.group({
      //Persona
      NombreCompleto: ["", Validators.compose([Validators.required, Validators.maxLength(50)])],
      ApellidoMaterno: ['', Validators.compose([Validators.maxLength(50)])],
      NroDocumento: ['',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
        this.validaPersonaByNroDocYTipoDoc.bind(this)],
      TelefonoPrincial: ['', Validators.compose([Validators.maxLength(20)])],
      TelefonoAlternativo: ['', Validators.compose([Validators.maxLength(20)])],
      TelefonoLaboral: ['', Validators.compose([Validators.maxLength(20)])],
      Sexo: ['', Validators.compose([Validators.required])],
      Correo: ['', [Validators.required, Validators.email, Validators.maxLength(50)], [this.personasService.validateEmailNotTaken()]], // Agrega el validador asíncrono

      //Correo: ['', [Validators.required, Validators.email, Validators.maxLength(50)]], // Agrega el validador asíncrono

      FechaNacimiento: [''],
      Ubicacion: ['', Validators.compose([Validators.maxLength(50)])],
      UbicacionLaboral: ['', Validators.compose([Validators.maxLength(50)])],
      Pais: [''],
      CodigoCpa: ['', Validators.compose([Validators.maxLength(8)])],
      EstadoCivil: ['', Validators.compose([Validators.required])],
      TiposDocumento: ['', Validators.compose([Validators.required])],
      Ocupacion: ['', Validators.compose([Validators.maxLength(50)])],
      Observacion: ['', Validators.compose([Validators.maxLength(2000)])]
    },
      {
        //validator:tipoDocNroDocValidator(this.idPersona,"TiposDocumento","NroDocumento",personasService)
      });


  }

  ngOnInit(): void {
    this.idPersona = 0
    this.spinner.show("spPersonaCombo")
    forkJoin({
      paises: this.paisService.getAll(),
      estadosCivil: this.estadoCivilService.getAll(),
      tiposDocumentos: this.tipoDocumentoService.getAll()
    })
      .subscribe(
        ({ paises, estadosCivil, tiposDocumentos }) => {

          this.lstPaises = paises;
          this.lstEstadosCivilModel = estadosCivil
          this.lstTipoDocumentoDto = tiposDocumentos
          this.spinner.hide("spPersonaCombo")

        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spPersonaCombo")
        });



    this.setDefault()
  }

  validaDocumentosSegunTipoDocumento(){
    this.personaForm.controls["TiposDocumento"].valueChanges.subscribe(valor => {
      //Obtenemos el control ya instanciado en el formulario.
      let tiposDocumentoControl = this.personaForm.controls["TiposDocumento"];

      //Quitamos todas las validaciones del control.
      //tiposDocumentoControl.clearValidators();

      //Agregamos la validacion segun el caso:
      switch (valor) {
        case TiposDocumentos.DNI:
          //Se agregan de nuevo todas las validaciones que necesites.
          //En este caso solo agregue la que me parecio oportuna.
          tiposDocumentoControl.setValidators([Validators.pattern("^[0-9]*$")]);
          break;
        case TiposDocumentos.PASS:
          //En el caso del alfanumerico no es necesario aplicar
          // validaciones. Si necesitas un required aqui lo debes poner.
          break;
      }

      //Para evitar problemas con la validacion marcamos el campo con
      // dirty, de esta manera se ejecutan de nuevo las validaciones
      tiposDocumentoControl.markAsDirty()
      //Recalculamos el estado del campo para que cambie el estado
      // del formulario.
      tiposDocumentoControl.updateValueAndValidity()
    });
  }

  setDefault() {
    this.personaForm.controls["NroDocumento"].disable();
    this.personaForm.controls["Pais"].setValue(11);
  }

  selectTipoDocumento(event: any) {
    this.idTipoDocumento = event
    if (this.idTipoDocumento != 0) {
      this.personaForm.controls["NroDocumento"].enable();
      this.personaForm.controls["NroDocumento"].setValue("");
    }
    else
      this.personaForm.controls["NroDocumento"].disable();

  }

  seleccionaLocalidad(data: BaseDto) {
    this.localidad = data
  }

  onSubmit() {
    this.submitted = true;
    if (this.personaForm.invalid) {
      return;
    }
    const createPersonaRequest: CreatePersonaRequest = this.setPersonaDetailDto();
    this.spinner.show("spPersona")
    this.personasService.addPersona(createPersonaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.idPersona = data.idPersona
          this.snackBar.showSuccess(this.personaForm.value['NombreCompleto'] +
            ' se registro correctamente.', "");
          this.getPersonaById(data.idPersona)
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        })
  }

  getPersonaById(id: number) {
    this.personasService.getPersonaById(id)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        })
  }

  async getPersonaByCorreo(correoPersona: string): Promise<boolean> {
    let correoValidoSubject = new Subject<boolean>();
    this.spinner.show("spPersonaCorreo");

    this.personasService.getPersonaByCorreo(correoPersona)
      .subscribe(
        data => {
          this.spinner.hide("spPersonaCorreo");
          if (data) {
            correoValidoSubject.next(true);
          } else {
            correoValidoSubject.next(false);
          }
          correoValidoSubject.complete();
        },
        error => {
          this.spinner.hide("spPersonaCorreo");
          this.snackBar.showError(error, "Error");
          correoValidoSubject.next(false);
          correoValidoSubject.complete();
        }
      );

    return new Promise<boolean>((resolve, reject) => {
      correoValidoSubject.subscribe({
        next: (value) => resolve(value),
        error: (err) => reject(err)
      });
    });
  }



  setPersonaDetailDto(): CreatePersonaRequest {

    const createPersonaRequest = new CreatePersonaRequest();

    var fecha = this.personaForm.controls['FechaNacimiento'].value.split('-');
    var fechaCompleta = "";
    if (fecha != "") {
      var anio = fecha[0];
      var mes = fecha[1];
      var dia = fecha[2];
      fechaCompleta = anio + "-" + mes + "-" + dia;
    }

    createPersonaRequest.nombreCompleto = this.personaForm.controls['NombreCompleto'].value;
    createPersonaRequest.apellidoMaterno = this.personaForm.controls['ApellidoMaterno'].value;
    createPersonaRequest.nroDocumento = this.personaForm.controls['NroDocumento'].value;
    createPersonaRequest.id_TipoDocumento = this.personaForm.controls['TiposDocumento'].value;
    createPersonaRequest.id_EstadoCivil = this.personaForm.controls['EstadoCivil'].value;
    createPersonaRequest.telefonoPrincial = this.personaForm.controls['TelefonoPrincial'].value
    createPersonaRequest.telefonoAlternativo = this.personaForm.controls['TelefonoAlternativo'].value
    createPersonaRequest.telefonoLaboral = this.personaForm.controls['TelefonoLaboral'].value
    createPersonaRequest.sexo = this.personaForm.controls['Sexo'].value;
    createPersonaRequest.correo = this.personaForm.controls['Correo'].value;;
    createPersonaRequest.fechaNacimiento = fechaCompleta;
    createPersonaRequest.ubicacion = this.personaForm.controls['Ubicacion'].value;
    createPersonaRequest.ubicacionLaboral = this.personaForm.controls['UbicacionLaboral'].value;
    createPersonaRequest.idPais = this.personaForm.controls['Pais'].value;
    createPersonaRequest.id_Localidad = this.localidad.id
    createPersonaRequest.codigoCpa = this.personaForm.controls['CodigoCpa'].value
    createPersonaRequest.observacion = this.personaForm.controls['Observacion'].value
    createPersonaRequest.ocupacion = this.personaForm.controls['Ocupacion'].value
    createPersonaRequest.estado = false;

    return createPersonaRequest
  }

  onReset() {
    this.submitted = false;
    this.personaForm.reset();
  }

  goToPesonas() {
    this.submitted = false;
    this.personaForm.reset();
    this.router.navigate(["/personas"])
  }

  validaPersonaByNroDocYTipoDoc(controlNroDoc: FormControl) {
    let idTipoDocumento = this.personaForm.controls['TiposDocumento'].value;
    if (controlNroDoc.value.toString().length > 7  && !isFalsy(idTipoDocumento) ) {
      let promesa = new Promise((resolve, reject) => {
        this.spinner.show("spPersonaCombo")
        this.personasService.validaPersonaByNroDocYTipoDoc(this.idPersona, controlNroDoc.value, idTipoDocumento)
          .subscribe(data => {
            if (data == true) {
              this.spinner.hide("spPersonaCombo")
              resolve({ existePersona: true })
            }
            else {
              this.spinner.hide("spPersonaCombo")
              resolve(true)
            }
          })
      });
      //
      return promesa;
    }
    else {
      let promesa = new Promise((resolve, reject) => {
        resolve(true)
      });
      return promesa;
    }
  }

  isFalsy(valor:any){
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
