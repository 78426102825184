import * as moment from "moment";
import { BaseDto } from "../base/baseDto.model";
import { GarantiaSummaryDto } from "../garantia/garantiaSummaryDto.model";
import { InquilinoDto } from "../inquilino/inquilinoDto.model";
import { PropietarioDto } from "../propietarios/propietarioDto.model";
import { TipoMonedaDto } from "../tipo-moneda/tipoMonedaDto.model";
import { TipoPunitorioDto } from "../tipo-punitorio/tipoPunitorioDto.model";
import { TipoSelladoDto } from "../tipo-sellado/tipoSelladoDto.model";
import { PeriodoContratoDetailDto } from "../periodos-contrato/periodoContratoDetailDto.model";
import { PropiedadSummaryDto } from "@models/propiedades/propiedadSummaryDto.model";
import { TipoAjusteContratoDto } from "@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model";

export class ContratoDetailDto {
  public id!: number;
  public inquilino!: InquilinoDto;
  public tomo!:string;
  public folio!: string;
  public numero!:string;

  public propiedad!: PropiedadSummaryDto;
  public fechaComienzo!: string;
  public fechaVencimiento!: string;
  public fechaResicion!: string;
  public diaVencimientoPago!: string;
  public tipoReciboInquilino!: BaseDto;
  public tipoReciboPropietario!: BaseDto;
  public tipoMonedaMontoContrato!: TipoMonedaDto;
  public tipoMontoAdministrativo!: BaseDto;
  public montoAdministrativo!: number;
  public planDePago!: number;
  public montoPunitorio!: number;
  public tipoPunitorio!: TipoPunitorioDto;
  public tipoMontoPunitorio!: BaseDto;
  public montoSellado!: number;
  public tipoSellado!: TipoSelladoDto;
  public tipoMontoSellado!: BaseDto;
  public tipoContrato!: BaseDto;
  public estadoContrato!: BaseDto;
  public firmado: boolean = false;
  public periodosContratos!: PeriodoContratoDetailDto[];
  public garantias!: GarantiaSummaryDto[];
  public propietarios!: PropietarioDto[];
  public porcentajeDiasCompletados: number;
  public porcentajeComisionPropietario!: number;
  public tipoLiquidacion!: BaseDto;
  public numeroContrato!: number;
  public formaPagoPropietario!: BaseDto;
  public frecuenciaAjusteMensual!: number;
  public montoTotalSellado!: number;
  public montoTotalHonorario!: number;
  public montoTotalContrato!: number;
  public tipoAjusteContrato!: TipoAjusteContratoDto
  public fechaModificacion!: string
  public fechaCreacion!: string

  constructor() {
    this.porcentajeDiasCompletados = this.calcularPorcentajeDiasCompletados()
  }

  calcularPorcentajeDiasCompletados(): number {
    const fechaComienzo = moment(new Date(this.fechaComienzo))
    const fechaVencimiento = moment(new Date(this.fechaVencimiento))
    const fechaHoy = moment(new Date())

    const x = fechaVencimiento.diff(fechaComienzo, 'days')
    const y = fechaVencimiento.diff(fechaHoy, 'days')
    const dif = (y * 100) / x

    return dif
  }
}
