import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared.module';
import { DialogTipoCondicionContableComponent } from '../views/tipos-condicion-contable/dialog-tipo-condicion-contable/dialog-tipo-condicion-contable.component';
import { TiposCondicionContableComponent } from '../views/tipos-condicion-contable/tipos-condicion-contable/tipos-condicion-contable.component';
import { BtnNuevoTipoCondicionContableComponent } from '../views/tipos-condicion-contable/btn-nuevo-tipo-condicion-contable/btn-nuevo-tipo-condicion-contable.component';



@NgModule({
  declarations: [
    BtnNuevoTipoCondicionContableComponent,
    DialogTipoCondicionContableComponent,
    TiposCondicionContableComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[
    BtnNuevoTipoCondicionContableComponent,
    DialogTipoCondicionContableComponent,
    TiposCondicionContableComponent,
  ]

})
export class TipoCondicionContableModule { }
