<button mat-icon-button class="close-button" [disabled]="showProgressBar" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>
    <strong>Resumen</strong> recibo</h1>
    <mat-divider></mat-divider>
    <br>
<mat-dialog-content>
    <mat-card>
        <mat-card-content>
            <div class="row">
                <div class="col-lg-6">
                    <p><b>Propiedad: </b> {{data.contrato.propiedad | direccionPropiedad}}</p>
                </div>
                <div class="col-lg-6">
                    <p><b>Inquilino: </b> {{data.contrato.inquilino.persona.descripcion | titlecase}}</p>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-content>
            <table mat-table class="example-table" [dataSource]="dataSource" class="mat-elevation-z8" table>
                <!-- Periodo Column -->
                <ng-container matColumnDef="periodo">
                    <th mat-header-cell *matHeaderCellDef> Periodo </th>
                    <td mat-cell *matCellDef="let element"> {{element.periodo }} </td>
                    <td mat-cell *matCellDef="let element"> Subtotal </td>
                    <td mat-footer-cell *matFooterCellDef> Total </td>
                </ng-container>

                <!-- Tipo Movimiento Column -->
                <ng-container matColumnDef="tipoMovimiento">
                    <th mat-header-cell *matHeaderCellDef> Tipo Movimiento </th>
                    <td mat-cell *matCellDef="let element"> {{element.tipoMovimiento }} </td>
                    <td mat-cell *matCellDef="let element"> </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- Importe Column -->
                <ng-container matColumnDef="importeDebe">
                    <th mat-header-cell *matHeaderCellDef> Importe Debe</th>
                    <td mat-cell *matCellDef="let element"> {{element.importeDebe | currency}} </td>
                    <td mat-cell *matCellDef="let element"> </td>
                    <td mat-footer-cell *matFooterCellDef> {{getTotalCostDebe() | currency}} </td>
                </ng-container>

                <!-- Importe Column -->
                <ng-container matColumnDef="importeHaber">
                    <th mat-header-cell *matHeaderCellDef> Importe Haber </th>
                    <td mat-cell *matCellDef="let element"> {{element.importeHaber | currency}} </td>
                    <td mat-cell *matCellDef="let element"> </td>
                    <td mat-footer-cell *matFooterCellDef> {{getTotalCostHaber() | currency}} </td>
                </ng-container>

                <!-- Descripcion -->
                <ng-container matColumnDef="descripcion">
                    <th mat-header-cell *matHeaderCellDef> Descripción </th>
                    <td mat-cell *matCellDef="let element">
                        <button matTooltip="Ver" *ngIf="!isFalsy(element.descripcion)" mat-icon-button (click)="openDialogDescripcion(element.descripcion )">
                <mat-icon color="primary">info</mat-icon></button>
                    </td>
                    <td mat-cell *matCellDef="let element"> </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

            </table>
            Saldo: {{ getTotalSaldo() | currency}}

        </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
    <br>

</mat-dialog-content>


<mat-dialog-actions *ngIf="!showProgressBar">
    <mat-card-actions>
        <button mat-raised-button [disabled]="showProgressBar" *appRole="['Contratos.GenerarRecibo']" (click)="generarRecibo()" color="primary">
  <mat-icon>payments</mat-icon> Generar recibo</button>
        <button mat-button [disabled]="showProgressBar" (click)="closeDialog()" mat-dialog-close color="warn">Cancelar</button>
    </mat-card-actions>
</mat-dialog-actions>

<div class="row" *ngIf="showProgressBar">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2 class="example-h2">Aguarde... generando recibo.</h2>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>
