<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Acción realizada :: <strong>Eliminar</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    ¿Esta seguro que desea eliminar el registro {{local_data.descripcion != "" || null ? local_data.descripcion : ""}}?
    <br>
    <br>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="doAction()"><mat-icon>delete</mat-icon> Eliminar</button>
    <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
</div>
