import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { AdendarContratoRequest } from '@models/contrato/adendarContratoRequest.model';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { CreateContratoRequest } from '@models/contrato/createContratoRequest.model';
import { PeriodoContratoDetailDto } from '@models/periodos-contrato/periodoContratoDetailDto.model';
import { ResindirContratoRequest } from '@models/contrato/resindirContratoRequest.model';
import { isFalsy } from 'utility-types';
import { IContratosService } from './iContratos.service';
import { ContratoFilter } from '@models/contrato/contratoFilter.model';
import { ContratoBasicDto } from '@models/contrato/contratoBasicDto.model';
import { ContratoSummaryDto } from '@models/contrato/contratoSummaryDto.mode';

@Injectable({
  providedIn: 'root'
})
export class ContratosService implements IContratosService {
  headers!: Headers;
  private id!: number;
  private contratoDto!: ContratoDetailDto

  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  setContratoDto(contratoDto: ContratoDetailDto) {
    this.contratoDto = contratoDto;
  }

  getContratoDto() {
    return this.contratoDto;
  }

  constructor(
    private http: HttpClient,
  ) { }

  /**
  * !Completar todos los campos es lo mejor posible.
  * @param createContratoRequest Parametro para crear contrato.
  */
  create(createContratoRequest: CreateContratoRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Contrato/Create", createContratoRequest)
  }

  /**
   * Rescindir un contrato.
   * @param resindirContratoRequest
   * @returns
   */
  rescisionContrato(resindirContratoRequest: ResindirContratoRequest): Observable<any> {
    return this.http.patch<any>("/api/Contrato/Resindir", resindirContratoRequest)
  }

  /**
   * Adendar un contrato.
   * @param adendarContratoRequest
   * @returns
   */
  adendar(adendarContratoRequest: AdendarContratoRequest): Observable<any> {
    return this.http.patch<any>("/api/Contrato/Adendar", adendarContratoRequest)
  }

  /**
   * *Finalizar un contrato.
   * @returns
   */
  endContratos(): Observable<any> {
    return this.http.patch<any>("/api/Contrato/EndContratos", {})
  }

  /**
   * *Obtener contrato por Id de Contrato
   * @param id
   * @returns
   */
  getById(id: Number): Observable<ContratoDetailDto> {
    return this.http.get<ContratoDetailDto>("/api/Contrato/GetById?id=" + id)
  }

  /**
   * *Obtener todos los contratos
   * @returns
   */
  getAll(): Observable<ContratoDetailDto[]> {
    return this.http.get<ContratoDetailDto[]>("/api/Contrato/GetAll")
  }

  /**
   * *Eliminar contrato por Id de Contrato
   * @param id
   * @returns
   */
  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Contrato/Delete?id=" + id);
    else
      return of(null)
  }

  /**
   * Obtener listado de contratos por Id de inquilino o Id de Propiedad
   * @param idInquilino
   * @param idPropiedad
   * @returns
   */
  getAllByIdInquilinoAndIdPropiedad(idInquilino: number, idPropiedad: number): Observable<ContratoDetailDto[]> {
    return this.http.get<ContratoDetailDto[]>("/api/Contrato/GetAllByIdInquilinoAndIdPropiedad?idInquilino=" + idInquilino + "&idPropiedad=" + idPropiedad)
  }

  /**
 * Obtener listado de contratos por Id de inquilino
 * @param idInquilino
 * @param idEstado
 * @returns
 */
  getAllByIdInquilino(idInquilino: number, idEstado: number): Observable<ContratoDetailDto[]> {
    return this.http.get<ContratoDetailDto[]>("/api/Contrato/GetAllByIdInquilino?idInquilino=" + idInquilino + "&idEstado=" + idEstado)
  }

  /**
* Obtener listado de contratos por Id de propietario
* @param idPropietario
* @param idEstado
* @returns
*/
  getAllByIdPropietario(idPropietario: number, idEstado: number): Observable<ContratoDetailDto[]> {
    return this.http.get<ContratoDetailDto[]>("/api/Contrato/GetAllByIdPropietario?idPropietario=" + idPropietario + "&idEstado=" + idEstado)
  }

  /**
   * *Obtener todos los periodos de un contrato por Id de Contrato
   * @param id_Contrato
   * @returns
   */
  getPeriodosContratoByIdContrato(id_Contrato: Number): Observable<PeriodoContratoDetailDto[]> {
    return this.http.get<PeriodoContratoDetailDto[]>("/api/Contrato/GetPeriodosContratoByIdContrato?id_Contrato=" + id_Contrato)
  }
  /**
   * Obtener un listado de contratos resumido. Utilizado para busquedas.
   * @returns
   */
  getAllContratosSummary(idEstadoContrato:number): Observable<BaseDto[]> {
    return this.http.get<BaseDto[]>("/api/Contrato/GetAllContratosSummary?idEstadoContrato="+idEstadoContrato)
  }


  /**
   * Filtrar listado de contrato.
   * @param contratoFilter
   * @returns
   */
  getAllByFilter(contratoFilter: ContratoFilter): Observable<ContratoDetailDto[]> {
    return this.http.post<ContratoDetailDto[]>("/api/Contrato/GetAllContratoDetailDtoByFilter", contratoFilter)
  }
 /**
  * Obtiene listado de contratos por actualizar indice de ajuste.
  * @returns
  */
  getAllContratoPorActualizarIndice(): Observable<ContratoSummaryDto[]> {
    return this.http.get<ContratoSummaryDto[]>("/api/Contrato/GetAllContratoPorActualizarIndice")
  }


}


