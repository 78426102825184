<button mat-icon-button tabindex="0" class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>

<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">

    <form [formGroup]="form">
        <div class="row">
            <div class="col-lg-12">
                <mat-form-field class="full-width-x100">
                    <ngx-spinner [zIndex]=100 name="spBusqueda" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Bonificación</mat-label>
                    <mat-select tabindex="1" cdkFocusInitial placeholder="Seleccione opcion" formControlName="Bonificacion">
                        <mat-option [value]="bonificacion" *ngFor="let bonificacion of lstBonificacion;let i=index;trackBy:trackByItems">
                            {{bonificacion.detalle}} - {{isFalsy(bonificacion.tipoBonificacion) ? "" : bonificacion.tipoBonificacion.descripcion}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.Bonificacion.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <mat-form-field>
                    <mat-label>Importe</mat-label>
                    <span matPrefix>$ &nbsp;</span>
                    <input tabindex="2" type="text" mask="separator.2" thousandSeparator="," placeholder="Agregar importe" formControlName="Importe" matInput>
                    <mat-error *ngIf="f.Importe.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-6">
                <mat-form-field>
                    <mat-label>Descripción</mat-label>
                    <input tabindex="3" type="text" formControlName="Descripcion" matInput>
                </mat-form-field>
            </div>
        </div>
    </form>
    <ng-template #elseTemplate>
        ¿Esta seguro que desea eliminar el registro <b>{{local_data.descripcion}}</b>?
    </ng-template><br>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" tabindex="4" (click)="doAction()" [disabled]="!form.valid">{{action}}</button>
    <button mat-button (click)="closeDialog()" tabindex="5" color="warn">Cancelar</button>
</div>
