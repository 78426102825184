<div *appRoll="['MisGarantias.Ver']">
  <ngx-spinner name="spListadogarantias" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>

<table mat-table [dataSource]="dataSource" matSort matSortStart="asc" [hidden]=!data class="mat-elevation-z8">
    <!-- <ng-container matColumnDef="id">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> # </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantia"> {{garantia.id}} </mat-cell>
    </ng-container> -->
    <ng-container matColumnDef="tipoGarantia">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Tipo Garantia </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantia"> {{garantia.tipoGarantia}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="legajo">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Legajo </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantia">
            <span *ngIf="garantia.idTipoFolio == 1" #tooltip="matTooltip" matTooltipPosition="right" matTooltip="Folio Personal Cronologico">
            FPC &nbsp;
        </span>
            <span *ngIf="garantia.idTipoFolio == 2" #tooltip="matTooltip" matTooltipPosition="right" matTooltip="Folio Personal">
            FP &nbsp;
        </span>
            <span *ngIf="garantia.idTipoFolio == 3" #tooltip="matTooltip" matTooltipPosition="right" matTooltip="Sin Folio">
            SF &nbsp;
        </span>
            <mat-icon *ngIf="garantia.idTipoFolio == 1 || garantia.idTipoFolio == 1" [matTooltip]="'Tomo: ' + garantia.tomo + '\n Folio: '+ garantia.nroFolio + '\n Nùmero: '+ garantia.numero " [matTooltipClass]="'allow-cr'">info</mat-icon>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="razonSocial" mat-sort-header>
        <mat-header-cell mat-header-cell *matHeaderCellDef> Razon Social </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantia"> {{garantia.razonSocial}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="cuitCuil">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Cuit-Cuil </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantia"> {{garantia.cuitCuil}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="sueldo">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Sueldo </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantia"> {{garantia.sueldo | currency}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="toggle">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Valida </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantia">
            <mat-slide-toggle matTooltip="Validar garantia" [matTooltipDisabled]="garantia.activo" matTooltipPosition="right" [checked]="garantia.activo" (change)="actualizaActivo(garantia)"></mat-slide-toggle>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="accion">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Acción</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantia">
            <button mat-icon-button color="primary" aria-label="Example icon button with a filter list icon">
                <a (click)="editarGarantia(garantia)" matTooltip="Editar"
                matTooltipPosition="right"><mat-icon>edit</mat-icon></a>
              </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>

</table>
<div class="no-data-table">
    <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
</div>
<mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
</mat-paginator>
</div>
