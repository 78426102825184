import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { PeriodoContratoDetailDto } from '@models/periodos-contrato/periodoContratoDetailDto.model';

@Component({
  selector: 'app-dialog-ver-periodo-contrato-detail-dto',
  templateUrl: './dialog-ver-periodo-contrato-detail-dto.component.html',
  styleUrls: ['./dialog-ver-periodo-contrato-detail-dto.component.css'],
})
export class DialogVerPeriodoContratoDetailDtoComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<DialogVerPeriodoContratoDetailDtoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public periodoContratoDetail: PeriodoContratoDetailDto) {
  }


  closeDialog(){
    this.dialogRef.close({event:AccionesDialog.Cancelar});
  }

  ngOnInit(): void {
  }
}

