import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CantidadTipoGarantiaDto } from '@models/cantidades-tipo-garantia/cantidadTipoGarantiaDto.model';
import { CreateCantidadTipoGarantiaRequest } from '@models/cantidades-tipo-garantia/createCantidadTipoGarantiaRequest.model';
import { UpdateCantidadTipoGarantiaRequest } from '@models/cantidades-tipo-garantia/updateCantidadTipoGarantiaRequest.modle';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class CantidadesTipoGarantiaService {
  constructor(
    private http: HttpClient
  ) { }

  create(createCantidadTipoGarantiaRequest:CreateCantidadTipoGarantiaRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/CantidadTipoGarantia/Create",createCantidadTipoGarantiaRequest);
  }

  update(updateCantidadTipoGarantiaRequest:UpdateCantidadTipoGarantiaRequest):Observable<any>
  {
    return  this.http.put<any>("/api/CantidadTipoGarantia/Update",updateCantidadTipoGarantiaRequest);
  }

  getAll():Observable<CantidadTipoGarantiaDto[]>
  {
    return  this.http.get<CantidadTipoGarantiaDto[]>("/api/CantidadTipoGarantia/GetAll");
  }

  getById(id:number):Observable<CantidadTipoGarantiaDto>
  {
    return  this.http.get<CantidadTipoGarantiaDto>("/api/CantidadTipoGarantia/GetById?id="+id);
  }
  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/CantidadTipoGarantia/Delete?id=" + id);
    else
      return of(null)
  }
}
