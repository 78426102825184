<mat-card>
    <ngx-spinner name="spFromGarantia" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>

    <mat-card-subtitle>Datos garantia</mat-card-subtitle>
    <div class="row">
        <div class="form-group col-md-3">
            <form [formGroup]="formTipoGarantia">
                <mat-form-field>
                    <mat-label>Tipo de Garantia</mat-label>
                    <mat-select (selectionChange)="selectTipoGarantia($event.value)" formControlName="TipoGarantia">
                        <mat-option [value]="tipoGarantia.id" *ngFor="let tipoGarantia of lstTiposGarantiaModel;let i=index;trackBy:trackByItems ">
                            {{tipoGarantia.descripcion | titlecase}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="fG.TipoGarantia.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarPersona">
            <form [formGroup]="formPersonaGarantia">
                <mat-form-field>
                    <mat-label>Empleador</mat-label>
                    <input matInput name="Empleador" type="text " formControlName="Empleador" />
                    <mat-error *ngIf="fPer.Empleador.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>

                </mat-form-field>
            </form>
        </div>

        <div class="form-group col-md-3" *ngIf="mostrarPersona">
            <form [formGroup]="formPersonaGarantia">
                <mat-form-field class="example-full-width">
                    <mat-label>Empleador CUIT/CUIL</mat-label>
                    <input matInput name="CuitCuil" type="text" placeholder="00-00000000-0" [mask]="'00-00000000-0'" formControlName="CuitCuil" />
                    <mat-error *ngIf="fPer.CuitCuil.errors?.pattern">Formato invalido.</mat-error>
                    <mat-error *ngIf="fPer.CuitCuil.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarPersona">
            <form [formGroup]="formPersonaGarantia">
                <mat-form-field class="example-full-width">
                    <mat-label>Sueldo</mat-label>
                    <span matPrefix>$ &nbsp;</span>
                    <input matInput name="Sueldo" placeholder="0.00" type="text" mask="separator.2" thousandSeparator="," formControlName="Sueldo" />
                    <mat-error *ngIf="fPer.Sueldo.errors?.pattern">Formato invalido.</mat-error>
                </mat-form-field>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
            <form [formGroup]="formPropiedad">
                <mat-form-field>
                    <mat-label>Tipo de Folio</mat-label>
                    <mat-select (selectionChange)="selectTipoFolio($event.value)" formControlName="TipoFolio">
                        <mat-option [value]="tipoFolio.id" *ngFor="let tipoFolio of lstTiposFolios ;let i=index;trackBy:trackByItems">
                            {{tipoFolio.descripcion | titlecase}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="fG.TipoGarantia.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarPropiedad && idTipoFolio===1">
            <form [formGroup]="formPropiedad">
                <mat-form-field class="example-full-width">
                    <mat-label>Tomo</mat-label>
                    <input matInput name="Tomo" type="text" formControlName="Tomo" />
                    <mat-error *ngIf="fPro.Tomo.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>

        <div class="form-group col-md-3" *ngIf="mostrarPropiedad  && idTipoFolio===1 || idTipoFolio===2">
            <form [formGroup]="formPropiedad">
                <mat-form-field class="example-full-width">
                    <mat-label>NroFolio</mat-label>
                    <input matInput name="NroFolio" type="text" formControlName="NroFolio" />
                    <mat-error *ngIf="fPro.NroFolio.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>

        <div class="form-group col-md-3" *ngIf="mostrarPropiedad && idTipoFolio===1">
            <form [formGroup]="formPropiedad">
                <mat-form-field class="example-full-width">
                    <mat-label>Numero</mat-label>
                    <input matInput name="Numero" type="text" formControlName="Numero" />
                </mat-form-field>
            </form>
        </div>

        <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
            <form [formGroup]="formPropiedad">
                <mat-form-field class="example-full-width">
                    <mat-label>Ubicacion</mat-label>
                    <input matInput name="Ubicacion" type="text" formControlName="Ubicacion" />
                    <mat-error *ngIf="fPro.Ubicacion.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>

        <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
            <form [formGroup]="formPropiedad">
                <mat-form-field class="example-full-width">
                    <mat-label>Localidad</mat-label>
                    <input matInput name="Localidad" type="text" formControlName="Localidad" />
                </mat-form-field>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
            <form [formGroup]="formPropiedad">
                <section class="example-section">
                    <mat-checkbox formControlName="PropiedadHorizontal">Es propiedad horizontal?</mat-checkbox>
                </section>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
            <form [formGroup]="formPropiedad">
                <mat-form-field class="example-full-width">
                    <mat-label>Indivisa</mat-label>
                    <span matPrefix>% &nbsp;</span>
                    <input matInput appPercentageDirective min="1" max="100" [(ngModel)]="indivisa" placeholder="100" name="Indivisa" type="number" formControlName="Indivisa" />
                    <mat-hint>Dueño de propiedad al % {{indivisa}}</mat-hint>
                </mat-form-field>
            </form>
        </div>

        <div class="form-group col-md-3" *ngIf="mostrarFianza">
            <form [formGroup]="formFianza">
                <mat-form-field>
                    <mat-label>Razon Social</mat-label>
                    <input matInput name="RazonSocial" type="text " formControlName="RazonSocial" />
                    <mat-error *ngIf="fTL.RazonSocial.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarFianza">
            <form [formGroup]="formFianza">
                <mat-form-field class="example-full-width">
                    <mat-label>CUIT/CUIL</mat-label>
                    <input matInput name="Cuit" type="text" placeholder="00-00000000-0" formControlName="Cuit" />
                    <mat-error *ngIf="fTL.Cuit.errors?.pattern">Formato invalido.</mat-error>
                    <mat-error *ngIf="fTL.Cuit.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>
    </div>

    <div class="col-auto my-1">
        <ng-container>
            <button mat-raised-button color="primary" style="margin-right:3px;" *ngIf="mostrarPropiedad" name="Guardar" [disabled]=" !formPropiedad.valid" (click)="onSubmitUpdate()">Guardar</button>
            <button mat-raised-button color="primary" style="margin-right:3px;" *ngIf="mostrarPersona" name="Guardar" [disabled]="  !formPersonaGarantia.valid" (click)="onSubmitUpdate()">Guardar</button>
            <button mat-raised-button color="primary" style="margin-right:3px;" *ngIf="mostrarFianza" name="Guardar" [disabled]=" !formFianza.valid " (click)="onSubmitUpdate()">Guardar</button>
        </ng-container>

        <button mat-button color="warn" style="margin-right:3px;" name="Cancelar" (click)="goBack()">Cancelar</button>
    </div>
</mat-card>
