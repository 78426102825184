import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogActualizaMontosBrochesComponent } from '../dialog-actualiza-montos-broches/dialog-actualiza-montos-broches.component';

@Component({
  selector: 'app-btn-dialog-actualiza-montos-broches',
  templateUrl: './btn-dialog-actualiza-montos-broches.component.html',
  styleUrls: ['./btn-dialog-actualiza-montos-broches.component.css']
})
export class BtnDialogActualizaMontosBrochesComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  openDialogActualizaMontosBroche(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogActualizaMontosBrochesComponent, dialogConfig);
  }

}
