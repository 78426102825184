import { IdImporteDto } from "@models/base/idImporteDto.model";
import { MovimientoBasicDto } from "@models/movimiento/movimientoBasicDto.model";
import { PagoDto } from "@models/pago/pagoDto.model";


export class CreateReceiptInquilinoRequest {

  public idContrato! : number | null;
  public pagos! : PagoDto[];
  public detalleServicioPropiedad! : IdImporteDto[];
  public movimientos! : IdImporteDto[];
  public periodosContrato! : IdImporteDto[];
  public movimientosNuevos!: MovimientoBasicDto[];
  public idEmpresa! : number;
  public idInquilino! : number;
  public idUsuario! : number;
}
