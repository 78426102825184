import moment from "moment";
import { ContratoDto } from "../models/contrato/contratoDto.model";
import { PeriodoContratoDetailDto } from "../models/periodos-contrato/periodoContratoDetailDto.model";
import { ContratosService } from "../services/contratos/contratos.service";

export class ContratoController {
  constructor(private contratosService: ContratosService) {
  }


  static calculoPunitorio(periodo: PeriodoContratoDetailDto, importeEntrega: number, fechaHasta: Date, contrato: ContratoDto): any {
    let totalPunitorio = 0;
    let entregaSinPunitorio = { entrega: 0, punitorio: 0 };
    let importeAlquiler = Math.round(periodo.importeAlquiler * 100) / 100;
    let diaVencimientoPago = contrato.diaVencimientoPago;
    let fechaVencimientoPagoString = periodo.periodoAnio + "/" + periodo.periodoMes + "/" + diaVencimientoPago;
    let fechaVencimientoPago = moment(new Date(fechaVencimientoPagoString));
    let fechaHastaPunitorio = moment(fechaHasta) != null ? moment(fechaHasta) : moment(new Date());

    // Verificar si la fecha de vencimiento cae en fin de semana y ajustarla al siguiente día hábil
    if (fechaVencimientoPago.day() === 6) { // Si es sábado
      fechaVencimientoPago.add(2, 'days'); // Sumar dos días para llegar al siguiente lunes
    } else if (fechaVencimientoPago.day() === 0) { // Si es domingo
      fechaVencimientoPago.add(1, 'days'); // Sumar un día para llegar al siguiente lunes
    }

    // Verificar si la fecha de hasta punitorio cae en fin de semana y ajustarla al siguiente día hábil
    if (fechaHastaPunitorio.day() === 6) { // Si es sábado
      fechaHastaPunitorio.add(2, 'days'); // Sumar dos días para llegar al siguiente lunes
    } else if (fechaHastaPunitorio.day() === 0) { // Si es domingo
      fechaHastaPunitorio.add(1, 'days'); // Sumar un día para llegar al siguiente lunes
    }

    let diasPunitorios = fechaHastaPunitorio.diff(fechaVencimientoPago, 'days');
    let montoPunitorio = contrato.montoPunitorio;
    let totalPorcentajePunitorios = diasPunitorios * montoPunitorio; // Porcentaje

    if (fechaVencimientoPago < fechaHastaPunitorio) {
      totalPunitorio = (importeAlquiler * totalPorcentajePunitorios) / 100;
      let totalImporteConPunitorio = importeAlquiler + totalPunitorio;
      let proporcioPunitorio = (totalPunitorio * 100) / totalImporteConPunitorio;

      if (importeEntrega != 0 && importeEntrega != importeAlquiler) {
        entregaSinPunitorio.punitorio = (importeEntrega * proporcioPunitorio) / 100;
        entregaSinPunitorio.entrega = importeEntrega - entregaSinPunitorio.punitorio;
      } else {
        entregaSinPunitorio.punitorio = Math.round(totalPunitorio * 100) / 100;
        entregaSinPunitorio.entrega = Math.round(importeEntrega * 100) / 100;
      }
    } else {
      if (diasPunitorios <= 0)
        entregaSinPunitorio.punitorio = 0;
      entregaSinPunitorio.entrega = Math.round(importeEntrega * 100) / 100;
    }

    return entregaSinPunitorio;
  }
}
