import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogDeleteComponent } from 'src/app/views/genericos/dialog-delete/dialog-delete.component';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, EstadosPropiedad, RangoFechasValores } from '@models/base/identificadores.model';
import { PropiedadDto } from '@models/propiedades/propiedadDto.model';
import { PropiedadesService } from '@services/propiedades/propiedades.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { DatePipe } from '@angular/common';
import { PropiedadFilter } from '@models/propiedades/propiedadFilter.model';
import { EstadosPropiedadService } from '@services/estados-propiedad/estados-propiedad.service';
import { PropiedadClaseService } from '@services/propiedad-clase/propiedad-clase.service';
import { TiposPropiedadService } from '@services/tipos-propiedad/tipos-propiedad.service';

@Component({
  selector: 'app-listado-propiedades',
  templateUrl: './listado-propiedades.component.html',
  styleUrls: ['./listado-propiedades.component.css'],
  providers: [DatePipe]
})
export class ListadoPropiedadesComponent implements OnInit {

  data = false
  displayedColumns: string[] = [];
  public propiedades: PropiedadDto[] = [];
  dataSource = new MatTableDataSource<PropiedadDto>();
  errorMessage: string = "";
  estadosPropiedad!: EstadosPropiedad;
  lstEstadosPropiedad!: BaseDto[];
  lstTiposPropiedad!: BaseDto[];
  ubicacionFilter = new FormControl();
  estadoFilter = new FormControl();
  localidadFilter = new FormControl();
  claseFilter = new FormControl();

  filteredValues = { ubicacion: '', localidad: '', estadoPropiedad: '', clase: '' };
  form: FormGroup;
  formBuilder = new FormBuilder
  checkedFiltroAvanzado: boolean = false
  lstEstadosContrato!: BaseDto[];
  estadosPropiedadEnum = EstadosPropiedad;
  lstPropiedadClase!: BaseDto[];
  constructor(
    private propiedadesService: PropiedadesService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private estadosPropiedadService: EstadosPropiedadService,
    private propiedadClaseService: PropiedadClaseService,
    private tiposPropiedadService: TiposPropiedadService

  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["ubicacion", "localidad", "accion"] :
        ["ubicacion", "propietario", "localidad", "tipoPropiedad", "propiedadClase", "estadoPropiedad", "fechaAlta", "activo", "accion"];
    });

    this.form = this.formBuilder.group({
      EstadosPropiedad: [],
      ClasesPropiedad: [],
      TiposPropiedad: [],
      FechaAltaDesde: [new Date((new Date().getTime() - RangoFechasValores.Todos)), Validators.compose([Validators.required,])],
      FechaAltaHasta: [new Date(), Validators.compose([Validators.required,])],
      Activo: [true]
    })


  }



  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }


  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  ngOnInit(): void {
    this.getAllClasesPropiedad();
    this.getAllEstadosPropiedad();
    this.getAllTiposPropiedad();

    this.ubicacionFilter.valueChanges.subscribe((ubicacionFilterValue) => {
      this.filteredValues['ubicacion'] = ubicacionFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.localidadFilter.valueChanges.subscribe((localidadFilterValue) => {
      this.filteredValues['localidad'] = localidadFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false

  }

  filtrarPropiedades() {
    let idsEstadosPropiedad = isFalsy(this.form.controls["EstadosPropiedad"].value) ? null : this.form.controls["EstadosPropiedad"].value

    let idsClasesPropiedad = isFalsy(this.form.controls["ClasesPropiedad"].value) ? null : this.form.controls["ClasesPropiedad"].value

    let idsTiposPropiedad = isFalsy(this.form.controls["TiposPropiedad"].value) ? null : this.form.controls["TiposPropiedad"].value

    let activo = this.form.controls["Activo"].value

    //Fijos
    let fechaAltaDesde = this.datepipe.transform(this.form.controls["FechaAltaDesde"].value, 'yyyy-MM-dd') as string;
    let fechaAltaHasta = this.datepipe.transform(this.form.controls["FechaAltaHasta"].value, 'yyyy-MM-dd') as string;

    // Ajustar la hora para la fecha desde
    fechaAltaDesde = fechaAltaDesde + ' 00:00:00';
    // Ajustar la hora para la fecha hasta
    fechaAltaHasta = fechaAltaHasta + ' 23:59:59';

    let propiedadFilter = new PropiedadFilter
    propiedadFilter.idsEstadoPropiedad = idsEstadosPropiedad
    propiedadFilter.fechaAltaDesde = fechaAltaDesde
    propiedadFilter.FechaAltaHasta = fechaAltaHasta
    propiedadFilter.activo = activo
    propiedadFilter.idsTipoPropiedad = idsTiposPropiedad
    propiedadFilter.idsPropiedadClase = idsClasesPropiedad
    propiedadFilter.idsTipoFolio = null
    propiedadFilter.idLocalidad = null
    propiedadFilter.ubicacion = null
    propiedadFilter.nroFolio = null
    propiedadFilter.activo = null
    propiedadFilter.horizontal = null

    this.getAllPropiedades(propiedadFilter);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();

  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }

  getAllPropiedades(propiedadFilter: PropiedadFilter) {
    this.spinner.show("spListadoPropiedad");
    this.propiedadesService.getAllByFilter(propiedadFilter).subscribe(
      data => {
        this.spinner.hide("spListadoPropiedad");
        this.dataSource.data = data.sort((a, b) => a.ubicacion.toString().localeCompare(b.ubicacion.toString())) as PropiedadDto[]
        this.data = this.dataSource.data.length >= 1 ? true : false
      },
      error => {
        this.spinner.hide("spListadoPropiedad");
        this.snackBar.showError(error, "Error");
      })
  }

  getAllEstadosPropiedad() {
    this.spinner.show("spBusquedaEstadosPropiedad");
    this.estadosPropiedadService.getAll().subscribe(
      data => {
        this.spinner.hide("spBusquedaEstadosPropiedad");
        this.lstEstadosContrato = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion))
        const idsEstadosContrato = this.lstEstadosContrato.map(item => item.id);

        this.form.patchValue({
          EstadosPropiedad: idsEstadosContrato,
        });
      },
      error => {
        this.spinner.hide("spBusquedaEstadosPropiedad");
        this.snackBar.showError(error, "Error");
      })
  }

  getAllClasesPropiedad() {
    this.spinner.show("spBusquedaPropiedadClase");
    this.propiedadClaseService.getAll().subscribe(
      data => {
        this.spinner.hide("spBusquedaPropiedadClase");
        this.lstPropiedadClase = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion))
        const idsPropiedadClase = this.lstPropiedadClase.map(item => item.id);
        this.form.patchValue({
          ClasesPropiedad: idsPropiedadClase
        });
      },
      error => {
        this.spinner.hide("spBusquedaPropiedadClase");
        this.snackBar.showError(error, "Error");
      })
  }

  getAllTiposPropiedad() {
    this.spinner.show("spBusquedaTiposPropiedad");
    this.tiposPropiedadService.getAll().subscribe(
      data => {
        this.spinner.hide("spBusquedaTiposPropiedad");
        this.lstTiposPropiedad = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion))
        const idsTiposPropiedad = this.lstTiposPropiedad.map(item => item.id);

        this.form.patchValue({
          TiposPropiedad: idsTiposPropiedad,
        });
      },
      error => {
        this.spinner.hide("spBusquedaTiposPropiedad");
        this.snackBar.showError(error, "Error");
      })
  }


  applyFilter(filterValue: any) {
    if (filterValue != null) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      this.dataSource.filter = filterValue;
    }
  }

  customFilterPredicate() {

    const myFilterPredicate = function (data: PropiedadDto, filter: string): boolean {
      let searchString = JSON.parse(filter);
      return data.ubicacion.toString().trim().toLowerCase().indexOf(searchString.ubicacion.toLowerCase()) !== -1 &&
        data.estadoPropiedad.descripcion.toString().trim().toLowerCase().indexOf(searchString.estadoPropiedad.toLowerCase()) !== -1
        &&
        data.localidad.descripcion.toString().trim().toLowerCase().indexOf(searchString.localidad.toLowerCase()) !== -1
        &&
        data.propiedadClase.descripcion.toString().trim().toLowerCase().indexOf(searchString.clase.toLowerCase()) !== -1

    }
    return myFilterPredicate;
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  deleteRowData(id: number) {
    this.propiedadesService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = this.dataSource.data.filter((value: { id: number; }, key: any) => {
            return value.id != id;
          });
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError("El registro no se puede eliminar porque tiene referencias asignadas.", "Error");
        })

  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
