import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { AsociarDocumentoAEntidadRequest } from '@models/documentos/asociarDocumentoAEntidadRequest.model';
import { AsuntosService } from '@services/asuntos/asuntos.service';
import { DocumentosService } from '@services/documentos/documentos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-asocia-documento-entidad',
  templateUrl: './asocia-documento-entidad.component.html',
  styleUrls: ['./asocia-documento-entidad.component.css']
})
export class AsociaDocumentoEntidadComponent implements OnInit {
  selectedAsuntoStatus:boolean = false
  selectedDocumentoStatus:boolean = false
  lstAsuntos: BaseDto[] = [  ];
  lstDocumentos: BaseDto[] = [ ]
  formDocuementos: any;
  idAsunto:number = 0
  idDocumento:number = 0
  @Input() idEntidad:number = 0
  @Input() idTipoEntidad:number =0
  @Output() documentoChange = new EventEmitter<number>();

  get f() { return this.formDocuementos.controls; }
  constructor(
    private formBuilder:FormBuilder,
    private spinner:NgxSpinnerService,
    private documentoService:DocumentosService,
    private asuntosService:AsuntosService,
    private snackBar: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    this.formDocuementos = this.formBuilder.group({
      Asuntos: [''],
      Documentos: ['', Validators.compose([Validators.required])],
    });

    this.getAllAsuntos()
  }

  selectAsunto(event: any) {
    this.selectedAsuntoStatus= true;
    this.idAsunto = event.value
    this.getAllDocumentosByIdAsunto(this.idAsunto)
  }

  selectDocumento(event: any){
    this.selectedDocumentoStatus= true;
    this.idDocumento = event.value
  }

  asociarDocumento(){
    this.spinner.show("docExistente")
    const asociarDocumentoAEntidadRequest =  new AsociarDocumentoAEntidadRequest
    asociarDocumentoAEntidadRequest.id_Documento = this.idDocumento
    asociarDocumentoAEntidadRequest.id_Entidad = this.idEntidad
    asociarDocumentoAEntidadRequest.id_TipoEntidad = this.idTipoEntidad
    this.documentoService.asociarDocumentoAEntidad(asociarDocumentoAEntidadRequest)
    .subscribe(
      data => {
        this.spinner.hide("docExistente")
        this.snackBar.showSuccess("Documento asignado con exito", "Exito");
        this.cancelUpload()
        this.documentoChange.emit(data)
      },
      error =>{
        this.spinner.hide("docExistente")
        this.snackBar.showError( error, "Error");
      }
    )
  }

  cancelUpload() {
    this.formDocuementos.controls["Asuntos"].setValue(0)
    this.idAsunto = 0
  }

  getAllAsuntos() {
    this.spinner.show("spBusquedaAsunto")
    this.asuntosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaAsunto")
          this.lstAsuntos = data
        },
        error =>{
          this.spinner.hide("spBusquedaAsunto")
          this.snackBar.showError( error, "Error");
        }
      )
  }

  getAllDocumentosByIdAsunto(idAsunto:number){
    this.spinner.show("spBusquedaDocumento")
    this.documentoService.getAllByIdAsunto(idAsunto)
    .subscribe(
      data => {
        this.spinner.hide("spBusquedaDocumento")
        this.lstDocumentos = data
      },
      error =>{
        this.spinner.hide("spBusquedaDocumento")
        this.snackBar.showError( error, "Error");
      }
    )
  }

  trackByItems(index: number, item: any): any { return item; }

}
