import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { AssignPropiedadPropietarioRequest } from '@models/propiedades/assignPropiedadPropietarioRequest.model';
import { AssingPropietarioTitularRequest } from '@models/propiedades/assingPropietarioTitularRequest.model';
import { CreatePropiedadRequest } from '@models/propiedades/createPropiedadRequest.model';
import { PropiedadDto } from '@models/propiedades/propiedadDto.model';
import { RemoveAssignmentPropiedadPropietarioRequest } from '@models/propiedades/removeAssignmentPropiedadPropietarioRequest.model';
import { TipoPropiedadDto } from '@models/propiedades/tipoPropiedadDto.model';
import { UpdatePropiedadRequest } from '@models/propiedades/updatePropiedadRequest.model';
import { PropietarioDto } from '@models/propietarios/propietarioDto.model';
import { isFalsy } from 'utility-types';
import { MessageSuccessfullDto } from '@models/base/MessageSuccessfullDto.model';
import { PropiedadPropietarioDto } from '@models/propiedad-propietario/propiedadPropietarioDto.model';
import { PropiedadFilter } from '@models/propiedades/propiedadFilter.model';

@Injectable({
  providedIn: 'root'
})
export class PropiedadesService {
  public idPropiedad: number = 0


  private id!: number;

  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient
  ) { }

  //Propiedades

  setIdPropiedad(id: number) {
    this.idPropiedad
  }
  getIdPropiedad() {
    return this.idPropiedad
  }

  getById(id: number): Observable<PropiedadDto> {
    return this.http.get<PropiedadDto>("/api/Propiedad/GetById?id=" + id)
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Propiedad/Delete?id=" + id);
    else
      return of(null)
  }

  create(createPropiedadRequest: CreatePropiedadRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Propiedad/Create", createPropiedadRequest);
  }

  update(updatePropiedadRequest: UpdatePropiedadRequest): Observable<any> {
    return this.http.put<any>("/api/Propiedad/Update", updatePropiedadRequest);
  }

  getAll(): Observable<PropiedadDto[]> {
    return this.http.get<PropiedadDto[]>("/api/Propiedad/GetAll");
  }

   /**
   * Filtrar listado de contrato.
   * @param propiedadFilter
   * @returns PropiedadDto[]
   */
   getAllByFilter(propiedadFilter: PropiedadFilter): Observable<PropiedadDto[]> {
    return this.http.post<PropiedadDto[]>("/api/Propiedad/GetAllByFilter", propiedadFilter)
  }

  getAllIdAndUbicacionPropiedades(idEstado: number | null): Observable<BaseDto[]> {
    if (isFalsy(idEstado))
      return this.http.get<BaseDto[]>("/api/Propiedad/GetAllIdAndUbicacionPropiedades");
    else
      return this.http.get<BaseDto[]>("/api/Propiedad/GetAllIdAndUbicacionPropiedades?idEstadoPropiedad=" + idEstado);
  }

  //Propietarios Propiedad

  assignPropiedadPropietario(assignPropietarioRequest: AssignPropiedadPropietarioRequest): Observable<MessageSuccessfullDto> {
    return this.http.post<MessageSuccessfullDto>("/api/Propiedad/AssignPropiedadPropietario", assignPropietarioRequest);
  }


  removeAssignmentPropiedadPropietario(removePropietario: RemoveAssignmentPropiedadPropietarioRequest): Observable<MessageSuccessfullDto> {
    return this.http.post<MessageSuccessfullDto>("/api/Propiedad/RemoveAssignmentPropiedadPropietario", removePropietario);
  }

  assingPropietarioTitularRequest(assingPropietarioTitularRequest: AssingPropietarioTitularRequest): Observable<any> {
    return this.http.post<any>("/api/Propiedad/AssignPropietarioTitular", assingPropietarioTitularRequest);
  }

  getAllPropietariosPropiedad(idPropiedad: number): Observable<PropietarioDto[]> {
    return this.http.get<PropietarioDto[]>("/api/Propietarios/GetAllPropietariosByIdPropiedad?idPropiedad=" + idPropiedad);
  }

  //Estados Propiedad
  getAllEstadosPropiedad(): Observable<BaseDto[]> {
    return this.http.get<BaseDto[]>("/api/EstadoPropiedad/GetAll");
  }

  //Tipos Propiedad
  getAllTiposPropiedad(): Observable<TipoPropiedadDto[]> {
    return this.http.get<TipoPropiedadDto[]>("/api/TiposPropiedad/GetAll");
  }

  //Clases Propiedad

  GetAllClasePropiedad(): Observable<BaseDto[]> {
    return this.http.get<BaseDto[]>("/api/PropiedadClase/GetAll");
  }

  getAllPersonasPropietariosByIdPropiedad(idPropietario: number): Observable<PropiedadPropietarioDto[]> {
    return this.http.get<PropiedadPropietarioDto[]>("/api/Propiedad/GetPropiedadesByIdPropietario?idPropietario=" + idPropietario);
  }
}
