import { ContratoDto } from "@models/contrato/contratoDto.model";
import { DetalleServicioPropiedadDetailDto, Vencimiento } from "@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadDetailDto.model";
import { isConstructorDeclaration } from "typescript";
import { isFalsy } from "utility-types";
import { TiposMovimiento } from "../models/base/identificadores.model";
import { PeriodoContratoDetailDto } from "../models/periodos-contrato/periodoContratoDetailDto.model";
import { GetPendingDebtInquilinoResponse } from "../models/inquilino/getPendingDebtInquilinoResponse.model";
import { MovimientoDetailDto } from "../models/movimiento/movimientoDetailDto.model";
import { ContratoController } from "./contratos.controller";

export class InquilinoController {




  constructor() {
  }

  /**
    * *Calcula deuda de inquilino hasta el dia de la fecha.
    * @param GetPendingDebtInquilinoResponse
    * ♠@return number
    */
  static calcularSaldoActual(getPendingDebtInquilinoResponse: GetPendingDebtInquilinoResponse,contratoDto: ContratoDto): number {
    var deudaActual = 0
    var totalImporteAlquiler = 0
    var totalImporteMovimientos = 0
    var totalImporteDetalleServicios = 0
    getPendingDebtInquilinoResponse.periodosContrato.forEach(element => {
      totalImporteAlquiler += isFalsy(element.importeAlquiler) ? 0 : element.importeAlquiler
      let fechaCalculo = element.fechaPago != null ? new Date(element.fechaPago) :  new Date()
      var valor = ContratoController.calculoPunitorio(element,element.importeAlquiler,fechaCalculo,contratoDto)
      totalImporteAlquiler += valor.punitorio
    });

    getPendingDebtInquilinoResponse.movimientos.forEach(element => {
      if (element.tipoMovimiento.id != TiposMovimiento.SaldoAFavor && element.tipoMovimiento.id != TiposMovimiento.SaldoAdeuda)
        totalImporteMovimientos +=  isFalsy(element.importe) ? 0 : element.importe
    });

    getPendingDebtInquilinoResponse.detallesServiciosPropiedad.forEach(element => {
      totalImporteDetalleServicios += isFalsy(element.importeATenerEnCuenta) ? 0 : element.importeATenerEnCuenta
    })
    deudaActual = totalImporteAlquiler + totalImporteMovimientos + totalImporteDetalleServicios

    return deudaActual
  }

  static calcularSaldoAnterior(getPendingDebtInquilinoResponse: GetPendingDebtInquilinoResponse): number {
    var totalSaldoAnterior = 0
    if (getPendingDebtInquilinoResponse.movimientos.length > 0) {
      let mov = getPendingDebtInquilinoResponse.movimientos.filter(this.esMovimientoTipoSaldo)
      mov.forEach(element => {
        if (element.tipoMovimiento.id == TiposMovimiento.SaldoAFavor)
          totalSaldoAnterior += element.importe
        if (element.tipoMovimiento.id == TiposMovimiento.SaldoAdeuda)
          totalSaldoAnterior -= element.importe
      });
    }
    return totalSaldoAnterior
  }

  /**
    * *Devuelve verdadero si es un movimiento de tipo Saldo de Deuda.
    * @param elemento:MovimientoDetailDto
    * ♠@return boolean
    */
  static esMovimientoTipoSaldo(elemento: MovimientoDetailDto) {
    return elemento.tipoMovimiento.id == TiposMovimiento.SaldoAFavor || elemento.tipoMovimiento.id == TiposMovimiento.SaldoAdeuda;
  }


  static calcularMontoEntregadoAlquiler(lstPeriodosContrato: PeriodoContratoDetailDto[]): number {
    var totalMontoAlquiler = 0
    lstPeriodosContrato.forEach(element => {
      let importeAlquilerSinPunitorio = isFalsy(element.importeAlquilerSinPunitorio) ? 0 : parseFloat((Math.round(element.importeAlquilerSinPunitorio * 100) / 100).toFixed(2))
      let importePunitorioEntrega = isFalsy(element.importePunitorioEntrega) ? 0 : parseFloat((Math.round(element.importePunitorioEntrega * 100) / 100).toFixed(2))
      totalMontoAlquiler += importeAlquilerSinPunitorio + importePunitorioEntrega
    });
    return totalMontoAlquiler
  }

  static cargarListadoVencimientos(lstDetalleServicioPropiedadDto: DetalleServicioPropiedadDetailDto[]): DetalleServicioPropiedadDetailDto[] {
    lstDetalleServicioPropiedadDto.forEach(element => {
      if (isFalsy(element.vencimientos)) {
        let lstVencimientos: Vencimiento[] = []
        if (!isFalsy(element.fechaVencimiento1) && !isFalsy(element.importeVencimiento1)) {
          let vencimiento = new Vencimiento
          vencimiento.idDetalle = element.id
          vencimiento.fechaVencimiento = element.fechaVencimiento1
          vencimiento.importeVencimiento = isFalsy(element.importeVencimiento1) ? 0 : element.importeVencimiento1
          vencimiento.seleccionPago = isFalsy(element.seleccionPago) ? false : element.seleccionPago
          lstVencimientos.push(vencimiento)
          element.vencimientos = lstVencimientos
        }
        if (!isFalsy(element.fechaVencimiento2) && !isFalsy(element.importeVencimiento2)) {
          let vencimiento = new Vencimiento
          vencimiento.idDetalle = element.id
          vencimiento.fechaVencimiento = element.fechaVencimiento2
          vencimiento.importeVencimiento = isFalsy(element.importeVencimiento2) ? 0 : element.importeVencimiento2
          vencimiento.seleccionPago = isFalsy(element.seleccionPago) ? false : element.seleccionPago
          lstVencimientos.push(vencimiento)
          element.vencimientos = lstVencimientos
        }
        if (!isFalsy(element.fechaVencimiento3) && !isFalsy(element.importeVencimiento3)) {
          let vencimiento = new Vencimiento
          vencimiento.idDetalle = element.id
          vencimiento.fechaVencimiento = element.fechaVencimiento3
          vencimiento.importeVencimiento = isFalsy(element.importeVencimiento3) ? 0 : element.importeVencimiento3
          vencimiento.seleccionPago = isFalsy(element.seleccionPago) ? false : element.seleccionPago
          lstVencimientos.push(vencimiento)
          element.vencimientos = lstVencimientos
        }
        if (!isFalsy(element.fechaVencimiento4) && !isFalsy(element.importeVencimiento4)) {
          let vencimiento = new Vencimiento
          vencimiento.idDetalle = element.id
          vencimiento.fechaVencimiento = element.fechaVencimiento4
          vencimiento.importeVencimiento = isFalsy(element.importeVencimiento4) ? 0 : element.importeVencimiento4
          vencimiento.seleccionPago = isFalsy(element.seleccionPago) ? false : element.seleccionPago
          lstVencimientos.push(vencimiento)
          element.vencimientos = lstVencimientos
        }
      }
    });
    return lstDetalleServicioPropiedadDto
  }
}
