<ngx-spinner name="spCaracteristica" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
</ngx-spinner>

<button mat-raised-button color="primary"  *appRole="['Propiedades.Crear']" (click)="agregar()">
  <mat-icon>add</mat-icon>
  Nueva caracteristica propiedad
</button>

<br><br>
<table *appRole="['Propiedades.Vista']" mat-table [dataSource]="dataSource" [hidden]=!data matSort matSortStart="asc" table class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Id Column -->
    <!-- <ng-container matColumnDef="id">
        <mat-header-cell mat-header-cell *matHeaderCellDef> # </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
    </ng-container> -->

    <!-- Name Column -->
    <ng-container matColumnDef="descripcion">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element"> {{element.caracteristica.descripcion}} </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="cantidad">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element"> {{element.cantidad}} </mat-cell>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="accion">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element" class="action-link">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
            <mat-menu #menu="matMenu">
                <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                <mat-icon>visibility</mat-icon>
                <span>Ver</span>
              </button> -->
                <button mat-menu-item (click)="editar(element)">
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
                <button mat-menu-item (click)="openDialog('Eliminar',element)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
</table>
<div class="no-data-table">
    <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
</div>
<mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
</mat-paginator>
