import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateKeyValueRequest } from '@models/base/createKeyValueRequest.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { UpdateKeyValueRequest } from '@models/base/updateKeyValueRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class EstadoDetalleServicioPropiedadService {

  constructor(
    private http:HttpClient
  ) { }

  getAll():Observable<BaseDto[]> {
    return   this.http.get<BaseDto[]>("/api/EstadoDetalleServicioPropiedad/GetAll");
  }

  GetById(idTipoServicio:number):Observable<BaseDto>
  {
    return  this.http.get<BaseDto>("/api/EstadoDetalleServicioPropiedad/GetById?id="+ idTipoServicio);
  }

  create(createKeyValueRequest:CreateKeyValueRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/EstadoDetalleServicioPropiedad/Create",createKeyValueRequest);
  }

  update(updateKeyValueRequest:UpdateKeyValueRequest):Observable<any>
  {
    return  this.http.put("/api/EstadoDetalleServicioPropiedad/Update",updateKeyValueRequest);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/EstadoDetalleServicioPropiedad/Delete?id=" + id);
    else
      return of(null)
  }
}
