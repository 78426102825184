import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { isFalsy } from 'utility-types';
import { ajax } from 'rxjs/ajax';
import { CreateKeyValueRequest } from '@models/base/createKeyValueRequest.model';
import { UpdateKeyValueRequest } from '@models/base/updateKeyValueRequest.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreatePaisRequest } from '@models/pais/createPaisRequest.model';
import { UpdatePaisRequest } from '@models/pais/updatePaisRequest.model';
import { PaisDto } from '@models/pais/paisDto.model';

@Injectable({
  providedIn: 'root'
})
export class PaisService {

  constructor(private http:HttpClient) { }

  create(createPaisRequest: CreatePaisRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/Pais/Create",createPaisRequest);
  }

  update(updatePaisRequest: UpdatePaisRequest):Observable<any>
  {
    return  this.http.put<any>("/api/Pais/Update",updatePaisRequest);
  }

  getAll():Observable<PaisDto[]>
  {
    return  this.http.get<PaisDto[]>("/api/Pais/GetAll");
  }

  getAllNombrePaises():Observable<BaseDto[]>
  {
    return  this.http.get<BaseDto[]>("/api/Pais/GetAllNombrePaises");
  }

  getById(id:number):Observable<PaisDto>
  {
    return  this.http.get<PaisDto>("/api/Pais/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Pais/Delete?id=" + id);
    else
      return of(null)
  }
}
