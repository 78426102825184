import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogCreateUpdateDeleteComponent } from '../views/genericos/dialog-create-update-delete/dialog-create-update-delete.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { DialogDeleteComponent } from '../views/genericos/dialog-delete/dialog-delete.component';
import { GenericDialogComponent } from '../views/genericos/generic-dialog/generic-dialog.component';
import { BtnGenerarResumenMensualComponent } from '@views/generar-resumen-mensual/btn-generar-resumen-mensual/btn-generar-resumen-mensual.component';
import { DetalleGeneracionResumenMasivoComponent } from '@views/generar-resumen-mensual/detalle-generacion-resumen-masivo/detalle-generacion-resumen-masivo.component';
import { DialogGenerarResumenMensualComponent } from '@views/generar-resumen-mensual/dialog-generar-resumen-mensual/dialog-generar-resumen-mensual.component';
import { GenerarResumenMensualComponent } from '@views/generar-resumen-mensual/generar-resumen-mensual.component';



@NgModule({
  declarations: [

    DetalleGeneracionResumenMasivoComponent,
    GenerarResumenMensualComponent,
    DialogGenerarResumenMensualComponent,
    BtnGenerarResumenMensualComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    DetalleGeneracionResumenMasivoComponent,
    GenerarResumenMensualComponent,
    DialogGenerarResumenMensualComponent,
    BtnGenerarResumenMensualComponent,
  ]
})
export class ResumenModule { }
