import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HistoricoPeriodoContratoDto } from '@models/historico-periodo-contrato/historicoPeriodoContratoDto.model';
import { HistoricoPeriodoContratoFilter } from '@models/historico-periodo-contrato/historicoPeriodoContratoFilter.model';
import { Observable, of, throwError } from 'rxjs';
import { isFalsy } from 'utility-types';
@Injectable({
  providedIn: 'root'
})
export class HistoricosPeriodoContratoService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient
  ) { }


  getAll():Observable<HistoricoPeriodoContratoDto[]>
  {
    return  this.http.get<HistoricoPeriodoContratoDto[]>("/api/HistoricoPeriodoContrato/GetAll")
  }


  getAllByFilter(historicoPeriodoContratoFilter: HistoricoPeriodoContratoFilter): Observable<HistoricoPeriodoContratoDto[]> {
    return this.http.post<HistoricoPeriodoContratoDto[]>("/api/HistoricoPeriodoContrato/GetAllByFilter", historicoPeriodoContratoFilter)
  }

  getById(id:number):Observable<HistoricoPeriodoContratoDto[]>
  {
    return  this.http.get<HistoricoPeriodoContratoDto[]>("/api/HistoricoPeriodoContrato/GetBydId?id="+id)
  }

  getAllByByIdContrato(id:number):Observable<HistoricoPeriodoContratoDto[]>
  {
    return  this.http.get<HistoricoPeriodoContratoDto[]>("/api/HistoricoPeriodoContrato/GetAllByIdContrato?idContrato="+id)
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/HistoricoPeriodoContrato/Delete?id=" + id);
    else
      return of(null)
  }

}
