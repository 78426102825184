import { LiveAnnouncer } from '@angular/cdk/a11y';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { Genericos } from '@models/base/genericos.model';
import { AccionesDialog, EstadosContrato, RangoFechasValores } from '@models/base/identificadores.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { MovimientoDetailDto } from '@models/movimiento/movimientoDetailDto.model';
import { PersonaPropietarioDto } from '@models/propietarios/personaPropietarioDto.model';
import { MovimientosService } from '@services/movimientos/movimientos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposMoviemientoService } from '@services/tipos-movimiento/tipos-movimiento.service';
import { isFalsy } from 'utility-types';
import { DialogNuevoMovimientoCajaComponent } from '@views/caja/dialog-nuevo-movimiento-caja/dialog-nuevo-movimiento-caja.component';
import { DialogCreateMovimientoComponent } from '../dialog-create-movimiento/dialog-create-movimiento.component';
import { DialogUpdateMovimientoComponent } from '../dialog-update-movimiento/dialog-update-movimiento.component';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { TipoMovimientoDto } from '@models/tipos-movimiento/tipoMovimientoDto.model';
export class MovimientoView {
  public id: number = 0
  public descripcion: string = ""
  public tipoMovimientoDescripcion: string = ""
  public tipoMovimiento!: TipoMovimientoDto
  public contratoDescripcion: string = ""
  public contratoId: number = 0
  public inquilinoDescripcion: string = ""
  public inquilinoPersonaId: number = 0
  public propiedadDescripcion: string = ""
  public propiedadId: number = 0
  public propietarioDescripcion: string = ""
  public propietarioId: number = 0
  public bonificacionDescripcion: string = ""
  public periodo: number = 0
  public periodoMes: number = 0
  public periodoAnio: number = 0
  public fechaEmision: string | null = null
  public fechaPago: string | null = null
  public fechaVencimiento: string = ""
  public periodoContratoDescripcion: string = ""
  public seleccionPago: boolean = false
  public anulado: boolean = false

  private _importe!: number;
  public get importe(): number {
    return this._importe;
  }
  public set importe(v: number) {
    if (this.tipoMovimiento.resta)
      this._importe = v * (-1);
    else
      this._importe = v;
  }

}

@Component({
  selector: 'app-list-movimientos',
  templateUrl: './list-movimientos.component.html',
  styleUrls: ['./list-movimientos.component.css'],
  providers: [DatePipe]
})
export class ListMovimientosComponent implements OnInit {

  public dialogRef!: MatDialogRef<DialogNuevoMovimientoCajaComponent>
  displayedColumns: string[] = []// ['tipoMovimientoDescripcion','contratoDescripcion','inquilinoDescripcion','propiedadDescripcion','propietarioDescripcion','periodo','fechaEmision','fechaPago','importe','accion'];
  lstMovimientoView: MovimientoView[] = []
  dataSource = new MatTableDataSource<MovimientoView>();
  data: boolean = false;
  personaPropietarioDto!: PersonaPropietarioDto;
  propiedadBaseDto!: BaseDto;
  inquilinoDto!: InquilinoDto;
  formBuilder: FormBuilder = new FormBuilder;
  form: FormGroup;
  @Input() contratoDetailDto!: ContratoDetailDto
  lstRangoFecha = Genericos.lstRangoFecha
  lstTiposMovimientos!: BaseDto[];

  rangoFecha: number = RangoFechasValores.Hoy // Hoy
  checkedFiltroAvanzado: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private movimientosService: MovimientosService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    private tiposMoviemientoService: TiposMoviemientoService
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['tipoMovimientoDescripcion', 'contratoDescripcion', 'inquilinoDescripcion', 'propietarioDescripcion', 'periodo', 'fechaEmision', 'fechaPago', 'importe', 'accion'] :
        ['tipoMovimientoDescripcion', 'contratoDescripcion', 'inquilinoDescripcion', 'propietarioDescripcion', 'periodo', 'fechaEmision', 'fechaPago', 'importe', 'descripcion', 'accion'];
    });
    this.form = this.formBuilder.group({
      FechaDesde: [new Date((new Date().getTime())), Validators.compose([Validators.required,])],
      FechaHasta: [new Date(), Validators.compose([Validators.required,])],
      FechaPagoDesde: [null],
      FechaPagoHasta: [null],
      RangoFecha: [''],
      TiposMovimientos: [''],
      NumeroContrato: [null],
    })
  }


  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp; EstadosContrato
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  ngOnInit(): void {

    if (!isFalsy(this.contratoDetailDto?.numeroContrato)) {
      this.form.controls["NumeroContrato"].setValue(this.contratoDetailDto.numeroContrato)
      this.buscarMovimientos()
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false

    this.getAllTiposMovimientos();

  }


  getAllMovimientos() {
    this.spinner.show()
    this.movimientosService.getAll()
      .subscribe(
        data => {
          this.setDataSource(data)
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        })
  }

  selectRangoFecha(event: any) {
    this.rangoFecha = event.value;

    // Referencias a los controles de fecha
    const fechaDesdeControl = this.form.controls["FechaDesde"];
    const fechaHastaControl = this.form.controls["FechaHasta"];

    fechaDesdeControl.setValue(new Date(new Date().getTime() - this.rangoFecha));
    fechaHastaControl.setValue(new Date(new Date().getTime() + this.rangoFecha));
  }


  setDataSource(data: MovimientoDetailDto[]) {
    while (this.lstMovimientoView.length) {
      this.lstMovimientoView.pop();
    }
    data.forEach(element => {
      let movimientoView = new MovimientoView
      movimientoView.id = element.id
      movimientoView.descripcion = isFalsy(element.descripcion) ? "" : element.descripcion
      movimientoView.tipoMovimientoDescripcion = isFalsy(element.tipoMovimiento) ? "" : element.tipoMovimiento.descripcion
      movimientoView.tipoMovimiento = element.tipoMovimiento
      movimientoView.contratoDescripcion = isFalsy(element.contrato) ? "" : element.contrato.descripcion
      movimientoView.contratoId = isFalsy(element.contrato) ? 0 : element.contrato.id
      movimientoView.inquilinoDescripcion = isFalsy(element.inquilino) ? "" : element.inquilino.persona.descripcion
      movimientoView.inquilinoPersonaId = isFalsy(element.inquilino) ? 0 : element.inquilino.persona.id
      movimientoView.propiedadDescripcion = isFalsy(element.propiedad) ? "" : element.propiedad.descripcion
      movimientoView.propiedadId = isFalsy(element.propiedad) ? 0 : element.propiedad.id
      movimientoView.propietarioDescripcion = isFalsy(element.propietario) ? "" : element.propietario.descripcion
      movimientoView.propietarioId = isFalsy(element.propietario) ? 0 : element.propietario.id
      movimientoView.bonificacionDescripcion = isFalsy(element.bonificacion) ? "" : element.bonificacion.tipoBonificacion.descripcion
      movimientoView.importe = element.importe
      movimientoView.periodo = isFalsy(element.periodo) ? 0 : element.periodo
      movimientoView.periodoMes = isFalsy(element.periodoMes) ? 0 : element.periodoMes
      movimientoView.periodoAnio = isFalsy(element.periodoAnio) ? 0 : element.periodoAnio
      movimientoView.fechaEmision = isFalsy(element.fechaEmision) ? "" : element.fechaEmision
      movimientoView.fechaPago = element.fechaPago
      movimientoView.fechaVencimiento = element.fechaVencimiento
      movimientoView.anulado = element.anulado
      this.lstMovimientoView.push(movimientoView)
    });

    this.dataSource.data = this.lstMovimientoView
    this.data = this.dataSource.data.length >= 1 ? true : false
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: any) {
    if (event && event.target && event.target instanceof HTMLInputElement) {
      let filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  buscarMovimientos() {

    //Fijos
    let fechaEmisionDesde = this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;
    let fechaEmisionHasta = this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;
    let id_TipoMovimiento = this.form.controls["TiposMovimientos"].value
    // Ajustar la hora para la fecha desde
    fechaEmisionDesde = fechaEmisionDesde + ' 00:00:00';
    // Ajustar la hora para la fecha hasta
    fechaEmisionHasta = fechaEmisionHasta + ' 23:59:59';

    let fechaPagoDesde = this.datepipe.transform(this.form.controls["FechaPagoDesde"].value, 'yyyy-MM-dd') as string;
    let fechaPagoHasta = this.datepipe.transform(this.form.controls["FechaPagoHasta"].value, 'yyyy-MM-dd') as string;
    // Ajustar la hora para la fecha desde
    fechaPagoDesde = fechaPagoDesde + ' 00:00:00';
    // Ajustar la hora para la fecha hasta
    fechaPagoHasta = fechaPagoHasta + ' 23:59:59';

    //Variables
    let id_Contrato = null
    let id_Inquilino = isFalsy(this.inquilinoDto) ? null : this.inquilinoDto.id
    let id_Propiedad = isFalsy(this.propiedadBaseDto) ? null : this.propiedadBaseDto.id
    let id_Propietario
      = isFalsy(this.personaPropietarioDto) ? null : this.personaPropietarioDto.id_Propietario
    let numeroContrato = this.form.controls["NumeroContrato"].value

    this.spinner.show()
    this.movimientosService.getAllByFilter(fechaEmisionDesde, fechaEmisionHasta, numeroContrato, id_TipoMovimiento, id_Inquilino, id_Contrato, id_Propiedad, id_Propietario, null, fechaPagoDesde, fechaPagoHasta)
      .subscribe(
        data => {
          this.spinner.hide();
          data.sort((a, b) => a.fechaEmision?.toString().padStart(9, "0").localeCompare(b.fechaEmision.toString().padStart(9, "0")));
          this.setDataSource(data)
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getAllTiposMovimientos() {
    this.spinner.show("spBusquedaTiposMovimientos");
    this.tiposMoviemientoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTiposMovimientos");
          this.lstTiposMovimientos = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide("spBusquedaTiposMovimientos");
          this.snackBar.showError(error, "Error");
        }
      )
  }

  selectPropietario(personaPropietarioDto: PersonaPropietarioDto) {
    this.personaPropietarioDto = personaPropietarioDto
  }

  selectPropiedad(propiedadBaseDto: BaseDto) {
    this.propiedadBaseDto = propiedadBaseDto
  }

  selectInquilino(inquilinoDto: InquilinoDto) {
    this.inquilinoDto = inquilinoDto
  }


  openDialogCreateMovimiento() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '100%';
    dialogConfig.maxWidth = "95%"
    dialogConfig.data = { contratoDetailDto: this.contratoDetailDto };

    let dialogRef = this.dialog.open(DialogCreateMovimientoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.buscarMovimientos();
      } else if (result.event == AccionesDialog.Modificar) {
        this.buscarMovimientos();
      } else if (result.event == AccionesDialog.Cancelar) { }

    });
  }

  openDialogUpdateMovimiento(id: number, accion: string) {

    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = { id: id, event: accion == 'Ver' ? AccionesDialog.Ver : AccionesDialog.Modificar };
    dialogConfig.width = "95%";
    dialogConfig.maxWidth = "95%";
    let dialogRef = this.dialog.open(DialogUpdateMovimientoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Modificar) {
        this.buscarMovimientos()
      }
    });
  }

  openDialogUpdateMovimientoEliminar(id: number, accion: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = { id: id, event: AccionesDialog.Eliminar };
    dialogConfig.width = "30%";
    let dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteMovimiento(result.data)
      }
    });
  }

  deleteMovimiento(id: number) {
    this.spinner.show()
    this.movimientosService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.buscarMovimientos()
          this.snackBar.showSuccess("Registro eliminado correctamente.", "Exito");
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })
  }


  clearFilter() {
    this.form.controls["TiposMovimientos"].setValue(0)
    this.form.controls["NumeroContrato"].setValue("")
    this.form.controls["FechaPagoDesde"].setValue(null)
    this.form.controls["FechaPagoHasta"].setValue(null)
    this.form.controls["FechaDesde"].setValue(null)
    this.form.controls["FechaHasta"].setValue(null)
    this.inquilinoDto == null
    this.propiedadBaseDto = new BaseDto
    this.personaPropietarioDto = new PersonaPropietarioDto
  }

  openDialogNuevoMovimientoCaja() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "100%"
    dialogConfig.maxWidth = "95%";
    dialogConfig.data = { event: AccionesDialog.Agregar }
    dialogConfig.autoFocus = false
    dialogConfig.disableClose = true

    const dialogRef = this.dialog.open(DialogNuevoMovimientoCajaComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.buscarMovimientos();
      } else if (result.event == AccionesDialog.Modificar) {
        this.buscarMovimientos();
      } else if (result.event == AccionesDialog.Cancelar) { }
    });
  }

  getTotalImporte() {
    return this.dataSource.data.map(t => t.importe).reduce((acc: any, value: any) => acc + value, 0);
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}
