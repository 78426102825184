export class UpdatePropietarioRequest {

    public id: number = 0;
    public id_Persona: number = 0;
    public comision: number = 0;
    public id_Banco: number|null = 0;
    public id_TipoLiquidacion: number|null = 0;
    public cbu: string = "";
    public alias: string = "";
    public nroCuenta: string = "";
    public cuit: string = "";
    public titular : boolean=false;
}
