import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'direccionPropiedad',
})
export class DireccionPropiedadPipe implements PipeTransform {

  transform(value: any): string {
    if (!value) {
      return '';
    }

    const departamento = value.departamento ? `Depto.: ${value.departamento}` : '';
    const piso = value.piso ? `Piso: ${value.piso}` : '';
    const ubicacion = value.ubicacion ? `${value.ubicacion}` : '';

    return [ubicacion, piso, departamento].filter(info => info).join(', ');
  }

}
