<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div class="row">
  <h1 mat-dialog-title><strong>Nueva</strong> liquidación</h1>
</div>

<mat-divider></mat-divider>
<br />
<div class="row">
  <div class="col-lg-6">
    <h3>
      <strong>Contrato nro.:</strong> {{ contratoDetailDto.numeroContrato }}
    </h3>
  </div>
  <div class="col-lg-6">
    <h3>
      <strong>Inquilino:</strong>
      {{
      isFalsy(contratoDetailDto.inquilino)
      ? ""
      : contratoDetailDto.inquilino.persona.descripcion | titlecase
      }}
    </h3>
  </div>
</div>
<div class="row">
  <div class="col-lg-6">
    <h3>
      <strong>Propiedad:</strong>
      {{
      (isFalsy(contratoDetailDto.propiedad.ubicacion) ? '' : contratoDetailDto.propiedad.ubicacion) +
      (isFalsy(contratoDetailDto.propiedad.piso) ? '' : ' ' + contratoDetailDto.propiedad.piso) +
      (isFalsy(contratoDetailDto.propiedad.departamento) ? '' : ' ' + contratoDetailDto.propiedad.departamento) | titlecase
      }}
    </h3>
  </div>
  <div class="col-lg-6">
    <div style="display: flex">
      <h3><strong>Propietarios: </strong> &nbsp;</h3>
      <h3 *ngFor="let propietario of contratoDetailDto.propietarios;let i=index;trackBy:trackByItems">
        <h3>
          {{ propietario.persona.descripcion | titlecase }}
          <span *ngIf="contratoDetailDto.propietarios.length > 1">,</span>
        </h3>
      </h3>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<br>
<div class="no-data-table">
  <span class="with-icon" [hidden]="!isFalsy(settlementResponse)"><mat-icon>search_off</mat-icon>No se encontraron datos
    para
    mostrar.</span>
</div>
<div mat-dialog-actions>


  <div *appRole="['Contratos.VisualizaLiquidacion']">
    <button mat-raised-button [disabled]="isFalsy(settlementResponse)" (click)="visualizaLiquidacion()" color="primary">
      Visualiza Liquidación
    </button>
  </div>

  <button mat-button (click)="closeDialog()" mat-dialog-close color="warn">
    Cancelar
  </button>
</div>
