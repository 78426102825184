<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Editar detalle servicio</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="position-relative">
        <ngx-spinner name="spDetServ" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
        <div *ngIf="detalleServicioPropiedadDetailDto != undefined">
            <form [formGroup]="formDetalleServicioPriopidad">
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field>
                            <ngx-spinner name="spBusquedaServicios" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                            <mat-label>Servicios</mat-label>
                            <mat-select placeholder="Seleccione opcion" formControlName="Servicios" (selectionChange)="selectServicio($event)">
                                <mat-option cdkFocusInitial [value]="servicioPropiedadDto.id" *ngFor="let servicioPropiedadDto of lstservicioPropiedadDto ;let i=index;trackBy:trackByItems">

                                    {{servicioPropiedadDto.servicio.descripcion}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="f.Servicios.errors?.required">Este campo es <strong>requerido</strong>.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-md-6">
                        <mat-form-field>
                            <ngx-spinner name="spBusquedaQuienPaga" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                            <mat-label>Quien Paga</mat-label>
                            <mat-select formControlName="QuienPaga" (selectionChange)="selectQuienPaga($event)">
                                <mat-option [value]="quienPaga.id" *ngFor="let quienPaga of lstQuienPaga ;let i=index;trackBy:trackByItems">
                                    {{quienPaga.descripcion}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="f.QuienPaga.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Comision</mat-label>
                            <span matPrefix>% &nbsp;</span>
                            <input matInput name="Comision" appPercentageDirective min="1" max="100" [(ngModel)]="comision" type="text" formControlName="Comision" />
                        </mat-form-field>
                    </div>
                    <div class="form-group col-md-6">
                        <button mat-button (click)="aplicarComision()" mat-stroked-button color="primary">Aplicar sobre
              importe</button>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <mat-form-field class="full-width-x0">
                                    <mat-label>Periodo Mes</mat-label>
                                    <input matInput name="Mes" type="number" maxlength="2" formControlName="Mes" />
                                    <mat-error *ngIf="f.Mes.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-6">
                                <mat-form-field class="full-width-x0">
                                    <mat-label>Periodo Año</mat-label>
                                    <input matInput name="Anio" type="number" maxlength="4" formControlName="Anio" />
                                    <mat-error *ngIf="f.Anio.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Periodo</mat-label>
                            <input matInput name="Periodo" type="number" maxlength="2" formControlName="Periodo" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>1er Vencimiento</mat-label>
                            <input matInput name="Vencimiento1" type="date" formControlName="Vencimiento1" (input)="onVencimiento1Change($event,1)" />
                            <mat-error *ngIf="f.Vencimiento1.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Importe</mat-label>
                            <span matPrefix>$ &nbsp;</span>
                            <input matInput name="ImporteVencimiento1" type="text" mask="separator.2" thousandSeparator="," formControlName="ImporteVencimiento1" />
                            <mat-error *ngIf="f.ImporteVencimiento1.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>2do Vencimiento</mat-label>
                            <input matInput name="Vencimiento2" type="date" formControlName="Vencimiento2" (input)="onVencimiento1Change($event,2)" />
                        </mat-form-field>
                    </div>
                    <div class="form-group col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Importe</mat-label>
                            <span matPrefix>$ &nbsp;</span>
                            <input matInput name="ImporteVencimiento2" type="text" mask="separator.2" thousandSeparator="," formControlName="ImporteVencimiento2" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>3er Vencimiento</mat-label>
                            <input matInput name="Vencimiento3" type="date" formControlName="Vencimiento3" (input)="onVencimiento1Change($event,3)" />
                        </mat-form-field>
                    </div>
                    <div class="form-group col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Importe</mat-label>
                            <span matPrefix>$ &nbsp;</span>
                            <input matInput name="ImporteVencimiento3" type="text" mask="separator.2" thousandSeparator="," formControlName="ImporteVencimiento3" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>4to Vencimiento</mat-label>
                            <input matInput name="Vencimiento4" type="date" formControlName="Vencimiento4" (input)="onVencimiento1Change($event,4)" />
                        </mat-form-field>
                    </div>
                    <div class="form-group col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Importe</mat-label>
                            <span matPrefix>$ &nbsp;</span>
                            <input matInput name="ImporteVencimiento4" type="text" mask="separator.2" thousandSeparator="," formControlName="ImporteVencimiento4" />
                        </mat-form-field>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button *appRole="['DetalleServicios.Modificar']" (click)="editarDetalle()" color="primary" [disabled]="!formDetalleServicioPriopidad.valid ">Guardar</button>
    <button mat-button (click)="closeDialog()" mat-dialog-close color="warn">Cancelar</button>
</div>
