import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContratosModule } from './contratos.module';
import { SharedModule } from './shared.module';
import { CalculaIndiceAjustePeriodosContratoComponent } from '@views/procesos/calcula-indice-ajuste-periodos-contrato/calcula-indice-ajuste-periodos-contrato/calcula-indice-ajuste-periodos-contrato.component';
import { DialogCalculaIndiceAjustePeriodoContrato } from '@views/procesos/calcula-indice-ajuste-periodos-contrato/dialog-calcula-indice-ajuste-periodo-contrato/dialog-calcula-indice-ajuste-periodo-contrato.component';
import { BtnCalculaIndiceAjustePeriodoContrato } from '@views/procesos/calcula-indice-ajuste-periodos-contrato/btn-calcula-indice-ajuste-periodo-contrato/btn-calcula-indice-ajuste-periodo-contrato.component';

@NgModule({
  declarations: [
    CalculaIndiceAjustePeriodosContratoComponent,
    DialogCalculaIndiceAjustePeriodoContrato,
    BtnCalculaIndiceAjustePeriodoContrato,

  ],
  imports: [
    CommonModule,
    SharedModule,
    ContratosModule
  ],
  exports: [
    CalculaIndiceAjustePeriodosContratoComponent,
    DialogCalculaIndiceAjustePeriodoContrato,
    BtnCalculaIndiceAjustePeriodoContrato,
  ]
})
export class ProcesosModule { }
