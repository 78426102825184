import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnNuevoTipoMovimientoComponent } from '../views/tipos-movimientos/btn-nuevo-tipo-movimiento/btn-nuevo-tipo-movimiento.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { ActualizarTipoMovimientoComponent } from '@views/tipos-movimientos/actualizar-tipo-movimiento/actualizar-tipo-movimiento.component';
import { CrearTipoMovimientoComponent } from '@views/tipos-movimientos/crear-tipo-movimiento/crear-tipo-movimiento.component';
import { EliminarTipoMovimientoComponent } from '@views/tipos-movimientos/eliminar-tipo-movimiento/eliminar-tipo-movimiento.component';
import { ListarTipoMovimientoComponent } from '@views/tipos-movimientos/listar-tipo-movimiento/listar-tipo-movimiento.component';
import { DialogViewTipoMovimientoComponent } from '@views/tipos-movimientos/ver-tipo-movimiento/dialog-view-tipo-movimiento/dialog-view-tipo-movimiento.component';
import { DialogCreateTipoMovimientoComponent } from '@views/tipos-movimientos/crear-tipo-movimiento/dialog-create-tipo-movimiento/dialog-create-tipo-movimiento.component';
import { DialogUpdateTipoMovimientoComponent } from '@views/tipos-movimientos/actualizar-tipo-movimiento/dialog-update-tipo-movimiento/dialog-update-tipo-movimiento.component';
import { DialogDeleteTipoMovimientoComponent } from '@views/tipos-movimientos/eliminar-tipo-movimiento/dialog-delete-tipo-movimiento/dialog-delete-tipo-movimiento.component';
import { VerTipoMovimientoComponent } from '@views/tipos-movimientos/ver-tipo-movimiento/ver-tipo-movimiento.component';
import { TiposMovimientoComponent } from '@views/tipos-movimientos/tipos-movimiento/tipos-movimiento.component';
import { DialogTipoMovimientoComponent } from '@views/tipos-movimientos/dialog-tipo-movimiento/dialog-tipo-movimiento.component';



@NgModule({
  declarations: [
    DialogUpdateTipoMovimientoComponent,
    DialogCreateTipoMovimientoComponent,
    DialogViewTipoMovimientoComponent,
    DialogDeleteTipoMovimientoComponent,
    BtnNuevoTipoMovimientoComponent,
    ListarTipoMovimientoComponent,
    EliminarTipoMovimientoComponent,
    CrearTipoMovimientoComponent,
    ActualizarTipoMovimientoComponent,
    VerTipoMovimientoComponent,
    TiposMovimientoComponent,
    DialogTipoMovimientoComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[
    DialogUpdateTipoMovimientoComponent,
    DialogCreateTipoMovimientoComponent,
    DialogViewTipoMovimientoComponent,
    DialogDeleteTipoMovimientoComponent,
    BtnNuevoTipoMovimientoComponent,
    ListarTipoMovimientoComponent,
    EliminarTipoMovimientoComponent,
    CrearTipoMovimientoComponent,
    ActualizarTipoMovimientoComponent,
    VerTipoMovimientoComponent,
    TiposMovimientoComponent,
    DialogTipoMovimientoComponent
  ]
})
export class TiposMovimientoModule { }
