import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Editor, Toolbar } from 'ngx-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParametrosConfiguracion } from '@models/base/identificadores.model';
import { CuentaContableDto } from '@models/cuentas-contables/cuentaContableDto.model';
import { ParametroSistemaDto } from '@models/parametros-sistema/parametroSistemaDto.model';
import { ParametroSistemaDescripcion } from '@models/parametros-sistema/parametrosSistemaDescipcion.model';
import { UpdateParametroSistemaRequest } from '@models/parametros-sistema/updateParametroSistemaRequest.model';
import { CuentasContablesService } from '@services/cuentas-contables/cuentas-contables.service';
import { ParametrosSistemaService } from '@services/parametro-sistema/parametros-sistema.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {
  errorMessage: string = "";
  lstParametrosSistema: ParametroSistemaDto[] = []
  lstCuentasContable: CuentaContableDto[] = []
  parametroSistemaDescripcion: ParametroSistemaDescripcion = new ParametroSistemaDescripcion
  formParametrosGenerales: FormGroup;
  formParametrosComisiones: FormGroup;
  formParametrosContables: FormGroup;
  formParametrosCorreo: FormGroup;
  formNotificaciones: FormGroup;
  formComprobantes: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  isLoading = false
  SslTls!: boolean;
  editorResumen!: Editor
  editorRecibo!: Editor
  editorLiquidacion!: Editor
  toolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  get paramGeneral() { return this.formParametrosGenerales.controls; }
  get paramContable() { return this.formParametrosContables.controls; }
  get paramComision() { return this.formParametrosComisiones.controls; }
  get paramNotificaciones() { return this.formNotificaciones.controls; }
  get paramComprobantes() { return this.formComprobantes.controls; }
  get paramCorreo() { return this.formParametrosCorreo.controls; }

  constructor(
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private parametrosSistemaService: ParametrosSistemaService,
    private cuentasContableService: CuentasContablesService
  ) {

    this.formParametrosGenerales = this.formBuilder.group({
      CodigoAlquiler: ['', Validators.compose([Validators.required])],
      DuracionMesesArchivosTemporales: ['',Validators.compose([Validators.required])],
    });

    this.formParametrosContables = this.formBuilder.group({
      Iva: ['', Validators.compose([Validators.required])],
      Caja: ['', Validators.compose([Validators.required])],
      CuentaContableDefecto: ['',Validators.compose([Validators.required])]
    });

    this.formParametrosComisiones = this.formBuilder.group({
      ComisionGananciaInmobiliaria: [''],
      ComisionGananciaPropietario: [''],
    }, {

    });

    this.formNotificaciones = this.formBuilder.group({
      DiasFinalizaContrato: ['',Validators.compose([Validators.required])],
      CantidadMesesDeudaPropiedad: ['',Validators.compose([Validators.required])],
      FechaGenLiquizacion: ['',Validators.compose([Validators.required])],
      FechaGenResumen: ['',Validators.compose([Validators.required])],
      DuracionAlertas: [''],
    });

    this.formComprobantes = this.formBuilder.group({
      NotaResumen: [''],
      NotaRecibo: [''],
      NotaLiquidacion: ['']
    });

    this.formParametrosCorreo = this.formBuilder.group({
      SmtpServer: ['', Validators.compose([Validators.required])],
      Port: [0, Validators.compose([Validators.required])],
      Sender: ['', Validators.compose([Validators.required])],
      Username: ['', Validators.compose([Validators.required])],
      Password: ['', Validators.compose([Validators.required])],
      SslTls: [false]
    });
  }

  ngOnInit(): void {
    this.editorResumen = new Editor();
    this.editorRecibo = new Editor();
    this.editorLiquidacion = new Editor();
    this.getAllCuentasContable()
    this.getAllParametros()

    let parametroSistemaValues = this.parametrosSistemaService.getParametrosSistemasValues()
  }

  setParametroInFormularios(lstParametrosSistema: ParametroSistemaDto[]) {
    this.parametroSistemaDescripcion = new ParametroSistemaDescripcion()
    lstParametrosSistema.forEach(element => {

      switch (element.id) {
        //Correo
        case ParametrosConfiguracion.Sender:
          this.formParametrosCorreo.controls["Sender"].setValue(element.valor)
          this.parametroSistemaDescripcion.Sender = element.descripcion
          break
        case ParametrosConfiguracion.SmtpServer:
          this.formParametrosCorreo.controls["SmtpServer"].setValue(element.valor)
          this.parametroSistemaDescripcion.SmtpServer = element.descripcion
          break
        case ParametrosConfiguracion.Port:
          this.formParametrosCorreo.controls["Port"].setValue(element.valor)
          this.parametroSistemaDescripcion.Port = element.descripcion
          break
        case ParametrosConfiguracion.Username:
          this.formParametrosCorreo.controls["Username"].setValue(element.valor)
          this.parametroSistemaDescripcion.Username = element.descripcion
          break
        case ParametrosConfiguracion.Password:
          this.formParametrosCorreo.controls["Password"].setValue(element.valor)
          this.parametroSistemaDescripcion.Password = element.descripcion
          break
        case ParametrosConfiguracion.SslTls:
          const SslTls = JSON.parse(element.valor)
          this.formParametrosCorreo.controls["SslTls"].setValue(SslTls)
          this.parametroSistemaDescripcion.SslTls = element.descripcion
          break
        //Notificaciones
        case ParametrosConfiguracion.FechaGenLiquizacion:
          this.formNotificaciones.controls["FechaGenLiquizacion"].setValue(element.valor)
          this.parametroSistemaDescripcion.FechaGenLiquizacion = element.descripcion
          break
        case ParametrosConfiguracion.FechaGenResumen:
          this.formNotificaciones.controls["FechaGenResumen"].setValue(element.valor)
          this.parametroSistemaDescripcion.FechaGenResumen = element.descripcion
          break
        case ParametrosConfiguracion.DiasFinalizaContrato:
          this.formNotificaciones.controls["DiasFinalizaContrato"].setValue(element.valor)
          this.parametroSistemaDescripcion.DiasFinalizaContrato = element.descripcion
          break
        case ParametrosConfiguracion.CantidadMesesDeudaPropiedad:
          this.formNotificaciones.controls["CantidadMesesDeudaPropiedad"].setValue(element.valor)
          this.parametroSistemaDescripcion.CantidadMesesDeudaPropiedad = element.descripcion
          break
        case ParametrosConfiguracion.DuracionAlertas:
            this.formNotificaciones.controls["DuracionAlertas"].setValue(element.valor)
            this.parametroSistemaDescripcion.DuracionAlertas = element.descripcion
            break
        //Comprobantes
        case ParametrosConfiguracion.NotaResumen:
          this.formComprobantes.controls["NotaResumen"].setValue(element.valor)
          this.parametroSistemaDescripcion.NotaResumen = element.descripcion
          break
        case ParametrosConfiguracion.NotaRecibo:
          this.formComprobantes.controls["NotaRecibo"].setValue(element.valor)
          this.parametroSistemaDescripcion.NotaRecibo = element.descripcion
          break
        case ParametrosConfiguracion.NotaLiquidacion:
          this.formComprobantes.controls["NotaLiquidacion"].setValue(element.valor)
          this.parametroSistemaDescripcion.NotaLiquidacion = element.descripcion
          break
        //Parametros Contables
        case ParametrosConfiguracion.Iva:
          this.formParametrosContables.controls["Iva"].setValue(element.valor)
          this.parametroSistemaDescripcion.Iva = element.descripcion
          break
        case ParametrosConfiguracion.Caja:
          this.formParametrosContables.controls["Caja"].setValue(element.valor)
          this.parametroSistemaDescripcion.Caja = element.descripcion
          break
        case ParametrosConfiguracion.CuentaContableDefecto:
          this.formParametrosContables.controls["CuentaContableDefecto"].setValue(parseInt(element.valor))
          this.parametroSistemaDescripcion.CuentaContableDefecto = element.descripcion
          break
        //Comisiones
        case ParametrosConfiguracion.ComisionGananciaInmobiliaria:
          this.formParametrosComisiones.controls["ComisionGananciaInmobiliaria"].setValue(element.valor)
          this.parametroSistemaDescripcion.ComisionGananciaInmobiliaria = element.descripcion
          break
        case ParametrosConfiguracion.ComisionGananciaPropietario:
          this.formParametrosComisiones.controls["ComisionGananciaPropietario"].setValue(element.valor)
          this.parametroSistemaDescripcion.ComisionGananciaPropietario = element.descripcion
          break
        //General
        case ParametrosConfiguracion.CodigoAlquiler:
          this.formParametrosGenerales.controls["CodigoAlquiler"].setValue(element.valor)
          this.parametroSistemaDescripcion.CodigoAlquiler = element.descripcion
          break
        case ParametrosConfiguracion.DuracionMesesArchivosTemporales:
          this.formParametrosGenerales.controls["DuracionMesesArchivosTemporales"].setValue(element.valor)
          this.parametroSistemaDescripcion.DuracionMesesArchivosTemporales = element.descripcion
          break
        default:
          break
      }
    });

  }

  ngOnDestroy(): void {
    this.editorResumen.destroy();
    this.editorRecibo.destroy();
    this.editorLiquidacion.destroy();
  }

  getAllParametros() {
    this.spinner.show()
    this.parametrosSistemaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.lstParametrosSistema = data
          this.setParametroInFormularios(this.lstParametrosSistema)
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      );
  }

  getAllCuentasContable() {
    this.spinner.show()
    this.cuentasContableService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.lstCuentasContable = data
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      );
  }

  onSubmitParametrosGenerales() {

    let updateParametroSistemaRequest: UpdateParametroSistemaRequest = new UpdateParametroSistemaRequest
    let lstParametroSistemaDto: ParametroSistemaDto[] = []

    let CodigoAlquiler: ParametroSistemaDto = new ParametroSistemaDto
    CodigoAlquiler.nombre = ParametrosConfiguracion.CodigoAlquiler.toString()
    CodigoAlquiler.id = ParametrosConfiguracion.CodigoAlquiler as number
    CodigoAlquiler.nombre = "CodigoAlquiler"
    CodigoAlquiler.valor = this.formParametrosGenerales.controls["CodigoAlquiler"].value
    lstParametroSistemaDto.push(CodigoAlquiler)

    let DuracionMesesArchivosTemporales: ParametroSistemaDto = new ParametroSistemaDto
    DuracionMesesArchivosTemporales.nombre = ParametrosConfiguracion.DuracionMesesArchivosTemporales.toString()
    DuracionMesesArchivosTemporales.id = ParametrosConfiguracion.DuracionMesesArchivosTemporales as number
    DuracionMesesArchivosTemporales.nombre = "DuracionMesesArchivosTemporales"
    DuracionMesesArchivosTemporales.valor = this.formParametrosGenerales.controls["DuracionMesesArchivosTemporales"].value
    lstParametroSistemaDto.push(DuracionMesesArchivosTemporales)

    updateParametroSistemaRequest.parametrosSistema = lstParametroSistemaDto

    this.actualizarParametros(updateParametroSistemaRequest)
  }

  onSubmitParametrosContables() {

    let updateParametroSistemaRequest: UpdateParametroSistemaRequest = new UpdateParametroSistemaRequest
    let lstParametroSistemaDto: ParametroSistemaDto[] = []

    let Iva: ParametroSistemaDto = new ParametroSistemaDto
    Iva.id = ParametrosConfiguracion.Iva
    Iva.nombre = "Iva"
    Iva.valor = this.formParametrosContables.controls["Iva"].value
    lstParametroSistemaDto.push(Iva)

    let Caja: ParametroSistemaDto = new ParametroSistemaDto
    Caja.id = ParametrosConfiguracion.Caja
    Caja.nombre = "Caja"
    Caja.valor = this.formParametrosContables.controls["Caja"].value
    lstParametroSistemaDto.push(Caja)

    let CuentaContableDefecto: ParametroSistemaDto = new ParametroSistemaDto
    CuentaContableDefecto.id = ParametrosConfiguracion.CuentaContableDefecto
    CuentaContableDefecto.nombre = "CuentaContableDefecto"
    CuentaContableDefecto.valor = this.formParametrosContables.controls["CuentaContableDefecto"].value
    lstParametroSistemaDto.push(CuentaContableDefecto)

    updateParametroSistemaRequest.parametrosSistema = lstParametroSistemaDto

    this.actualizarParametros(updateParametroSistemaRequest)
  }

  onSubmitParametrosComisiones() {

    let updateParametroSistemaRequest: UpdateParametroSistemaRequest = new UpdateParametroSistemaRequest
    let lstParametroSistemaDto: ParametroSistemaDto[] = []

    let comisionGananciaInmobiliaria: ParametroSistemaDto = new ParametroSistemaDto
    comisionGananciaInmobiliaria.id = ParametrosConfiguracion.ComisionGananciaInmobiliaria
    comisionGananciaInmobiliaria.nombre = "ComisionGananciaInmobiliaria"
    comisionGananciaInmobiliaria.valor = this.formParametrosComisiones.controls["ComisionGananciaInmobiliaria"].value
    lstParametroSistemaDto.push(comisionGananciaInmobiliaria)

    let comisionGananciaPropietario: ParametroSistemaDto = new ParametroSistemaDto
    comisionGananciaPropietario.id = ParametrosConfiguracion.ComisionGananciaPropietario
    comisionGananciaPropietario.nombre = "ComisionGananciaPropietario"
    comisionGananciaPropietario.valor = this.formParametrosComisiones.controls["ComisionGananciaPropietario"].value
    lstParametroSistemaDto.push(comisionGananciaPropietario)

    updateParametroSistemaRequest.parametrosSistema = lstParametroSistemaDto

    this.actualizarParametros(updateParametroSistemaRequest)
  }

  onSubmitParametrosNotificaciones() {

    let updateParametroSistemaRequest: UpdateParametroSistemaRequest = new UpdateParametroSistemaRequest
    let lstParametroSistemaDto: ParametroSistemaDto[] = []


    let FechaGenLiquizacion: ParametroSistemaDto = new ParametroSistemaDto
    FechaGenLiquizacion.nombre = ParametrosConfiguracion.FechaGenLiquizacion.toString()
    FechaGenLiquizacion.id = ParametrosConfiguracion.FechaGenLiquizacion as number
    FechaGenLiquizacion.nombre = "FechaGenLiquizacion"
    FechaGenLiquizacion.valor = this.formNotificaciones.controls["FechaGenLiquizacion"].value
    lstParametroSistemaDto.push(FechaGenLiquizacion)

    let FechaGenResumen: ParametroSistemaDto = new ParametroSistemaDto
    FechaGenResumen.nombre = ParametrosConfiguracion.FechaGenResumen.toString()
    FechaGenResumen.id = ParametrosConfiguracion.FechaGenResumen as number
    FechaGenResumen.nombre = "FechaGenResumen"
    FechaGenResumen.valor = this.formNotificaciones.controls["FechaGenResumen"].value
    lstParametroSistemaDto.push(FechaGenResumen)

    let diasFinalizaContrato: ParametroSistemaDto = new ParametroSistemaDto
    diasFinalizaContrato.id = ParametrosConfiguracion.DiasFinalizaContrato
    diasFinalizaContrato.nombre = "DiasFinalizaContrato"
    diasFinalizaContrato.valor = this.formNotificaciones.controls["DiasFinalizaContrato"].value
    lstParametroSistemaDto.push(diasFinalizaContrato)

    let cantidadMesesDeudaPropiedad: ParametroSistemaDto = new ParametroSistemaDto
    cantidadMesesDeudaPropiedad.id = ParametrosConfiguracion.CantidadMesesDeudaPropiedad
    cantidadMesesDeudaPropiedad.nombre = "CantidadMesesDeudaPropiedad"
    cantidadMesesDeudaPropiedad.valor = this.formNotificaciones.controls["CantidadMesesDeudaPropiedad"].value
    lstParametroSistemaDto.push(cantidadMesesDeudaPropiedad)

    let duracionAlertas: ParametroSistemaDto = new ParametroSistemaDto
    duracionAlertas.id = ParametrosConfiguracion.DuracionAlertas
    duracionAlertas.nombre = "DuracionAlertas"
    duracionAlertas.valor = this.formNotificaciones.controls["DuracionAlertas"].value
    lstParametroSistemaDto.push(duracionAlertas)

    updateParametroSistemaRequest.parametrosSistema = lstParametroSistemaDto

    this.actualizarParametros(updateParametroSistemaRequest)
  }

  onSubmitParametrosComprobantes() {

    let updateParametroSistemaRequest: UpdateParametroSistemaRequest = new UpdateParametroSistemaRequest
    let lstParametroSistemaDto: ParametroSistemaDto[] = []

    let notaResumen: ParametroSistemaDto = new ParametroSistemaDto
    notaResumen.id = ParametrosConfiguracion.NotaResumen
    notaResumen.nombre = "NotaResumen"
    notaResumen.valor = this.formComprobantes.controls["NotaResumen"].value
    lstParametroSistemaDto.push(notaResumen)

    let notaRecibo: ParametroSistemaDto = new ParametroSistemaDto
    notaRecibo.id = ParametrosConfiguracion.NotaRecibo
    notaRecibo.nombre = "NotaRecibo"
    notaRecibo.valor = this.formComprobantes.controls["NotaRecibo"].value
    lstParametroSistemaDto.push(notaRecibo)

    let notaLiquidacion: ParametroSistemaDto = new ParametroSistemaDto
    notaLiquidacion.id = ParametrosConfiguracion.NotaLiquidacion
    notaLiquidacion.nombre = "NotaLiquidacion"
    notaLiquidacion.valor = this.formComprobantes.controls["NotaLiquidacion"].value
    lstParametroSistemaDto.push(notaLiquidacion)

    updateParametroSistemaRequest.parametrosSistema = lstParametroSistemaDto

    this.actualizarParametros(updateParametroSistemaRequest)
  }

  onSubmitParametrosCorreo() {

    let updateParametroSistemaRequest: UpdateParametroSistemaRequest = new UpdateParametroSistemaRequest
    let lstParametroSistemaDto: ParametroSistemaDto[] = []

    let Sender: ParametroSistemaDto = new ParametroSistemaDto
    Sender.id = ParametrosConfiguracion.Sender
    Sender.nombre = "Sender"
    Sender.valor = this.formParametrosCorreo.controls["Sender"].value
    lstParametroSistemaDto.push(Sender)

    let SmtpServer: ParametroSistemaDto = new ParametroSistemaDto
    SmtpServer.id = ParametrosConfiguracion.SmtpServer
    SmtpServer.nombre = "SmtpServer"
    SmtpServer.valor = this.formParametrosCorreo.controls["SmtpServer"].value
    lstParametroSistemaDto.push(SmtpServer)

    let Port: ParametroSistemaDto = new ParametroSistemaDto
    Port.id = ParametrosConfiguracion.Port
    Port.nombre = "Port"
    Port.valor = this.formParametrosCorreo.controls["Port"].value
    lstParametroSistemaDto.push(Port)

    let Username: ParametroSistemaDto = new ParametroSistemaDto
    Username.id = ParametrosConfiguracion.Username
    Username.nombre = "Username"
    Username.valor = this.formParametrosCorreo.controls["Username"].value
    lstParametroSistemaDto.push(Username)

    let Password: ParametroSistemaDto = new ParametroSistemaDto
    Password.id = ParametrosConfiguracion.Password
    Password.nombre = "Password"
    Password.valor = this.formParametrosCorreo.controls["Password"].value
    lstParametroSistemaDto.push(Password)

    let SslTls: ParametroSistemaDto = new ParametroSistemaDto
    SslTls.id = ParametrosConfiguracion.SslTls
    SslTls.nombre = "SslTls"
    SslTls.valor = this.formParametrosCorreo.controls["SslTls"].value
    lstParametroSistemaDto.push(SslTls)

    updateParametroSistemaRequest.parametrosSistema = lstParametroSistemaDto

    this.actualizarParametros(updateParametroSistemaRequest)
  }

  actualizarParametros(updateParametroSistemaRequest: UpdateParametroSistemaRequest) {
    this.isLoading = true
    this.parametrosSistemaService.update(updateParametroSistemaRequest)
      .subscribe(
        data => {
          this.isLoading = false
          this.snackBar.showSuccess("Parametros actualizados correctamente.", "Exito");
        },
        error => {
          this.isLoading = false
          this.snackBar.showError(error, "Error");
        }
      )
  }

  trackByItems(index: number, item: any): any { return item; }

}
