import { BaseDto } from "./baseDto.model";
import { RangoFechas } from "./identificadores.model";

export class Genericos{
  static lstRangoFecha: BaseDto[] = [
    {
      id: 8.64e+6,
      descripcion: RangoFechas.Hoy
    },
    {
      id: 8.64e+7,
      descripcion: RangoFechas.Diario
    },
    {
      id: 2.592e+9,
      descripcion: RangoFechas.Mensual
    },
    {
      id: 5.184e+9,
      descripcion: RangoFechas.Bimestral
    },
    {
      id: 7.776e+9,
      descripcion: RangoFechas.Trimestral
    },
    {
      id: 1.555e+10,
      descripcion: RangoFechas.Semestral
    },
    {
      id: 3.154e+10,
      descripcion: RangoFechas.Anual
    },
    {
      id: 3.154e+11,
      descripcion: RangoFechas.Todos
    }
  ]
}
