import { Component, OnInit } from '@angular/core';
import { Roles } from '@models/base/identificadores.model';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { LoginService } from '@services/login/login.service';
import { PersonasService } from '@services/personas/personas.service';

@Component({
  selector: 'app-cuentas-contable-persona',
  templateUrl: './cuentas-contable-persona.component.html',
  styleUrls: ['./cuentas-contable-persona.component.css']
})
export class CuentasContablePersonaComponent implements OnInit {
  usuarioCliente: boolean = false;
  personaDetailDto!: PersonaDetailDto;

  constructor(
    private loginService: LoginService,
    private personasService: PersonasService,
  ) { }

  ngOnInit(): void {
    if (this.loginService.getIdUserRol() == Roles.Clientes)
      this.usuarioCliente = true

    this.personaDetailDto = this.personasService.getPersona()

  }

}
