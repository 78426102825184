import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntidadDto } from '@models/base/entidadDto.model';
import { Identificador, TiposEntidad } from '@models/base/identificadores.model';

@Component({
  selector: 'app-documentos-contratos',
  templateUrl: './documentos-contratos.component.html',
  styleUrls: ['./documentos-contratos.component.css']
})
export class DocumentosContratosComponent implements OnInit {

  entidad:EntidadDto = new EntidadDto
  idContrato!: number;
  idTipoEntidad!: number;
  idEntidad!: number;
  documentoChange!:number;
  constructor(
    private aRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idContrato = Number(routeParams.get('id'));
    this.idEntidad = this.idContrato
    this.idTipoEntidad = TiposEntidad.Contratos
  }

  onDocumentoChange(documentoChange: number) {
    this.documentoChange = documentoChange;
  }

}
