import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog, EstadosContrato, RangoFechasValores } from '@models/base/identificadores.model';
import { ResindirContratoRequest } from '@models/contrato/resindirContratoRequest.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogGenerarResumenComponent } from '../form-editar-contrato/dialog-generar-resumen/dialog-generar-resumen.component';
import { DialogNuevaLiquidacionComponent } from '../form-editar-contrato/dialog-nueva-liquidacion/dialog-nueva-liquidacion.component';
import { DialogRescisionContratoComponent } from '../form-editar-contrato/dialog-rescision-contrato/dialog-rescision-contrato.component';
import { isFalsy } from 'utility-types';
import { DialogImputacionPropietarioComponent } from '@views/propietarios/dialog-imputacion-propietario/dialog-imputacion-propietario.component';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { EstadosContratoService } from '@services/estados-contratos/estados-contrato.service';
import { BaseDto } from '@models/base/baseDto.model';
import { TiposContratoService } from '@services/tipos-contrato/tipos-contrato.service';
import { ContratoFilter } from '@models/contrato/contratoFilter.model';
import { PersonaPropietarioDto } from '@models/propietarios/personaPropietarioDto.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { DatePipe } from '@angular/common';
import { TipoAjusteContratoService } from '@services/tipo-ajuste-contrato/tipoAjusteContrato.service';
import { TipoAjusteContratoDto } from '@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model';
import { DialogNuevoReciboComponent } from '../dialog-nuevo-recibo/dialog-nuevo-recibo/dialog-nuevo-recibo.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Console } from 'console';
import { DialogGenerarResumenMensualComponent } from '@views/generar-resumen-mensual/dialog-generar-resumen-mensual/dialog-generar-resumen-mensual.component';

export class ContratoLista {



  public id: number = 0
  public numeroContrato: number = 0
  public legajo: string = ""
  public propiedadNombre: string = ""
  public propiedadId: number = 0
  public inquilinoNombre: string = ""
  public inquilinoId: number = 0
  public propietarioId: number = 0
  public propietarioNombre: string = ""
  public porcentajeDiasCompletados: number = 0
  public tipoContrato: string = ""
  public fechaInicio: string = ""
  public fechaFin: string = ""
  public estadoNombre: string = ""
  public estadoId: number = 0
  public tipoAjuste: string = ""
  public contratoDto!: ContratoDetailDto
}

@Component({
  selector: 'app-listado-contratos',
  templateUrl: './listado-contratos.component.html',
  styleUrls: ['./listado-contratos.component.css'],
  providers: [DatePipe]
})


export class ListadoContratosComponent implements OnInit {

  data = false
  dataSource = new MatTableDataSource<ContratoLista>();
  displayedColumns: string[] = [];
  estadosContrato!: typeof EstadosContrato;
  porcentajeDiasCompletados: number = 0
  filterValues: any = {};
  filterSelectObjEstados: { name: string; columnProp: string; options: never[]; }[];
  contratoLista: ContratoLista[] = [];
  inquilinoNombre: string = ""
  filter: any;
  tipoBusqueda: string = "";
  @Input() idInquilino: number = 0
  @Input() idPropiedad: number = 0
  formControlBuscar = new FormControl('');
  selectTipoBusquedaControl!: string;

  private paginator!: MatPaginator;
  private sort!: MatSort;
  lstEstadosContrato!: BaseDto[];
  form: FormGroup;
  formBuilder = new FormBuilder
  lstTiposContrato!: BaseDto[];
  checkedFiltroAvanzado: boolean = false;
  rangoFecha: number = RangoFechasValores.Hoy // Hoy
  personaPropietarioDto?: PersonaPropietarioDto;
  propiedadBaseDto?: BaseDto;
  inquilinoDto?: InquilinoDto;
  idPropietario?: number;
  lstTiposAjuste!: TipoAjusteContratoDto[];
  selection = new SelectionModel<ContratoLista>(true, []);  // Permite selección múltiple


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  constructor(
    private dialog: MatDialog,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private contratosService: ContratosService,
    private personasService: PersonasService,
    private estadosContratoService: EstadosContratoService,
    private tiposContratoService: TiposContratoService,
    private tipoAjusteContratoService: TipoAjusteContratoService,
    private router: Router,
    private datepipe: DatePipe,
  ) {

    this.form = this.formBuilder.group({
      TiposContrato: [''],
      TiposAjuste: [''],
      EstadosContrato: [''],
      NumeroContrato: [''],
      TiposLiquidacion: [''],
      FechaDesde: [''],
      FechaHasta: [''],
      Recindido: [''],
      Finalizado: [''],
    })

    this.filterSelectObjEstados = [
      {
        name: 'Tipo Contrato',
        columnProp: 'tipoContrato',
        options: []
      },
      {
        name: 'Estado Contrato',
        columnProp: 'estadoNombre',
        options: []
      }

    ]
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["select", "propiedadNombre", "numeroContrato", "accion"] :
        ["select", "propiedadNombre", "estadoNombre", "numeroContrato", "inquilinoNombre", "propietarioNombre", "duracion", "tipoContrato", "tipoAjuste", "accion"];
    });
  }



  ngOnInit() {
    this.getAllEstadosContrato()
    this.getAllTiposContrato()
    this.getAllTiposAjuste();

    if (this.idInquilino && !isFalsy(this.idInquilino)) {
      this.dataSource.data = [];
      this.getAllByIdInquilinoAndIdPropiedad(this.idInquilino, 0)
    }

    if (this.idPropiedad && !isFalsy(this.idPropiedad)) {
      this.dataSource.data = [];
      this.getAllByIdInquilinoAndIdPropiedad(0, this.idPropiedad)
    }

    if (this.idPropietario && !isFalsy(this.idPropietario)) {
      this.dataSource.data = [];
      this.getAllByIdInquilinoAndIdPropiedad(0, this.idPropiedad)
    }


    this.estadosContrato = EstadosContrato
    this.filter = { name: "propiedadNombre", columnProp: "propiedadNombre", options: [] }
    this.tipoBusqueda = "Propiedad"
    this.selectTipoBusquedaControl = "3"
    this.dataSource.filterPredicate = this.createFilter();
  }

  clearFilter() {
    this.clearSelection()
    if (this.idInquilino > 0)
      this.getAllByIdInquilinoAndIdPropiedad(this.idInquilino, 0)
    if (this.idPropiedad > 0)
      this.getAllByIdInquilinoAndIdPropiedad(0, this.idPropiedad)
    if (!isFalsy(this.idPropietario))
      this.getAllByIdInquilinoAndIdPropiedad(0, this.idPropiedad)

    this.checkedFiltroAvanzado = false
    this.form.controls["Recindido"].setValue(false)
    this.form.controls["Finalizado"].setValue(false)
    this.form.controls["NumeroContrato"].setValue(null)
    this.form.controls["FechaDesde"].setValue(null)
    this.form.controls["FechaHasta"].setValue(null)
    this.form.controls["EstadosContrato"].setValue(null)
    this.form.controls["TiposContrato"].setValue(null)
    this.form.controls["TiposAjuste"].setValue(null)

    this.dataSource.data = []
    this.data = false
  }

  filterContratos() {
    this.clearSelection()
    let idsEstadosContrato = isFalsy(this.form.controls["EstadosContrato"].value) ? null : this.form.controls["EstadosContrato"].value
    let idsTipoContrato = isFalsy(this.form.controls["TiposContrato"].value) ? null : this.form.controls["TiposContrato"].value
    let idsTipoAjusteContrato = isFalsy(this.form.controls["TiposAjuste"].value) ? null : this.form.controls["TiposAjuste"].value
    let idsTipoLiquidacion = isFalsy(this.form.controls["TiposLiquidacion"].value) ? null : this.form.controls["TiposLiquidacion"].value
    let nroContrato = isFalsy(this.form.controls["NumeroContrato"].value) ? null : this.form.controls["NumeroContrato"].value
    //let recindido = isFalsy(this.form.controls["Recindido"].value) ? null : this.form.controls["Recindido"].value
    //let finalizado = isFalsy(this.form.controls["Finalizado"].value) ? null : this.form.controls["Finalizado"].value


    //Fijos
    let fechaEmisionDesde = isFalsy(this.form.controls["FechaDesde"].value) ? null : this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;

    let fechaEmisionHasta = isFalsy(this.form.controls["FechaHasta"].value) ? null : this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;

    //Variables
    let idContrato = null
    let idInquilino = isFalsy(this.inquilinoDto) ? null : this.inquilinoDto.id
    let idPropiedad = isFalsy(this.propiedadBaseDto) ? null : this.propiedadBaseDto.id
    let idPropietario = isFalsy(this.personaPropietarioDto) ? null : this.personaPropietarioDto.id_Propietario

    let contratoFilter = new ContratoFilter
    contratoFilter.idContrato = idContrato
    contratoFilter.idInquilino = idInquilino
    contratoFilter.idPropiedad = idPropiedad
    contratoFilter.idPropietario = idPropietario
    contratoFilter.fechaComienzoDesde = fechaEmisionDesde
    contratoFilter.fechaComienzoHasta = fechaEmisionHasta
    contratoFilter.idsEstadoContrato = idsEstadosContrato
    contratoFilter.idsTipoContrato = idsTipoContrato
    contratoFilter.idsTipoLiquidacion = idsTipoLiquidacion
    contratoFilter.numeroContrato = nroContrato
    contratoFilter.idsTipoAjusteContrato = idsTipoAjusteContrato
    contratoFilter.idsFormaPagoPropietario = null

    this.getAllByFilter(contratoFilter)
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  selectTipoBusqueda(event: any) {
    switch (event) {
      case "0":
        this.filter = { name: "numeroContrato", columnProp: "numeroContrato", options: [] }
        this.tipoBusqueda = "Nro. Contrato"
        break;
      case "1":
        this.filter = { name: "inquilinoNombre", columnProp: "inquilinoNombre", options: [] }
        this.tipoBusqueda = "Inquilino"
        break;
      case "2":
        this.filter = { name: "propietarioNombre", columnProp: "propietarioNombre", options: [] }
        this.tipoBusqueda = "Propietario"
        break;
      case "3":
        this.filter = { name: "propiedadNombre", columnProp: "propiedadNombre", options: [] }
        this.tipoBusqueda = "Propiedad"
        break;
      default:
        this.filter = { name: "numeroContrato", columnProp: "numeroContrato", options: [] }
        this.tipoBusqueda = "Nro. Contrato"
        break;
    }
  }

  onSelectionChangeEstadosContrato(event: any) {
    const selectedValue = event.value;
    const control = this.form.get('EstadosContrato');
    this.form.controls["EstadosContrato"].setValue(selectedValue)
  }

  onSelectionChangeTiposContrato(event: any) {
    const selectedValue = event.value;
    const control = this.form.get('EstadosContrato');
    this.form.controls["TiposContrato"].setValue(selectedValue)
  }

  onSelectionChangeTiposAjuste(event: any) {
    const selectedValue = event.value;
    const control = this.form.get('EstadosContrato');
    this.form.controls["TiposAjuste"].setValue(selectedValue)
  }

  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.filterValues[this.filter.columnProp] = filterValue.toString().trim().toLowerCase().replace(/\s+/g, '')
      this.dataSource.filter = JSON.stringify(this.filterValues)

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  filterChangeEstados(filter: any, event: any) {
    this.filterValues[filter.columnProp] = event.value.trim().toLowerCase().replace(/\s+/g, '')
    this.dataSource.filter = JSON.stringify(this.filterValues)
  }

  getFilterObject(fullObj: any, key: any) {
    const uniqChk: any = [];
    fullObj.filter((obj: any) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString().replace(/\s+/g, '') !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().replace(/\s+/g, '').split(' ').forEach((word: any) => {
              if (data[col].toString().toLowerCase().replace(/\s+/g, '').indexOf(word) != -1 && isFilterSet) {
                found = true
              }
            });
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }


  getAllByFilter(contratoFilter: ContratoFilter) {
    this.spinner.show()
    this.contratosService.getAllByFilter(contratoFilter)
      .subscribe(
        data => {
          this.spinner.hide()
          this.contratoLista = []

          data.forEach(element => {
            let contrato = new ContratoLista
            const porcentajeDiasCompletados = this.calcularPorcentajeDiasCompletados(element.fechaComienzo, element.fechaVencimiento)
            contrato.id = element.id
            contrato.fechaFin = element.fechaVencimiento
            contrato.fechaInicio = element.fechaComienzo
            contrato.numeroContrato = element.numeroContrato
            contrato.legajo = "Tomo: " + element.tomo + "\nFolio: " + element.folio + "\nNúmero. " + element.numero
            contrato.inquilinoId = element.inquilino.persona.id
            contrato.inquilinoNombre = element.inquilino.persona.descripcion
            element.propietarios.filter(x => x.titular == true).forEach(element => {
              contrato.propietarioId = element.id
              contrato.propietarioNombre = element.persona.descripcion
            });
            contrato.inquilinoNombre = element.inquilino.persona.descripcion
            contrato.propiedadId = element.propiedad.id
            contrato.propiedadNombre = (isFalsy(element.propiedad.ubicacion) ? '' : element.propiedad.ubicacion) +
              (isFalsy(element.propiedad.piso) ? '' : ' ' + element.propiedad.piso) +
              (isFalsy(element.propiedad.departamento) ? '' : ' ' + element.propiedad.departamento);
            contrato.porcentajeDiasCompletados = porcentajeDiasCompletados
            contrato.tipoContrato = element.tipoContrato.descripcion
            contrato.estadoNombre = element.estadoContrato.descripcion
            contrato.estadoId = element.estadoContrato.id
            contrato.tipoAjuste = element.tipoAjusteContrato.acronimo
            contrato.contratoDto = element
            this.contratoLista.push(contrato)
          });
          this.dataSource.data = this.contratoLista
          this.data = this.dataSource.data.length >= 1 ? true : false


          this.filterSelectObjEstados.filter((o) => {
            o.options = this.getFilterObject(this.contratoLista, o.columnProp);
          });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getAllByIdInquilinoAndIdPropiedad(idInquilino: number, idPropiedad: number) {
    this.spinner.show()
    this.contratosService.getAllByIdInquilinoAndIdPropiedad(idInquilino, idPropiedad)
      .subscribe(
        data => {
          this.spinner.hide()
          data.forEach(element => {
            let contrato = new ContratoLista
            const porcentajeDiasCompletados = this.calcularPorcentajeDiasCompletados(element.fechaComienzo, element.fechaVencimiento)
            contrato.id = element.id
            contrato.fechaFin = element.fechaVencimiento
            contrato.fechaInicio = element.fechaComienzo
            contrato.numeroContrato = element.numeroContrato
            contrato.legajo = "Tomo: " + element.tomo + "\nFolio: " + element.folio + "\nNúmero. " + element.numero
            contrato.inquilinoId = element.inquilino.persona.id
            contrato.inquilinoNombre = element.inquilino.persona.descripcion
            element.propietarios.filter(x => x.titular == true).forEach(element => {
              contrato.propietarioId = element.id
              contrato.propietarioNombre = element.persona.descripcion
            });
            contrato.inquilinoNombre = element.inquilino.persona.descripcion
            contrato.propiedadId = element.propiedad.id
            contrato.propiedadNombre = (isFalsy(element.propiedad.ubicacion) ? '' : element.propiedad.ubicacion) +
              (isFalsy(element.propiedad.piso) ? '' : ' ' + element.propiedad.piso) +
              (isFalsy(element.propiedad.departamento) ? '' : ' ' + element.propiedad.departamento);
            contrato.porcentajeDiasCompletados = porcentajeDiasCompletados
            contrato.tipoContrato = element.tipoContrato.descripcion
            contrato.estadoNombre = element.estadoContrato.descripcion
            contrato.estadoId = element.estadoContrato.id
            contrato.contratoDto = element
            this.contratoLista.push(contrato)
          });
          this.dataSource.data = this.contratoLista
          this.data = this.dataSource.data.length >= 1 ? true : false


          this.filterSelectObjEstados.filter((o) => {
            o.options = this.getFilterObject(this.contratoLista, o.columnProp);
          });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  calcularPorcentajeDiasCompletados(fechaComienzo: string, fechaVencimiento: string): number {
    const fechaComienzo1 = moment(new Date(fechaComienzo))
    const fechaVencimiento1 = moment(new Date(fechaVencimiento))
    const fechaHoy = moment(new Date())

    const x = fechaVencimiento1.diff(fechaComienzo1, 'days')
    const y = fechaVencimiento1.diff(fechaHoy, 'days')
    const dif = Math.round((y * 100) / x)

    return 100 - dif
  }

  progressInLoading() {
  }

  getById(id: number) {
    this.spinner.show()
    this.contratosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide()
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }


  openDialogGenerarResumen(id: number) {
    this.spinner.show()
    this.contratosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide()
          const dialogConfig = new MatDialogConfig();
          dialogConfig.width = "400px"
          dialogConfig.autoFocus = false
          dialogConfig.disableClose = true

          dialogConfig.data = { event: AccionesDialog.Agregar, contrato: data }
          const dialogRef = this.dialog.open(DialogGenerarResumenComponent,
            dialogConfig);

          dialogRef.afterClosed().subscribe(result => {
          });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialogGenerarResumenMasivo() {

    const contratosSeleccionados = this.selection.selected;
    const idsSeleccionados = contratosSeleccionados.map(contrato => contrato.id); // Reemplaza 'id' con el nombre correcto
    if (idsSeleccionados.length >= 1) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.width = "90%"
      dialogConfig.data = { event: AccionesDialog.Agregar, idContratos: idsSeleccionados }
      dialogConfig.disableClose = true

      const dialogRef = this.dialog.open(DialogGenerarResumenMensualComponent,
        dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result.event == AccionesDialog.Aceptar) {
          this.snackBar.showSuccess("Proceso realizado correctamente.", "Exito");
          this.selection.clear()
        }

        if (result.event == AccionesDialog.Cancelar)
          this.snackBar.showInfo("Proceso cancelado.", "Info");
      });
    }
    else
      this.snackBar.showInfo("Previamente debe seleccionar algun contrato.", "Exito");


  }


  openDialogNuevaLiquidacion(id: number) {
    this.spinner.show()
    this.contratosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide()
          const dialogConfig = new MatDialogConfig();
          dialogConfig.width = "1080px"
          dialogConfig.data = data
          dialogConfig.autoFocus = false
          dialogConfig.disableClose = true

          const dialogRef = this.dialog.open(DialogNuevaLiquidacionComponent,
            dialogConfig);
          dialogRef.afterClosed().subscribe(result => {

          });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialogRescisionContrato(id: number) {
    this.spinner.show()
    this.contratosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide()
          const dialogConfig = new MatDialogConfig();
          dialogConfig.autoFocus = false
          dialogConfig.disableClose = false
          dialogConfig.data = data

          const dialogRef = this.dialog.open(DialogRescisionContratoComponent,
            dialogConfig);

          dialogRef.afterClosed().subscribe(result => {
            if (result.event == AccionesDialog.Aceptar) {
              this.clearFilter()
            }
          });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialogImputacionPropietario(contratoDetailDto: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false
    dialogConfig.disableClose = false
    dialogConfig.width = "70%"
    dialogConfig.data = contratoDetailDto

    const dialogRef = this.dialog.open(DialogImputacionPropietarioComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

    });
  }


  rescisionContrato(resindirContratoRequest: ResindirContratoRequest) {
    this.spinner.show()
    this.contratosService.rescisionContrato(resindirContratoRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Contrato resindido de manera correcta.", "Exito");
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }


  openDialogNuevoRecibo(id: number) {
    this.spinner.show()
    this.contratosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide()
          const dialogConfig = new MatDialogConfig();
          dialogConfig.width = "100%"
          dialogConfig.data = { event: AccionesDialog.Agregar, contrato: data }
          dialogConfig.autoFocus = false
          dialogConfig.disableClose = true

          const dialogRef = this.dialog.open(DialogNuevoReciboComponent,
            dialogConfig);

          dialogRef.afterClosed().subscribe(result => {
          });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }


  getAllEstadosContrato() {
    this.spinner.show("spBusquedaEstadosContrato")
    this.estadosContratoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaEstadosContrato")
          this.lstEstadosContrato = data
        },
        error => {
          this.spinner.hide("spBusquedaEstadosContrato")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getAllTiposContrato() {
    this.spinner.show("spBusquedaTiposContrato")
    this.tiposContratoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTiposContrato")
          this.lstTiposContrato = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
        },
        error => {
          this.spinner.hide("spBusquedaTiposContrato")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getAllTiposAjuste() {
    this.spinner.show("spBusquedaTiposAjuste")
    this.tipoAjusteContratoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTiposAjuste")
          this.lstTiposAjuste = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
        },
        error => {
          this.spinner.hide("spBusquedaTiposAjuste")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }


  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false

  }

  selectPropietario(personaPropietarioDto: PersonaPropietarioDto) {
    this.personaPropietarioDto = personaPropietarioDto
  }

  selectPropiedad(propiedadBaseDto: BaseDto) {
    this.propiedadBaseDto = propiedadBaseDto
  }

  selectInquilino(inquilinoDto: InquilinoDto) {
    this.inquilinoDto = inquilinoDto
  }

  selectRangoFecha(event: any) {
    this.rangoFecha = event.value
    if (this.rangoFecha == RangoFechasValores.Todos) {
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
      this.form.controls["FechaHasta"].setValue(new Date((new Date().getTime() + this.rangoFecha)))
    }
    else
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
  }


  trackByItems(index: number, item: any): any { return item; }

  // Determina si todos los contratos están seleccionados
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  // Seleccionar/deseleccionar todos los contratos
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // Alternar la selección de un contrato
  toggleSelection(contrato: ContratoLista) {
    this.selection.toggle(contrato);
  }

  // Puedes realizar alguna acción con los contratos seleccionados, por ejemplo:
  realizarAccionConSeleccionados() {
    const contratosSeleccionados = this.selection.selected;
    const idsSeleccionados = contratosSeleccionados.map(contrato => contrato.id); // Reemplaza 'id' con el nombre correcto de la propiedad ID en tu objeto

    // Aquí puedes agregar lógica para realizar acciones con los contratos seleccionados
  }

  // Verifica si la selección es indeterminada
  isIndeterminate(): boolean {
    return this.selection.selected.length > 0 && !this.isAllSelected();
  }

  // Limpia la selección de todos los checkboxes
  clearSelection(): void {
    this.selection.clear();
  }
}
