import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { isFalsy } from 'utility-types';

@Component({

  selector: 'app-dialog-card-contrato',
  templateUrl: 'dialog-card-contrato.component.html',
  styleUrls: ['./dialog-card-contrato.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({
        height: '*',
        minHeight: ''
      })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DialogCardContratoComponent {

  @Input() contratoDetailDto!: ContratoDetailDto
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ContratoDetailDto
  ) { }

  ngOnInit(): void {
    if (!isFalsy(this.data))
      this.contratoDetailDto = this.data
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }
}
