<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>
    Nuevo vencimiento</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="position-relative">
        <div [formGroup]="form">
            <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <div class="row">
                <div class="col-lg-6">
                    <mat-form-field>
                        <mat-label>Fecha vencimiento:</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="Fecha">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="f.Fecha.errors?.required">Este campo es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <mat-form-field class="example-full-width">
                        <span matPrefix>$ &nbsp;</span>
                        <mat-label>Importe</mat-label>
                        <input cdkFocusInitial type="text" mask="separator.2" thousandSeparator="," placeholder="Importe" formControlName="Importe" matInput>
                        <mat-error *ngIf="f.Importe.errors?.required">Este campo es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="onSubmit()">Aceptar</button>
    <button mat-button mat-dialog-close (click)="onCancel()" color="warn">Cancelar</button>
</div>
