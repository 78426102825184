import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateTemplateRequest } from '@models/template/createTemplateRequest.model';
import { TemplateDto } from '@models/template/templateDto.model';
import { UpdateTemplateRequest } from '@models/template/updateTemplateRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http: HttpClient) { }


  create(createTemplateRequest: CreateTemplateRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Template/Create", createTemplateRequest);
  }

  update(updateTemplateRequest: UpdateTemplateRequest): Observable<any> {
    return this.http.put<any>("/api/Template/Update", updateTemplateRequest);
  }

  getAll(): Observable<TemplateDto[]> {
    return this.http.get<TemplateDto[]>("/api/Template/GetAll");
  }

  getById(id: number): Observable<TemplateDto> {
    return this.http.get<TemplateDto>("/api/Template/GetById?id=" + id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Template/Delete?id=" + id);
    else
      return of(null)
  }
}
