<div class="position-relative">
    <ng-container *appRole="['Propietarios.Ver']">

        <form [formGroup]="personaForm">

            <ngx-spinner [zIndex]=100 name="spPersona" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <div class="row">
                <div class="form-group col-md-3">
                    <mat-form-field class="full-width">
                        <mat-label>Nombre y Apellido</mat-label>
                        <input matInput type="text" maxlength="50" style="text-transform: capitalize;" id="NombreCompleto" formControlName="NombreCompleto" />
                        <mat-error *ngIf="f.NombreCompleto.errors?.required">Ingrese <strong> nombre completo.</strong>
                        </mat-error>
                        <mat-error *ngIf="f.NombreCompleto.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group col-md-3">
                    <mat-form-field>
                        <mat-label>Sexo</mat-label>
                        <mat-select formControlName="Sexo">
                            <mat-option value="M">Masculino</mat-option>
                            <mat-option value="F">Femenino</mat-option>
                        </mat-select>

                        <mat-error *ngIf="f.Sexo.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group col-md-3">
                    <mat-form-field>
                        <ngx-spinner name="spPersonaCombo" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                        <mat-label>Tipo Documento</mat-label>
                        <mat-select #SelectTipoDocuemtno placeholder="Seleccione opcion" (selectionChange)="selectTipoDocumento($event)" formControlName="TiposDocumento">
                            <mat-option [value]="tipoDocumento.id" *ngFor="let tipoDocumento of lstTipoDocumentoDto;let i=index;trackBy:trackByItems ">
                                {{tipoDocumento.descripcion}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="f.TiposDocumento.errors?.required">Este campo es <strong>requerido</strong>.
                        </mat-error>
                        <mat-hint>Habilita nro. documento</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group col-md-3">
                    <mat-form-field class="example-full-width">
                        <ngx-spinner name="spNroDocumento" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                        <mat-label>Nro. Documento</mat-label>
                        <input matInput name="NroDocumento" type="text" maxlength="15" #NroDocumento oninput="this.value=this.value.toUpperCase()" formControlName="NroDocumento" />
                        <mat-error *ngIf="f.NroDocumento.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        <mat-error *ngIf="f.NroDocumento.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                        <mat-error *ngIf="f.NroDocumento.errors?.existePersona">Ya existe esta persona.</mat-error>
                        <mat-error *ngIf="f.NroDocumento.errors?.pattern">Caracteres no validos.</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-3">
                    <mat-form-field class="full-width">
                        <mat-label>Correo</mat-label>
                        <input matInput name="Correo" type="email" formControlName="Correo" />
                        <mat-error *ngIf="f.Correo.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        <mat-error *ngIf="f.Correo.errors?.email">Correo invalido.</mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group col-md-3">
                    <mat-form-field>
                        <ngx-spinner name="spPersonaCombo" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                        <mat-label>Estado Civil</mat-label>
                        <mat-select #SelectEstadoCivil formControlName="EstadoCivil">
                            <mat-option [value]="estadoCivil.id" *ngFor="let estadoCivil of lstEstadosCivilModel;let i=index;trackBy:trackByItems ">
                                {{estadoCivil.descripcion}}</mat-option>
                        </mat-select>

                        <mat-error *ngIf="f.EstadoCivil.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group col-md-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>Telefono Personal</mat-label>
                        <!-- <span matPrefix>+54 &nbsp;</span> -->
                        <input matInput name="TelefonoPrincial" maxlength="20" placeholder="Sin 0 y sin 15" type="tel" formControlName="TelefonoPrincial" />
                        <mat-error *ngIf="f.TelefonoPrincial.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group col-md-3">
                    <mat-form-field class="full-width">
                        <mat-label>Ubicación</mat-label>
                        <input matInput name="Ubicacion" style="text-transform: capitalize;" type="text" maxlength="50" formControlName="Ubicacion" />
                        <mat-error *ngIf="f.Ubicacion.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <app-buscar-localidad (eventoLocalidad)="seleccionaLocalidad($event)"></app-buscar-localidad>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-3">
                    <mat-form-field class="example-full-width">
                        <span matPrefix>%&nbsp;</span>
                        <mat-label>Comision</mat-label>
                        <input matInput name="Comision" appPercentageDirective min="1" max="100" [(ngModel)]="comision" type="text" formControlName="Comision" />
                        <mat-error *ngIf="f.Comision.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group col-md-3">
                    <mat-form-field>
                        <ngx-spinner name="spPersonaCombo" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                        <mat-label>Tipo Liquidación</mat-label>
                        <mat-select placeholder="Seleccione opcion" formControlName="TipoLiquidacion" (selectionChange)="selectTipoLiquidacion($event)">
                            <mat-option [value]="tipoLiquidacion.id" *ngFor="let tipoLiquidacion of lstTipoLiquidacionModel ;let i=index;trackBy:trackByItems">
                                {{tipoLiquidacion.descripcion}}</mat-option>
                        </mat-select>

                        <mat-error *ngIf="f.TipoLiquidacion.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group col-md-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>CUIT/CUIL</mat-label>
                        <input matInput name="CuitCuil" type="text" formControlName="CuitCuil" [mask]="'00-00000000-0'" placeholder="00-00000000-0" />
                        <mat-error *ngIf="f.CuitCuil.errors?.pattern">Formato invalido.</mat-error>
                        <mat-error *ngIf="f.CuitCuil.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <br>
        <div class="col-auto my-1 btn-block">
            <button mat-raised-button color="primary" style="margin-right:3px;" name="Agregar" [disabled]="!personaForm.valid || isFalsy(localidad)" (click)="onSubmit()" *appRole="['Propietarios.Crear']">Agregar</button>
            <button mat-button color="warn" style="margin-right:3px;" [mat-dialog-close]="true" name="Cancelar">Cancelar</button>
        </div>
    </ng-container>
</div>