<mat-card *appRole="['PeriodosContrato.Ver']">

  <form [formGroup]="form">
    <div class="row">
      <div class="col-lg-2">
        <mat-form-field>
          <mat-label>Periodo Mes</mat-label>
          <input matInput type="number" formControlName="PeriodoMes">
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <mat-form-field>
          <mat-label>Periodo Año</mat-label>
          <input matInput type="number" formControlName="PeriodoAnio">
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <mat-form-field>
          <ngx-spinner name="spBusquedaTipoAjusteContrato" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688"
            type="line-scale" [fullScreen]="false"></ngx-spinner>
          <mat-label>Tipos de Ajuste</mat-label>
          <mat-select placeholder="Seleccione opcion" multiple formControlName="TiposAjusteContrato">
            <mat-option [value]="tipoAjusteContrato.id" selected=true
              *ngFor="let tipoAjusteContrato of lstTiposAjusteContrato;let i=index;trackBy:trackByItems"
              [matTooltip]="tipoAjusteContrato.descripcion">
              {{ tipoAjusteContrato.acronimo | uppercase }}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.TiposAjusteContrato.errors?.required">Este campo es <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <app-buscar-contrato  (eventEntity)="selectContrato($event)"></app-buscar-contrato>
      </div>
    </div>


    <div class="row">
      <div class="col-lg-2">
        <mat-form-field>
          <mat-label>Fecha Vencimiento</mat-label>
          <mat-date-range-input [rangePicker]="pickerFechaVencimiento">
            <input matStartDate [disabled]="true" formControlName="FechaVencimientoDesde" appDateFormat="DD/MM/YYYY"
              placeholder="Fecha desde" />
            <input matEndDate [disabled]="true" appDateFormat="DD/MM/YYYY" formControlName="FechaVencimientoHasta"
              placeholder="Fecha hasta" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickerFechaVencimiento"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerFechaVencimiento disabled="false"></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <mat-form-field>
          <mat-label>Fechas de Ajuste</mat-label>
          <mat-date-range-input [rangePicker]="pickerFechaAjuste">
            <input matStartDate [disabled]="true" appDateFormat="DD/MM/YYYY" formControlName="FechaAjusteDesde"
              placeholder="Fecha desde" />
            <input matEndDate [disabled]="true" appDateFormat="DD/MM/YYYY" formControlName="FechaAjusteHasta"
              placeholder="Fecha hasta" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickerFechaAjuste"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerFechaAjuste disabled="false"></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <mat-form-field>
          <mat-label>Fechas de Liquidación</mat-label>
          <mat-date-range-input [rangePicker]="pickerFechaLiquidacion">
            <input matStartDate [disabled]="true" appDateFormat="DD/MM/YYYY" formControlName="FechaLiquidacionDesde"
              placeholder="Fecha desde" />
            <input matEndDate [disabled]="true" appDateFormat="DD/MM/YYYY" formControlName="FechaLiquidacionHasta"
              placeholder="Fecha hasta" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickerFechaLiquidacion"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerFechaLiquidacion disabled="false"></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <mat-form-field>
          <mat-label>Fechas de Pago</mat-label>
          <mat-date-range-input [rangePicker]="pickerFechaPago">
            <input matStartDate [disabled]="true" appDateFormat="DD/MM/YYYY" formControlName="FechaPagoDesde"
              placeholder="Fecha desde" />
            <input matEndDate [disabled]="true" appDateFormat="DD/MM/YYYY" formControlName="FechaPagoHasta"
              placeholder="Fecha hasta" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickerFechaPago"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerFechaPago disabled="false"></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <button mat-stroked-button color="primary" [disabled]="!form.valid" (click)="filtrar()">
          <mat-icon>filter_alt</mat-icon>
          Filtrar</button>&nbsp; &nbsp;
            <button mat-stroked-button  matTooltip="Limpiar" color="primary" (click)="clearDateControls()">
              <mat-icon>clear</mat-icon>
              Limpiar</button>
      </div>

    </div>
  </form>

  <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
  </ngx-spinner>
  <mat-form-field class="example-full-width">
    <mat-label>Buscar</mat-label>
    <input matInput type="search" (keyup)="applyFilter($event)">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <br>
  <table mat-table [dataSource]="dataSource" [hidden]=!data table matSort matSortStart="asc" class="mat-elevation-z8">


    <ng-container matColumnDef="contrato">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Contrato </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        <a [routerLink]="['/contratos',element.id]" #tooltip="matTooltip" matTooltipPosition="right"
          matTooltip="Ver"><span>{{element.contrato.descripcion | titlecase}}</span></a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="periodo">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Periodo </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element"> {{element.periodoMes}}-{{element.periodoAnio}}/{{element.periodo}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fechaVencimiento">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>  Fecha Vto. </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element" [ngClass]="{'vencimiento-rojo': isVencidoSinPago(element)}"> {{element.fechaVencimiento | date:"dd/MM/yyyy"}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fechaAjuste">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Ajuste </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element"> {{element.fechaAjuste | date:"dd/MM/yyyy"}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="valorAjuste">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Valor Ajuste </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element"> {{ element.valorAjuste | number:'1.3-3' }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="indiceAjusteContrato">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Indice </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        <span
          [matTooltip]="'Valor: '+ element.indiceAjusteContrato?.valor+ '\n' + 'Fecha: ' + element.indiceAjusteContrato?.fecha  + '\n' + 'Descripción: ' + element.indiceAjusteContrato?.tipoAjusteContrato.descripcion | titlecase"
          matTooltipClass="toolTipsClass">
          {{element.indiceAjusteContrato?.tipoAjusteContrato.acronimo | uppercase}}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="importeAlquiler">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Importe Alq. </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element"> {{element.importeAlquiler | currency}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="fechaPago">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Pago </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element"> {{element.fechaPago | date:"dd/MM/yyyy"}} </mat-cell>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="accion">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let periodo" class="action-link">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openDialog(periodo)">
            <mat-icon>visibility</mat-icon>
            <span>Ver</span>
          </button>
        </mat-menu>
      </mat-cell>
      <!-- <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell> -->
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <!-- <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row> -->
  </table>

  <div>

  </div>
  <div class="no-data-table">
    <span class="with-icon" *ngIf="dataSource.data.length == 0">
      <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
    </span>
  </div>
  <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="50"
    [pageSizeOptions]="[5, 10, 20,50,100,200]">
  </mat-paginator>
</mat-card>
