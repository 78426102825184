import { BaseDto } from "@models/base/baseDto.model";

export class AuditoriaLogDto extends BaseDto {
  id_Usuario: number;
  requestData: string;
  responseData: string;
  browserInfo: string;
  timestamp: Date;
  fullUrl: string;
  httpMethod: string;
  httpStatusCode: number;
  ipAddress: string;
  duration: number;
  requestScheme: string;
  requestHost: string;
  requestPath: string;
  requestQueryString: string;

  constructor(
    id_Usuario: number,
    requestData: string,
    responseData: string,
    browserInfo: string,
    timestamp: Date,
    fullUrl: string,
    httpMethod: string,
    httpStatusCode: number,
    ipAddress: string,
    duration: number,
    requestScheme: string,
    requestHost: string,
    requestPath: string,
    requestQueryString: string
  ) {
    super();
    this.id_Usuario = id_Usuario;
    this.requestData = requestData;
    this.responseData = responseData;
    this.browserInfo = browserInfo;
    this.timestamp = timestamp;
    this.fullUrl = fullUrl;
    this.httpMethod = httpMethod;
    this.httpStatusCode = httpStatusCode;
    this.ipAddress = ipAddress;
    this.duration = duration;
    this.requestScheme = requestScheme;
    this.requestHost = requestHost;
    this.requestPath = requestPath;
    this.requestQueryString = requestQueryString;
  }
}
