import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateServicioRequest } from '@models/servicios/servicio/createServicioRequest.model';
import { ServicioDto } from '@models/servicios/servicio/servicioDto.model';
import { UpdateServicioRequest } from '@models/servicios/servicio/updateServicioRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http:HttpClient
  ) { }


  getAll():Observable<ServicioDto[]> {
    return   this.http.get<ServicioDto[]>("/api/Servicio/GetAll");
  }

  GetById(idServicio:number):Observable<ServicioDto>
  {
    return  this.http.get<ServicioDto>("/api/Servicio/GetById?id="+ idServicio);
  }

  create(createServicioRequest:CreateServicioRequest):Observable<any>
  {
    return  this.http.post("/api/Servicio/Create",createServicioRequest);
  }

  update(updateServicioRequest:UpdateServicioRequest):Observable<any>
  {
    return  this.http.put("/api/Servicio/Update",updateServicioRequest);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Servicio/Delete?id=" + id);
    else
      return of(null)
  }


}
