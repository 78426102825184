<div class="row content" *appRole="['Documentos.Ver']">
    <div class="col-sm-2">
        <app-nav-panel-contrato [idContrato]="idContrato"></app-nav-panel-contrato>
    </div>
    <div class="col-sm-10">
        <ngx-spinner [zIndex]=100 name="spContrato" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <div *ngIf="contratoDetailDto.id != 0 && contratoDetailDto.id != undefined">
            <app-listado-archivos-temporales [idContrato]="contratoDetailDto.id"></app-listado-archivos-temporales>
        </div>
    </div>
</div>
