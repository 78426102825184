import { Component, OnInit } from '@angular/core';
import { EntidadDto } from '@models/base/entidadDto.model';
import { Roles, TiposEntidad } from '@models/base/identificadores.model';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { LoginService } from '@services/login/login.service';
import { PersonasService } from '@services/personas/personas.service';

@Component({
  selector: 'documentos-personas',
  templateUrl: './documentos-personas.component.html',
  styleUrls: ['./documentos-personas.component.css']
})
export class DocumentosPersonasComponent implements OnInit {
  idTipoEntidad!: number
  idEntidad!: number
  subMenuActive: boolean = false;
  entidad = new EntidadDto
  documentoChange!: number

  counter = 0;
  roles!: typeof Roles;
  idUserRol!: number;
  usuarioCliente: boolean = false;
  personaDetailDto!: PersonaDetailDto;

  constructor(
    private loginService: LoginService,
    private personasServices: PersonasService
  ) {

  }

  ngOnInit(): void {
    this.personaDetailDto = this.personasServices.getPersona()
    if (this.loginService.getIdUserRol() == Roles.Clientes) {
      this.usuarioCliente = true
      this.idEntidad = this.personaDetailDto.id
    }
    else {
      this.idEntidad = this.personaDetailDto.id
    }

    this.idTipoEntidad = TiposEntidad.Personas
    this.roles = Roles
    this.idUserRol = this.loginService.getIdUserRol()



  }

  onDocumentoChange(documentoChange: number) {
    this.documentoChange = documentoChange;
  }

}
