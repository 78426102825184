import { CommonModule } from '@angular/common';
import { Component, Inject, NgModule, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule,ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';



@Component({
  selector: 'app-dialog-tipo-servicios',
  templateUrl: './dialog-tipo-servicios.component.html',
  styleUrls: ['./dialog-tipo-servicios.component.css']
})
export class DialogTipoServiciosComponent implements OnInit {


  form: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  local_data: any;
  action: any;

  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogTipoServiciosComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: BaseDto) {
    this.local_data = {...data};
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
    })

    if (this.action != AccionesDialog.Agregar){
      this.form.controls["Descripcion"].setValue(this.local_data.descripcion)
    }
  }

  doAction(){
    this.dialogRef.close({event:this.action,data:this.local_data});
  }

  closeDialog(){
    this.dialogRef.close({event:AccionesDialog.Cancelar});
  }

  ngOnInit(): void {
  }
}
