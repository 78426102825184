<div *appRole="['Servicios.Ver']">

  <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
  </ngx-spinner>
  <form [formGroup]="formServicioPriopidad">
    <div class="row">
      <div class="form-group col-md-3">
        <mat-form-field class="example-full-width">
          <mat-label>Servicio</mat-label>
          <input matInput name="Servicio" type="text" formControlName="Servicio" />
          <button mat-icon-button matSuffix [routerLink]="['/servicios/servicio/editar/',idServicio]"
            #tooltip="matTooltip" matTooltip="Ver mas info.">
            <mat-icon>{{'info'}}</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="form-group col-md-3">
        <mat-form-field class="full-width">
          <mat-label>Nro. Cuenta</mat-label>
          <input matInput name="NroCuenta" type="text" maxlength="50" formControlName="NroCuenta" />
          <mat-error *ngIf="f.NroCuenta.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
          <mat-error *ngIf="f.NroCuenta.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
        </mat-form-field>
      </div>

      <div class="form-group col-md-3">
        <mat-form-field class="full-width">
          <mat-label>Nro. gestion personal</mat-label>
          <input matInput name="NroGestionPersonal" type="text" maxlength="50" formControlName="NroGestionPersonal" />
          <mat-error *ngIf="f.NroGestionPersonal.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
        </mat-form-field>
      </div>

      <div class="form-group col-md-3">
        <mat-form-field class="full-width">
          <mat-label>Referencia Pago</mat-label>
          <input matInput name="NroGestionPersonal" type="text" maxlength="50" formControlName="ReferenciaPago" />
          <mat-error *ngIf="f.ReferenciaPago.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
        </mat-form-field>
      </div>

    </div>
    <br>

    <div class="col-auto my-1">
      <button mat-raised-button color="primary" style="margin-right:3px;" name="Guardar"
        [disabled]="!formServicioPriopidad.valid" (click)="update()" *appRole="['Servicios.Modificar']">Guardar</button>
      <button mat-button color="warn" style="margin-right:3px;" name="Cancelar"
        [routerLink]="['/serviciospropiedad/servicio/',idPropiedad]">Cancelar</button>
    </div>

  </form>
</div>
