import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { BonificacionDto } from '@models/bonificaciones/bonificacionDto.model';
import { CreateBonificacionRequest } from '@models/bonificaciones/createBonificacionRequest.model';
import { UpdateBonificacionRequest } from '@models/bonificaciones/updateBonificacionRequest.model';
import { BonificacionesService } from '@services/bonificaciones/bonificaciones.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogBonificacionComponent } from '../dialog-bonificacion/dialog-bonificacion.component';

@Component({
  selector: 'app-bonificaciones',
  templateUrl: './bonificaciones.component.html',
  styleUrls: ['./bonificaciones.component.css']
})
export class BonificacionesComponent implements OnInit {

  data = false
  dataSource = new MatTableDataSource<BonificacionDto>();
  obj!: undefined
  displayedColumns: string[] = [];

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  constructor(
    public dialog: MatDialog,
    private bonificacionesServices: BonificacionesService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
      ['detalle', 'tipoBonificacion', 'accion'] :
      ['detalle', 'tipoBonificacion', 'accion'];
    });
  }


  ngOnInit() {
    this.getAllBonificaciones();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllBonificaciones() {
    this.spinner.show()
    this.bonificacionesServices.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data =  data.sort((a, b) => a.detalle.toString().localeCompare(b.detalle.toString()))
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogBonificacionComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.addRowData(result.data);
      } else if (result.event == AccionesDialog.Modificar) {
        this.updateRowData(result.data);
      } else if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
    if (event && event.target && event.target.value !== undefined) {
const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
}
  }
  }


  addRowData(row_obj: { detalle: string; tipoBonificacion: number }) {
    this.spinner.show()
    let createBonificacionRequest = new CreateBonificacionRequest
    createBonificacionRequest.detalle = row_obj.detalle
    createBonificacionRequest.idTipoBonificacion = row_obj.tipoBonificacion

    let existe = false
    this.dataSource.data.forEach((element, index) => {
      if (element.detalle === row_obj.detalle)
        existe = true
    });

    if (createBonificacionRequest && !existe) {
      this.bonificacionesServices.create(createBonificacionRequest)
        .subscribe(
          data => {
            this.spinner.hide()
            this.getAllBonificaciones();
            this.data = this.dataSource.data.length >= 1 ? true : false
            this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          },
          error => {
            this.spinner.hide()
            this.snackBar.showError(error, "Error");
          }
        )
    }
    else {
      this.spinner.hide()
      this.snackBar.showWarn("El registro ya existe", "Error");
    }

  }
  updateRowData(row_obj: { id: number; detalle: string; tipoBonificacion: number }) {
    this.spinner.show()
    let updateBonificacionRequest = new UpdateBonificacionRequest
    updateBonificacionRequest.id = row_obj.id
    updateBonificacionRequest.detalle = row_obj.detalle
    updateBonificacionRequest.idTipoBonificacion = row_obj.tipoBonificacion

    let existe = false
    this.dataSource.data.forEach((element, index) => {
      if (element.detalle === row_obj.detalle && element.tipoBonificacion?.id == row_obj.tipoBonificacion)
        existe = true
    });
    if (updateBonificacionRequest && !existe) {
      this.bonificacionesServices.update(updateBonificacionRequest)
        .subscribe(
          data => {
            this.spinner.hide()
            this.getAllBonificaciones();
            this.data = this.dataSource.data.length >= 1 ? true : false
            this.snackBar.showSuccess("Registro actualizado correctamente.", "Exito");
          },
          error => {
            this.spinner.hide()
            this.snackBar.showError(error, "Error");
          })
    }
    else {
      this.spinner.hide()
      this.snackBar.showWarn("El registro ya existe", "Error");
    }
  }

  deleteRowData(row_obj: { id: number; }) {
    this.bonificacionesServices.delete(row_obj.id)
        .subscribe(
          data => {
            this.spinner.hide()
            this.dataSource.data = this.dataSource.data.filter((value: { id: number; }, key: any) => {
              return value.id != row_obj.id;
            });
            this.data = this.dataSource.data.length >= 1 ?  true : false
            this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          },
          error => {
            this.spinner.hide()
            this.snackBar.showError( error, "Error");
          })

  }

}
