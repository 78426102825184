import { Pipe, PipeTransform } from '@angular/core';
//Se utiliza para filtrar en formulario en formularios
//Ej:
//<div *ngFor="let city of cities | filter: cityName">
//    {{city}}
//</div>
@Pipe({
  name: 'filtro'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, input: any): any {
    if (input) {
      return value.filter((val: string | any[]) => val.indexOf(input)) >= 0;
    } else {
      return value;
    }
  }

}
