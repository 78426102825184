import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared.module';
import { IndicesAjusteContratoComponent } from '@views/indices-ajuste-contrato/indices-ajuste-contrato/indices-ajuste-contrato.component';
import { BtnNuevoIndiceAjusteContratoComponent } from '@views/indices-ajuste-contrato/btn-nuevo-incide-ajuste-contrato/btn-nuevo-incide-ajuste-contrato.component';
import { DialogIndiceAjusteContratoComponent } from '@views/indices-ajuste-contrato/dialog-indice-ajuste-contrato/dialog-indice-ajuste-contrato.component';
import { DialogWebICLReferenciaComponent } from '@views/indices-ajuste-contrato/dialog-web-iclreferencia/dialog-web-iclreferencia.component';



@NgModule({
  imports: [
      CommonModule,
      SharedModule,
  ],
  declarations: [
    IndicesAjusteContratoComponent,
    BtnNuevoIndiceAjusteContratoComponent,
    DialogIndiceAjusteContratoComponent,
    DialogWebICLReferenciaComponent,
  ],
  exports: [

    IndicesAjusteContratoComponent,
    DialogIndiceAjusteContratoComponent,
    BtnNuevoIndiceAjusteContratoComponent,
    DialogWebICLReferenciaComponent,
  ]
})
export class IndicesAjusteContratoModule { }
