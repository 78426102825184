<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Ver</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true" class="container">

  <div class="row">
    <div class="col">
      <div class="field">
        <label><strong>Periodo:</strong></label>
        <span>{{periodoContratoDetail.periodo}}</span>
      </div>
      <div class="field">
        <label><strong>Periodo Mes:</strong></label>
        <span>{{periodoContratoDetail.periodoMes}}</span>
      </div>
      <div class="field">
        <label><strong>Periodo Año:</strong></label>
        <span>{{periodoContratoDetail.periodoAnio}}</span>
      </div>
      <div class="field">
        <label><strong>Descuento:</strong></label>
        <span>{{periodoContratoDetail.descuento | currency}}</span>
      </div>
      <div class="field">
        <label><strong>Incremento:</strong></label>
        <span>{{periodoContratoDetail.incremento | currency}}</span>
      </div>
      <div class="field">
        <label><strong>Importe Alquiler:</strong></label>
        <span>{{periodoContratoDetail.importeAlquiler | currency}}</span>
      </div>

      <div class="field">
        <label><strong>Ajuste Procesado:</strong></label>
        <span>{{periodoContratoDetail.ajusteProcesado ? 'Sí' : 'No'}}</span>
      </div>
      <div class="field">
        <label><strong>Tipo Ajuste:</strong></label>
        <span>{{periodoContratoDetail.indiceAjusteContrato?.tipoAjusteContrato?.acronimo | uppercase}}</span>
      </div>
      <div class="field">
        <label><strong>Valor Ajuste:</strong></label>
        <span>{{periodoContratoDetail.valorAjuste | number:'1.3-3'}}</span>
      </div>
    </div>

    <div class="col">
      <div class="field">
        <label><strong>Fecha Pago:</strong></label>
        {{ periodoContratoDetail.fechaPago === '01/01/0001' ? '' : (periodoContratoDetail.fechaPago | date:'dd/MM/yyyy') }}
      </div>
      <div class="field">
        <label><strong>Fecha Adenda:</strong></label>
        {{ periodoContratoDetail.fechaAdenda === '01/01/0001' ? '' : (periodoContratoDetail.fechaAdenda | date:'dd/MM/yyyy') }}
      </div>
      <div class="field">
        <label><strong>Fecha Ajuste:</strong></label>
        {{ periodoContratoDetail.fechaAjuste === '01/01/0001' ? '' : (periodoContratoDetail.fechaAjuste | date:'dd/MM/yyyy') }}
      </div>
      <div class="field">
        <label><strong>Fecha Modificación:</strong></label>
        {{ periodoContratoDetail.fechaModificacion === '01/01/0001' ? '' : (periodoContratoDetail.fechaModificacion | date:'dd/MM/yyyy') }}
      </div>
      <div class="field">
        <label><strong>Fecha Alta:</strong></label>
        {{ periodoContratoDetail.fechaAlta === '01/01/0001' ? '' : (periodoContratoDetail.fechaAlta | date:'dd/MM/yyyy') }}
      </div>
      <div class="field">
        <label><strong>Descripción:</strong></label>
        {{ periodoContratoDetail.descripcion}}
      </div>
      <div class="field">
        <label><strong>Valor Índice :</strong></label>
        <span>{{periodoContratoDetail.indiceAjusteContrato?.valor}} %</span>
      </div>
      <div class="field">
        <label><strong>Fecha Índice:</strong></label>
        <span>{{periodoContratoDetail.indiceAjusteContrato?.fecha | date:'dd/MM/yyyy'}}</span>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()" color="warn">Cerrar</button>
</div>
