import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CasoUsoDto } from '@models/seguridad/casosUso/casoUsoDto.model';
import { CreateCasosUsoRequest } from '@models/seguridad/casosUso/createCasosUsoRequest.model';
import { UpdateCasosUsoRequest } from '@models/seguridad/casosUso/updateCasosUsoRequest.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasosUsoService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<CasoUsoDto[]> {
    return this.http.get<CasoUsoDto[]>("/api/CasosUso/GetAll");
  }
  getById(id:number):Observable<CasoUsoDto>
  {
    return  this.http.get<CasoUsoDto>("/api/CasosUso/GetById?id="+ id);
  }

  create(createCasosUsoRequest: CreateCasosUsoRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/CasosUso/Create", createCasosUsoRequest);
  }

  update(updateCasosUsoRequest: UpdateCasosUsoRequest): Observable<any> {
    return this.http.put<any>("/api/CasosUso/Update", updateCasosUsoRequest);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>("/api/Permiso/Delete?id="+id);
  }
}
