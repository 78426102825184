import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';

@Component({
  selector: 'app-dialog-create-update-delete',
  templateUrl: './dialog-create-update-delete.component.html',
  styleUrls: ['./dialog-create-update-delete.component.css']
})
export class DialogCreateUpdateDeleteComponent implements OnInit {


  action:string;
  local_data:any;
  form: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;

  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogCreateUpdateDeleteComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: BaseDto) {
    this.local_data = {...data};
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
    })
  }

  doAction(){
    this.dialogRef.close({event:this.action,data:this.local_data});
  }

  closeDialog(){
    this.dialogRef.close({event:AccionesDialog.Cancelar});
  }

  ngOnInit(): void {
  }

}
