import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnNuevoFormaPagoComponent } from '../views/formas-pago/btn-nuevo-forma-pago/btn-nuevo-forma-pago.component';
import { DialogFormaPagoComponent } from '../views/formas-pago/dialog-forma-pago/dialog-forma-pago.component';
import { FormasPagoComponent } from '../views/formas-pago/formas-pago/formas-pago.component';
import { SharedModule } from './shared.module';



@NgModule({
  declarations: [BtnNuevoFormaPagoComponent, DialogFormaPagoComponent, FormasPagoComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports:[
    BtnNuevoFormaPagoComponent,
    FormasPagoComponent,
    DialogFormaPagoComponent]
})
export class FormaPagoModule { }
