import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CasosUsoService } from '@services/seguridad/casos-uso/casos-uso.service';
import { PermisosService } from '@services/seguridad/permisos/permisos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogDeleteCasoUsoComponent } from '@views/seguridad/casos-uso/eliminar-caso-uso/dialog-delete-caso-uso/dialog-delete-caso-uso.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogDeletePermisoComponent } from './dialog-delete-permiso/dialog-delete-permiso.component';

@Component({
  selector: 'app-eliminar-permiso',
  templateUrl: './eliminar-permiso.component.html',
  styleUrls: ['./eliminar-permiso.component.css']
})
export class EliminarPermisoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogDeletePermisoComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private permisosService: PermisosService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
  }

  doAction() {
    this.delete(this.data.id)
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  delete(id: number) {
    this.spinner.show("spEliminarPermiso")
    this.permisosService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide("spEliminarPermiso")
          this.snackBar.showSuccess("Registro eliminado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Eliminar });
        },
        error => {
          this.spinner.hide("spEliminarPermiso")
          this.snackBar.showError(error, "Error");
        }
      )
  }
  trackByItems(index: number, item: any): any { return item; }
}
