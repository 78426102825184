import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateMenuRequest } from '@models/seguridad/menu/createMenuRequest.model';
import { MenuService } from '@services/seguridad/menu/menu.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogCreateMenuComponent } from './dialog-create-menu/dialog-create-menu.component';

@Component({
  selector: 'app-crear-menu',
  templateUrl: './crear-menu.component.html',
  styleUrls: ['./crear-menu.component.css']
})
export class CrearMenuComponent implements OnInit {
  formBuilder = new FormBuilder;
  form: any;
  get f() { return this.form.controls; }
  constructor(
    public dialogRef: MatDialogRef<DialogCreateMenuComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private menuService: MenuService
  ) {

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
      Icono: ["", Validators.compose([Validators.required,])],
      Path: ["", Validators.compose([])],
      Nivel: ["", Validators.compose([Validators.required])],
      IdMenuPadre: ["", Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {

  }

  doAction() {

    let createMenuRequest: CreateMenuRequest = new CreateMenuRequest
    createMenuRequest.descripcion = this.form.controls["Descripcion"].value
    createMenuRequest.icon = this.form.controls["Icono"].value
    createMenuRequest.path = this.form.controls["Path"].value
    createMenuRequest.nivel = this.form.controls["Nivel"].value
    createMenuRequest.idMenuPadre = this.form.controls["IdMenuPadre"].value
    this.create(createMenuRequest)
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  create(createMenuRequest: CreateMenuRequest) {
    this.spinner.show("spCrearMenu")
    this.menuService.create(createMenuRequest)
      .subscribe(
        data => {
          this.spinner.hide("spCrearMenu")
          this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Agregar });
        },
        error => {
          this.spinner.hide("spCrearMenu")
          this.snackBar.showError(error, "Error");
        }
      )
  }

}

