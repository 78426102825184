import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { MenuDto } from '@models/seguridad/menu/menuDto.model';
import { MenuService } from '@services/seguridad/menu/menu.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogUpdateMenuComponent } from '../actualizar-menu/dialog-update-menu/dialog-update-menu.component';
import { DialogViewMenuComponent } from './dialog-view-menu/dialog-view-menu.component';

@Component({
  selector: 'app-ver-menu',
  templateUrl: './ver-menu.component.html',
  styleUrls: ['./ver-menu.component.css']
})
export class VerMenuComponent implements OnInit {

  formBuilder = new FormBuilder;
  form: any;
  menuDto!: MenuDto;
  get f() { return this.form.controls; }
  constructor(
    public dialogRef: MatDialogRef<DialogViewMenuComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private menuService: MenuService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
      Icono: ["", Validators.compose([Validators.required,])],
      Path: ["", Validators.compose([])],
      Nivel: ["", Validators.compose([Validators.required])],
      IdMenuPadre: ["", Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
    this.getById(this.data.id)
    this.form.disable()
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  getById(id: number) {
    this.spinner.show("spUpdateMenu")
    this.menuService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spUpdateMenu")
          this.menuDto = data
          this.seteaForm(data)
        },
        error => {
          this.spinner.hide("spUpdateMenu")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  seteaForm(menuDto: MenuDto) {
    this.form.controls["Descripcion"].setValue(menuDto.descripcion)
    this.form.controls["Icono"].setValue(menuDto.icon)
    this.form.controls["Path"].setValue(menuDto.path)
    this.form.controls["Nivel"].setValue(menuDto.nivel)
    this.form.controls["IdMenuPadre"].setValue(menuDto.idMenuPadre)
    this.form.disable()
  }

}
