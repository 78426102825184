import { BaseDto } from "../base/baseDto.model"
import { CuentaContableDto } from "../cuentas-contables/cuentaContableDto.model"

export class PagoDto extends BaseDto{

  formaPago!: BaseDto | null
  estadoPago!: BaseDto
  importe: number = 0
  fechaPago!: string | null
  fechaVencimiento!: string | null
  fechaAcreditacion!: string | null
  cuentaContableOrigen!: CuentaContableDto
  cuentaContableDestino!: CuentaContableDto
  idCuentaContableOrigen!: number | null
  idCuentaContableDestino!: number | null
  tipoMoneda!: BaseDto | null
  idTipoMoneda!:number | null
  idFormaPago!: number | null
  idEstadoPago!: number
}
