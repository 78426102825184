import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParametroSistemaDescripcion } from '@models/parametros-sistema/parametrosSistemaDescipcion.model';
import { ContratosService } from '../contratos/contratos.service';

@Injectable({
  providedIn: 'root'
})
export class ProcesosService {

  parametroSistemaDescripcion!: ParametroSistemaDescripcion
  constructor(
    private contratoServices: ContratosService
  ) { }

  endContratos(): Observable<any> {
    return this.contratoServices.endContratos()
  }




}
