import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DialogImputacionPropietarioComponent } from "@views/propietarios/dialog-imputacion-propietario/dialog-imputacion-propietario.component";
import { SharedModule } from "src/app/modules/shared.module";
import { BuscarPersonaPropietariaComponent } from "../views/busquedas/buscar-persona-propietaria/buscar-persona-propietaria.component";
import { AddPropietarioPropiedadesComponent } from "../views/propiedades/add-propietario-propiedades/add-propietario-propiedades.component";
import { BtnDialogCreatePropietarioComponent } from "../views/propietarios/btn-dialog-create-propietario/btn-dialog-create-propietario.component";
import { DialogCreatePropietarioComponent } from "../views/propietarios/dialog-create-propietario/dialog-create-propietario.component";
import { FormPersonaPropietariaCreateComponent } from "../views/propietarios/form-persona-propietaria-create/form-persona-propietaria-create.component";
import { FormPropietarioCreateComponent } from "../views/propietarios/form-propietario-create/form-propietario-create.component";
import { FormPropietarioUpdateComponent } from "../views/propietarios/form-propietario-update/form-propietario-update.component";
import { PropietarioComponent } from "../views/propietarios/form-propietario/propietario.component";
import { BancosModule } from "./bancos.module";
import { LocalidadModule } from "./localidad.module";
import { ListPropiedadesPropietarioComponent } from "@views/propietarios/list-propiedades-propietario/list-propiedades-propietario.component";

@NgModule({
    imports:[
        CommonModule,
        SharedModule,
        BancosModule,
        LocalidadModule

    ],
    declarations:[
        PropietarioComponent,
        FormPropietarioCreateComponent,
        FormPropietarioUpdateComponent,
        AddPropietarioPropiedadesComponent,
        BuscarPersonaPropietariaComponent,
        FormPersonaPropietariaCreateComponent,
        DialogCreatePropietarioComponent,
        BtnDialogCreatePropietarioComponent,
        DialogImputacionPropietarioComponent,
        ListPropiedadesPropietarioComponent

      ],
    exports:[
        PropietarioComponent,
        FormPropietarioCreateComponent,
        FormPropietarioUpdateComponent,
        AddPropietarioPropiedadesComponent,
        BuscarPersonaPropietariaComponent,
        FormPersonaPropietariaCreateComponent,
        DialogCreatePropietarioComponent,
        BtnDialogCreatePropietarioComponent,
        DialogImputacionPropietarioComponent,
        ListPropiedadesPropietarioComponent
    ]
})

export class PropietariosModule{}
