import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ContratosService } from '@services/contratos/contratos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposMoviemientoService } from '@services/tipos-movimiento/tipos-movimiento.service';
import { default as _rollupMoment, Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import * as _moment from 'moment';
import { TipoMovimientoDto } from '@models/tipos-movimiento/tipoMovimientoDto.model';
import { MovimientosService } from '@services/movimientos/movimientos.service';
import { CreateMovimientoRequest } from '@models/movimiento/createMovimientoRequest.mode';
import { AccionesDialog, EstadosContrato, TiposMovimiento } from '@models/base/identificadores.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { MatDatepicker } from '@angular/material/datepicker';
import { PropietariosService } from '@services/propietarios/propietarios.service';
import { PropietarioDto } from '@models/propietarios/propietarioDto.model';
import { ParametrosSistemaService } from '@services/parametro-sistema/parametros-sistema.service';
const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-dialog-imputacion-propietario',
  templateUrl: './dialog-imputacion-propietario.component.html',
  styleUrls: ['./dialog-imputacion-propietario.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    [DatePipe],
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DialogImputacionPropietarioComponent implements OnInit {

  @Input() idPropietario!: number

  formBuilder: FormBuilder = new FormBuilder;
  movimientoForm: any;
  lstTiposMovimiento!: TipoMovimientoDto[];
  contratosPropietario!: ContratoDetailDto[];
  lstPropietarios!: PropietarioDto[];
  get f() { return this.movimientoForm.controls; }

  contratoDetailDto!: ContratoDetailDto

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogImputacionPropietarioComponent>,
    private tiposMoviemientoService: TiposMoviemientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private contratoService: ContratosService,
    private movimientosService: MovimientosService,
    public datepipe: DatePipe,
    private propietariosService: PropietariosService,


  ) {
    this.movimientoForm = this.formBuilder.group({
      //Movimiento
      TipoMovimiento: ["", Validators.compose([Validators.required])],
      Descripcion: [""],
      Contrato: [""],
      Comisionable: [""],
      Importe: ["", Validators.compose([Validators.required])],
      Periodo: [moment()],
    })
    if (!isFalsy(this.data))
      this.contratoDetailDto = this.data
  }

  ngOnInit(): void {
    this.getAllTiposMovimientos()
  }

  getAllTiposMovimientos() {
    this.spinner.show("spBusquedaTiposMovimiento")
    this.tiposMoviemientoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTiposMovimiento")
          this.lstTiposMovimiento = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide("spBusquedaTiposMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllPropietarios() {
    this.spinner.show("spBusquedaPropietarios")
    this.propietariosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaPropietarios")
          this.lstPropietarios = data
        },
        error => {
          this.spinner.hide("spBusquedaPropietarios")
          this.snackBar.showError(error, "Error");
        })
  }

  onSubmit() {

    if (isFalsy(this.contratoDetailDto))
      this.contratoDetailDto = this.movimientoForm.controls["Contrato"].value[0]

    if (!isFalsy(this.contratoDetailDto)) {
      let createMovimientoRequest = this.setCreateMovimientoRequest()
      if (createMovimientoRequest) {
        this.createMovimiento(createMovimientoRequest)
        let comisionable = this.movimientoForm.controls["Comisionable"].value
        if (comisionable) {
          let createMovimientoComisionRequest = this.setCreateMovimientoComisioRequest()
          this.createMovimiento(createMovimientoComisionRequest)
        }
      }
    }

  }


  selectPropietario(event: any) {
    this.getAllContratosByIdPropietario(event.id_Propietario)
  }

  setCreateMovimientoRequest(): CreateMovimientoRequest {
    let tipoMovimientoId = this.movimientoForm.controls["TipoMovimiento"].value
    let descripcion = this.movimientoForm.controls["Descripcion"].value == "" ? null : this.movimientoForm.controls["Descripcion"].value
    let importe = this.movimientoForm.controls["Importe"].value == "" ? null : parseFloat(this.movimientoForm.controls["Importe"].value)
    let periodoAnio = this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'yyyy') == "" ? null : this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'yyyy')
    let periodoMes = this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'MM') == "" ? null : this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'MM')
    let periodo = 0
    let fechaEmision = this.datepipe.transform(new Date, 'yyyy-MM-dd');

    let createMovimientoRequest: CreateMovimientoRequest = new CreateMovimientoRequest
    createMovimientoRequest.descripcion = descripcion
    createMovimientoRequest.idTipoMovimiento = tipoMovimientoId
    createMovimientoRequest.importe = importe
    createMovimientoRequest.periodo = periodo
    createMovimientoRequest.periodoAnio = isFalsy(periodoAnio) ? null : parseInt(periodoAnio)
    createMovimientoRequest.periodoMes = isFalsy(periodoMes) ? null : parseInt(periodoMes)
    createMovimientoRequest.idContrato = this.contratoDetailDto.id
    createMovimientoRequest.idPropiedad = this.contratoDetailDto.propiedad.id
    createMovimientoRequest.idInquilino = null
    this.contratoDetailDto.propietarios.forEach(propietario => {
      if (propietario.titular == true)
        createMovimientoRequest.idPropietario = propietario.id
    });

    createMovimientoRequest.fechaEmision = fechaEmision
    createMovimientoRequest.fechaPago = null
    createMovimientoRequest.idBonificacion = null
    createMovimientoRequest.idMovimientoRef = null
    createMovimientoRequest.idPeriodoContrato = null

    return createMovimientoRequest

  }

  setCreateMovimientoComisioRequest(): CreateMovimientoRequest {
    let descripcion = this.movimientoForm.controls["Descripcion"].value == "" ? null : this.movimientoForm.controls["Descripcion"].value
    let importe = this.movimientoForm.controls["Importe"].value == "" ? 0 : parseFloat(this.movimientoForm.controls["Importe"].value)
    let periodoAnio = this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'yyyy') == "" ? null : this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'yyyy')
    let periodoMes = this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'MM') == "" ? null : this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'MM')
    let periodo = 0
    let fechaEmision = this.datepipe.transform(new Date, 'yyyy-MM-dd');

    let createMovimientoRequest: CreateMovimientoRequest = new CreateMovimientoRequest
    createMovimientoRequest.descripcion = "Comisión: " + isFalsy(descripcion) ? "": descripcion
    createMovimientoRequest.idTipoMovimiento = TiposMovimiento.ComisionInmobiliaria

    if (!isFalsy(this.contratoDetailDto.porcentajeComisionPropietario))
      createMovimientoRequest.importe = (importe * this.contratoDetailDto.porcentajeComisionPropietario) / 100
    else
      createMovimientoRequest.importe = importe

    createMovimientoRequest.periodo = periodo
    createMovimientoRequest.periodoAnio = isFalsy(periodoAnio) ? null : parseInt(periodoAnio)
    createMovimientoRequest.periodoMes = isFalsy(periodoMes) ? null : parseInt(periodoMes)
    createMovimientoRequest.idContrato = this.contratoDetailDto.id
    createMovimientoRequest.idPropiedad = this.contratoDetailDto.propiedad.id
    createMovimientoRequest.idInquilino = null
    this.contratoDetailDto.propietarios.forEach(propietario => {
      if (propietario.titular == true)
        createMovimientoRequest.idPropietario = propietario.id
    });

    createMovimientoRequest.fechaEmision = fechaEmision
    createMovimientoRequest.fechaPago = null
    createMovimientoRequest.idBonificacion = null
    createMovimientoRequest.idMovimientoRef = null
    createMovimientoRequest.idPeriodoContrato = null

    return createMovimientoRequest

  }
  createMovimiento(createMovimientoRequest: CreateMovimientoRequest) {
    this.spinner.show("spMovimiento")
    this.movimientosService.create(createMovimientoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showSuccess("Movimiento creado correctamente.", "Exito")
          this.dialogRef.close({ event: AccionesDialog.Agregar });
        },
        error => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllContratosByIdPropietario(idPropietario: number) {
    this.spinner.show("spAllContratosPropietario")
    this.contratoService.getAllByIdPropietario(idPropietario, EstadosContrato.Activo)
      .subscribe(
        data => {
          this.spinner.hide("spAllContratosPropietario")
          this.contratosPropietario = data
        },
        error => {
          this.spinner.hide("spAllContratosPropietario")
          this.snackBar.showError(error, "Error");
        })
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.movimientoForm.controls["Periodo"].value;
    ctrlValue.year(normalizedYear.year());
    // this.date.setValue(ctrlValue);
    this.movimientoForm.controls["Periodo"].setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.movimientoForm.controls["Periodo"].value;
    ctrlValue.month(normalizedMonth.month());
    this.movimientoForm.controls["Periodo"].setValue(ctrlValue);
    datepicker.close();
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}
