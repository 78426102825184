import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";

@NgModule({
    imports:[
        CommonModule,
    ],
    declarations:[],
    exports:[],
    providers:[
      HttpClient
    ]
})

export class CoreModule {}
