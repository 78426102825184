import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  @Input() visible: boolean = true
  @Input() close: boolean = true
  @Input() mensaje: string = ""
  constructor() { }

  ngOnInit(): void {
  }

}
