import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-nuevo-asunto',
  templateUrl: './btn-nuevo-asunto.component.html',
  styleUrls: ['./btn-nuevo-asunto.component.css']
})
export class BtnNuevoAsuntoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
