import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-ir-a-persona',
  templateUrl: './btn-ir-a-persona.component.html',
  styleUrls: ['./btn-ir-a-persona.component.css']
})
export class BtnIrAPersonaComponent implements OnInit {
  @Input() idPersona: number =0
  @Input() isDisabled: boolean =true
  constructor() { }

  ngOnInit(): void {
  }

}
