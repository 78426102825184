<mat-card>
    <table mat-table [dataSource]="dataSource" [hidden]="!data" table matSort matSortStart="asc" class="mat-elevation-z8">

        <ng-container matColumnDef="contrato">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Contrato</th>
            <td mat-cell *matCellDef="let liq">{{liq.contrato.descripcion}} </td>
        </ng-container>

        <ng-container matColumnDef="propietario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Propietario</th>
            <td mat-cell *matCellDef="let liq">{{liq.propietario.descripcion}} </td>
        </ng-container>

        <ng-container matColumnDef="archivo">
            <th mat-header-cell *matHeaderCellDef> Archivo</th>
            <td mat-cell *matCellDef="let liq">
                <button mat-mini-fab color="primary" matTooltip="Descargar archivo." class="button-container" (click)="descargarRecibo(liq.archivoLiquidacion)">
  <mat-icon>file_download
  </mat-icon>
</button></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="no-data-table">
        <span class="with-icon" [hidden]=data><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
    </div>
    <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="10" [pageSizeOptions]="[5, 10, 20,50,100,200]">
    </mat-paginator>
</mat-card>
