import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';

@Component({
  selector: 'app-dialog-adenda-contrato',
  templateUrl: './dialog-adenda-contrato.component.html',
  styleUrls: ['./dialog-adenda-contrato.component.css']
})
export class DialogAdendaContratoComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogAdendaContratoComponent>
  ) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  aceptaAdenda() {
    this.dialogRef.close({ event: AccionesDialog.Aceptar });
  }

}
