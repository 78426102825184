import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateServicioRequest } from '@models/servicios/servicio/createServicioRequest.model';
import { TipoServicio } from '@models/servicios/tipo-servicios/tipoServicioDto.model';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { TipoServiciosService } from '@services/servicios/tipos-servicios/tipo-servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-form-servicio-create',
  templateUrl: './form-servicio-create.component.html',
  styleUrls: ['./form-servicio-create.component.css']
})
export class FormServicioCreateComponent implements OnInit {

  formBuilder: FormBuilder = new FormBuilder;
  servicioForm: FormGroup;
  lstTiposServicio: TipoServicio[] = []
  errorMessage: string = "";
  createServicioRequest: CreateServicioRequest = new CreateServicioRequest;
  disableNroBroche: boolean = false;
  get f() { return this.servicioForm.controls; }

  constructor(
    private serviciosService: ServiciosService,
    private tipoServiciosService: TipoServiciosService,
    private snackBar: SnackBarService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {

    this.servicioForm = this.formBuilder.group({
      //Persona
      TiposServicio: ['', Validators.compose([Validators.required,])],
      Broche: [''],
      NroBroche: ['', Validators.compose([Validators.maxLength(50)])],
    })
  }

  ngOnInit(): void {
    this.getAllTipoServicios();
    this.servicioForm.controls['NroBroche'].setValue("0")
  }

  habilitaNroBroche(evet: any) {
    this.disableNroBroche = evet
  }

  getAllTipoServicios() {
    this.spinner.show("spBusqueda");
    this.tipoServiciosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda");
          this.lstTiposServicio = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));

        },
        error => {
          this.spinner.hide("spBusqueda");
        }
      )
  }

  onReset() {
    this.servicioForm.reset();
  }

  onSubmit() {
    if (this.servicioForm.invalid) {
      return;
    }
    this.setServicioModel()
    this.agregarServicio()
  }

  setServicioModel() {

    this.createServicioRequest = new CreateServicioRequest();
    this.createServicioRequest.id_TipoServicio = this.servicioForm.controls['TiposServicio'].value;
    let nroBroche = this.servicioForm.controls['NroBroche'].value;
    if (nroBroche === "0" || nroBroche === "" || !nroBroche) {
      this.createServicioRequest.nroBroche = "0"
      this.createServicioRequest.broche = false
    }
    else {
      this.createServicioRequest.nroBroche = this.servicioForm.controls['NroBroche'].value;
      this.createServicioRequest.broche = true
    }

  }

  agregarServicio() {
    this.spinner.show()
    this.serviciosService.create(this.createServicioRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Servicio agregado correctamente.", "Exito");
          this.router.navigate(["/servicios"])
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        });
  }

  goBack() {
    window.history.back();
  }

  trackByItems(index: number, item: any): any { return item; }

}
