import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PersonasService } from '@services/personas/personas.service';
import { PropietariosService } from '@services/propietarios/propietarios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { CreatePropietarioRequest } from '@models/propietarios/createPropietarioRequest.model';
import { UpdatePropietarioRequest } from '@models/propietarios/updatePropietarioRequest.model';
import { CreatePropietarioResponse } from '@models/propietarios/createPropietarioResponse.model';
import { BaseDto } from '@models/base/baseDto.model';
import { PropietarioDto } from '@models/propietarios/propietarioDto.model';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'app-form-propietario-create',
  templateUrl: './form-propietario-create.component.html',
  styleUrls: ['./form-propietario-create.component.css']
})
export class FormPropietarioCreateComponent implements OnInit {

  propietarioForm!: FormGroup;
  errorMessage: string = "";
  formBuilder: FormBuilder = new FormBuilder;
  idTipoLiquidacion: number = 0;
  createPropietarioRequest: CreatePropietarioRequest = new CreatePropietarioRequest;
  updatePropietarioRequest: UpdatePropietarioRequest = new UpdatePropietarioRequest;
  propietario!: PropietarioDto;
  banco: BaseDto = new BaseDto;
  idPropietario: number = 0;
  idBanco: number = 0;
  createPropietarioResponse: CreatePropietarioResponse = new CreatePropietarioResponse
  personaDetailDto!:PersonaDetailDto;
  get f() { return this.propietarioForm.controls; }
  lstTipoLiquidacionModel: BaseDto[] = [];
  tipoLiquidacion = new BaseDto
  esPropietarioNuevo: boolean = false;
  agregaPropietario: boolean = false;
  nuevoPropietario: boolean = false
  editaPropietario: boolean = false
  comision: any
  constructor(
    private propietariosService: PropietariosService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private aRoute: ActivatedRoute,
    private personasService: PersonasService,
    private router: Router
  ) {

    this.propietarioForm = this.formBuilder.group({
      Comision: ['', Validators.compose([Validators.required])],
      CuitCuil: ['', Validators.compose([Validators.pattern(/^(20|23|27|30|33)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/)])],
      TipoLiquidacion: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.personaDetailDto = this.personasService.getPersona()
    this.getAllTipoLiquidacion()
  }

  ngOnChange() {
  }



  getAllTipoLiquidacion() {
    this.spinner.show('spTipoLiquidacion')
    this.propietariosService.getAllTiposLiquidacion()
      .subscribe(
        data => {
          this.spinner.hide('spTipoLiquidacion')
          this.lstTipoLiquidacionModel = data
        },
        error => {
          this.snackBar.showError( error, "Error");
          this.spinner.hide('spTipoLiquidacion')
        });
  }

  selectTipoLiquidacion(event: any) {
    this.idTipoLiquidacion = event.value
  }

  selectBanco(event: any) {
    this.banco = event;
  }

  onReset() {
    this.propietarioForm.reset();
  }

  onSubmit() {
    if (this.propietarioForm.invalid) {
      return;
    }
    this.setCreatePropietarioRequest()
    this.createPropietario(this.createPropietarioRequest)

  }

  setCreatePropietarioRequest() {
    this.createPropietarioRequest = new CreatePropietarioRequest();

    this.createPropietarioRequest.id_Persona = this.personaDetailDto.id;
    if (this.banco.id > 0) {
      this.createPropietarioRequest.id_Banco = this.banco.id
    }
    else {
      this.createPropietarioRequest.id_Banco = null
    }
    if (this.idTipoLiquidacion > 0) {
      this.createPropietarioRequest.id_TipoLiquidacion = this.idTipoLiquidacion
    }
    else {
      this.createPropietarioRequest.id_TipoLiquidacion = null
    }

    this.createPropietarioRequest.comision = parseInt(this.propietarioForm.controls['Comision'].value)
    this.createPropietarioRequest.cuit = this.propietarioForm.controls['CuitCuil'].value
    this.createPropietarioRequest.activo = true
  }

  createPropietario(createPropietarioRequest: CreatePropietarioRequest) {
    this.spinner.show()
    this.propietariosService.create(createPropietarioRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.createPropietarioResponse = data
          this.idPropietario = this.createPropietarioResponse.id
          this.esPropietarioNuevo = false
          this.snackBar.showSuccess('Se creo el propietario de manera correcta.', "");
          this.router.navigate(["/propietario/editar"])
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError( error, "Error");
        })
  }

  trackByItems(index: number, item: any): any { return item; }

}

