import { BaseDto } from "../../base/baseDto.model"

export class MenuDto extends BaseDto {

    public icon?:string
    public path?:string
    public nivel?:number
    public idMenuPadre?:number
    public orden?:number
}
