import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateCuentaContableRequest } from '@models/cuentas-contables/createCuentaContableRequest.model';
import { CuentaContableDto } from '@models/cuentas-contables/cuentaContableDto.model';
import { UpdateCuentaContableRequest } from '@models/cuentas-contables/updateCuentaContableRequest.model';
import { TipoPunitorioDto } from '@models/tipo-punitorio/tipoPunitorioDto.model';
import { CuentasContablesService } from '@services/cuentas-contables/cuentas-contables.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { BuscarCuentaContableComponent } from '../../busquedas/buscar-cuenta-contable/buscar-cuenta-contable.component';

@Component({
  selector: 'app-dialog-tipo-punitorio',
  templateUrl: './dialog-cuenta-contable.component.html',
  styleUrls: ['./dialog-cuenta-contable.component.css']
})
export class DialogCuentaContableComponent implements OnInit {

  action: string;
  local_data: any;
  form: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  banco!: BaseDto;
  tiposCuentas = ["Patrimonial"]
  idCuentaContable: any;
  get f() { return this.form.controls; }
  @ViewChild(BuscarCuentaContableComponent) buscarCuentaContableComponentChild: any;

  constructor(
    public dialogRef: MatDialogRef<DialogCuentaContableComponent>,
    private cuentasContablesService: CuentasContablesService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
      Numero: ["", Validators.compose([Validators.required,])],
      Tipo: ["", Validators.compose([Validators.required,])],
      CBU: ["", Validators.compose([Validators.maxLength(22),])],
      Alias: ["", Validators.compose([Validators.maxLength(22),])],
    })

    if (this.action != AccionesDialog.Agregar)
      this.setFomulario(this.local_data.cuentaContableDto)
  }

  doAction() {

    if (this.action == AccionesDialog.Agregar) {
      let createCuentaContableRequest: CreateCuentaContableRequest = new CreateCuentaContableRequest
      createCuentaContableRequest.descripcion = this.form.controls["Descripcion"].value
      createCuentaContableRequest.numero = this.form.controls["Numero"].value
      createCuentaContableRequest.cbu = this.form.controls["CBU"].value
      createCuentaContableRequest.alias = this.form.controls["Alias"].value
      createCuentaContableRequest.idBanco = isFalsy(this.banco) ? null : this.banco.id
      createCuentaContableRequest.tipo = this.form.controls["Tipo"].value
      this.create(createCuentaContableRequest).id

    }
    if (this.action == AccionesDialog.Modificar) {
      let updateCuentaContableRequest: UpdateCuentaContableRequest = new UpdateCuentaContableRequest
      updateCuentaContableRequest.id = this.local_data.cuentaContableDto.id
      updateCuentaContableRequest.descripcion = this.form.controls["Descripcion"].value
      updateCuentaContableRequest.numero = this.form.controls["Numero"].value
      updateCuentaContableRequest.cbu = this.form.controls["CBU"].value
      updateCuentaContableRequest.alias = this.form.controls["Alias"].value
      updateCuentaContableRequest.idBanco = isFalsy(this.banco) ? null : this.banco.id
      updateCuentaContableRequest.tipo = this.form.controls["Tipo"].value
      this.update(updateCuentaContableRequest)
    }

    if (this.action == AccionesDialog.Eliminar) {
      this.delete(this.local_data.cuentaContableDto.id)
    }



  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }


  selectBanco(event: any) {
    this.banco = event;
  }

  setFomulario(cuentaContableDto: CuentaContableDto) {
    this.banco = cuentaContableDto.banco
    this.form.controls["Descripcion"].setValue(cuentaContableDto.descripcion)
    this.form.controls["Numero"].setValue(cuentaContableDto.numero)
    this.form.controls["CBU"].setValue(cuentaContableDto.cbu)
    this.form.controls["Tipo"].setValue(cuentaContableDto.tipo)
    this.form.controls["Alias"].setValue(cuentaContableDto.alias)
  }


  ngOnInit(): void {
  }

  create(createCuentaContableRequest: CreateCuentaContableRequest): CreateResponseBase {
    let createResponseBase: CreateResponseBase = new CreateResponseBase
    this.spinner.show()
    this.cuentasContablesService.create(createCuentaContableRequest)
      .subscribe(
        async data => {
          createResponseBase = data
          this.spinner.hide()
          this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          await this.getById(data.id)
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
    return createResponseBase
  }

  update(updateCuentaContableRequest: UpdateCuentaContableRequest) {
    this.spinner.show()
    this.cuentasContablesService.update(updateCuentaContableRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro modificado correctamente.", "Exito");
          this.dialogRef.close({ event: this.action, data: null });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  delete(id: number) {
    this.cuentasContablesService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.dialogRef.close({ event: this.action, data: null });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError("El registro no se puede eliminar porque tiene referencias asignadas.", "Error");
        })

  }

  getById(id: number) {
    this.spinner.show()
    this.cuentasContablesService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.cuentasContablesService.enviarCuentaContable(data)
          this.dialogRef.close({ event: this.action, data: data });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })
  }

  trackByItems(index: number, item: any): any { return item; }
}
