import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FileUploadMultipleComponent } from "@views/file-upload-multiple/file-upload-multiple.component";
import { FileUploadComponent } from "@views/file-upload/file-upload.component";
import { AsuntosModule } from "./asuntos.module";
import { ContratosModule } from "./contratos.module";
import { PersonasModule } from "./personas.module";
import { PropiedadesModule } from "./propiedades.module";
import { SharedModule } from "./shared.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AsuntosModule,
        ContratosModule,
        PersonasModule,
        PropiedadesModule,
        PersonasModule,
        AsuntosModule
    ],
    declarations: [
        FileUploadComponent,
        FileUploadMultipleComponent
    ],

    providers: [
    ],
    exports: [
      FileUploadComponent,
      FileUploadMultipleComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ArchivosModule { }
