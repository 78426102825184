<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <h2>Auditoría Log Detalles</h2>
  <div class="log-details">
    <p><strong>Id Usuario:</strong> {{ auditoriaLog.id_Usuario }}</p>
    <p><strong>Request Data:</strong> {{ auditoriaLog.requestData }}</p>
    <p><strong>Response Data:</strong> {{ auditoriaLog.responseData }}</p>
    <p><strong>Browser Info:</strong> {{ auditoriaLog.browserInfo }}</p>
    <p><strong>Timestamp:</strong> {{ auditoriaLog.timestamp }}</p>
    <p><strong>Full URL:</strong> {{ auditoriaLog.fullUrl }}</p>
    <p><strong>HTTP Method:</strong> {{ auditoriaLog.httpMethod }}</p>
    <p><strong>HTTP Status Code:</strong> {{ auditoriaLog.httpStatusCode }}</p>
    <p><strong>IP Address:</strong> {{ auditoriaLog.ipAddress }}</p>
    <p><strong>Duration:</strong> {{ auditoriaLog.duration }} ms</p>
    <p><strong>Request Scheme:</strong> {{ auditoriaLog.requestScheme }}</p>
    <p><strong>Request Host:</strong> {{ auditoriaLog.requestHost }}</p>
    <p><strong>Request Path:</strong> {{ auditoriaLog.requestPath }}</p>
    <p><strong>Request Query String:</strong> {{ auditoriaLog.requestQueryString }}</p>
  </div>
</div>
