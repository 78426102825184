import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';

@Component({
  selector: 'app-dialog-editar-importe-periodo',
  templateUrl: './dialog-editar-importe-periodo.component.html',
  styleUrls: ['./dialog-editar-importe-periodo.component.css']
})
export class DialogEditarImportePeriodoComponent implements OnInit {

  form: FormGroup;
  get f() { return this.form.controls; }
  constructor(
    private formBuilder: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogEditarImportePeriodoComponent>,
  ) {

    this.form = this.formBuilder.group({
      ImporteAlquiler: ['']
    });

    this.form.controls["ImporteAlquiler"].setValue(this.data.importe)
  }

  ngOnInit(): void {
  }

  cambiarImporte() {
    const importe = this.form.controls["ImporteAlquiler"].value
    const id = this.data.id
    const data = {
      id: id,
      importeEntrega: importe
    }
    this.dialogRef.close({ data: data, event: AccionesDialog.Aceptar });
  }


}
