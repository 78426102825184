<!-- <mat-form-field>
  <mat-label>Ingrese rango de vencimientos</mat-label>
  <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
    <input matStartDate formControlName="FechaDesde" placeholder="Fecha desde" (focus)="setActiveField('desde')">
    <input matEndDate formControlName="FechaHasta" placeholder="Fecha hasta" (focus)="setActiveField('hasta')">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker
    #picker
    startView="multi-year"
    (yearSelected)="chosenYearHandler($event)"
    (monthSelected)="chosenMonthHandler($event, picker)"
    panelClass="example-month-picker">
  </mat-date-range-picker>
</mat-form-field> -->

<div class="row">
<div class="col-lg-3">
  <mat-form-field>
    <mat-label>Periodo desde</mat-label>
    <input matInput [matDatepicker]="dpPeriodoDesde" formControlName="FechaDesde">
    <mat-hint>MM/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="dpPeriodoDesde"></mat-datepicker-toggle>
    <mat-datepicker #dpPeriodoDesde
                    startView="multi-year"
                    (monthSelected)="setPeriodoDesdeMonthAndYear($event, dpPeriodoDesde)"
                    panelClass="example-month-picker">
    </mat-datepicker>
  </mat-form-field>
</div>
<div class="col-lg-3">

<mat-form-field>
  <mat-label>Periodo hasta</mat-label>
  <input matInput [matDatepicker]="dpPeriodoHasta" formControlName="FechaHasta">
  <mat-hint>MM/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="dpPeriodoHasta"></mat-datepicker-toggle>
  <mat-datepicker #dpPeriodoHasta
                  startView="multi-year"
                  (monthSelected)="setPeriodoHastaMonthAndYear($event, dpPeriodoHasta)"
                  panelClass="example-month-picker">
  </mat-datepicker>
</mat-form-field>
</div>
</div>



<!-- Mostrar error si el rango de fechas no es válido -->
<!-- <div *ngIf="form.errors?.invalidRange">
  <mat-error>La fecha de inicio no puede ser mayor que la fecha de fin.</mat-error>
</div> -->
