import { holdReady } from "jquery";

export class Identificador {
  public id: number = 0;
  public tabla: string = "";
  public valor: string = "";
  public detalle: string = "";
}

export enum SnackbarClass {
  success = "success",
  info = "info",
  warning= "warning",
  error="error"
}
export enum EstadosContrato {
  Activo = 1,
  Finalizado = 2,
  Rescindido= 3
}

export enum EstadosComprobantes {
  Emitido = 1,
  Anulado = 2,
  Pendiente= 3,
  PendienteAutorizacion= 4,
  Rechazado= 5,
  Autorizado= 6,
  Finalizado= 7
}
export enum TiposDocumentos{
  DNI = 1,
  LE = 2,
  CI= 3,
  LC= 4,
  PASS=5,
  CC= 6
}

export enum EstadosPropiedad {
  ConContrato = 1,
  SinContrato = 2,
  Deuda = 3,
  Legales = 4,
}

export enum EstadosPago {
  Pendiente = 1,
  Completo = 2,
}

export enum TiposContrato {
  ConAjuste = 1,
  Fijo = 2,
}

export enum TiposAjusteContrato {
  SIN = 1,
  ICL = 2,
  IPC = 3,
  USD = 4,
}

export enum TiposComprobantes {
  Resumen = 1,
  Liquidación = 2,
  Recibo = 3,
}

export enum EstadosDetalleServicioPropiedad {
  Pagado = 1,
  NoVencido = 2,
  Deuda = 3,
  Cobrado = 4,
  PagadoSinCobro= 5
}

export enum QuienPaga {
  Inquilino = 1,
  Propietario = 2,
  SegunContrato = 3
}

export enum TiposSellado {
  CasaViviendaCGarantia = 1,
  CasaViviendaSGarantia = 2,
  ComercialCGarantia = 3,
  ComercialSGarantia = 4
}

export enum TiposMovimiento {
  PunitorioContrato = 1,
  SaldoAdeuda = 2,
  SaldoAFavor = 3,
  LiquidacionPropietario = 4,
  SelladoContrato = 5,
  HonorarioContrato = 6,
  EntregaParcialAlquiler = 7,
  SaldoDeudaPeriodo = 8,
  Bonificaciones = 9,
  IngresoCaja= 10,
  EgresoCaja= 11,
  DeudaBonificacion= 12,
  ComisionInmobiliaria= 13,
  CobroParcialAlquiler= 14,
}

export enum TiposPunitorio {
  Sin = 1,
  Bajo= 2,
  MedioBajo = 3,
  Alto = 4,
  MedioAlto = 5,
  MontoFijo = 6
}

export enum TiposMontos {
  PorcentajeAlquiler = 1,
  MontoFijo = 2
}

export enum TiposFolios {
  FolioPersonalCronologico = 1,
  FolioReal = 2,
  SinFolio = 3
}

export enum TiposGarantia {
  ReciboSueldo = 1,
  Propiedad = 2,
  Fianza = 3
}

export enum TiposMonedas {
  Pesos = 1,
  Dolares = 2
}

export enum TiposEntidad {
  Personas = 1,
  Propiedades = 2,
  Contratos = 3
}

export enum TiposRecibosInquilinos {
  ReciboSimple = 1
}

export enum TiposRecibosPropietarios {
  ReciboSimple = 1
}

export enum PropiedaClase {
  CasaVivienda = 1,
  LocalComercial = 2
}
export enum AccionesDialog {
  Agregar = "Agregar",
  Modificar = "Modificar",
  Eliminar = "Eliminar",
  Cancelar = "Cancelar",
  Aceptar = "Aceptar",
  Ver = "Ver"
}

export enum Roles {
  SuperAdministradores = 1,
  Administradores = 2,
  Usuarios = 3,
  Clientes = 4
}

export enum Asuntos {
  DNIFrente = 1,
  DNIDorso = 2,
  FotoPerfil = 3,
  Contratos = 4,
  DocumentosPropiedad = 5,
  Plantillas = 6

}

export enum TiposBonificaciones {
  Inquilinos = 1,
  Propietarios = 2,
  Promociones = 3,
  Inmobiliaria = 4,
  InmobiliariaPropietario = 5,
  InmobiliariaInquilino = 6,
}


export enum ParametrosConfiguracion {

  Iva = 1,
  Caja = 2,
  CodigoAlquiler = 3,
  UrlLogin = 4,
  Sender = 5,
  SmtpServer = 6,
  Port = 7,
  Username = 8,
  Password = 9,
  SslTls = 10,
  DiasFinalizaContrato = 11,
  ComisionGananciaInmobiliaria = 12,
  ComisionGananciaPropietario = 13,
  FechaGenLiquizacion = 14,
  FechaGenResumen = 15,
  CuentaContableDefecto = 16,
  NotaResumen = 17,
  DarkMode = 18,
  CantidadMesesDeudaPropiedad = 19,
  NotaRecibo = 20,
  NotaLiquidacion = 21,
  DuracionMesesArchivosTemporales = 22,
  CantidadMesesCorridaIcl= 23,
  DuracionAlertas= 24
}

export enum FormasPagos{
  Efectivo=1,
  Trasferencia=2,
  TarjetaCredito=3,
  ChequePropio=4,
  ChequeDiferido=5,
  ChequeDeTercero=6,
  TarjetaDebito=7,
  DepositoBanco=8,
  MonederoElectronico=9,
  Otro=10
}

export enum RangoFechas
{
  Hoy='Hoy',
  Diario='Diario',
  Mensual='Mensual',
  Bimestral='Bimestral',
  Trimestral='Trimestral',
  Semestral='Semestral',
  Anual='Anual',
  Todos='Todos'
}
export enum RangoFechasValores
{
  Hoy=8.64e+6,
  Diario=8.64e+7,
  Mensual=2.592e+9,
  Bimestral=5.184e+9,
  Trimestral=7.776e+9,
  Semestral=1.555e+10,
  Anual=3.154e+10,
  Todos=3.154e+11
}

export enum TiposFechaValidaciones{
  Hoy='Hoy',
  Anterior='Anterior',
  Posterior='Posterior',
}
