import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuditoriaLogDto } from '@models/auditoriaLog/auditoriaLogDto.model';
import { AuditoriaLogFilter } from '@models/auditoriaLog/auditoriaLogFilter.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AutoriaLogService {

  constructor(
    private http: HttpClient
  ) { }

  deleteAll():Observable<any>
  {
    return  this.http.post<any>("/api/AuditoriaLog/DeleteAll",null);
  }

  deleteOldAuditoriaLog(meses:number):Observable<any>
  {
    return  this.http.post<any>("/api/AuditoriaLog/DeleteOldAuditoriaLog",meses);
  }

  getAllByFilter(auditoriaLogFilter:AuditoriaLogFilter):Observable<AuditoriaLogDto[]>
  {
    return  this.http.post<AuditoriaLogDto[]>("/api/AuditoriaLog/GetAllByFilter",auditoriaLogFilter);
  }

}
