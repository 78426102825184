import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ServicioDto } from '@models/servicios/servicio/servicioDto.model';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogDeleteComponent } from '../../genericos/dialog-delete/dialog-delete.component';

@Component({
  selector: 'app-listado-servicios',
  templateUrl: './listado-servicios.component.html',
  styleUrls: ['./listado-servicios.component.css']
})
export class ListadoServiciosComponent implements OnInit {
  data = false;
  displayedColumns: string[] = [];
  public servicios: ServicioDto[] = [];
  dataSource = new MatTableDataSource<ServicioDto>();
  errorMessage: string = "";

  constructor(
    public dialog: MatDialog,
    private serviciosService: ServiciosService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["tipoServicio", "nroBroche", "accion"] :
        ["tipoServicio", "broche", "nroBroche", "accion"];
    });

    this.dataSource.filterPredicate = (data: ServicioDto, filter: string) => {
      const dataStr = `${data.tipoServicio.descripcion} ${data.nroBroche}`.toLowerCase();
      return dataStr.includes(filter.trim().toLowerCase());
    };
  }

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.dataSource.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.dataSource.paginator = mp;
  }

  ngOnInit(): void {
    this.getAllServicios();
  }

  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
      const filterValue = (event.target as HTMLInputElement).value;
          this.dataSource.filter = filterValue.trim().toLowerCase();

          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
      }
  }

  getAllServicios() {
    this.spinner.show();
    this.serviciosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide();
          this.dataSource.data = data.sort((a, b) => a.tipoServicio.descripcion.localeCompare(b.tipoServicio.descripcion));
          this.data = this.dataSource.data.length > 0;
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      );
  }

  openDialogDelete(action: any, id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.autoFocus = false;
    dialogConfig.data = { action, id };

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.delete(result.data);
      }
    });
  }

  delete(id: number) {
    this.spinner.show();
    this.serviciosService.delete(id)
      .subscribe(
        () => {
          this.spinner.hide();
          this.snackBar.showSuccess("Registro eliminado correctamente.", "Exito");
          this.getAllServicios();
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      );
  }
}
