import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listado-caja',
  templateUrl: './listado-caja.component.html',
  styleUrls: ['./listado-caja.component.css']
})
export class ListadoCajaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
