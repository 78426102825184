import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageSuccessfullDto } from '@models/base/MessageSuccessfullDto.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionApiService {

  constructor(
    private http: HttpClient
  ) { }

  getCurrenApiVersion(): Observable<MessageSuccessfullDto> {
    return this.http.get<MessageSuccessfullDto>("/api/ApiVersion/GetCurrenApiVersion")
  }
}
