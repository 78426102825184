import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { TiposFolios, TiposGarantia } from '@models/base/identificadores.model';
import { CreateGarantiaRequest } from '@models/garantia/createGarantiaRequest.model';
import { GarantiaDto } from '@models/garantia/garantiaDto.model';
import { GarantiaSummaryDto } from '@models/garantia/garantiaSummaryDto.model';
import { UpdateGarantiaRequest } from '@models/garantia/updateGarantiaRequest.model';
import { GarantiasService } from '@services/garantias/garantias.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposFolioService } from '@services/tipos-folio/tipos-folio.service';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { EnableGarantiaRequest } from '@models/garantia/enableGarantiaRequest.model';
import { DisableGarantiaRequest } from '@models/garantia/disableGarantiaRequest.model';

@Component({
  selector: 'app-mis-garantias-crud',
  templateUrl: './mis-garantias-crud.component.html',
  styleUrls: ['./mis-garantias-crud.component.css']
})
export class MisGarantiasCrudComponent implements OnInit {
  data = false
  dataSource = new MatTableDataSource<GarantiaSummaryDto>();
  garantiaDto: GarantiaDto = new GarantiaDto
  validador: boolean = false;
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
}
  }
  lstTiposGarantiaModel: BaseDto[] = []
  lstTiposFolios: BaseDto[] = []
  personaDetailDto!: PersonaDetailDto
  id_PersonaInquilino!: number;
  formBuilder: FormBuilder = new FormBuilder
  createGarantiaRequest: CreateGarantiaRequest = new CreateGarantiaRequest
  idGarantia: number = 0
  mostrarPersona!: boolean;
  mostrarPropiedad!: boolean;
  mostrarFianza!: boolean;
  formTipoGarantia: FormGroup;
  formPropiedad: FormGroup;
  formPersonaGarantia: FormGroup;
  nuevaGarantia!: boolean;
  formFianza: FormGroup;
  garantiaSummaryDtoTable: GarantiaSummaryDto = new GarantiaSummaryDto
  updateGarantiaRequest!: UpdateGarantiaRequest;
  isEditMode: boolean = false
  indivisa: number = 100
  displayedColumns!: string[];
  idTipoFolio: any;
  idTipoGarantia: any;
  get fPro() { return this.formPropiedad.controls; }
  get fPer() { return this.formPersonaGarantia.controls; }
  get fG() { return this.formTipoGarantia.controls; }
  get fTL() { return this.formFianza.controls; }



  constructor(
    private garantiaService: GarantiasService,
    private snackBar: SnackBarService,
    private garantiasService: GarantiasService,
    private spinner: NgxSpinnerService,
    private tiposFolioService: TiposFolioService,
    private breakpointObserver: BreakpointObserver,
    private personasService: PersonasService
  ) {

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["tipoGarantia", "toggle", "accion"] :
        ["tipoGarantia", "legajo", "razonSocial", "cuitCuil", "sueldo", "toggle", "accion"];
    });

    this.formTipoGarantia = this.formBuilder.group({
      TipoGarantia: ['', Validators.compose([Validators.required])],
    });
    this.formPersonaGarantia = this.formBuilder.group({
      Sueldo: [""],
      CuitCuil: ['', Validators.compose([Validators.pattern(/^([0-9]{11}|[0-9]{2}-[0-9]{8}-[0-9]{1})$/)])],
      Empleador: ['', Validators.compose([Validators.maxLength(200), Validators.required])],
    });
    this.formFianza = this.formBuilder.group({
      Cuit: ['', Validators.compose([Validators.pattern(/^[0-9]{2}-[0-9]{8}-[0-9]$/)])],
      RazonSocial: ['', Validators.compose([Validators.maxLength(200), Validators.required])],
    });
    this.formPropiedad = this.formBuilder.group({
      TipoFolio: ['', Validators.compose([Validators.required],)],
      Tomo: ['', Validators.compose([Validators.maxLength(20)])],
      NroFolio: ['', Validators.compose([Validators.maxLength(20), Validators.required],)],
      Numero: ['', Validators.compose([Validators.maxLength(20)],)],
      Indivisa: [100, Validators.compose([Validators.maxLength(20)])],
      Ubicacion: ['', Validators.compose([Validators.maxLength(100)],)],
      Localidad: ['', Validators.compose([Validators.maxLength(100)],)],
      PropiedadHorizontal: [''],

    });

  }

  ngOnInit(): void {
    this.personaDetailDto = this.personasService.getPersona()
    this.getAllGarantias(this.personaDetailDto.id)


    this.spinner.show("spPersona")
    forkJoin({
      tiposGarantias: this.garantiasService.getAllTiposGarantia(),
      tiposFolio: this.tiposFolioService.getAll()
    })
      .subscribe(
        ({ tiposGarantias, tiposFolio }) => {
          this.lstTiposGarantiaModel = tiposGarantias
          this.lstTiposFolios = tiposFolio
          this.spinner.hide("spPersona")

        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spPersona")
        });

  }

  editarGarantia(garantia: GarantiaSummaryDto) {
    this.garantiaService.garantia = garantia
    this.isEditMode = true
    this.idGarantia = garantia.id
    this.formTipoGarantia.controls['TipoGarantia'].disable()
    this.getGarantiaById(garantia.id)
  }

  getAllTipoFolios() {
    this.spinner.show("spFromGarantia")
    this.tiposFolioService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spFromGarantia")
          this.lstTiposFolios = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide("spFromGarantia")
        }
      )
  }

  getAllTipoGarantias() {
    this.spinner.show("spFromGarantia")
    this.garantiasService.getAllTiposGarantia()
      .subscribe(
        data => {
          this.spinner.hide("spFromGarantia")
          this.lstTiposGarantiaModel = data
        },
        error => {
          this.spinner.hide("spFromGarantia")
        }
      )
  }

  getAllGarantias(idPersona: number) {
    this.spinner.show("spListadoGarantias")
    this.garantiaService.getAllGarantiasByPersona(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spListadoGarantias")
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide("spListadoGarantias")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  selectTipoFolio(event: any) {
    this.idTipoFolio = event;

  }

  actualizaActivo(garantia: any) {
    garantia.activo = !garantia.activo;
    if (garantia.activo == true) {
      let enableGarantiaRequest:EnableGarantiaRequest = new EnableGarantiaRequest;
      enableGarantiaRequest.id_Garantia = garantia.id
      this.enableGarantia(enableGarantiaRequest)
    }
    else {
      let disableGarantiaRequest:DisableGarantiaRequest = new DisableGarantiaRequest;
      disableGarantiaRequest.id_Garantia = garantia.id
      this.disableGarantia(disableGarantiaRequest)
    }
  }

  enableGarantia(enableGarantiaRequest: EnableGarantiaRequest) {
    this.spinner.show("spListadoGarantias")
    this.garantiaService.enableGarantia(enableGarantiaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spListadoGarantias")
          this.snackBar.showSuccess(data.text, "");
        },
        error => {
          this.spinner.hide("spListadoGarantias")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  disableGarantia(disableGarantiaRequest: DisableGarantiaRequest) {
    this.spinner.show("spListadoGarantias")
    this.garantiaService.disableGarantia(disableGarantiaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spListadoGarantias")
          this.snackBar.showSuccess(data.text, "");
        },
        error => {
          this.spinner.hide("spListadoGarantias")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  selectTipoGarantia(event: any) {
    this.idTipoGarantia = event;
    //Persona
    if (event == TiposGarantia.ReciboSueldo) {
      this.mostrarPersona = true
      this.mostrarPropiedad = false
      this.mostrarFianza = false
      this.formPropiedad.reset();
      this.formFianza.reset();
    }
    //Propiedad
    if (event == TiposGarantia.Propiedad) {
      this.mostrarPersona = false
      this.mostrarPropiedad = true
      this.mostrarFianza = false
      this.formPersonaGarantia.reset();
      this.formFianza.reset();
    }
    //Fianza
    if (event == TiposGarantia.Fianza) {
      this.mostrarPersona = false
      this.mostrarPropiedad = false
      this.mostrarFianza = true
      this.formPersonaGarantia.reset();
      this.formPropiedad.reset();
    }
  }


  getGarantiaById(idGarantia: number) {
    this.spinner.show("spFromGarantia")
    this.garantiasService.getGarantiaById(idGarantia)
      .subscribe(
        async data => {
          this.spinner.hide("spFromGarantia")
          this.garantiaDto = data
          await this.setGarantiaSummaryDto(this.garantiaDto)
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })
  }

  getGarantiaByForTable(idGarantia: number, accion: string) {
    this.spinner.show("spFromGarantia")
    this.garantiasService.getGarantiaById(idGarantia)
      .subscribe(
        async data => {

          this.spinner.hide("spFromGarantia")
          this.garantiaDto = data
          await this.setGarantiaSummaryDtoTable(this.garantiaDto)
        },
        error => {
          this.spinner.hide("spFromGarantia")
          this.snackBar.showError(error, "Error");
        })
  }

  getGarantiaByForUpdate(idGarantia: number) {
    this.spinner.show("spFromGarantia")
    this.garantiasService.getGarantiaById(idGarantia)
      .subscribe(
        async data => {
          this.spinner.hide("spFromGarantia")
          this.garantiaDto = data
          await this.setGarantiaSummaryDtoUpdate(this.garantiaDto)

        },
        error => {
          this.spinner.hide("spFromGarantia")
          this.snackBar.showError(error, "Error");
        })
  }

  onSubmit() {
    this.spinner.show("spFromGarantia")
    let garantia = this.setCreateGarantiaRequest()
    this.garantiaService.create(garantia)
      .subscribe(
        async data => {
          const createResponseBase: CreateResponseBase = new CreateResponseBase
          createResponseBase.id = data.id
          if (createResponseBase.id)
            await this.getGarantiaByForTable(createResponseBase.id, "create")
          this.snackBar.showSuccess("Garantia agregada correctamente.", "Exito");
          this.spinner.hide("spFromGarantia")
          this.onReset()
        },
        error => {
          this.spinner.hide("spFromGarantia")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  onSubmitUpdate() {
    this.spinner.show("spFromGarantia")
    let garantia = this.setUpdateGarantiaRequest()
    this.garantiaService.update(garantia)
      .subscribe(
        async data => {
          this.spinner.hide("spFromGarantia")
          this.getGarantiaByForUpdate(this.updateGarantiaRequest.id)
          this.snackBar.showSuccess("Garantia actualizada correctamente.", "Exito");
          this.onReset()
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  onReset() {
    this.formPersonaGarantia.reset();
    this.formTipoGarantia.reset();
    this.formPropiedad.reset();
    this.formFianza.reset()
    this.formTipoGarantia.controls["TipoGarantia"].setValue(0)
    this.mostrarPersona = false
    this.mostrarPropiedad = false
    this.mostrarFianza = false
    this.isEditMode = false
    this.formTipoGarantia.controls['TipoGarantia'].enable()
  }

  setCreateGarantiaRequest(): CreateGarantiaRequest {
    this.createGarantiaRequest = new CreateGarantiaRequest
    this.createGarantiaRequest.id_Persona = this.personaDetailDto.id
    this.createGarantiaRequest.id_TipoGrantia = this.idTipoGarantia
    if (this.createGarantiaRequest.id_TipoGrantia == TiposGarantia.ReciboSueldo) {
      //Tipo Persona
      this.createGarantiaRequest.razonSocial = this.formPersonaGarantia.controls["Empleador"].value
      this.createGarantiaRequest.cuitCuil = this.formPersonaGarantia.controls["CuitCuil"].value
      this.createGarantiaRequest.sueldo = this.formPersonaGarantia.controls["Sueldo"].value
      this.createGarantiaRequest.idTipoFolio = TiposFolios.SinFolio
    }
    if (this.createGarantiaRequest.id_TipoGrantia == TiposGarantia.Propiedad) {
      //Tipo Propiedad
      this.createGarantiaRequest.idTipoFolio = this.formPropiedad.controls['TipoFolio'].value
      this.createGarantiaRequest.nroFolio = this.formPropiedad.controls['NroFolio'].value

      if (this.createGarantiaRequest.idTipoFolio == TiposFolios.FolioPersonalCronologico) {
        this.createGarantiaRequest.tomo = this.formPropiedad.controls['Tomo'].value
        this.createGarantiaRequest.numero = this.formPropiedad.controls['Numero'].value
      }
      else {
        this.createGarantiaRequest.tomo = ""
        this.createGarantiaRequest.numero = ""
      }

      this.createGarantiaRequest.indivisa = this.formPropiedad.controls['Indivisa'].value
      this.createGarantiaRequest.ubicacion = this.formPropiedad.controls['Ubicacion'].value
      this.createGarantiaRequest.localidad = this.formPropiedad.controls['Localidad'].value
      this.createGarantiaRequest.horizontal = this.formPropiedad.controls['PropiedadHorizontal'].value
    }
    if (this.createGarantiaRequest.id_TipoGrantia == TiposGarantia.Fianza) {
      //Tipo Fianza
      this.createGarantiaRequest.razonSocial = this.formFianza.controls['RazonSocial'].value
      this.createGarantiaRequest.cuitCuil = this.formFianza.controls['Cuit'].value
      this.createGarantiaRequest.idTipoFolio = TiposFolios.SinFolio
    }

    return this.createGarantiaRequest
  }

  setUpdateGarantiaRequest(): UpdateGarantiaRequest {
    this.updateGarantiaRequest = new UpdateGarantiaRequest
    this.updateGarantiaRequest.id = this.garantiaDto.id
    this.updateGarantiaRequest.id_Persona = this.personaDetailDto.id
    this.updateGarantiaRequest.id_TipoGrantia = this.idTipoGarantia
    if (this.updateGarantiaRequest.id_TipoGrantia == TiposGarantia.ReciboSueldo) {
      //Tipo Persona
      this.updateGarantiaRequest.razonSocial = this.formPersonaGarantia.controls["Empleador"].value
      this.updateGarantiaRequest.cuitCuil = this.formPersonaGarantia.controls["CuitCuil"].value
      this.updateGarantiaRequest.sueldo = this.formPersonaGarantia.controls["Sueldo"].value
      this.updateGarantiaRequest.idTipoFolio = TiposFolios.SinFolio
    }
    if (this.updateGarantiaRequest.id_TipoGrantia == TiposGarantia.Propiedad) {
      //Tipo Propiedad
      this.updateGarantiaRequest.idTipoFolio = this.formPropiedad.controls['TipoFolio'].value
      if (this.updateGarantiaRequest.idTipoFolio == TiposFolios.FolioPersonalCronologico) {
        this.updateGarantiaRequest.tomo = this.formPropiedad.controls['Tomo'].value
        this.updateGarantiaRequest.numero = this.formPropiedad.controls['Numero'].value
      }
      else {
        this.updateGarantiaRequest.tomo = ""
        this.updateGarantiaRequest.numero = ""
      }

      this.updateGarantiaRequest.nroFolio = this.formPropiedad.controls['NroFolio'].value
      this.updateGarantiaRequest.indivisa = this.formPropiedad.controls['Indivisa'].value
      this.updateGarantiaRequest.ubicacion = this.formPropiedad.controls['Ubicacion'].value
      this.updateGarantiaRequest.localidad = this.formPropiedad.controls['Localidad'].value
      this.createGarantiaRequest.horizontal = this.formPropiedad.controls['PropiedadHorizontal'].value
    }
    if (this.updateGarantiaRequest.id_TipoGrantia == TiposGarantia.Fianza) {
      //Tipo Fianza
      this.updateGarantiaRequest.razonSocial = this.formFianza.controls['RazonSocial'].value
      this.updateGarantiaRequest.cuitCuil = this.formFianza.controls['Cuit'].value
      this.updateGarantiaRequest.idTipoFolio = TiposFolios.SinFolio
    }

    return this.updateGarantiaRequest
  }

  setGarantiaSummaryDtoTable(garantia: GarantiaDto) {
    this.garantiaSummaryDtoTable.id = garantia.id
    this.garantiaSummaryDtoTable.activo = garantia.activo;
    this.garantiaSummaryDtoTable.cuitCuil = garantia.cuitCuil
    this.garantiaSummaryDtoTable.descripcion = garantia.descripcion
    this.garantiaSummaryDtoTable.nroFolio = garantia.nroFolio
    this.garantiaSummaryDtoTable.numero = garantia.numero
    this.garantiaSummaryDtoTable.tomo = garantia.tomo
    this.garantiaSummaryDtoTable.ubicacion = garantia.ubicacion
    this.garantiaSummaryDtoTable.idTipoFolio = garantia.idTipoFolio
    this.garantiaSummaryDtoTable.tipoGarantia = garantia.tipoGarantia
    this.garantiaSummaryDtoTable.razonSocial = garantia.razonSocial
    this.garantiaSummaryDtoTable.sueldo = garantia.sueldo
    this.dataSource.data.push({ ...this.garantiaSummaryDtoTable })
    this.dataSource.data = this.dataSource.data.map(o => {
      return o;
    })
    this.data = this.dataSource.data.length >= 1 ? true : false

  }

  setGarantiaSummaryDtoUpdate(garantia: GarantiaDto) {
    this.idGarantia = garantia.id
    this.garantiaSummaryDtoTable.id = garantia.id
    this.garantiaSummaryDtoTable.activo = garantia.activo;
    this.garantiaSummaryDtoTable.cuitCuil = garantia.cuitCuil
    this.garantiaSummaryDtoTable.descripcion = garantia.descripcion
    this.garantiaSummaryDtoTable.nroFolio = garantia.nroFolio
    this.garantiaSummaryDtoTable.tipoGarantia = garantia.tipoGarantia
    this.garantiaSummaryDtoTable.razonSocial = garantia.razonSocial
    this.garantiaSummaryDtoTable.sueldo = garantia.sueldo

    this.dataSource.data = this.dataSource.data.map((o: GarantiaSummaryDto) => {
      if (o.id === this.garantiaSummaryDtoTable.id) {
        o = this.garantiaSummaryDtoTable;
      }
      return o;
    })
  }

  setGarantiaSummaryDto(garantia: GarantiaDto) {
    this.garantiaDto = garantia
    switch (garantia.tipoGarantia.id) {
      case TiposGarantia.ReciboSueldo: {
        this.formTipoGarantia.controls['TipoGarantia'].setValue(garantia.tipoGarantia.id)
        this.formPersonaGarantia.controls['Sueldo'].setValue(garantia.sueldo)
        this.formPersonaGarantia.controls['CuitCuil'].setValue(garantia.cuitCuil)
        this.formPersonaGarantia.controls['Empleador'].setValue(garantia.razonSocial)
        this.idTipoGarantia = garantia.tipoGarantia.id
        this.idTipoFolio = TiposFolios.SinFolio
        this.mostrarPersona = true;
        this.mostrarFianza = false
        this.mostrarPropiedad = false;

        break;
      }
      case TiposGarantia.Propiedad: {
        this.formTipoGarantia.controls['TipoGarantia'].setValue(garantia.tipoGarantia.id)
        this.formPropiedad.controls['NroFolio'].setValue(garantia.nroFolio)
        this.formPropiedad.controls['Localidad'].setValue(garantia.localidad)
        this.formPropiedad.controls['Ubicacion'].setValue(garantia.ubicacion)
        this.formPropiedad.controls['PropiedadHorizontal'].setValue(garantia.horizontal)
        this.formPropiedad.controls['Indivisa'].setValue(garantia.indivisa)
        this.indivisa = garantia.indivisa
        this.formPropiedad.controls['Numero'].setValue(garantia.numero)
        this.formPropiedad.controls['Tomo'].setValue(garantia.tomo)
        this.formPropiedad.controls['TipoFolio'].setValue(garantia.idTipoFolio)
        this.idTipoFolio = garantia.idTipoFolio
        this.idTipoGarantia = garantia.tipoGarantia.id
        this.mostrarPropiedad = true;
        this.mostrarPersona = false;
        this.mostrarFianza = false
        break;
      }
      case TiposGarantia.Fianza: {
        this.formTipoGarantia.controls['TipoGarantia'].setValue(garantia.tipoGarantia.id)
        this.formFianza.controls['Cuit'].setValue(garantia.cuitCuil)
        this.formFianza.controls['RazonSocial'].setValue(garantia.razonSocial)
        this.idTipoGarantia = garantia.tipoGarantia.id
        this.idTipoFolio = TiposFolios.SinFolio
        this.mostrarFianza = true
        this.mostrarPersona = false;
        this.mostrarPropiedad = false;
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  trackByItems(index: number, item: any): any { return item; }

}
