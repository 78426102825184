import { FlatTreeControl } from '@angular/cdk/tree';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTreeFlattener } from '@angular/material/tree';
import { Chart, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { RangoFechasValores } from '@models/base/identificadores.model';
import { ContadorComprobantePorEstadoResponse } from '@models/caja/contadorComprobantePorEstadoResponse.model';
import { ContadorComprobantePorTipoResponse } from '@models/caja/contadorComprobantePorTipoResponse.model';
import { ContadorComprobantePorTipoYEstadoResponse } from '@models/caja/contadorComprobantePorTipoYEstadoResponse.model';
import { ContadorMovimientoPorTipoResonse } from '@models/caja/contadorMovimientoPorTipoResonse.model';
import { ContadorMovimientoResponse } from '@models/caja/contadorMovimientoResponse.model';
import { ContadorPeriodoContratoResponse } from '@models/caja/contadorPeriodoContratoResponse.model';
import { CajaService } from '@services/caja/caja.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-dashboard-caja',
  templateUrl: './dashboard-caja.component.html',
  styleUrls: ['./dashboard-caja.component.css'],
  providers: [DatePipe]
})
export class DashboardCajaComponent implements OnInit {
  formBuilder: FormBuilder = new FormBuilder;
  form: FormGroup;
  contadorPeriodoContratoResponse!: ContadorPeriodoContratoResponse;
  contadorMovimientoResponse!: ContadorMovimientoResponse;
  contadorComprobantePorEstadoResponse!: ContadorComprobantePorEstadoResponse;
  contadorMovimientoPorTipoResponse!: ContadorMovimientoPorTipoResonse;
  contadorComprobantePorTipoResponse!: ContadorComprobantePorTipoResponse;
  contadorComprobantePorTipoYEstadoResponse!: ContadorComprobantePorTipoYEstadoResponse;

  //#region  ImportePeriodosContrato

  barChartOptionsImportePeriodosContrato: ChartOptions = {
    responsive: true,
    legend: {
      fullWidth: true,
      position: 'left'
    },
    animation: {
      duration: 1000,
      easing: 'linear',
    },
    // title: {
    //   display: true,
    //   text: "Total Importes de Periodos de Contratos",
    //   fontSize:16,
    //   fontColor:"#009788",
    //   position: "top"
    // }
  };

  barChartLabelsImportePeriodosContrato: Label[] = [];
  barChartTypeImportePeriodosContrato: ChartType = 'horizontalBar';
  barChartPluginsImportePeriodosContrato = [];
  barChartDataImportePeriodosContrato: SingleDataSet = [];

  //#endregion

  //#region  CantidadPeriodosContrato

  barChartOptionsCantidadPeriodosContrato: ChartOptions = {
    responsive: true,
    legend: {
      fullWidth: true,
      position: 'left'
    },
    animation: {
      duration: 1000,
      easing: 'linear',
    },
    // title: {
    //   display: true,
    //   text: "Total Cantidad de Periodos de Contratos",
    //   fontSize:16,
    //   fontColor:"#009788",
    //   position: "top"
    // }
  };

  barChartLabelsCantidadPeriodosContrato: Label[] = [];
  barChartTypeCantidadPeriodosContrato: ChartType = 'pie';
  barChartPluginsCantidadPeriodosContrato = [];
  barChartDataCantidadPeriodosContrato: SingleDataSet = [];
  lineChartColorsCantidadPeriodosContrato: Color[] = [
    {
      borderColor: 'blue',
      backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];
  //#endregion

  //#region  ImporteMovimientos

  barChartOptionsImporteMovimientos: ChartOptions = {
    responsive: true,
    legend: {
      fullWidth: true,
      position: 'left'
    },
    animation: {
      duration: 1000,
      easing: 'linear',
    },
    // title: {
    //   display: true,
    //   text: "Total Importes de Movimientos",
    //   fontSize:16,
    //   fontColor:"#009788",
    //   position: "top"
    // }
  };

  barChartLabelsImporteMovimientos: Label[] = [];
  barChartTypeImporteMovimientos: ChartType = 'horizontalBar';
  barChartPluginsImporteMovimientos = [];
  barChartDataImporteMovimientos: SingleDataSet = [];

  //#endregion

  //#region  CantidadMovimientos
  barChartOptionsCantidadMovimientos: ChartOptions = {
    responsive: true,
    legend: {
      fullWidth: true,
      position: 'left'
    },
    animation: {
      duration: 1000,
      easing: 'linear',
    },
    // title: {
    //   display: true,
    //   text: "Total Cantidad de Movimientos",
    //   fontSize:16,
    //   fontColor:"#009788",
    //   position: "top"
    // }
  };

  barChartLabelsCantidadMovimientos: Label[] = [];
  barChartTypeCantidadMovimientos: ChartType = 'pie';
  barChartPluginsCantidadMovimientos = [];
  barChartDataCantidadMovimientos: SingleDataSet = [];
  lineChartColorsCantidadMovimientos: Color[] = [
    {
      borderColor: 'blue',
      backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];
  //#endregion
  constructor(
    private cajaService: CajaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private datepipe: DatePipe
  ) {

    this.form = this.formBuilder.group({
      FechaDesde: [new Date((new Date().getTime() - RangoFechasValores.Bimestral)), Validators.compose([Validators.required,])],
      FechaHasta: [new Date(new Date().getTime()), Validators.compose([Validators.required,])],
    })
  }

  ngOnInit(): void {
    let fechaDesde = this.datepipe.transform(new Date().getTime() - RangoFechasValores.Bimestral, 'yyyy-MM-dd') as string;
    let fechaHasta = this.datepipe.transform(new Date().getTime() + RangoFechasValores.Bimestral, 'yyyy-MM-dd') as string;
  }

  filtrarDetallesServiciosPropiedad() {
    let fechaDesde = this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;
    let fechaHasta = this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;
    // Ajustar la hora para la fecha desde
    fechaDesde = fechaDesde + ' 00:00:00';
    // Ajustar la hora para la fecha hasta
    fechaHasta = fechaHasta + ' 23:59:59';

    this.getContadorPeriodosContrato(fechaDesde, fechaHasta)
    this.getContadorMovimientos(fechaDesde, fechaHasta)
    this.getContadoMovimientosPorTipo(fechaDesde, fechaHasta)
    this.getContadorComprobantePorEstado(fechaDesde, fechaHasta)
    this.getContadorComprobantePorTipo(fechaDesde, fechaHasta)
    this.getContadorComprobantePorTipoYEstado(fechaDesde, fechaHasta)
  }


  private getContadorPeriodosContrato(fechaDesde: string, fechaHasta: string) {
    this.spinner.show("spGetContadorPeriodosContrato")
    this.cajaService.getContadorPeriodosContrato(fechaDesde, fechaHasta).subscribe(
      data => {
        data.detalleReporte.forEach(element => {
          this.barChartLabelsImportePeriodosContrato.push(element.descripcion)
          this.barChartDataImportePeriodosContrato.push(element.importeTotal)
          this.barChartLabelsCantidadPeriodosContrato.push(element.descripcion)
          this.barChartDataCantidadPeriodosContrato.push(element.cantidad)
        });
        this.spinner.hide("spGetContadorPeriodosContrato")
        this.contadorPeriodoContratoResponse = data
      },
      error => {
        this.spinner.hide("spGetContadorPeriodosContrato")
        this.snackBar.showError(error, "Error");
      })
  }

  private getContadorMovimientos(fechaDesde: string, fechaHasta: string) {
    this.spinner.show("spGetContadorMovimientos")
    this.cajaService.getContadorMovimientos(fechaDesde, fechaHasta).subscribe(
      data => {
        this.spinner.hide("spGetContadorMovimientos")
        this.contadorMovimientoResponse = data
        data.detalleReporte.forEach(element => {
          this.barChartLabelsImporteMovimientos.push(element.descripcion)
          this.barChartDataImporteMovimientos.push(element.importeTotal)
          this.barChartLabelsCantidadMovimientos.push(element.descripcion)
          this.barChartDataCantidadMovimientos.push(element.cantidad)
        });
      },
      error => {
        this.spinner.hide("spGetContadorMovimientos")
        this.snackBar.showError(error, "Error");
      })
  }

  private getContadoMovimientosPorTipo(fechaDesde: string, fechaHasta: string) {
    this.spinner.show("spGetContadoMovimientosPorTipo")
    this.cajaService.getContadoMovimientosPorTipo(fechaDesde, fechaHasta).subscribe(
      data => {
        this.spinner.hide("spGetContadoMovimientosPorTipo")
        this.contadorMovimientoPorTipoResponse = data
      },
      error => {
        this.spinner.hide("spGetContadoMovimientosPorTipo")
        this.snackBar.showError(error, "Error");
      })
  }

  private getContadorComprobantePorEstado(fechaDesde: string, fechaHasta: string) {
    this.spinner.show("spGetContadorComprobantePorEstado")
    this.cajaService.getContadorComprobantePorEstado(fechaDesde, fechaHasta).subscribe(
      data => {
        this.spinner.hide("spGetContadorComprobantePorEstado")
        this.contadorComprobantePorEstadoResponse = data
      },
      error => {
        this.spinner.hide("spGetContadorComprobantePorEstado")
        this.snackBar.showError(error, "Error");
      })
  }

  private getContadorComprobantePorTipo(fechaDesde: string, fechaHasta: string) {
    this.spinner.show("spGetContadorComprobantePorTipo")
    this.cajaService.getContadorComprobantePorTipo(fechaDesde, fechaHasta).subscribe(
      data => {
        this.spinner.hide("spGetContadorComprobantePorTipo")
        this.contadorComprobantePorTipoResponse = data
      },
      error => {
        this.spinner.hide("spGetContadorComprobantePorTipo")
        this.snackBar.showError(error, "Error");
      })
  }

  private getContadorComprobantePorTipoYEstado(fechaDesde: string, fechaHasta: string) {
    this.spinner.show("spGetContadorComprobantePorTipoYEstado")
    this.cajaService.getContadorComprobantePorTipoYEstado(fechaDesde, fechaHasta).subscribe(
      data => {
        this.spinner.hide("spGetContadorComprobantePorTipoYEstado")
        this.contadorComprobantePorTipoYEstadoResponse = data
      },
      error => {
        this.spinner.hide("spGetContadorComprobantePorTipoYEstado")
        this.snackBar.showError(error, "Error");
      })
  }

  trackByItems(index: number, item: any): any { return item; }

}
