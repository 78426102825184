<mat-tab-group>

    <mat-tab label="Recibos" *appRole="['Comprobantes.Ver']">
        <br>
        <app-listado-comprobantes-recibos></app-listado-comprobantes-recibos>
    </mat-tab>
    <mat-tab label="Liquidaciones" *appRole="['Comprobantes.Ver']">
        <br>
        <app-listado-comprobantes-liquidaciones></app-listado-comprobantes-liquidaciones>
    </mat-tab>
    <mat-tab label="Caja" *appRole="['ComprobantesCaja.Ver']">
        <br>
        <app-listado-comprobantes-genericos></app-listado-comprobantes-genericos>
    </mat-tab>
    <mat-tab label="Acreditaciones" *appRole="['Acreditaciones.Ver']">
        <br>
        <app-listado-acreditaciones></app-listado-acreditaciones>
    </mat-tab>
</mat-tab-group>
