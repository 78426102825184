import { HttpClient } from '@angular/common/http';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreatePropietarioRequest } from '@models/propietarios/createPropietarioRequest.model';
import { CreatePropietarioResponse } from '@models/propietarios/createPropietarioResponse.model';
import { CreateSettlementResponse } from '@models/propietarios/createSettlementResponse.model';
import { PersonaPropietarioDto } from '@models/propietarios/personaPropietarioDto.model';
import { PropietarioDto } from '@models/propietarios/propietarioDto.model';
import { SettlementResponse } from '@models/propietarios/settlementResponse.model';
import { UpdatePropietarioRequest } from '@models/propietarios/updatePropietarioRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class PropietariosService {

  propietario!: PropietarioDto
  private enviarPropietarioSubject = new Subject<PropietarioDto>()
  enviarPropietarioObservable = this.enviarPropietarioSubject.asObservable()

  private id!: number;

  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http: HttpClient) { }

  enviarPropietario(propietario: PropietarioDto) {
    this.propietario = propietario
    this.enviarPropietarioSubject.next(propietario)
  }

  setPropietario(propietario: PropietarioDto) {
    this.propietario = propietario
  }

  getPropietario(): PropietarioDto {
    return this.propietario
  }

  getAllTiposLiquidacion(): Observable<BaseDto[]> {
    return this.http.get<BaseDto[]>("/api/TipoLiquidacion/GetAll");
  }

  getAll(): Observable<PropietarioDto[]> {
    return this.http.get<PropietarioDto[]>("/api/Propietarios/GetAll");
  }

  getByIdPersona(id: number): Observable<PropietarioDto> {
    return this.http.get<PropietarioDto>("api/Propietarios/GetByIdPersona?idPersona=" + id)
  }

  getById(id: number): Observable<PropietarioDto> {
    return this.http.get<PropietarioDto>("api/Propietarios/GetById?id=" + id)
  }

  create(createPropietarioRequest: CreatePropietarioRequest): Observable<CreatePropietarioResponse> {
    return this.http.post<CreatePropietarioResponse>("/api/Propietarios/Create", createPropietarioRequest);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Propietarios/Delete?id=" + id);
    else
      return of(null)
  }

  update(updatePropietarioRequest: UpdatePropietarioRequest) {
    return this.http.put("/api/Propietarios/Update", updatePropietarioRequest);
  }

  reactivate(idPropietario: number) {
    return this.http.put("/api/Propietarios/Reactivate?idPropietario=" + idPropietario,null);
  }

  getAllPersonasPropietariosByIdPropiedad(idPropietario: number): Observable<PersonaPropietarioDto> {
    return this.http.get<PersonaPropietarioDto>("/api/Propietarios/GetPersonaPropietarioByIdPropiedad?IdPropiedad=" + idPropietario);
  }

  getSettlement(settlementDate: string, idsPropietarios: number[],idContrato: number,): Observable<SettlementResponse> {
    let lstIdsPropietarios = ""
    idsPropietarios.forEach(element => {
      lstIdsPropietarios += "&idsPropietarios=" + element
    });
    if (!isFalsy(idContrato) && idsPropietarios.length == 0) {
      return this.http.get<SettlementResponse>("/api/Propietarios/GetSettlement?settlementDate=" + settlementDate + "&idContrato="+idContrato);
    }
    else{
      return this.http.get<SettlementResponse>("/api/Propietarios/GetSettlement?settlementDate=" + settlementDate + lstIdsPropietarios);
    }

  }

  createSettlement(idsPropietario: Number[], idUsuario: number, idContrato: number, agruparPorContrato: boolean): Observable<CreateSettlementResponse> {
    let idsPropietarios: string = ""
    idsPropietario.forEach(element => {
      idsPropietarios += "&IdsPropietario=" + element
    });
    if (!isFalsy(idContrato) && idsPropietarios.length == 0) {
      return this.http.post<CreateSettlementResponse>("/api/Propietarios/CreateSettlement?IdEmpresa=1&IdUsuario=" + idUsuario + "&IdContrato=" + idContrato + "&AgruparPorContrato="+agruparPorContrato, {})
    }
    else {
      return this.http.post<CreateSettlementResponse>("/api/Propietarios/CreateSettlement?IdEmpresa=1&IdUsuario=" + idUsuario + "&AgruparPorContrato="+agruparPorContrato + idsPropietarios , {})
    }

  }



}
