<br>
<div class="container-fluid" *appRole="['Dashboard.Caja']">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Filtro
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <ngx-spinner name="spBusqueda" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
                    <form [formGroup]="form">
                        <mat-form-field class="full-width">
                            <mat-label>Ingrese rango de fechas</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate [disabled]="true" formControlName="FechaDesde" placeholder="Fecha desde">
                                <input matEndDate [disabled]="true" formControlName="FechaHasta" placeholder="Fecha hasta">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                        </mat-form-field>&nbsp;&nbsp;
                        <button mat-stroked-button color="primary" (click)="filtrarDetallesServiciosPropiedad()">
<mat-icon>filter_alt</mat-icon>
Filtrar</button>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <ngx-spinner name="spGetContadorPeriodosContrato" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Total cantidad de periodos de ontratos</mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="barChartDataCantidadPeriodosContrato.length >= 1">
                    <canvas baseChart width="400" height="100" [data]="barChartDataCantidadPeriodosContrato" [labels]="barChartLabelsCantidadPeriodosContrato" [options]="barChartOptionsCantidadPeriodosContrato" [plugins]="barChartPluginsCantidadPeriodosContrato" [chartType]="barChartTypeCantidadPeriodosContrato">
          </canvas>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <ngx-spinner name="spGetContadorPeriodosContrato" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Total importes de periodos de contratos</mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="barChartDataImportePeriodosContrato.length >= 1">
                    <canvas width="400" height="100" baseChart [data]="barChartDataImportePeriodosContrato" [labels]="barChartLabelsImportePeriodosContrato" [options]="barChartOptionsImportePeriodosContrato" [plugins]="barChartPluginsImportePeriodosContrato" [chartType]="barChartTypeImportePeriodosContrato">
              </canvas>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <ngx-spinner name="spGetContadorMovimientos" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Total cantidad de movimientos</mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="barChartDataCantidadMovimientos.length >= 1">
                    <canvas baseChart width="400" height="100" [data]="barChartDataCantidadMovimientos" [labels]="barChartLabelsCantidadMovimientos" [options]="barChartOptionsCantidadMovimientos" [plugins]="barChartPluginsCantidadMovimientos" [chartType]="barChartTypeCantidadMovimientos">
        </canvas>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <ngx-spinner name="spGetContadorMovimientos" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Total importe de movimientos</mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="barChartDataImporteMovimientos.length >= 1">
                    <canvas width="400" height="100" baseChart [data]="barChartDataImporteMovimientos" [labels]="barChartLabelsImporteMovimientos" [options]="barChartOptionsImporteMovimientos" [plugins]="barChartPluginsImporteMovimientos" [chartType]="barChartTypeImporteMovimientos">
            </canvas>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <ngx-spinner name="spGetContadorComprobantePorEstado" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Comprobantes por estado</mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="contadorComprobantePorEstadoResponse != undefined">
                    <mat-list>
                        <!-- <div mat-subheader>Movimientos por tipos</div> -->
                        <mat-list-item *ngFor="let item of contadorComprobantePorEstadoResponse.comprobantesPorEstado;let i=index;trackBy:trackByItems">
                            <mat-icon color="primary" mat-list-icon>request_quote </mat-icon>
                            <div mat-line class="titulo-mat-list" [matTooltip]="item.estado">{{item.estado}}</div>
                            <div class="detalleReporte-mat-list">
                                <div *ngFor="let detalleReporte of item.detalleReporte" mat-line>
                                    <div class="subtitulo-mat-list">{{detalleReporte.descripcion}} <span class="descripcion-mat-list">Cantidad: {{detalleReporte.cantidad}} Total: $ {{detalleReporte.importeTotal}}</span></div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <ngx-spinner name="spGetContadorComprobantePorTipo" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Comprobantes por tipo</mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="contadorComprobantePorTipoResponse != undefined">
                    <mat-list>
                        <mat-list-item *ngFor="let item of contadorComprobantePorTipoResponse.comprobantesPorTipo;let i=index;trackBy:trackByItems">
                            <mat-icon color="primary" mat-list-icon>request_quote </mat-icon>
                            <div mat-line class="titulo-mat-list" [matTooltip]="item.tipoComprobante">{{item.tipoComprobante}}</div>
                            <div class="detalleReporte-mat-list">
                                <div *ngFor="let detalleReporte of item.detalleReporte" mat-line>
                                    <div class="subtitulo-mat-list">{{detalleReporte.descripcion}} <span class="descripcion-mat-list">Cantidad: {{detalleReporte.cantidad}} Total: $ {{detalleReporte.importeTotal}}</span></div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <ngx-spinner name="spGetContadorComprobantePorTipoYEstado" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Comprobantes por tipo y estado</mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="contadorComprobantePorTipoYEstadoResponse != undefined">
                    <mat-list>
                        <mat-list-item *ngFor="let item of contadorComprobantePorTipoYEstadoResponse.comprobantesPorTipoYEstadoDto;let i=index;trackBy:trackByItems">
                            <mat-icon color="primary" mat-list-icon>request_quote </mat-icon>
                            <div mat-line class="titulo-mat-list" [matTooltip]="item.tipoComprobante + ' - '+ item.estado">{{item.tipoComprobante}} - {{item.estado}}</div>
                            <div class="detalleReporte-mat-list">
                                <div *ngFor="let detalleReporte of item.detalleReporte;let i=index;trackBy:trackByItems" mat-line>
                                    <div class="subtitulo-mat-list">{{detalleReporte.descripcion}} <span class="descripcion-mat-list">Cantidad: {{detalleReporte.cantidad}} Total: $ {{detalleReporte.importeTotal}}</span></div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
            <ngx-spinner name="spGetContadoMovimientosPorTipo" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Movimientos por tipo</mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="contadorMovimientoPorTipoResponse != undefined">
                    <mat-list>
                        <mat-list-item *ngFor="let item of contadorMovimientoPorTipoResponse.movimientosPorTipo;let i=index;trackBy:trackByItems">
                            <mat-icon color="primary" mat-list-icon>request_quote </mat-icon>
                            <div mat-line class="titulo-mat-list" [matTooltip]="item.tipoMovimiento">{{item.tipoMovimiento}} </div>
                            <div class="detalleReporte-mat-list">
                                <div *ngFor="let detalleReporte of item.detalleReporte;let i=index;trackBy:trackByItems" mat-line>
                                    <div class="subtitulo-mat-list">{{detalleReporte.descripcion}} <span class="descripcion-mat-list">Cantidad: {{detalleReporte.cantidad}} Total: $ {{detalleReporte.importeTotal}}</span></div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
