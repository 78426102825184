import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-test',
  templateUrl: './app-test.component.html',
  styleUrls: ['./app-test.component.css']
})
export class AppTestComponent implements OnInit {

  items2 = ["Rosario", "Santa Fe"]
  items = [
    {
      id: 1,
      descripcion: "Perros"
    },
    {
      id: 2,
      descripcion: "Gatos"
    },
    {
      id: 3,
      descripcion: "Leones"
    }
  ]
  constructor() {

  }

  ngOnInit(): void {

  }

  agregar() {
    this.items.push({ id: 1, descripcion: "Perros" })
  }

  actualizar() {
    this.items.forEach(element => {
      if (element.id == 1)
        element.descripcion = "Jirafa"
    });
  }

  eliminar() {
    this.items.forEach((element, index) => {
      if (element.id == 1)
        this.items.splice(index, 1);
    });
  }


  trackByItems(index: number, item: any): any { return item; }

}
