<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <form [formGroup]="form">
        <br>
        <div class="row">
            <div class="col-4">
                <app-buscar-cuenta-contable [cuentaContableEntrada]="cuentaContableBaseDto" (eventEntity)="selectCuentaContable($event)"></app-buscar-cuenta-contable>
            </div>
            <div class="col-2">
                <button mat-icon-button (click)="openDialog('Agregar',cuentaContableDto)" matTooltip="Agregar cuenta contable" color="primary">
              <mat-icon>add</mat-icon>
          </button></div>
            <div class="col-2">
                <p>
                    <mat-checkbox formControlName="Activo" matTooltip="Es la Cuenta Contable por defecto">CC por Defecto</mat-checkbox>
                </p>
            </div>
        </div>
        <div *ngIf="data.persona!= null || undefined">
            <div class="row">
                <div class="col-6">
                    <p><strong>Nombre: </strong>{{data.persona.nombreCompleto}}</p>
                </div>
                <div class="col-6">
                    <p><strong>Nro. Documento: </strong>{{data.persona.nroDocumento}}</p>
                </div>
            </div>
        </div>

        <ng-template *ngIf="cuentaContableDto != null || undefined">
            <mat-card *ngIf="cuentaContableDto.id != 0">
                <div class="row">
                    <div class="col-6">
                        <p><strong>Numero: </strong>{{cuentaContableDto.numero}}</p>
                    </div>
                    <div class="col-6">
                        <p><strong>Banco: </strong>{{isFalsy(cuentaContableDto.banco) ? "": cuentaContableDto.banco.descripcion}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <p><strong>Alias: </strong>{{cuentaContableDto.alias}}</p>
                    </div>
                    <div class="col-6">
                        <p><strong>CBU: </strong>{{cuentaContableDto.cbu}}</p>
                    </div>
                </div>
            </mat-card>
        </ng-template>

        <div class="row justify-content-center ">
            <div class="col-12">
                <mat-form-field class="full-width-x100">
                    <mat-label>Descripcion</mat-label>
                    <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
                    <mat-error *ngIf="f.Descripcion.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                    <mat-hint align="start"><strong>Amplie información</strong> </mat-hint>
                    <mat-hint align="end">{{message.value.length}} / 200</mat-hint>
                    <mat-error *ngIf="f.Descripcion.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<br>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" *appRole="['CuentasContable.Crear','CuentasContable.Modificar','CuentasContable.Eliminar']" (click)="doAction()" [disabled]="!form.valid || isFalsy(cuentaContableDto)">{{action}}</button>
    <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
</div>
