import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateServicioPropiedadRequest } from '@models/servicios/servicio-propiedad/createServicioPropiedadRequest.model';
import { ServicioPropiedadDto } from '@models/servicios/servicio-propiedad/servicioPropiedadDto.model';
import { UpdateServicioPropiedadRequest } from '@models/servicios/servicio-propiedad/updateServicioPropiedadRequest.model';
import { isFalsy } from 'utility-types';
import { MessageSuccessfullDto } from '@models/base/MessageSuccessfullDto.model';

@Injectable({
  providedIn: 'root'
})
export class ServiciosPropiedadService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http:HttpClient
  ) { }


/**
 * Crea un ServiciosPropiedad para una Propiedad
 * @param createServicioPropiedadRequest
 * @returns
 */
  createServicioPropiedad(createServicioPropiedadRequest:CreateServicioPropiedadRequest):Observable<MessageSuccessfullDto>
  {
    return  this.http.post<MessageSuccessfullDto>("/api/Servicio/CreateServicioPropiedad",createServicioPropiedadRequest);
  }

  /**
   * Actualiza un ServiciosPropiedad  a partir del Id del ServicioPropiedad
   * @param updateServicioPropiedadRequest
   * @returns
   */
  updateServicioPropiedad(updateServicioPropiedadRequest:UpdateServicioPropiedadRequest):Observable<MessageSuccessfullDto>
  {
    return  this.http.put<MessageSuccessfullDto>("/api/Servicio/UpdateServicioPropiedad",updateServicioPropiedadRequest);
  }
/**
 * Obtener todos los ServiciosPropiedad de una Propiedad a partir del Id de una Propiedad
 * @param idPropiedad
 * @returns
 */
  getServiciosPropiedadByIdPropiedad(idPropiedad:number):Observable<ServicioPropiedadDto[]>
  {
    return  this.http.get<ServicioPropiedadDto[]>("/api/Servicio/GetServiciosPropiedadByIdPropiedad?idPropiedad="+ idPropiedad);
  }

  /**
   * Obtener ServiciosPropiedad de una Propiedad a partir del Id de ServicioPropiedad
   * @param id
   * @returns
   */
  getServicioPropiedadById(id:number):Observable<ServicioPropiedadDto>
  {
    return  this.http.get<ServicioPropiedadDto>("/api/Servicio/GetServicioPropiedadById?id="+id);
  }

/**
 * Eliminar un ServiciosPropiedad de una Propiedad
 * @param id
 * @returns
 */
  deleteServicioPropiedad(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Servicio/DeleteServicioPropiedad?id=" + id);
    else
      return of(null)
  }


}
