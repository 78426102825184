<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<h1 mat-dialog-title class="mat-dialog-title-icon">
    Notificaciones</h1>
<div mat-dialog-content>
    <div class="alert alert-warning alert-dismissible" *ngFor="let item of notificaciones;let i=index;trackBy:trackByItems">
        <a href="#" class="close" (click)="eliminarNotificacion(item)" data-dismiss="alert" aria-label="close">&times;</a> {{item.mensaje}} &nbsp;&nbsp;<strong><span style="font-size:larger;">{{item.cantidad}}</span></strong>
    </div>
    <div class="no-data-table">
        <span class="with-icon" *ngIf="notificaciones.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
    </div>
</div>
