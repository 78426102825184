<h1>Animales  </h1>
<br>
<div *ngFor="let item of items">
  <h5>{{ item.descripcion }}</h5>
</div>
<div class="position-relative">
  <button (click)="agregar()">Agregar</button>
  <button (click)="eliminar()">Eliminar</button>
  <button (click)="actualizar()">Actualizar</button>
</div>
