import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserValidator } from 'src/app/helper/validations/user.validator';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { ForgotPasswordRequest } from '@models/usuarios/forgotPasswordRequest.model';
import { LoginService } from '@services/login/login.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { UsuariosService } from '@services/usuarios/usuarios.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recoverPassword.component.html',
  styleUrls: ['./recoverPassword.component.css']
})
export class RecoverPasswordComponent implements OnInit {
  isLinear = true;
  isCompleted = false;
  isEditable = true;
  FormGroup!: FormGroup;
  isLoading: boolean =  false;
  get p() { return this.FormGroup.controls; }
  mensaje: boolean = false;
  mensajeDescripcion: string = "";
  usuario: UsuarioDto = new UsuarioDto;



  constructor(
    private loginService: LoginService,
    private router: Router,
    private userService: UsuariosService,
    private _formBuilder: FormBuilder,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit(): void {
    this.FormGroup = this._formBuilder.group({
      Usuario: ['', Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
    }, {
      //validator: UserValidator("Usuario")
    });
    this.mensaje = false;
  }

  goToLogin() {
    this.router.navigate(["/login"])
  }

  resetForm() {
    this.FormGroup.reset()
    this.mensaje = false;
  }

  recoverPassword() {
    this.isLoading = true
    let userName = this.FormGroup.controls["Usuario"].value
    if ( !isFalsy(userName)) {
      let forgotPasswordRequest = new ForgotPasswordRequest
      forgotPasswordRequest.userName = userName
      this.userService.recoverPassword(forgotPasswordRequest).subscribe(
        data => {
          this.isLoading = false
          this.mensaje = true;
          this.mensajeDescripcion = data.text
        },
        (error) => {
          this.snackBar.showWarn("Usuario incorrecto.", "Error")
          this.isLoading = false
        }
      )
    }
    else {
      this.mensaje = false;
      this.snackBar.showWarn("Ingrese usuario.", "")
    }

  }

  protect_email(user_email: string) {
    var avg, splitted, part1, part2;
    splitted = user_email.split("@");
    part1 = splitted[0];
    avg = part1.length / 2;
    part1 = part1.substring(0, (part1.length - avg));
    part2 = splitted[1];
    return part1 + "...@" + part2;
  };

}
