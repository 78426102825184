<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
<h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <form [formGroup]="form" *ngIf="action != 'Eliminar'; else elseTemplate">
        <div class="row">
            <div class="col-lg-3">
                <mat-form-field >
                    <input cdkFocusInitial placeholder="{{action}} Fecha" type="date" formControlName="Fecha" matInput>
                    <mat-error *ngIf="f.Fecha.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-3">
                <mat-form-field >
                    <input placeholder="{{action}} Valor" formControlName="Valor" matInput>
                    <mat-error *ngIf="f.Valor.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-4">

              <mat-form-field class="example-full-width">
                <ngx-spinner name="spBusquedaTipoAjusteContrato2" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                <mat-label>Tipos de Ajustes Contrato</mat-label>
                <mat-select placeholder="Seleccione opcion" formControlName="TiposAjusteContrato">
                  <mat-option [value]="tipoAjusteContrato.id" *ngFor="let tipoAjusteContrato of lstTiposAjusteContrato;let i=index;trackBy:trackByItems">
                      {{ tipoAjusteContrato.descripcion | titlecase }}({{ tipoAjusteContrato.acronimo | uppercase }})</mat-option>
              </mat-select>
              <mat-error *ngIf="f.TiposAjusteContrato.errors?.required">Este campo es <strong>requerido</strong>.
              </mat-error>
              </mat-form-field>
            </div>
        </div>
    </form>

    <ng-template #elseTemplate>
        ¿Esta seguro que desea eliminar el registro <b>{{local_data.fecha | date:"dd/MM/yyyy"}} - {{local_data.valor}}</b>?
    </ng-template>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" *appRole="['IndiceAjusteContrato.Crear','IndiceAjusteContrato.Modificar','IndiceAjusteContrato.Eliminar']"  (click)="doAction()" [disabled]="!form.valid">{{action}}</button>
    <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
</div>
