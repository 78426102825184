import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateKeyValueRequest } from '@models/base/createKeyValueRequest.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { UpdateKeyValueRequest } from '@models/base/updateKeyValueRequest.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class EstadosPagoService {

  constructor(
    private http: HttpClient
  ) { }

  create(createKeyValueRequest:CreateKeyValueRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/EstadoPago/Create",createKeyValueRequest);
  }

  update(updateKeyValueRequest:UpdateKeyValueRequest):Observable<any>
  {
    return  this.http.put<any>("/api/EstadoPago/Update",updateKeyValueRequest);
  }

  getAll():Observable<BaseDto[]>
  {
    return  this.http.get<BaseDto[]>("/api/EstadoPago/GetAll");
  }

  getById(id:number):Observable<BaseDto>
  {
    return  this.http.get<BaseDto>("/api/EstadoPago/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/EstadoPago/Delete?id=" + id);
    else
      return of(null)
  }
}
