<form [formGroup]="formServicioPriopidad" *appRole="['Servicios.Ver']">
  <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
  </ngx-spinner>

  <div class="row">
    <div class="form-group col-md-3">
      <app-buscar-servicios (eventEntity)="selectServicio($event)"></app-buscar-servicios>
    </div>

    <div class="form-group col-md-3">
      <mat-form-field class="full-width">
        <mat-label>Nro. Cuenta</mat-label>
        <input matInput name="NroCuenta" type="text" maxlength="50" formControlName="NroCuenta" />
        <mat-error *ngIf="f.NroCuenta.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
        <mat-error *ngIf="f.NroCuenta.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
      </mat-form-field>
    </div>

    <div class="form-group col-md-3">
      <mat-form-field class="full-width">
        <mat-label>Nro. gestion personal</mat-label>
        <input matInput name="NroGestionPersonal" type="text" maxlength="50" formControlName="NroGestionPersonal" />
        <mat-error *ngIf="f.NroGestionPersonal.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
      </mat-form-field>
    </div>

    <div class="form-group col-md-3">
      <mat-form-field class="full-width">
        <mat-label>Referencia Pago</mat-label>
        <input matInput name="NroGestionPersonal" type="text" maxlength="50" formControlName="ReferenciaPago" />
        <mat-error *ngIf="f.ReferenciaPago.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
      </mat-form-field>
    </div>

  </div>
  <br>
</form>
<div class="col-auto my-1">
  <button mat-raised-button *appRole="['Servicios.Crear']" color="primary" style="margin-right:3px;" name="Guardar"
    [disabled]="!formServicioPriopidad.valid" (click)="create()">Agregar</button>
  <button mat-button color="warn" (click)="cancel()" style="margin-right:3px;" name="Cancelar">Cancelar</button>
</div>
<br>
<br>
<app-instructivos-servicios></app-instructivos-servicios>
