export class CreateCuentaContableRequest {
  public descripcion?: string
  public numero?: string | null
  public tipo?: string  | null
  public idBanco?: number  | null
  public cbu?: string  | null
  public alias?: string  | null
  public fechaBaja?: string | null

}
