import { ArchivoDto } from "../archivo/archivoDto.model";

export class UpdateDocumentoRequest {
    public id_File:number = 0
    public fileContent: string = "";
    public fileType: string = "";
    public fileName: string = "";
    public id_Documento :number = 0
    public idTipoEntidad?: number;
    public idEntidad?: number;
    public idAsunto?: number;
    public etiquetas: string = "";
    public nombre: string = "";
    public descripcion: string = "";
    public archivo: ArchivoDto = new ArchivoDto
}