import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { AssignPropiedadPropietarioRequest } from '@models/propiedades/assignPropiedadPropietarioRequest.model';
import { AssingPropietarioTitularRequest } from '@models/propiedades/assingPropietarioTitularRequest.model';
import { CreatePropiedadRequest } from '@models/propiedades/createPropiedadRequest.model';
import { PropiedadDto } from '@models/propiedades/propiedadDto.model';
import { RemoveAssignmentPropiedadPropietarioRequest } from '@models/propiedades/removeAssignmentPropiedadPropietarioRequest.model';
import { TipoPropiedadDto } from '@models/propiedades/tipoPropiedadDto.model';
import { UpdatePropiedadRequest } from '@models/propiedades/updatePropiedadRequest.model';
import { PropietarioDto } from '@models/propietarios/propietarioDto.model';
import { isFalsy } from 'utility-types';
import { MessageSuccessfullDto } from '@models/base/MessageSuccessfullDto.model';
import { PropiedadPropietarioDto } from '@models/propiedad-propietario/propiedadPropietarioDto.model';
import { CreateKeyValueRequest } from '@models/base/createKeyValueRequest.model';
import { UpdateKeyValueRequest } from '@models/base/updateKeyValueRequest.model';

@Injectable({
  providedIn: 'root'
})
export class PropiedadClaseService {

  constructor(
    private http: HttpClient
  ) { }

  getById(id: number): Observable<BaseDto> {
    return this.http.get<BaseDto>("/api/PropiedadClase/GetById?id=" + id)
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/PropiedadClase/Delete?id=" + id);
    else
      return of(null)
  }

  create(createKeyValueRequest: CreateKeyValueRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/PropiedadClase/Create", createKeyValueRequest);
  }

  update( updateKeyValueRequest: UpdateKeyValueRequest): Observable<any> {
    return this.http.put<any>("/api/PropiedadClase/Update", updateKeyValueRequest);
  }

  getAll(): Observable<BaseDto[]> {
    return this.http.get<BaseDto[]>("/api/PropiedadClase/GetAll");
  }

}
