import { BaseDto } from "../base/baseDto.model";

export class TipoDocumentoDto extends BaseDto {

  static fromTipoDocumentoDto(tipoDocumentoDto: TipoDocumentoDto) {
    const dto = new TipoDocumentoDto();
    dto.id = tipoDocumentoDto.id;
    dto.descripcion = tipoDocumentoDto.descripcion;
    return dto;
  }
}
