<div class="position-relative">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title>Generar resumen inquilino</h1>
  <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
      <ngx-spinner [zIndex]=100 name="spResumen" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
      </ngx-spinner>
      <form [formGroup]="formGenerarResumen">
          <mat-form-field class="example-full-width">
              <mat-label>Fecha</mat-label>
              <input matInput name="FechaResumen" [matDatepicker]="dp" formControlName="FechaResumen">
              <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
              <mat-datepicker #dp></mat-datepicker>
          </mat-form-field>

          <mat-checkbox (change)="checkEnvioMail($event.checked)" formControlName="EnvioMail">Enviar email</mat-checkbox>
          <mat-checkbox formControlName="IncluyePeriodoPosterior">Incluye periodo posterior</mat-checkbox>

          <div class="row">
              <div class="col-lg-12">
                  <ngx-spinner [zIndex]="1" name="spBusquedaCorreo" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                  <mat-form-field class="full-width-x1" *ngIf="showCorreo">
                      <mat-label>Correo inquilino</mat-label>
                      <input matInput name="CorreoInquilino" type="text" formControlName="CorreoInquilino" />
                  </mat-form-field>
              </div>
          </div>
      </form>

  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-actions>
      <button mat-raised-button *appRole="['Contratos.GenerarResumen']"  (click)="generarResumen()" color="primary">
        <mat-icon>picture_as_pdf</mat-icon> Generar resumen</button>
      <button mat-button (click)="closeDialog()" mat-dialog-close color="warn">Cancelar</button>
  </div>

</div>
