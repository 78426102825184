import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UtilidadesController } from 'src/app/controllers/utilidades.controller';
import { ParametrosConfiguracion } from '@models/base/identificadores.model';
import { ParametroSistemaDto } from '@models/parametros-sistema/parametroSistemaDto.model';
import { ParametroSistemaValues } from '@models/parametros-sistema/parametrosSistemaValues.model';
import { UpdateParametroSistemaRequest } from '@models/parametros-sistema/updateParametroSistemaRequest.model';
import { isFalsy } from 'utility-types';
import { SnackBarService } from '../snackBar/snack-bar-service.service';

@Injectable({
  providedIn: 'root'
})
export class ParametrosSistemaService {
  private id!: number;

  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient) {

  }
  getValuesByName(names: string[]): Observable<ParametroSistemaDto[]> {
    let cadena = ''
    names.forEach(element => {
      cadena = '&names=' + element
    });
    return this.http.get<ParametroSistemaDto[]>("/api/ParametrosSistema/GetValuesByNames?" + cadena)
  }

  getValueByName(names: string): Observable<ParametroSistemaDto> {
    return this.http.get<ParametroSistemaDto>("/api/ParametrosSistema/GetValueByName?names=" + names)
  }

  update(updateParametroSistemaRequest: UpdateParametroSistemaRequest): Observable<any> {
    return this.http.put<any>("/api/ParametrosSistema/Update", updateParametroSistemaRequest);
  }

  getAll(): Observable<ParametroSistemaDto[]> {
    return this.http.get<ParametroSistemaDto[]>("/api/ParametrosSistema/GetAll")
  }

  getById(id: number): Observable<ParametroSistemaDto> {
    return this.http.get<ParametroSistemaDto>("/api/ParametrosSistema/GetByid?id=" + id)
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/ParametrosSistema/Delete?id=" + id);
    else
      return of(null)
  }


  setParametrosSistemaLocalStorage() {
    this.getAll()
      .subscribe(
        data => {
          var parametroSistemaValues = new ParametroSistemaValues()
          data.forEach(element => {
            switch (element.id) {
              //Correo
              case ParametrosConfiguracion.Sender:
                parametroSistemaValues.Sender = element.valor
                break
              case ParametrosConfiguracion.SmtpServer:
                parametroSistemaValues.SmtpServer = ""//element.valor
                break
              case ParametrosConfiguracion.Port:
                parametroSistemaValues.Port = ""//element.valor
                break
              case ParametrosConfiguracion.Username:
                parametroSistemaValues.Username = ""//element.valor
                break
              case ParametrosConfiguracion.Password:
                parametroSistemaValues.Password = ""//element.valor
                break
              case ParametrosConfiguracion.SslTls:
                const SslTls = JSON.parse(element.valor)
                parametroSistemaValues.SslTls = ""//element.valor
                break
              //Notificaciones
              case ParametrosConfiguracion.FechaGenLiquizacion:
                parametroSistemaValues.FechaGenLiquizacion = element.valor
                break
              case ParametrosConfiguracion.FechaGenResumen:
                parametroSistemaValues.FechaGenResumen = element.valor
                break
              case ParametrosConfiguracion.DiasFinalizaContrato:
                parametroSistemaValues.DiasFinalizaContrato = element.valor
                break
              case ParametrosConfiguracion.CantidadMesesDeudaPropiedad:
                parametroSistemaValues.CantidadMesesDeudaPropiedad = ""//element.valor
                break
              case ParametrosConfiguracion.DuracionAlertas:
                parametroSistemaValues.DuracionAlertas = element.valor
                break
              //Comprobantes
              case ParametrosConfiguracion.NotaResumen:
                parametroSistemaValues.NotaResumen = ""//element.valor
                break
              case ParametrosConfiguracion.NotaRecibo:
                parametroSistemaValues.NotaRecibo = ""//element.valor
                break
              case ParametrosConfiguracion.NotaLiquidacion:
                parametroSistemaValues.NotaLiquidacion = ""//element.valor
                break
              //Parametros Contables
              case ParametrosConfiguracion.Iva:
                parametroSistemaValues.Iva = ""//element.valor
                break
              case ParametrosConfiguracion.Caja:
                parametroSistemaValues.Caja = ""//element.valor
                break
              case ParametrosConfiguracion.CuentaContableDefecto:
                parametroSistemaValues.CuentaContableDefecto = ""//element.valor
                break
              //Comisiones
              case ParametrosConfiguracion.ComisionGananciaInmobiliaria:
                parametroSistemaValues.ComisionGananciaInmobiliaria = ""// element.valor
                break
              case ParametrosConfiguracion.ComisionGananciaPropietario:
                parametroSistemaValues.ComisionGananciaPropietario = ""// element.valor
                break
              //General
              case ParametrosConfiguracion.CodigoAlquiler:
                parametroSistemaValues.CodigoAlquiler = element.valor
                break
              case ParametrosConfiguracion.DuracionMesesArchivosTemporales:
                parametroSistemaValues.DuracionMesesArchivosTemporales = ""//element.valor
                break
              default:
                break
            }
          });
          this.setParametrosSistemas(parametroSistemaValues)
        },
        error => {
        }
      );

  }

  private setParametrosSistemas(parametroSistemaValues: ParametroSistemaValues) {
    sessionStorage.removeItem("parametroSistemaValues")
    sessionStorage.setItem("parametroSistemaValues", UtilidadesController.encrypt(JSON.stringify(parametroSistemaValues)))
  }

  public  getParametrosSistemasValues(): ParametroSistemaValues {
    let parametroSistemaValues = new ParametroSistemaValues
    let parameter = sessionStorage.getItem("parametroSistemaValues")
    if (!isFalsy(parameter)) {
      let parameterDesncrypt = UtilidadesController.decrypt(parameter)
      parametroSistemaValues = JSON.parse(parameterDesncrypt) as ParametroSistemaValues
    }

    if (isFalsy(parametroSistemaValues))
      this.setParametrosSistemaLocalStorage()

    return parametroSistemaValues
  }


}





