<button mat-icon-button tabindex="1" class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div class="row">
    <div class="col-lg-10">
        <h1 mat-dialog-title>
            Seleccione bonificaciones</h1>
    </div>
    <div class="col-lg-2">
        <button mat-mini-fab tabindex="2" matTooltip="Nuevo bonificación" matTooltipPosition="right" (click)="openDialogNuevoBonificacion()" color="primary">
      <mat-icon>add</mat-icon></button>
    </div>
</div>
<div mat-dialog-content>
    <mat-card>
        <div class="example-container">
            <table mat-table class="example-table" [dataSource]="dataSource">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox tabindex="3" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Tipo Movimiento Column -->
                <ng-container matColumnDef="tipoBonificacion">
                    <th mat-header-cell *matHeaderCellDef> Tipo Bonificacion </th>
                    <td mat-cell *matCellDef="let element"> {{element.bonificacion != undefined ? element.bonificacion.tipoBonificacion.descripcion : "" | titlecase}} </td>
                </ng-container>

                <!-- Detalle Column -->
                <ng-container matColumnDef="detalle">
                    <th mat-header-cell *matHeaderCellDef> Detalle </th>
                    <td mat-cell *matCellDef="let element"> {{element.descripcion != undefined ? element.descripcion : ""}}
                </ng-container>

                <!-- Importe Column -->
                <ng-container matColumnDef="importe">
                    <th mat-header-cell *matHeaderCellDef> Importe </th>
                    <td mat-cell *matCellDef="let element"> {{element.importe | currency}} </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="accion">
                    <th mat-header-cell *matHeaderCellDef> Acción</th>
                    <td mat-cell *matCellDef="let pago" class="action-link">
                        <button mat-icon-button color="primary" tabindex="4" (click)="eliminarBonifiacion(pago)" aria-label="Example icon-button with a menu" matTooltip="Eliminar">
                      <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
                </tr>
            </table>
            <div class="no-data-table">
                <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
            </div>
        </div>
    </mat-card>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button tabindex="5" color="primary" (click)="onSubmit()">Aceptar</button>
    <button mat-button mat-dialog-close tabindex="6" color="warn" (click)="onCancel()">Cancelar</button>
</div>
