import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoArchivosTemporalesComponent } from '../views/archivos-temporales/listado-archivos-temporales/listado-archivos-temporales.component';
import { SharedModule } from 'src/app/modules/shared.module';



@NgModule({
  declarations: [ListadoArchivosTemporalesComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[ListadoArchivosTemporalesComponent]
})
export class ArchivoTemporalModule { }
