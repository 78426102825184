import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { Roles } from '@models/base/identificadores.model';
import { JwtPayload } from '@models/login/token-model.model';
import { Menu, SubMenu } from '@models/seguridad/menu/menu.model';
import { PermisoDto } from '@models/seguridad/permisos/permisoDto.model';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { EmpresaService } from '@services/empresa/empresa.service';
import { LoginService } from '@services/login/login.service';
import { UsuariosService } from '@services/usuarios/usuarios.service';
import { isFalsy } from 'utility-types';



@Component({
  selector: 'nav-search-perfil-logo',
  templateUrl: './nav-search-perfil-logo.component.html',
  styleUrls: ['./nav-search-perfil-logo.component.css']
})

export class NavSearchPerfilLogoComponent implements OnInit {

  archivo: ArchivoDto = new ArchivoDto
  @ViewChild("inputSearch") input?: ElementRef;
  hidden = false;
  isLoading: boolean = true;
  matTooltip?: string;
  menues!: Menu[]
  value = 'Buscar';
  user!: UsuarioDto;

  constructor(
    private loginService: LoginService,
  ) {
  }

  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser();
    this.archivo.id = 1;

    if (this.user) {
        this.matTooltip = `Nombre y Apellido: &#13; ${this.user.persona.nombreCompleto}. &#13; Usuario: ${this.user.name}`;
    }

    if (this.user.rol.permisos) {
        this.user.rol.permisos.forEach(element => {
            if (isFalsy(this.menues)) this.menues = [];

            let menu = this.menues.find(x => x.displayName === element.casoUso.menu.descripcion) as Menu;

            if (!menu) {
                menu = this.addMenu(element);
                let subMenu = this.addSubMenu(element);
                menu.submenues.push(subMenu);
                this.menues.push(menu);
            } else {
                let subMenu = menu.submenues.find(x => x.displayName === element.casoUso.descripcion) as SubMenu;
                if (!subMenu) {
                    subMenu = this.addSubMenu(element);
                    menu.submenues.push(subMenu);
                }
            }
        });

        // Ordenar los menús por la propiedad `orden`
        this.menues.sort((a, b) => {
            const ordenA = this.getOrdenFromMenu(b.displayName);
            const ordenB = this.getOrdenFromMenu(a.displayName);
            return ordenA - ordenB;
        });

        // Ordenar los submenús de cada menú por `orden`
        this.menues.forEach(menu => {
            menu.submenues.sort((a, b) => {
                const ordenA = this.getOrdenFromSubMenu(b.displayName);
                const ordenB = this.getOrdenFromSubMenu(a.displayName);
                return ordenA - ordenB;
            });
        });
    }

    this.isLoading = false;
}

// Método para obtener el valor de `orden` de un menú
getOrdenFromMenu(menuName: string): number {
    const menu = this.user.rol.permisos.find(
        permiso => permiso.casoUso.menu.descripcion === menuName
    )?.casoUso.menu;

    return menu?.orden ? menu.orden : Number.MAX_SAFE_INTEGER; // Si no tiene orden, lo coloca al final
}

// Método para obtener el valor de `orden` de un submenú
getOrdenFromSubMenu(subMenuName: string): number {
    const subMenu = this.user.rol.permisos.find(
        permiso => permiso.casoUso.descripcion === subMenuName
    )?.casoUso;

    return subMenu?.menu.orden ? subMenu.menu.orden : Number.MAX_SAFE_INTEGER; // Si no tiene orden, lo coloca al final
}

  addMenu(element: PermisoDto) {
    let menu = new Menu
    menu.displayName = isFalsy(element.casoUso.menu.descripcion) ? "" : element.casoUso.menu.descripcion
    menu.iconName = isFalsy(element.casoUso.menu.icon) ? "" : element.casoUso.menu.icon
    menu.route = isFalsy(element.casoUso.menu.path) ? "" : element.casoUso.menu.path
    return menu
  }
  addSubMenu(element: PermisoDto) {
    let subMenu = new SubMenu
    subMenu.displayName = isFalsy(element.casoUso.descripcion) ? "" : element.casoUso.descripcion
    subMenu.iconName = isFalsy(element.casoUso.icon) ? "" : element.casoUso.icon
    subMenu.route = isFalsy(element.casoUso.path) ? "" : element.casoUso.path
    return subMenu
  }

  trackByItems(index: number, item: any): any { return item; }

}
