import { PersonaDetailDto } from "@models/personas/personaDetailDto.model";
import { UsuarioDto } from "../models/usuarios/usuarioDto.model";
import * as fromActions from "./app.actions";

export class AppState {
  public personaDetailDto!: PersonaDetailDto
  public usuario!: UsuarioDto
}


export function reducer(state: AppState, action: fromActions.Acciones) {
  switch (action.type) {
    case fromActions.SETPERSONA:
      return state.personaDetailDto = action.personaDetailDto
    case fromActions.SETUSUARIO:
      return state.usuario=  action.usuarioDto

    default:
      break;
  }
  return state;
}

