import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/modules/shared.module";
import { AsociaDocumentoEntidadComponent } from "../views/documentos/asocia-documento-entidad/asocia-documento-entidad.component";
import { AsociaDocumentoNuevoEntidadComponent } from "../views/documentos/asocia-documento-nuevo-entidad/asocia-documento-nuevo-entidad.component";
import { BtnAddDocumentoComponent } from "../views/documentos/btn-add-documento/btn-add-documento.component";
import { BtnNuevoDocumentoComponent } from "../views/documentos/btn-nuevo-documento/btn-nuevo-documento.component";
import { CreateDocumentComponent } from "../views/documentos/create-document/create-document.component";
import { DialogCreateDocumentComponent } from "../views/documentos/dialog-create-document/dialog-create-document.component";
import { DialogEditarDocumentoComponent } from "../views/documentos/dialog-editar-documento/dialog-editar-documento.component";
import { DocumentosContratosComponent } from "../views/documentos/documentos-entidades/documentos-contratos/documentos-contratos.component";
import { DocumentosPersonasComponent } from "../views/documentos/documentos-entidades/documentos-personas/documentos-personas.component";
import { DocumentosPropiedadesComponent } from "../views/documentos/documentos-entidades/documentos-propiedades/documentos-propiedades.component";
import { ListadoDocumentosEntidadComponent } from "../views/documentos/documentos-entidades/listado-documentos-entidad/listado-documentos-entidad.component";
import { DocumentosComponent } from "../views/documentos/documentos.component";
import { EditarDocumentoComponent } from "../views/documentos/editar-documento/editar-documento.component";
import { FormDocumentoComponent } from "../views/documentos/form-documento/form-documento.component";
import { ListadoDocumentosComponent } from "../views/documentos/listado-documentos/listado-documentos.component";
import { TiposDocumentoService } from "../services/tipos-documento/tipos-documento.service";
import { ArchivosModule } from "./archivos.module";
import { AsuntosModule } from "./asuntos.module";
import { ContratosModule } from "./contratos.module";
import { PersonasModule } from "./personas.module";
import { PropiedadesModule } from "./propiedades.module";
import { DocumentoRoutingModule } from "../route/documento-routing.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AsuntosModule,
        ContratosModule,
        PersonasModule,
        PropiedadesModule,
        PersonasModule,
        ArchivosModule,
        //DocumentoRoutingModule
    ],
    declarations: [
        DocumentosComponent,
        FormDocumentoComponent,
        ListadoDocumentosComponent,
        EditarDocumentoComponent,
        BtnAddDocumentoComponent,
        CreateDocumentComponent,
        DialogCreateDocumentComponent,
        ListadoDocumentosEntidadComponent,
        DialogEditarDocumentoComponent,
        BtnNuevoDocumentoComponent,
        AsociaDocumentoEntidadComponent,
        AsociaDocumentoNuevoEntidadComponent,
        DocumentosPropiedadesComponent,
        DocumentosPersonasComponent,
        DocumentosContratosComponent,
    ],

    providers: [
        TiposDocumentoService
    ],
    exports: [
        DocumentosComponent,
        FormDocumentoComponent,
        ListadoDocumentosComponent,
        EditarDocumentoComponent,
        BtnAddDocumentoComponent,
        CreateDocumentComponent,
        DialogCreateDocumentComponent,
        ListadoDocumentosEntidadComponent,
        DialogEditarDocumentoComponent,
        BtnNuevoDocumentoComponent,
        AsociaDocumentoEntidadComponent,
        AsociaDocumentoNuevoEntidadComponent,
        DocumentosPropiedadesComponent,
        DocumentosPersonasComponent,
        DocumentosContratosComponent,]
})
export class DocumentosModule { }
