<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div class="row">
  <div class="col-lg-11">
    <h1 mat-dialog-title>
      <strong>Nuevo</strong> recibo
    </h1>
  </div>
</div>
<mat-divider></mat-divider>
<br>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <form [formGroup]="nuevoLiquidacionFormGroup">
    <div class="row">
      <div class="col-lg-6">
        <mat-card>
          <ngx-spinner name="spNuevoRecibo" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
            type="ball-atom" [fullScreen]="false">
          </ngx-spinner>
          <mat-card-content>
            <div class="row">
              <div class="col-lg-6">
                <p><b>Propiedad: </b> {{contrato.propiedad | direccionPropiedad}}</p>
              </div>
              <div class="col-lg-6">
                <p><b>Inquilino: </b> {{contrato.inquilino.persona.descripcion | titlecase}}</p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card>
          <ngx-spinner name="spNuevoRecibo" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
            type="ball-atom" [fullScreen]="false">
          </ngx-spinner>
          <mat-card-subtitle class="container">
            <div>Detalle de deuda</div>
            <div> Saldo anterior: <strong>{{totalSaldoAnterior | currency}}</strong>
            </div>
          </mat-card-subtitle>
          <mat-card-content *ngIf="getPendingDebtInquilinoResponse">
            <div class="row">
              <div class="col-lg-6">
                <h3>Estado actual
                  <mat-icon class="center-icon" color="primary"
                    [matTooltip]="'La misma esta compuesta por: \n Sumatoria de Monto Alquiler \n Sumatoria de Servicios \n Sumatoria de Movimientos'">
                    info</mat-icon>
                </h3>
              </div>
              <div class="col-lg-3">
                <h3>{{deudaActual | currency}}</h3>
              </div>
            </div>
            <div class="row" *ngIf="getPendingDebtInquilinoResponse.detallesServiciosPropiedad.length > 0">
              <div class="col-lg-6">
                <p>
                  <strong>Servicios:</strong>
                </p>
              </div>
              <div class="col-lg-3">
                <h3>{{totalDetallesServiciosPropiedad | currency}}</h3>
              </div>
              <div class="col-lg-3">
                <button mat-mini-fab (click)="editarAdicionalesInquilino()" color="primary"
                  [matBadge]="cantidadDetalleServicios" matBadgeColor="accent"
                  aria-label="Example icon button with a home icon">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>
            <div class="row" *ngIf="getPendingDebtInquilinoResponse.periodosContrato.length >0">
              <div class="col-lg-6">
                <p><strong>Monto Alquiler: </strong></p>
              </div>
              <div class="col-lg-3">
                <h3>{{totalMontoAlquiler | currency}}</h3>
              </div>
              <div class="col-lg-3">
                <button mat-mini-fab (click)="editarPeriodosContrato()"
                  color="primary" [matBadge]="contadorPeriodoContratoAPagar()" matBadgeColor="accent"
                  aria-label="Example icon button with a home icon">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <p>
                  <strong>Movimientos:</strong>
                </p>
              </div>
              <div class="col-lg-3">
                <h3>{{totalMontoMovimientos | currency}}</h3>
              </div>
              <div class="col-lg-3">
                <button mat-mini-fab (click)="editarMovimientosInquilino()" color="primary"
                  [matBadge]="cantidadMovimientos" matBadgeColor="accent"
                  aria-label="Example icon button with a home icon">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <p>
                  <strong>Bonificaciones:</strong>
                </p>
              </div>
              <div class="col-lg-3">
                <h3>{{totalMontoBonificaciones | currency}}</h3>
              </div>
              <div class="col-lg-3">
                <button mat-mini-fab (click)="editarBonificaciones()" color="primary"
                  [matBadge]="cantidadBonificaciones" matBadgeColor="accent"
                  aria-label="Example icon button with a home icon">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <h2>Total:</h2>
              </div>
              <div class="col-lg-3">
                <h2 *ngIf="calcularTotales() > totalEntregaInquilino" style="color: red;">{{calcularTotales() |
                  currency}}</h2>
                <h2 *ngIf="calcularTotales() <= totalEntregaInquilino" style="color: green;">{{calcularTotales() |
                  currency}}</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <span>Saldo
                </span>
              </div>
              <div class="col-lg-3">
                <h3>{{ totalEntregaInquilino - calcularTotales() + totalSaldoAnterior | currency}}</h3>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-lg-6">
        <!-- Pagos -->
        <mat-card>
          <mat-card-subtitle>Pago del inquilino</mat-card-subtitle>
          <mat-card-content>
            <button mat-raised-button (click)="agregarPago()" color="primary">
              <mat-icon>add</mat-icon>
              Nuevo pago
            </button>
          </mat-card-content>
          <table mat-table [dataSource]="dataSource" [hidden]=!data class="mat-elevation-z8">
            <!-- Forma de Pago Column -->
            <ng-container matColumnDef="formaPago">
              <th mat-header-cell *matHeaderCellDef> Forma de Pago </th>
              <td mat-cell *matCellDef="let pago"> {{pago.formaPago.descripcion}} </td>
              <td mat-footer-cell *matFooterCellDef> Total </td>
            </ng-container>
            <!-- Fecha Column -->
            <ng-container matColumnDef="fecha">
              <th mat-header-cell *matHeaderCellDef> Fecha </th>
              <td mat-cell *matCellDef="let pago"> {{pago.fechaPago | date:('dd/MM/yyyy')}} </td>
              <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <!-- Importe Column -->
            <ng-container matColumnDef="importe">
              <th mat-header-cell *matHeaderCellDef> Importe </th>
              <td mat-cell *matCellDef="let pago"> {{pago.importe | currency}} </td>
              <td mat-footer-cell *matFooterCellDef> {{totalEntregaInquilino | currency}} </td>
            </ng-container>
            <!-- Importe Column -->
            <ng-container matColumnDef="detalle">
              <th mat-header-cell *matHeaderCellDef> Detalle </th>
              <td mat-cell *matCellDef="let pago">
                <mat-icon
                  matTooltip='Fecha Vencimiento: {{pago.fechaVencimiento | date}} &#10; &#13;  Descripcion: {{pago.descripcion}}'>
                  info</mat-icon>
              </td>
              <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>
            <!-- Action Column -->
            <ng-container matColumnDef="accion">
              <th mat-header-cell *matHeaderCellDef> Acción</th>
              <td mat-cell *matCellDef="let pago" class="action-link">
                <button mat-icon-button color="primary" (click)="eliminarPago(pago)"
                  aria-label="Example icon-button with a menu" matTooltip="Eliminar">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
              <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row">
            </tr>

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns" class="example-first-footer-row"></tr> -->
          </table>
          <div class="no-data-table">
            <span class="with-icon" *ngIf="dataSource.data.length == 0">
              <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
            </span>
          </div>
        </mat-card>
      </div>
    </div>
  </form>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions align="end">
  <button mat-stroked-button (click)="getResumenRecibo()" *appRole="['Comprobantes.Crear']" style="text-align: left;"
    color="primary">
    <mat-icon> description </mat-icon> Resumen recibo
  </button>
  <button mat-button (click)="closeDialog()" style="float: right;" mat-dialog-close color="warn">Cancelar</button>
</div>
