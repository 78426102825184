import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';

@Component({
  selector: 'app-dialog-forma-pago',
  templateUrl: './dialog-forma-pago.component.html',
  styleUrls: ['./dialog-forma-pago.component.css']
})
export class DialogFormaPagoComponent implements OnInit {

  action:string;
  local_data:any;
  form: any;
  formBuilder: FormBuilder = new FormBuilder;

  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogFormaPagoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: BaseDto) {
    this.local_data = {...data};
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      Descripcion: ['', Validators.compose([Validators.required])]
    })

    if (this.action != AccionesDialog.Agregar){
      this.form.controls["Descripcion"].setValue(this.local_data.descripcion)
    }
  }

  doAction(){
    this.dialogRef.close({event:this.action,data:this.local_data});
  }

  closeDialog(){
    this.dialogRef.close({event:AccionesDialog.Cancelar});
  }

  ngOnInit(): void {
  }

}
