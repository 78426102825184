import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { BonificacionDto } from '@models/bonificaciones/bonificacionDto.model';
import { BonificacionesService } from '@services/bonificaciones/bonificaciones.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-nuevo-movimiento-bonificacion',
  templateUrl: './dialog-nuevo-movimiento-bonificacion.component.html',
  styleUrls: ['./dialog-nuevo-movimiento-bonificacion.component.css']
})
export class DialogNuevoMovimientoBonificacionComponent implements OnInit {


  action: string;
  local_data: any;

  form: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  lstBonificacion!: BonificacionDto[];

  get f() { return this.form.controls; }

  constructor(
    public bonificacionesService: BonificacionesService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<DialogNuevoMovimientoBonificacionComponent>,

    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    this.action = this.local_data.event;

    this.form = this.formBuilder.group({
      Importe: [Validators.compose([Validators.required,])],
      Bonificacion: ["", Validators.compose([Validators.required,])],
      Descripcion: [""],
    })

    this.form.controls["Bonificacion"].setValue(data.bonificacion)
    this.form.controls["Importe"].setValue(data.importe)

  }

  doAction() {
    this.local_data.bonificacion = this.form.controls["Bonificacion"].value
    this.local_data.importe = parseFloat(this.form.controls["Importe"].value)
    this.local_data.descripcion = this.form.controls["Descripcion"].value
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }
  ngOnInit(): void {
    this.getAllBonificacion()
  }

  getAllBonificacion() {
    this.spinner.show("spBusqueda")
    this.bonificacionesService.getAll().subscribe(
      data => {
        this.lstBonificacion = data
        this.spinner.hide("spBusqueda")
      },
      error => {
        this.spinner.hide("spBusqueda")
        this.snackBar.showError(error, "Error");
      }
    )
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}
