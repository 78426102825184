import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SendEmailRequest } from '@models/email/sendEmailRequest.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private http: HttpClient
  ) { }

  send(sendEmailRequest: SendEmailRequest): Observable<any> {
    return this.http.post<any>('/api/Email/Send', sendEmailRequest)
  }
}
