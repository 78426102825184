import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseDto } from "@models/base/baseDto.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class QuienPagaService {
  constructor(
    private http: HttpClient
  ) { }


  getById(id: number): Observable<BaseDto> {
    return this.http.get<BaseDto>("/api/QuienPaga/GetById?id=" + id);
  }

  getAll(): Observable<BaseDto[]> {
    return this.http.get<BaseDto[]>("/api/QuienPaga/GetAll");
  }

}
