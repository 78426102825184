<div class="centered">
    <div class="centered">
        <mat-icon>{{icono}}</mat-icon>
    </div>
    <div class="accion">
        <span><strong>{{accion}}</strong></span>
    </div>
</div>
<span> {{mensaje}} <a class="link" [routerLink]="link"> {{nombreLink}}
</a></span>
