<app-btn-nuevo-contrato *appRole="['Contratos.Crear']"></app-btn-nuevo-contrato>
<br>
<br>
<div *appRole="['Contratos.Ver']">
  <mat-card>
    <form [formGroup]="form">
      <div class="row">

        <div class="col-lg-2">
          <mat-form-field class="example-full-width">
            <mat-label>Nro. Contrato</mat-label>
            <input formControlName="NumeroContrato" matInput>
            <mat-icon matSuffix>gavel</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-lg-2">
          <mat-form-field class="example-full-width">
            <ngx-spinner [zIndex]="100" name="spBusquedaEstadosContrato" bdColor="rgba(255,255,255,0.8)" size="small"
              color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
            <mat-label>Estados de contrato</mat-label>
            <mat-select placeholder="Seleccione opcion" multiple formControlName="EstadosContrato"
              (selectionChange)="onSelectionChangeEstadosContrato($event)">
              <mat-option [value]="item.id" *ngFor="let item of lstEstadosContrato;let i=index;trackBy:trackByItems">
                {{ item.descripcion | titlecase }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-2">
          <mat-form-field class="example-full-width">
            <ngx-spinner [zIndex]="100" name="spBusquedaTiposContrato" bdColor="rgba(255,255,255,0.8)" size="small"
              color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
            <mat-label>Tipos de contrato</mat-label>
            <mat-select placeholder="Seleccione opcion" multiple formControlName="TiposContrato"
              (selectionChange)="onSelectionChangeTiposContrato($event)">
              <mat-option [value]="item.id" *ngFor="let item of lstTiposContrato;let i=index;trackBy:trackByItems">
                {{ item.descripcion | titlecase }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-2">
          <mat-form-field class="example-full-width">
            <ngx-spinner [zIndex]="100" name="spBusquedaTiposAjuste" bdColor="rgba(255,255,255,0.8)" size="small"
              color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
            <mat-label>Tipos de ajuste</mat-label>
            <mat-select placeholder="Seleccione opcion" multiple formControlName="TiposAjuste"
              (selectionChange)="onSelectionChangeTiposAjuste($event)">
              <mat-option [value]="item.id" *ngFor="let item of lstTiposAjuste;let i=index;trackBy:trackByItems">
                {{ item.acronimo | uppercase }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- <div class="col-lg-2">
              <mat-checkbox class="example-margin" formControlName="Recindido">Recindido</mat-checkbox>
            </div>
            <div class="col-lg-2">
              <mat-checkbox class="example-margin" formControlName="Finalizado">Finalizado</mat-checkbox>
            </div>  -->

      </div>

      <div class="row" *ngIf="checkedFiltroAvanzado">

        <div class="col-lg-3" *ngIf="checkedFiltroAvanzado">
          <mat-form-field class="full-width">
            <mat-label>Ingrese rango de fechas comienzo</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate [disabled]="true" formControlName="FechaDesde" placeholder="Fecha desde">
              <input matEndDate [disabled]="true" formControlName="FechaHasta" placeholder="Fecha hasta">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="col-lg-2" *ngIf="checkedFiltroAvanzado">
          <mat-form-field class="example-full-width">
            <mat-label>Tipos de liquidación</mat-label>
            <mat-select placeholder="Seleccione opcion" multiple formControlName="TiposLiquidacion">
              <mat-option [value]=1>Real</mat-option>
              <mat-option [value]=2>Ficticia</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-3" *ngIf="checkedFiltroAvanzado">
          <app-buscar-propiedad (eventEntity)="selectPropiedad($event)"></app-buscar-propiedad>&nbsp;&nbsp;
        </div>
        <div class="col-lg-3" *ngIf="checkedFiltroAvanzado">
          <app-buscar-persona-inquilino
            (eventEntity)="selectInquilino($event)"></app-buscar-persona-inquilino>&nbsp;&nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button mat-stroked-button color="primary" (click)="filterContratos()">
            <mat-icon>filter_alt</mat-icon>
            Filtrar</button>&nbsp; &nbsp;
          <mat-slide-toggle class="example-margin" (change)="filtrosAvanzados($event)" color="primary">
            Filtros Avanzados
          </mat-slide-toggle>&nbsp; &nbsp;
          <button mat-stroked-button color="primary" (click)="clearFilter()">
            <mat-icon>clear</mat-icon>
            Limpiar</button>
        </div>

      </div>
    </form>
    <div class="row" [hidden]=!data>
      <div class="col-lg-2">
        <mat-form-field>
          <mat-select placeholder="Buscar por" [(value)]="selectTipoBusquedaControl"
            (selectionChange)="selectTipoBusqueda($event.value)">
            <mat-option value="0">Nro. Contrato</mat-option>
            <mat-option value="1">Inquilino</mat-option>
            <mat-option value="2">Propietario</mat-option>
            <mat-option value="3">Propiedad</mat-option>
            <!-- <mat-option value="4">Tomo</mat-option>
                    <mat-option value="5">Folio</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-2">

        <mat-form-field class="example-full-width">
          <mat-label>Buscar {{tipoBusqueda}}</mat-label>
          <input type="search" matInput (keyup)="applyFilter($event)" [formControl]="formControlBuscar">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <!-- <div class="col-lg-6">
                <mat-form-field *ngFor="let filter of filterSelectObjEstados;let i=index;trackBy:trackByItems" style="margin-left: 15px;">
                    <mat-label>Filtro {{filter.name}}</mat-label>
                    <mat-select matNativeControl name="{{filter.columnProp}}" [(ngModel)]="filter.name" (selectionChange)="filterChangeEstados(filter,$event)">
                        <mat-option value="">Todos</mat-option>
                        <mat-option [value]="item" *ngFor="let item of filter.options">{{item}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
    </div>




    <ngx-spinner [zIndex]=100 bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom"
      [fullScreen]="false">
    </ngx-spinner>

    <div class="row">
      <div class="col-lg-2">
        <!-- Botón que abre el menú de acciones -->
        <button mat-raised-button *appRole="['Contratos.Acciones']" [matMenuTriggerFor]="actionsMenu" [hidden]=!data color="primary">
          <mat-icon>more_vert</mat-icon>
          <span>Acciones</span>
        </button>

        <!-- Definición del menú de acciones -->
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item *appRole="['Contratos.GenerarResumen']"
              (click)="openDialogGenerarResumenMasivo()">
              <mat-icon>picture_as_pdf</mat-icon>
              <span>Generar Resumen</span>
            </button>
        </mat-menu>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" [hidden]=!data table matSort matSortStart="asc" class="mat-elevation-z8">

      <!-- Seleccionar contrato -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="masterToggle()" class="select-center" [checked]="isAllSelected()" [indeterminate]="isIndeterminate()"></mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let contrato">
      <mat-checkbox (click)="$event.stopPropagation()" class="select-center" (change)="toggleSelection(contrato)" [checked]="selection.isSelected(contrato)">
      </mat-checkbox>
    </td>
  </ng-container>

      <!-- Propiedad -->
      <ng-container matColumnDef="propiedadNombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Propiedad</th>
        <td mat-cell *matCellDef="let contrato">
          <a [routerLink]="['/propiedades/',contrato.propiedadId]"><b>{{contrato.propiedadNombre |
              titlecase}}</b></a>
        </td>
      </ng-container>

      <!-- Estado Contrato -->
      <ng-container matColumnDef="estadoNombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado</th>
        <td mat-cell *matCellDef="let contrato">
          <div *ngIf="contrato.estadoId == estadosContrato.Activo">
            <span class="badge bg-success text-light">{{contrato.estadoNombre | titlecase}}</span>
          </div>
          <div *ngIf="contrato.estadoId == estadosContrato.Finalizado">
            <span class="badge bg-danger text-light">{{contrato.estadoNombre | titlecase}}</span>
          </div>
        </td>
      </ng-container>
      <!-- Nro Contrato -->
      <ng-container matColumnDef="numeroContrato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Contrato </th>
        <td mat-cell *matCellDef="let contrato">{{contrato.numeroContrato}} </td>
      </ng-container>

      <!-- Legajo -->
      <!-- <ng-container matColumnDef="legajo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Legajo</th>
            <td mat-cell *matCellDef="let contrato">
                <mat-icon color="primary" [matTooltip]="contrato.legajo" [matTooltipClass]="'allow-cr'">info</mat-icon>
            </td>
        </ng-container> -->


      <!-- Inquilino -->
      <ng-container matColumnDef="inquilinoNombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Inquilino</th>
        <td mat-cell *matCellDef="let contrato">
          <b>{{contrato.inquilinoNombre | titlecase}}</b>
        </td>
      </ng-container>

      <!-- Propietario -->
      <ng-container matColumnDef="propietarioNombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Propietario titular</th>
        <td mat-cell *matCellDef="let contrato">
          <b>{{contrato.propietarioNombre | titlecase}}</b>
        </td>
      </ng-container>

      <!-- Duracion -->
      <ng-container matColumnDef="duracion">
        <th mat-header-cell *matHeaderCellDef> Duración</th>
        <td mat-cell *matCellDef="let contrato">
          <div class="duracion">
            <div class="date-duracion">
              {{contrato.fechaInicio | date:("dd/MM/yyyy")}} - {{contrato.fechaFin | date:("dd/MM/yyyy")}}
            </div>
            <div class="progressPorcent">
              <mat-progress-bar
                *ngIf="contrato.porcentajeDiasCompletados >= 0 && contrato.porcentajeDiasCompletados <=33" mode="buffer"
                (animationEnd)="progressInLoading()" color="primary" [value]="contrato.porcentajeDiasCompletados"
                [bufferValue]="100">
              </mat-progress-bar>
              <mat-progress-bar
                *ngIf="contrato.porcentajeDiasCompletados > 33  && contrato.porcentajeDiasCompletados <=66"
                mode="buffer" (animationEnd)="progressInLoading()" color="accent"
                [value]="contrato.porcentajeDiasCompletados" [bufferValue]="100">
              </mat-progress-bar>
              <mat-progress-bar *ngIf="contrato.porcentajeDiasCompletados > 66" mode="buffer"
                (animationEnd)="progressInLoading()" color="warn" [value]="contrato.porcentajeDiasCompletados"
                [bufferValue]="100">
              </mat-progress-bar>
              <span>% {{contrato.porcentajeDiasCompletados > 100 ? 100 :
                contrato.porcentajeDiasCompletados}}</span>

            </div>

          </div>
        </td>
      </ng-container>

      <!-- Tipo Contrato -->
      <ng-container matColumnDef="tipoContrato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Contrato</th>
        <td mat-cell *matCellDef="let contrato">
          <span class="badge bg-info text-light">{{contrato.tipoContrato | titlecase}}</span>
        </td>
      </ng-container>

      <!-- Tipo Ajuste -->
      <ng-container matColumnDef="tipoAjuste">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Ajuste</th>
        <td mat-cell *matCellDef="let contrato">
          <span class="badge bg-info text-light">{{contrato.tipoAjuste | uppercase}}</span>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="accion">
        <th mat-header-cell *matHeaderCellDef> Acción</th>
        <td mat-cell *matCellDef="let contrato" class="action-link">

          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
              <mat-icon>visibility</mat-icon>
              <span>Ver</span>
            </button>
            <button mat-menu-item *appRole="['Contratos.GenerarResumen']"
              (click)="openDialogGenerarResumen(contrato.id)">
              <mat-icon>picture_as_pdf</mat-icon>
              <span>Generar Resumen</span>
            </button>
            <button mat-menu-item *appRole="['Contratos.GenerarRecibo']" (click)="openDialogNuevoRecibo(contrato.id)"
              [disabled]="contrato.estadoId != estadosContrato.Activo">
              <mat-icon>add_circle</mat-icon>
              <span>Nuevo recibo</span>

            </button>
            <button mat-menu-item *appRole="['Contratos.GeneraLiquidacion']"
              (click)="openDialogNuevaLiquidacion(contrato.id)"
              [disabled]="contrato.estadoId != estadosContrato.Activo">
              <mat-icon>add_circle_outline</mat-icon>
              <span>Nueva liquidación</span>
            </button>
            <button mat-menu-item *appRole="['Movimientos.Crear']"
              (click)="openDialogImputacionPropietario(contrato.contratoDto)"
              [disabled]="contrato.estadoId != estadosContrato.Activo">
              <mat-icon>add_shopping_cart</mat-icon>
              <span>Imputación a propietario</span>
            </button>
            <button mat-menu-item *appRole="['Contratos.Rescindir']" (click)="openDialogRescisionContrato(contrato.id)"
              [disabled]="contrato.estadoId != estadosContrato.Activo">
              <mat-icon>cancel</mat-icon>
              <span>Rescisión de contrato</span>
            </button>

          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="no-data-table">
      <span class="with-icon" [hidden]=data><mat-icon>search_off</mat-icon>No se encontraron datos para
        mostrar.</span>
    </div>
    <mat-paginator showFirstLastButtons showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20,50,100,200]">
    </mat-paginator>
  </mat-card>
</div>
