import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateTipoMovimientoRequest } from '@models/tipos-movimiento/createTipoMovimientoRequest.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposMoviemientoService } from '@services/tipos-movimiento/tipos-movimiento.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogCreateTipoMovimientoComponent } from './dialog-create-tipo-movimiento/dialog-create-tipo-movimiento.component';

@Component({
  selector: 'app-crear-tipo-movimiento',
  templateUrl: './crear-tipo-movimiento.component.html',
  styleUrls: ['./crear-tipo-movimiento.component.css']
})
export class CrearTipoMovimientoComponent implements OnInit {
  formBuilder = new FormBuilder;
  form: any;
  get f() { return this.form.controls; }
  constructor(
    public dialogRef: MatDialogRef<DialogCreateTipoMovimientoComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private tiposMoviemientoService: TiposMoviemientoService
  ) {

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
      Resta: [""],
      EsAnulable: [true],
    })
  }

  ngOnInit(): void {

  }

  doAction() {

    let createTipoMovimientoRequest: CreateTipoMovimientoRequest = new CreateTipoMovimientoRequest
    createTipoMovimientoRequest.descripcion = this.form.controls["Descripcion"].value
    createTipoMovimientoRequest.resta = this.form.controls["Resta"].value == '' ? false : true
    createTipoMovimientoRequest.esAnulable = this.form.controls["EsAnulable"].value == '' ? false : true
    createTipoMovimientoRequest.editable = true
    this.create(createTipoMovimientoRequest)
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  create(createTipoMovimientoRequest: CreateTipoMovimientoRequest) {
    this.spinner.show("spCrearTipoMovimiento")
    this.tiposMoviemientoService.create(createTipoMovimientoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spCrearTipoMovimiento")
          this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Agregar });
        },
        error => {
          this.spinner.hide("spCrearTipoMovimiento")
          this.snackBar.showError(error, "Error");
        }
      )
  }

}
