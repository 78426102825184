import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ServicioDto } from '@models/servicios/servicio/servicioDto.model';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-buscar-servicios',
  templateUrl: './buscar-servicios.component.html',
  styleUrls: ['./buscar-servicios.component.css']
})
export class BuscarServiciosComponent implements OnInit {

  servcioSelect: EventEmitter<ServicioDto> = new EventEmitter()
  public filteredServicios!: Observable<ServicioDto[]>;
  servicios: ServicioDto[] = []
  entitySelect: ServicioDto | null = new ServicioDto;
  public entityControl = new FormControl();
  @Output() eventEntity = new EventEmitter<ServicioDto>();
  @Input() servicioEntrada: ServicioDto = new ServicioDto;
  @Input() idServicioInput: number = 0;
  idServicio?: number;
  esServicio: boolean = false;

  constructor(
    private serviciosService: ServiciosService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private router: Router
  )
  {
    this.eventEntity = new EventEmitter<ServicioDto>();
  }

  ngOnInit(): void {
    this.getAllServicios();

    if (this.idServicioInput != 0) {
      this.getServicioById(this.idServicioInput)
      this.entityControl.setValue(this.servicioEntrada.descripcion)
    }
  }

  selectValue(event: any) {
    if (isFalsy(event)){
      this.entitySelect=null
      this.entityControl.setValue("")
    }
    this.eventEntity.emit(event)
  }

  ngOnChanges(): void {
    this.getServicioById(this.idServicioInput)
    this.entityControl.setValue(this.servicioEntrada.descripcion)
  }

  findOption(val: string) {
    for (let i = 0; i < this.servicios.length; i++) {
      if (this.servicios[i].tipoServicio.descripcion.toUpperCase() == val.toUpperCase()) {
        this.eventEntity.emit(this.servicios[i])
        this.entitySelect = this.servicios[i]
        this.esServicio = true
      }
    }
    return this.servicios.filter((servicio) => new RegExp(val, 'gi').test(servicio.tipoServicio.descripcion));
  }


  getAllServicios() {
    this.spinner.show("spBusquedaServicios");
    this.serviciosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaServicios")
          this.servicios = data.sort((a, b) => a.tipoServicio?.descripcion.localeCompare(b.tipoServicio?.descripcion));
          this.filteredServicios = this.entityControl.valueChanges.pipe(
            startWith(''),
            map((term: any) => this.findOption(term))
          );
        },
        error => {
          this.spinner.hide("spBusquedaServicios");
          this.snackBar.showError( error, "Error");
        });
  }

  getServicioById(idServicio: any) {
    this.spinner.show("spBusquedaServicios");
    this.serviciosService.GetById(idServicio)
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaServicios");
          this.servicioEntrada = data
        },
        error=> {
          this.spinner.hide("spBusquedaServicios");
          this.snackBar.showError( error, "Error");
        }
        );
  }

  verServicio(entitySelect: any) {
    if (!isFalsy(entitySelect))
      this.router.navigate(['/servicios/servicio/editar/'],entitySelect.id)
  }

  trackByItems(index: number, item: any): any { return item; }

}
