import { Injectable, } from '@angular/core'
import { HttpClient, } from '@angular/common/http'
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CreatePersonaRequest } from '@models/personas/createPersonaRequest.model';
import { CreatePersonaResponse } from '@models/personas/createPersonaResponse.model';
//Necesario para listado de personas
import { UpdatePersonaRequest } from '@models/personas/updatePersonaRequest.model';
import { DeletePersonaRequest } from '@models/personas/deletePersonaRequest.model';
import { PersonaPropietarioDto } from '@models/propietarios/personaPropietarioDto.model';
import { PersonaGaranteDto } from '@models/garantia/personaGaranteDto.model';
import { SnackBarService } from '../snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { PersonaDto } from '@models/personas/personaDto.model';
import { UnlockPersonaRequest } from '@models/personas/unlockPersonaRequest.model';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms/';


@Injectable({
  providedIn: 'root'
})
export class PersonasService {
  public personaDetailDto!: PersonaDetailDto;
  public idPersona!: number

  private personaSource = new BehaviorSubject(new PersonaDetailDto);
  currentPersona = this.personaSource.asObservable();

  private id!: number;

  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient,
    private snackBar: SnackBarService) {
  }

  setPersona(personaDetailDto: PersonaDetailDto) {
    sessionStorage.removeItem("personaDetailDto")
    sessionStorage.setItem("personaDetailDto", JSON.stringify(personaDetailDto))
    this.personaDetailDto = personaDetailDto
  }

  setPersonaById(id: number) {
    if (id != 0)
      this.getPersonaById(id).subscribe(
        data => {
          sessionStorage.removeItem("personaDetailDto")
          sessionStorage.setItem("personaDetailDto", JSON.stringify(data))
          this.personaDetailDto = data
        },
        error => {
          this.snackBar.showError(error, "Error");
        })
  }

  getPersona(): PersonaDetailDto {
    let persona = JSON.parse(sessionStorage.getItem("personaDetailDto") as string == "" ? "" : sessionStorage.getItem("personaDetailDto") as string) as PersonaDetailDto
    if (isFalsy(persona) && this.personaDetailDto)
      return this.personaDetailDto
    else
      return persona
  }

  changePersona(personaDetailDto: PersonaDetailDto) {
    this.personaSource.next(personaDetailDto)
  }

  // private readonly urlBase = `${environment.apiUrl}`;

  validaPersonaByNroDocYTipoDoc(idPersona: number, nroDocumento: string, idTipoDocumento: number) {
    return this.http.get<boolean>("/api/Personas/ValidaPersonaByNroDocYTipoDoc?idPersona=" + idPersona + "&nroDocumento=" + nroDocumento + "&idTipoDocumento=" + idTipoDocumento);
  }

  getPersonaById(id: any): Observable<PersonaDetailDto> {
    return this.http.get<PersonaDetailDto>("api/Personas/GetPersonaById?idPersona=" + id);
  }

  getPersonaByCorreo(correoPersonal: string): Observable<PersonaDetailDto> {
    return this.http.get<PersonaDetailDto>("api/Personas/GetPersonaByCorreo?correoPersona=" + correoPersonal);
  }

  varlidaCorreoExistente(correoPersonal: string): Observable<boolean> {
    return this.http.get<PersonaDetailDto>(`/api/Personas/GetPersonaByCorreo?correoPersona=${correoPersonal}`).pipe(
      map(persona => !!persona),
      catchError(() => of(false))
    );
  }

  validateEmailNotTaken(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);  // Retorna nulo si el valor está vacío.
      }
      return of(control.value).pipe(
        debounceTime(300),  // Para evitar múltiples llamadas mientras el usuario está escribiendo.
        switchMap(email => this.varlidaCorreoExistente(email)),
        map(isTaken => (isTaken ? { emailTaken: true } : null)),
        catchError(() => of(null))  // En caso de error, retornamos nulo para no interrumpir la validación.
      );
    };
  }

  getAll(): Observable<PersonaDto[]> {
    return this.http.get<PersonaDto[]>("/api/Personas/GetAll");
  }

  getAllByFilter(fechaAltaDesde: string| null, fechaBajaDesde: string | null,fechaBajaHasta: string | null, fechaAltaHasta: string | null, idLocalidad: number | null, idTipoDocumentos: number | null, nombreCompleto: string | null, nroDocumento: string | null, correo: string | null,  estado: boolean | null): Observable<PersonaDto[]> {

    let filtro = ""

    if (fechaAltaDesde != null && fechaAltaDesde != "" && fechaAltaHasta != null && fechaAltaHasta != "")
      filtro += "&fechaAltaDesde=" + fechaAltaDesde + "&fechaAltaHasta=" + fechaAltaHasta
    if (fechaBajaDesde != null && fechaBajaDesde != "" && fechaBajaHasta != null && fechaBajaHasta != "")
      filtro += "&fechaBajaDesde=" + fechaBajaDesde + "&fechaBajaHasta=" + fechaBajaHasta
    if (!isFalsy(nombreCompleto))
      filtro += "&nombreCompleto=" + nombreCompleto
      if (!isFalsy(nroDocumento))
      filtro += "&nroDocumento=" + nroDocumento
      if (!isFalsy(correo))
      filtro += "&correo=" + correo
      if (!isFalsy(idTipoDocumentos))
      filtro += "&idTipoDocumentos=" + idTipoDocumentos
      if (!isFalsy(idLocalidad))
      filtro += "&idLocalidad=" + idLocalidad
      if (!isFalsy(estado))
      filtro += "&estado=" + estado

    return this.http.get<PersonaDto[]>("/api/Personas/GetAllByFilter?" + filtro);
  }


  getAllByName(nombrePersona: string): Observable<PersonaDetailDto[]> {
    return this.http.get<PersonaDetailDto[]>("/api/Personas/GetAllByName?nombrePersona=" + nombrePersona);
  }

  getPersonaByNroDocYTipoDoc(idTipoDocumento: number, nroDocumento: string): Observable<PersonaDetailDto> {
    return this.http.get<PersonaDetailDto>("/api/Personas/GetPersonaByNroDocYTipoDoc?nroDocumento=" + nroDocumento + "&idTipoDocumento=" + idTipoDocumento);
  }

  addPersona(createPersonaRequest: CreatePersonaRequest): Observable<CreatePersonaResponse> {
    return this.http.post<CreatePersonaResponse>("/api/Personas/CreatePersona", createPersonaRequest);
  }

  updatePersona(updatePersonaRequest: UpdatePersonaRequest) {
    return this.http.post("/api/Personas/UpdatePersona", updatePersonaRequest);
  }

  deletePersona(deletePersonaRequest: DeletePersonaRequest): Observable<boolean> {
    return this.http.post<boolean>("/api/Personas/DeletePersona", deletePersonaRequest);
  }

  getAllPersonasPropietarios(): Observable<PersonaPropietarioDto[]> {
    return this.http.get<PersonaPropietarioDto[]>("/api/Personas/GetAllPersonasPropietarios");
  }

  getAllPersonasGarantes(): Observable<PersonaGaranteDto[]> {
    return this.http.get<PersonaGaranteDto[]>("/api/Personas/GetAllPersonasGarantes");
  }

  unlockPersona(unlockPersonaRequest: UnlockPersonaRequest) {
    return this.http.post("/api/Personas/UnlockPersona", unlockPersonaRequest);
  }



}


