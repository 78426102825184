<mat-button-toggle-group [formControl]="matButtonToggleGroup" name="matButtonToggleGroup" aria-label="Font Style">
    <mat-button-toggle *appRole="['Dashboard.General']" value="general">General</mat-button-toggle>
    <mat-button-toggle *appRole="['Dashboard.Contratos']" value="contratos">Contratos</mat-button-toggle>
    <mat-button-toggle *appRole="['Dashboard.Propiedades']" value="propiedades">Propiedades</mat-button-toggle>
    <mat-button-toggle *appRole="['Dashboard.Clientes']" value="clientes">Clientes</mat-button-toggle>
    <mat-button-toggle *appRole="['Dashboard.Caja']" value="caja">Caja</mat-button-toggle>
</mat-button-toggle-group>

<!-- General -->
<div *appRole="['Dashboard.General']">
    <div *ngIf="mostrarDashboard && matButtonToggleGroup.value === 'general'">
        <app-dashboard-general></app-dashboard-general>
    </div>
</div>

<!-- Contratos -->
<div *appRole="['Dashboard.Contratos']">
    <div *ngIf="mostrarDashboard && matButtonToggleGroup.value === 'contratos'">
        <app-dashboard-contratos></app-dashboard-contratos>
    </div>
</div>

<!-- Propiedades -->
<div *appRole="['Dashboard.Propiedades']">
    <div *ngIf="mostrarDashboard && matButtonToggleGroup.value === 'propiedades'">
        <app-dashboard-propiedades></app-dashboard-propiedades>
    </div>
</div>

<!-- Caja -->
<div *appRole="['Dashboard.Caja']">
    <div *ngIf="mostrarDashboard && matButtonToggleGroup.value === 'caja'">
        <app-dashboard-caja></app-dashboard-caja>
    </div>
</div>
