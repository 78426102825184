<div class="position-relative">
    <ngx-spinner name="spCreateInquilino" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
        type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <form [formGroup]="personaForm" #regForm="ngForm">
        <div class="row">
            <mat-form-field class="example-full-width">
                <mat-label>Nombre y Apellido</mat-label>
                <input matInput type="text" maxlength="50" style="text-transform: capitalize;" id="NombreCompleto"
                    formControlName="NombreCompleto" />
                <mat-error *ngIf="f.NombreCompleto.errors?.required">Ingrese <strong> nombre completo.</strong>
                </mat-error>
                <mat-error *ngIf="f.NombreCompleto.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <ngx-spinner name="spBusquedaTipoDocumento" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688"
                    type="line-scale" [fullScreen]="false"></ngx-spinner>
                <mat-label>Tipo Documento</mat-label>
                <mat-select required #SelectTipoDocuemtno placeholder="Seleccione opcion"
                    formControlName="TiposDocumento" (selectionChange)="selectTipoDocumento($event)">
                    <mat-option [value]="tipoDocumento.id"
                        *ngFor="let tipoDocumento of lstTipoDocumentoDto ;let i=index;trackBy:trackByItems">
                        {{tipoDocumento.descripcion}}</mat-option>
                </mat-select>
                <mat-error *ngIf="f.TiposDocumento.errors?.required">Este campo es <strong>requerido</strong>.
                </mat-error>
                <mat-hint>Habilita nro. documento</mat-hint>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="example-full-width">
                <mat-label>Nro. Documento</mat-label>
                <input matInput name="NroDocumento" type="text" maxlength="15" #NroDocumento
                    oninput="this.value=this.value.toUpperCase()" formControlName="NroDocumento" />
                <mat-error *ngIf="f.NroDocumento.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                <mat-error *ngIf="f.NroDocumento.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                <mat-error *ngIf="f.NroDocumento.errors?.existePersona">Ya existe esta persona.</mat-error>
                <mat-error *ngIf="f.NroDocumento.errors?.pattern">Caracteres no validos.</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Correo</mat-label>
                <input matInput name="Correo" type="email" formControlName="Correo" />
                <mat-error *ngIf="f.Correo.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                <mat-error *ngIf="f.Correo.errors?.email">Correo invalido.</mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="example-full-width">
                <ngx-spinner name="spBusquedaGarantias" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688"
                    type="line-scale" [fullScreen]="false"></ngx-spinner>
                <mat-label>Grupos de garantias</mat-label>
                <mat-select (selectionChange)="selectGrupoGarantia($event.value)" formControlName="GruposGarantias">
                    <mat-option [value]="grupoGarantia.id"
                        *ngFor="let grupoGarantia of lstGruposGarantias ;let i=index;trackBy:trackByItems">
                        {{grupoGarantia.descripcion}}</mat-option>
                </mat-select>
                <mat-error *ngIf="f.GruposGarantias.errors?.required">Este campo es
                    <strong>requerido</strong>.</mat-error>
                <span matSuffix><button mat-icon-button matTooltipPosition="right" mat-dialog-close
                        matTooltip="Gestionar Grupos" [routerLink]="['/grupos-tipo-garantia']">
                        <mat-icon color="primary">add</mat-icon>
                    </button></span>
            </mat-form-field>

        </div>
        <br>
        <div class="col-auto my-1">
            <button mat-raised-button color="primary" style="margin-right:3px;" name="Agregar"
                [disabled]="!personaForm.valid || this.enProceso" (click)="onSubmit()">Agregar</button>
            <button mat-button color="warn" style="margin-right:3px;" name="Cancelar" (click)="onReset()"
                mat-dialog-close>Cancelar</button>

        </div>
    </form>
</div>
