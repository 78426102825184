import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PeriodoContratoComponent } from "@views/periodos-contrato/periodos-contrato/periodo-contrato.component";
import { SharedModule } from "./shared.module";
import { DialogVerPeriodoContratoDetailDtoComponent } from "@views/periodos-contrato/dialog-ver-periodo-contrato-detail-dto/dialog-ver-periodo-contrato-detail-dto.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
      PeriodoContratoComponent,
      DialogVerPeriodoContratoDetailDtoComponent
    ],
    exports: [
      PeriodoContratoComponent,
      DialogVerPeriodoContratoDetailDtoComponent
    ],
    providers: [
    ],
})

export class PeriodosContratoModule { }
