import { ServicioPropiedadDto } from "../servicio-propiedad/servicioPropiedadDto.model";

export class DetalleServicioPropiedadDto {
  public idServicioPropiedad?: number
  public periodoMes?: number
  public periodoAnio?: number
  public fechaVencimiento1?: string | null  = null
  public importeVencimiento1?: number | null  = null
  public fechaVencimiento2?: string | null  = null
  public importeVencimiento2?: number | null  = null
  public fechaVencimiento3?: string | null  = null
  public importeVencimiento3?: number | null  = null
  public fechaVencimiento4?: string | null  = null
  public importeVencimiento4?: number | null  = null
  public cuota?: number
  public pendientePago? = true
  public comision?: number = 0
  public periodo?: string
  public id_QuienPaga?: number
}
