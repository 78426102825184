import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateTipoContratoQuienPagaServicioRequest } from '@models/tipocontrato-quienpaga-servicio/createTipoContratoQuienPagaServicioRequest.model';
import { TipoContratoQuienPagaServicioDto } from '@models/tipocontrato-quienpaga-servicio/tipoContratoQuienPagaServicioDto.model';
import { UpdateTipoContratoQuienPagaServicioRequest } from '@models/tipocontrato-quienpaga-servicio/updateTipoContratoQuienPagaServicioRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class TipocontratoQuienPagaServicioService {

  constructor(
    private http: HttpClient
  ) { }

  create(createTipoContratoQuienPagaServicioRequest:CreateTipoContratoQuienPagaServicioRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/TipoContratoQuienPagaServicio/Create",createTipoContratoQuienPagaServicioRequest);
  }

  update(updateTipoContratoQuienPagaServicioRequest: UpdateTipoContratoQuienPagaServicioRequest): Observable<any> {
    return this.http.put<any>("/api/TipoContratoQuienPagaServicio/Update", updateTipoContratoQuienPagaServicioRequest);
  }

  getAll():Observable<TipoContratoQuienPagaServicioDto[]>
  {
    return  this.http.get<TipoContratoQuienPagaServicioDto[]>("/api/TipoContratoQuienPagaServicio/GetAll");
  }

  getById(id:number):Observable<TipoContratoQuienPagaServicioDto>
  {
    return  this.http.get<TipoContratoQuienPagaServicioDto>("/api/TipoContratoQuienPagaServicio/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/TipoContratoQuienPagaServicio/Delete?id=" + id);
    else
      return of(null)
  }
}
