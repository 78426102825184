import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DescargarComprobantesComponent } from "@views/comprobantes/descargar-comprobantes/descargar-comprobantes.component";
import { DialogComprobanteComponent } from "@views/comprobantes/dialog-comprobante/dialog-comprobante.component";
import { ListadoComprobantesRecibosComponent } from "@views/comprobantes/listado-comprobantes-recibos/listado-comprobantes-recibos.component";
import { ListadoComprobantesGenericosComponent } from "@views/comprobantes/listado-comprobantes-genericos/listado-comprobantes-genericos.component";
import { ListadoComprobantesComponent } from "@views/comprobantes/listado-comprobantes/listado-comprobantes.component";
import { DialogNuevoEmailComponent } from "@views/genericos/dialog-nuevo-email/dialog-nuevo-email.component";
import { BtnNuevoTipoComprobanteComponent } from "@views/tipos-comprobante/btn-nuevo-tipo-comprobante/btn-nuevo-tipo-comprobante.component";
import { DialogTipoComprobanteComponent } from "@views/tipos-comprobante/dialog-tipo-comprobante/dialog-tipo-comprobante.component";
import { TiposComprobanteComponent } from "@views/tipos-comprobante/tipos-comprobante/tipos-comprobante.component";
import { PersonasModule } from "./personas.module";
import { PropiedadesModule } from "./propiedades.module";
import { PropietariosModule } from "./propietarios.module";
import { SharedModule } from "./shared.module";
import { ListadoComprobantesLiquidacionesComponent } from "@views/comprobantes/listado-comprobantes-liquidaciones/listado-comprobantes-liquidaciones.component";
import { ListadoAcreditacionesComponent } from "@views/comprobantes/listado-acreditaciones/listado-acreditaciones.component";
import { PagosComprobantesModule } from "./pagos-comprobantes.module";
import { PagoscomprobantesService } from "@services/pagos-comprobantes/pagoscomprobantes.service";
import { HttpClient } from "@angular/common/http";
import { FormasPagoService } from "@services/formas-pago/formas-pago.service";



@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PersonasModule,
    PropietariosModule,
    PropiedadesModule
  ],
  declarations: [
    TiposComprobanteComponent,
    ListadoComprobantesGenericosComponent,
    DialogComprobanteComponent,
    DescargarComprobantesComponent,
    BtnNuevoTipoComprobanteComponent,
    DialogTipoComprobanteComponent,
    DialogNuevoEmailComponent,
    ListadoComprobantesComponent,
    ListadoComprobantesLiquidacionesComponent,
    ListadoComprobantesRecibosComponent,
    ListadoAcreditacionesComponent
  ],

  exports:[
    TiposComprobanteComponent,
    ListadoComprobantesGenericosComponent,
    DialogComprobanteComponent,
    DescargarComprobantesComponent,
    BtnNuevoTipoComprobanteComponent,
    DialogTipoComprobanteComponent,
    DialogNuevoEmailComponent,
    ListadoComprobantesRecibosComponent,
    ListadoComprobantesComponent,
    ListadoComprobantesLiquidacionesComponent,
    ListadoAcreditacionesComponent
  ],
  providers: [
    FormasPagoService,
    PagoscomprobantesService,
    HttpClient
  ]

})
export class ComprobantesModule { }
