import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChartOptions, ChartType } from 'chart.js';
import { truncateSync } from 'fs';
import { Color, Label, SingleDataSet, ThemeService } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { RangoFechasValores } from '@models/base/identificadores.model';
import { ContadoPagosPorFormaPagoDto } from '@models/dashboard/contadoPagosPorFormaPagoDto.model';
import { ContadorDetalleServicioPropiedadDto } from '@models/dashboard/contadorDetalleServicioPropiedadDto.model';
import { ContadorEntidadDto } from '@models/dashboard/contadorEntidadDto.model';
import { ContadorPagosDto } from '@models/dashboard/contadorPagosDto.model';
import { ChartDto } from '@models/dashboard/graficos/chartDto.model';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { GraficosService } from '@services/graficos/graficos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';


@Component({
  selector: 'app-dashboard-general',
  templateUrl: './dashboard-general.component.html',
  styleUrls: ['./dashboard-general.component.css'],
  providers: [DatePipe]
})
export class DashboardGeneralComponent implements OnInit {



  contadoresEntidades!: ContadorEntidadDto;
  contadorDetalleServicioPropiedadDto!: ContadorDetalleServicioPropiedadDto;
  contadorPagosDto!: ContadorPagosDto;
  formBuilder: FormBuilder = new FormBuilder;
  form: FormGroup;
  contadoPagosPorFormaPagoDto!: ContadoPagosPorFormaPagoDto;



  barChartOptions: ChartOptions = {
    responsive: true,
    legend: { position: 'left' },
    animation: {
      duration: 1000,
        easing: 'linear',
    }
  };
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'pie';
  barChartLegend = true;
  barChartPlugins = [];
  barChartData: SingleDataSet = [];
  lineChartColors: Color[] = [
    {
      borderColor: 'blue',
      backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';


  constructor(
    private dashboardService: DashboardService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private datepipe: DatePipe
  ) {


    this.form = this.formBuilder.group({
      FechaDesde: [new Date((new Date().getTime() - RangoFechasValores.Bimestral)), Validators.compose([Validators.required,])],
      FechaHasta: [new Date(new Date().getTime()), Validators.compose([Validators.required,])],
    })
  }

  ngOnInit(): void {
    this.getContadorEntidades()
    this.getContadorDetalleServicioPropiedad()

    let fechaDesde = this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;
    let fechaHasta = this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;
    // Ajustar la hora para la fecha desde
    fechaDesde = fechaDesde + ' 00:00:00';
    // Ajustar la hora para la fecha hasta
    fechaHasta = fechaHasta + ' 23:59:59';
    this.getContadorPagosPorFormaPago(fechaDesde, fechaHasta)
    this.getContadorPagos(fechaDesde, fechaHasta)

  }


  private getContadorEntidades() {
    this.spinner.show("spContadores")
    this.dashboardService.getContadorEntidades().subscribe(
      data => {
        this.spinner.hide("spContadores")
        this.contadoresEntidades = data
      },
      error => {
        this.spinner.hide("spContadores")
        this.snackBar.showError(error, "Error");
      })
  }

  private getContadorDetalleServicioPropiedad() {
    let fechaDesde = this.datepipe.transform(new Date().getTime(), 'yyyy-MM-dd') as string;
    let fechaHasta = this.datepipe.transform(new Date().getTime(), 'yyyy-MM-dd') as string;

    this.spinner.show("spGetContadorDetalleServicioPropiedad")
    this.dashboardService.getContadorDetalleServicioPropiedad(fechaDesde,fechaHasta).subscribe(
      data => {
        this.spinner.hide("spGetContadorDetalleServicioPropiedad")
        this.contadorDetalleServicioPropiedadDto = data
      },
      error => {
        this.spinner.hide("spGetContadorDetalleServicioPropiedad")
        this.snackBar.showError(error, "Error");
      })
  }

  filtrarPagos() {
    let fechaDesde = this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;
    let fechaHasta = this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;
    // Ajustar la hora para la fecha desde
    fechaDesde = fechaDesde + ' 00:00:00';
    // Ajustar la hora para la fecha hasta
    fechaHasta = fechaHasta + ' 23:59:59';
    this.getContadorPagosPorFormaPago(fechaDesde, fechaHasta)
    this.getContadorPagos(fechaDesde, fechaHasta)
  }

  private getContadorPagos(fechaDesde: string, fechaHasta: string) {
    this.spinner.show("spGetContadorPagos")
    this.dashboardService.getContadorPagos(fechaDesde, fechaHasta).subscribe(
      data => {
        this.spinner.hide("spGetContadorPagos")
        this.contadorPagosDto = data
      },
      error => {
        this.spinner.hide("spGetContadorPagos")
        this.snackBar.showError(error, "Error");
      })
  }

  private getContadorPagosPorFormaPago(fechaDesde: string, fechaHasta: string) {
    this.spinner.show("spContadoPagosPorFormaPagoDto")
    this.dashboardService.getContadorPagosPorFormaPago(fechaDesde, fechaHasta).subscribe(
      data => {
        this.spinner.hide("spContadoPagosPorFormaPagoDto")
        this.contadoPagosPorFormaPagoDto = data
        this.barChartLabels = ['Efectivo', 'Trasferencia', 'Tarjeta de crédito', 'Cheque propio', 'Cheque Diferido', 'Cheque Tercero', 'Tarjeta de débito','Depósito en banco','Monedero electrónico', 'Otro'];
        this.barChartData = [
          data.efectivo, data.trasferencia, data.tarjetaCredito, data.chequePropio, data.chequeDiferido, data.chequeTercero, data.tarjetaDebito, data.depositoEnBanco, data.monederoElectronico, data.otro]
      },
      error => {
        this.spinner.hide("spContadoPagosPorFormaPagoDto")
        this.snackBar.showError(error, "Error");
      })
  }

}
