import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { UpdateRequestBase } from '@models/base/updateRequestBase.model';
import { CancelVoucherResponse } from '@models/comprobantes/cancelVoucherResponse.model';
import { ComprobanteDetailDto } from '@models/comprobantes/comprobanteDetailDto.model';
import { CreateComprobanteReciboResponse } from '@models/comprobantes/createComprobanteReciboResponse.model';
import { CreateComprobanteRequest } from '@models/comprobantes/createComprobanteRequest.model';
import { IComprobantes } from './iComprobantes.model';

@Injectable({
  providedIn: 'root'
})
export class ComprobantesService implements IComprobantes{

  constructor(
    private http: HttpClient
  ) { }

/**
 * *Crear contrato
 * @param createComprobanteRequest
 * @returns
 */
  create(createComprobanteRequest: CreateComprobanteRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Comprobante/Create", createComprobanteRequest);
  }

  /**
   * *Actualizar contrato
   * @param updateRequestBase
   * @returns
   */
  update(updateRequestBase: UpdateRequestBase): Observable<any> {
    return this.http.put<UpdateRequestBase>("/api/Comprobante/Update", updateRequestBase);
  }

  /**
   * *Obtener todos los comprobante.
   * @returns
   */
  getAll(): Observable<ComprobanteDetailDto[]> {
    return this.http.get<ComprobanteDetailDto[]>("/api/Comprobante/GetAll");
  }

/**
 * *Obtener comprobante por Id de Comprobante.
 * @param id
 * @returns
 */
  getById(id: number): Observable<ComprobanteDetailDto> {
    return this.http.get<ComprobanteDetailDto>("/api/Comprobante/GetById?id=" + id);
  }

  /**
   * *Anular comprobante
   * @return Observable<CancelVoucherResponse>
   */
  cancelVoucher(id: number): Observable<CancelVoucherResponse> {
      return this.http.post<CancelVoucherResponse>("/api/Comprobante/CancelVoucher?IdComprobante=" + id,null);
  }

  /**
   * *Obtener Contratos segun filtros
   * @param fechaDesde Fecha desde
   * @param fechaHasta Fecha hasta
   * @param idTipoComprobante Tipo de Comprobante
   * @param idEstadoComprobante Estado Comprobante
   * @param numeroContrato Numero de contrato
   * @param idPropiedad Id de Propiedad
   * @param idInquilino Id de Inquilino
   * @param idPropietario Id de Propietario
   * @returns Observable<ComprobanteDetailDto[]>
   */
  getAllByFilter(fechaDesde: string, fechaHasta: string, idTipoComprobante: number | null, idEstadoComprobante: number | null, numeroContrato: number | null, idPropiedad: number | null, idInquilino: number | null,  idPropietario: number | null): Observable<ComprobanteDetailDto[]> {

    let filtro = ""

    if (fechaDesde != null && fechaDesde != "" && fechaHasta != null && fechaHasta != "")
      filtro += "?fechaDesde=" + fechaDesde + "&fechaHasta=" + fechaHasta
    if (numeroContrato != null && numeroContrato != 0)
      filtro += "&numeroContrato=" + numeroContrato
    if (idInquilino != null && idInquilino != 0)
      filtro += "&idInquilino=" + idInquilino
    if (idPropiedad != null && idPropiedad != 0)
      filtro += "&idPropiedad=" + idPropiedad
    if (idTipoComprobante != null && idTipoComprobante != 0)
      filtro += "&idTipoComprobante=" + idTipoComprobante
    if (idEstadoComprobante != null && idEstadoComprobante != 0)
      filtro += "&idEstadoComprobante=" + idEstadoComprobante
    if (idPropietario != null && idPropietario != 0)
      filtro += "&idPropietario=" + idPropietario

    return this.http.get<ComprobanteDetailDto[]>("/api/Comprobante/GetAllByFilter" + filtro);
  }

  /**
 * *Crear comprobante de tipo recibo
 * !Solo se utiliza para movimiento de tipo Ingreso y Egreso de Caja. Ya que no generan PDF
 * @param createComprobanteRequest CreateComprobanteRequest
 */
createComprobanteRecibo(createComprobanteRequest: CreateComprobanteRequest):  Observable<CreateComprobanteReciboResponse>{
  return this.http.post<CreateComprobanteReciboResponse>("/api/Comprobante/CreateComprobanteRecibo", createComprobanteRequest);
}

}
