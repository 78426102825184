import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared.module';
import { ChartsModule } from 'ng2-charts';
import { InquilinosModule } from './inquilinos.module';
import { NotificacionesModule } from './notificaciones.module';
import { PersonasModule } from './personas.module';
import { PropiedadesModule } from './propiedades.module';
import { DashboardContratosComponent } from '../views/dashboard/dashboard-contratos/dashboard-contratos.component';
import { DashboardPropiedadesComponent } from '../views/dashboard/dashboard-propiedades/dashboard-propiedades.component';
import { DashboardGeneralComponent } from '../views/dashboard/dashboard-general/dashboard-general.component';
import { DashboardComponent } from '../views/dashboard/dashboard/dashboard.component';
import { DashboardCajaComponent } from '../views/dashboard/dashboard-caja/dashboard-caja/dashboard-caja.component';
import { ContratosModule } from './contratos.module';
import { DashboardClienteComponent } from '@views/dashboard/dashboard-cliente/dashboard-cliente.component';
import { MovimientoModule } from './movimiento.module';
//Charts



@NgModule({
  imports:[
      CommonModule,
      SharedModule,
      ChartsModule,
      PersonasModule,
      PropiedadesModule,
      InquilinosModule,
      ContratosModule,
      NotificacionesModule,
      MovimientoModule
  ],
  declarations:[
    //Chart
    DashboardComponent,
    DashboardClienteComponent,
    DashboardContratosComponent,
    DashboardPropiedadesComponent,
    DashboardGeneralComponent,
    DashboardCajaComponent
  ],
  exports:[
    DashboardComponent,
    DashboardClienteComponent,
    DashboardContratosComponent,
    DashboardGeneralComponent,
    DashboardCajaComponent
  ]
})
export class DashboardModule { }
