import { DatePipe } from '@angular/common';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, EstadosPago, FormasPagos, ParametrosConfiguracion, TiposMonedas } from '@models/base/identificadores.model';
import { PersonaCuentaContableDto } from '@models/cuentas-contable-persona/personaCuentaContableDto.model';
import { PagoDto } from '@models/pago/pagoDto.model';
import { ParametroSistemaDto } from '@models/parametros-sistema/parametroSistemaDto.model';
import { TipoMonedaDto } from '@models/tipo-moneda/tipoMonedaDto.model';
import { FormasPagoService } from '@services/formas-pago/formas-pago.service';
import { ParametrosSistemaService } from '@services/parametro-sistema/parametros-sistema.service';
import { PersonasCuentasContableService } from '@services/personas-cuentas-contable/personas-cuentas-contable.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposMonedaService } from '@services/tipos-moneda/tipos-moneda.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-nuevo-pago',
  templateUrl: './dialog-nuevo-pago.component.html',
  styleUrls: ['./dialog-nuevo-pago.component.css'],
  providers: [DatePipe]
})
export class DialogNuevoPagoComponent implements OnInit {
  form: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  lstTiposMoneda!: TipoMonedaDto[];
  cuentaContableOrigen!: BaseDto
  idCuentaContableDestino!: number | null;
  ccAsignada: boolean = false;
  banco: any;
  lstFormasPago: BaseDto[] = [];
  get f() { return this.form.controls; }

  constructor(
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<DialogNuevoPagoComponent>,
    public formaPagoService: FormasPagoService,
    private snackBar: SnackBarService,
    private datePipe: DatePipe,
    private tiposMonedaService: TiposMonedaService,
    private parametrosSistemaService: ParametrosSistemaService,
    private personasCuentasContableService: PersonasCuentasContableService,
    @Optional() @Inject(MAT_DIALOG_DATA) public datos: any
  ) {

    this.form = this.formBuilder.group({
      Importe: ["", Validators.compose([Validators.required,])],
      FechaPago: [new Date(), Validators.compose([Validators.required,])],
      FechaAcreditacion: [new Date()],
      Descripcion: [""],
      FormasPago: ["", Validators.compose([Validators.required,])],
      TiposMoneda: ["", Validators.compose([Validators.required,])],
    })
  }


  ngOnInit(): void {
    this.getAllTiposMoneda();
    this.getAllFormasPago();
    this.getCuentaContablePersona(this.datos.idPersona)
    this.form.controls["TiposMoneda"].setValue(TiposMonedas.Pesos)
    this.form.controls["Importe"].setValue(this.datos.saldo <= 0 ? this.datos.saldo * (-1) : this.datos.saldo)

  }

  onSubmit() {
    const pagoDto = new PagoDto
    pagoDto.fechaPago = !isFalsy(this.form.controls["FechaPago"].value) ? this.datePipe.transform(this.form.controls["FechaPago"].value, 'yyyy-MM-dd') : null
    pagoDto.importe = !isFalsy(this.form.controls["Importe"].value) ? parseFloat(this.form.controls["Importe"].value) : 0
    pagoDto.fechaAcreditacion = this.datePipe.transform(this.form.controls["FechaAcreditacion"].value, 'yyyy-MM-dd')
    pagoDto.descripcion = !isFalsy(this.form.controls["Descripcion"].value) ? this.form.controls["Descripcion"].value : null
    let formaPago = this.form.controls["FormasPago"].value;
    pagoDto.idFormaPago = formaPago.id
    pagoDto.formaPago = formaPago
    pagoDto.idEstadoPago = EstadosPago.Completo
    pagoDto.idTipoMoneda = !isFalsy(this.form.controls["TiposMoneda"].value) ? this.form.controls["TiposMoneda"].value : null

    pagoDto.idCuentaContableDestino = this.idCuentaContableDestino
    pagoDto.idCuentaContableOrigen = !isFalsy(this.cuentaContableOrigen) ? this.cuentaContableOrigen.id : null
    this.dialogRef.close({ event: AccionesDialog.Agregar, data: pagoDto });
  }

  onCancel() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  selectBanco(event: any) {
    this.banco = event;
    var descripcion = this.form.controls["Descripcion"].value
    if (!isFalsy(descripcion))
      descripcion += "\n Banco: " + this.banco.descripcion
    else {
      descripcion = "";
      descripcion += "Banco: " + this.banco.descripcion
    }


    this.form.controls["Descripcion"].setValue(descripcion)
  }


  getAllFormasPago() {
    this.spinner.show("spBusquedaFormaPago")
    this.formaPagoService.getAll().subscribe(
      data => {
        this.spinner.hide("spBusquedaFormaPago")
        this.lstFormasPago = data
      },
      error => {
        this.spinner.hide("spBusquedaFormaPago")
        this.snackBar.showError(error, "Error");
      }
    )
  }

  getAllTiposMoneda() {
    this.spinner.show("spBusquedaTiposMoneda")
    this.tiposMonedaService.getAll().subscribe(
      data => {
        this.spinner.hide("spBusquedaTiposMoneda")
        this.lstTiposMoneda = data
      },
      error => {
        this.spinner.hide("spBusquedaTiposMoneda")
        this.snackBar.showError(error, "Error");
      }
    )
  }

  getCuentaContablePersona(idPersona: number) {
    this.personasCuentasContableService.getAllByIdPersona(idPersona).subscribe(
      data => {
        data.forEach(element => {
          if (element.activo) {
            this.ccAsignada = true
            this.cuentaContableOrigen = new BaseDto();
            this.cuentaContableOrigen.id = element.cuentaContable.id
            this.cuentaContableOrigen.descripcion = element.cuentaContable.descripcion
          }
          else {
            this.ccAsignada = false
          }
        });
      },
      error => {
        this.snackBar.showError(error, "Error");
      }
    )
  }


  getCuentaContableDefecto() {
    this.parametrosSistemaService.getValueByName("CuentaContableDefecto").subscribe(
      data => {
        this.idCuentaContableDestino = parseInt(data.valor)
      },
      error => {
        this.snackBar.showError(error, "Error");
      }
    )
  }


  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}
