import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contrato',
  templateUrl: './contrato.component.html',
  styleUrls: ['./contrato.component.css']
})
export class ContratoComponent implements OnInit,OnDestroy {

   idContrato!: number
  constructor(
    private aRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.spinner.show('spContratoMain');
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idContrato = Number(routeParams.get('id'));
  }

  ngOnDestroy(){

  }
}
