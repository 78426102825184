import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoTemporalDto } from '@models/archivos-temporales/archivoTemporalDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ArchivosTemporalesService } from '@services/archivos-temporales/archivos-temporales.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogDeleteComponent } from 'src/app/views/genericos/dialog-delete/dialog-delete.component';
import { isFalsy } from 'utility-types';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-listado-archivos-temporales',
  templateUrl: './listado-archivos-temporales.component.html',
  styleUrls: ['./listado-archivos-temporales.component.css']
})
export class ListadoArchivosTemporalesComponent implements OnInit, AfterViewInit {

  data = false
  dataSource = new MatTableDataSource<ArchivoTemporalDto>();
  @Input() idContrato!: number
  displayedColumns: string[] = [];
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }


  constructor(
    private spinner: NgxSpinnerService,
    private archivosTemporalesService: ArchivosTemporalesService,
    private snackBar: SnackBarService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['nombreArchivo', 'contrato', 'fechaGeneracion', 'accion'] :
        ['nombreArchivo', 'contrato', 'fechaGeneracion', 'tipoComprobante', 'falloEnvioEmail', 'accion'];
    });

  }

  ngOnInit(): void {
    if (!isFalsy(this.idContrato))
      this.getAllByIdContrato(this.idContrato)
    else
      this.getAll()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }


  getAll() {
    this.spinner.show();
    this.archivosTemporalesService.getAll().subscribe
      (
        data => {
          this.spinner.hide();
          if (data.length >= 1) {
            this.dataSource.data = data.sort((a, b) => b.id.toString().localeCompare(a.id.toString()))
            this.data = this.dataSource.data.length >= 1 ? true : false
          }
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getAllByIdContrato(idContrato: number) {
    this.spinner.show();
    this.archivosTemporalesService.getAllByIdContrato(idContrato).subscribe
      (
        data => {
          this.spinner.hide();
          this.dataSource.data = data.sort((b, a) => a.fechaGeneracion.toString().localeCompare(b.fechaGeneracion.toString()))
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      )
  }

  delete(id: number) {
    this.spinner.show();
    this.archivosTemporalesService.delete(id).subscribe
      (
        data => {
          this.spinner.hide();
          this.dataSource.data = this.dataSource.data.filter((value: { id: number; }, key: any) => {
            return value.id != id;
          });
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getFileContentById(archivoTemporalDto: ArchivoTemporalDto): string {
    let contenido = ""
    this.spinner.show();
    this.archivosTemporalesService.getFileContentById(archivoTemporalDto.id).subscribe
      (
        data => {
          this.spinner.hide();
          if (!isFalsy(data.content)) {
            archivoTemporalDto.contenidoArchivo = data.content
            this.generarDocumentoPdf(archivoTemporalDto)
          }
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      )
    return contenido
  }

  openDialog(element: ArchivoTemporalDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = element

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.delete(result.data);
      }
    });
  }

  descargarArchivo(archivoTemporalDto: ArchivoTemporalDto) {
    this.getFileContentById(archivoTemporalDto)
  }

  generarDocumentoPdf(archivoTemporalDto: ArchivoTemporalDto) {
    const link = document.createElement("a");
    link.href = "data:application/pdf;base64," + archivoTemporalDto.contenidoArchivo;
    link.download = archivoTemporalDto.nombreArchivo.trim() + ".pdf"
    link.click();
  }
}

