<div class="position-relative">
  <div class="row content">
    <div class="col-sm-2">
      <app-nav-panel-persona></app-nav-panel-persona>
    </div>

    <div class="col-sm-10">
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel [disabled]="false" [expanded]="true" [hideToggle]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>Datos Inquilino/a</b>
            </mat-panel-title>
            <mat-icon style="margin-right: 10px">vpn_key</mat-icon>
            <mat-panel-description> </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <ngx-spinner name="spCargarElemento" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
              type="ball-atom" [fullScreen]="false"></ngx-spinner>
            <app-agrega-persona-inquilino (nuevoInquilino)="nuevoInquilino($event)"
              *ngIf="personaDetailDto && personaDetailDto.esInquilino == false"></app-agrega-persona-inquilino>
            <div class="row">
              <div class="col-lg-10">
                <h3 *ngIf="personaDetailDto && personaDetailDto.esInquilino == true">
                  Esta persona ya es un inquilino/a
                </h3>
                <app-estado-garantias-pendientes></app-estado-garantias-pendientes>
              </div>
              <div *ngIf="personaDetailDto && personaDetailDto.esInquilino == true" class="col-lg-2">
                <div *appRole="['Inquilinos.Eliminar']" >
                  <button mat-stroked-button *ngIf="personaDetailDto" color="warn" class="float-right"
                  (click)="personaDetailDto.id ? openDialogDelete('Eliminar',personaDetailDto.id) : null">
                  <mat-icon>delete</mat-icon>
                  Eliminar
                </button>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
      <br />
      <div *ngIf="personaDetailDto && personaDetailDto.esInquilino == true">
        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel [disabled]="false" [expanded]="true" [hideToggle]="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b>Asignar garantia a inquilino/a</b>
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <app-asignar-garantia-a-inquilino
                (asignaGarantia)="asignaGarantia($event)"></app-asignar-garantia-a-inquilino>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
        <br />
        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel [disabled]="false" [expanded]="true" [hideToggle]="false"
            *ngIf="personaDetailDto && personaDetailDto.esInquilino == true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b>Mis garantias como inquilino/a</b>
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>

              <app-garantias-inquilino *ngIf="personaDetailDto && personaDetailDto.id"                 [asignaGarantia]="actualizaGrantias" ></app-garantias-inquilino>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
