
export class MovimientoBasicDto {
  public id? : number;
  public descripcion? : string | null;
  public idTipoMovimiento? : number;
  public importe? : number;
  public idBonificacion? : number | null;
  public idPeriodoContrato? : number | null;
  public periodo? : number;
  public periodoMes? : number;
  public periodoAnio? : number;
  public anulado: boolean = false;
  public idMovimientoRef: number | null = null
  public fechaModificacion!: string
  public fechaCreacion!: string
}
