<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title><strong>Generación de Liquidaciones</strong></h1>
<mat-divider></mat-divider>
<br>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true" class="position-relative">
  <div class="row">
    <div class="col-lg-5">
      <mat-form-field class="example-full-width">
        <mat-label>Buscar</mat-label>
        <input matInput type="search" (keyup)="applyFilter($event)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="col-lg-5">
      <mat-form-field class="full-width">
        <mat-label>Tipo de Liquidación</mat-label>
        <mat-select [(value)]="tipoLiquidacion">
          <mat-option value="agrupada">Agrupada por Propiedades</mat-option>
          <mat-option value="simple">Propiedades individuales</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <ngx-spinner name="spPropietarios" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom"
    [fullScreen]="false">
  </ngx-spinner>
  <table mat-table [dataSource]="dataSource" [hidden]=!data matSort matSortStart="asc" class="mat-elevation-z8">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Descripcion Column -->
    <ng-container matColumnDef="descripcion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Propietario </th>
      <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
    </ng-container>

    <!-- Documento Column -->
    <ng-container matColumnDef="nroDocumento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
      <td mat-cell *matCellDef="let element"> {{element.nroDocumento}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>
  <div class="no-data-table" [hidden]=data>
    <span class="with-icon">
      <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
    </span>
  </div>
  <br>
  <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="5"
    [pageSizeOptions]="[5, 10, 20,50,100,200]">
  </mat-paginator>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
  <button mat-raised-button *appRole="['Contratos.VisualizaLiquidacion']" (click)="visualizaLiquidacion()"
    [disabled]="selection.selected.length == 0 || procesando==true" color="primary">
    <mat-icon>change_circle</mat-icon> Visualiza liquidaciones
  </button>
  <button mat-raised-button *appRole="['Contratos.GeneraLiquidacion']" (click)="generarLiquidacion()"
    [disabled]="selection.selected.length == 0 || procesando==true" color="primary">
    <mat-icon>change_circle</mat-icon> Iniciar Proceso
  </button>
  <button mat-button (click)="closeDialog()" mat-dialog-close color="warn">Cancelar</button>
</div>
