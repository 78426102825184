import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { PeriodoContratoLiquidacionPropietarioDto } from '@models/periodos-contrato/periodoContratoLiquidacionPropietarioDto.model';
import { MovimientoDetailDto } from '@models/movimiento/movimientoDetailDto.model';
import { CreateSettlementResponse } from '@models/propietarios/createSettlementResponse.model';
import { SettlementByPropertyDto } from '@models/propietarios/settlementByPropertyDto.model';
import { SettlementContractDto } from '@models/propietarios/settlementContractDto.model';
import { SettlementDto } from '@models/propietarios/settlementDto.model';
import { DetalleServicioPropiedadDetailDto } from '@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadDetailDto.model';
import { LoginService } from '@services/login/login.service';
import { PropietariosService } from '@services/propietarios/propietarios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { DialogLiquidacionesGeneradasComponent } from '../dialog-liquidaciones-generadas/dialog-liquidaciones-generadas.component';

export class Resumen {
  public id!: number
  public concepto!: string
  public importeDebe!: number
  public importeHaber!: number
  public descripcion!: string
}
@Component({
  selector: 'app-dialog-visualiza-liquidacion',
  templateUrl: './dialog-visualiza-liquidacion.component.html',
  styleUrls: ['./dialog-visualiza-liquidacion.component.css']
})
export class DialogVisualizaLiquidacionComponent implements OnInit {

  settlementByPropertyDto!: SettlementByPropertyDto[]
  contratos!: SettlementContractDto
  displayedColumnsMovimientos: string[] = [];
  displayedColumnsPeriodos: string[] = [];
  displayedColumnsDetallesServicios: string[] = [];
  idContrato!: number
  settlementResponse: any;
  esLiquidacionGenerada: boolean = false;
  contratoDetailDto!: ContratoDetailDto;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpointObserver: BreakpointObserver,
    private loginService: LoginService,
    private propietariosService: PropietariosService,
    private toastService: HotToastService,
    private dialog: MatDialog,
    private snackBar: SnackBarService,

  ) {
    this.settlementByPropertyDto = this.data.settlementResponse.settlementsByProperty as SettlementByPropertyDto[]
    if (this.settlementByPropertyDto.length >= 1) {
      this.settlementByPropertyDto.forEach(contratos => {
        if (contratos.contratos.length >= 1) {
          this.ajustaImportes(this.settlementByPropertyDto)
          this.contratoDetailDto = this.data.contratoDetailDto as ContratoDetailDto
          if (this.contratoDetailDto)
            this.settlementByPropertyDto.filter(x => x.contratos.forEach(x => x.contrato.numeroContrato == this.contratoDetailDto.numeroContrato))
        }
      });
    }


    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumnsMovimientos = result.matches ?
        ['mov_periodo', 'mov_tipoMovimiento', 'mov_importe'] :
        ['mov_periodo', 'mov_tipoMovimiento', 'mov_descripcion', 'mov_importe'];
    });

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumnsPeriodos = result.matches ?
        ['periodo', 'importeAlquiler',  'importePunitorio', 'importeTotal'] :
        ['periodo', 'importeAlquiler',  'importePunitorio', 'importeTotal'];
    });

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumnsDetallesServicios = result.matches ?
        ['periodo', 'servicio', 'importe'] :
        ['periodo', 'servicio', 'importe'];
    });
  }


  ngOnInit(): void {
  }

  getTotalServicioPropiedad(detalleServicioPropiedadDetailDto: any) {
    let dspdto = detalleServicioPropiedadDetailDto as DetalleServicioPropiedadDetailDto[]
    let valor = 0
    dspdto.forEach(element => {
      valor += element.importeATenerEnCuenta
    });
    return valor
  }

  getTotalPeriodosContrato(periodosPeriodoContratoLiquidacionPropietarioDtoContrato: any) {
    let periodosContrato = periodosPeriodoContratoLiquidacionPropietarioDtoContrato as PeriodoContratoLiquidacionPropietarioDto[]
    let valor = 0
    periodosContrato.forEach(element => {
      valor += element.importeAlquiler
    });
    return valor
  }

  getTotalImporteComisionPeriodo(periodosPeriodoContratoLiquidacionPropietarioDtoContrato: any) {
    let periodosContrato = periodosPeriodoContratoLiquidacionPropietarioDtoContrato as PeriodoContratoLiquidacionPropietarioDto[]
    let valor = 0
    periodosContrato.forEach(element => {
      valor += element.comisionInmobiliara
    });
    return valor
  }

  getTotalImporPunitorio(periodosPeriodoContratoLiquidacionPropietarioDtoContrato: any) {
    let periodosContrato = periodosPeriodoContratoLiquidacionPropietarioDtoContrato as PeriodoContratoLiquidacionPropietarioDto[]
    let valor = 0
    periodosContrato.forEach(element => {
      valor += element.importePunitorio
    });
    return valor
  }

  getTotalImporte(periodosPeriodoContratoLiquidacionPropietarioDtoContrato: any) {
    let periodosContrato = periodosPeriodoContratoLiquidacionPropietarioDtoContrato as PeriodoContratoLiquidacionPropietarioDto[]
    let valor = 0
    periodosContrato.forEach(element => {
      valor += element.importeAlquiler + element.importePunitorio - element.comisionInmobiliara
    });
    return valor
  }



  getTotalMovimientos(movimientoDetailDto: any) {
    let movimientos = movimientoDetailDto as MovimientoDetailDto[]
    let valor = 0
    movimientos.forEach(element => {
      valor += element.importe
    });
    return valor
  }

  getImporteLiquidacion(contratos: any): number {
    let totalDetallesServiciosPropiedad = 0
    let totalPeriodos = 0
    let totalMovimientos = 0
    let totalComisiones = 0
    let total = 0
    contratos.settlements.forEach((settlement: SettlementDto) => {
      totalDetallesServiciosPropiedad = settlement.detallesServiciosPropiedad.reduce((sum, current) => sum + current.importeATenerEnCuenta, 0);
      totalPeriodos = settlement.periodosContrato.reduce((sum, current) => sum + current.importeAlquiler, 0);
      totalComisiones = settlement.periodosContrato.reduce((sum, current) => sum + current.comisionInmobiliara, 0);
      totalMovimientos = this.getTotalMovimientos(settlement.movimientos)

    });

    total = (totalDetallesServiciosPropiedad * (-1)) + totalPeriodos + totalMovimientos
    return total

  }

  agregarLiquidacion() {
    this.createSettlement(this.contratoDetailDto.id)
  }

  createSettlement(idContrato: number) {
    this.esLiquidacionGenerada = true
    let usuarioDto = this.loginService.getCurrentUser()
    this.propietariosService.createSettlement([], usuarioDto.id, idContrato, false)
      .pipe(
        this.toastService.observe(
          {
            loading: 'Por favor, aguarde',
            success: (s) => 'Proceso finalizado con exito. ',
            error: (e) => e,
          }
        ))
      .subscribe(
        data => {
          this.toastService.close()
          if (data.liquidacionPorContrato.length != 0)
            this.openDialogLiquidacionesGeneradasComponent(data)
          else
            this.snackBar.showInfo("No se registraron datos.", "Info");

        });
  }


  openDialogLiquidacionesGeneradasComponent(createSettlementResponse: CreateSettlementResponse) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%";
    dialogConfig.autoFocus = false;
    dialogConfig.data = createSettlementResponse

    const dialogRef = this.dialog.open(DialogLiquidacionesGeneradasComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

  ajustaImportes(settlementByPropertyDto: SettlementByPropertyDto[]) {
    settlementByPropertyDto.forEach(element => {
      element.contratos.forEach(settlements => {
        settlements.settlements.forEach(settlementDto => {
          settlementDto.movimientos.forEach(movimiento => {
            if (movimiento.tipoMovimiento.resta)
              movimiento.importe = movimiento.importe * (-1)
          });
        });
      });
    });
  }

}
