import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseDto } from '@models/base/baseDto.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-buscar-persona-inquilino',
  templateUrl: './buscar-persona-inquilino.component.html',
  styleUrls: ['./buscar-persona-inquilino.component.css']
})
export class BuscarPersonaInquilinoComponent implements OnInit {

  inquilinoSelect: EventEmitter<BaseDto> = new EventEmitter()
  public filteredInquilinos!: Observable<InquilinoDto[]>;
  inquilinos: InquilinoDto[] = []
  public entityControl = new FormControl();
  public chkConContrato = new FormControl();
  @Output() eventEntity = new EventEmitter<InquilinoDto>();
  @Input() inquilinoEntrada!: InquilinoDto | null;
  @Input() idPersonaInput: number = 0;
  @Input() disable: boolean = false
  @Input() width = "full-width"
  @Input() esRequerido: boolean = false;
  entitySelect!: InquilinoDto | null;

  constructor(
    private inquilinoService: InquilinosService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private personasService: PersonasService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    var filtroConContrato = this.chkConContrato.value
    if (filtroConContrato)
      this.GetAllInquilinosWithoutCurrentContrat();
    else
      this.getAllPersonasInquilino()

    if (!isFalsy(this.idPersonaInput)) {
      this.getPersonaById(this.idPersonaInput)
      this.entityControl.setValue(!isFalsy(this.inquilinoEntrada) ? this.inquilinoEntrada.persona.descripcion : "")
    }
  }

  filtroConContrato(event: any) {
    if (event)
      this.GetAllInquilinosWithoutCurrentContrat();
    else
      this.getAllPersonasInquilino()
  }

  selectValue(event: any) {
    if (isFalsy(event)) {
      this.entitySelect = null
      this.entityControl.setValue("")
    }
    this.eventEntity.emit(event)
  }


  ngOnChanges(): void {
    if (!isFalsy(this.idPersonaInput)) {
      this.getPersonaById(this.idPersonaInput)
    }

    this.entityControl.setValue(!isFalsy(this.inquilinoEntrada) ? this.inquilinoEntrada.persona.descripcion : "")

    if (this.esRequerido) {
      // Si es requerido, se agrega la validación de requerido
      this.entityControl.setValidators([Validators.required]);
    } else {
      // Si no es requerido, se eliminan las validaciones
      this.entityControl.clearValidators();
    }
    this.entityControl.updateValueAndValidity(); // Actualizar el estado del control
  }

  findOption(val: string) {
    this.spinner.show("spBusquedaInquilino")
    for (let i = 0; i < this.inquilinos.length; i++) {
      if (this.inquilinos[i].persona.descripcion.toUpperCase() == val.toUpperCase()) {
        this.eventEntity.emit(this.inquilinos[i])
        this.entitySelect = this.inquilinos[i]
      }
    }
    this.spinner.hide("spBusquedaInquilino")
    return this.inquilinos.filter(inquilino => inquilino.persona.descripcion.toLowerCase().includes(val.toLowerCase()));
  }


  getAllPersonasInquilino() {
    this.spinner.show("spBusquedaInquilino")
    this.inquilinoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaInquilino")
          this.inquilinos = data.sort((a, b) => a.persona.descripcion.localeCompare(b.persona.descripcion));
          this.filteredInquilinos = this.entityControl.valueChanges.pipe(
            startWith(''),
            map((term: any) => this.findOption(term))
          );
        },
        error => {
          this.spinner.hide("spBusquedaInquilino")
          this.snackBar.showError(error, "Error");
        });
  }

  getPersonaById(idPersona: any) {
    this.inquilinoService.getInquilinoByIdPersona(idPersona)
      .subscribe(
        data => {
          if (data.persona) {
            let personaInquilinoDto = new InquilinoDto
            let persona = new BaseDto
            persona.id = data.persona.id
            persona.descripcion = data.persona.descripcion
            personaInquilinoDto.persona = persona
            personaInquilinoDto.id = data.id
            this.inquilinoEntrada = personaInquilinoDto
          }

        },
        error => {
          this.snackBar.showError(error, "Error");
        }
      );
  }

  editarPersona(idPersona: number) {
    this.getPersonaByIdAndRoute(idPersona)
  }

  getPersonaByIdAndRoute(idPersona: number) {
    this.spinner.show("spBusquedaInquilino")
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaInquilino")
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spBusquedaInquilino")
          this.snackBar.showError(error, "Error");
        });
  }

  GetAllInquilinosWithoutCurrentContrat() {
    this.spinner.show("spBusquedaInquilino")
    this.inquilinoService.GetAllInquilinosWithoutCurrentContrat()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaInquilino")
          this.inquilinos = data
          this.filteredInquilinos = this.entityControl.valueChanges.pipe(
            startWith(''),
            map((term: any) => this.findOption(term))
          );
        },
        error => {
          this.spinner.hide("spBusquedaInquilino")
          this.snackBar.showError(error, "Error");
        });
  }

  trackByItems(index: number, item: any): any { return item; }
}
