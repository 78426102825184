<div *appRole="['Documentos.Adjuntar']">
  <ngx-spinner name="spinerArchivo" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
  </ngx-spinner>
  <div style="padding: 1%;">
      <input type="file" hidden class="file-input" (change)="fileChangeEvent($event)" #fileUpload> {{archivoDto.descripcion || "Aún no se ha subido ningún archivo."}}
      <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()" #tooltip="matTooltip" matTooltipPosition="right" matTooltip="Adjuntar archivo.">
              <mat-icon>attach_file</mat-icon>
              </button>

      <button mat-icon-button #tooltip="matTooltip" matTooltipPosition="above" matTooltip="35 MB Max. Solo jpg, jpge, pdf">
      <mat-icon color="primary">info</mat-icon>
      </button>
      <button mat-icon-button (click)="cancelUpload()" *ngIf="fileUploadState" diameter="1" color="primary" #tooltip="matTooltip" matTooltipPosition="right" matTooltip="Eliminar archivo." aria-label="Example icon button with a home icon">
              <mat-icon color="warn">delete_forever</mat-icon>
          </button>
      <button mat-icon-button *ngIf="fileUploadState" #tooltip="matTooltip" matTooltipPosition="right" matTooltip="Archivo cargado con exito.">
              <mat-icon color="primary">check_circle</mat-icon>
          </button>
      <button mat-icon-button *ngIf="fileError != ''" #tooltip="matTooltip" matTooltipPosition="right" matTooltip={{fileError}}>
              <mat-icon color="warn">error</mat-icon>
          </button>
  </div>
</div>
