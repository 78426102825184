import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GarantiaDto } from '@models/garantia/garantiaDto.model';
import { GarantiaSummaryDto } from '@models/garantia/garantiaSummaryDto.model';
import { GarantiasService } from '@services/garantias/garantias.service';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { MatTableDataSource } from '@angular/material/table';
import { PersonasService } from '@services/personas/personas.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { GarantiasInquilinosService } from '@services/garantias-inquilinos/garantias-inquilino.service';
import { DisableGarantiaRequest } from '@models/garantia/disableGarantiaRequest.model';
import { EnableGarantiaRequest } from '@models/garantia/enableGarantiaRequest.model';
@Component({
  selector: 'app-garantias-inquilino',
  templateUrl: './garantias-inquilino.component.html',
  styleUrls: ['./garantias-inquilino.component.css'],

})
export class GarantiasInquilinoComponent implements OnInit {
  data = false

  @Output() cambioEstado = new EventEmitter<number>();
  @Input() asignaGarantia: number = 0
  dataSource = new MatTableDataSource<GarantiaSummaryDto>();
  displayedColumns: string[] = [];
  myTrackById: any;
  personaDetailDto!: PersonaDetailDto
  idInquilino!: number;
  inquilinoDto!: InquilinoDto;

  constructor(
    private router: Router,
    private garantiaService: GarantiasService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private inquilinosService: InquilinosService,
    private personasService: PersonasService,
    private breakpointObserver: BreakpointObserver,
    private garantiasInquilinosService: GarantiasInquilinosService
  ) {

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["persona", "tipoGarantia", "validador", "accion"] :
        ["persona", "tipoGarantia", "nroFolio", "razonSocial", "cuitCuil", "sueldo", "validador", "accion"];
    });
  }

  ngOnInit(): void {
    this.personaDetailDto = this.personasService.getPersona()

     if (this.personaDetailDto && this.personaDetailDto.esInquilino === true)
       this.getInquilinoByIdPersona(this.personaDetailDto.id)
  }


  ngOnChanges() {
    if (this.asignaGarantia != 0 && this.personaDetailDto) {
      this.personaDetailDto = this.personasService.getPersona()
      this.getInquilinoByIdPersona(this.personaDetailDto.id)
    }
  }

  desasignarGarantia(garantiaSummaryDto: GarantiaSummaryDto) {
    if (garantiaSummaryDto)
      this.getAllGarantiasInquilinosByIdGarantia(garantiaSummaryDto.id)
  }

  deleteGarantiaInquilino(id: number) {
    this.spinner.show("spgarantiaInquilino")
    this.garantiasInquilinosService.delete(id)
      .subscribe(
        data => {
          this.snackBar.showSuccess("Garantia quitada correctamente.", "Exito");
          this.spinner.hide("spgarantiaInquilino")
          if (this.personaDetailDto)
            this.getAllGarantias(this.personaDetailDto.id)
        }, error => {
          this.spinner.hide("spgarantiaInquilino")
          this.snackBar.showError(error, "Error");
        }
      )
  }



  getAllGarantias(idPersona: number) {
    this.spinner.show("spgarantiaInquilino")
    this.garantiaService.getAllGarantiasByInquilino(idPersona)
      .subscribe(
        data => {
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.spinner.hide("spgarantiaInquilino")
        }, error => {
          this.spinner.hide("spgarantiaInquilino")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getAllGarantiasInquilinosByIdGarantia(idGarantia: number) {
    this.spinner.show("spgarantiaInquilino")
    this.garantiasInquilinosService.getAllGarantiasInquilinosByIdGarantia(idGarantia)
      .subscribe(
        data => {
          data = data.filter(x => x.inquilino && x.inquilino.id === this.idInquilino);
          data.forEach(element => {
            this.deleteGarantiaInquilino(element.id)
          });
          this.spinner.hide("spgarantiaInquilino")
        }, error => {
          this.spinner.hide("spgarantiaInquilino")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getInquilinoByIdPersona(idPersona: any) {
    this.spinner.show("spgarantiaInquilino")
    this.inquilinosService.getInquilinoByIdPersona(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spgarantiaInquilino")
          this.idInquilino = data.id
           if (data && data.id > 0) {
             this.cambioEstado.emit(this.idInquilino)
             this.inquilinosService.sendInquilinoDto(data)
             this.inquilinoDto = data
             this.inquilinosService.inquilinoDto.next(this.inquilinoDto)
             if (this.personaDetailDto)
               this.getAllGarantias(this.personaDetailDto.id)
           }

        },
        error => {
          this.spinner.hide("spgarantiaInquilino")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  editarPersona(idPersona: number) {
    this.getPersonaByIdAndRoute(idPersona)
  }

  getPersonaByIdAndRoute(idPersona: number) {
    this.spinner.show("spPersona")
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        });
  }

  elegirGarantia(garantiaDto: GarantiaDto) {
  }

  actualizaActivo(garantia: any) {
    garantia.activo = !garantia.activo;
    if (garantia && garantia.activo == true) {
      let enableGarantiaRequest: EnableGarantiaRequest = new EnableGarantiaRequest;
      enableGarantiaRequest.id_Garantia = garantia.id
      this.enableGarantia(enableGarantiaRequest)
    }
    else {
      let disableGarantiaRequest: DisableGarantiaRequest = new DisableGarantiaRequest;
      disableGarantiaRequest.id_Garantia = garantia.id
      this.disableGarantia(disableGarantiaRequest)
    }
    if (this.personaDetailDto)
      this.inquilinosService.sendIdPersona(this.personaDetailDto.id)
    this.inquilinosService.inquilinoDto.next(this.inquilinoDto)
  }

  enableGarantia(enableGarantiaRequest: EnableGarantiaRequest) {
    this.spinner.show("spgarantiaInquilino")
    this.garantiaService.enableGarantia(enableGarantiaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spgarantiaInquilino")
          this.inquilinosService.inquilinoDto.next(this.inquilinoDto)
          this.snackBar.showSuccess(data.text, "Exito");
        },
        error => {
          this.spinner.hide("spgarantiaInquilino")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  disableGarantia(disableGarantiaRequest: DisableGarantiaRequest) {
    this.spinner.show("spgarantiaInquilino")
    this.garantiaService.disableGarantia(disableGarantiaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spgarantiaInquilino")
          this.snackBar.showSuccess(data.text, "Exito");
        },
        error => {
          this.spinner.hide("spgarantiaInquilino")
          this.snackBar.showError(error, "Error");
        }
      )
  }

}
