<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>
    Seleccione alquiler a pagar</h1>

<div mat-dialog-content>
    <mat-card>
        <div class="row">
            <div class="col">
                <p>Total a pagar: <b>{{totalAPagarSinPunitorios | currency}}</b> - Con punitorios: <b>{{totalAPagarSinPunitorios + totalConPunitorio | currency}}</b></p>
                <p></p>
            </div>
        </div>
        <div class="example-container">
            <table mat-table [dataSource]="dataSource" table matSort matSortStart="asc">

                <ng-container matColumnDef="select">
                    <mat-header-cell mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let row">
                        <mat-checkbox #checkbok (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </mat-cell>
                    <mat-cell mat-footer-cell *matFooterCellDef> <strong>Total</strong> </mat-cell>
                </ng-container>

                <ng-container matColumnDef="periodo">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Periodo </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element"> {{element.periodoMes}}/ {{element.periodoAnio}}- {{element.periodo}}
                    </mat-cell>
                    <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                </ng-container>

                <ng-container matColumnDef="importe">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Imp. Aquiler
                        <mat-icon class="icon-large icon-help" [matTooltip]="defImpAlquiler">info</mat-icon>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element">
                        <span>{{element.importeAlquiler | currency}}</span> </mat-cell>
                    <mat-cell mat-footer-cell *matFooterCellDef> <span><strong>{{getTotalImporteAlquiler() |
                      currency}}</strong></span></mat-cell>
                </ng-container>

                <ng-container matColumnDef="punitorio">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Imp. Punitorios
                        <mat-icon class=" icon-large icon-help" [matTooltip]="defImpPunitorios">info</mat-icon>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element"> <span>
                      {{element.importePunitorio | currency}}
                    </span></mat-cell>
                    <mat-cell mat-footer-cell *matFooterCellDef> <span><strong>{{getTotalPunitorios() | currency}}</strong></span></mat-cell>
                </ng-container>

                <ng-container matColumnDef="totalAlquiler">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Total
                        <mat-icon class="icon-large icon-help" [matTooltip]="defImpTotal">info</mat-icon>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element">
                        <span>{{element.importeAlquiler + element.importePunitorio | currency}}</span> </mat-cell>
                    <mat-cell mat-footer-cell *matFooterCellDef> <span>
                      <strong>{{getTotalImporteAlquiler() + getTotalPunitorios() |
                        currency}}</strong>
                    </span></mat-cell>
                </ng-container>

                <ng-container matColumnDef="importeEntrega">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Imp. Entrega
                        <mat-icon class=" icon-large icon-help" [matTooltip]="defImpEntrega">info</mat-icon>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element">
                        <span>
                        {{element.importeAlquilerSinPunitorio + element.importePunitorioEntrega | currency}}
                      </span>
                        <mat-icon class="icon-large icon-help" [matTooltip]="'Parte Alquiler: $' + element.importeAlquilerSinPunitorio  + '\n Parte Punitorio: $' + element.importePunitorioEntrega ">info</mat-icon>
                    </mat-cell>
                    <mat-cell mat-footer-cell *matFooterCellDef>
                        <span><strong>{{getTotalImporteEntrega() | currency}}</strong></span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="difAlquiler">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Dif.Alquiler
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element"> <span>
            {{(element.importeAlquiler - element.importeAlquilerSinPunitorio) | currency}}
          </span></mat-cell>
                    <mat-cell mat-footer-cell *matFooterCellDef>
                        <span><strong>{{getTotalDifAlquiler() | currency}}</strong></span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="difPunitorio">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Dif.Punitorio
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element"> <span>
          {{ (element.importePunitorio - element.importePunitorioEntrega)
            | currency}}
        </span></mat-cell>
                    <mat-cell mat-footer-cell *matFooterCellDef>
                        <span><strong>{{getTotalDifPunitorio() | currency}}</strong></span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="saldoTotal">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Saldo Total
                        <mat-icon class="icon-large icon-help" [matTooltip]="defSaldoTotal">info</mat-icon>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element"> <span><strong>
              {{(element.importeAlquiler - element.importeAlquilerSinPunitorio)+
                (element.importePunitorio - element.importePunitorioEntrega)
                | currency}}
            </strong></span></mat-cell>
                    <mat-cell mat-footer-cell *matFooterCellDef> <strong></strong></mat-cell>
                </ng-container>

                <ng-container matColumnDef="accion">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Acción</mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element" class="action-link">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item selection-model-ignore="true" (click)="editarMontoPunitorio(element)">
                <mat-icon>calculate</mat-icon>
                Calcular punitorio
              </button>
                        </mat-menu>
                    </mat-cell>
                    <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns,sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
                </mat-row>
                <mat-row mat-footer-row *matFooterRowDef="displayedColumns"></mat-row>
            </table>
        </div>
    </mat-card>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()">Aceptar</button>
    <button mat-button mat-dialog-close color="warn" (click)="onCancel()">Cancelar</button>
</div>