<!-- <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="true">
</ngx-spinner> -->
<mat-toolbar class="toolbar" color="primary">

    <div style="display: flex; align-items: center;">

        <!-- Dispositivos Moviles -->

        <button *ngIf="!isLoading" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" class="hide2">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu" yPosition="below">
            <span *ngFor="let item of menues;let i=index;trackBy:trackByItems">
              <span *ngIf="item.submenues && item.submenues.length > 0"  >
                <mat-menu-item  mat-menu-item   [matMenuTriggerFor]="menu.childMenu">
                  <mat-icon>{{item.iconName}} </mat-icon>
                  <span>{{item.displayName}}</span>
            </mat-menu-item>
            <app-menu-item #menu [items]="item.submenues"></app-menu-item>
            </span>
            <span *ngIf="!item.submenues || item.submenues.length === 0">
                <mat-menu-item mat-menu-item   [routerLink]="item.route">
                  <mat-icon>{{item.iconName}} </mat-icon>
                  <span>{{item.displayName}}</span>
            </mat-menu-item>
            </span>
            </span>
        </mat-menu>

        <!-- Fin Dispositivos Moviles -->

        <!-- Dispositivos PC -->
        <app-logo *ngIf="archivo.id != 0" [idArchivo]="archivo.id"></app-logo>

        <!-- Original -->
        <div *ngIf="!isLoading" style="display: flex; ">
            <span *ngFor="let item of menues;let i=index;trackBy:trackByItems" class="hide">

              <span *ngIf="item.submenues && item.submenues.length > 0">
                <button mat-button [matMenuTriggerFor]="menu.childMenu">
                  <mat-icon>{{item.iconName}}</mat-icon>
                 <span> {{item.displayName}}</span>
            </button>
            <app-menu-item #menu [items]="item.submenues"></app-menu-item>
            </span>

            <span *ngIf="!item.submenues || item.submenues.length === 0">
                  <button mat-button [routerLink]="'/'+item.route">
                    <mat-icon>{{item.iconName}} </mat-icon>
                    <span>{{item.displayName}}</span>
            </button>
            </span>

            </span>
        </div>




    </div>

    <span class="example-spacer"></span>

    <!-- Panel Derecho -->
    <div style="display: flex; align-items: center;">

        <mat-menu #buscar="matMenu" xPosition="before">
            <app-menu-setting></app-menu-setting>
        </mat-menu>

        <app-api-version></app-api-version>
        <!-- <app-btn-notificaciones></app-btn-notificaciones> -->

        <button mat-icon-button [matMenuTriggerFor]="beforeMenu" [matTooltip]="'Nombre y Apellido: '+ user.persona.nombreCompleto +  '\n Usuario: ' + user.name" [matTooltipClass]="'allow-cr'">
          <mat-icon style=" transform: scale(1.5);">account_circle</mat-icon>
        </button>

        <mat-menu #beforeMenu="matMenu" xPosition="before">
            <app-menu-setting></app-menu-setting>
        </mat-menu>
    </div>
</mat-toolbar>
