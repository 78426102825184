import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/modules/shared.module";
import { BancosComponent } from "../views/bancos/bancos/bancos.component";
import { BtnNuevoBancoComponent } from "../views/bancos/btn-nuevo-banco/btn-nuevo-banco.component";
import { DialogBancoComponent } from "../views/bancos/dialog-banco/dialog-banco.component";
import { BuscarBancoComponent } from "../views/busquedas/buscar-banco/buscar-banco.component";

@NgModule({
    imports:[
        CommonModule,
        SharedModule
    ],
    declarations:[
        BuscarBancoComponent,
        BancosComponent,
        DialogBancoComponent,
        BtnNuevoBancoComponent,],
    exports:[
        BuscarBancoComponent,
        BancosComponent,
        DialogBancoComponent,
        BtnNuevoBancoComponent,]
})

export class BancosModule{}
