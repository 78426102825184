import { AutoMap } from '@automapper/classes';
import { BaseDto } from '../base/baseDto.model';
import { EstadoCivilDto } from '../estado-civil/estadoCivilDto.model';
import { LocalidadDto } from '../localidad-provincia/localidadDto.model';
import { ProvinciaDto } from '../localidad-provincia/provinciaDto.model';
import { TipoDocumentoDto } from '../tipo-documento/tipoDocumentoDto.model';


export class PersonaDetailDto {
  @AutoMap()
  id: number = 0;
  @AutoMap() nombreCompleto!: string ;
  @AutoMap() nombre!: string ;
  @AutoMap() apellido!: string ;
  @AutoMap() apellidoMaterno!: string ;
  @AutoMap(() => TipoDocumentoDto) tipoDocumento: TipoDocumentoDto = new TipoDocumentoDto;
  @AutoMap() idTipoDocumento: number = 0;
  @AutoMap() nroDocumento!: string ;
  @AutoMap() telefonoPrincial!: string ;
  @AutoMap() telefonoAlternativo!: string ;
  @AutoMap() sexo!: string ;
  @AutoMap() correo!: string ;
  @AutoMap() fechaNacimiento!: string ;
  @AutoMap() ubicacion!: string ;
  @AutoMap(() => LocalidadDto) localidad: LocalidadDto = new LocalidadDto;
  @AutoMap() idLocalidad: number = 0;
  @AutoMap(() => ProvinciaDto) provincia: ProvinciaDto = new ProvinciaDto;
  @AutoMap() idProvincia: number = 0;
  @AutoMap() codigoCpa!: string ;
  @AutoMap(() => EstadoCivilDto) estadoCivil: EstadoCivilDto = new EstadoCivilDto;
  @AutoMap() idEstadoCivil: number = 0;
  @AutoMap() ocupacion!: string ;
  @AutoMap()  estado: boolean = true;
  @AutoMap() observacion!: string ;
  @AutoMap() fechaAlta!: string ;
  @AutoMap() fechaBaja!: string ;
  @AutoMap() esGarante!: boolean;
  @AutoMap()esInquilino!: boolean;
  @AutoMap() esPropietario!: boolean;
  @AutoMap(() => BaseDto) pais!: BaseDto
  @AutoMap() telefonoLaboral!: string ;
  @AutoMap() ubicacionLaboral!: string ;
  @AutoMap() fechaCreacion!: string ;
  @AutoMap() fechaModificacion!: string ;
}
