
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { NgxSpinnerService } from 'ngx-spinner';
import { BonificacionDto } from '@models/bonificaciones/bonificacionDto.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { PersonaPropietarioDto } from '@models/propietarios/personaPropietarioDto.model';
import { BonificacionesService } from '@services/bonificaciones/bonificaciones.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposMoviemientoService } from '@services/tipos-movimiento/tipos-movimiento.service';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DatePipe, formatDate } from '@angular/common';
import { MovimientosService } from '@services/movimientos/movimientos.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { UpdateMovimientoRequest } from '@models/movimiento/updateMovimientoRequestUpdate.mode';
import { MovimientoDetailDto } from '@models/movimiento/movimientoDetailDto.model';
import { BaseDto } from '@models/base/baseDto.model';
import { isFalsy } from 'utility-types';
import { TipoMovimientoDto } from '@models/tipos-movimiento/tipoMovimientoDto.model';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-dialog-update-movimiento',
  templateUrl: './dialog-update-movimiento.component.html',
  styleUrls: ['./dialog-update-movimiento.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    [DatePipe],
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DialogUpdateMovimientoComponent implements OnInit {
  lstTiposMovimiento!: TipoMovimientoDto[];
  lstBonificaciones!: BonificacionDto[];
  movimientoForm: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  propiedad!: BaseDto;
  inquilinoDto!: InquilinoDto;
  propietario!: PersonaPropietarioDto;
  idMovimiento: any;
  movimientoDetailDto!: MovimientoDetailDto;
  get f() { return this.movimientoForm.controls; }
  modoVista:boolean= true
  accionesDialog!: AccionesDialog
  selectedTipoMovimiento:TipoMovimientoDto = new TipoMovimientoDto
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private bonificacionService: BonificacionesService,
    private tiposMoviemientoService: TiposMoviemientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    private movimientosService: MovimientosService,
    private dialogRef: MatDialogRef<DialogUpdateMovimientoComponent>,


  ) {
    this.movimientoForm = this.formBuilder.group({
      //Movimiento
      TipoMovimiento: ["", Validators.compose([Validators.required])],
      Descripcion: [""],
      Importe: ["", Validators.compose([Validators.required])],
      Periodo: ["", Validators.compose([Validators.required])],
      Contrato: [""],
      Propietario: [""],
      Inquilino: [""],
      FechaEmision: [""],
      FechaPago: [""],
    })
  }

  ngOnInit(): void {
    this.getTipoMovimientoService()
    if (!isFalsy(this.data.id))
      this.getMovimientoById(this.data.id)
  }

  onSubmit() {
    const updateMovimientoRequest = this.setUpdateMovimientoRequest()
    if (updateMovimientoRequest != null && isFalsy(this.movimientoDetailDto.fechaPago)) {
      this.updateMovimiento(updateMovimientoRequest)
      this.dialogRef.close({ event: AccionesDialog.Modificar });
    }

  }
  disableForm(accionesDialog: AccionesDialog) {

    if (accionesDialog == AccionesDialog.Modificar) {
      this.movimientoForm.controls["TipoMovimiento"].enable()
      this.movimientoForm.controls["Descripcion"].enable()
      this.movimientoForm.controls["Importe"].enable()
      this.movimientoForm.controls["Periodo"].enable()
    }
    else {
      this.movimientoForm.controls["TipoMovimiento"].disable()
      this.movimientoForm.controls["Descripcion"].disable()
      this.movimientoForm.controls["Importe"].disable()
      this.movimientoForm.controls["Periodo"].disable()
    }
    this.movimientoForm.controls["Contrato"].disable()
    this.movimientoForm.controls["Propietario"].disable()
    this.movimientoForm.controls["Inquilino"].disable()
    this.movimientoForm.controls["FechaEmision"].disable()
    this.movimientoForm.controls["FechaPago"].disable()
  }

  setUpdateMovimientoRequest(): UpdateMovimientoRequest {

    let tipoMovimientoId = this.movimientoForm.controls["TipoMovimiento"].value

    let descripcion = this.movimientoForm.controls["Descripcion"].value == "" ? this.movimientoDetailDto.descripcion : this.movimientoForm.controls["Descripcion"].value

    let importe = this.movimientoForm.controls["Importe"].value == "" ? this.movimientoDetailDto.importe : parseFloat(this.movimientoForm.controls["Importe"].value)

    let periodoAnio = this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'yyyy') == "" ? null : this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'yyyy')
    let periodoMes = this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'MM') == "" ? null : this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'MM')

    let updateMovimientoRequest: UpdateMovimientoRequest = new UpdateMovimientoRequest
    updateMovimientoRequest.id = this.movimientoDetailDto.id
    updateMovimientoRequest.descripcion = descripcion
    updateMovimientoRequest.idTipoMovimiento = tipoMovimientoId
    updateMovimientoRequest.importe = importe
    updateMovimientoRequest.periodo = this.movimientoDetailDto.periodo
    updateMovimientoRequest.periodoAnio = isFalsy(periodoAnio) ? null : parseInt(periodoAnio)
    updateMovimientoRequest.periodoMes = isFalsy(periodoMes) ? null : parseInt(periodoMes)
    updateMovimientoRequest.idContrato = this.movimientoDetailDto.contrato != null ? this.movimientoDetailDto.contrato.id : null
    updateMovimientoRequest.idPropiedad = this.movimientoDetailDto.propiedad != null ? this.movimientoDetailDto.propiedad.id : null
    updateMovimientoRequest.idInquilino = this.movimientoDetailDto.inquilino != null ? this.movimientoDetailDto.inquilino.id : null
    updateMovimientoRequest.idPropietario = this.movimientoDetailDto.propietario != null ? this.movimientoDetailDto.propietario.id : null
    updateMovimientoRequest.fechaEmision = this.movimientoDetailDto.fechaEmision
    updateMovimientoRequest.fechaPago = this.movimientoDetailDto.fechaPago
    updateMovimientoRequest.fechaVencimiento = this.movimientoDetailDto.fechaVencimiento
    updateMovimientoRequest.idBonificacion = this.movimientoDetailDto.bonificacion != null ? this.movimientoDetailDto.bonificacion.id : null
    updateMovimientoRequest.idPeriodoContrato = this.movimientoDetailDto.periodoContrato != null ? this.movimientoDetailDto.periodoContrato.id : null

    return updateMovimientoRequest

  }



  updateMovimiento(updateMovimientoRequest: UpdateMovimientoRequest) {
    this.spinner.show("spMovimiento")
    this.movimientosService.update(updateMovimientoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showSuccess("Movimiento actualizado correctamente.", "Exito")
          this.dialogRef.close({ event: AccionesDialog.Modificar });
        },
        error => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }

  getMovimientoById(id: number) {
    this.spinner.show("spMovimiento")
    this.movimientosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spMovimiento")
          this.movimientoDetailDto = data
          this.setFormMovimiento(data)
        },
        error => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }

  setFormMovimiento(data: MovimientoDetailDto) {
    if (this.data.accion == AccionesDialog.Ver)
      this.disableForm(AccionesDialog.Ver)
    else {
      if (isFalsy(data.fechaPago))
        this.disableForm(AccionesDialog.Modificar)
      else
        this.disableForm(AccionesDialog.Ver)
    }

    this.movimientoForm.controls["TipoMovimiento"].setValue(data.tipoMovimiento.id)
    this.movimientoForm.controls["Descripcion"].setValue(!isFalsy(data.descripcion) ? data.descripcion : "")
    this.movimientoForm.controls["Importe"].setValue(data.importe)

    this.movimientoForm.controls["FechaPago"].setValue(!isFalsy(data.fechaPago ) ? formatDate(data.fechaPago,'yyyy-MM-dd','es') : "")
    this.movimientoForm.controls["Contrato"].setValue(!isFalsy(data.contrato)? data.contrato.descripcion : "")
    this.movimientoForm.controls["FechaEmision"].setValue(!isFalsy(data.fechaEmision) ? formatDate(data.fechaEmision,'yyyy-MM-dd','es')  : "")
    this.movimientoForm.controls["Propietario"].setValue(!isFalsy(data.propietario) ? data.propietario.descripcion : "")
    this.movimientoForm.controls["Inquilino"].setValue(!isFalsy(data.inquilino) ? data.inquilino.persona.descripcion : "")
    const date = moment(data.periodoMes.toString() + "-01-" + data.periodoAnio.toString())
    this.movimientoForm.controls["Periodo"].setValue(date)
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  //#region Servicios

  getTipoMovimientoService() {
    this.spinner.show("spBusquedaTiposMovimiento")
    this.tiposMoviemientoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTiposMovimiento")
          this.lstTiposMovimiento = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide("spBusquedaTiposMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }

  getBonificaciones() {
    this.bonificacionService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("")
          this.lstBonificaciones = data
        },
        error => {
          this.spinner.hide("")
          this.snackBar.showError(error, "Error");
        })
  }

  selectPropiedad(event: BaseDto) {
    this.propiedad = event
  }
  selectInquilino(event: InquilinoDto) {
    this.inquilinoDto = event
  }

  selectPropietario(event: PersonaPropietarioDto) {
    this.propietario = event
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.movimientoForm.controls["Periodo"].value;
    ctrlValue.year(normalizedYear.year());
    this.movimientoForm.controls["Periodo"].setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.movimientoForm.controls["Periodo"].value;
    ctrlValue.month(normalizedMonth.month());
    this.movimientoForm.controls["Periodo"].setValue(ctrlValue);
    datepicker.close();
  }

  trackByItems(index: number, item: any): any { return item; }

}


    //#endregion

