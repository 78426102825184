<div class="position-relative">

  <ngx-spinner name="spModificarPermiso" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
  </ngx-spinner>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-lg-6">
        <mat-form-field>
          <input placeholder="Descripción" type="text" formControlName="Descripcion" matInput>
          <mat-error *ngIf="f.Descripcion.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field>
          <input formControlName="Token" placeholder="Token" type="text" matInput>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="example-full-width">
          <ngx-spinner [zIndex]=100 name="spBusquedaCasosUso" bdColor="rgba(255,255,255,0.8)" size="small"
            color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
          <mat-label>Caso de uso</mat-label>
          <mat-select placeholder="Seleccione opcion" formControlName="CasoUso">
            <mat-option [value]="casoUso.id"
              *ngFor="let casoUso of casosUso;let i=index;trackBy:trackByItems">
              {{casoUso.descripcion}}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.CasoUso.errors?.required">Este campo es <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="example-full-width">
          <ngx-spinner [zIndex]=100 name="spBusquedaRoles" bdColor="rgba(255,255,255,0.8)" size="small"
            color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
          <mat-label>Roles</mat-label>
          <mat-select placeholder="Seleccione opcion" formControlName="Rol">
            <mat-option [value]="rol.id"
              *ngFor="let rol of roles;let i=index;trackBy:trackByItems">
              {{rol.descripcion}}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.Rol.errors?.required">Este campo es <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <button mat-raised-button color="primary" *appRole="['Permisos.Modificar']" (click)="doAction()"
          [disabled]="!form.valid">Agregar</button>
      </div>
      <div class="col-lg-3">
        <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancelar</button>
      </div>
    </div>
  </form>
</div>
