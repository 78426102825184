import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EstadoGarantiasPendientesComponent } from "@views/inquilinos/estado-garantias-pendientes/estado-garantias-pendientes.component";
import { SharedModule } from "src/app/modules/shared.module";
import { InquilinoController } from "../controllers/inquilinos.controller";
import { BuscarPersonaInquilinoSinContratoComponent } from "../views/busquedas/buscar-persona-inquilino-sin-contrato/buscar-persona-inquilino-sin-contrato/buscar-persona-inquilino-sin-contrato.component";
import { AsignarGarantiaAInquilinoComponent } from "../views/garantias/asignar-garantia-a-inquilino/asignar-garantia-a-inquilino.component";
import { GarantiasInquilinoComponent } from "../views/garantias/garantias-inquilino/garantias-inquilino.component";
import { AgregaPersonaInquilinoComponent } from "../views/inquilinos/agrega-persona-inquilino/agrega-persona-inquilino.component";
import { BtnNewInquilinoComponent } from "../views/inquilinos/btn-new-inquilino/btn-new-inquilino.component";
import { DialogCreateInquilinoComponent } from "../views/inquilinos/dialog-create-inquilino/dialog-create-inquilino.component";
import { FormCreateInquilinoComponent } from "../views/inquilinos/form-create-inquilino/form-create-inquilino.component";
import { InquilinoComponent } from "../views/inquilinos/form-inquilino/inquilino.component";
import { FormUpdateInquilinoComponent } from "../views/inquilinos/form-update-inquilino/form-update-inquilino.component";
import { PersonasModule } from "./personas.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PersonasModule
    ],
    declarations: [
        BtnNewInquilinoComponent,
        DialogCreateInquilinoComponent,
        FormCreateInquilinoComponent,
        FormUpdateInquilinoComponent,
        AsignarGarantiaAInquilinoComponent,
        InquilinoComponent,
        GarantiasInquilinoComponent,
        AgregaPersonaInquilinoComponent,
        BuscarPersonaInquilinoSinContratoComponent,
        EstadoGarantiasPendientesComponent
    ],
    exports: [
        BtnNewInquilinoComponent,
        DialogCreateInquilinoComponent,
        FormCreateInquilinoComponent,
        FormUpdateInquilinoComponent,
        AsignarGarantiaAInquilinoComponent,
        InquilinoComponent,
        GarantiasInquilinoComponent,
        AgregaPersonaInquilinoComponent,
        BuscarPersonaInquilinoSinContratoComponent,
        EstadoGarantiasPendientesComponent
    ]
})

export class InquilinosModule { }
