<div class="row content">
    <div class="col-12">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false" #mapanel="matExpansionPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Datos persona</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">person_pin</mat-icon>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>

                    <form [formGroup]="personaForm">

                        <ngx-spinner [zIndex]=100 name="spPersona" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                        </ngx-spinner>
                        <div class="row">
                            <div class="form-group col-md-3">
                                <mat-form-field class="full-width">
                                    <mat-label>Nombre y Apellido</mat-label>
                                    <input cdkFocusInitial matInput type="text" maxlength="50" style="text-transform: capitalize;" id="NombreCompleto" formControlName="NombreCompleto" />
                                    <mat-error *ngIf="f.NombreCompleto.errors?.required">Ingrese <strong> nombre completo.</strong>
                                    </mat-error>
                                    <mat-error *ngIf="f.NombreCompleto.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Apellido Materno</mat-label>
                                    <input matInput name="ApellidoMaterno" style="text-transform: capitalize;" maxlength="50" type="text" formControlName="ApellidoMaterno" />
                                    <mat-error *ngIf="f.ApellidoMaterno.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-3">
                                <mat-form-field>
                                    <mat-label>Sexo</mat-label>
                                    <mat-select formControlName="Sexo">
                                        <mat-option value="M">Masculino</mat-option>
                                        <mat-option value="F">Femenino</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="f.Sexo.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field>
                                    <ngx-spinner name="spPersonaCombo" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Tipo Documento</mat-label>
                                    <mat-select #SelectTipoDocuemtno placeholder="Seleccione opcion" (selectionChange)="selectTipoDocumento($event)" formControlName="TiposDocumento">
                                        <mat-option [value]="tipoDocumento.id" *ngFor="let tipoDocumento of lstTipoDocumentoDto ;let i=index;trackBy:trackByItems">
                                            {{tipoDocumento.descripcion}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="f.TiposDocumento.errors?.required">Este campo es <strong>requerido</strong>.
                                    </mat-error>
                                    <mat-hint>Habilita nro. documento</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <ngx-spinner name="spPersonaCombo" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                    <mat-label>Nro. Documento</mat-label>
                                    <input matInput name="NroDocumento" type="text" maxlength="15" #NroDocumento oninput="this.value=this.value.toUpperCase()" formControlName="NroDocumento" />
                                    <mat-error *ngIf="f.NroDocumento.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                    <mat-error *ngIf="f.NroDocumento.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                    <mat-error *ngIf="f.NroDocumento.errors?.existePersona">Ya existe esta persona.</mat-error>
                                    <mat-error *ngIf="f.NroDocumento.errors?.pattern">Caracteres no validos.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Fecha Nacimiento</mat-label>
                                    <input matInput name="FechaNacimiento" type="date" formControlName="FechaNacimiento" />
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-3">
                                <mat-form-field class="full-width">
                                  <ngx-spinner name="spPersonaCorreo" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                    <mat-label>Correo</mat-label>
                                    <input matInput name="Correo" type="email" formControlName="Correo" />
                                    <mat-error *ngIf="f.Correo.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                    <mat-error *ngIf="f.Correo.errors?.email">Correo invalido.</mat-error>

                                    <mat-error *ngIf="personaForm.get('Correo')?.hasError('emailTaken') && personaForm.get('Correo')?.touched">
                                      El correo ya está registrado.
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field>
                                    <ngx-spinner name="spPersonaCombo" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                    <mat-label>Estado Civil</mat-label>
                                    <mat-select #SelectEstadoCivil formControlName="EstadoCivil">
                                        <mat-option [value]="estadoCivil.id" *ngFor="let estadoCivil of lstEstadosCivilModel;let i=index;trackBy:trackByItems ">
                                            {{estadoCivil.descripcion}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="f.EstadoCivil.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Telefono Personal</mat-label>
                                    <!-- <span matPrefix>+54 &nbsp;</span> -->
                                    <input matInput name="TelefonoPrincial" maxlength="20" placeholder="Sin 0 y sin 15" type="tel" formControlName="TelefonoPrincial" />
                                    <mat-error *ngIf="f.TelefonoPrincial.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Telefono Particular</mat-label>
                                    <!-- <span matPrefix>+54 &nbsp;</span> -->
                                    <input matInput name="TelefonoAlternativo" maxlength="20" placeholder="Sin 0 y sin 15" type="tel" formControlName="TelefonoAlternativo" />
                                    <mat-error *ngIf="f.TelefonoAlternativo.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Ocupacion</mat-label>
                                    <input matInput name="Ocupacion" type="text" maxlength="50" formControlName="Ocupacion" />
                                    <mat-error *ngIf="f.Ocupacion.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field class="full-width">
                                    <mat-label>Ubicacion</mat-label>
                                    <input matInput name="Ubicacion" style="text-transform: capitalize;" type="text" maxlength="50" formControlName="Ubicacion" />
                                    <mat-error *ngIf="f.Ubicacion.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="  col-lg-6">
                                <app-buscar-localidad (eventoLocalidad)="seleccionaLocalidad($event)"></app-buscar-localidad>
                            </div>
                            <div class="form-group col-md-3">
                                <mat-form-field>
                                    <ngx-spinner name="spPersonaCombo" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                    <mat-label>Nacionalidad</mat-label>
                                    <mat-select formControlName="Pais">
                                        <mat-option [value]="pais.id" *ngFor="let pais of lstPaises;let i=index;trackBy:trackByItems ">
                                            {{pais.descripcion}}</mat-option>
                                    </mat-select>

                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Telefono Laboral</mat-label>
                                    <!-- <span matPrefix>+54 &nbsp;</span> -->
                                    <input matInput name="TelefonoLaboral" maxlength="20" placeholder="Sin 0 y sin 15" type="tel" formControlName="TelefonoLaboral" />
                                    <mat-error *ngIf="f.TelefonoAlternativo.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field class="full-width">
                                    <mat-label>Ubicacion Laboral</mat-label>
                                    <input matInput name="UbicacionLaboral" style="text-transform: capitalize;" type="text" maxlength="50" formControlName="UbicacionLaboral" />
                                    <mat-error *ngIf="f.Ubicacion.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="full-width-x100">
                                    <mat-label>Observaciones</mat-label>
                                    <textarea matInput #message name="Observacion" formControlName="Observacion"></textarea>
                                    <mat-error *ngIf="f.Observacion.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                    <mat-hint align="start"><strong>Amplie información personal</strong> </mat-hint>
                                    <mat-hint align="end">{{message.value.length}} / 2000</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                    <br>
                    <div class="col-auto my-1">
                        <button mat-raised-button color="primary" style="margin-right:3px;" name="Agregar" [disabled]="!personaForm.valid || isFalsy(localidad)" *appRole="['Personas.Crear']" (click)="onSubmit()">Agregar</button>
                        <button mat-button color="warn" style="margin-right:3px;" name="Cancelar" (click)="goToPesonas()">Cancelar</button>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
