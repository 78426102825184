import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GarantiaDtoSimple } from '@models/garantia/garantiaDtoSimple.model';
import { GarantiaSummaryDto } from '@models/garantia/garantiaSummaryDto.model';
import { GarantiasService } from '@services/garantias/garantias.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { EnableGarantiaRequest } from '@models/garantia/enableGarantiaRequest.model';
import { DisableGarantiaRequest } from '@models/garantia/disableGarantiaRequest.model';

@Component({
  selector: 'app-mis-garantias',
  templateUrl: './mis-garantias.component.html',
  styleUrls: ['./mis-garantias.component.css']
})

export class MisGarantiasComponent implements OnInit {
  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<GarantiaSummaryDto>();
  garantiaDto: GarantiaDtoSimple = new GarantiaDtoSimple
  validador: boolean = false;
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
}
  }
  personaDetailDto!: PersonaDetailDto;

  constructor(
    private garantiaService: GarantiasService,
    private snackBar: SnackBarService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private personasService: PersonasService
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["tipoGarantia", "toggle", "accion"] :
        ["tipoGarantia", "legajo", "razonSocial", "cuitCuil", "sueldo", "toggle", "accion"]
    });
  }

  ngOnInit(): void {

    this.personaDetailDto = this.personasService.getPersona()
    this.getAllGarantias(this.personaDetailDto.id)
  }

  editarGarantia(garantia: GarantiaSummaryDto) {
    this.garantiaService.garantia = garantia
    this.router.navigate(['/garantia/editar'])
  }

  getAllGarantias(idPersona: number) {
    this.spinner.show("spListadogarantias")
    this.garantiaService.getAllGarantiasByPersona(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spListadogarantias")
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ?  true : false

        },
        error => {
          this.spinner.hide("spListadogarantias")
          this.snackBar.showError( error, "Error");
        }
      )
  }


  actualizaActivo(garantia: any) {
    garantia.activo = !garantia.activo;
    if (garantia.activo == true) {
      let enableGarantiaRequest =  new EnableGarantiaRequest;
      enableGarantiaRequest.id_Garantia = garantia.id
      this.enableGarantia(enableGarantiaRequest)
    }
    else {
      let disableGarantiaRequest =  new DisableGarantiaRequest;
      disableGarantiaRequest.id_Garantia = garantia.id
      this.disableGarantia(disableGarantiaRequest)
    }

  }

  enableGarantia(enableGarantiaRequest: EnableGarantiaRequest) {
    this.spinner.show("spListadogarantias")
    this.garantiaService.enableGarantia(enableGarantiaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spListadogarantias")
          this.snackBar.showError(data.text, "Exito");
        },
        error => {
          this.spinner.hide("spListadogarantias")
          this.snackBar.showError( error, "Error");
        }
      )
  }

  disableGarantia(disableGarantiaRequest: DisableGarantiaRequest) {
    this.spinner.show("spListadogarantias")
    this.garantiaService.disableGarantia(disableGarantiaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spListadogarantias")
          this.snackBar.showError(data.text, "Exito");
        },
        error => {
          this.spinner.hide("spListadogarantias")
          this.snackBar.showError( error, "Error");
        }
      )
  }


}
