
<ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
</ngx-spinner>
<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-7" *ngIf="action != 'Eliminar' ">
        <div *ngIf="action == 'Agregar' && !inquilinoDto">
          <app-buscar-persona-inquilino width='full-width-x100'  (eventEntity)="selectInquilino($event)"></app-buscar-persona-inquilino>
        </div>
        <div *ngIf="inquilinoDto">
          <p>Inquilino:</p>
          <h2>{{inquilinoDto.persona.descripcion | titlecase}} </h2>
        </div>
      </div>


      <div class="col-5">
        <mat-form-field *ngIf="action != 'Eliminar'">
          <ngx-spinner [zIndex]=100 name="spBusqueda" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688"
            type="line-scale" [fullScreen]="false"></ngx-spinner>
          <mat-label>Grupo Garantias</mat-label>
          <mat-select placeholder="Seleccione opcion" formControlName="GrupoTipoGarantia">
            <mat-option [value]="grupoTipoGarantia.id"
              *ngFor="let grupoTipoGarantia of lstGrupoTipoGarantia;let i=index;trackBy:trackByItems">
              {{grupoTipoGarantia.descripcion}}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.GrupoTipoGarantia.errors?.required">Este campo es <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div #elseTemplate *ngIf="action == 'Eliminar';">
    ¿Esta seguro que desea eliminar el registro <b>{{local_data.descripcion}}</b>?
  </div><br>
</div>
<br>
<mat-divider></mat-divider>
<div mat-dialog-actions>
  <div
    *appRole="['GruposGarantiasInquilino.Crear','GruposGarantiasInquilino.Modificar','GruposGarantiasInquilino.Eliminar']">
    <button mat-raised-button color="primary" (click)="doAction()" [disabled]="!form.valid">{{action}}</button>
    <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
  </div>
