<div class="list-group" *appRole="['Propiedades.Ver']">
    <button type="button" [ngClass]="{'active': propiedadNueva== true}" [routerLinkActive]="['active']" [routerLink]="['/propiedades/'+idPropiedad ]" class="list-group-item list-group-item-action">
    <div class="with-icon">
      <mat-icon class="padding-icon">roofing</mat-icon> <span >Propiedad</span>
    </div>
</button>
<div *appRole="['Servicios.Ver']">
  <button type="button" *ngIf="!propiedadNueva" [routerLinkActive]="['active']" [routerLink]="['/serviciospropiedad/servicio/'+idPropiedad]" class="list-group-item list-group-item-action">
  <div class="with-icon">
    <mat-icon class="padding-icon">water_drop</mat-icon> <span >Servicios</span>

  </div>
</button></div>

<div *appRole="['DetalleServicios.Ver']">
  <button type="button" *ngIf="!propiedadNueva" [routerLinkActive]="['active']" [routerLink]="['/detalleservicios/'+idPropiedad]" class="list-group-item list-group-item-action">
    <div class="with-icon">
      <mat-icon class="padding-icon">doorbell</mat-icon> <span >Detalle Servicios</span>
    </div>
</button>
</div>

<div *appRole="['Documentos.Ver']">

  <button type="button"   *ngIf="!propiedadNueva" [routerLinkActive]="['active']" [routerLink]="['/documentospropiedad/'+idPropiedad]" class="list-group-item list-group-item-action">
    <div class="with-icon">
      <mat-icon class="padding-icon">folder</mat-icon> <span >Documentos</span>
    </div>
  </button>
</div>
<div *appRole="['Contratos.Ver']">
  <button type="button" *ngIf="!propiedadNueva"   [routerLinkActive]="['active']" [routerLink]="['/contratospropiedad/'+idPropiedad]" class="list-group-item list-group-item-action">
    <div class="with-icon">
      <mat-icon class="padding-icon">gavel</mat-icon> <span >Contratos</span>
    </div>
  </button>
</div>

</div>
