import { BaseDto } from "../base/baseDto.model";

export class EstadoCivilDto extends BaseDto{

  static fromEstadoCivilDto(estadoCivilDto: EstadoCivilDto) {
    const dto = new EstadoCivilDto();
    dto.id = estadoCivilDto.id;
    dto.descripcion = estadoCivilDto.descripcion;
    return dto;
  }
}
