import { ComprobanteDetailDto } from "./comprobanteDetailDto.model"
import { ConceptoSimpleComprobanteDto } from "./conceptoSiempleComprobanteDto.model"

export class VistaComprobanteDetalle {
  public id!: number
  public estado!: string
  public tipoComprobante!: string
  public fechaEmision!: string
  public inquilino!: string
  public propiedad!: string
  public propietario!: string
  public fechaAcreditacion!: string
  public numeroComprobante!: string
  public descripcion!: string
  public importeComprobante!: number
  public comprobanteDetailDto!: ComprobanteDetailDto
  public conceptos!: ConceptoSimpleComprobanteDto[]
}
