import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateAsuntoRequest } from '@models/asunto/createAsuntoRequest.model';
import { UpdateAsuntoRequest } from '@models/asunto/updateAsuntoRequest.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogAsuntosComponent } from '../dialog-asuntos/dialog-asuntos.component';
import { AsuntosService } from '@services/asuntos/asuntos.service';
import { AccionesDialog } from '@models/base/identificadores.model';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-asuntos',
  templateUrl: './asuntos.component.html',
  styleUrls: ['./asuntos.component.css']
})

export class AsuntosComponent implements OnInit {
  dataSource = new MatTableDataSource<BaseDto>();
  obj!: undefined
  displayedColumns: string[] = [];
  data = false

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  constructor(
    public dialog: MatDialog,
    private asuntosService: AsuntosService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver
  ) {


    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['descripcion', 'accion'] :
        ['descripcion', 'accion'];
    });
  }


  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getAllAsuntos();
  }

  getAllAsuntos() {
    this.spinner.show()
    this.asuntosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "30%",
      dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogAsuntosComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.addRowData(result.data);
      } else if (result.event == AccionesDialog.Modificar) {
        this.updateRowData(result.data);
      } else if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }


  addRowData(row_obj: { descripcion: any; }) {
    this.spinner.show()
    let createAsuntoRequest = new CreateAsuntoRequest
    createAsuntoRequest.descripcion = row_obj.descripcion

    let existe = false
    this.dataSource.data.forEach((element: { descripcion: any; }, index: any) => {
      if (element.descripcion === row_obj.descripcion)
        existe = true
    });

    if (createAsuntoRequest && !existe) {
      this.asuntosService.create(createAsuntoRequest)
        .subscribe(
          data => {
            this.spinner.hide()
            this.dataSource.data.push({
              id: data.id,
              descripcion: row_obj.descripcion
            });
            this.table.renderRows();
            this.dataSource.data = this.dataSource.data.filter((value: { id: number; descripcion: string; }, key: any) => {
              return true;
            });
            this.data = this.dataSource.data.length >= 1 ? true : false
            this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          },
          error => {
            this.snackBar.showError(error, "Error");
          }
        )
    }
    else {
      this.spinner.hide()
      this.snackBar.showError("El registro ya existe", "Error");
    }

  }
  updateRowData(row_obj: { id: number; descripcion: string; }) {
    this.spinner.show()
    let updateAsuntoRequest = new UpdateAsuntoRequest
    updateAsuntoRequest.id = row_obj.id
    updateAsuntoRequest.descripcion = row_obj.descripcion

    let existe = false
    this.dataSource.data.forEach((element: { descripcion: string; }, index: any) => {
      if (element.descripcion === row_obj.descripcion)
        existe = true
    });
    if (updateAsuntoRequest && !existe) {
      this.asuntosService.update(updateAsuntoRequest)
        .subscribe(
          data => {
            this.spinner.hide()
            this.dataSource.data = this.dataSource.data.filter((value: { id: number; descripcion: string; }, key: any) => {
              if (value.id == row_obj.id) {
                value.descripcion = row_obj.descripcion;
              }
              return true;
            });
            this.data = this.dataSource.data.length >= 1 ? true : false
            this.snackBar.showSuccess("Registro actualizado correctamente.", "Exito");
          },
          error => {
            this.spinner.hide()
            this.snackBar.showError(error, "Error");
          })
    }
    else {
      this.spinner.hide()
      this.snackBar.showWarn("El registro ya existe", "Error");
    }
  }

  deleteRowData(row_obj: { id: number; }) {
    this.asuntosService.delete(row_obj.id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = this.dataSource.data.filter((value: { id: number; }, key: any) => {
            return value.id != row_obj.id;
          });
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })

  }

}
