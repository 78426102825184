import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-panel-usuario',
  templateUrl: './nav-panel-usuario.component.html',
  styleUrls: ['./nav-panel-usuario.component.css']
})
export class NavPanelUsuarioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
