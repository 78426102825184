import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-form-only-persona-update',
  templateUrl: './dialog-form-only-persona-update.component.html',
  styleUrls: ['./dialog-form-only-persona-update.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogFormOnlyPersonaUpdateComponent {

  constructor(

    public dialogRef: MatDialogRef<DialogFormOnlyPersonaUpdateComponent>,
  ) {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
