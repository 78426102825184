import { DatePipe } from "@angular/common";
import { FormControl } from "@angular/forms";
import { Vencimiento } from "@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadDetailDto.model";

export class Helper {


  static padTo2Digits(num: any) {
    return num.toString().padStart(2, '0');
  }

  /**
   * Convierte una fecha en formato DD-MM-YYYY
   * @param date
   * @returns
   */
  static formatDateDDMMYYYY(date: { getDate: () => any; getMonth: () => number; getFullYear: () => any; }) {
    let month = date.getMonth() + 1; // Sumar 1 al mes
    let day = date.getDate();
    return [
      this.padTo2Digits(day),
      this.padTo2Digits(month),
      date.getFullYear(),
    ].join('-');
  }


  /**
    * Convierte una fecha en formato YYYY-MM-DD
    * @param date
    * @returns
    */
  // static formatDateYYYYMMDD(date: { getDate: () => any; getMonth: () => any; getFullYear: () => any; }) {
  //   let month = date.getMonth()
  //   let day =date.getDate()
  //   return [
  //     date.getFullYear(),
  //     this.padTo2Digits(month),
  //     this.padTo2Digits(day),
  //   ].join('-');
  // }

  static formatDateYYYYMMDD(date:any) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

  static getFormatedDate(date: Date, format: string) {
    const datePipe = new DatePipe('es-ES');
    return datePipe.transform(date, format);
  }
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static esFechaVencida(vencimiento: Vencimiento) {
    const ven = new Date(vencimiento.fechaVencimiento)
    const hoy = new Date()
    let days = Math.floor((hoy.getTime() - ven.getTime()) / 1000 / 60 / 60 / 24);

    const estado = days <= 0 ? false : true
    return estado
  }




}
