import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogCreatePropietarioComponent } from '../dialog-create-propietario/dialog-create-propietario.component';

@Component({
  selector: 'app-btn-dialog-create-propietario',
  templateUrl: './btn-dialog-create-propietario.component.html',
  styleUrls: ['./btn-dialog-create-propietario.component.css']
})
export class BtnDialogCreatePropietarioComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  openDialogNuevoPropietario() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%"
    dialogConfig.maxWidth = "80%"


    const dialogRef = this.dialog.open(DialogCreatePropietarioComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // if (!isFalsy(result))

    });
  }
}
