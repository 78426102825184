<div class="row content">
    <div class="col-lg-2">
        <app-nav-panel-persona></app-nav-panel-persona>
    </div>
    <div class="col-lg-10">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Datos Garantia</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">person</mat-icon>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <button mat-raised-button color="primary" [routerLink]="['/garantia/nuevo']">
                <mat-icon>add</mat-icon>
                Nueva garantia
            </button>
                    <div style="padding-bottom: 10px;"></div>
                    <router-outlet></router-outlet>
                    <div style="padding-bottom: 10px;"></div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>