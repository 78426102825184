import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreatePropiedadRequest } from '@models/propiedades/createPropiedadRequest.model';
import { TipoPropiedadDto } from '@models/propiedades/tipoPropiedadDto.model';
import { ProvinciaDto } from '@models/localidad-provincia/provinciaDto.model';
import { PropiedadesService } from '@services/propiedades/propiedades.service';
import { ProvinciasService } from '@services/provincias/provincias.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DatePipe } from '@angular/common';
import { TiposFolioService } from '@services/tipos-folio/tipos-folio.service';
import { EstadosPropiedad, TiposFolios } from '@models/base/identificadores.model';
import { SubtipoPropiedadService } from '@services/subtiposPropiedad/subtipo-propiedad.service';

@Component({
  selector: 'app-form-create-propiedad',
  templateUrl: './form-create-propiedad.component.html',
  styleUrls: ['./form-create-propiedad.component.css'],
  providers: [DatePipe]
})
export class FormCreatePropiedadComponent implements OnInit {

  subMenuActive: boolean = false;
  stringObject: any;
  stringJson: any;
  propiedadForm!: FormGroup
  lstProvinciaDto: ProvinciaDto[] = [];
  submitted = false;
  idProvincia: number = 0;
  errorMessage: string = "";
  accordionList: any;
  formBuilder: FormBuilder = new FormBuilder;
  idPersona: any;
  filteredOptions?: Observable<any[]>;
  idPropiedad!: number;
  lstTiposFolios: BaseDto[] = [];
  idTipoFolio: any;
  localidad: BaseDto = new BaseDto;
  get f() { return this.propiedadForm.controls; }
  lstSubtiposPropiedad: BaseDto[] = []
  lstTiposPropiedad: TipoPropiedadDto[] = []
  lstEstadosPropiedad: BaseDto[] = []
  lstPropiedadClases: BaseDto[] = [ ]

  constructor(
    private provinciasService: ProvinciasService,
    private router: Router,
    private propiedadesService: PropiedadesService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    private tiposFoliosServices: TiposFolioService,
    private subtipoPropiedadService: SubtipoPropiedadService
  ) {
    this.propiedadForm = this.formBuilder.group({
      //Persona
      EstadoPropiedad: [null, Validators.compose([Validators.required])],
      ClasePropiedad: [null, Validators.compose([Validators.required])],
      Ubicacion: ['', Validators.compose([Validators.maxLength(200), Validators.required])],
      CodigoCpa: ['', Validators.compose([Validators.maxLength(8)])],
      Antiguedad: ['', Validators.compose([Validators.maxLength(50)])],
      SuperficieTotal: ['',Validators.compose([Validators.maxLength(20)])],
      Piso: [''],
      Departamento: [''],
      SuperficieCubierta: ['',Validators.compose([Validators.maxLength(20)])],
      TiposPropiedad: [null, Validators.compose([Validators.required])],
      SubtiposPropiedad: [null],
      Precio: ['',Validators.compose([Validators.maxLength(20)])],
      Observacion: ['', Validators.compose([Validators.maxLength(2000)])],
      TipoFolio: [null,Validators.compose([Validators.required])],
      NroFolio: [''],
      Tomo: [''],
      Numero: [''],
      Horizontal: [false],
    });



  }

  ngOnInit(): void {

    this.idPropiedad =0

    this.spinner.show("spPropiedad")
      forkJoin({
        estadosPropiedad: this.propiedadesService.getAllEstadosPropiedad(),
        tiposPropiedad: this.propiedadesService.getAllTiposPropiedad(),
        tiposFolio: this.tiposFoliosServices.getAll(),
        provincias: this.provinciasService.getAllProvincias(),
        propiedadClases: this.propiedadesService.GetAllClasePropiedad(),

      })
      .subscribe(
        ({estadosPropiedad,tiposPropiedad,tiposFolio,provincias,propiedadClases  }) => {

        this.lstProvinciaDto = provincias;
        this.lstEstadosPropiedad = estadosPropiedad;
        this.lstTiposPropiedad = tiposPropiedad;
        this.lstTiposFolios = tiposFolio;
        this.lstPropiedadClases = propiedadClases;
        this.spinner.hide("spPropiedad")

      },
      error=>{
        this.snackBar.showError(error, "Error");
        this.spinner.hide("spPropiedad")
      });

      this.propiedadForm.controls["EstadoPropiedad"].setValue(EstadosPropiedad.SinContrato)
      this.propiedadForm.controls["EstadoPropiedad"].disable()
  }

  selectTipoFolio(event: any) {
    this.idTipoFolio = event;
  }

  seleccionaLocalidad(data: BaseDto) {
    this.localidad = data
  }

  selectTiposPropiedad(envent: any) {
    this.spinner.show("spSubTipoPropiedad")
    this.subtipoPropiedadService.getAllByIdTipoPropiedad(envent.value).subscribe(
      data => {
        this.spinner.hide("spSubTipoPropiedad")
        this.lstSubtiposPropiedad = data
        if (this.lstSubtiposPropiedad.length === 0) {
          this.propiedadForm.controls['SubtiposPropiedad'].disable()
        }
        else {
          this.propiedadForm.controls['SubtiposPropiedad'].enable()
        }
      },
      error => {
        this.spinner.hide("spSubTipoPropiedad")
        this.snackBar.showError( error, "Error");
      });
  }


  setCreatePropiedadRequest(): CreatePropiedadRequest {
    let createPropiedadRequest = new CreatePropiedadRequest

    createPropiedadRequest.id_EstadoPropiedad = this.propiedadForm.controls['EstadoPropiedad'].value;
    createPropiedadRequest.ubicacion = this.propiedadForm.controls['Ubicacion'].value.toUpperCase();
    createPropiedadRequest.piso = this.propiedadForm.controls['Piso'].value.toUpperCase();
    createPropiedadRequest.departamento = this.propiedadForm.controls['Departamento'].value.toUpperCase();

    createPropiedadRequest.id_Localidad = this.localidad.id
    createPropiedadRequest.idTipoPropiedad = this.propiedadForm.controls['TiposPropiedad'].value;
    createPropiedadRequest.idSubtipoPropiedad = this.propiedadForm.controls['SubtiposPropiedad'].value;
    createPropiedadRequest.superficieTotal = this.propiedadForm.controls['SuperficieTotal'].value;
    createPropiedadRequest.superficieCubierta = this.propiedadForm.controls['SuperficieCubierta'].value;
    createPropiedadRequest.id_PropiedadClase = this.propiedadForm.controls['ClasePropiedad'].value;
    createPropiedadRequest.precio = this.propiedadForm.controls['Precio'].value;
    createPropiedadRequest.antiguedad = this.propiedadForm.controls['Antiguedad'].value;
    createPropiedadRequest.codigoCpa = this.propiedadForm.controls['CodigoCpa'].value;
    createPropiedadRequest.observacion = this.propiedadForm.controls['Observacion'].value;
    createPropiedadRequest.fechaAlta = this.datepipe.transform(new Date, 'yyyy-MM-dd HH:mm:ss') as string;
    createPropiedadRequest.activo = true;
    createPropiedadRequest.horizontal = this.propiedadForm.controls['Horizontal'].value as boolean
    createPropiedadRequest.idTipoFolio = this.propiedadForm.controls['TipoFolio'].value
    createPropiedadRequest.nroFolio = this.propiedadForm.controls['NroFolio'].value

    if (createPropiedadRequest.idTipoFolio == TiposFolios.FolioPersonalCronologico) {
      if (createPropiedadRequest.tomo != "")
        createPropiedadRequest.tomo = this.propiedadForm.controls['Tomo'].value
      else {
        createPropiedadRequest.tomo = "0"
      }

      if (createPropiedadRequest.numero != "")
        createPropiedadRequest.numero = this.propiedadForm.controls['Numero'].value
      else {
        createPropiedadRequest.numero = "0"
      }

    }
    else {
      createPropiedadRequest.tomo = ""
      createPropiedadRequest.numero = ""
    }
    return createPropiedadRequest

  }

  onSubmit() {

    this.submitted = true;
    if (this.propiedadForm.invalid) {
      return;
    }
    else {
      this.spinner.show("spPropiedadCompleto")
      const createPropiedadRequest = this.setCreatePropiedadRequest();
      this.propiedadesService.create(createPropiedadRequest).subscribe(
        async data => {
          this.spinner.hide("spPropiedadCompleto")
          this.snackBar.showSuccess('Se creo la propiedad de manera correcta.', "Exito");
          this.idPropiedad = data.id
          await this.router.navigate(["/propiedades/"+ data.id])

        },
        error => {
          this.spinner.hide("spPropiedadCompleto")
          this.snackBar.showError( error, "Error");
        });

    }
  }

  onReset() {
    this.submitted = false;
    this.propiedadForm.reset();
    this.router.navigate(["/propiedades"])
  }

  trackByItems(index: number, item: any): any { return item; }
}
