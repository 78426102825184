import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/modules/shared.module";
import { BtnNuevoTipoPunitorioComponent } from "../views/tipos-punitorios/btn-nuevo-tipo-punitorio/btn-nuevo-tipo-punitorio.component";
import { DialogTipoPunitorioComponent } from "../views/tipos-punitorios/dialog-tipo-punitorio/dialog-tipo-punitorio.component";
import { TiposPunitorioComponent } from "../views/tipos-punitorios/tipos-punitorio/tipos-punitorio.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        DialogTipoPunitorioComponent,
        TiposPunitorioComponent,
        BtnNuevoTipoPunitorioComponent
    ],
    exports: [
        DialogTipoPunitorioComponent,
        TiposPunitorioComponent,
        BtnNuevoTipoPunitorioComponent
    ],
    providers: [
    ],
})

export class TipoPunitorioModule { }
