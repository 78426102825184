import { TipoAjusteContratoDto } from "@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model";
import { BaseDto } from "../base/baseDto.model";

export class ContratoDto extends BaseDto{
  public idInquilino: number = 0;
  public idPropiedad: number = 0;
  public tomo: string = "";
  public folio: string = "";
  public numero: string = "";
  public fechaComienzo: string = "";
  public fechaVencimiento: string = "";
  public fechaRescicion: string = "";
  public diaVencimientoPago: string = "";
  public idTipoReciboInquilino: number = 0;
  public idTipoReciboPropietario: number = 0;
  public idTipoMonedaMontoContrato: number = 0
  public idTipoMontoAdministrativo: number = 0;
  public montoAdministrativo: number = 0;
  public planDePago: number = 0;
  public montoPunitorio: number = 0;
  public idTipoPunitorio: number = 0;
  public idTipoMontoPunitorio: number = 0;
  public montoSellado: number = 0;
  public idTipoSellado: number = 0;
  public idTipoMontoSellado: number = 0;
  public idTipoContrato: number = 0;
  public idEstadoContrato: number = 0;
  public firmado: boolean = false
  public montoTotalContrato: number = 0;
  public montoTotalSellado: number = 0;
  public montoTotalHonorario: number = 0;
  public idTipoLiquidacion: number = 0;
  public porcentajeComisionPropietario: number = 0;
  public numeroContrato: number = 0
  public idFormaPagoPropietario!: number
  public frecuenciaAjusteMensual!: number
  public idTipoAjusteContrato!: number
  public fechaModificacion!: string
  public fechaCreacion!: string
}


export class ContratoListado {
  public idContrato: number = 0;
  public inquilino: BaseDto = new BaseDto;// Id Personas y Nombre completo
  public propiedad: BaseDto = new BaseDto; // Id Propiedad y Ubicacion
  public tomo: string = "";
  public folio: string = "";
  public numero: string = "";
  public fechaComienzo?: string;
  public fechaVencimiento?: string;
  public fechaResicion?: string;
  public tipoContrato: BaseDto = new BaseDto;
  public estadoContrato: BaseDto = new BaseDto;
  public porcentajeDuracion: number = 0;
}
