import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalidadDto } from '@models/localidad-provincia/localidadDto.model';
import { BaseDto } from '@models/base/baseDto.model';

import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class LocalidadesService {

  LocalidadDto:LocalidadDto= new LocalidadDto() ;
  lstLocalidadDto: LocalidadDto[] = [];

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http:HttpClient) {
  }

  getAll():Observable<LocalidadDto[]> {
    return   this.http.get<LocalidadDto[]>("/api/Localidades/GetAll");
  }

  getLocalidadesByProvincia(idLocalidad:number):Observable<LocalidadDto[]>{
    return  this.http.get<LocalidadDto[]>("/api/Localidades/GetAllLocalidadesByProvincia?Id_Provincia="+idLocalidad);
  }

  getLocalidadByName(nombreLocalidad:string):Observable<LocalidadDto[]>
  {
    return  this.http.get<LocalidadDto[]>("/api/Localidades/getLocalidadPorNombre?idLocalidad="+nombreLocalidad);
  }

  getLocalidadById(idLocalidad:number):Observable<LocalidadDto>
  {
    return  this.http.get<LocalidadDto>("/api/Localidades/GetLocalidadById?idLocalidad="+idLocalidad);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Localidades/Delete?id=" + id);
    else
      return of(null)
  }

}
