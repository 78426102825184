import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogCalculaIndiceAjustePeriodoContrato } from '../dialog-calcula-indice-ajuste-periodo-contrato/dialog-calcula-indice-ajuste-periodo-contrato.component';

@Component({
  selector: 'app-btn-calcula-indice-ajuste-periodo-contrato',
  templateUrl: './btn-calcula-indice-ajuste-periodo-contrato.component.html',
  styleUrls: ['./btn-calcula-indice-ajuste-periodo-contrato.component.css']
})
export class BtnCalculaIndiceAjustePeriodoContrato implements OnInit {


  @Output() procesoFinalizado = new  EventEmitter()

  constructor(
    private dialog: MatDialog,
    private snackBar: SnackBarService
  ) { }

  ngOnInit(): void {
  }

  openDialogNuevoCalculoIcl() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "30%"
    dialogConfig.data = { event: AccionesDialog.Agregar }
    dialogConfig.disableClose = true

    const dialogRef = this.dialog.open(DialogCalculaIndiceAjustePeriodoContrato,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Aceptar){
        //this.snackBar.showSuccess("Proceso finalizado correctamente.", "Exito");
        this.procesoFinalizado.emit(true)
      }

      if (result.event == AccionesDialog.Cancelar)
        this.snackBar.showInfo("Proceso cancelado.", "Info");
    });
  }

}
