export class CreatePropiedadRequest {
    public id_EstadoPropiedad?: number
    public id_Localidad?: number
    public ubicacion?: string
    public piso?: string
    public departamento?: string
    public codigoCpa?: string
    public observacion?: string
    public activo:boolean= false;
    public precio?: number
    public fechaAlta?:  string
    public superficieTotal?: number
    public superficieCubierta?: number
    public idTipoPropiedad?: number
    public idSubtipoPropiedad?: number
    public antiguedad?: string
    public idTipoFolio?: number
    public numero?:string
    public nroFolio?:string
    public tomo?:string
    public horizontal: boolean = false
    public id_PropiedadClase?: number
}
