import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContadoPagosPorFormaPagoDto } from '@models/dashboard/contadoPagosPorFormaPagoDto.model';
import { ContadorContratoDto } from '@models/dashboard/contadorContratoDto.model';
import { ContadorContratoPorPropiedadClaseDto } from '@models/dashboard/contadorContratoPorPropiedadClaseDto.model';
import { ContadorDetalleServicioPropiedadDto } from '@models/dashboard/contadorDetalleServicioPropiedadDto.model';
import { ContadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto } from '@models/dashboard/contadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto.model';
import { ContadorEntidadDto } from '@models/dashboard/contadorEntidadDto.model';
import { ContadorPagosDto } from '@models/dashboard/contadorPagosDto.model';
import { ContadorPropiedadDto } from '@models/dashboard/contadorPropiedadDto.model';
import { ContadorServiciosPorEstadoDetalleServicioPropiedad } from '@models/dashboard/contadorServiciosPorEstadoDetalleServicioPropiedad.mode';
import { ContadorTipoPersonaDto } from '@models/dashboard/contadorTipoPersonaDto.model';
import { ContratoPorVencerDto } from '@models/dashboard/contratoPorVencerDto.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }


  getContadorEntidades(): Observable<ContadorEntidadDto> {
    return this.http.get<ContadorEntidadDto>('/api/Dashboard/GetContadorEntidades')
  }

  getContadorContratos(): Observable<ContadorContratoDto> {
    return this.http.get<ContadorContratoDto>('/api/Dashboard/GetContadorContratos')
  }

  getContadorPropiedades(): Observable<ContadorPropiedadDto> {
    return this.http.get<ContadorPropiedadDto>('/api/Dashboard/GetContadorPropiedades')
  }

  getContadorTiposPersona(): Observable<ContadorTipoPersonaDto> {
    return this.http.get<ContadorTipoPersonaDto>('/api/Dashboard/GetContadorTiposPersona')
  }

  getContratosPorVencer(): Observable<ContratoPorVencerDto[]> {
    return this.http.get<ContratoPorVencerDto[]>('/api/Dashboard/GetContratosPorVencer')
  }

  getContadorContratoPorPripiedadClase(): Observable<ContadorContratoPorPropiedadClaseDto> {
    return this.http.get<ContadorContratoPorPropiedadClaseDto>('/api/Dashboard/GetContadorContratoPorPripiedadClase')
  }

  getContadorDetalleServicioPropiedad(fechaDesde: string, fechaHasta: string): Observable<ContadorDetalleServicioPropiedadDto> {
    return this.http.get<ContadorDetalleServicioPropiedadDto>('/api/Dashboard/GetContadorDetalleServicioPropiedad?fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta)
  }

  getContadorPagosPorFormaPago(fechaDesde: string, fechaHasta: string): Observable<ContadoPagosPorFormaPagoDto> {
    return this.http.get<ContadoPagosPorFormaPagoDto>('/api/Dashboard/GetContadorPagosPorFormaPago?fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta)
  }

  getContadorPagos(fechaDesde: string, fechaHasta: string): Observable<ContadorPagosDto> {
    return this.http.get<ContadorPagosDto>('/api/Dashboard/GetContadorPagos?fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta)
  }

  getContadorDetalleServicioPropiedadPorEstadoYServicio(fechaDesde: string, fechaHasta: string): Observable<ContadorServiciosPorEstadoDetalleServicioPropiedad> {
    return this.http.get<ContadorServiciosPorEstadoDetalleServicioPropiedad>('/api/Dashboard/GetContadorDetalleServicioPropiedadPorEstadoYServicio?fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta)
  }

  getContadorDetalleServicioPropiedadFechaDesdeHastaPeriodo(fechaDesde: string, fechaHasta: string): Observable<ContadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto> {
    return this.http.get<ContadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto>('/api/Dashboard/GetContadorDetalleServicioPropiedadFechaDesdeHastaPeriodo?fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta)
  }

}
