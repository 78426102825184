import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateTipoMonedaRequest } from '@models/tipo-moneda/createTipoMonedaRequest.model';
import { TipoMonedaDto } from '@models/tipo-moneda/tipoMonedaDto.model';
import { UpdateTipoMonedaRequest } from '@models/tipo-moneda/updateTipoMonedaRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class TiposMonedaService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient
  ) { }

  create(createTipoMonedaRequest:CreateTipoMonedaRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/TipoMoneda/Create",createTipoMonedaRequest);
  }

  update(updateTipoMonedaRequest:UpdateTipoMonedaRequest):Observable<any>
  {
    return  this.http.put<any>("/api/TipoMoneda/Update",updateTipoMonedaRequest);
  }

  getAll():Observable<TipoMonedaDto[]>
  {
    return  this.http.get<TipoMonedaDto[]>("/api/TipoMoneda/GetAll");
  }

  getById(id:number):Observable<TipoMonedaDto>
  {
    return  this.http.get<TipoMonedaDto>("/api/TipoMoneda/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/TipoMoneda/Delete?id=" + id);
    else
      return of(null)
  }
}
