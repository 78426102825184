<h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <form [formGroup]="form">
        <mat-form-field *ngIf="action != 'Eliminar'; else elseTemplate">
            <input cdkFocusInitial placeholder="{{action}} Descripcion" formControlName="Descripcion" matInput [(ngModel)]="local_data.descripcion">
            <mat-error *ngIf="f.Descripcion.errors?.required">Este campo es <strong>requerido</strong>.
            </mat-error>
        </mat-form-field>
    </form>
    <ng-template #elseTemplate>
        ¿Esta seguro que desea eliminar el registro <b>{{local_data.descripcion}}</b>?
    </ng-template><br>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" *appRole="['TiposCondicionContable.Crear','TiposCondicionContable.Modificar','TiposCondicionContable.Eliminar']" (click)="doAction()" [disabled]="!form.valid">{{action}}</button>
    <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancelar</button>
</div>
