<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <form [formGroup]="form" *ngIf="action != 'Eliminar'">
        <div class="row">
            <div class="col-6">
                <mat-form-field>
                    <ngx-spinner [zIndex]=100 name="spBusqueda" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Tipo Garantia</mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="TipoGarantia">
                        <mat-option [value]="tipoGarantia.id" *ngFor="let tipoGarantia of lstTiposGarantia;let i=index;trackBy:trackByItems">
                            {{tipoGarantia.descripcion}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.TipoGarantia.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field>
                    <input cdkFocusInitial type="number" formControlName="Cantidad" placeholder="{{action}} Cantidad" matInput>
                    <mat-error *ngIf="f.Cantidad.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>

    <div *ngIf="action == 'Eliminar'">
        ¿Esta seguro que desea eliminar el registro {{local_data.tipoGarantia.descripcion}} * {{local_data.cantidad}}?
    </div><br>
</div>
<br>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="doAction()" [disabled]="!form.valid"*appRole="['CantidadTiposGarantia.Crear','CantidadTiposGarantia.Modificar','CantidadTiposGarantia.Eliminar']"> {{action}}</button>
    <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
</div>
