<mat-form-field>
    <ngx-spinner [zIndex]="1" name="spBusquedaInqSinCon" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
    <mat-label>Buscar inquilino</mat-label>
    <input type="search" placeholder="Buscar por nombre." matInput [matAutocomplete]="auto" [formControl]="entityControl" />
    <button disabled mat-icon-button matSuffix>
          <mat-icon>search</mat-icon>
      </button>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option [disabled]="disable" *ngFor="let inquilino of filteredInquilinos | async ;let i=index;trackBy:trackByItems" [value]="inquilino.persona.descripcion" (click)="selectValue(inquilino)" (onSelectionChange)="selectValue(inquilino)">
            <span>{{inquilino.persona.descripcion | titlecase}}</span>
        </mat-option>
    </mat-autocomplete>

    <button mat-icon-button matSuffix *ngIf="entitySelect && entitySelect.persona" (click)="editarPersona(entitySelect.persona.id)" matTooltip="Editar persona" matTooltipPosition="below">
    <mat-icon color="primary">info</mat-icon>
    </button>

    <button mat-icon-button matSuffix matTooltip="Limpiar" *ngIf="entitySelect" (click)="selectValue(null)">
    <mat-icon>clear</mat-icon>
    </button>
</mat-form-field>
