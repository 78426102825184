import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogGenerarLiquidacionesComponent } from '../views/liquidaciones/dialog-generar-liquidaciones/dialog-generar-liquidaciones.component';
import { LiquidacionesGeneradasComponent } from '../views/liquidaciones/liquidaciones-generadas/liquidaciones-generadas.component';
import { LiquidacionesComponent } from '../views/liquidaciones/liquidaciones/liquidaciones.component';
import { SharedModule } from './shared.module';


@NgModule({
  declarations: [
    DialogGenerarLiquidacionesComponent,
    LiquidacionesComponent,
    LiquidacionesGeneradasComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[
    DialogGenerarLiquidacionesComponent,
    LiquidacionesComponent,
    LiquidacionesGeneradasComponent,
  ]
})
export class LiquidacionesModule { }
