import { BaseDto } from "@models/base/baseDto.model"
import { ServicioPropiedadDto } from "../servicio-propiedad/servicioPropiedadDto.model"


export class Vencimiento{
  public idDetalle: number= 0
  public fechaVencimiento: string = ""
  public importeVencimiento: number = 0
  public seleccionPago:boolean = false
}
export class DetalleServicioPropiedadDetailDto {
    public id: number = 0
    public descripcion: string = ""
    public servicioPropiedad: ServicioPropiedadDto = new ServicioPropiedadDto
    public periodoMes: number = 0
    public periodoAnio: number = 0
    public fechaVencimiento1: string = ""
    public importeVencimiento1: number = 0
    public fechaVencimiento2: string = ""
    public importeVencimiento2: number = 0
    public fechaVencimiento3!: string
    public importeVencimiento3: number = 0
    public fechaVencimiento4!: string
    public importeVencimiento4: number = 0
    public cuota: number = 0
    public pendientePago = true
    public comision: number = 0
    public periodo: string  = ""
    public estado: BaseDto = new BaseDto
    public quienPaga:BaseDto = new BaseDto
    public vencimientos: Vencimiento[] = []
    public seleccionPago:boolean = false
    public importeATenerEnCuenta:number = 0
  }
