import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
/**
 * https://blog.angular-university.io/angular-custom-validators/
 * Valida que el Plan de Pago sea menor que la duracion del contrato
 * @param planPago
 * @param duracionContrato
 * @returns ValidatorFn
 */

export function planDePagoValidator(duracionContrato: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    let esPlanPagoExcedido = false
    if (!value) {
      return null;
    }
    if (value > duracionContrato) {
      esPlanPagoExcedido = true
    }
    return esPlanPagoExcedido ? { planPagoExcedido: true } : null;
  }
}

/**
 * Valida que la duracion del contrato sea mayor o igual a la cantidad de periodos cargados
 * @param cantidadPeriodos
 * @returns ValidatorFn
 */
export function duracionContratoExcedePeriodos(cantidadPeriodos: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    let esduracionContratoExcede = false
    if (!value) {
      return null;
    }
    if (value < cantidadPeriodos) {
      esduracionContratoExcede = true
    }
    return esduracionContratoExcede ? { duracionContratoExcede: true } : null;
  }
}


export function validarPeriodoContrato(controlNameDesde: string, controlNameHasta: string, cantidadPeriodos: number) {
  return (formGroup: FormGroup) => {
    const controlPeriodoDesde = formGroup.controls[controlNameDesde];
    const controlPeriodoHasta = formGroup.controls[controlNameHasta];

    if (controlPeriodoDesde.value != "" && controlPeriodoHasta.value != "") {
      let desde = parseInt(controlPeriodoDesde.value)
      let hasta = parseInt(controlPeriodoHasta.value)
      if (desde > hasta && hasta <= cantidadPeriodos) {
        controlPeriodoHasta.setErrors({ validarPeriodoContrato: true });
      }
      else {
        controlPeriodoHasta.setErrors(null);
      }

    } else {
      controlPeriodoHasta.setErrors(null);
    }
  }
}

export function validarCantidadPeriodos(controlNameHasta: string, cantidadPeriodos: number) {
  return (formGroup: FormGroup) => {
    const controlPeriodoHasta = formGroup.controls[controlNameHasta];

    if (controlPeriodoHasta.value > cantidadPeriodos) {
      controlPeriodoHasta.setErrors({ validarCantidadPeriodos: true });
    }
    else {
      controlPeriodoHasta.setErrors(null);
    }
  }
}

/**
 * Valida que la fecha de comienzo de contrato sea mayor o igual a la de hoy.
 * @param fechaComienzoControl
 * @returns
 */
export function validarFechaComienzoContrato(fechaComienzoControl: string) {
  return (formGroup: FormGroup) => {
    const controlFechaComienzo = formGroup.controls[fechaComienzoControl];

    if (controlFechaComienzo.value != "") {
      let fechaComienzo = new Date(controlFechaComienzo.value)
      var hoy = new Date
      if (fechaComienzo < hoy) {
        controlFechaComienzo.setErrors({ validarFechaComienzoContrato: true });
      }
      else {
        controlFechaComienzo.setErrors(null);
      }

    } else {
      controlFechaComienzo.setErrors(null);
    }
  }
}

/**
 * Valida que las fecha de comienzo de contrato sea menor a la fecha de finalización.
 * @param fechaComienzoControl
 * @param fechaFinalizacionControl
 * @returns
 */

export function validarFechasContrato(fechaComienzoControl: string, fechaFinalizacionControl: string) {
  return (formGroup: FormGroup) => {
    const controlFechaComienzo = formGroup.controls[fechaComienzoControl];
    const controlFechaFinalizacion = formGroup.controls[fechaFinalizacionControl];

    if (controlFechaComienzo.value != "" && controlFechaFinalizacion.value != "") {
      let fechaComienzo = new Date(controlFechaComienzo.value)
      let fechaFinalizacion = new Date(controlFechaComienzo.value)
      if (fechaComienzo > fechaFinalizacion) {
        controlFechaComienzo.setErrors({ validarFechasContrato: true });
      }
      else {
        controlFechaComienzo.setErrors(null);
      }

    } else {
      controlFechaComienzo.setErrors(null);
    }
  }
}
