<button mat-raised-button color="primary" *appRole="['IndiceAjusteContrato.Crear']" (click)="openDialog('Agregar',{})"
  color="primary">
  <mat-icon>add</mat-icon>
  Nuevo Indice
</button>&nbsp;
<a mat-raised-button
  href="http://www.bcra.gov.ar/PublicacionesEstadisticas/Principales_variables_datos.asp?serie=7988&detalle=%CDndice%20para%20Contratos%20de%20Locaci%F3n%20(ICL-Ley%2027.551,%20con%20dos%20decimales,%20base%2030.6.20=1)"
  target="_blank">Web BCRA</a>
<!-- <button mat-raised-button color="primary" (click)="openDialogWebIclReferencia()">Adquirir valores</button> -->
<br><br>
<mat-card *appRole="['IndiceAjusteContrato.Ver']">


  <form [formGroup]="form">
    <div class="row">
      <div class="col-3">
        <mat-form-field class="full-width">
          <mat-label>Ingrese rango de fechas</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [disabled]="true" formControlName="FechaDesde" placeholder="Fecha desde" />
            <input matEndDate [disabled]="true" formControlName="FechaHasta" placeholder="Fecha hasta" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="col-lg-3">
        <mat-form-field>
          <mat-label>Rangos rapidos</mat-label>
          <mat-select placeholder="Seleccione opcion" formControlName="RangoFecha"
            (selectionChange)="selectRangoFecha($event)">
            <mat-option [value]="fecha.id"
              *ngFor="let fecha of lstRangoFecha ;let i=index;trackBy:trackByItems">
              {{fecha.descripcion | titlecase}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="example-full-width">
          <ngx-spinner name="spBusquedaTipoAjusteContrato" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
          <mat-label>Tipos de Ajuste Contrato</mat-label>
          <mat-select placeholder="Seleccione opcion" multiple formControlName="TiposAjusteContrato">
            <mat-option [value]="tipoAjusteContrato.id" *ngFor="let tipoAjusteContrato of lstTiposAjusteContrato;let i=index;trackBy:trackByItems">
              {{ tipoAjusteContrato.descripcion | titlecase }}({{ tipoAjusteContrato.acronimo | uppercase }})</mat-option>
        </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-stroked-button color="primary" (click)="filtrar()">
          <mat-icon>filter_alt</mat-icon>
          Filtrar</button>&nbsp; &nbsp;
      </div>

    </div>
  </form>

  <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
  </ngx-spinner>
  <mat-form-field class="example-full-width">
    <mat-label>Buscar</mat-label>
    <input matInput type="search" (keyup)="applyFilter($event)">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <br>
  <table mat-table [dataSource]="dataSource" [hidden]=!data table matSort matSortStart="asc" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Id Column -->
    <!-- <ng-container matColumnDef="id">
            <mat-header-cell mat-header-cell *matHeaderCellDef> # </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
        </ng-container> -->

    <!-- Fecha Column -->
    <ng-container matColumnDef="fecha">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element"> {{element.fecha | date:"dd/MM/yyyy"}} </mat-cell>
    </ng-container>

    <!-- VAlor Column -->
    <ng-container matColumnDef="valor">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Valor </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element"> {{element.valor}} </mat-cell>
    </ng-container>

    <!-- Descripcion Column -->
    <ng-container matColumnDef="tipoAjusteContratoDto">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">
              <span [matTooltip]="element.tipoAjusteContrato.descripcion | titlecase">{{element.tipoAjusteContrato.acronimo | uppercase}} </span></mat-cell>
        </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="accion">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element" class="action-link">

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *appRole="['IndiceAjusteContrato.Modificar']" matTooltip="Editar"
            matTooltipPosition="right" (click)="openDialog('Modificar',element)">
            <mat-icon>edit</mat-icon>
            <span>Editar</span>
          </button>
          <button mat-menu-item *appRole="['IndiceAjusteContrato.Eliminar']" matTooltip="Eliminar"
            matTooltipPosition="right" (click)="openDialog('Eliminar',element)">
            <mat-icon>delete</mat-icon>
            <span>Eliminar</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </table>

  <div>

  </div>
  <div class="no-data-table">
    <span class="with-icon" *ngIf="dataSource.data.length == 0">
      <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
    </span>
  </div>
  <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20,50,100,200]">
  </mat-paginator>
</mat-card>
