import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RenterDeliveredNecessaryGuaranteesResponse } from '@models/inquilino/renterDeliveredNecessaryGuaranteesResponse.model';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { PersonasService } from '@services/personas/personas.service';

@Component({
  selector: 'app-inquilino',
  templateUrl: './inquilino.component.html',
  styleUrls: ['./inquilino.component.css']
})
export class InquilinoComponent implements OnInit {

  personaDetailDto!: PersonaDetailDto
  actualizaGrantias!: number;
  renterDeliveredNecessaryGuaranteesResponse!: RenterDeliveredNecessaryGuaranteesResponse;

  constructor(
    private personasService: PersonasService,
    private router: Router
  ) { }


  ngOnInit(): void {
    this.personaDetailDto = this.personasService.getPersona()
    if (this.personaDetailDto.esInquilino)
      this.router.navigate(["/inquilino/editar"])
  }

  nuevoInquilino($event: any) {
    this.personaDetailDto.esInquilino = $event
  }

  asignaGarantia(event: number) {
    this.actualizaGrantias = event
  }






}
