export class UpdateGarantiaRequest {

    public id : number=0;
    public id_Persona : number=0;
    public id_TipoGrantia : number=0;
    public idTipoFolio:number = 0
    public razonSocial : string="";
    public nroFolio : string="";
    public tomo : string="";
    public numero : string="";
    public cuitCuil : string="";
    public ubicacion : string="";
    public localidad : string="";
    public sueldo : number=0;
    public indivisa : number=100;
    public horizontal : boolean=false;
}
