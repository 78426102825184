import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { TiposFolios } from '@models/base/identificadores.model';
import { LocalidadDto } from '@models/localidad-provincia/localidadDto.model';
import { LocalidadBasicDto } from '@models/localidad-provincia/localidadBasicDto.model';
import { PropiedadDto } from '@models/propiedades/propiedadDto.model';
import { TipoPropiedadDto } from '@models/propiedades/tipoPropiedadDto.model';
import { UpdatePropiedadRequest } from '@models/propiedades/updatePropiedadRequest.model';
import { PropiedadesService } from '@services/propiedades/propiedades.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { SubtipoPropiedadService } from '@services/subtiposPropiedad/subtipo-propiedad.service';
import { TiposFolioService } from '@services/tipos-folio/tipos-folio.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-form-update-propiedad',
  templateUrl: './form-update-propiedad.component.html',
  styleUrls: ['./form-update-propiedad.component.css']
})
export class FormUpdatePropiedadComponent implements OnInit {
  propiedadForm!: FormGroup
  idPropiedad: number = 0;
  formBuilder: FormBuilder = new FormBuilder;
  idPersona: any;
  propiedadDto: PropiedadDto = new PropiedadDto
  idTipoFolio: any;
  localidad: LocalidadBasicDto = new LocalidadBasicDto;
  get f() { return this.propiedadForm.controls; }
  lstSubtiposPropiedad: BaseDto[] = []
  lstTiposPropiedad: TipoPropiedadDto[] = []
  lstEstadosPropiedad: BaseDto[] = []
  lstPropiedadClases: BaseDto[] = []
  lstTiposFolios: BaseDto[] = [];
  propiedadHorizontal = false
  tiposFolios!: TiposFolios

  constructor(
    private snackBar: SnackBarService,
    private propiedadesService: PropiedadesService,
    private spinner: NgxSpinnerService,
    private aRoute: ActivatedRoute,
    private subtipoPropiedadService: SubtipoPropiedadService,
    private tiposFoliosServices: TiposFolioService,
  ) {
    this.propiedadForm = this.formBuilder.group({
      EstadoPropiedad: [null, Validators.compose([Validators.required])],
      ClasePropiedad: [null, Validators.compose([Validators.required])],
      Ubicacion: ['', Validators.compose([Validators.maxLength(200), Validators.required])],
      CodigoCpa: ['', Validators.compose([Validators.maxLength(8)])],
      Antiguedad: ['', Validators.compose([Validators.maxLength(50)])],
      Piso: [''],
      Departamento: [''],
      SuperficieTotal: [''],
      SuperficieCubierta: [''],
      TiposPropiedad: [null, Validators.compose([Validators.required])],
      SubtiposPropiedad: [null],
      Precio: ['',Validators.compose([Validators.maxLength(20)])],
      FechaAlta: [''],
      Observacion: ['', Validators.compose([Validators.maxLength(2000)])],
      TipoFolio: [null, Validators.compose([Validators.required])],
      NroFolio: [''],
      Tomo: [''],
      Numero: [''],
      Horizontal: [false],
    });

  }

  ngOnInit(): void {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idPropiedad = Number(routeParams.get('id'));

    this.spinner.show("spPropiedad")
    forkJoin({
      estadosPropiedad: this.propiedadesService.getAllEstadosPropiedad(),
      tiposPropiedad: this.propiedadesService.getAllTiposPropiedad(),
      tiposFolio: this.tiposFoliosServices.getAll(),
      propiedadClases: this.propiedadesService.GetAllClasePropiedad(),

    })
      .subscribe(
        ({ estadosPropiedad, tiposPropiedad, tiposFolio, propiedadClases }) => {

          this.lstEstadosPropiedad = estadosPropiedad;
          this.lstTiposPropiedad = tiposPropiedad;
          this.lstTiposFolios = tiposFolio;
          this.lstPropiedadClases = propiedadClases;
          this.spinner.hide("spPropiedad")

        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spPropiedad")
        });

    this.getPropiedadById(this.idPropiedad)

    this.propiedadesService.setIdPropiedad(this.idPropiedad)
    this.propiedadForm.controls['EstadoPropiedad'].disable();



  }

  goBack() {
    window.history.back();
  }

  selectTipoFolio(event: any) {
    this.idTipoFolio = event;
  }

  getPropiedadById(id: number) {
    this.spinner.show("spPropiedad")
    this.propiedadesService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spPropiedad")
          this.propiedadDto = data
          this.setPropiedadFormulario(this.propiedadDto)
        },
        error => {
          this.spinner.hide("spPropiedad")
        });

  }


  setPropiedadFormulario(propiedadDto: PropiedadDto) {
    this.propiedadForm.controls['EstadoPropiedad'].setValue(isFalsy(propiedadDto.estadoPropiedad) ? null : propiedadDto.estadoPropiedad.id);
    this.propiedadForm.controls['Ubicacion'].setValue(propiedadDto.ubicacion.toUpperCase());
    this.propiedadForm.controls['Piso'].setValue(propiedadDto.piso);
    this.propiedadForm.controls['Departamento'].setValue(propiedadDto.departamento);
    this.propiedadForm.controls['SuperficieTotal'].setValue(propiedadDto.superficieTotal);
    this.propiedadForm.controls['SuperficieCubierta'].setValue(propiedadDto.superficieCubierta);
    this.propiedadForm.controls['Precio'].setValue(propiedadDto.precio);
    this.propiedadForm.controls['CodigoCpa'].setValue(propiedadDto.codigoCpa);
    this.propiedadForm.controls['Antiguedad'].setValue(propiedadDto.antiguedad);
    this.propiedadForm.controls['Observacion'].setValue(propiedadDto.observacion);
    this.propiedadForm.controls['TiposPropiedad'].setValue(isFalsy(propiedadDto.tipoPropiedad) ? null : propiedadDto.tipoPropiedad.id);
    this.propiedadForm.controls['ClasePropiedad'].setValue(isFalsy(propiedadDto.propiedadClase) ? null : propiedadDto.propiedadClase.id);
    this.propiedadForm.controls['TipoFolio'].setValue(propiedadDto.tipoFolio.id);
    this.propiedadForm.controls['Tomo'].setValue(propiedadDto.tomo);
    this.propiedadForm.controls['NroFolio'].setValue(propiedadDto.numeroFolio);
    this.propiedadForm.controls['Numero'].setValue(propiedadDto.numero)
    this.propiedadForm.controls['Horizontal'].setValue(propiedadDto.horizontal)
    this.idTipoFolio = propiedadDto.tipoFolio.id
    this.localidad.id = propiedadDto.localidad.id
    this.localidad.provincia.id = propiedadDto.localidad.provincia.id

    if (!isFalsy(propiedadDto.tipoPropiedad))
      if (propiedadDto.tipoPropiedad.id > 0)
        this.getAllSubtiposPropiedad(propiedadDto.tipoPropiedad.id)

    if (!isFalsy(propiedadDto.subtipoPropiedad))
      this.propiedadForm.controls['SubtiposPropiedad'].setValue(propiedadDto.subtipoPropiedad.id);

    //this.propiedadForm.controls['FechaAlta'].setValue(convierteFecha(propiedadDto.fechaAlta));
    //this.propiedadForm.controls['FechaAlta'].disable();
  }

  getAllSubtiposPropiedad(id_TiposPropiedad: number) {
    this.spinner.show("spPropiedad")
    this.subtipoPropiedadService.getAllByIdTipoPropiedad(id_TiposPropiedad).subscribe(
      data => {
        this.spinner.hide("spPropiedad")
        this.lstSubtiposPropiedad = data
      },
      error => {
        this.spinner.hide("spPropiedad")
        this.snackBar.showError(error, "Error");
      });
  }

  selectTiposPropiedad(envent: any) {
    this.spinner.show("spSubTipoPropiedad")
    this.subtipoPropiedadService.getAllByIdTipoPropiedad(envent.value).subscribe(
      data => {
        this.spinner.hide("spSubTipoPropiedad")
        this.lstSubtiposPropiedad = data
        this.lstSubtiposPropiedad.length === 0 ? this.propiedadForm.controls['SubtiposPropiedad'].disable() : this.propiedadForm.controls['SubtiposPropiedad'].enable()
      },
      error => {
        this.spinner.hide("spSubTipoPropiedad")
        this.snackBar.showError(error, "Error");
      });
  }


  onSubmit() {
    if (this.propiedadForm.invalid || this.localidad.id == 0) {
      return;
    }
    else {
      this.updatePropiedad()
    }
  }

  setUpdatePropiedadRequest(): UpdatePropiedadRequest {
    let updatePropiedadRequest = new UpdatePropiedadRequest
    updatePropiedadRequest.id = this.idPropiedad
    updatePropiedadRequest.id_EstadoPropiedad = this.propiedadForm.controls['EstadoPropiedad'].value;
    updatePropiedadRequest.ubicacion = this.propiedadForm.controls['Ubicacion'].value;
    updatePropiedadRequest.departamento = this.propiedadForm.controls['Departamento'].value;
    updatePropiedadRequest.piso = this.propiedadForm.controls['Piso'].value;
    updatePropiedadRequest.id_Localidad = this.localidad.id
    updatePropiedadRequest.id_PropiedadClase = this.propiedadForm.controls['ClasePropiedad'].value;
    updatePropiedadRequest.idTipoPropiedad = this.propiedadForm.controls['TiposPropiedad'].value;
    updatePropiedadRequest.idSubtipoPropiedad = this.propiedadForm.controls['SubtiposPropiedad'].value;
    updatePropiedadRequest.superficieTotal = this.propiedadForm.controls['SuperficieTotal'].value;
    updatePropiedadRequest.superficieCubierta = this.propiedadForm.controls['SuperficieCubierta'].value;
    updatePropiedadRequest.precio = this.propiedadForm.controls['Precio'].value;
    updatePropiedadRequest.antiguedad = this.propiedadForm.controls['Antiguedad'].value;
    updatePropiedadRequest.codigoCpa = this.propiedadForm.controls['CodigoCpa'].value;
    updatePropiedadRequest.observacion = this.propiedadForm.controls['Observacion'].value;
    updatePropiedadRequest.horizontal = this.propiedadForm.controls['Horizontal'].value;
    updatePropiedadRequest.activo = this.propiedadDto.activo
    updatePropiedadRequest.idTipoFolio = this.propiedadForm.controls['TipoFolio'].value
    updatePropiedadRequest.nroFolio = this.propiedadForm.controls['NroFolio'].value

    if (updatePropiedadRequest.idTipoFolio == TiposFolios.FolioPersonalCronologico) {
      if (updatePropiedadRequest.tomo != "")
        updatePropiedadRequest.tomo = this.propiedadForm.controls['Tomo'].value
      else {
        updatePropiedadRequest.tomo = "0"
      }

      if (updatePropiedadRequest.numero != "")
        updatePropiedadRequest.numero = this.propiedadForm.controls['Numero'].value
      else {
        updatePropiedadRequest.numero = "0"
      }

    }
    else {
      updatePropiedadRequest.tomo = ""
      updatePropiedadRequest.numero = ""
    }

    return updatePropiedadRequest
  }

  updatePropiedad() {
    this.spinner.show("spPropiedad")
    const updatePropiedadRequest = this.setUpdatePropiedadRequest();
    this.propiedadesService.update(updatePropiedadRequest).subscribe(
      data => {
        this.spinner.hide("spPropiedad")
        this.snackBar.showSuccess('Se actualizo la propiedad de manera correcta.', "");
      },
      error => {
        this.spinner.hide("spPropiedad")
        this.snackBar.showError(error, "Error");
      });
  }


  onReset() {
    this.propiedadForm.reset();
  }

  seleccionaLocalidad(data: LocalidadDto) {
    this.localidad.id = data!= null?data.id: 0
  }

  trackByItems(index: number, item: any): any { return item; }

}
