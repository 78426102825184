export class AuditoriaLogFilter{
  public RequestScheme!:string
  public RequestHost!:string
  public RequestPath!:string
  public RequestQueryString!:string
  public fechaDesde!:string
  public fechaHasta!:string
  public idUsuario!:number
  public duration!:number
  public iPAddress!:string
  public httpMethod!:string
  public fullUrl!:string
  public browserInfo!:string
  public responseData!:string
  public requestData!:string
  public httpStatusCode!:number
  public timestamp!:Date
}
