<br>
<div class="container-fluid" *appRole="['Dashboard.Propiedades']">
    <div class="row">
        <!-- Accesos Rapidos   -->
        <div class="col-lg-4 col-md-12 col-sm-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Acciones
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <app-btn-nuevo-propiedad></app-btn-nuevo-propiedad>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <!-- Contratos por estado -->
        <div class="col-lg-8 col-md-12 col-sm-12">
            <mat-card>
                <ngx-spinner name="spGetContadorPropiedades" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                </ngx-spinner>
                <mat-card-header>
                    <mat-card-title>
                        Estados de propiedades
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content *ngIf="contadorPropiedadDto">
                  <div class="row">
                    <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
                      <div class="with-icon d-flex align-items-center">
                        <mat-icon class="icon-success mr-2">real_estate_agent</mat-icon>
                        <span class="mr-1"><strong>Con contrato:</strong></span>
                        <span class="count-number">{{contadorPropiedadDto.conContrato}}</span>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
                      <div class="with-icon d-flex align-items-center">
                        <mat-icon class="icon-info mr-2">real_estate_agent</mat-icon>
                        <span class="mr-1"><strong>Sin contrato:</strong></span>
                        <span class="count-number">{{contadorPropiedadDto.sinContrato}}</span>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
                      <div class="with-icon d-flex align-items-center">
                        <mat-icon class="icon-info mr-2">real_estate_agent</mat-icon>
                        <span class="mr-1"><strong>Deuda:</strong></span>
                        <span class="count-number">{{contadorPropiedadDto.deuda}}</span>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
                      <div class="with-icon d-flex align-items-center">
                        <mat-icon class="icon-info mr-2">real_estate_agent</mat-icon>
                        <span class="mr-1"><strong>Legales:</strong></span>
                        <span class="count-number">{{contadorPropiedadDto.legales}}</span>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-12 col-sm-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Filtro de Servicios
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form [formGroup]="form">
                        <mat-form-field class="full-width">
                            <mat-label>Ingrese rango de fechas</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate [disabled]="true" formControlName="FechaDesde" placeholder="Fecha desde">
                                <input matEndDate [disabled]="true" formControlName="FechaHasta" placeholder="Fecha hasta">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                        </mat-form-field>&nbsp;&nbsp;
                        <button mat-stroked-button color="primary" (click)="filtrarDetallesServiciosPropiedad()">
<mat-icon>filter_alt</mat-icon>
Filtrar</button>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-lg-4 col-md-12 col-sm-12">
            <mat-card>
                <ngx-spinner name="spGetContadorDetalleServicioPropiedadFechaDesdeHastaPeriodo" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                </ngx-spinner>
                <mat-card-header>
                    <mat-card-title>
                        Estado de Servicios
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="contadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto">
                  <div class="row">
                    <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
                      <div class="with-icon d-flex align-items-center">
                        <mat-icon class="icon-success mr-2">gavel</mat-icon>
                        <span class="mr-1"><strong>Cobrados:</strong></span>
                        <span class="count-number">{{contadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto.cobrados}}</span>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
                      <div class="with-icon d-flex align-items-center">
                        <mat-icon class="icon-info mr-2">home</mat-icon>
                        <span class="mr-1"><strong>No vencidos:</strong></span>
                        <span class="count-number">{{contadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto.noVencidos}}</span>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
                      <div class="with-icon d-flex align-items-center">
                        <mat-icon class="icon-info mr-2">business</mat-icon>
                        <span class="mr-1"><strong>Deuda:</strong></span>
                        <span class="count-number">{{contadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto.deuda}}</span>
                      </div>
                    </div>
                  </div>
                </mat-card-content>

            </mat-card>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12">
            <mat-card>
                <ngx-spinner name="spGetContadorDetalleServicioPropiedadPorEstadoYServicio" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                </ngx-spinner>
                <mat-card-header>
                    <mat-card-title>
                        Estados de Servicio por Tipo Servicio
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="lstEstadosServiciosPorTipo">
                    <mat-list role="list" *ngFor="let item of lstEstadosServiciosPorTipo;let i=index;trackBy:trackByItems">

                        <mat-list-item role="listitem">
                            <p matLine><strong>{{item.servicio}}</strong></p>
                            <br>
                            <div matLine class="servicio-contenido">
                                <div class="row">
                                    <div class="col-lg-3 col-md-6 col-sm-12">
                                        <span [matTooltip]="item.cantidadPagada.toString()" matTooltipPosition="above" [matBadge]="item.cantidadPagada" matBadgeOverlap="false">
          <span  class="badge bg-success text-light">Pagados </span>
                                        </span>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-12">
                                        <span [matTooltip]="item.cantidadCobrado.toString()" matTooltipPosition="above" [matBadge]="item.cantidadCobrado" matBadgeOverlap="false">
          <span class="badge bg-info text-light">Cobrados</span>
                                        </span>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-12">
                                        <span [matTooltip]="item.cantidadNoVencida.toString()" matTooltipPosition="above" [matBadge]="item.cantidadNoVencida" matBadgeOverlap="false">
            <span  class="badge bg-warning text-light">No Vencidos</span>
                                        </span>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-12">
                                        <span [matTooltip]="item.cantidadDeuda.toString()" matTooltipPosition="above" [matBadge]="item.cantidadDeuda" matBadgeOverlap="false">
                  <span  class="badge bg-danger text-light">Deuda</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
