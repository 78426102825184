<button mat-menu-item [routerLink]="['/perfilUsuarios']">
  <mat-icon>account_circle</mat-icon>
  Perfil
</button>
<button mat-menu-item *appRole="['Configuracion.Ver']" [routerLink]="['/configuracion']">
  <mat-icon>admin_panel_settings</mat-icon>
  Configuración
</button>
<a
  mat-menu-item *appRole="['Ayuda.Ver']"
  style="text-decoration: none"
  href="https://massoudwiki.clientes.mariniti.com.ar"
  target="_blank"
>
  <mat-icon>help</mat-icon>
  Ayuda
</a>
<button mat-menu-item (click)="logOut()">
  <mat-icon>logout</mat-icon>
  Cerrar Sesion
</button>
