<h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">

    <form [formGroup]="form" *ngIf="action != 'Eliminar'; else elseTemplate">
        <div class="row">
            <div class="col-lg-4">
                <mat-form-field  class="full-width">
                    <ngx-spinner name="spBusquedaTipoContrato" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Tipo Contrato</mat-label>
                    <mat-select tabindex="1" cdkFocusInitial placeholder="Seleccione opcion" formControlName="TiposContrato">
                        <mat-option [value]="tipoContrato.id" *ngFor="let tipoContrato of lstTiposContrato">
                            {{tipoContrato.descripcion}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.TiposContrato.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-4">
                <mat-form-field  class="full-width">
                    <ngx-spinner name="spBusquedaQuienPaga" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Quien Paga</mat-label>
                    <mat-select tabindex="2" cdkFocusInitial placeholder="Seleccione opcion" formControlName="QuienPaga">
                        <mat-option [value]="quienPaga.id" *ngFor="let quienPaga of lstQuienPaga;let i=index;trackBy:trackByItems">
                            {{quienPaga.descripcion}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.QuienPaga.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-4">
                <mat-form-field  class="full-width">
                    <ngx-spinner name="spBusquedaServicios" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Servicio</mat-label>
                    <mat-select tabindex="3" cdkFocusInitial placeholder="Seleccione opcion" formControlName="Servicios">
                        <mat-option [value]="servicio.id" *ngFor="let servicio of lstServicios;let i=index;trackBy:trackByItems">
                            {{servicio.tipoServicio.descripcion}} - {{servicio.nroBroche}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.Servicios.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>

        </div>
    </form>
    <ng-template #elseTemplate>
        ¿Esta seguro que desea eliminar el registro <b>{{local_data.descripcion}}</b>?
    </ng-template><br>
</div>
<br>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button *appRole="['TiposContratoQuienPaga.Crear','TiposContratoQuienPaga.Modificar','TiposContratoQuienPaga.Eliminar']" color="primary" (click)="doAction()" tabindex="3" [disabled]="!form.valid ">{{action}}</button>
    <button mat-button (click)="closeDialog()" tabindex="4" color="warn">Cancelar</button>
</div>
