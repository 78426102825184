import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateServicioPropiedadRequest } from '@models/servicios/servicio-propiedad/createServicioPropiedadRequest.model';
import { ServiciosPropiedadService } from '@services/servicios/servicios-propiedad/servicios-propiedad.service';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-form-create-servicio-propiedad',
  templateUrl: './form-create-servicio-propiedad.component.html',
  styleUrls: ['./form-create-servicio-propiedad.component.css']
})
export class FormCreateServicioPropiedadComponent implements OnInit {
  formServicioPriopidad: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  idQuienPaga: any;
  idPropiedad!: number;
  servicioPropiedad: any;
  idServcio: any;
  createServicioPropiedadRequest: CreateServicioPropiedadRequest = new CreateServicioPropiedadRequest;
  get f() { return this.formServicioPriopidad.controls; }
  lstQuienPaga: BaseDto[] = []
  private sub: any;
  constructor(
    private serviciosService: ServiciosService,
    private serviciosPropiedadService: ServiciosPropiedadService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {
    this.formServicioPriopidad = this.formBuilder.group({
      //Persona
      NroCuenta: ["", Validators.compose([Validators.maxLength(50),Validators.required])],
      NroGestionPersonal: ['', Validators.compose([Validators.maxLength(50)])],
      ReferenciaPago: ['', Validators.compose([Validators.maxLength(50)])],
    });
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.idPropiedad = +params['idPropiedad'];
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  selectServicio(event: any) {
    this.idServcio = event.id
  }

  selectQuienPaga(event: any) {
    this.idQuienPaga = event.value
  }

  // getAllQuienPaga() {
  //   this.spinner.show()
  //   this.serviciosService.getAllQuienPaga()
  //     .subscribe(
  //       data => {
  //         this.spinner.hide()
  //         this.lstQuienPaga = data
  //       },
  //       error => {
  //         this.spinner.hide()
  //         this.snackBar.showError( error, "Error");
  //       }
  //     )
  // }

  setServicioPropiedad() {
    this.createServicioPropiedadRequest.id_Propiedad = this.idPropiedad
    this.createServicioPropiedadRequest.id_Servicio = this.idServcio

    if (this.formServicioPriopidad.controls["NroCuenta"].value == "") {
      this.createServicioPropiedadRequest.nroCuenta = null
    }
    else {
      this.createServicioPropiedadRequest.nroCuenta = this.formServicioPriopidad.controls["NroCuenta"].value
    }

    if (this.formServicioPriopidad.controls["NroGestionPersonal"].value == "") {
      this.createServicioPropiedadRequest.nroGestionPersonal = null
    }
    else {
      this.createServicioPropiedadRequest.nroGestionPersonal = this.formServicioPriopidad.controls["NroGestionPersonal"].value
    }

    if (this.formServicioPriopidad.controls["ReferenciaPago"].value == "") {
      this.createServicioPropiedadRequest.referenciaPago = null
    }
    else {
      this.createServicioPropiedadRequest.referenciaPago = this.formServicioPriopidad.controls["ReferenciaPago"].value
    }

  }

  create() {
    this.spinner.show()
    this.setServicioPropiedad()
    this.serviciosPropiedadService.createServicioPropiedad(this.createServicioPropiedadRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess(data.text, "Exito")
          this.router.navigate(["/serviciospropiedad/servicio/", this.idPropiedad])
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        });
  }

  cancel() {
    this.router.navigate(["/serviciospropiedad/servicio/", this.idPropiedad])
  }

}
