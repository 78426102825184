import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instructivos-servicios',
  templateUrl: './instructivos-servicios.component.html',
  styleUrls: ['./instructivos-servicios.component.css']
})
export class InstructivosServiciosComponent implements OnInit {


  ayudaOn= false
  constructor() { }

  ngOnInit(): void {
  }

}
