<div class="row content" *appRole="['Servicios.Ver']">
    <div class="col-sm-2">
        <app-nav-panel-propiedad [idPropiedad]="idPropiedad"></app-nav-panel-propiedad>
    </div>
    <div class="col-sm-10">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Servicios</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">water_drop</mat-icon>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <router-outlet></router-outlet>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
