import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { ArchivosService } from '@services/archivos/archivos.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {
  imgURL!: string | ArrayBuffer | null;
  archivo!: ArchivoDto;
  @Input() idArchivo: number = 0
  @Input() archivoDto!: ArchivoDto

  constructor(
    private archivosService: ArchivosService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    if (this.archivoDto != null || this.archivoDto != undefined) {
      if (this.archivoDto.id != null || this.archivoDto.id != undefined)
        this.getArchivoById(this.archivoDto.id)
      else
        this.seleccionArchivo(this.archivoDto)
    }
  }

  seleccionArchivo(archivo: ArchivoDto) {
    var reader = new FileReader();

    const b64toBlob = (b64Data: any, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }

    const blob = b64toBlob(archivo.content.replace("data:" + archivo.contentType + ";base64,", ""), archivo.contentType);

    reader.readAsDataURL(blob);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.archivo = archivo as ArchivoDto
  }

  getArchivoById(id: number) {
    this.spinner.show("spArchivos")
    this.archivosService.getById(id).subscribe(
      data => {
        this.spinner.hide("spArchivos");
        this.archivo = data;
        this.seleccionArchivo(data);
      },
      error => {
        this.spinner.hide("spArchivos")
      }
    )
  }

}
