import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.css']
})
export class GenericDialogComponent implements OnInit {

  head!: string;
  action!: string;
  body!: string;

  constructor(
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { head: string, action: string, body: string } // Definimos el tipo de datos
  ) {}

  ngOnInit(): void {
    // Inicializamos las variables con los datos inyectados
    this.head = this.data.head;
    this.action = this.data.action;
    this.body = this.data.body;
  }

  close(): void {
    this.dialogRef.close(); // Cerramos el diálogo al hacer clic en cerrar
  }
}
