<button mat-raised-button color="primary" *appRole="['Permisos.Crear']" (click)="openDialogCreate()">
  <mat-icon>add</mat-icon>
  Nuevo permiso
</button>&nbsp;
<br><br>

<mat-card *appRole="['Permisos.Ver']">
  <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom"
    [fullScreen]="false"></ngx-spinner>

  <form [formGroup]="form">
    <div class="row">
      <div class="col-12 col-lg-2">
        <mat-form-field class="example-full-width">
          <ngx-spinner [zIndex]="100" name="spBusquedaTiposMovimientos" bdColor="rgba(255,255,255,0.8)" size="small"
            color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
          <mat-label>Roles</mat-label>
          <mat-select placeholder="Seleccione opción" formControlName="Roles">
            <mat-option [value]="rol.id" *ngFor="let rol of roles; let i = index; trackBy: trackByItems">
              {{ rol.descripcion | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-2">
        <mat-form-field class="example-full-width">
          <mat-label for="Importe">Token</mat-label>
          <input type="text" placeholder="Token" formControlName="Token" matInput>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button mat-stroked-button color="primary" (click)="filtrarRoles()">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </button>&nbsp; &nbsp;
      </div>
    </div>
  </form>

  <div class="row" [hidden]="!data">
    <div class="col-12 col-lg-2">
      <mat-form-field class="example-full-width">
        <mat-label>Buscar</mat-label>
        <input type="search" matInput (keyup)="applyFilter($event)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <br>
  <table mat-table [dataSource]="dataSource" [hidden]="!data" matSort matSortStart="asc" class="mat-elevation-z8">
    <ng-container matColumnDef="descripcion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Descripción</mat-header-cell>
      <mat-cell *matCellDef="let element"><span>{{ element.descripcion }}</span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="token">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Token</mat-header-cell>
      <mat-cell *matCellDef="let element"><span>{{ element.token }}</span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="casoUso">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Caso Uso</mat-header-cell>
      <mat-cell *matCellDef="let element"><span>{{ isFalsy(element.casoUso) ? "" : element.casoUso.descripcion
          }}</span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="rol">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Rol</mat-header-cell>
      <mat-cell *matCellDef="let element"><span>{{ isFalsy(element.rol) ? "" : element.rol.descripcion
          }}</span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="accion">
      <mat-header-cell *matHeaderCellDef>Acción</mat-header-cell>
      <mat-cell *matCellDef="let element" class="action-link">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *appRole="['Permisos.Ver']" matTooltip="Ver" matTooltipPosition="right"
            (click)="openDialogVer(element)">
            <mat-icon>visibility</mat-icon>
            <span>Ver</span>
          </button>
          <button mat-menu-item *appRole="['Permisos.Modificar']" matTooltip="Editar" matTooltipPosition="right"
            (click)="openDialogUpdate(element)">
            <mat-icon>edit</mat-icon>
            <span>Editar</span>
          </button>
          <button mat-menu-item *appRole="['Permisos.Eliminar']" matTooltip="Eliminar" matTooltipPosition="right"
            (click)="openDialogDelete(element)">
            <mat-icon>delete</mat-icon>
            <span>Eliminar</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </table>

  <div class="no-data-table" *ngIf="dataSource.data.length == 0">
    <span class="with-icon">
      <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
    </span>
  </div>

  <mat-paginator showFirstLastButtons class="paginator" [hidden]="!data" [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20, 50, 100, 200]">
  </mat-paginator>
</mat-card>
