import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-view-rol',
  templateUrl: './dialog-view-rol.component.html',
  styleUrls: ['./dialog-view-rol.component.css']
})
export class DialogViewRolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
