import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { HotToastService } from '@ngneat/hot-toast';
import { MatTableDataSource } from '@angular/material/table';
import { ResultadoResumenInquilinoMasivoYEnvioCorreo } from '@models/inquilino/resultadoResumenInquilinoMasivoYEnvioCorreo.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BreakpointObserver } from '@angular/cdk/layout';
import { GenericDialogComponent } from '@views/genericos/generic-dialog/generic-dialog.component';
import { DetalleGeneracionResumenMasivoComponent } from '../detalle-generacion-resumen-masivo/detalle-generacion-resumen-masivo.component';

@Component({
  selector: 'app-dialog-generar-resumen-mensual',
  templateUrl: './dialog-generar-resumen-mensual.component.html',
  styleUrls: ['./dialog-generar-resumen-mensual.component.css']
})
export class DialogGenerarResumenMensualComponent implements OnInit {

  formGenerarCalculo: FormGroup;
  confirmaProceso: boolean = false
  esResumenGenerado: boolean = false
  incluirPeriodoPosterior!: boolean;
  detalleResumen!: ResultadoResumenInquilinoMasivoYEnvioCorreo[];
  get f() { return this.formGenerarCalculo.controls; }
  constructor
    (
      private dialog: MatDialog,
      private _formBuilder: FormBuilder,
      public dialogMensajeDetalle: MatDialog,
      private snackBar: SnackBarService,
      private spinner: NgxSpinnerService,
      private inquilinosService: InquilinosService,
      public dialogRef: MatDialogRef<DialogGenerarResumenMensualComponent>,
      private toastService: HotToastService,
      private breakpointObserver: BreakpointObserver,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {



    this.formGenerarCalculo = this._formBuilder.group({
      AcceptTerms: [false, Validators.requiredTrue],
    });
  }


  ngOnInit(): void {

  }

  generarCalculo() {
    this.spinner.show("spresultadoEnvioMasivo");
    this.esResumenGenerado = true;
    this.incluirPeriodoPosterior = true;

    // Mostrar el estado de carga manualmente antes de ejecutar la tarea asíncrona
    const toastId = this.toastService.loading('Por favor, aguarde. Este proceso puede demorar más de lo habitual debido a la generación masiva de resúmenes.', {
      icon: '⏳',  // Puedes usar un emoji o un ícono de librería
      autoClose: false  // Mantener el loading hasta que se actualice manualmente
    });
    // Agregar un temporizador de 10 segundos (por ejemplo)
    setTimeout(() => {
      toastId.updateMessage('El proceso continúa, estamos trabajando en ello...');
      toastId.updateToast({
        icon: '🔄'  // Cambia el icono si lo deseas
      });
    }, 10000);  // 450000 milisegundos = 45 segundos

    this.inquilinosService.getSummaryInquilinoMasivoAndSendEmail(this.incluirPeriodoPosterior, this.data.idContratos)
      .subscribe(
        data => {
          this.spinner.hide("spresultadoEnvioMasivo");
          this.detalleResumen  = data
          this.toastService.success('Proceso finalizado con éxito.');
          toastId.close()
        },
        error => {
          this.spinner.hide("spresultadoEnvioMasivo");
          this.dialogRef.close({ event: AccionesDialog.Aceptar });
          this.toastService.error('Error al procesar resumen.');
          toastId.close()
        }
      );
  }


  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  openDialogDetalleGeneracionResumenMasivo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "90%"
    dialogConfig.data = this.detalleResumen
    dialogConfig.disableClose = true

    const dialogRef = this.dialog.open(DetalleGeneracionResumenMasivoComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
