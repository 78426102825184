import { BaseDto } from "../base/baseDto.model"

export class CuentaContableDto extends BaseDto{
  public numero: string= ""
  public tipo: string = ""
  public banco: BaseDto = new BaseDto
  public cbu: string = ""
  public alias: string = ""
  public fechaBaja!: string
}
