import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-servicio-propiedad',
  templateUrl: './servicio-propiedad.component.html',
  styleUrls: ['./servicio-propiedad.component.css']
})
export class ServicioPropiedadComponent implements OnInit {

  idPropiedad!: number;

  constructor(
    private aRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idPropiedad = Number(routeParams.get('id'));
  }
}
