import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';

@Component({
  selector: 'app-dialog-delete',
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.css']
})
export class DialogDeleteComponent implements OnInit {
  local_data: any;

  constructor(
    public dialogRef: MatDialogRef<DialogDeleteComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.local_data = {...data};
    }

  ngOnInit(): void {
  }

  doAction(){
    this.dialogRef.close({event:AccionesDialog.Eliminar, data: this.local_data.id});
  }

  closeDialog(){
    this.dialogRef.close({event:AccionesDialog.Cancelar});
  }


}
