import { BaseDto } from "../base/baseDto.model";

export class PaisDto extends BaseDto{

  public prefijoTelefonico!: string
  public latitud!: number
  public longitud!: number
  public codigo!: string

  static fromPaisDto(paisDto: PaisDto) {
    const dto = new PaisDto();
    dto.id = paisDto.id;
    dto.prefijoTelefonico = paisDto.prefijoTelefonico;
    dto.descripcion = paisDto.descripcion;
    dto.latitud = paisDto.latitud;
    dto.longitud = paisDto.longitud;
    dto.codigo = paisDto.codigo;
    return dto;
  }
}
