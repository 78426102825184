import { Component, OnInit } from '@angular/core';
import { PersonasService } from '@services/personas/personas.service';

@Component({
  selector: 'app-btn-agregar-garantia',
  templateUrl: './btn-agregar-garantia.component.html',
  styleUrls: ['./btn-agregar-garantia.component.css']
})
export class BtnAgregarGarantiaComponent implements OnInit {
  idPersona:any

  constructor(
    private personasService: PersonasService
  ) { }

  ngOnInit(): void {
    this.idPersona = this.personasService.idPersona
  }

}
