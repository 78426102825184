<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div class="row">
    <div class="col-lg-10">
        <h1 mat-dialog-title>
            Seleccione movimientos</h1>
    </div>
    <div class="col-lg-2">
        <button mat-mini-fab matTooltip="Nuevo movimiento" matTooltipPosition="right" (click)="openDialogNuevoMovimiento()" color="primary">
    <mat-icon>add</mat-icon></button>
    </div>
</div>
<div mat-dialog-content>
    <mat-card>
        <div class="example-container">
            <table mat-table class="example-table" [dataSource]="dataSource">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox style="vertical-align: middle;" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox style="vertical-align: middle;" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Periodo Column -->
                <ng-container matColumnDef="periodo">
                    <th mat-header-cell *matHeaderCellDef> Periodo </th>
                    <td mat-cell *matCellDef="let element"> {{element.periodoMes}}/ {{element.periodoAnio}}- {{element.periodo}}</td>
                </ng-container>

                <!-- Tipo Movimiento Column -->
                <ng-container matColumnDef="tipoMovimiento">
                    <th mat-header-cell *matHeaderCellDef> Tipo Movimiento </th>
                    <td mat-cell *matCellDef="let element"> {{element.tipoMovimiento != undefined ? element.tipoMovimiento.descripcion : "" | titlecase}} </td>

                </ng-container>

                <!-- Importe Column -->
                <ng-container matColumnDef="importe">
                    <th mat-header-cell *matHeaderCellDef> Importe </th>
                    <td mat-cell *matCellDef="let element"> {{
                      element.tipoMovimiento.resta
                          ? (element.importe * -1 | currency)
                          : (element.importe | currency)
                  }} </td>
                </ng-container>

                <!-- Descripcion Column -->
                <ng-container matColumnDef="descripcion">
                    <th mat-header-cell *matHeaderCellDef> Descripción </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button [matTooltip]="isFalsy(element.descripcion) ? '' : element.descripcion">
                        <mat-icon color="primary" *ngIf="!isFalsy(element.descripcion)">info</mat-icon>
                      </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="accion">
                    <th mat-header-cell *matHeaderCellDef> Accion</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" (click)="eliminarMovimiento(element)" matTooltip="Eliminar" matTooltipPosition="right">
                          <mat-icon >delete</mat-icon>
                        </button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
                </tr>
            </table>
        </div>
    </mat-card>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()">Aceptar</button>
    <button mat-button mat-dialog-close color="warn" (click)="onCancel()">Cancelar</button>
</div>
