<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
<h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-lg-6">
                <mat-form-field #descripcion *ngIf="action != 'Eliminar'; ">
                    <input cdkFocusInitial placeholder="{{action}} Descripcion" formControlName="Descripcion" matInput [(ngModel)]="local_data.descripcion">
                    <mat-error *ngIf="f.Descripcion.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-6">
                <mat-form-field *ngIf="action != 'Eliminar'; ">
                    <input placeholder="{{action}} Valor" formControlName="Valor" matInput [(ngModel)]="local_data.valor">
                    <mat-error *ngIf="f.Valor.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>

    <div *ngIf="action == 'Eliminar';">
        ¿Esta seguro que desea eliminar el registro <b>{{local_data.descripcion}}</b>?
    </div><br>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" *appRole="['TiposSellado.Crear','TiposSellado.Modificar','TiposSellado.Eliminar']" (click)="doAction()" [disabled]="!form.valid">{{action}}</button>
    <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
</div>
