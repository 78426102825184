import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreatePersonaCuentaContableRequest } from '@models/cuentas-contable-persona/createPersonaCuentaContableRequest.model';
import { PersonaCuentaContableDto } from '@models/cuentas-contable-persona/personaCuentaContableDto.model';
import { UpdatePersonaCuentaContableRequest } from '@models/cuentas-contable-persona/updatePersonaCuentaContableRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class PersonasCuentasContableService {

  constructor(
    private http: HttpClient
  ) { }


  create(createPersonaCuentaContableRequest:CreatePersonaCuentaContableRequest):Observable<CreateResponseBase>
  {
    return this.http.post<CreateResponseBase>("/api/PersonaCuentaContable/Create", createPersonaCuentaContableRequest)
  }

  update(updatePersonaCuentaContableRequest:UpdatePersonaCuentaContableRequest):Observable<any>
  {
    return  this.http.put<any>("/api/PersonaCuentaContable/Update",updatePersonaCuentaContableRequest);
  }

  getAll():Observable<PersonaCuentaContableDto[]>
  {
    return this.http.get<PersonaCuentaContableDto[]>("/api/PersonaCuentaContable/GetAll")
  }

  getById(id:number):Observable<PersonaCuentaContableDto>
  {
    return  this.http.get<PersonaCuentaContableDto>("/api/PersonaCuentaContable/GetById?id="+id);
  }

  getAllByIdPersona(idPersona:number):Observable<PersonaCuentaContableDto[]>
  {
    return  this.http.get<PersonaCuentaContableDto[]>("/api/PersonaCuentaContable/GetAllByIdPersona?idPersona="+idPersona);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/PersonaCuentaContable/Delete?id=" + id);
    else
      return of(null)
  }
}
