<div class="position-relative" *appRole="['Contratos.GenerarResumenMasivo']">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title><strong>Generar resumenes mensuales</strong></h1>
  <form [formGroup]="formGenerarCalculo" class="position-relative">
    <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
      <span>
        Este proceso genera los resúmenes de todos los contratos seleccionados y activos en el sistema. Se realizaran
        los cálculos de
        deudas correspondiente al periodo corriente y luego se enviara el mismo por correo en formato PDF al Inquilino.
      </span>
      <br>
      <br>

      <div class="alert alert-danger alert-dismissible">
        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
        <strong>Atención!</strong> Si usted esta generando este proceso el mismo día que la fecha de generaciín de
        resumen parametrizada en el sistema, recuerde correr el proceso de actualización de los incides.
      </div>
      <div class="alert alert-info alert-dismissible">
        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
        <strong>Info!</strong> Este proceso realiza la generación de los resúmenes para los inquilinos. El mismo abarca
        las
        deudas pendientes hasta la fecha y lo que debe abonar el mes posterior. Luego se envía un correo con dicha
        informacion
        al correo correspondiente
        del inquilino.
      </div>
      <br>
      <mat-slide-toggle formControlName="AcceptTerms">¿Confirma proceso?</mat-slide-toggle>
      <br>
      <br>
      <div *appRole="['Contratos.GenerarResumenMasivoDetalle']">
        <button mat-icon-button  *ngIf="this.detalleResumen != undefined" (click)="openDialogDetalleGeneracionResumenMasivo()">
          <mat-icon>visibility</mat-icon> Ver resultado del proceso.
        </button>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button *appRole="['Contratos.GenerarResumenMasivo']"
        [disabled]="!formGenerarCalculo.valid || esResumenGenerado" (click)="generarCalculo()" color="primary">
        <mat-icon>change_circle</mat-icon> Iniciar Proceso
      </button>
      <button [disabled]="esResumenGenerado" mat-raised-button (click)="closeDialog()" mat-dialog-close
        color="warn">Cancelar</button>
    </div>
  </form>
</div>
