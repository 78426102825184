import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Genericos } from '@models/base/genericos.model';
import { RangoFechasValores, AccionesDialog } from '@models/base/identificadores.model';
import { CreateIndiceAjusteContratoRequest } from '@models/indice-ajuste-contrato/createIndiceAjusteContratoRequest.model';
import { IndiceAjusteContratoDto } from '@models/indice-ajuste-contrato/indiceAjusteContratoDto.model';
import { IndiceAjusteContratoFilter } from '@models/indice-ajuste-contrato/indiceAjusteContratoFilter.model';
import { TipoAjusteContratoDto } from '@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model';
import { IndiceAjusteContratoService } from '@services/indice-ajuste-contrato/indiceAjusteContrato.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoAjusteContratoService } from '@services/tipo-ajuste-contrato/tipoAjusteContrato.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogIndiceAjusteContratoComponent } from '../dialog-indice-ajuste-contrato/dialog-indice-ajuste-contrato.component';

@Component({
  selector: 'app-btn-nuevo-incide-ajuste-contrato',
  templateUrl: './btn-nuevo-incide-ajuste-contrato.component.html',
  styleUrls: ['./btn-nuevo-incide-ajuste-contrato.component.css']
})
export class BtnNuevoIndiceAjusteContratoComponent implements OnInit {


  dataSource = new MatTableDataSource<IndiceAjusteContratoDto>();
  obj!: undefined
  displayedColumns: string[] = [];
  data = false
  lstRangoFecha = Genericos.lstRangoFecha
  form: FormGroup;
  formBuilder = new FormBuilder
  rangoFecha: number = RangoFechasValores.Hoy // Hoy

  @ViewChild(MatTable, { static: true }) table!: MatTable<IndiceAjusteContratoDto>;

  private paginator!: MatPaginator;
  private sort!: MatSort;
  lstTiposAjusteContrato!: TipoAjusteContratoDto[];
  indiceAjusteContratoFilter!: IndiceAjusteContratoFilter

  constructor(
    public dialog: MatDialog,
    private indiceAjusteContratoService: IndiceAjusteContratoService,
    private snackBar: SnackBarService,
    private tipoAjusteContratoService: TipoAjusteContratoService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private datepipe: DatePipe
  ) {

    this.form = this.formBuilder.group({
      TiposAjusteContrato: [''],
      FechaDesde: [new Date((new Date().getTime() - RangoFechasValores.Hoy)), Validators.compose([Validators.required,])],
      FechaHasta: [new Date(), Validators.compose([Validators.required,])],
    })

    breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['fecha', 'valor', 'tipoAjusteContratoDto', 'accion'] :
        ['fecha', 'valor', 'tipoAjusteContratoDto', 'accion']
    });
  }


  ngOnInit() {
    this.getAllTipoAjusteContrato()
  }


  addRowData(row_obj: { fecha: string, valor: number, idTipoAjusteContrato: number, descripcion: string }) {
    this.spinner.show()
    let createIndiceAjusteContratoRequest = new CreateIndiceAjusteContratoRequest
    createIndiceAjusteContratoRequest.fecha = row_obj.fecha
    createIndiceAjusteContratoRequest.valor = row_obj.valor
    createIndiceAjusteContratoRequest.activo = true
    createIndiceAjusteContratoRequest.idTipoAjusteContrato = row_obj.idTipoAjusteContrato
    createIndiceAjusteContratoRequest.descripcion = row_obj.descripcion

    if (createIndiceAjusteContratoRequest) {
      this.spinner.show()
      this.indiceAjusteContratoService.create(createIndiceAjusteContratoRequest)
        .subscribe(
          data => {
            if (data.id >= 0) {
              this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
            }
            else
              this.snackBar.showWarn("No se puede ingresar mas de un valor para la misma fecha.", "Exito");
            this.spinner.hide()

          },
          error => {
            this.spinner.hide()
            this.snackBar.showError(error, "Error");
          }
        )
    }
    else {
      this.spinner.hide()
      this.snackBar.showWarn("El registro ya existe", "Error");
    }
  }


  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj,
      dialogConfig.width = "70%"

    const dialogRef = this.dialog.open(DialogIndiceAjusteContratoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.addRowData(result.data);
      }
    });
  }

  getAllTipoAjusteContrato() {
    this.spinner.show("spBusquedaTipoAjusteContrato")
    this.tipoAjusteContratoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTipoAjusteContrato")
          this.lstTiposAjusteContrato = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
        },
        error => {
          this.spinner.hide("spBusquedaTipoAjusteContrato")
          this.snackBar.showError(error, "Error");
        }
      )
  }


  //#region Helper

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
  //#endregion

}
