<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>
    Calcular punitorios</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div [formGroup]="form">
        <mat-card>
            <div class="row">
                <div class="col-lg-6">
                    <p>Importe Alquiler: {{periodo.importeAlquiler | currency}}</p>
                    <p>Tipo Punitorio: {{contrato.tipoPunitorio.descripcion}}</p>
                </div>
                <div class="col-lg-6">
                    <p>Tipo Monto: {{contrato.tipoMontoPunitorio.descripcion}}</p>
                    <p>Valor Punitorio: {{contrato.tipoPunitorio.valor}}</p>
                </div>
            </div>
        </mat-card>
        <div class="row">
            <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                    <span matPrefix>$ &nbsp;</span>
                    <mat-label>Importe Entregado</mat-label>
                    <input matInput name="importeEntrega" required type="text" mask="separator.2" thousandSeparator="," formControlName="importeEntrega" (change)="calcularPunitorioPorImporte()" />
                    <mat-error *ngIf="f.importeEntrega.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-6">
                <mat-form-field>
                    <mat-label>Punitorio hasta:</mat-label>
                    <input matInput [matDatepicker]="picker" [matDatepickerFilter]="filterWeekends" formControlName="fechaHastaPunitorio" (dateChange)="calcularPunitorioPorFecha($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="f.fechaHastaPunitorio.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <p><b>Imp. Dif. Alquiler: </b> {{importeAlquilerSinPunitorio | currency}}</p>
                <p><b>Imp. Dif. Punitorio: </b> {{importePunitorioEntrega | currency }}</p>
            </div>
            <div class="col-lg-6">
                <h2>Total: {{importeAlquilerSinPunitorio + importePunitorioEntrega | currency}}</h2>
            </div>
        </div>
    </div>
    <div *ngIf="notificacion != ''" class="alert alert-danger alert-dismissible fade show" role="alert">
        <span>{{notificacion}}</span>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span >&times;</span>
    </button>
    </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="!form.valid || notificacion != '' || importePunitorioEntrega + importeAlquilerSinPunitorio == 0 " (click)="onSubmit()">Aceptar</button>
    <button mat-button mat-dialog-close color="warn" (click)="onCancel()">Cancelar</button>
</div>
