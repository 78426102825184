<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
    <span *ngFor="let child of items;let i=index;trackBy:trackByItems">
      <span *ngIf="child.children && child.children.length > 0">
        <button mat-menu-item [matMenuTriggerFor]="menu.childMenu">
         <mat-icon >{{child.iconName}}</mat-icon>
          <span>{{child.displayName}}</span>
    </button>
    <app-menu-item #menu [items]="child.children"></app-menu-item>
    </span>
    <span *ngIf="!child.children || child.children.length === 0">
        <button mat-menu-item [routerLink]="child.route">
           <mat-icon>{{child.iconName}}</mat-icon>
          <span>{{child.displayName}}</span>
    </button>
    </span>
    </span>
</mat-menu>
