<br>
<div class="container-fluid" *appRole="['Dashboard.General']">
  <div class="row">
    <!-- Accesos Rápidos -->
    <div class="col-lg-12 col-md-12 col-sm-12" *appRole="['Dashboard.General.Acciones']">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Acciones
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col" style="margin-bottom: 10px;">
              <app-btn-nuevo-propiedad *appRole="['Propiedades.Crear']"></app-btn-nuevo-propiedad>
            </div>
            <div class="col" style="margin-bottom: 10px;">
              <app-btn-nueva-persona *appRole="['Personas.Crear']"></app-btn-nueva-persona>
            </div>
            <div class="col" style="margin-bottom: 10px;">
              <app-btn-new-inquilino *appRole="['Inquilinos.Crear']"></app-btn-new-inquilino>
            </div>
            <div class="col" style="margin-bottom: 10px;">
              <app-btn-create-movimiento *appRole="['Movimientos.Crear']"></app-btn-create-movimiento>
            </div>
            <div class="col" style="margin-bottom: 10px;">
              <app-btn-create-movimiento-caja *appRole="['Movimientos.Crear']"></app-btn-create-movimiento-caja>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row">
    <!-- Contadores -->
    <div class="col-lg-6 col-md-10 col-sm-12" *appRole="['Dashboard.General.Contadores']">
      <mat-card>
        <ngx-spinner name="spContadores" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom"
          [fullScreen]="false">
        </ngx-spinner>
        <mat-card-header>
          <mat-card-title>
            Entidades
          </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="contadoresEntidades">

          <link rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css" />
          <div class="container">
            <div class="row">
              <div class="col-md-3">
                <div class="card-counter primary">
                  <i class="fa fa-key"></i>
                  <span class="count-numbers">{{contadoresEntidades.inquilinos}}</span>
                  <span class="count-name">Inquilinos</span>
                </div>
              </div>

              <div class="col-md-3">
                <div class="card-counter danger">
                  <i class="fa fa-building"></i>
                  <span class="count-numbers">{{contadoresEntidades.propietarios}}</span>
                  <span class="count-name">Propietarios</span>
                </div>
              </div>

              <div class="col-md-3">
                <div class="card-counter success">
                  <i class="fa fa-home"></i>
                  <span class="count-numbers">{{contadoresEntidades.propiedades}}</span>
                  <span class="count-name">Propiedades</span>
                </div>
              </div>

              <div class="col-md-3">
                <div class="card-counter info">
                  <i class="fa fa-user"></i>
                  <span class="count-numbers">{{contadoresEntidades.garantias}}</span>
                  <span class="count-name">Garantias</span>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Estados de servico -->
    <div class="col-lg-6 col-md-10 col-sm-12" *appRole="['Dashboard.General.Servicios']">
      <mat-card>
        <ngx-spinner name="spGetContadorDetalleServicioPropiedad" bdColor="rgba(255,255,255,0.8)" size="default"
          color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <mat-card-header>
          <mat-card-title>
            Estados de Servicios (hoy)
          </mat-card-title>
        </mat-card-header>

        <mat-card-content *ngIf="contadorDetalleServicioPropiedadDto">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
              <div class="with-icon d-flex align-items-center">
                <mat-icon class="icon-success mr-2">price_check</mat-icon>
                <span class="mr-1"><strong>Cobrados:</strong></span>
                <span class="count-number">{{contadorDetalleServicioPropiedadDto.cobrados}}</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
              <div class="with-icon d-flex align-items-center">
                <mat-icon class="icon-info mr-2">attach_money</mat-icon>
                <span class="mr-1"><strong>No vencidos:</strong></span>
                <span class="count-number">{{contadorDetalleServicioPropiedadDto.noVencidos}}</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
              <div class="with-icon d-flex align-items-center">
                <mat-icon class="icon-info mr-2">money_off</mat-icon>
                <span class="mr-1"><strong>Deuda:</strong></span>
                <span class="count-number">{{contadorDetalleServicioPropiedadDto.deuda}}</span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <!-- Pagos -->
  <div class="row" *appRole="['Dashboard.General.Pagos']">
    <div class="col-lg-4 col-md-12 col-sm-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Filtro de Pagos
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <from [formGroup]="form">
            <mat-form-field class="full-width">
              <mat-label>Ingrese rango de fechas</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate [disabled]="true" formControlName="FechaDesde" placeholder="Fecha desde">
                <input matEndDate [disabled]="true" formControlName="FechaHasta" placeholder="Fecha hasta">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
            </mat-form-field>&nbsp;&nbsp;
            <button mat-stroked-button color="primary" (click)="filtrarPagos()">
              <mat-icon>filter_alt</mat-icon>
              Filtrar</button>
          </from>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-3 col-md-12 col-sm-12">
      <mat-card>
        <ngx-spinner name="spGetContadorPagos" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
          type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <mat-card-header>
          <mat-card-title>
            Estado de Pagos
          </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="contadorPagosDto">
          <div class="row">

            <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
              <div class="with-icon d-flex align-items-center">
                <mat-icon class="icon-info mr-2">attach_money</mat-icon>
                <span class="mr-1"><strong>Completo:</strong></span>
                <span class="count-number">{{contadorPagosDto.completo}}</span>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
              <div class="with-icon d-flex align-items-center">
                <mat-icon class="icon-info mr-2">money_off</mat-icon>
                <span class="mr-1"><strong>Pendientes:</strong></span>
                <span class="count-number">{{contadorPagosDto.pendiente}}</span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-5 col-md-12 col-sm-12">
      <mat-card>
        <ngx-spinner name="spGetContadorPagos" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
          type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <mat-card-header>
          <mat-card-title>
            Pagos por Formas de Pago
          </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="contadoPagosPorFormaPagoDto">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="chart-wrapper">
                <canvas baseChart [data]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                  [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
                </canvas>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
