import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuariosModule } from '@modules/usuarios.module';
import { PersonasModule } from '@modules/personas.module';
import { SharedModule } from '@modules/shared.module';
import { LoginService } from '@services/login/login.service';
import { PerfilUsuarioComponent } from '@views/perfil-usuario/perfil-usuario.component';
import { ChangePasswordComponent } from './changePassword/changePassword.component';
import { IniciarSesionComponent } from './iniciar-sesion/iniciar-sesion.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login/login.component';
import { RecoverPasswordComponent } from './recoverPassword/recoverPassword.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LoginRoutingModule,
    UsuariosModule,
    PersonasModule,

  ],
  declarations: [
    LoginComponent,
    RecoverPasswordComponent,
    IniciarSesionComponent,
    ChangePasswordComponent,
    LoginComponent,
    PerfilUsuarioComponent,
  ],
  exports:[
    LoginComponent,
    RecoverPasswordComponent,
    IniciarSesionComponent,
    ChangePasswordComponent,
    LoginComponent,
    PerfilUsuarioComponent,
  ],
  providers: [
    LoginService,]
})
export class LoginModule { }
