<div class="row content" *appRole="['Propiedades.Ver']">
    <div class="col-sm-2">
        <app-nav-panel-propiedad [idPropiedad]="idPropiedad"></app-nav-panel-propiedad>
    </div>
    <div class="col-sm-10">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Datos propiedad</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">roofing</mat-icon>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <ngx-spinner [zIndex]=100 name="spPropiedad" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>

                    <form [formGroup]="propiedadForm" *ngIf="propiedadDto">
                        <div class="row">

                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Ubicacion</mat-label>
                                    <input matInput name="Ubicacion" style="text-transform: uppercase;" type="text" maxlength="200" formControlName="Ubicacion" />
                                    <mat-error *ngIf="f.Ubicacion.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                    <mat-error *ngIf="f.Ubicacion.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Piso</mat-label>
                                    <input matInput name="Piso" style="text-transform: uppercase;" maxlength="5" type="text" formControlName="Piso" />
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Departamento</mat-label>
                                    <input matInput name="Departamento" style="text-transform: uppercase;" maxlength="5" type="text" formControlName="Departamento" />
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Codigo CPA</mat-label>
                                    <input matInput name="CodigoCpa" type="text" maxlength="8" formControlName="CodigoCpa" />
                                    <mat-error *ngIf="f.CodigoCpa.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                </mat-form-field>
                            </div>

                        </div>

                        <div class="row">

                            <div class="form-group col-md-6">
                                <app-buscar-localidad [localidadBasicDto]="propiedadDto.localidad" (eventoLocalidad)="seleccionaLocalidad($event)"></app-buscar-localidad>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field>
                                    <mat-label>Tipo Propiedad</mat-label>
                                    <mat-select formControlName="TiposPropiedad" (selectionChange)="selectTiposPropiedad($event)">
                                        <mat-option [value]="tipoPropiedad.id" *ngFor="let tipoPropiedad of lstTiposPropiedad ;let i=index;trackBy:trackByItems">
                                            {{tipoPropiedad.descripcion}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="f.TiposPropiedad.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                    <mat-hint>Habilita subtipo de propiedad.</mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field>
                                    <ngx-spinner name="spSubTipoPropiedad" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Subtipo Propiedad</mat-label>
                                    <mat-select formControlName="SubtiposPropiedad">
                                        <mat-option [value]="subtipoPropiedad.id" *ngFor="let subtipoPropiedad of lstSubtiposPropiedad ;let i=index;trackBy:trackByItems">
                                            {{subtipoPropiedad.descripcion}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </div>

                        <div class="row">

                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Antiguedad</mat-label>
                                    <input matInput name="Antiguedad" type="text" maxlength="50" formControlName="Antiguedad" />
                                    <mat-error *ngIf="f.Antiguedad.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Precio</mat-label>
                                    <span matPrefix>U$S &nbsp;</span>
                                    <input matInput name="Precio" min="0" mask="separator.2" thousandSeparator="," type="text" formControlName="Precio" />
                                    <mat-error *ngIf="f.Precio.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field>
                                    <mat-label>Clase Propiedad</mat-label>
                                    <mat-select formControlName="ClasePropiedad">
                                        <mat-option [value]="propiedadClase.id" *ngFor="let propiedadClase of lstPropiedadClases ;let i=index;trackBy:trackByItems">
                                            {{propiedadClase.descripcion}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="f.ClasePropiedad.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3">
                                <mat-form-field>
                                    <mat-label>Tipo de Folio</mat-label>
                                    <mat-select (selectionChange)="selectTipoFolio($event.value)" formControlName="TipoFolio">
                                        <mat-option [value]="tipoFolio.id" *ngFor="let tipoFolio of lstTiposFolios;let i=index;trackBy:trackByItems ">
                                            {{tipoFolio.descripcion}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="f.TipoFolio.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                            <!-- <div class="form-group col-md-3">
                              <mat-form-field>
                                  <mat-label>Fecha Alta</mat-label>
                                  <input matInput name="Fecha Alta" type="datetime" formControlName="FechaAlta" />
                              </mat-form-field>
                          </div> -->
                        </div>
                        <div class="row">

                            <div class="form-group col-md-3" *ngIf="idTipoFolio===1">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Tomo</mat-label>
                                    <input matInput name="Tomo" type="text" maxlength="50" formControlName="Tomo" />
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3" *ngIf="idTipoFolio===1 || idTipoFolio===2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>NroFolio</mat-label>
                                    <input matInput name="NroFolio" type="text" maxlength="50" formControlName="NroFolio" />
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3" *ngIf="idTipoFolio===1">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Numero</mat-label>
                                    <input matInput name="Numero" type="text" maxlength="50" formControlName="Numero" />
                                    <mat-error *ngIf="f.Numero.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Superficie Total</mat-label>
                                    <span matPrefix>m² &nbsp;</span>
                                    <input matInput name="SuperficieTotal" min="0" max="100000000" maxlength="100000000" type="number" formControlName="SuperficieTotal" />
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Superficie Cubierta</mat-label>
                                    <span matPrefix>m² &nbsp;</span>
                                    <input matInput name="SuperficieCubierta" min="0" maxlength="100000000" max="100000000" type="number" formControlName="SuperficieCubierta" />
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-3">
                                <mat-form-field>
                                    <mat-label>Estado Propiedad</mat-label>
                                    <mat-select formControlName="EstadoPropiedad">
                                        <mat-option [value]="estadoPropiedad.id" *ngFor="let estadoPropiedad of lstEstadosPropiedad ;let i=index;trackBy:trackByItems">
                                            {{estadoPropiedad.descripcion}}</mat-option>
                                    </mat-select>

                                    <mat-error *ngIf="f.EstadoPropiedad.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-3">
                                <mat-checkbox formControlName="Horizontal" class="example-disabled-checkbox">
                                    Es propiedad Horizontal?
                                </mat-checkbox>
                            </div>
                        </div>

                        <div class="row">
                            <mat-form-field class="example-full-width col-md-12">
                                <mat-label>Observaciones</mat-label>
                                <textarea matInput #message name="Observacion" formControlName="Observacion"></textarea>
                                <mat-error *ngIf="f.Observacion.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                                <mat-hint align="start"><strong>Amplie información personal</strong> </mat-hint>
                                <mat-hint align="end">{{message.value.length}} / 2000</mat-hint>
                            </mat-form-field>
                        </div>
                        <br>
                        <div class="col-auto my-1">
                            <button mat-raised-button *appRole="['Propiedades.Modificar']" color="primary" style="margin-right:3px;" name="Guardar" [disabled]="!propiedadForm.valid || localidad.id == 0" (click)="onSubmit()">Guardar</button>
                            <button mat-button color="warn" style="margin-right:3px;" name="Cancelar" (click)="goBack()">Cancelar</button>
                        </div>
                    </form>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
        <br>
        <mat-accordion class="example-headers-align" multi *appRole="['Propietarios.Ver']">
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Propietarios</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">apartment</mat-icon>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <app-add-propietario-propiedades>
                    </app-add-propietario-propiedades>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
        <br>
        <mat-accordion class="example-headers-align" multi *appRole="['CaracteristicaPropiedad.Ver']">
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Caracteristicas</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">deck</mat-icon>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <app-caracteristica-propiedad></app-caracteristica-propiedad>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
