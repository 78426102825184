<div *appRole="['Movimientos.Crear']">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title>Agregar nuevo <strong>movimiento</strong></h1>
  <mat-divider></mat-divider>
  <br>
  <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <app-create-movimiento [contratoDetailDto]="data.contratoDetailDto"></app-create-movimiento>
  </div>
</div>
