import { AfterViewInit, Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseDto } from '@models/base/baseDto.model';
import { EstadosPropiedad } from '@models/base/identificadores.model';
import { PropiedadDto } from '@models/propiedades/propiedadDto.model';
import { PropiedadesService } from '@services/propiedades/propiedades.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-buscar-propiedad',
  templateUrl: './buscar-propiedad.component.html',
  styleUrls: ['./buscar-propiedad.component.css']
})
export class BuscarPropiedadComponent implements OnInit {

  public filteredPropiedades!: Observable<BaseDto[]>;
  propiedades: BaseDto[] = []
  public entityControl = new FormControl();
  @Output() eventEntity = new EventEmitter<BaseDto>();
  @Input() propiedadEntrada: BaseDto = new BaseDto;
  @Input() idEstado!: number
  @Input() disable: boolean = false
  entitySelect!: number | null;

  constructor(
    private propiedadesService: PropiedadesService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private router: Router
  ) {
    this.eventEntity = new EventEmitter<BaseDto>();
  }

  ngOnInit(): void {

    switch (this.idEstado) {
      case EstadosPropiedad.SinContrato:
        this.getAllPropiedades(EstadosPropiedad.SinContrato);
        break;
      case EstadosPropiedad.ConContrato || EstadosPropiedad.Legales || EstadosPropiedad.Deuda:
        this.getAllPropiedades(EstadosPropiedad.ConContrato);
        break;
      default:
        this.getAllPropiedades(null);
        break;
    }
  }


  ngOnChanges(): void {
    this.entityControl.setValue(this.propiedadEntrada.descripcion)
  }

  selectValue(event: any) {
    if (isFalsy(event)) {
      this.entitySelect = null
      this.entityControl.setValue("")
    }
    this.eventEntity.emit(event)
  }

  findOption(val: string) {
    this.spinner.show("spBusquedaPropiedad")
    for (let i = 0; i < this.propiedades.length; i++) {
      if (this.propiedades[i].descripcion.toLowerCase() == val.toLowerCase()) {
        //this.eventEntity.emit(this.propiedades[i])
        this.entitySelect = this.propiedades[i].id
      }
    }
    this.spinner.hide("spBusquedaPropiedad")
    return this.propiedades.filter(propiedad => propiedad.descripcion.toLowerCase().includes(val.toLowerCase()));
  }

  getAllPropiedades(idEstado: number | null) {
    this.spinner.show("spBusquedaPropiedad")
    this.propiedadesService.getAllIdAndUbicacionPropiedades(idEstado)
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaPropiedad")
          this.propiedades = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
          this.filteredPropiedades = this.entityControl.valueChanges.pipe(
            startWith(''),
            map((term: any) => this.findOption(term))
          );

        },
        error => {
          this.spinner.hide("spBusquedaPropiedad")
          this.snackBar.showError(error, "Error");
        })
  }

  verPropiedad(entitySelect: any) {
    if (!isFalsy(entitySelect))
      this.router.navigate(['/dashboard'],entitySelect)
  }

  trackByItems(index: number, item: any): any { return item; }

}
