import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateTipoServicioRequest } from '@models/servicios/tipo-servicios/createTipoServicioRequest.model';
import { TipoServicio } from '@models/servicios/tipo-servicios/tipoServicioDto.model';
import { UpdateTipoServicioRequest } from '@models/servicios/tipo-servicios/updateTipoServicioRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class TipoServiciosService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http:HttpClient
  ) { }


  //Tipos Servicios

  getAll():Observable<TipoServicio[]> {
    return   this.http.get<TipoServicio[]>("/api/TipoServicio/GetAll");
  }

  GetById(idTipoServicio:number):Observable<TipoServicio>
  {
    return  this.http.get<TipoServicio>("/api/TipoServicio/GetById?id="+ idTipoServicio);
  }

  create(createTipoServicioRequest:CreateTipoServicioRequest):Observable<any>
  {
    return  this.http.post("/api/TipoServicio/Create",createTipoServicioRequest);
  }

  update(updateTipoServicioRequest:UpdateTipoServicioRequest):Observable<any>
  {
    return  this.http.put("/api/TipoServicio/Update",updateTipoServicioRequest);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/TipoServicio/Delete?id=" + id);
    else
      return of(null)
  }


}
