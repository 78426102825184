import { Component, Input, OnInit } from '@angular/core';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-nav-panel-propiedad',
  templateUrl: './nav-panel-propiedad.component.html',
  styleUrls: ['./nav-panel-propiedad.component.css']
})
export class NavPanelPropiedadComponent implements OnInit {

  @Input() idPropiedad!: number
  propiedadNueva: boolean = false;
  constructor() { }

  ngOnInit(): void {
    if(isFalsy(this.idPropiedad))
    this.propiedadNueva = true
  }

}
