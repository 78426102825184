import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { PropiedadPropietarioDto } from '@models/propiedad-propietario/propiedadPropietarioDto.model';
import { PersonasService } from '@services/personas/personas.service';
import { PropiedadesService } from '@services/propiedades/propiedades.service';
import { PropietariosService } from '@services/propietarios/propietarios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list-propiedades-propietario',
  templateUrl: './list-propiedades-propietario.component.html',
  styleUrls: ['./list-propiedades-propietario.component.css']
})
export class ListPropiedadesPropietarioComponent implements OnInit {

  data = false
  dataSource = new MatTableDataSource<PropiedadPropietarioDto>();
  obj!: undefined
  displayedColumns: string[] = [];
  @Input() idPropietario!: number

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;
  personaDetailDto!: PersonaDetailDto;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  constructor(
    public dialog: MatDialog,
    private propiedadesService: PropiedadesService,
    private propietariosService: PropietariosService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private personasService: PersonasService
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['propiedad','localidad','fechaAlta','estadoPropiedad'] :
        ['propiedad','localidad','fechaAlta','estadoPropiedad'];
    });
  }


  ngOnInit() {
    this.personaDetailDto = this.personasService.getPersona()
    this.getPropietarioByIdPersona(this.personaDetailDto.id)
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllPersonasPropietariosByIdPropiedad(idPropietario: number) {
    this.spinner.show('spPropiedadesPropietario')
    this.propiedadesService.getAllPersonasPropietariosByIdPropiedad(idPropietario)
      .subscribe(
        data => {
          this.spinner.hide('spPropiedadesPropietario')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spPropiedadesPropietario')
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getPropietarioByIdPersona(idPersona: number) {
    this.spinner.show('spPropiedadesPropietario')
    this.propietariosService.getByIdPersona(idPersona)
      .subscribe(
        async data => {
          this.spinner.hide('spPropiedadesPropietario')
          this.getAllPersonasPropietariosByIdPropiedad(data.id);
        },
        error => {
          this.spinner.hide('spPropiedadesPropietario')
          this.snackBar.showError(error, "Error");
        })
  }

}
