<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<div class="row">
    <div class="col-6">
        <button mat-stroked-button *appRole="['Contratos.Modificar']" (click)="nuevosPeriodos()" color="primary">Nuevos periodos</button>
    </div>
    <div class="col-6">
        <button mat-stroked-button *appRole="['Contratos.Adendar']" (click)="aceptaAdenda()" color="primary">Adendar contrato</button>
    </div>
</div>
