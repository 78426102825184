import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/modules/shared.module";
import { PersonasModule } from "./personas.module";
import { BtnAgregarGarantiaComponent } from "../views/garantias/btn-agregar-garantia/btn-agregar-garantia.component";
import { BtnNewGarantiaComponent } from "../views/garantias/btn-new-garantia/btn-new-garantia.component";
import { FormGarantiaCreatePublicComponent } from "../views/garantias/publico/form-garantia-create-public/form-garantia-create-public.component";
import { FormGarantiaCreateComponent } from "../views/garantias/form-garantia-create/form-garantia-create.component";
import { FormGarantiaUpdatePublicComponent } from "../views/garantias/publico/form-garantia-update-public/form-garantia-update-public.component";
import { GarantiaComponent } from "../views/garantias/form-garantia/garantia.component";
import { GarantiasInquilinoPublicComponent } from "../views/garantias/publico/garantias-inquilino-public/garantias-inquilino-public.component";
import { GarantiasComponent } from "../views/garantias/garantias/garantias.component";
import { MisGarantiasCrudComponent } from "../views/garantias/mis-garantias-crud/mis-garantias-crud.component";
import { MisGarantiasComponent } from "../views/garantias/mis-garantias/mis-garantias.component";
import { ListadoGarantiasComponent } from '../views/garantias/listado-garantias/listado-garantias.component';
import { FormGarantiaUpdateComponent } from "../views/garantias/form-garantia-update/form-garantia-update.component";
import { TiposGarantiaComponent } from "../views/tipos-garantia/tipos-garantia/tipos-garantia.component";
import { DialogTipoGarantiaComponent } from "../views/tipos-garantia/dialog-tipo-garantia/dialog-tipo-garantia.component";
import { DialogCantidadTipoGarantiaComponent } from "../views/cantidades-tipo-garantia/dialog-cantidad-tipo-garantia/dialog-cantidad-tipo-garantia.component";
import { CantidadesTipoGarantiaComponent } from "../views/cantidades-tipo-garantia/cantidades-tipo-garantia/cantidades-tipo-garantia.component";
import { DialogGruposTipoGarantiaComponent } from "../views/grupos-tipo-garantia/dialog-grupos-tipo-garantia/dialog-grupos-tipo-garantia.component";
import { GruposTipoGarantiaComponent } from "../views/grupos-tipo-garantia/grupos-tipo-garantia/grupos-tipo-garantia.component";
import { DialogGruposTipoGarantiaInquilinoComponent } from "../views/grupos-tipo-garantia-inquilino/dialog-grupos-tipo-garantia-inquilino/dialog-grupos-tipo-garantia-inquilino.component";
import { GruposTipoGarantiaInquilinoComponent } from "../views/grupos-tipo-garantia-inquilino/grupos-tipo-garantia-inquilino/grupos-tipo-garantia-inquilino.component";
import { ArchivosModule } from "./archivos.module";
import { DocumentosModule } from "./documentos.module";
import { LocalidadModule } from "./localidad.module";
import { ValidarGarantiasPendientesComponent } from "@views/inquilinos/validar-garantias-pendientes/validar-garantias-pendientes.component";
import { FormGarantiaViewPublicComponent } from "@views/garantias/publico/form-garantia-view-public/form-garantia-view-public.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PersonasModule,
    DocumentosModule,
    ArchivosModule,
    LocalidadModule

  ],
  declarations: [
    GarantiaComponent,
    FormGarantiaCreateComponent,
    FormGarantiaUpdateComponent,
    GarantiasComponent,
    FormGarantiaCreatePublicComponent,
    MisGarantiasComponent,
    BtnNewGarantiaComponent,
    BtnAgregarGarantiaComponent,
    FormGarantiaUpdatePublicComponent,
    MisGarantiasCrudComponent,
    GarantiasInquilinoPublicComponent,
    ListadoGarantiasComponent,
    TiposGarantiaComponent,
    DialogTipoGarantiaComponent,
    DialogCantidadTipoGarantiaComponent,
    CantidadesTipoGarantiaComponent,
    DialogGruposTipoGarantiaComponent,
    GruposTipoGarantiaComponent,
    DialogGruposTipoGarantiaInquilinoComponent,
    GruposTipoGarantiaInquilinoComponent,
    ValidarGarantiasPendientesComponent,
    FormGarantiaViewPublicComponent

  ],
  exports: [
    GarantiaComponent,
    FormGarantiaCreateComponent,
    FormGarantiaUpdateComponent,
    GarantiasComponent,
    FormGarantiaCreatePublicComponent,
    MisGarantiasComponent,
    BtnNewGarantiaComponent,
    BtnAgregarGarantiaComponent,
    FormGarantiaUpdatePublicComponent,
    MisGarantiasCrudComponent,
    GarantiasInquilinoPublicComponent,
    TiposGarantiaComponent,
    DialogTipoGarantiaComponent,
    DialogCantidadTipoGarantiaComponent,
    CantidadesTipoGarantiaComponent,
    DialogGruposTipoGarantiaComponent,
    GruposTipoGarantiaComponent,
    DialogGruposTipoGarantiaInquilinoComponent,
    GruposTipoGarantiaInquilinoComponent,
    ValidarGarantiasPendientesComponent,
    FormGarantiaViewPublicComponent

  ]
})

export class GarantiasModule { }
