<mat-card>
  <mat-card-header>
    <mat-card-title>Detalle contrato</mat-card-title>
  </mat-card-header>
  <div class="row">
    <div class="col-lg-3">
      <span class="campo">Contrato:</span>
      <span class="valor">{{isFalsy(contratoDetailDto) || contratoDetailDto == null ? "" :
        contratoDetailDto.numeroContrato}}</span>
    </div>
    <div class="col-lg-3">
      <span class="campo">Tipo Contrato:</span>
      <span class="valor">{{isFalsy(contratoDetailDto.tipoContrato) ? "" :
        contratoDetailDto.tipoContrato.descripcion | titlecase}}</span>
    </div>
    <div class="col-lg-3">
      <span class="campo">Estado:</span>
      <span class="valor">{{isFalsy(contratoDetailDto.estadoContrato) ? "":
        contratoDetailDto.estadoContrato.descripcion | titlecase}}</span>
    </div>
    <div class="col-lg-3">
      <span class="campo">Duración:</span>

      <span class="valor">{{isFalsy(contratoDetailDto) || contratoDetailDto == null ? "":
        contratoDetailDto.fechaComienzo | date:("dd/MM/yyyy")}} - {{isFalsy(contratoDetailDto) || contratoDetailDto ==
        null ? "": contratoDetailDto.fechaVencimiento | date:("dd/MM/yyyy")}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3">
      <span class="campo">Propiedad:</span>
      <span class="valor">{{(isFalsy(contratoDetailDto.propiedad.ubicacion) ? '' : contratoDetailDto.propiedad.ubicacion) +
        (isFalsy(contratoDetailDto.propiedad.piso) ? '' : ' ' + contratoDetailDto.propiedad.piso) +
        (isFalsy(contratoDetailDto.propiedad.departamento) ? '' : ' ' + contratoDetailDto.propiedad.departamento) | titlecase}}</span>
    </div>
    <div class="col-lg-3">
      <span class="campo">Inquilino</span>
      <span class="valor">{{isFalsy(contratoDetailDto.inquilino) ? "" :
        isFalsy(contratoDetailDto.inquilino.persona) ? "" : contratoDetailDto.inquilino.persona.descripcion | titlecase}}</span>
    </div>
    <div class="col-lg-3">
      <span class="campo">Propietarios</span>
      <div *ngIf="!isFalsy(contratoDetailDto)  ">
        <span class="valor"
          *ngFor="let propietario of contratoDetailDto.propietarios">{{isFalsy(propietario.persona) ? "" :propietario.persona.descripcion | titlecase}} -</span>
      </div>
    </div>
  </div>
</mat-card>
