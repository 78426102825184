import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LocalidadModule } from "src/app/modules/localidad.module";
import { SharedModule } from "src/app/modules/shared.module";
import { BuscarPropiedadComponent } from "../views/busquedas/buscar-propiedad/buscar-propiedad.component";
import { ListadoPropiedadesComponent } from "../views/propiedades/propiedades/listado-propiedades/listado-propiedades.component";
import { NavPanelPropiedadComponent } from "../views/propiedades/nav-panel-propiedad/nav-panel-propiedad.component";
import { FormCreatePropiedadComponent } from "../views/propiedades/propiedades/form-create-propiedad/form-create-propiedad.component";
import { FormUpdatePropiedadComponent } from "../views/propiedades/propiedades/form-update-propiedad/form-update-propiedad.component";
import { BtnNuevoPropiedadComponent } from "../views/propiedades/propiedades/btn-nuevo-propiedad/btn-nuevo-propiedad.component";
import { PropietariosModule } from "./propietarios.module";
import { CaracteristicaPropiedadComponent } from "../views/caracteristica-propiedad/caracteristica-propiedad.component";
import { CreateDialogCaracteristicaPropiedadComponent } from "../views/caracteristica-propiedad/create-dialog-caracteristica-propiedad/create-dialog-caracteristica-propiedad.component";
import { DeleteDialogCaracteristicaPropiedadComponent } from "../views/caracteristica-propiedad/delete-dialog-caracteristica-propiedad/delete-dialog-caracteristica-propiedad.component";
import { UpdateDialogCaracteristicaPropiedadComponent } from "../views/caracteristica-propiedad/update-dialog-caracteristica-propiedad/update-dialog-caracteristica-propiedad.component";
import { BtnNuevoTipoCaracteristicaComponent } from "../views/tipos-caracteristicas/btn-nuevo-tipo-caracteristica/btn-nuevo-tipo-caracteristica.component";
import { DialogTipoCaracteristicaComponent } from "../views/tipos-caracteristicas/dialog-tipo-caracteristica/dialog-tipo-caracteristica.component";
import { TiposCaracteristicaComponent } from "../views/tipos-caracteristicas/tipos-caracteristica/tipos-caracteristica.component";
import { DireccionPropiedadPipe } from "@pipes/direccion-propiedad.pipe";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PropietariosModule,
        LocalidadModule
    ],
    declarations: [
        FormCreatePropiedadComponent,
        FormUpdatePropiedadComponent,
        BtnNuevoPropiedadComponent,
        ListadoPropiedadesComponent,
        BtnNuevoTipoCaracteristicaComponent,
        DialogTipoCaracteristicaComponent,
        TiposCaracteristicaComponent,
        CaracteristicaPropiedadComponent,
        CreateDialogCaracteristicaPropiedadComponent,
        UpdateDialogCaracteristicaPropiedadComponent,
        DeleteDialogCaracteristicaPropiedadComponent,
        NavPanelPropiedadComponent,
        BuscarPropiedadComponent,
        DireccionPropiedadPipe,
      ],
    exports: [
        FormCreatePropiedadComponent,
        FormUpdatePropiedadComponent,
        BtnNuevoPropiedadComponent,
        ListadoPropiedadesComponent,
        BtnNuevoPropiedadComponent,
        BtnNuevoTipoCaracteristicaComponent,
        DialogTipoCaracteristicaComponent,
        TiposCaracteristicaComponent,
        CaracteristicaPropiedadComponent,
        CreateDialogCaracteristicaPropiedadComponent,
        UpdateDialogCaracteristicaPropiedadComponent,
        DeleteDialogCaracteristicaPropiedadComponent,
        NavPanelPropiedadComponent,
        BuscarPropiedadComponent,
        DireccionPropiedadPipe

    ]
})

export class PropiedadesModule { }
