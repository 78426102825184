import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateGrupoTipoGarantiaRequest } from '@models/grupos-tipo-garantia/createGrupoTipoGarantiaRequest.model';
import { GrupoTipoGarantiaDto } from '@models/grupos-tipo-garantia/grupoTipoGarantiaDto.model';
import { UpdateGrupoTipoGarantiaRequest } from '@models/grupos-tipo-garantia/updateGrupoTipoGarantiaRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class GruposTipoGarantiaService {

  constructor(
    private http: HttpClient
  ) { }

  create(createGrupoTipoGarantiaRequest:CreateGrupoTipoGarantiaRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/GruposTipoGarantia/Create",createGrupoTipoGarantiaRequest);
  }

  update(updateGrupoTipoGarantiaRequest:UpdateGrupoTipoGarantiaRequest):Observable<any>
  {
    return  this.http.put<any>("/api/GruposTipoGarantia/Update",updateGrupoTipoGarantiaRequest);
  }

  getAll():Observable<GrupoTipoGarantiaDto[]>
  {
    return  this.http.get<GrupoTipoGarantiaDto[]>("/api/GruposTipoGarantia/GetAll");
  }

  getById(id:number):Observable<GrupoTipoGarantiaDto>
  {
    return  this.http.get<GrupoTipoGarantiaDto>("/api/GruposTipoGarantia/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/GruposTipoGarantia/Delete?id=" + id);
    else
      return of(null)
  }

  getGruposTipoGarantias():Observable<BaseDto[]>
  {
    return  this.http.get<BaseDto[]>("/api/GruposTipoGarantia/GetGruposTipoGarantias");
  }
}
