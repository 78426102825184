import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateInquilinoRequest } from '@models/inquilino/createInquilinoRequest.model';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { BaseDto } from '@models/base/baseDto.model';

@Component({
  selector: 'app-agrega-persona-inquilino',
  templateUrl: './agrega-persona-inquilino.component.html',
  styleUrls: ['./agrega-persona-inquilino.component.css']
})
export class AgregaPersonaInquilinoComponent implements OnInit {

  esInquilino: boolean = false;
  personaDetailDto!: PersonaDetailDto;
  @Output() nuevoInquilino = new EventEmitter<boolean>();

  constructor(
    private aRoute: ActivatedRoute,
    private snackBar: SnackBarService,
    private inquilinosService: InquilinosService,
    private personasService: PersonasService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }


  ngOnInit(): void {

    this.personaDetailDto = this.personasService.getPersona()

    this.esInquilino = this.personaDetailDto.esInquilino == true ? true : false

    if (this.esInquilino)
      this.router.navigate(['/inquilino']);
  }

  getPersonaById(idPersona: any) {
    this.spinner.show()
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide()
          this.personaDetailDto = data
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  onSubmit(): void {
    this.spinner.show()
    let createInquilinoRequest: CreateInquilinoRequest = new CreateInquilinoRequest
    createInquilinoRequest.idPersona = this.personaDetailDto.id
    this.inquilinosService.create(createInquilinoRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          if (!isFalsy(data.id)) {
            this.esInquilino = true
            this.personaDetailDto = this.personasService.getPersona()
            this.personaDetailDto.esInquilino = true
            this.personasService.setPersona(this.personaDetailDto)
            let inquilinoDto = new InquilinoDto
            inquilinoDto.id = data.id
            let persona = new BaseDto
            persona.id = this.personaDetailDto.id
            persona.descripcion = this.personaDetailDto.nombreCompleto
            inquilinoDto.persona = persona
            this.inquilinosService.sendInquilinoDto(inquilinoDto)
            this.inquilinosService.inquilinoDto.next(inquilinoDto)
            this.snackBar.showSuccess("Se agrego el inquilino de manera correcta", "Exito");
            this.router.navigate(["/inquilino/editar"])
          }
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })
  }

}
