import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateAsuntoRequest } from '@models/asunto/createAsuntoRequest.model';
import { UpdateAsuntoRequest } from '@models/asunto/updateAsuntoRequest.model';
import { CreateBancoRequest } from '@models/banco/createBancoRequest.model';
import { UpdateBancoRequest } from '@models/banco/updateBancoRequest.model';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class BancosService {


  constructor(private http:HttpClient)  { }


  create(createBancoRequest:CreateBancoRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/Banco/Create",createBancoRequest);
  }

  update(updateBancoRequest:UpdateBancoRequest):Observable<any>
  {
    return  this.http.put<any>("/api/Banco/Update",updateBancoRequest);
  }

  getAll():Observable<BaseDto[]>
  {
    return  this.http.get<BaseDto[]>("/api/Banco/GetAll");
  }

  getById(id:number):Observable<BaseDto>
  {
    return  this.http.get<BaseDto>("/api/Banco/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Banco/Delete?id=" + id);
    else
      return of(null)
  }
}
