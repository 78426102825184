<h1 mat-dialog-title><strong>Nuevo</strong> pago</h1>
<mat-divider></mat-divider>
<br>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-6">
                <mat-form-field  class="full-width">
                  <ngx-spinner [zIndex]=100 name="spBusquedaFormaPago" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                  <mat-label>Formas de pago</mat-label>
                  <mat-select placeholder="Seleccione opcion" formControlName="FormasPago">
                      <mat-option [value]="formaPago" *ngFor="let formaPago of lstFormasPago;let i=index;trackBy:trackByItems">
                          {{formaPago.descripcion}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.FormasPago.errors?.required">Este campo es <strong>requerido</strong>.
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field>
                    <ngx-spinner [zIndex]="1" name="spBusquedaTiposMoneda" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                    <mat-select placeholder="Seleccione opcion" formControlName="TiposMoneda">
                        <mat-option [value]="tipoMoneda.id" *ngFor="let tipoMoneda of lstTiposMoneda ;let i=index;trackBy:trackByItems">
                            {{tipoMoneda.descripcion}} - <strong>{{tipoMoneda.simbolo}}</strong></mat-option>
                    </mat-select>

                    <mat-error *ngIf="f.TiposMoneda.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mat-form-field>
                    <input type="text" mask="separator.2" min=1 thousandSeparator="," placeholder="Importe" formControlName="Importe" matInput>
                    <mat-error *ngIf="f.Importe.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field>
                    <mat-label>Fecha pago:</mat-label>
                    <input matInput [matDatepicker]="pickerFechaPago" formControlName="FechaPago">
                    <mat-datepicker-toggle matSuffix [for]="pickerFechaPago"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFechaPago></mat-datepicker>
                    <mat-error *ngIf="f.FechaPago.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <app-buscar-cuenta-contable [habilitaNuevo]="true" [ccAsignada]="ccAsignada" [cuentaContableEntrada]="cuentaContableOrigen"></app-buscar-cuenta-contable>
            </div>
            <div class="col-6">
                <mat-form-field>
                    <mat-label>Fecha acreditación:</mat-label>
                    <input matInput [matDatepicker]="pickerFechaAcreditacion" formControlName="FechaAcreditacion">
                    <mat-datepicker-toggle matSuffix [for]="pickerFechaAcreditacion"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFechaAcreditacion></mat-datepicker>
                    <button mat-icon-button matSuffix>
                      <mat-icon  color="primary" matTooltip="Se aplica a pagos futuros. Ej: Cheques, trasferencias, etc.">info</mat-icon>
                    </button>
                    <mat-error *ngIf="f.FechaAcreditacion.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-buscar-banco (eventoBanco)="selectBanco($event)"></app-buscar-banco>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field class="full-width-x100">
                    <textarea type="textarea" placeholder="Descripcion" rows="3" formControlName="Descripcion" matInput></textarea>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!form.valid">Agregar</button>
    <button mat-button (click)="onCancel()" color="warn">Cancelar</button>
</div>
