<ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>

<h1 mat-dialog-title>
  Acción realizada :: <strong>{{action}}</strong>
</h1>

<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <form [formGroup]="form" *ngIf="action !== 'Eliminar'; else deleteTemplate">
    <div class="row">
      <div class="col-lg-3">
        <mat-form-field>
          <input
            cdkFocusInitial
            placeholder="{{action}} Nombre"
            type="text"
            formControlName="Nombre"
            matInput>
          <mat-error *ngIf="f.Nombre.errors?.required">
            Este campo es <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-3">
        <mat-form-field>
          <input
            placeholder="{{action}} Descripción"
            formControlName="Descripcion"
            matInput>
          <mat-error *ngIf="f.Descripcion.errors?.required">
            Este campo es <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="NgxEditor__Wrapper">
          <ngx-editor-menu [editor]="editorHtml" [toolbar]="toolbar"></ngx-editor-menu>
          <ngx-editor
            [editor]="editorHtml"
            formControlName="Contenido"
            [disabled]="false"
            [placeholder]="'Escriba aquí...'">
          </ngx-editor>
        </div>

      </div>
    </div>
  </form>

  <ng-template #deleteTemplate>
    ¿Está seguro que desea eliminar el registro <b>{{local_data.nombre}}</b>?
  </ng-template>
</div>
<br>
<mat-divider></mat-divider>

<div mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    *appRole="['Templates.Crear', 'Templates.Modificar', 'Templates.Eliminar']"
    (click)="doAction()"
    [disabled]="!form.valid">
    {{action}}
  </button>
  <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
</div>
