import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LoginService } from '@services/login/login.service';
import { AccionesDialog, ParametrosConfiguracion, Roles } from '@models/base/identificadores.model';
import { FormControl } from '@angular/forms';
import { ProcesosService } from '@services/procesos/procesos.service';
import { HotToastService, ToastConfig } from '@ngneat/hot-toast';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { ContadorContratoDto } from '@models/dashboard/contadorContratoDto.model';
import { ParametrosSistemaService } from '@services/parametro-sistema/parametros-sistema.service';
import { NotificacionDto } from '@models/notificaciones/notificacionDto.model';
import { NotificacionesService } from '@services/notificaciones/notificaciones.service';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';


@Component({
  selector: 'app-dashboard-cliente',
  templateUrl: './dashboard-cliente.component.html',
  styleUrls: ['./dashboard-cliente.component.css']
})
export class DashboardClienteComponent implements OnInit {
  public mostrarDashboard = false
  matButtonToggleGroup = new FormControl();
  contadoresEntidades!: any[];
  contadorContratoDto!: ContadorContratoDto;
  notificaciones: NotificacionDto[] = [];
  usuarioDto!: UsuarioDto
  constructor(
    private loginService: LoginService,
    private procesosService: ProcesosService,
    private toastService: HotToastService,
    private spinner: NgxSpinnerService,
    private parametrosSistemaService: ParametrosSistemaService,
    private snackBar: SnackBarService,
    private notificacionesService: NotificacionesService
  ) {
    let toasConfig = new ToastConfig
    toasConfig.autoClose = false
    toasConfig.reverseOrder= true,
    toasConfig.position= 'bottom-right'
    this.toastService.defaultConfig = toasConfig
    const roles = this.loginService.getIdUserRol()
    this.matButtonToggleGroup.setValue('general')
    switch (roles) {
      case Roles.Usuarios:
        this.mostrarDashboard = false
        break;
      case Roles.Administradores:
        this.mostrarDashboard = true
        break;
      case Roles.Clientes:
        this.mostrarDashboard = false
        break;
      case Roles.SuperAdministradores:
        this.mostrarDashboard = true
        break;
      default:
        this.mostrarDashboard = true
        break;
    }
  }

  ngOnInit() {
    this.usuarioDto = this.loginService.getCurrentUser();

    if (this.loginService.getIdUserRol() == Roles.Clientes) {

    }
  }



}

