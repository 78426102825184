<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
<h1 mat-dialog-title>
    <strong>Adenda</strong> contrato</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <p>Tener en cuenta que al realizar una nueva carga de periodos en un contrato vigente se esta produciendo una
        <b>Adenda</b>. Esto quiere decir que se generara una nueva fecha de finalización de contrato segun los periodos cargados. Ademas no se realizaran ajustes en los montos de sellado ni comisiones.
    </p>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button *appRole="['Contratos.Adendar']" (click)="aceptaAdenda()" color="primary">Aceptar</button>
    <button mat-button cdkFocusInitial (click)="closeDialog()" mat-dialog-close color="warn">Cancelar</button>
</div>
