import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { AsociarDocumentoAEntidadRequest } from '@models/documentos/asociarDocumentoAEntidadRequest.model';
import { CreateDocumentoRequest } from '@models/documentos/createDocumentoRequest.model';
import { CreateDocumentoResponse } from '@models/documentos/createDocumentoResponse.model';
import { DocumentoDto } from '@models/documentos/documentoDto.model';
import { DocumentoEntidadDto } from '@models/documentos/documentoEntidadDto.model';
import { UpdateDocumentoRequest } from '@models/documentos/updateDocumentoRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http:HttpClient
  ) { }

  create(createDocumentoRequest:CreateDocumentoRequest):Observable<CreateDocumentoResponse>
  {
    return  this.http.post<CreateDocumentoResponse>("/api/Documento/Create",createDocumentoRequest);
  }

  update(updateDocumentoRequest:UpdateDocumentoRequest):Observable<any>
  {
    return  this.http.put<any>("/api/Documento/Update",updateDocumentoRequest);
  }

  getAll():Observable<DocumentoDto[]>
  {
    return  this.http.get<DocumentoDto[]>("/api/Documento/GetAll");
  }

  getAllByIdAsunto(idAsunto:number):Observable<BaseDto[]>
  {
    return  this.http.get<BaseDto[]>("/api/Documento/GetAllByIdAsunto?idAsunto="+idAsunto);
  }

  getById(id:number):Observable<DocumentoDto>
  {
    return  this.http.get<DocumentoDto>("/api/Documento/GetById?id="+id);
  }

  asociarDocumentoAEntidad(asociarDocumentoAEntidadRequest:AsociarDocumentoAEntidadRequest):Observable<any>
  {
    return  this.http.patch<any>("/api/Documento/AsociarDocumentoAEntidad",asociarDocumentoAEntidadRequest);
  }

  getByIdTipoEntidadAndIdEntidad(idTipoEntidad:number,idEntidad:number):Observable<DocumentoEntidadDto[]>
  {
    return  this.http.get<DocumentoEntidadDto[]>("/api/Documento/GetByIdTipoEntidadAndIdEntidad?idTipoEntidad="+idTipoEntidad+"&idEntidad="+idEntidad);
  }

  quitarAsociacionDocumentoAEntidad(id:number):Observable<any>
  {
    return  this.http.patch<any>("/api/Documento/QuitarAsociacionDocumentoAEntidad",id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Documento/Delete?id=" + id);
    else
      return of(null)
  }

}
