
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDto } from '@models/base/baseDto.model';

export class HistorialActualizaPeriodosContrato {
  public id: number = 0
  public fechaProceso: string = ""
  public contrato: BaseDto = new BaseDto
  public periodo: number = 0
  public icl: number = 0
  public importeAnterior: number = 0
  public importeNuevo: number = 0
}
@Component({
  selector: 'app-calcula-indice-ajuste-periodos-contrato',
  templateUrl: './calcula-indice-ajuste-periodos-contrato.component.html',
  styleUrls: ['./calcula-indice-ajuste-periodos-contrato.component.css']
})
export class CalculaIndiceAjustePeriodosContratoComponent implements OnInit {
  procesado: boolean = false;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  procesoFinalizado($event: any) {
    this.procesado = $event
  }

}
