import { PersonaDetailDto } from "@models/personas/personaDetailDto.model"
import { Action } from "@ngrx/store"
import { UsuarioDto } from "../models/usuarios/usuarioDto.model"

export const SETPERSONA = 'SETEAPERSONA'
export const SETUSUARIO = 'SETEAUSUARIO'

export class SetearPersona implements Action{
  readonly type= SETPERSONA
  constructor(public personaDetailDto:PersonaDetailDto){}
}

export class SetearUsuario implements Action{
  readonly type= SETUSUARIO
  constructor(public usuarioDto:UsuarioDto){}
}


export type Acciones = SetearPersona | SetearUsuario
