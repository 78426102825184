import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateCasosUsoRequest } from '@models/seguridad/casosUso/createCasosUsoRequest.model';
import { MenuDto } from '@models/seguridad/menu/menuDto.model';
import { CasosUsoService } from '@services/seguridad/casos-uso/casos-uso.service';
import { MenuService } from '@services/seguridad/menu/menu.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogCreateCasoUsoComponent } from './dialog-create-caso-uso/dialog-create-caso-uso.component';

@Component({
  selector: 'app-crear-caso-uso',
  templateUrl: './crear-caso-uso.component.html',
  styleUrls: ['./crear-caso-uso.component.css']
})
export class CrearCasoUsoComponent implements OnInit {

  formBuilder = new FormBuilder;
  form: any;
  menus!: MenuDto[];
  get f() { return this.form.controls; }
  constructor(
    public dialogRef: MatDialogRef<DialogCreateCasoUsoComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private casosUsoService: CasosUsoService,
    private menuService: MenuService
  ) {

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
      Icono: ["", Validators.compose([Validators.required,])],
      Path: ["", Validators.compose([Validators.required])],
      Menu: ["", Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
    this.getAllMenus()
  }
  getAllMenus() {
    this.spinner.show("spBusquedaMenu")
    this.menuService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaMenu")
          this.menus = data
        },
        error => {
          this.spinner.hide("spBusquedaMenu")
          this.snackBar.showError(error, "Error");
        }
      )
  }


  doAction() {

    let createCasosUsoRequest: CreateCasosUsoRequest = new CreateCasosUsoRequest
    createCasosUsoRequest.descripcion = this.form.controls["Descripcion"].value
    createCasosUsoRequest.icon = this.form.controls["Icono"].value
    createCasosUsoRequest.path = this.form.controls["Path"].value
    createCasosUsoRequest.id_menu = this.form.controls["Menu"].value
    this.create(createCasosUsoRequest)
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  create(createCasosUsoRequest: CreateCasosUsoRequest) {
    this.spinner.show("spCrearCasoUso")
    this.casosUsoService.create(createCasosUsoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spCrearCasoUso")
          this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Agregar });
        },
        error => {
          this.spinner.hide("spCrearCasoUso")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}
