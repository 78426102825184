import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { SendEmailComponent } from '../send-email/send-email.component';

@Component({
  selector: 'app-btn-send-email',
  templateUrl: './btn-send-email.component.html',
  styleUrls: ['./btn-send-email.component.css']
})
export class BtnSendEmailComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }


  openDialogCreateMovimiento() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "90%";


    let dialogRef = this.dialog.open(SendEmailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: { event: AccionesDialog; }) => {
      if (result.event == AccionesDialog.Agregar) {
        //this.addRowData(result.data);
      } else if (result.event == AccionesDialog.Cancelar) {
        //this.deleteRowData(result.data);
      }
    });
  }

}
