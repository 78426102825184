import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateTipoFolioRequest } from '@models/tipo-folio/createTipoFolioRequest.model';
import { UpdateTipoFolioRequest } from '@models/tipo-folio/updateTipoFolioRequestUpdateTipoFolioRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class TiposFolioService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http:HttpClient)  { }


  create(createTipoFolioRequest:CreateTipoFolioRequest):Observable<any>
  {
    return  this.http.post<any>("/api/TipoFolio/Create",createTipoFolioRequest);
  }

  update(updateTipoFolioRequest:UpdateTipoFolioRequest):Observable<any>
  {
    return  this.http.put<any>("/api/TipoFolio/Update",updateTipoFolioRequest);
  }

  getAll():Observable<BaseDto[]>
  {
    return  this.http.get<BaseDto[]>("/api/TipoFolio/GetAll");
  }

  getById(id:number):Observable<BaseDto>
  {
    return  this.http.get<BaseDto>("/api/TipoFolio/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/TipoFolio/Delete?id=" + id);
    else
      return of(null)
  }
}
