import { PersonaDetailDto } from "@models/personas/personaDetailDto.model";
import { BaseDto } from "../base/baseDto.model";
import { RolDto } from "../seguridad/roles/rolDto.model";


export class UsuarioDto extends BaseDto{

    public estado? : boolean;
    public name : string = "";
    public password:string ="";
    public rol : RolDto= new RolDto;
    public persona : PersonaDetailDto = new PersonaDetailDto;
    public changePassword? : boolean;
    public empresa: BaseDto = {id:1, descripcion: "Massoud Inmobiliaria"};

}
