<div class="row content" *appRole="['Contratos.Ver']">
    <div class="col-sm-2">
        <app-nav-panel-contrato [idContrato]="contratoDetailDto.id"></app-nav-panel-contrato>
    </div>
    <div class="col-sm-10">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    Periodos Historicos
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-listado-periodos-historicos [idContrato]="contratoDetailDto.id"></app-listado-periodos-historicos>
            </mat-card-content>
        </mat-card>
    </div>
</div>
