import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { isFalsy } from 'utility-types';
import { DialogCreateUpdateDeleteComponent } from '../dialog-create-update-delete/dialog-create-update-delete.component';

@Component({
  selector: 'app-dialog-nuevo-email',
  templateUrl: './dialog-nuevo-email.component.html',
  styleUrls: ['./dialog-nuevo-email.component.css']
})
export class DialogNuevoEmailComponent implements OnInit {

  action: string;
  local_data: any;
  form: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;

  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogCreateUpdateDeleteComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: BaseDto) {
    this.local_data = { ...data };
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,Validators.email, Validators.maxLength(50)])],
    })
  }

  ngOnInit(): void {
    this.form.controls["Descripcion"].setValue(this.local_data.receiverAddress)
  }
  doAction() {
    var email = this.form.controls["Descripcion"].value
    if (!isFalsy(email))
      this.dialogRef.close({ event: AccionesDialog.Agregar, data: email });
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

}
