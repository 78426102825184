import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ResindirContratoRequest } from '@models/contrato/resindirContratoRequest.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-rescision-contrato',
  templateUrl: './dialog-rescision-contrato.component.html',
  styleUrls: ['./dialog-rescision-contrato.component.css']
})
export class DialogRescisionContratoComponent implements OnInit {

  controlFechaRescicion: FormControl = new FormControl
  form: any;
  formBuilder: FormBuilder = new FormBuilder;
  startDate = new Date();

  get f() { return this.form.controls; }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private contratosService: ContratosService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    public dialogRef: MatDialogRef<DialogRescisionContratoComponent>) {
    this.form = this.formBuilder.group({
      FechaRescision: ['', Validators.compose([Validators.required])]
    })
  }


  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  clickRescicionContrato() {
    const fechaRescision = this.form.controls["FechaRescision"].value
    if (!isFalsy(fechaRescision) && !isFalsy(this.data.id)) {
      let resindirContratoRequest = new ResindirContratoRequest
      resindirContratoRequest.fechaResicion = fechaRescision
      resindirContratoRequest.idContrato = this.data.id
      this.rescisionContrato(resindirContratoRequest)
    }

  }

  rescisionContrato(resindirContratoRequest: ResindirContratoRequest) {
    this.spinner.show()
    this.contratosService.rescisionContrato(resindirContratoRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Contrato resindido de manera correcta.", "Exito");
          this.endContrato();
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  endContrato() {
    this.spinner.show()
    this.contratosService.endContratos()
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Proceso de finalización de contrato realizado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Aceptar});
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

}
