<div class="position-relative">
  <div *appRole="['Propietarios.Crear']">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
    <h1 mat-dialog-title> Crear propietario</h1>
    <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
        <app-form-persona-propietaria-create></app-form-persona-propietaria-create>
    </div>
  </div>
</div>


