import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BaseDto } from '@models/base/baseDto.model';
import { Genericos } from '@models/base/genericos.model';
import { RangoFechasValores } from '@models/base/identificadores.model';
import { PagoComprobanteDetailDto } from '@models/pagos-comprobantes/pagoComprobanteDetailDto.model';
import { HotToastService } from '@ngneat/hot-toast';
import { EstadosPagoService } from '@services/estados-pago/estados-pago.service';
import { FormasPagoService } from '@services/formas-pago/formas-pago.service';
import { PagoscomprobantesService } from '@services/pagos-comprobantes/pagoscomprobantes.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-listado-acreditaciones',
  templateUrl: './listado-acreditaciones.component.html',
  styleUrls: ['./listado-acreditaciones.component.css'],
  providers: [DatePipe],
})
export class ListadoAcreditacionesComponent implements OnInit {

  form!: FormGroup
  rangoFecha: number = RangoFechasValores.Hoy
  private paginator!: MatPaginator;
  private sort!: MatSort;
  allRowsExpanded: boolean = false;
  checkedFiltroAvanzado: boolean = false;
  formBuilder = new FormBuilder;
  dataSource = new MatTableDataSource<PagoComprobanteDetailDto>();
  lstFormasPago!: BaseDto[];
  lstEstadosPago!: BaseDto[];
  lstRangoFecha = Genericos.lstRangoFecha
  @Input() idContrato!: number
  data = false
  displayedColumns: string[] = [];

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  constructor(
    private pagoscomprobantesService: PagoscomprobantesService,
    private toastService: HotToastService,
    private snackBarService: SnackBarService,
    private snackbar: MatSnackBar,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    private formasPagoService: FormasPagoService,
    private breakpointObserver: BreakpointObserver,
    private estadosPagoService: EstadosPagoService
  ) {

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['fecha', 'accion'] :
        ['fecha', 'fechaAcreditacion', 'numeroComprobante', 'contrato', 'estadoPago', 'formaPago', 'importe', 'descripcion'];
    });

    this.form = this.formBuilder.group({
      FechaDesde: [new Date((new Date().getTime() - RangoFechasValores.Hoy)), Validators.compose([Validators.required,])],
      FechaHasta: [new Date(), Validators.compose([Validators.required,])],
      RangoFecha: [''],
      FormasPago: [''],
      EstadosPago: ['']
    })
  }

  ngOnInit(): void {
    this.getAllFormasPago()
    this.getAllEstadosPago()
    if (this.idContrato)
      this.filtrar()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false

  }

  selectRangoFecha(event: any) {
    this.rangoFecha = event.value
    if (this.rangoFecha == RangoFechasValores.Todos) {
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
      this.form.controls["FechaHasta"].setValue(new Date((new Date().getTime() + this.rangoFecha)))
    }
    else {
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
      this.form.controls["FechaHasta"].setValue(new Date((new Date().getTime())))
    }
  }



  filtrar() {
    //Fijos
    let fechaDesde = this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;
    let fechaHasta = this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;
    // Ajustar la hora para la fecha desde
    fechaDesde = fechaDesde + ' 00:00:00';

    // Ajustar la hora para la fecha hasta
    fechaHasta = fechaHasta + ' 23:59:59';
    //Variables
    let idContrato = isFalsy(this.idContrato) ? null : this.idContrato
    let idEstadosPago = this.form.controls["EstadosPago"].value
    let idsFormasPago = this.form.controls["FormasPago"].value

    this.spinner.show()
    this.pagoscomprobantesService.getPagosComprobantesByFilter(fechaDesde, fechaHasta, idsFormasPago, idEstadosPago, idContrato)
      .subscribe(
        data => {
          this.spinner.hide();
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide();
          this.snackBarService.showError(error, "Error");
        }
      )
  }


  getAllEstadosPago() {
    this.spinner.show("spBusquedaEstadosPagos")
    this.estadosPagoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaEstadosPagos")
          this.lstEstadosPago = data
        },
        error => {
          this.spinner.hide("spBusquedaEstadosPagos")
          this.snackBarService.showError(error, "Error");
        }
      )
  }

  getAllFormasPago() {
    this.spinner.show("spBusquedaFormasPago")
    this.formasPagoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaFormasPago")
          this.lstFormasPago = data
        },
        error => {
          this.spinner.hide("spBusquedaFormasPago")
          this.snackBarService.showError(error, "Error");
        }
      )
  }


  //#region Helper

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
  //#endregion

}
