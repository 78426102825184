<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom"
  [fullScreen]="false"></ngx-spinner>
<h1 mat-dialog-title><strong>{{dialogData.event}}</strong> periodo</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <form [formGroup]="nuevoPeriodoFormGroup">
    <div class="row">
      <div class="col-lg-6">
        <mat-form-field class="example-full-width">
          <mat-label>Desde</mat-label>
          <input matInput name="PeriodoDesde" type="text" maxlength="2" formControlName="PeriodoDesde" />
          <mat-error *ngIf="f.PeriodoDesde.errors?.pattern">Formato incorrecto.</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="example-full-width">
          <mat-label>Hasta</mat-label>
          <input matInput name="PeriodoHasta" type="text" maxlength="2" formControlName="PeriodoHasta" />
          <mat-error *ngIf="f.PeriodoHasta.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
          <mat-error *ngIf="f.PeriodoHasta.errors?.pattern">Formato incorrecto.</mat-error>
          <mat-error *ngIf="f.PeriodoHasta.errors?.validarPeriodoContrato">Periodo incorrecto.</mat-error>
          <mat-error *ngIf="f.PeriodoHasta.errors?.validarCantidadPeriodos">Supera duración contrato.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <mat-form-field class="example-full-width">
          <mat-label>Monto Alquiler</mat-label>
          <span matPrefix>$&nbsp;</span>
          <input matInput cdkFocusInitial name="MontoAlquiler" (change)="changeMontoAlquiler()" type="text"
            mask="separator.2" thousandSeparator="," formControlName="MontoAlquiler" />
          <mat-error *ngIf="f.MontoAlquiler.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="col-lg-6">
        <ngx-spinner name="spIndiceAjusteContrato" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688"
          type="line-scale" [fullScreen]="false"></ngx-spinner>
        <div class="row" *ngIf="indiceAjusteContrato">
          <div class="col-lg-3">
            <span matTooltip="Indice con fecha igual o anterior a fecha de comienzo de contrato."><b>Indice</b></span>
          </div>
          <div class="col-lg-9">
            <span>
              <br><b>Fecha:</b> {{indiceAjusteContrato.fecha | date:"dd/MM/yyyy"}}
              <br> <b>Valor:</b> {{indiceAjusteContrato.valor}}
              <br> <b>Tipo Ajuste:</b> {{indiceAjusteContrato.tipoAjusteContrato.acronimo}}</span>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row" *ngIf="esContratoFijo">
      <div class="col-lg-6">
        <mat-form-field class="example-full-width">
          <mat-label>Incremento</mat-label>
          <span matPrefix>$&nbsp;</span>
          <input matInput name="Incremento" (change)="changeIncremento()" type="text" mask="separator.2"
            thousandSeparator="," formControlName="Incremento" />
        </mat-form-field>
      </div>

      <div class="col-lg-6">
        <button mat-icon-button color="primary" #tooltip="matTooltip" (click)="calculaIncremento()"
          matTooltipPosition="above" matTooltip="Calcular" aria-label="Example icon button with a home icon">
          <mat-icon>functions</mat-icon>
        </button>
        <mat-form-field class="example-full-width">
          <mat-label>Valor</mat-label>
          <span matPrefix>%&nbsp;</span>
          <input matInput name="IncrementoPorcentajeValor" type="number" formControlName="IncrementoPorcentajeValor" />
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="esContratoFijo">
      <div class="col-lg-6">
        <mat-form-field class="example-full-width">
          <mat-label>Descuento</mat-label>
          <span matPrefix>$&nbsp;</span>
          <input matInput name="Descuento" type="text" mask="separator.2" thousandSeparator=","
            (change)="changeDescuento()" formControlName="Descuento" />
        </mat-form-field>
      </div>

      <div class="col-lg-6">
        <button mat-icon-button color="primary" (click)="calculaDescuento()" #tooltip="matTooltip"
          matTooltipPosition="above" matTooltip="Calcular" aria-label="Example icon button with a home icon">
          <mat-icon>functions</mat-icon>
        </button>
        <mat-form-field class="example-full-width">
          <mat-label>Valor</mat-label>
          <span matPrefix>%&nbsp;</span>
          <input matInput name="DescuentoPorcentajeValor" type="number" formControlName="DescuentoPorcentajeValor" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <h2>Precio Final Alquiler</h2>
      </div>
      <div class="col-lg-6">
        <h2> {{calcularPrecioFinalAlquiler(precioAlquiler,descuento,incremento) | currency}}</h2>
      </div>
    </div>

  </form>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
  <button mat-raised-button (click)="agregarPeriodo()" *appRole="['Contratos.Modificar']"
    [disabled]="!nuevoPeriodoFormGroup.valid" color="primary">{{dialogData.event}}</button>
  <button mat-button (click)="closeDialog()" mat-dialog-close color="warn">Cancelar</button>
</div>
