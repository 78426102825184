import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackbarData } from '@models/base/snackbar-data.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';


@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.css']
})
export class SnackbarComponent  {

  mensaje: string = ""
  link: string = ""
  nombreLink: string = ""
  accion: string = ""
  icono: string = ""

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
    private snackBar: MatSnackBar,
    private snackBarService: SnackBarService)
    {
      this.mensaje =  data.mensaje
      this.link =  data.link
      this.nombreLink =  data.nombreLink
      this.accion =  data.accion
      this.icono =  data.icono

    }


}
