import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { TipoDocumentoDto } from '@models/tipo-documento/tipoDocumentoDto.model';
import { GruposTipoGarantiaService } from '@services/grupos-tipo-garantia/grupos-tipo-garantia.service';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposDocumentoService } from '@services/tipos-documento/tipos-documento.service';
import { DialogCreateInquilinoComponent } from '../dialog-create-inquilino/dialog-create-inquilino.component';
import { isFalsy } from 'utility-types';
import { FastChargePersonaRequest } from '@models/inquilino/fastChargePersonaRequest.model';

@Component({
  selector: 'app-form-create-inquilino',
  templateUrl: './form-create-inquilino.component.html',
  styleUrls: ['./form-create-inquilino.component.css']
})
export class FormCreateInquilinoComponent implements OnInit {

  @ViewChild(DialogCreateInquilinoComponent) dialogCreateInquilinoComponent: DialogCreateInquilinoComponent = new DialogCreateInquilinoComponent;
  lstGruposGarantias: BaseDto[] = []
  titulo: string = '';
  subMenuActive: boolean = false;
  stringObject: any;
  stringJson: any;
  personaForm!: FormGroup
  lstTipoDocumentoDto: TipoDocumentoDto[] = [];
  submitted = false;
  parametro: string = '';
  idTipoDocumento: number = 0;
  idPersona: number = 0;
  valor: any
  errorMessage: string = "";
  accordionList: any;
  formBuilder: FormBuilder = new FormBuilder;
  fastChargePersonaRequest: FastChargePersonaRequest = new FastChargePersonaRequest;
  matDialogClose: boolean= false
  idGrupoGarantia: any;
  enProceso: boolean = false;
  get f() { return this.personaForm.controls; }
  @ViewChild('regForm', {static: false}) myForm!: NgForm;

  constructor(
    private personasService: PersonasService,
    private inquilinosService: InquilinosService,
    private gruposTipoGarantiaService: GruposTipoGarantiaService,
    private tipoDocumentoService: TiposDocumentoService,
    private router: Router,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) {
    this.personaForm = this.formBuilder.group({
      //Persona
      NombreCompleto: ["", Validators.compose([Validators.required, Validators.maxLength(50)])],
      NroDocumento: ['',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
        this.validaPersonaByNroDocYTipoDoc.bind(this), {updateOn: 'blur'}],
      Correo: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(50)])],
      TiposDocumento: ['', Validators.compose([Validators.required])],
      GruposGarantias: ['', Validators.compose([Validators.required])],
    });
  }


  ngOnInit(): void {
    this.personaForm.controls["NroDocumento"].disable();
    this.getTiposDocumento();
    this.getTiposGruposGarantia();
  }

  getTiposGruposGarantia() {
    this.spinner.show("spBusquedaGarantias")
    this.gruposTipoGarantiaService.getGruposTipoGarantias()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaGarantias")
          this.lstGruposGarantias = data
        },
        error => {
          this.spinner.hide("spBusquedaGarantias")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getTiposDocumento() {
    this.spinner.show("spBusquedaTipoDocumento")
    this.tipoDocumentoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTipoDocumento")
          this.lstTipoDocumentoDto = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
        },
        error => {
          this.spinner.hide("spBusquedaTipoDocumento")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  onSubmit() {
    if (this.personaForm.invalid) {
      return;
    }
    else
    {
      this.spinner.show("spCreateInquilino")
      this.submitted = true;
      this.setPersonaDetailDto();
      this.inquilinosService.fastChargePersona(this.fastChargePersonaRequest)
        .subscribe(
          data => {
            this.snackBar.showSuccess(data.text, "Exito");
            this.spinner.hide("spCreateInquilino")
            this.clearForm()
          },
          error => {
            this.spinner.hide("spCreateInquilino")

            error =
            error.includes("A connection attempt failed because the connected party did not properly respond after a period of time") ? "Un intento de conexión falló porque la parte conectada no respondió correctamente después de un período de tiempo, o la conexión establecida falló porque el host conectado no respondió." :
            error.includes("The mail server could not deliver") ?  "El servidor de correo no pudo entregar correo. La cuenta o el dominio pueden no existir, pueden estar en la lista negra o faltar el entradas dns adecuadas": error

            this.snackBar.showError(error.toString().replace(/['"]+/g, ''), "Error");
          }
        )
    }

  }
  clearForm() {
    this.myForm.resetForm();
  }

  //Selecciones
  selectTipoDocumento(event: any) {
    if (event != 0) {
      this.personaForm.controls["NroDocumento"].enable();
      this.personaForm.controls["NroDocumento"].setValue("");
    }
    else {
      this.personaForm.controls["NroDocumento"].disable();
    }
  }

  selectGrupoGarantia(event: any) {
    this.idGrupoGarantia = event;
  }

  setPersonaDetailDto() {
    this.fastChargePersonaRequest = new FastChargePersonaRequest();
    this.fastChargePersonaRequest.nombreCompleto = this.personaForm.controls['NombreCompleto'].value;
    this.fastChargePersonaRequest.nroDocumento = this.personaForm.controls['NroDocumento'].value;
    this.fastChargePersonaRequest.id_TipoDocumento = this.personaForm.controls['TiposDocumento'].value;
    this.fastChargePersonaRequest.correo = this.personaForm.controls['Correo'].value;
    this.fastChargePersonaRequest.id_Grupo = this.idGrupoGarantia
    var tet = this.personaForm.controls['GruposGarantias'].value;
  }

  onReset() {
    this.submitted = false;
    this.personaForm.reset();
  }

  goToPesonas() {
    this.submitted = false;
    this.personaForm.reset();
    this.router.navigate(["/personas"])
  }

  //Validaciones
  validaLargoNumerico(control: FormControl) {
    if (control.value != null && control.value != "") {
      if (<number>control.value > 999999999999) {
        return { validaLargoNumerico: true }
      }
      else
        return { validaLargoNumerico: false }
    }
    else
      return { validaLargoNumerico: false }
  }

  validaPersonaByNroDocYTipoDoc(controlNroDoc: FormControl) {
    let idTipoDocumento = this.personaForm.controls['TiposDocumento'].value;
    if (controlNroDoc.value.toString().length > 7  && !isFalsy(idTipoDocumento) ) {
      let promesa = new Promise((resolve, reject) => {
        this.personasService.validaPersonaByNroDocYTipoDoc(this.idPersona, controlNroDoc.value, idTipoDocumento)
          .subscribe(data => {
            if (data == true) {
              resolve({ existePersona: true })
            }
            else {
              resolve(true)
            }
          })
      });
      return promesa;
    }
    else {
      let promesa = new Promise((resolve, reject) => {
        resolve(true)
      });
      return promesa;
    }
  }

  //Fin Validaciones

  trackByItems(index: number, item: any): any { return item; }
}
