import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ContratoController } from 'src/app/controllers/contratos.controller';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { ContratoDto } from '@models/contrato/contratoDto.model';
import { PeriodoContratoDetailDto } from '@models/periodos-contrato/periodoContratoDetailDto.model';
import { ContratosService } from '@services/contratos/contratos.service';

@Component({
  selector: 'app-dialog-editar-importe-punitorio',
  templateUrl: './dialog-editar-importe-punitorio.component.html',
  styleUrls: ['./dialog-editar-importe-punitorio.component.css'],

})
export class DialogEditarImportePunitorioComponent implements OnInit {



  form: FormGroup;
  contrato!: ContratoDetailDto;
  periodo!: PeriodoContratoDetailDto;
  importeAlquilerSinPunitorio: number = 0;
  importePunitorio: number = 0;
  importePunitorioEntrega: number = 0;
  formBuilder: FormBuilder = new FormBuilder
  dataLocal: any;
  notificacion: string = "";
  get f() { return this.form.controls; }


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogEditarImportePunitorioComponent>,

  ) {
    this.dataLocal = { ...data }
    this.form = this.formBuilder.group({
      importeEntrega: ['', Validators.required],
      fechaHastaPunitorio: [this.calcularFechaHastaInicial(), Validators.required]
    });
  }

  ngOnInit(): void {
    this.contrato = this.dataLocal.contrato as ContratoDetailDto
    this.periodo = this.dataLocal.periodo as PeriodoContratoDetailDto

    const fechaPunitorio = this.form.controls["fechaHastaPunitorio"].value
    const entregaSinPunitorio = ContratoController.calculoPunitorio(this.dataLocal.periodo, this.periodo.importeAlquiler as number, new Date(fechaPunitorio), this.data.contrato as ContratoDto)
    this.importeAlquilerSinPunitorio = Math.round(entregaSinPunitorio.entrega as number * 100) / 100
    this.importePunitorioEntrega = Math.round(entregaSinPunitorio.punitorio as number * 100) / 100
    this.form.controls["importeEntrega"].setValue(this.periodo.importeAlquiler + this.periodo.importePunitorio, { emitEvent: false })
  }

  onSubmit() {
    this.dataLocal.periodo.importeEntrega = this.form.controls["importeEntrega"].value as number
    this.dataLocal.periodo.importePunitorioEntrega = this.importePunitorioEntrega,
    this.dataLocal.periodo.importeAlquilerSinPunitorio = this.importeAlquilerSinPunitorio
    this.dataLocal.periodo.fechaPunitorio = this.form.controls["fechaHastaPunitorio"].value
    this.dataLocal.periodo.fechaPago = this.form.controls["fechaHastaPunitorio"].value

    this.dialogRef.close({ event: AccionesDialog.Aceptar, data: this.dataLocal });
  }

  // Función para filtrar los días habilitados
  filterWeekends = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Devuelve true si el día no es sábado ni domingo
    return day !== 0 && day !== 6;
  };

  // Método para calcular la fecha inicial para fechaHastaPunitorio
  calcularFechaHastaInicial(): string {
    let fechaInicial = new Date();
    // Verificar si es sábado (6) o domingo (0)
    if (fechaInicial.getDay() === 6) { // Si es sábado
        fechaInicial.setDate(fechaInicial.getDate() + 2); // Saltar al siguiente lunes
    } else if (fechaInicial.getDay() === 0) { // Si es domingo
        fechaInicial.setDate(fechaInicial.getDate() + 1); // Saltar al siguiente lunes
    }
    return fechaInicial.toISOString();
}

  onCancel() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar, data: this.dataLocal });
  }

  // calcularPunitorioPorFecha(event: MatDatepickerInputEvent<Date>) {
  //   if (event.value != null) {
  //     const importeEntrega = this.form.controls["importeEntrega"].value as number
  //     const fechaHastaPunitorio = event.value;
  //     const entregaSinPunitorio = ContratoController.calculoPunitorio(this.dataLocal.periodo, importeEntrega, fechaHastaPunitorio, this.dataLocal.contrato as ContratoDto)
  //     this.importeAlquilerSinPunitorio = Math.round(entregaSinPunitorio.entrega as number * 100) / 100
  //     this.importePunitorioEntrega = Math.round(entregaSinPunitorio.punitorio as number * 100) / 100
  //   }
  // }

  calcularPunitorioPorFecha(event: MatDatepickerInputEvent<Date>) {
    if (event.value != null) {
        const importeEntrega = this.form.controls["importeEntrega"].value as number;
        let fechaHastaPunitorio = new Date(event.value); // Obtener la fecha seleccionada
        // Verificar si la fecha seleccionada es sábado (6) o domingo (0)
        if (fechaHastaPunitorio.getDay() === 6) { // Si es sábado
            fechaHastaPunitorio.setDate(fechaHastaPunitorio.getDate() + 2); // Saltar al siguiente lunes
        } else if (fechaHastaPunitorio.getDay() === 0) { // Si es domingo
            fechaHastaPunitorio.setDate(fechaHastaPunitorio.getDate() + 1); // Saltar al siguiente lunes
        }

        // Ahora `fechaHastaPunitorio` contendrá la próxima fecha hábil si la fecha seleccionada es fin de semana.

        const entregaSinPunitorio = ContratoController.calculoPunitorio(
            this.dataLocal.periodo,
            importeEntrega,
            fechaHastaPunitorio,
            this.dataLocal.contrato as ContratoDto
        );

        this.importeAlquilerSinPunitorio = Math.round(entregaSinPunitorio.entrega as number * 100) / 100;
        this.importePunitorioEntrega = Math.round(entregaSinPunitorio.punitorio as number * 100) / 100;
    }
}


  calcularPunitorioPorImporte() {
    const importeAlquiler = this.periodo.importeAlquiler + this.periodo.importePunitorio
    const importeEntrega = this.form.controls["importeEntrega"].value as number
    if (importeEntrega > importeAlquiler)
      this.notificacion = "El importe de entrega no debe ser mayor al importe de alquiler."
    else

      this.notificacion = ""

    if (importeEntrega != 0 || importeEntrega != null) {
      const fechaPunitorio = this.form.controls["fechaHastaPunitorio"].value
      const entregaSinPunitorio = ContratoController.calculoPunitorio(this.data.periodo, importeEntrega, new Date(fechaPunitorio), this.data.contrato as ContratoDto)
      this.importeAlquilerSinPunitorio = entregaSinPunitorio.entrega as number
      this.importePunitorioEntrega = Math.round(entregaSinPunitorio.punitorio as number * 100) / 100
    }
  }

  addEvent(event: MatDatepickerInputEvent<Date>) {
    const fecha = event.value;
  }

}
