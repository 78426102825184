import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ResultadoResumenInquilinoMasivoYEnvioCorreo } from '@models/inquilino/resultadoResumenInquilinoMasivoYEnvioCorreo.model';
import { HotToastService } from '@ngneat/hot-toast';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogGenerarResumenMensualComponent } from '../dialog-generar-resumen-mensual/dialog-generar-resumen-mensual.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GenericDialogComponent } from '@views/genericos/generic-dialog/generic-dialog.component';

@Component({
  selector: 'app-detalle-generacion-resumen-masivo',
  templateUrl: './detalle-generacion-resumen-masivo.component.html',
  styleUrls: ['./detalle-generacion-resumen-masivo.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetalleGeneracionResumenMasivoComponent {
  displayedColumns: string[] = []
  verTabla: boolean = false
  dataSource = new MatTableDataSource<ResultadoResumenInquilinoMasivoYEnvioCorreo>();
  constructor
    (
      public dialogMensajeDetalle: MatDialog,
      private snackBar: SnackBarService,
      private spinner: NgxSpinnerService,
      public dialogRef: MatDialogRef<DialogGenerarResumenMensualComponent>,
      private toastService: HotToastService,
      private breakpointObserver: BreakpointObserver,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = ['numeroContrato', 'envioExitoso', 'fechaEnvio', 'mensaje']
    });


    this.dataSource.data = data;
    this.verTabla = this.dataSource.data.length >= 1 ? true : false

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }



  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openMessageDialog(element: any): void {
    this.dialogMensajeDetalle.open(GenericDialogComponent, {
      data: { head: 'Ver detalle', body: element }
    });
  }
}

