<div class="row content" *appRole="['Comprobantes.Ver']">
    <div class="col-sm-2">
        <app-nav-panel-contrato [idContrato]="idContrato"></app-nav-panel-contrato>
    </div>
    <div class="col-sm-10">
        <ngx-spinner [zIndex]=100 name="spContrato" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <mat-tab-group>

            <mat-tab label="Recibos" *appRole="['Comprobantes.Ver']">
                <br>
                <div *ngIf="contratoDetailDto.numeroContrato != 0 && contratoDetailDto.numeroContrato != undefined">
                    <app-listado-comprobantes-recibos [numeroContrato]="contratoDetailDto.numeroContrato"></app-listado-comprobantes-recibos>
                </div>

            </mat-tab>

            <mat-tab label="Liquidaciones" *appRole="['Comprobantes.Ver']">
                <br>
                <div *ngIf="contratoDetailDto.numeroContrato != 0 && contratoDetailDto.numeroContrato != undefined">
                    <app-listado-comprobantes-liquidaciones [numeroContrato]="contratoDetailDto.numeroContrato"></app-listado-comprobantes-liquidaciones>
                </div>

            </mat-tab>
            <mat-tab label="Caja" *appRole="['ComprobantesCaja.Ver']">
                <br>
                <div *ngIf="contratoDetailDto.numeroContrato != 0 && contratoDetailDto.numeroContrato != undefined">
                    <app-listado-comprobantes-genericos [numeroContrato]="contratoDetailDto.numeroContrato"></app-listado-comprobantes-genericos>
                </div>
            </mat-tab>
            <mat-tab label="Acreditaciones" *appRole="['Acreditaciones.Ver']">
                <br>
                <div *ngIf="contratoDetailDto.numeroContrato != 0 && contratoDetailDto.numeroContrato != undefined">
                    <app-listado-acreditaciones [idContrato]="contratoDetailDto.id"></app-listado-acreditaciones>
                </div>
            </mat-tab>
        </mat-tab-group>

    </div>
</div>
