<button mat-button *appRole="['CuentasContable.Crear']" (click)="openDialog('Agregar',cuentaContable)" mat-flat-button color="primary">
    <mat-icon>add</mat-icon>
    Nueva cuenta contable
</button>
<br><br>
<mat-card *appRole="['CuentasContable.Ver']">
    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <mat-form-field class="example-full-width">
        <mat-label>Buscar</mat-label>
        <input matInput type="search" (keyup)="applyFilter($event)">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <br>

    <table mat-table [dataSource]="dataSource" [hidden]=!data table matSort matSortStart="asc" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
    The actual rendered columns are set as a property on the row definition" -->

        <!-- Id Column -->
        <!-- <ng-container matColumnDef="id">
            <mat-header-cell mat-header-cell *matHeaderCellDef> # </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
        </ng-container> -->

        <!-- Descripcion Column -->
        <ng-container matColumnDef="descripcion">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{element.descripcion}} </mat-cell>
        </ng-container>

        <!-- Banco Column -->
        <ng-container matColumnDef="banco">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Banco </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{isFalsy(element.banco) ? "": element.banco.descripcion}} </mat-cell>
        </ng-container>

        <!-- Numero Column -->
        <ng-container matColumnDef="numero">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Numero </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">{{element.numero}} </mat-cell>
        </ng-container>

        <!-- Tipo Column -->
        <ng-container matColumnDef="tipo">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{element.tipo}} </mat-cell>
        </ng-container>

        <!-- CBU Column -->
        <ng-container matColumnDef="cbu">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> CBU </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{element.cbu}} </mat-cell>
        </ng-container>

        <!-- Alias Column -->
        <ng-container matColumnDef="alias">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Alias </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{element.alias}} </mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="accion">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element" class="action-link">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
                <mat-menu #menu="matMenu">
                    <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                    <mat-icon>visibility</mat-icon>
                    <span>Ver</span>
                  </button> -->
                    <button *appRole="['CuentasContable.Modificar']" mat-menu-item (click)="openDialog('Modificar',element)">
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                  </button>
                    <button  *appRole="['CuentasContable.Eliminar']" mat-menu-item (click)="openDialog('Eliminar',element)">
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar</span>
                  </button>
                </mat-menu>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </table>
    <div class="no-data-table">
        <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
    </div>
    <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="10" [pageSizeOptions]="[5, 10, 20,50,100,200]">
    </mat-paginator>

</mat-card>
