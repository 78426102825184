<mat-form-field class="full-width">
    <ngx-spinner [zIndex]="1" name="spBusquedaServicios" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
    <mat-label>Servicios</mat-label>
    <input type="search" placeholder="Buscar por nombre." matInput [matAutocomplete]="auto" [formControl]="entityControl" />
    <button disabled mat-icon-button matSuffix>
            <mat-icon>{{'search'}}</mat-icon>
        </button>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let servicio of filteredServicios | async ;let i=index;trackBy:trackByItems" [value]="servicio.tipoServicio.descripcion"  [matTooltip]="servicio.tipoServicio.descripcion +  ' - '+servicio.nroBroche" (click)="selectValue(servicio)" (onSelectionChange)="selectValue(servicio)">
            <span>{{servicio.tipoServicio.descripcion +" - "+ servicio.nroBroche | titlecase}}</span>
        </mat-option>
    </mat-autocomplete>
    <button mat-icon-button matSuffix *ngIf="entitySelect" (click)="selectValue(null)">
      <mat-icon>clear</mat-icon>
      </button>
    <button mat-icon-button matSuffix matTooltip="Limpiar" *ngIf="esServicio == true && entitySelect != null" matTooltip="Ver" (click)="verServicio(entitySelect)">
        <mat-icon color="primary">info</mat-icon>
        </button>
</mat-form-field>
