<div class="row" *appRole="['ImportarDetalleServicios.Ver']">
    <div class="col-lg-6">
        <mat-card>
            <mat-card-title>Descargar Plantilla</mat-card-title>
            <mat-card-subtitle>Descargar esquema de plantilla para completar e importar.</mat-card-subtitle>
            <mat-card-content>
                <ngx-spinner name="spFileDowload" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                </ngx-spinner>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="full-width-x1">
                            <ngx-spinner [zIndex]=100 name="spBusqueda" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                            <mat-label>Servicios</mat-label>
                            <mat-select placeholder="Seleccione opcion" (selectionChange)="selectServicio($event)">
                                <mat-option [value]="servicios" *ngFor="let servicios of lstServicios;let i=index;trackBy:trackByItems">
                                    {{servicios.tipoServicio.descripcion}}-{{servicios.nroBroche}}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                    </div>
                    <div class="col">
                        <div *ngIf="servicioDto">
                            <strong>Nro. de Broche: </strong> {{servicioDto != null ? servicioDto.nroBroche : ""}}
                        </div>
                    </div>
                </div>

                <br>
                <br>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            ¿Como completar el archivo?
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <p>Antes que nada se debe tener en cuenta que este proceso funciona exclusivamente con servicios que contienen numero de broche. </p>
                    <p>Antes de <strong>importar</strong> se debe completar el archivo a descargar con la siguiente información:</p>
                    <p><b>NroBroche</b>: Aqui colocar el número de broche que se informa al seleccionar servicio.</p>
                    <p><b>NroCuenta</b>: Aqui colocar el número de cuenta del servicio. Para más ayuda puede verificar a continuación de donde obtener dicho dato.</p>
                    <p><b>PeriodoMes</b>: Mes del periodo del servicio.</p>
                    <p><b>PeriodoAño</b>: Año del periodo del servicio.</p>
                    <p><b>PeriodoCuota</b>: Este campo hace referencia al número de cuota.</p>
                    <p><b>ImporteVt1</b>: Aqui colocar el importe del primer vencimiento.</p>
                    <p><b>FechaVto1</b>: Aqui colocar la fecha del primer vencimiento en formato dd/MM/yyyy.</p>
                </mat-expansion-panel>
                <br>
                <app-instructivos-servicios></app-instructivos-servicios>
                <br>

            </mat-card-content>
            <mat-divider inset></mat-divider>
            <mat-card-actions align="end">
                <button mat-button color="primary" [disabled]="isFalsy(servicioDto) || servicioDto.nroBroche == '0'" (click)="generateExcel()">Generar Excel</button>
            </mat-card-actions>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card>
            <mat-card-title>Importar Plantilla</mat-card-title>
            <mat-card-subtitle>Importar planilla descargada con los datos.</mat-card-subtitle>
            <mat-card-content>
                <ngx-spinner name="spFileUpload" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                <app-file-upload (eventoArchivo)="seleccionArchivo($event)"></app-file-upload>
            </mat-card-content>
            <mat-divider inset></mat-divider>
            <mat-card-actions align="end">
                <button mat-button color="primary" *appRole="['ImportarDetalleServicios.Crear']" (click)="importarDocumento()" [disabled]="fileUploadState ===false && createDocumentoRequest.fileContent == ''" color="primary"><mat-icon>post_add</mat-icon>
               Importar</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <mat-card>
            <mat-card-title>Registros procesados: {{uploadFileResponse.idsDetalleServiciosPropiedadGenerados.length}}</mat-card-title>
            <mat-card-subtitle>Detalle de registros no procesados. Total: {{uploadFileResponse.detalleServicioPropiedadNoProcesados.length}}</mat-card-subtitle>
            <mat-card-content>
                <ngx-spinner name="spTableResultado" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
                <table mat-table [dataSource]="dataSource" [hidden]=!data matSort matSortStart="asc" class="mat-elevation-z8">

                    <ng-container matColumnDef="nroBroche">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Broche </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let servicio"> {{servicio.nroBroche}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="nroCuenta">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Cuenta </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let servicio"> {{servicio.nroCuenta}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="periodo">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Periodo </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let servicio"> {{servicio.periodoMes}}/{{servicio.periodoAnio}}-{{servicio.periodoCuota}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="importeVto1">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Importe 1 </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let servicio"> {{servicio.importeVto1 == 0? "-": servicio.importeVto1 | currency}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="fechaVto1">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> 1er Vto </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let servicio"> {{servicio.fechaVto1 != "01/01/1900" ? servicio.fecha4 : "-" | date:("dd/MM/yyyy")}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="importeVto2">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Importe 2 </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let servicio"> {{servicio.importeVto2 == 0? "-": servicio.importeVto2 | currency}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="fechaVto2">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> 2do Vto </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let servicio"> {{servicio.fechaVto2 != "01/01/1900" ? servicio.fecha4 : "-" | date:("dd/MM/yyyy")}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="importeVto3">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Importe 3 </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let servicio"> {{servicio.importeVto3 == 0? "-": servicio.importeVto3 | currency}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="fechaVto3">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> 3er Vto </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let servicio"> {{servicio.fechaVto3 != "01/01/1900" ? servicio.fecha4 : "-" | date:("dd/MM/yyyy")}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="importeVto4">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Importe 1 </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let servicio"> {{servicio.importeVto4 == 0? "-": servicio.importeVto4 | currency}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="fechaVto4">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> 4to Vto </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let servicio"> {{servicio.fechaVto4 != "01/01/1900" ? servicio.fecha4 : "-" | date:("dd/MM/yyyy")}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="detalle">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>Detalle </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let servicio"><button mat-icon-button (click)="showDetalle(servicio)"><mat-icon  color="primary">info</mat-icon> </button></mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


                </table>
                <div class="no-data-table">
                    <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
                </div>
                <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="10" [pageSizeOptions]="[5, 10, 20]">
                </mat-paginator>
            </mat-card-content>
        </mat-card>
    </div>
</div>
