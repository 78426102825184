<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>
    Entrega</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div [formGroup]="form">
        <mat-form-field class="example-full-width">
            <span matPrefix>$ &nbsp;</span>
            <mat-label>Importe entregado</mat-label>
            <input cdkFocusInitial matInput name="ImporteAlquiler" type="text" mask="separator.2" thousandSeparator="," formControlName="ImporteAlquiler" />
        </mat-form-field>
    </div>

</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="cambiarImporte()">Aceptar</button>
    <button mat-button mat-dialog-close color="warn">Cancelar</button>
</div>