export class CreateMovimientoRequest{
public descripcion!:string|null
public idTipoMovimiento!:number|null
public idContrato!:number|null
public idInquilino?:number|null
public idPropiedad?:number|null
public idPropietario?:number|null
public idBonificacion?:number|null
public importe!:number|null
public periodo!:number|null
public periodoMes!: number|null
public periodoAnio!: number|null
public fechaEmision!: string|null
public fechaPago!: string|null
public fechaVencimiento!:	string|null
public idPeriodoContrato !:number|null
public seleccionPago!: boolean|null
public idMovimientoRef!:number|null
public idTipoMoneda!: number
}
