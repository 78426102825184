import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseDto } from '@models/base/baseDto.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';


@Component({
  selector: 'app-buscar-contrato',
  templateUrl: './buscar-contrato.component.html',
  styleUrls: ['./buscar-contrato.component.css']
})
export class BuscarContratoComponent implements OnInit {


  elementSelect: EventEmitter<BaseDto> = new EventEmitter()
  public elementFiltered!: Observable<BaseDto[]>;
  entitys: BaseDto[] = []
  entity: BaseDto = new BaseDto;
  public entityControl = new FormControl();
  @Output() eventEntity = new EventEmitter<BaseDto>();
  @Input() entityInput: BaseDto = new BaseDto;
  @Input() idContrato!: number | null
  @Input() idEstadoContrato: number  = 1
  entitySelect!: BaseDto | null;
  @Input() width = "full-width"
  @Input() esRequerido: boolean = false;
  constructor(
    private contratosService: ContratosService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getAll();

    this.elementFiltered = this.entityControl.valueChanges.pipe(
      startWith(''),
      map((term: any) => this.findOption(term))
    );
  }

  selectValue(event: any) {
    if (isFalsy(event)) {
      this.entitySelect = null
      this.entityControl.setValue("")
    }
    this.eventEntity.emit(event)
  }


  ngOnChanges(): void {
    this.entityControl.setValue(isFalsy(this.entityInput) ? "" : this.entityInput.descripcion)

    if (this.esRequerido) {
      // Si es requerido, se agrega la validación de requerido
      this.entityControl.setValidators([Validators.required]);
    } else {
      // Si no es requerido, se eliminan las validaciones
      this.entityControl.clearValidators();
    }
    this.entityControl.updateValueAndValidity(); // Actualizar el estado del control
  }


  findOption(val: string) {
    for (let i = 0; i < this.entitys.length; i++) {
      if (this.entitys[i].descripcion.toUpperCase() == val.toUpperCase()) {
        this.entitySelect = this.entitys[i]
        this.entityControl.setValue(isFalsy(this.entitys[i]) ? "" : this.entitys[i].descripcion)
      }
    }
    return this.entitys.filter((entity) => new RegExp(val, 'gi').test(entity.descripcion));
  }
  getAll() {
    this.spinner.show("spBusquedaContratos")
    this.contratosService.getAllContratosSummary(this.idEstadoContrato)
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaContratos")
          this.entitys = data
        },
        error => {
          this.spinner.hide("spBusquedaContratos")
          this.snackBar.showError(error, "Error");
        });
  }

  verContrato(entitySelect: any) {
    if (!isFalsy(entitySelect))
      this.router.navigate(['/contratos'],entitySelect.id);
  }
  trackByItems(index: number, item: any): any { return item; }
}
