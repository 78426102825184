import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateMenuRequest } from '@models/seguridad/menu/createMenuRequest.model';
import { MenuDto } from '@models/seguridad/menu/menuDto.model';
import { UpdateMenuRequest } from '@models/seguridad/menu/updateMenuRequest.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(private http: HttpClient) { }

  getAll(): Observable<MenuDto[]> {
    return this.http.get<MenuDto[]>("/api/Menu/GetAll");
  }
  getById(id: number): Observable<MenuDto> {
    return this.http.get<MenuDto>("/api/Menu/GetById?id=" + id);
  }

  create(createMenuRequest: CreateMenuRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Menu/Create", createMenuRequest);
  }

  update(updateMenuRequest: UpdateMenuRequest): Observable<any> {
    return this.http.put<any>("/api/Menu/Update", updateMenuRequest);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>("/api/Menu/Delete?id="+id);
  }
}
