import { ContratoDto } from "./contratoDto.model"
import { ContratoGarantiaDto } from "./contratoGarantiaDto.model"
import { ContratoPropietarioDto } from "./contratoPropietarioDto.model"
import { PeriodoContratoDto } from "../periodos-contrato/periodoContratoDto.model"

export class UpdateContratoRequest {
    public contrato:ContratoDto = new ContratoDto
    public propietariosContrato: ContratoPropietarioDto[] = []
    public periodosContrato: PeriodoContratoDto[]= []
}
