import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { CreateArchivoRequest } from '@models/archivo/createArchivoRequest.model';
import { UpdateArchivoRequest } from '@models/archivo/updateArchivoRequest.model';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateEmpresaRequest } from '@models/empresa/createEmpresaRequest.model';
import { EmpresaDto } from '@models/empresa/empresaDto.model';
import { UpdateEmpresaRequest } from '@models/empresa/updateEmpresaRequest.model';
import { LocalidadDto } from '@models/localidad-provincia/localidadDto.model';
import { LocalidadBasicDto } from '@models/localidad-provincia/localidadBasicDto.model';
import { JwtPayload } from '@models/login/token-model.model';
import { ArchivosService } from '@services/archivos/archivos.service';
import { EmpresaService } from '@services/empresa/empresa.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposCondicionContableService } from '@services/tipos-condicion-contable/tipos-condicion-contable.service';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit {

  lstCondicionesContables: BaseDto[] = []
  formEmpresa: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  imgURL!: string | ArrayBuffer | null;
  localidadBasicDto: LocalidadBasicDto = new LocalidadBasicDto;
  empresaDto?: EmpresaDto
  archivo: ArchivoDto = new ArchivoDto;
  statusFileUpload: boolean = false
  localidad!: LocalidadDto;
  userSession!: JwtPayload;
  get f() { return this.formEmpresa.controls; }

  constructor(
    private empresaService: EmpresaService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private tiposCondicionContableService: TiposCondicionContableService,
    private archivosService: ArchivosService
  ) {
    this.formEmpresa = this.formBuilder.group({
      Nombre: ['',Validators.compose([Validators.required])],
      Ubicacion: [''],
      Piso: [''],
      Departamento: [''],
      TipoCondicionContable: ['',Validators.compose([Validators.required])],
      FechaInicioActividad: [''],
      CUIT: [''],
      IIBB: [''],
      Web: [''],
      WebApp: [''],
      Telefono: ['', Validators.compose([Validators.maxLength(20)])],
      Correo: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(50)])],
    });
  }

  ngOnInit(): void {
    this.archivo.id = 1;
    this.getAllTipoCondicionContable()
    this.getEmpresa(1)
  }

  getEmpresa(id: number) {
    this.spinner.show()
    this.empresaService.getById(id).subscribe(
      data => {
        this.empresaDto = data
        this.setFormEmpresa(this.empresaDto)
        this.spinner.hide()
      },
      error => {
        this.spinner.hide()
        this.snackBar.showError(error, "Error");
      })
  }

  changeStatusFileUpload($event: any) {
    this.statusFileUpload = $event
  }

  setFormEmpresa(empresaDto: EmpresaDto) {
    this.formEmpresa.controls['Nombre'].setValue(empresaDto.nombre)
    this.formEmpresa.controls['Piso'].setValue(empresaDto.piso)
    this.formEmpresa.controls['Ubicacion'].setValue(empresaDto.ubicacion)
    this.formEmpresa.controls['Departamento'].setValue(empresaDto.departamento)
    this.formEmpresa.controls['TipoCondicionContable'].setValue(empresaDto.tipoCondicionContable?.id)
    this.formEmpresa.controls['IIBB'].setValue(empresaDto.iibb)
    this.formEmpresa.controls['CUIT'].setValue(empresaDto.cuit)
    this.formEmpresa.controls['Telefono'].setValue(empresaDto.telefono)
    this.formEmpresa.controls['Correo'].setValue(this.empresaDto?.correo)
    this.formEmpresa.controls['Web'].setValue(this.empresaDto?.web)
    this.formEmpresa.controls['WebApp'].setValue(this.empresaDto?.webApp)
    this.localidadBasicDto = empresaDto.localidad
    this.empresaDto = empresaDto
  }

  seleccionArchivo(archivo: ArchivoDto) {
    this.archivo.content = archivo.content
    this.archivo.contentType = archivo.contentType
    this.archivo.descripcion = archivo.descripcion
    this.archivo.fechaAlta = archivo.fechaAlta
    this.archivo.nombre = archivo.nombre
  }


  eventoImagen(archivo: ArchivoDto) { }

  guardarArchivo() {
    if (this.archivo.id != 0) {
      let updateArchivoRequest = new UpdateArchivoRequest
      updateArchivoRequest.content = this.archivo.content
      updateArchivoRequest.contentType = this.archivo.contentType
      updateArchivoRequest.descripcion = this.archivo.descripcion
      updateArchivoRequest.nombre = this.archivo.nombre
      updateArchivoRequest.encriptado = this.archivo.encriptado
      updateArchivoRequest.fechaAlta = this.archivo.fechaAlta
      updateArchivoRequest.id = this.archivo.id
      this.updateArchivo(updateArchivoRequest)
    }
    else {
      let createArchivoRequest = new CreateArchivoRequest
      createArchivoRequest.content = this.archivo.content
      createArchivoRequest.contentType = this.archivo.contentType
      createArchivoRequest.descripcion = this.archivo.descripcion
      createArchivoRequest.nombre = this.archivo.nombre
      createArchivoRequest.encriptado = this.archivo.encriptado
      createArchivoRequest.fechaAlta = this.archivo.fechaAlta
      this.createArchivo(createArchivoRequest)
    }
  }

  onSubmit() {
    const updateEmpresaRequest = this.setUpdateEmpresaRequest()
    this.update(updateEmpresaRequest)
  }

  createArchivo(createArchivoRequest: CreateArchivoRequest) {
    this.spinner.show()
    this.archivosService.create(createArchivoRequest).subscribe(
      data => {
        this.spinner.hide()
        this.archivo.id = data.id
        this.onSubmit()
      },
      error => {
        this.spinner.hide()
        this.snackBar.showError(error, "Error");
      })
  }

  updateArchivo(updateArchivoRequest: UpdateArchivoRequest) {
    this.spinner.show()
    this.archivosService.update(updateArchivoRequest).subscribe(
      async data => {
        this.spinner.hide()
        this.snackBar.showSuccess("Logo guardado.", "Exito");
        await this.onSubmit()
      },
      error => {
        this.spinner.hide()
        this.snackBar.showError(error, "Error");
      })
  }

  seleccionaLocalidad(event: LocalidadDto) {
    this.localidad = event
    this.localidadBasicDto.id = this.localidad.id
    this.localidadBasicDto.descripcion = this.localidad.descripcion
  }

  setCreateEmpresaRequest(): CreateEmpresaRequest {
    var createEmpresaRequest = new CreateEmpresaRequest
    createEmpresaRequest.nombre = this.formEmpresa.controls['Nombre'].value
    createEmpresaRequest.ubicacion = this.formEmpresa.controls['Ubicacion'].value
    createEmpresaRequest.piso = this.formEmpresa.controls['Piso'].value
    createEmpresaRequest.departamento = this.formEmpresa.controls['Departamento'].value
    createEmpresaRequest.idTipoCondicionContable = this.formEmpresa.controls['TipoCondicionContable'].value
    createEmpresaRequest.iibb = this.formEmpresa.controls['IIBB'].value
    createEmpresaRequest.cuit = this.formEmpresa.controls['CUIT'].value
    createEmpresaRequest.telefono = this.formEmpresa.controls['Telefono'].value
    createEmpresaRequest.correo = this.formEmpresa.controls['Correo'].value
    createEmpresaRequest.web = this.formEmpresa.controls['Web'].value
    createEmpresaRequest.webApp = this.formEmpresa.controls['WebApp'].value
    createEmpresaRequest.idLocalidad = this.localidadBasicDto.id
    createEmpresaRequest.idArchivoLogo =  this.archivo.id

    return createEmpresaRequest
  }

  setUpdateEmpresaRequest(): UpdateEmpresaRequest {
    var updateEmpresaRequest = new UpdateEmpresaRequest
    updateEmpresaRequest.id = this.empresaDto?.id
    updateEmpresaRequest.nombre = this.formEmpresa.controls['Nombre'].value
    updateEmpresaRequest.ubicacion = this.formEmpresa.controls['Ubicacion'].value
    updateEmpresaRequest.piso = this.formEmpresa.controls['Piso'].value
    updateEmpresaRequest.departamento = this.formEmpresa.controls['Departamento'].value
    updateEmpresaRequest.idTipoCondicionContable = this.formEmpresa.controls['TipoCondicionContable'].value
    updateEmpresaRequest.iibb = this.formEmpresa.controls['IIBB'].value
    updateEmpresaRequest.cuit = this.formEmpresa.controls['CUIT'].value
    updateEmpresaRequest.telefono = this.formEmpresa.controls['Telefono'].value
    updateEmpresaRequest.correo = this.formEmpresa.controls['Correo'].value
    updateEmpresaRequest.web = this.formEmpresa.controls['Web'].value
    updateEmpresaRequest.webApp = this.formEmpresa.controls['WebApp'].value
    updateEmpresaRequest.idLocalidad =this.localidadBasicDto.id
    updateEmpresaRequest.idArchivoLogo = this.archivo.id

    return updateEmpresaRequest
  }

  create(createEmpresaRequest: CreateEmpresaRequest) {
    this.spinner.show()
    this.empresaService.create(createEmpresaRequest).subscribe(
      data => {
        this.spinner.hide()
        this.snackBar.showSuccess("Empresa creada con exito.", "Exito");
      },
      error => {
        this.spinner.hide()
        this.snackBar.showError(error, "Error");
      })
  }

  update(updateEmpresaRequest: UpdateEmpresaRequest) {
    this.spinner.show()
    this.empresaService.update(updateEmpresaRequest).subscribe(
      data => {
        this.spinner.hide()
        this.snackBar.showSuccess("Cambio realizado con exito.", "Exito");
      },
      error => {
        this.spinner.hide()
        this.snackBar.showError(error, "Error");
      })
  }

  getAllTipoCondicionContable() {
    this.spinner.hide("spCondicionContable")
    this.tiposCondicionContableService.getAll().subscribe(
      data => {
        this.spinner.hide("spCondicionContable")
        this.lstCondicionesContables = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
      },
      error => {
        this.spinner.hide("spCondicionContable")
        this.snackBar.showError(error, "Error");
      })
  }

  trackByItems(index: number, item: any): any { return item; }
}
