import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '@services/login/login.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private loginService: LoginService,
    private router: Router) { }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    throw new Error('Metodo no implementado');
  }


  canActivate
  (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.loginService.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['/login'],{
      queryParams: {returnUrl: state.url}})
      this.loginService.logout()
      return false;
    }
  }

}
