import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateTipoAjusteContratoRequest } from '@models/tipo-ajuste-contrato/createTipoAjusteContratoRequest.model';
import { TipoAjusteContratoDto } from '@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model';
import { UpdateTipoAjusteContratoRequest } from '@models/tipo-ajuste-contrato/updateTipoAjusteContratoRequest.mode';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class TipoAjusteContratoService {


  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient
  ) { }

  create(createTipoAjusteContratoRequest:CreateTipoAjusteContratoRequest):Observable<any>
  {
    return  this.http.post<any>("/api/TipoAjusteContrato/Create",createTipoAjusteContratoRequest);
  }

  update(updateTipoAjusteContratoRequest:UpdateTipoAjusteContratoRequest):Observable<any>
  {
    return  this.http.put<any>("/api/TipoAjusteContrato/Update",updateTipoAjusteContratoRequest);
  }

  getAll():Observable<TipoAjusteContratoDto[]>
  {
    return  this.http.get<TipoAjusteContratoDto[]>("/api/TipoAjusteContrato/GetAll");
  }


  getById(id:number):Observable<TipoAjusteContratoDto>
  {
    return  this.http.get<TipoAjusteContratoDto>("/api/TipoAjusteContrato/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/TipoAjusteContrato/Delete?id=" + id);
    else
      return of(null)
  }
}
