<div class="position-relative">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title>
      <strong>Rescisión de contrato </strong></h1>
  <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <ngx-spinner [zIndex]=100  bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>

      <div class="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Cuidado!</strong> Esta acción dejara sin efecto el contrato <strong>{{data.numeroContrato}}</strong>. Propiedad <strong>{{data.propiedad.descripcion | titlecase}}</strong>. Inquilino <strong>{{data.inquilino.persona.descripcion | titlecase}}</strong>.
          <p>El mismo no se podra volver a activar.</p>
      </div>
      <div class="row">
          <div class="col-lg-12">
              <form [formGroup]="form">
                  <mat-form-field class="example-full-width">
                      <mat-label>Fecha Rescisión</mat-label>
                      <input matInput [disabled]="true" [min]="startDate" [matDatepicker]="picker" formControlName="FechaRescision">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker disabled="false" color="primary" [startAt]="startDate" #picker></mat-datepicker>
                      <mat-error *ngIf="f.FechaRescision.errors?.required">Este campo es <strong>requerido</strong>.
                      </mat-error>
                  </mat-form-field>
              </form>
          </div>
      </div>
  </div>
  <br>
  <mat-divider></mat-divider>
  <div mat-dialog-actions>
      <button mat-raised-button (click)="clickRescicionContrato()" *appRole="['Contratos.Rescindir']" [disabled]="!form.valid" color="primary">Aceptar</button>
      <button mat-button cdkFocusInitial (click)="closeDialog()" mat-dialog-close color="warn">Cancelar</button>
  </div>

</div>
