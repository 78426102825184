import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiVersionComponent } from '../views/api-version/api-version.component';
import { SharedModule } from './shared.module';



@NgModule({
  declarations: [
    ApiVersionComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[ApiVersionComponent]
})
export class ApiVersionModule { }
