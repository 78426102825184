import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CuentaContableDto } from '@models/cuentas-contables/cuentaContableDto.model';
import { CuentasContablesService } from '@services/cuentas-contables/cuentas-contables.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { DialogCuentaContableComponent } from '@views/cuentas-contables/dialog-cuenta-contable/dialog-cuenta-contable.component';



@Component({
  selector: 'app-buscar-cuenta-contable',
  templateUrl: './buscar-cuenta-contable.component.html',
  styleUrls: ['./buscar-cuenta-contable.component.css']
})
export class BuscarCuentaContableComponent implements OnInit {


  cuentaContableSelect: EventEmitter<BaseDto> = new EventEmitter()
  public filteredCuentaContable!: Observable<BaseDto[]>;
  cuentasContable: BaseDto[] = []
  cuentaContable!: BaseDto
  public entityControl = new FormControl();
  @Output() eventEntity = new EventEmitter<BaseDto | null>();
  @Input() cuentaContableEntrada: BaseDto = new BaseDto;
  cuentaContableDto!: CuentaContableDto
  @Input() habilitaNuevo: boolean = false;
  @Input() ccAsignada: boolean = false;

  entitySelect!: number | null;

  constructor(
    private cuentasContablesService: CuentasContablesService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    public dialog: MatDialog,
  ) {
    this.eventEntity = new EventEmitter<BaseDto | null>();
  }

  ngOnInit(): void {

    this.getAllCuentasContables();
    if (!isFalsy(this.cuentaContableEntrada)) {
      this.entityControl.setValue(this.cuentaContableEntrada.descripcion)
      this.findOption(this.cuentaContableEntrada.descripcion)
    }

    this.cuentasContablesService.enviarCuentaContableDtoObservable.subscribe(x =>
      this.cuentasContable.push(x)
    )
  }

  selectValue(event: BaseDto | null) {
    if (isFalsy(event)){
      this.entitySelect=null
      this.entityControl.setValue("")
    }
    this.eventEntity.emit(event)
  }
  ngOnChanges(): void {
    this.getAllCuentasContables();
    if (!isFalsy(this.cuentaContableEntrada)) {
      this.entityControl.setValue(this.cuentaContableEntrada.descripcion)
      this.findOption(this.cuentaContableEntrada.descripcion)
    }

    this.cuentasContablesService.enviarCuentaContableDtoObservable.subscribe(x =>
      this.cuentasContable.push(x)
    )
  }

  findOption(val: string) {
    this.spinner.show("spBusqueda")
    for (let i = 0; i < this.cuentasContable.length; i++) {
      if (this.cuentasContable[i].descripcion.toUpperCase() == val.toUpperCase()) {
        this.eventEntity.emit(this.cuentasContable[i])
        this.entitySelect = this.cuentasContable[i].id
      }
    }
    this.spinner.hide("spBusqueda")
    return this.cuentasContable.filter(cc => cc.descripcion.toLowerCase().includes(val.toLowerCase()));
  }

  getAllCuentasContables() {
    this.spinner.show("spBusqueda")
    this.cuentasContablesService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda")
          this.cuentasContable = data

          this.filteredCuentaContable = this.entityControl.valueChanges.pipe(
            startWith(''),
            map((term: any) => this.findOption(term))
          );

        },
        error => {
          this.spinner.hide("spBusqueda")
          this.snackBar.showError(error, "Error");
        })
  }

  openDialog(action: any, cuentaContableDto: CuentaContableDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%"
    dialogConfig.data = { action, cuentaContableDto }
    const dialogRef = this.dialog.open(DialogCuentaContableComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar)
        if (!isFalsy(result.data))
        {
          this.eventEntity.emit(result.data)
        }

    });
  }

  trackByItems(index: number, item: any): any { return item; }

}
