import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { TipoSelladoDto } from '@models/tipo-sellado/tipoSelladoDto.model';

@Component({
  selector: 'app-dialog-tipo-sellado',
  templateUrl: './dialog-tipo-sellado.component.html',
  styleUrls: ['./dialog-tipo-sellado.component.css']
})
export class DialogTipoSelladoComponent implements OnInit {

  action:string;
  local_data:any;
  form: any;
  formBuilder: FormBuilder = new FormBuilder;

  get f() { return this.form.controls; }
  constructor(
    public dialogRef: MatDialogRef<DialogTipoSelladoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TipoSelladoDto) {
    this.local_data = {...data};
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      //Persona
      Descripcion: ["", Validators.compose([Validators.required,])],
      Valor: ["", Validators.compose([Validators.required,])],
    })

    if(this.action != AccionesDialog.Agregar){
      this.form.controls['Descripcion'].disable();
      this.form.controls['Descripcion'].setValue(this.local_data.descripcion);
      this.form.controls['Valor'].setValue(this.local_data.valor);
    }
  }

  doAction(){
    this.dialogRef.close({event:this.action,data:this.local_data});
  }

  closeDialog(){
    this.dialogRef.close({event: AccionesDialog.Cancelar});
  }

  ngOnInit(): void {
  }

}
