<div class="position-relative">

    <ngx-spinner name="spCrearRol" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom"
        [fullScreen]="false">
    </ngx-spinner>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-lg-6">
                <mat-form-field>
                    <input placeholder="Descripción" type="text" formControlName="Descripcion" matInput>
                    <mat-error *ngIf="f.Descripcion.errors?.required">Este campo es
                        <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3">
                <button mat-raised-button color="primary" *appRole="['Roles.Crear']" (click)="doAction()"
                    [disabled]="!form.valid">Agregar</button>
            </div>
            <div class="col-lg-3">
                <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancelar</button>
            </div>
        </div>
    </form>
</div>
