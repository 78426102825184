import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Roles } from '@models/base/identificadores.model';
import { RolDto } from '@models/seguridad/roles/rolDto.model';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { LoginService } from '@services/login/login.service';
import { PersonasService } from '@services/personas/personas.service';
import { RolesService } from '@services/seguridad/roles/roles.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.css']
})
export class PerfilUsuarioComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion = new MatAccordion;
  titulo: string = '';

  subMenuActive: boolean = false;
  usuarioForm: FormGroup;
  public personaDetailDto: PersonaDetailDto = new PersonaDetailDto;
  public UsuarioDto: UsuarioDto = new UsuarioDto;
  userString: any;
  submitted = false;
  parametro: string = '';
  idTipoDocumento: number = 0;
  idEstadoCivil: number = 0;
  idPersona: number = 0;
  idUsuario: number = 0;
  idRol: number = 0;
  valor: any;
  errorMessage: string = "";
  usuarioCliente: boolean = false;
  formBuilder: FormBuilder = new FormBuilder;
  rol!: RolDto;
  get f() { return this.usuarioForm.controls; }


  constructor(
    private router: Router,
    private loginService: LoginService,
    private personasService: PersonasService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private rolesService: RolesService
  ) {

    this.usuarioForm = this.formBuilder.group({
      NombreUsuario: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      ApellidoMaterno: ['', Validators.compose([Validators.maxLength(50)])],
      Persona: [''],
      Rol: [''],
    })

  }

  ngOnInit(): void {
    const user = this.loginService.getCurrentUser()

    this.idUsuario = user.id;
    this.usuarioForm.controls["NombreUsuario"].disable();
    this.usuarioForm.controls["NombreUsuario"].setValue(user.name)

    this.idPersona = user.persona.id;
    this.usuarioForm.controls["Persona"].disable();
    this.usuarioForm.controls["Persona"].setValue(user.persona.nombreCompleto)

    this.idRol = user.rol.id;
    this.usuarioForm.controls["Rol"].disable();

    switch (user.rol.id) {
      case Roles.SuperAdministradores:
        this.usuarioForm.controls["Rol"].setValue("Super Administradores")
        break;
      case Roles.Administradores:
        this.usuarioForm.controls["Rol"].setValue("Administradores")
        break;
      case Roles.Usuarios:
        this.usuarioForm.controls["Rol"].setValue("Usuarios")
        break;
      case Roles.Clientes:
        this.usuarioForm.controls["Rol"].setValue("Clientes")
        this.usuarioCliente = true
        break;
      default:
        break;
    }

    // if(!isFalsy(this.idRol ))
    // this.getRol(this.idRol)
  }
  getRol(id:number) {
    this.spinner.show("spRol")
    this.rolesService.getById(id)
    .subscribe(
      data => {
        this.spinner.hide("spRol")
        this.usuarioForm.controls["Rol"].setValue(data.descripcion)
      },
      error => {
        this.spinner.hide("spRol")
        this.snackBar.showError(error, "Error");
      });
  }
  goToUsuarios() { }

  editarPersona() {
    this.getPersonaById(this.idPersona)
  }

  onSubmit() {
    this.submitted = true;
  }

  getPersonaById(idPersona: number) {
    this.spinner.show("spPersona")
    this.personasService.getPersonaById(this.idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.idPersona = parseInt(data.id.toString())
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        });
  }
}
