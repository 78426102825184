import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TipoDocumentoDto } from '@models/tipo-documento/tipoDocumentoDto.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class TiposDocumentoService {

  TipoDocumentoDto:TipoDocumentoDto= new TipoDocumentoDto() ;
  lstTipoDocumentoDto: TipoDocumentoDto[] = [];

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http:HttpClient) {
  }

  getAll():Observable<TipoDocumentoDto[]> {
    return   this.http.get<TipoDocumentoDto[]>("/api/TiposDocumento/GetAll");
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/TiposDocumento/Delete?id=" + id);
    else
      return of(null)
  }
}
