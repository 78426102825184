import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { data } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateCaracteristicaPropiedadRequest } from '@models/propiedades/createCaracteristicaPropiedadRequest.model';
import { PropiedadCaracteristicaPropiedadDto } from '@models/propiedades/propiedadCaracteristicaPropiedadDto.model';
import { UpdateCaracteristicaPropiedadRequest } from '@models/propiedades/updateCaracteristicaPropiedadRequest.model';
import { PropiedadesService } from '@services/propiedades/propiedades.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogDeleteComponent } from '../genericos/dialog-delete/dialog-delete.component';
import { CreateDialogCaracteristicaPropiedadComponent } from './create-dialog-caracteristica-propiedad/create-dialog-caracteristica-propiedad.component';
import { DeleteDialogCaracteristicaPropiedadComponent } from './delete-dialog-caracteristica-propiedad/delete-dialog-caracteristica-propiedad.component';
import { UpdateDialogCaracteristicaPropiedadComponent } from './update-dialog-caracteristica-propiedad/update-dialog-caracteristica-propiedad.component';
import { CaracteristicasPropiedadService } from '@services/caracteristicas-propiedad/caracteristicas-propiedad.service';



@Component({
  selector: 'app-caracteristica-propiedad',
  templateUrl: './caracteristica-propiedad.component.html',
  styleUrls: ['./caracteristica-propiedad.component.css']
})

export class CaracteristicaPropiedadComponent implements OnInit {
  data = false
  dataSource = new MatTableDataSource<PropiedadCaracteristicaPropiedadDto>();
  obj?: undefined
  public displayedColumns: string[] = [];
  public columnsFilters = {};
  searchCaracteristica = new FormControl();
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  idPropiedad: number = 0;
  idCaractersiticaPropiedad: number = 0;


  constructor(
    private caracteristicasPropiedadService: CaracteristicasPropiedadService,
    public dialog: MatDialog,
    private aRoute: ActivatedRoute,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['descripcion', 'cantidad', 'accion'] :
        ['descripcion', 'cantidad', 'accion'];
    });
  }


  ngOnInit() {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idPropiedad = Number(routeParams.get('id'));
    this.getAllCaracteristicasPropiedadByIdPropiedad()
  }

  agregar() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = data

    const dialogRef = this.dialog.open(CreateDialogCaracteristicaPropiedadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar) {
        let found = false
        this.dataSource.data.forEach((element, index) => {
          if (element.caracteristica.id === result.data.caracteristica.id)
            found = true
        });

        if (result.data && !found) {
          this.createCaracteristicaPropiedadRequest(result.data)
        }
      }
    });
  }

  editar(data: PropiedadCaracteristicaPropiedadDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = data

    const dialogRef = this.dialog.open(UpdateDialogCaracteristicaPropiedadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar)
        if (result.data) {
          this.dataSource.data = this.dataSource.data.filter((value: PropiedadCaracteristicaPropiedadDto, key: any) => {
            if (value.id == result.data.id) {
              value.cantidad = result.data.cantidad;
              this.updateCaracteristicaPropiedadRequest(result.data)
            }
            return true;
          });
          this.data = this.dataSource.data.length >= 1 ? true : false
        }
    });
  }

  eliminar(data: PropiedadCaracteristicaPropiedadDto) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = data

    const dialogRef = this.dialog.open(DeleteDialogCaracteristicaPropiedadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar)
        if (result.data) {
          this.dataSource.data = this.dataSource.data.filter((value: PropiedadCaracteristicaPropiedadDto, key: any) => {
            return value != result.data;
          });
          this.data = this.dataSource.data.length >= 1 ? true : false
        }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  updateCaracteristicaPropiedadRequest(caracteristica: PropiedadCaracteristicaPropiedadDto) {
    const updateCaracteristicaPropiedadRequest = new UpdateCaracteristicaPropiedadRequest
    updateCaracteristicaPropiedadRequest.cantidad = caracteristica.cantidad
    updateCaracteristicaPropiedadRequest.id_CaracteristicaPropiedad = caracteristica.caracteristica.id
    updateCaracteristicaPropiedadRequest.id = caracteristica.id

    this.caracteristicasPropiedadService.updatePropiedadCaracteristicaPropiedad(updateCaracteristicaPropiedadRequest)
      .subscribe(
        data => {
          this.snackBar.showSuccess(data.text, "Exito");
          this.getAllCaracteristicasPropiedadByIdPropiedad();
        },
        error => {
          this.snackBar.showError(error, "Error");
        }
      )
  }

  createCaracteristicaPropiedadRequest(caracteristica: PropiedadCaracteristicaPropiedadDto) {
    this.spinner.show("spCaracteristica")
    const createCaracteristicaPropiedadRequest = new CreateCaracteristicaPropiedadRequest
    createCaracteristicaPropiedadRequest.cantidad = caracteristica.cantidad
    createCaracteristicaPropiedadRequest.id_CaracteristicaPropiedad = caracteristica.caracteristica.id
    createCaracteristicaPropiedadRequest.id_Propiedad = this.idPropiedad

    this.caracteristicasPropiedadService.createPropiedadCaracteristicaPropiedad(createCaracteristicaPropiedadRequest)
      .subscribe(
        data => {
          this.spinner.hide("spCaracteristica")
          this.dataSource.data.push({
            id: data.id,
            caracteristica: caracteristica.caracteristica,
            cantidad: caracteristica.cantidad
          });
          this.table.renderRows();
          this.dataSource.data = this.dataSource.data.filter((value: { id: number; caracteristica: BaseDto; cantidad: number }, key: any) => {
            return true;
          });
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.snackBar.showSuccess("Caracteristica agregada correctamente.", "Exito");
        },
        error => {
          this.spinner.hide("spCaracteristica")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }



  getAllCaracteristicasPropiedadByIdPropiedad() {
    this.spinner.show("spCaracteristica")
    this.caracteristicasPropiedadService.getAllCaracteristicasPropiedadByIdPropiedad(this.idPropiedad)
      .subscribe(
        data => {
          this.spinner.hide("spCaracteristica")
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide("spCaracteristica")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        //this.deleteRowData(result.data);
      }
    });
  }

  trackByItems(index: number, item: any): any { return item; }

}
