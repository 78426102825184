import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { data } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogDescripcionComponent } from 'src/app/views/dialog-basic/dialog-descripcion/dialog-descripcion.component';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { GetFileContentByIdArchivoRequest } from '@models/archivo/getFileContentByIdArchivoRequest.model';
import { Asuntos } from '@models/base/identificadores.model';
import { CreateDocumentoRequest } from '@models/documentos/createDocumentoRequest.model';
import { DocumentoDto } from '@models/documentos/documentoDto.model';
import { DetalleServicioPropiedadNoProcesadoDto } from '@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadNoProcesadoDto.model';
import { UploadFileRequest } from '@models/servicios/detalle-servicio-propiedad/uploadFileRequest.model';
import { UploadFileResponse } from '@models/servicios/detalle-servicio-propiedad/uploadFileResponse.model';
import { ServicioDto } from '@models/servicios/servicio/servicioDto.model';
import { ArchivosService } from '@services/archivos/archivos.service';
import { DocumentosService } from '@services/documentos/documentos.service';
import { ExcelService } from '@services/excel/excel.service';
import { DetalleServicioPropiedadService } from '@services/servicios/detalle-servicios-propiedad/detalle-servicio-propiedad.service';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';




@Component({
  selector: 'app-importar-detalles-servicio-propiedad',
  templateUrl: './importar-detalles-servicio-propiedad.component.html',
  styleUrls: ['./importar-detalles-servicio-propiedad.component.css']
})
export class ImportarDetallesServicioPropiedadComponent implements OnInit {

  files: any[] = [];
  createDocumentoRequest: CreateDocumentoRequest = new CreateDocumentoRequest;
  fileUploadState = false
  getFileContentByIdArchivoRequest!: GetFileContentByIdArchivoRequest
  lstDocumentos: DocumentoDto[] = [];
  documentoDto!: DocumentoDto;
  lstServicios!: ServicioDto[];
  servicioDto!: ServicioDto;

  //Tabla
  data = false

  public servicios: ServicioDto[] = [];
  dataSource = new MatTableDataSource<DetalleServicioPropiedadNoProcesadoDto>();
  uploadFileResponse: UploadFileResponse = new UploadFileResponse;
  displayedColumns!: string[];
  constructor(
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private archivosService: ArchivosService,
    private documentosService: DocumentosService,
    private excelService: ExcelService,
    private serviciosService: ServiciosService,
    private detalleServicioPropiedadServie: DetalleServicioPropiedadService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["nroBroche", "nroCuenta", "periodo",] :
        ["nroBroche", "nroCuenta", "periodo", "importeVto1", "fechaVto1", "importeVto2", "fechaVto2", "importeVto3", "fechaVto3", "importeVto4", "fechaVto4", "detalle"];
    });
  }



  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  applyFilter(event: any) {
    if (event && event.target && event.target instanceof HTMLInputElement) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.getAllServicios()
  }

  importarDocumento() {
    let uploadFileRequest = new UploadFileRequest
    let contenido = this.createDocumentoRequest.fileContent.split(",")
    let base64 = contenido[1]
    uploadFileRequest.fileContent = base64
    this.uploadFile(uploadFileRequest)
  }

  seleccionArchivo(archivo: ArchivoDto) {
    if (archivo.content != "") {
      this.createDocumentoRequest.fileContent = archivo.content
      this.createDocumentoRequest.fileName = archivo.descripcion
      this.createDocumentoRequest.fileType = archivo.contentType
      this.fileUploadState = true
    }
  }

  createDocumento(createDocumentoRequest: CreateDocumentoRequest) {
    this.spinner.show("fileUpload")
    this.snackBar.showSuccess("Documento creado correctamente.", "Exito");
  }

  getAllServicios() {
    this.spinner.show("spBusqueda")
    this.serviciosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda");
          this.lstServicios = data
        },
        error => {
          this.spinner.hide("spBusqueda");
          this.snackBar.showError(error, "Error");
        })
  }

  getDocumento(id: number) {
    this.spinner.show("spFileDowload")
    this.documentosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spFileDowload");
          this.documentoDto = data as DocumentoDto;
          this.descargarArchivo(data);
        },
        error => {
          this.spinner.hide("spFileDowload");
          this.snackBar.showError(error, "Error");
        })
  }

  selectServicio(event: any) {
    this.servicioDto = event.value
  }

  descargaPlantilla() {
    this.getDocumento(this.documentoDto.id)
  }

  descargarArchivo(documentoDto: DocumentoDto) {
    this.spinner.show("spFileDowload");
    this.archivosService.getFileContentByIdArchivo(documentoDto.archivo.id).subscribe
      (
        data => {
          this.spinner.hide("spFileDowload");
          this.getFileContentByIdArchivoRequest = data
          this.downloadFile(data, this.documentoDto)
        },
        error => {
          this.spinner.hide("spFileDowload");
          this.snackBar.showError(error, "Error");
        }
      )

  }

  downloadFile(getFileContentByIdArchivoRequest: GetFileContentByIdArchivoRequest, documento: DocumentoDto) {
    const link = document.createElement("a");
    let tipoArchivo: string = ""
    link.href = getFileContentByIdArchivoRequest.content;
    switch (getFileContentByIdArchivoRequest.contentType) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
        tipoArchivo = ".xlsx"
        break;
      }
      case "application/vnd.ms-excel": {
        tipoArchivo = ".xls"
        break;
      }
    }
    link.download = documento.descripcion.trim() + tipoArchivo
    link.click();
  }

  generateExcel() {
    let reportData = {
      title: 'Importacion de Servicios por Broche',
      headers: ["NroBroche", "NroCuenta", "PeriodoMes", "PeriodoAnio", "PeriodoCuota", "ImporteVto1", "FechaVto1", "ImporteVto2", "FechaVto2", "ImporteVto3", "FechaVto3", "ImporteVto4", "FechaVto4"],
      data: ''
    }

    this.excelService.generateExcel_Carga_Detalle_Servicios(reportData);
  }

  uploadFile(uploadFileRequest: UploadFileRequest) {
    if (uploadFileRequest) {
      this.spinner.show("spTableResultado")
      this.detalleServicioPropiedadServie.uploadFile(uploadFileRequest)
        .subscribe(
          data => {
            this.spinner.hide("spTableResultado");
            this.uploadFileResponse = data
            this.dataSource.data = data.detalleServicioPropiedadNoProcesados
            this.data = this.dataSource.data.length >= 1 ? true : false
          },
          error => {
            this.spinner.hide("spTableResultado");
            this.snackBar.showError(error, "Error");
          })
    }
  }

  showDetalle(detalleServicioPropiedadNoProcesadoDto: DetalleServicioPropiedadNoProcesadoDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = detalleServicioPropiedadNoProcesadoDto.detalleError as string

    const dialogRef = this.dialog.open(DialogDescripcionComponent, dialogConfig);

  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}
