import { BreakpointObserver } from '@angular/cdk/layout';
import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { IndiceAjusteContratoDto } from '@models/indice-ajuste-contrato/indiceAjusteContratoDto.model';
import { TipoAjusteContratoDto } from '@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoAjusteContratoService } from '@services/tipo-ajuste-contrato/tipoAjusteContrato.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-indice-ajuste-contrato',
  templateUrl: './dialog-indice-ajuste-contrato.component.html',
  styleUrls: ['./dialog-indice-ajuste-contrato.component.css']
})
export class DialogIndiceAjusteContratoComponent implements OnInit {

  action: string;
  local_data: any;
  form: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  lstTiposAjusteContrato!: TipoAjusteContratoDto[];

  get f() { return this.form.controls; }
  constructor(
    public dialogRef: MatDialogRef<DialogIndiceAjusteContratoComponent>,
    private tipoAjusteContratoService: TipoAjusteContratoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IndiceAjusteContratoDto) {
    this.local_data = { ...data };
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      Fecha: ["", Validators.compose([Validators.required,])],
      Valor: ["", Validators.compose([Validators.required,])],
      TiposAjusteContrato: ["", Validators.compose([Validators.required,])],
    })

    if (this.action == AccionesDialog.Modificar)
      {
        this.form.controls['Fecha'].disable();
        this.form.controls['Valor'].disable();
        this.form.controls['TiposAjusteContrato'].disable();
      }
  }

  doAction() {
    this.local_data.fecha = this.form.controls['Fecha'].value
    this.local_data.valor = this.form.controls['Valor'].value
    if (this.action == AccionesDialog.Agregar)
      {
        this.local_data.tipoAjusteContrato = new TipoAjusteContratoDto
        this.local_data.tipoAjusteContrato.id = this.form.controls['TiposAjusteContrato'].value

      }
    if(this.action == AccionesDialog.Modificar)
      this.local_data.tipoAjusteContrato.id = this.form.controls['TiposAjusteContrato'].value

    this.local_data.idTipoAjusteContrato = this.form.controls['TiposAjusteContrato'].value
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  ngOnInit(): void {
    this.getAllTipoAjusteContrato()
    this.form.controls['Fecha'].setValue(this.convierteFecha(this.data.fecha));
    this.form.controls['Valor'].setValue(this.data.valor);
    this.form.controls['TiposAjusteContrato'].setValue(this.data.tipoAjusteContrato.id);

  }

  convierteFecha(fechaOrigen: any): string {
    const fecha = fechaOrigen.split('-');
    const anio = fecha[0];
    const mes = fecha[1];
    const dia = fecha[2].substring(0, 2);
    const fechaCompleta = anio + "-" + mes + "-" + dia
    return fechaCompleta
  }

  getAllTipoAjusteContrato() {
    this.spinner.show("spBusquedaTipoAjusteContrato2")
    this.tipoAjusteContratoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTipoAjusteContrato2")
          this.lstTiposAjusteContrato = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
        },
        error => {
          this.spinner.hide("spBusquedaTipoAjusteContrato2")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  //#region Helper

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
  //#endregion

}
