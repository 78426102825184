<mat-card *appRole="['ArchivosTemporales.Ver']">
    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <mat-form-field [hidden]=!data class="example-full-width">
        <mat-label>Buscar</mat-label>
        <input matInput type="search" (keyup)="applyFilter($event)">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <br>
    <table mat-table [dataSource]="dataSource" [hidden]=!data table matSort matSortStart="desc" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->

        <!-- Id Column -->
        <!-- <ng-container matColumnDef="id">
            <mat-header-cell mat-header-cell *matHeaderCellDef> # </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
        </ng-container> -->

        <!-- Nombre archivo Column -->
        <ng-container matColumnDef="nombreArchivo">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Nombre archivo </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{element.nombreArchivo | titlecase}} </mat-cell>
        </ng-container>

        <!-- Contrato Column -->
        <ng-container matColumnDef="contrato">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Contrato </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{element.contrato.descripcion | titlecase}} </mat-cell>
        </ng-container>

        <!-- Fecha generacion Column -->
        <ng-container matColumnDef="fechaGeneracion">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Fecha generación </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{element.fechaGeneracion | date:("dd/MM/yyyy")}} </mat-cell>
        </ng-container>

        <!-- Tipo comprobante Column -->
        <ng-container matColumnDef="tipoComprobante">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Tipo comprobante </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element"> {{element.tipoComprobante.descripcion | titlecase}} </mat-cell>
        </ng-container>

        <!-- Fallo envio Column -->
        <ng-container matColumnDef="falloEnvioEmail">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Estado envío </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element">
                <mat-icon matTooltip="Envio no realizado." *ngIf="element.falloEnvioEmail==true">cancel_schedule_send</mat-icon>
                <mat-icon matTooltip="Envio realizado." *ngIf="element.falloEnvioEmail==false">send</mat-icon>
            </mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="accion">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let element" class="action-link">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
                <mat-menu #menu="matMenu">
                    <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                  <mat-icon>visibility</mat-icon>
                  <span>Ver</span>
                </button> -->
                    <button *appRole="['ArchivosTemporales.Ver']" mat-menu-item (click)="descargarArchivo(element)">
                  <mat-icon>download</mat-icon>
                  <span>Descargar</span>
                </button>
                    <button *appRole="['ArchivosTemporales.Eliminar']" mat-menu-item (click)="openDialog(element)">
                  <mat-icon>delete</mat-icon>
                  <span>Eliminar</span>
                </button>
                </mat-menu>
            </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </table>
    <div class="no-data-table">
        <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
    </div>
    <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="10" [pageSizeOptions]="[5, 10, 20,50,100,200]">
    </mat-paginator>
</mat-card>
