<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Acción comprobante</h1>
<mat-divider></mat-divider>
<br>
<div mat-dialog-content>
    <div class="container-button">
        <button mat-mini-fab color="primary" *appRole="['Comprobantes.Descargar']" matTooltip="Descargar archivo." (click)="descargarRecibo()">
    <mat-icon>file_download
    </mat-icon>
  </button>&nbsp;
  <!-- <button mat-mini-fab color="primary" *appRole="['Comprobantes.Descargar']" matTooltip="Enviar email" (click)="descargarRecibo()">
    <mat-icon>mail
    </mat-icon>
  </button>&nbsp;
  <button mat-mini-fab color="primary" disable *appRole="['Comprobantes.Descargar']" matTooltip="Envio whatsapp." (click)="descargarRecibo()">
    <mat-icon>whatshot
    </mat-icon>
  </button> -->
    </div>
</div>
<br>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cerrar</button>
</div>
