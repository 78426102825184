import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { ServicioPropiedadDto } from '@models/servicios/servicio-propiedad/servicioPropiedadDto.model';
import { UpdateServicioPropiedadRequest } from '@models/servicios/servicio-propiedad/updateServicioPropiedadRequest.model';
import { ServiciosPropiedadService } from '@services/servicios/servicios-propiedad/servicios-propiedad.service';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-form-update-servicio-propiedad',
  templateUrl: './form-update-servicio-propiedad.component.html',
  styleUrls: ['./form-update-servicio-propiedad.component.css']
})
export class FormUpdateServicioPropiedadComponent implements OnInit {

  formServicioPriopidad: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  idQuienPaga: any;
  idPropiedad!: number;
  idServicioPropiedad!: number;
  servicioPropiedad: any;
  idServicio: any;
  updateServicioPropiedadRequest: UpdateServicioPropiedadRequest = new UpdateServicioPropiedadRequest;
  get f() { return this.formServicioPriopidad.controls; }
 private sub: any;
  constructor(
    private serviciosPropiedadService: ServiciosPropiedadService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {
    this.formServicioPriopidad = this.formBuilder.group({
      //Persona
      Servicio: ["", Validators.compose([Validators.required])],
      NroCuenta: ["", Validators.compose([Validators.maxLength(50),Validators.required])],
      NroGestionPersonal: ['', Validators.compose([Validators.maxLength(50)])],
      ReferenciaPago: ['', Validators.compose([Validators.maxLength(50)])],
    });
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
       this.idServicioPropiedad = +params['id'];
    });
    this.getServicioPropiedadById(this.idServicioPropiedad)
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  selectServicio(event:any){
    this.idServicio = event.id
  }

  getServicioPropiedadById(id:number){
    this.spinner.show()
    this.serviciosPropiedadService.getServicioPropiedadById(id)
    .subscribe(
      data => {
        this.spinner.hide()
        this.setFormularioServicioPropiedad(data)
      },
      error => {
        this.spinner.hide()
          this.snackBar.showError( error, "Error");
      }
    )
  }
  setFormularioServicioPropiedad(data: ServicioPropiedadDto) {
    this.idServicioPropiedad = data.id
    this.idPropiedad = data.idPropiedad
    this.idServicio = data.servicio.id
    this.formServicioPriopidad.controls["Servicio"].setValue(data.servicio.descripcion)
    this.formServicioPriopidad.controls["Servicio"].disable()
    this.formServicioPriopidad.controls["NroGestionPersonal"].setValue(data.nroGestionPersonal)
    this.formServicioPriopidad.controls["NroCuenta"].setValue(data.nroCuenta)
    this.formServicioPriopidad.controls["ReferenciaPago"].setValue(data.referenciaPago)
  }

  setServicioPropiedad(){
    this.updateServicioPropiedadRequest.id = this.idServicioPropiedad
    this.updateServicioPropiedadRequest.id_Propiedad = this.idPropiedad
    this.updateServicioPropiedadRequest.id_Servicio = this.idServicio
    this.updateServicioPropiedadRequest.nroCuenta = this.formServicioPriopidad.controls["NroCuenta"].value
    this.updateServicioPropiedadRequest.nroGestionPersonal = this.formServicioPriopidad.controls["NroGestionPersonal"].value
    this.updateServicioPropiedadRequest.referenciaPago = this.formServicioPriopidad.controls["ReferenciaPago"].value
  }

  update()
  {
    this.spinner.show()
    this.setServicioPropiedad()
    this.serviciosPropiedadService.updateServicioPropiedad(this.updateServicioPropiedadRequest)
    .subscribe(
      data => {
        this.spinner.hide()
        this.snackBar.showSuccess(data.text, "Exito")
        this.router.navigate(["/serviciospropiedad/servicio/", this.idPropiedad])
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError( error, "Error");
        });
  }
}
