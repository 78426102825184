import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '@services/login/login.service';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class GlobalHttpErrorInterceptorService {

  constructor(
    private loginService: LoginService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        // Manejo de errores según el código de estado
        this.handleHttpError(error);

        // Si la respuesta contiene un mensaje de error, se maneja
        if (error.error) {
          this.processErrorMessage(error);
        } else {
          this.handleErrorWithoutDetails(error);
        }

        return throwError(error.message); // Retorna el mensaje de error
      })
    );
  }

  private handleHttpError(error: any): void {
    switch (error.status) {
      case 400:
        error.message = "El servidor no puede o no procesará la petición debido a un error en la solicitud.";
        break;
      case 401:
      case 403:
        this.handleUnauthorizedError();
        break;
      case 404:
        error.message = "El servidor no pudo encontrar el contenido solicitado.";
        break;
      case 500:
        error.message = "El servidor ha encontrado una situación que no sabe cómo manejarla.";
        break;
      case 503:
        error.message = "El servidor no está listo para manejar la petición. Puede estar en mantenimiento o sobrecargado.";
        break;
      case 504:
        error.message = "Error de conexión. El servidor no respondió a tiempo (Gateway Timeout).";
        break;
      default:
        error.message = "Ups... hubo un problema en el sistema.";
    }
  }

  private handleUnauthorizedError(): void {
    this.loginService.logout();
    this.router.navigate(['login']);
  }

  private processErrorMessage(error: any): void {
    error.message = !isFalsy(error.error.Message)
      ? error.error.Message
      : isFalsy(error.message)
        ? ""
        : error.message;

    if (isFalsy(error.message)) {
      const err = JSON.parse(error.error);
      error.message = err.Message;
    }

    this.customizeErrorMessage(error);
  }

  private customizeErrorMessage(error: any): void {
    if (error.message.includes("REFERENCE 'FK")) {
      error.message = "El registro no se puede eliminar debido a referencias asociadas.";
    }

    if (error.message.includes("Http failure response")) {
      // error.message = "Error de conexión."; // Comentado para evitar sobreescribir
    }

    if (error.message.includes("A connection attempt failed because the connected party did not properly respond")) {
      error.message = "Un intento de conexión falló debido a que la parte conectada no respondió correctamente después de un período de tiempo.";
    }

    if (error.message.includes("Sequence contains no elements")) {
      error.message = "La consulta no contiene elementos.";
    }

    if (error.message.includes("Year, Month, and Day parameters describe an un-representable DateTime.")) {
      error.message = "Los parámetros de fecha y hora no son válidos.";
    }

    if (error.message.includes("Execution Timeout Expired")) {
      error.message = "Tiempo de espera de ejecución caducado.";
    }

    if (error.message.includes("An error occurred while updating the entries")) {
      error.message = "Ocurrió un error al actualizar las entradas.";
    }
  }

  private handleErrorWithoutDetails(error: any): void {
    if (error.message === "Unauthorized") {
      this.handleUnauthorizedError();
    }
    else {
      error.message = "Ups... sin autorización en el sistema.";
    }
  }
}
