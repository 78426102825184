// src/app/services/error/rollbarErrorHandler.service.ts
import * as Rollbar from 'rollbar';
import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginService } from '@services/login/login.service';

// Declaración de RollbarService como un InjectionToken
export const RollbarService = new InjectionToken<Rollbar>('rollbar');

// Exportación de la clase del handler
@Injectable()
export class RollbarErrorHandlerService implements ErrorHandler {

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private loginService: LoginService
  ) {}

  handleError(err: any): void {
    const entorno = environment.entorno;
    const userSession = this.loginService.getCurrentUser();

    // Configurar datos personalizados para Rollbar
    this.rollbar.configure({
      payload: {
        usuario: userSession?.name,
        idPersona: userSession?.persona?.id,
        empresa: userSession?.empresa,
        rol: userSession?.rol?.id,
        environment: entorno === "Desarrollo" ? 'develop' : entorno === "Testing" ? 'staging' : 'production',
        code_version: '1.0.0',  // Ajusta según tu version actual
        custom_data: '12/08/2022',  // Actualiza con datos relevantes
      }
    });

    // Si el error es un objeto, asegurarse de tener un mensaje útil
    if (err instanceof Error) {
      const errorMessage = err.message || 'No error message available';
      this.rollbar.error(errorMessage, err);
    } else {
      // Si el error no es una instancia de Error, se registra como un mensaje estándar
      this.rollbar.error('An unknown error occurred', err);
    }
  }
}

// Exportación de la función factory para crear el servicio Rollbar
export function rollbarFactory() {
  const rollbar = new Rollbar();

  // Configuración de Rollbar
  rollbar.configure({
    accessToken: '98974e1779eb45b39fed2296c26f2178',
    autoInstrument: true
  });

  // Configuración adicional de Rollbar
  rollbar.configure({ captureUncaught: true, captureUnhandledRejections: true });
  rollbar.configure({ sendConfig: true });
  rollbar.configure({ captureIp: true });

  // Setear el entorno según la configuración
  const entorno = `${environment.entorno}`;
  if (entorno === "Desarrollo") rollbar.configure({ payload: { environment: 'develop' } });
  if (entorno === "Testing") rollbar.configure({ payload: { environment: 'staging' } });
  if (entorno === "Produccion") rollbar.configure({ payload: { environment: 'production' } });

  return rollbar;
}
