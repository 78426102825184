import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { startWith } from 'rxjs/operators';
import { BaseDto } from '@models/base/baseDto.model';
import { BancosService } from '@services/bancos/bancos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-buscar-banco',
  templateUrl: './buscar-banco.component.html',
  styleUrls: ['./buscar-banco.component.css']
})
export class BuscarBancoComponent implements OnInit {

  bancoSelect: EventEmitter<BaseDto> = new EventEmitter()
  public filteredBancos!: Observable<BaseDto[]>;
  bancos: BaseDto[] = []
  banco: BaseDto = new BaseDto;
  public entityControl = new FormControl();
  @Output() eventoBanco = new EventEmitter<BaseDto>();
  @Input() bancoEntrada: BaseDto = new BaseDto();
  entitySelect!: number | null;

  constructor(
    private bancosService: BancosService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    this.getAllBanco();
    if (!isFalsy(this.bancoEntrada))
      this.entityControl.setValue(isFalsy(this.bancoEntrada) ? "" : this.bancoEntrada.descripcion)
  }

  selectValue(event: any) {
    if (isFalsy(event)) {
      this.entitySelect = null
      this.entityControl.setValue("")
    }
    this.eventoBanco.emit(event)
  }

  ngOnChanges(): void {
    if (!isFalsy(this.bancoEntrada))
      this.entityControl.setValue(isFalsy(this.bancoEntrada) ? "" : this.bancoEntrada.descripcion)

  }

  findOption(val: string) {
    for (let i = 0; i < this.bancos.length; i++) {
      if (this.bancos[i].descripcion.toUpperCase() == val.toUpperCase()) {
        this.entitySelect = this.bancos[i].id
        //this.eventoBanco.emit(this.bancos[i])
      }
    }
    return this.bancos.filter((banco) => new RegExp(val, 'gi').test(banco.descripcion));
  }

  getAllBanco() {
    this.spinner.show("spBusquedaBanco")
    this.bancosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaBanco")
          this.bancos = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
          this.filteredBancos = this.entityControl.valueChanges.pipe(
            startWith(''),
            map((term: any) => this.findOption(term))
          );
        },
        error => {
          this.spinner.hide("spBusquedaBanco")
          this.snackBar.showError(error, "Error");
        });
  }

  trackByItems(index: number, item: any): any { return item; }

}
