import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { UpdateRolInUserRequest } from '@models/usuarios/updateRolInUserRequest.model';
import { UpdateUserRequest } from '@models/usuarios/updateUserRequest.model';
import { RolesService } from '@services/seguridad/roles/roles.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { UsuariosService } from '@services/usuarios/usuarios.service';
import { RolDto } from '@models/seguridad/roles/rolDto.model';
import { PersonasService } from '@services/personas/personas.service';
import { Roles } from '@models/base/identificadores.model';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'app-form-usuario-update',
  templateUrl: './form-usuario-update.component.html',
  styleUrls: ['./form-usuario-update.component.css']
})
export class FormUsuarioUpdateComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion = new MatAccordion;
  @Input() personaId!: number;
  public personaControl = new FormControl();
  titulo: string = '';
  subMenuActive: boolean = false;
  stringObject: any;
  stringJson: any;
  usuarioForm!: FormGroup;
  public personaDetailDto: PersonaDetailDto = new PersonaDetailDto;
  UsuarioDto: UsuarioDto = new UsuarioDto;
  submitted = false;
  parametro: string = '';
  idRol: number = 0;
  isDisabled: boolean = false;
  idPersona: number = 0;
  valor: any
  errorMessage: string = "";
  personas: PersonaDetailDto[] = [];
  hide = true;
  public filteredPersonas!: Observable<PersonaDetailDto[]>;
  updateUserRequest: UpdateUserRequest = new UpdateUserRequest;
  idUsuario!: number;
  formBuilder: FormBuilder = new FormBuilder;
  get f() { return this.usuarioForm.controls; }
  roles: RolDto[] = [ ]
  constructor(
    private usuariosService: UsuariosService,
    private rolesService: RolesService,
    private aRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private personasService: PersonasService
  ) {

    this.usuarioForm = this.formBuilder.group({
      IdPersona: [''],
      IdRol: [''],
      Rol: [''],
      NombreUsuario: [''],
      NombreCompleto: [''],
      ClaveNueva: [''],
    })
    this.usuarioForm.controls["NombreCompleto"].disable();
    this.usuarioForm.controls["NombreUsuario"].disable();
  }



  ngOnInit(): void {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idUsuario = Number(routeParams.get('id'));
    this.getUsuarioById(this.idUsuario)
    this.getAllRoles()
  }



  getUsuarioById(id: number) {
    this.spinner.show();
    this.usuariosService.getUserById(id)
      .subscribe(
        data => {
          this.spinner.hide();
          this.UsuarioDto = data
          this.setFormularioUsuario()
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        });
  }
  selectRol(event: any) {
    this.idRol = parseInt(event)
  }

  goToFormPersona() {
    if (this.idPersona != 0)
      this.router.navigate(["/personas", this.idPersona])
  }

  onSubmit() {
    this.submitted = true;
    if (this.usuarioForm.valid == true) {
      this.spinner.show();
      let updateRolInUserRequest = new UpdateRolInUserRequest
      updateRolInUserRequest.userName = this.usuarioForm.controls['NombreUsuario'].value
      updateRolInUserRequest.id_Rol = this.idRol;

      this.usuariosService.updateRolInUser(updateRolInUserRequest).subscribe(
        data => {
          this.spinner.hide();
          this.snackBar.showSuccess(data.text, "Exito")
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      )

    }
  }


  getAllRoles() {
    this.spinner.show();
    this.rolesService.getAll()
      .subscribe(
        data => {
          this.spinner.hide();
          this.roles = data.filter(x => x.id != Roles.SuperAdministradores)
          this.roles = data
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        });
  }


  setFormularioUsuario() {
    this.usuarioForm.controls['NombreUsuario'].setValue(this.UsuarioDto.name)
    this.usuarioForm.controls['NombreCompleto'].setValue(this.UsuarioDto.persona.nombreCompleto)
    this.idPersona = this.UsuarioDto.persona.id
    this.usuarioForm.controls['Rol'].setValue(this.UsuarioDto.rol.id)
    this.usuarioForm.controls['IdPersona'].setValue(this.UsuarioDto.persona.id)
    this.usuarioForm.controls['IdRol'].setValue(this.UsuarioDto.rol.id)
  }

  onCancel() {
    this.usuarioForm.reset();
    this.router.navigate(["/usuarios"])
  }

  editarPersona(idPersona:number) {
    this.getPersonaByIdAndRoute(idPersona)
  }

  getPersonaByIdAndRoute(idPersona: number) {
    this.spinner.show("spPersona")
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        });
  }

  trackByItems(index: number, item: any): any { return item; }
}
