
import { Component, OnInit } from '@angular/core';
import { Roles } from '@models/base/identificadores.model';
import { LoginService } from '@services/login/login.service';

@Component({
  selector: 'personas',
  templateUrl: './personas.component.html',
  styleUrls: ['./personas.component.css']
})
export class PersonasComponent implements OnInit {
  constructor(
  ) {
  }
  ngOnInit(): void {

  }



}
