import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateGrupoTipoGarantiaInquilinoRequest } from '@models/grupos-tipo-garantia-inquilino/createGrupoTipoGarantiaInquilinoRequest.model';
import { UpdateGrupoTipoGarantiaInquilinoRequest } from '@models/grupos-tipo-garantia-inquilino/updateGrupoTipoGarantiaInquilinoRequest.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { GruposTipoGarantiaInquilinoService } from '@services/grupos-tipo-garantia-inquilino/grupos-tipo-garantia-inquilino.service';
import { GruposTipoGarantiaService } from '@services/grupos-tipo-garantia/grupos-tipo-garantia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { DialogGruposTipoGarantiaComponent } from '@views/grupos-tipo-garantia/dialog-grupos-tipo-garantia/dialog-grupos-tipo-garantia.component';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';

@Component({
  selector: 'app-dialog-grupos-tipo-garantia-inquilino',
  templateUrl: './dialog-grupos-tipo-garantia-inquilino.component.html',
  styleUrls: ['./dialog-grupos-tipo-garantia-inquilino.component.css']
})
export class DialogGruposTipoGarantiaInquilinoComponent implements OnInit {

  action: string = "";
  local_data: any;
  form: any;
  formBuilder: FormBuilder = new FormBuilder;
  lstGrupoTipoGarantia: BaseDto[] = []
  inquilinoDto!: InquilinoDto;
  get f() { return this.form.controls; }

  constructor(
    private gruposTipoGarantiaService: GruposTipoGarantiaService,
    private gruposTipoGarantiaInquilinoService: GruposTipoGarantiaInquilinoService,
    public dialogRef: MatDialogRef<DialogGruposTipoGarantiaComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private inquilinosService: InquilinosService

  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;


    this.form = this.formBuilder.group({
      GrupoTipoGarantia: ["", Validators.compose([Validators.required,])],
    })
  }


  ngOnInit(): void {
    this.getAllGruposTipoGarantia()
    if (this.action != AccionesDialog.Agregar)
      this.inquilinoDto = this.data.inquilino
    if (this.action == AccionesDialog.Agregar && this.data.inquilinoDto)
      this.inquilinoDto = this.data.inquilinoDto
  }

  doAction() {
    let id = this.local_data.id
    let idInquilino = isFalsy(this.local_data.inquilino) ? this.data.inquilinoDto.id : this.local_data.inquilino.id
    let idGrupo = this.form.controls["GrupoTipoGarantia"].value

    switch (this.action) {
      case AccionesDialog.Agregar:
        this.addRowData(idGrupo, idInquilino);
        break;
      case AccionesDialog.Modificar:
        this.updateRowData(id, idGrupo, idInquilino)
        break;
      case AccionesDialog.Eliminar:
        this.deleteRowData(idInquilino,idGrupo)
        break;
      default:
        break;
    }
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }


  getAllGruposTipoGarantia() {
    this.spinner.show("spBusqueda")
    this.gruposTipoGarantiaService.getGruposTipoGarantias()
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda")
          this.lstGrupoTipoGarantia = data
          if (this.local_data.action != AccionesDialog.Agregar) {
            this.form.controls["GrupoTipoGarantia"].setValue(this.local_data.grupo)
          }
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spBusqueda")
        }
      )
  }

  selectInquilino(event: InquilinoDto) {
    if (!isFalsy(event)) {
      this.inquilinoDto = event
      this.local_data.id = this.inquilinoDto.id
      this.local_data.inquilino = this.inquilinoDto
    }
  }


  addRowData(idGrupo: number, idInquilino: number) {
    this.spinner.show()
    let createGrupoTipoGarantiaInquilinoRequest = new CreateGrupoTipoGarantiaInquilinoRequest
    createGrupoTipoGarantiaInquilinoRequest.idGrupo = idGrupo
    createGrupoTipoGarantiaInquilinoRequest.idInquilino = idInquilino

    this.gruposTipoGarantiaInquilinoService.createGruposTipoGarantiaInquilinos(createGrupoTipoGarantiaInquilinoRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          this.inquilinosService.inquilinoDto.next(this.inquilinoDto)
          this.dialogRef.close({ event: AccionesDialog.Aceptar });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )

  }
  updateRowData(id: number, idGrupo: number, idInquilino: number) {
    this.spinner.show()
    let updateGrupoTipoGarantiaInquilinoRequest = new UpdateGrupoTipoGarantiaInquilinoRequest
    updateGrupoTipoGarantiaInquilinoRequest.id = id
    updateGrupoTipoGarantiaInquilinoRequest.idGrupo = idGrupo
    updateGrupoTipoGarantiaInquilinoRequest.idInquilino = idInquilino

    this.gruposTipoGarantiaInquilinoService.updateGruposTipoGarantiaInquilinos(updateGrupoTipoGarantiaInquilinoRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro actualizado correctamente.", "Exito");
          this.inquilinosService.inquilinoDto.next(this.inquilinoDto)
          this.dialogRef.close({ event: AccionesDialog.Aceptar });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })
  }

  deleteRowData(idInquilino: number, idGrupo:number ) {
    this.spinner.show()
    this.gruposTipoGarantiaInquilinoService.deleteGruposTipoGarantiaInquilinos(idInquilino, null)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.inquilinosService.inquilinoDto.next(this.inquilinoDto)
          this.dialogRef.close({ event: AccionesDialog.Aceptar });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError("El registro no se puede eliminar porque tiene referencias asignadas.", "Error");
        })
  }

  trackByItems(index: number, item: any): any { return item; }

}
