import { Component } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AccionesDialog } from "@models/base/identificadores.model";
import { DialogCreateMovimientoComponent } from "../dialog-create-movimiento/dialog-create-movimiento.component";


@Component({
  selector: 'app-btn-create-movimiento',
  templateUrl: './btn-create-movimiento.component.html',
  styleUrls: ['./btn-create-movimiento.component.css'],
})
export class BtnCreateMovimientoComponent {

  constructor(
    public dialog: MatDialog,
  ) {

  }
  openDialogCreateMovimiento() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '100%';
    dialogConfig.maxWidth = "95%"
    dialogConfig.data = { event: AccionesDialog.Agregar }

    let dialogRef = this.dialog.open(DialogCreateMovimientoComponent, dialogConfig);

  }
 }
