<h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <form [formGroup]="form" *ngIf="action != 'Eliminar'; else elseTemplate">
        <div class="row">
            <div class="col-lg-6">
                <mat-form-field #detalle class="full-width">
                    <input cdkFocusInitial placeholder="{{action}} Detalle" formControlName="Detalle" [(ngModel)]="local_data.detalle" matInput>
                    <mat-error *ngIf="f.Detalle.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-6">
                <mat-form-field  class="full-width">
                    <ngx-spinner [zIndex]=100 name="spBusqueda" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Tipo Bonificación</mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="TipoBonificacion">
                        <mat-option [value]="tiposBonificacion.id" *ngFor="let tiposBonificacion of lstTiposBonificacion;let i=index;trackBy:trackByItems">
                            {{tiposBonificacion.descripcion}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.TipoBonificacion.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <ng-template #elseTemplate>
        ¿Esta seguro que desea eliminar el registro <b>{{local_data.detalle}}</b>?
    </ng-template><br>
</div><br>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" *appRole="['Bonificaciones.Crear','Bonificaciones.Modificar','Bonificaciones.Eliminar']" (click)="doAction()" [disabled]="!form.valid">{{action}}</button>
    <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
</div>
