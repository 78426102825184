import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreatePersonaCuentaContableRequest } from '@models/cuentas-contable-persona/createPersonaCuentaContableRequest.model';
import { PersonaCuentaContableDto } from '@models/cuentas-contable-persona/personaCuentaContableDto.model';
import { UpdatePersonaCuentaContableRequest } from '@models/cuentas-contable-persona/updatePersonaCuentaContableRequest.model';
import { CuentaContableDto } from '@models/cuentas-contables/cuentaContableDto.model';
import { CuentasContablesService } from '@services/cuentas-contables/cuentas-contables.service';
import { PersonasCuentasContableService } from '@services/personas-cuentas-contable/personas-cuentas-contable.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { DialogCuentaContableComponent } from '@views/cuentas-contables/dialog-cuenta-contable/dialog-cuenta-contable.component';
@Component({
  selector: 'app-dialog-cuenta-contable-persona',
  templateUrl: './dialog-cuenta-contable-persona.component.html',
  styleUrls: ['./dialog-cuenta-contable-persona.component.css']
})
export class DialogCuentaContablePersonaComponent implements OnInit {
  cuentaContableDto!: CuentaContableDto
  personaCuentaContableDto!: PersonaCuentaContableDto
  formBuilder: FormBuilder = new FormBuilder;
  form: FormGroup;
  lstCuentasContables: CuentaContableDto[] = [];
  action: any;
  local_data: any;
  cuentaContableBaseDto!: BaseDto;
  get f() { return this.form.controls; }

  constructor(
    public dialog: MatDialog,
    private cuentasContablesService: CuentasContablesService,
    private personasCuentasContableService: PersonasCuentasContableService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<DialogCuentaContablePersonaComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
      Activo: [true],
    })
  }

  ngOnInit(): void {
    this.getAllCuentasContables()
    if (!isFalsy(this.data.personaCuentaContableDto.id))
      this.setFormulario(this.data.personaCuentaContableDto)
  }

  setFormulario(personaCuentaContableDto: PersonaCuentaContableDto) {
    this.form.controls["Descripcion"].setValue(personaCuentaContableDto.descripcion)
    this.form.controls["Activo"].setValue(personaCuentaContableDto.activo)
    this.cuentaContableDto = personaCuentaContableDto.cuentaContable
    if (!isFalsy(this.cuentaContableDto))
      this.cuentaContableBaseDto = { id: this.cuentaContableDto.id, descripcion: this.cuentaContableDto.descripcion }
    this.personaCuentaContableDto = personaCuentaContableDto
  }

  getAllCuentasContables() {
    this.spinner.show("spBusqueda")
    this.cuentasContablesService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda")
          this.lstCuentasContables = data
        },
        error => {
          this.spinner.hide("spBusqueda")
        }
      )
  }

  selectCuentaContable(event: any) {
    this.cuentaContableDto = event
  }

  doAction() {
    if (this.action == AccionesDialog.Agregar) {
      let createPersonaCuentaContableRequest: CreatePersonaCuentaContableRequest = new CreatePersonaCuentaContableRequest
      createPersonaCuentaContableRequest.descripcion = this.form.controls["Descripcion"].value
      createPersonaCuentaContableRequest.activo = this.form.controls["Activo"].value
      createPersonaCuentaContableRequest.idCuentaContable = this.cuentaContableDto.id
      createPersonaCuentaContableRequest.idPersona = this.local_data.persona.id
      this.create(createPersonaCuentaContableRequest)
    }
    if (this.action == AccionesDialog.Modificar) {
      let updatePersonaCuentaContableRequest: UpdatePersonaCuentaContableRequest = new UpdatePersonaCuentaContableRequest
      updatePersonaCuentaContableRequest.id = this.personaCuentaContableDto.id
      updatePersonaCuentaContableRequest.descripcion = this.form.controls["Descripcion"].value
      updatePersonaCuentaContableRequest.activo = this.form.controls["Activo"].value
      updatePersonaCuentaContableRequest.idCuentaContable = this.cuentaContableDto.id
      updatePersonaCuentaContableRequest.idPersona = this.personaCuentaContableDto.persona.id
      this.update(updatePersonaCuentaContableRequest)
    }

    this.dialogRef.close({ event: this.action, data: this.local_data });
  }


  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  openDialog(action: any, cuentaContableDto: CuentaContableDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%"
    dialogConfig.data = { action, cuentaContableDto }
    const dialogRef = this.dialog.open(DialogCuentaContableComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar)
        if (!isFalsy(result.data))
          this.cuentaContableBaseDto = result.data
    });
  }

  create(createPersonaCuentaContableRequest: CreatePersonaCuentaContableRequest) {
    this.spinner.show()
    this.personasCuentasContableService.create(createPersonaCuentaContableRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  update(updatePersonaCuentaContableRequest: UpdatePersonaCuentaContableRequest) {
    this.spinner.show()
    this.personasCuentasContableService.update(updatePersonaCuentaContableRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro modificado correctamente.", "Exito");
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  isFalsy(valor:any){
    return isFalsy(valor)
  }
}
