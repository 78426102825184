import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/modules/shared.module";
import { TiposFolioService } from "@services/tipos-folio/tipos-folio.service";
import { BtnNuevoTipoFolioComponent } from "../views/tiposFolio/btn-nuevo-tipo-folio/btn-nuevo-tipo-folio.component";
import { DialogTipoFolioComponent } from "../views/tiposFolio/dialog-tipo-folio/dialog-tipo-folio.component";
import { TiposFolioComponent } from "../views/tiposFolio/tipos-folio/tipos-folio.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    declarations: [
        DialogTipoFolioComponent,
        BtnNuevoTipoFolioComponent,
        TiposFolioComponent
    ],
    exports: [
        DialogTipoFolioComponent,
        BtnNuevoTipoFolioComponent,
        TiposFolioComponent,
    ],
    providers: [
      TiposFolioService
    ],
})

export class TiposFolioModule { }
