import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
// Servicios
import { PersonasService } from '@services/personas/personas.service';
import { EstadosCivilService } from "@services/estados-civil/estados-civil.service";
import { TiposDocumentoService } from "@services/tipos-documento/tipos-documento.service";
import { LocalidadesService } from "@services/localidades/localidades.service";
import { PaisService } from '@services/pais/pais.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { LoginService } from '@services/login/login.service';
// Modelos
import { UpdatePersonaRequest } from '@models/personas/updatePersonaRequest.model';
import { BaseDto } from '@models/base/baseDto.model';
// Ngrx y otros
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/redux/app.reducers';
import { SetearPersona } from 'src/app/redux/app.actions';
import { NgxSpinnerService } from 'ngx-spinner';
import { PuedeDesactivar } from 'src/app/directives/can-deactivate/can-deactivate.guard';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { EstadoCivilDto } from '@models/estado-civil/estadoCivilDto.model';
import { TipoDocumentoDto } from '@models/tipo-documento/tipoDocumentoDto.model';
import { Roles } from '@models/base/identificadores.model';
import { isFalsy } from 'utility-types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDeletePersonaComponent } from '../dialog-delete-persona/dialog-delete-persona.component';

@Component({
  selector: 'app-form-only-persona-update',
  templateUrl: './form-only-persona-update.component.html',
  styleUrls: ['./form-only-persona-update.component.css']
})
export class FormOnlyPersonaUpdateComponent implements OnInit, OnDestroy, PuedeDesactivar {

  personaForm!: FormGroup;
  personaDetailDto: PersonaDetailDto = new PersonaDetailDto();
  lstEstadosCivilModel: EstadoCivilDto[] = [];
  lstTipoDocumentoDto: TipoDocumentoDto[] = [];
  lstPaises: BaseDto[] = [];
  localidad!: BaseDto;
  enviado = false;
  usuarioCliente = false;


  constructor(

    private fb: FormBuilder,
    private personasService: PersonasService,
    private tipoDocumentoService: TiposDocumentoService,
    private estadoCivilService: EstadosCivilService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private aRoute: ActivatedRoute,
    private loginService: LoginService,
    private store: Store<AppState>,
    private paisService: PaisService
  ) {
    this.initForm();
  }

  private initForm() {
    this.personaForm = this.fb.group({
      IdPersona: [],
      NombreCompleto: ['', [Validators.required, Validators.maxLength(50)]],
      ApellidoMaterno: ['', Validators.maxLength(50)],
      NroDocumento: ['', [Validators.required, Validators.maxLength(50)], this.validaPersonaByNroDocYTipoDoc.bind(this)],
      TelefonoPrincial: ['', Validators.maxLength(20)],
      TelefonoAlternativo: ['', Validators.maxLength(20)],
      TelefonoLaboral: ['', Validators.maxLength(20)],
      Sexo: ['', Validators.required],
      Correo: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      FechaNacimiento: [''],
      Ubicacion: ['', Validators.maxLength(50)],
      UbicacionLaboral: ['', Validators.maxLength(50)],
      Provincia: ['', Validators.required],
      CodigoCpa: ['', Validators.maxLength(8)],
      EstadoCivil: ['', Validators.required],
      TiposDocumento: ['', Validators.required],
      Ocupacion: ['', Validators.maxLength(50)],
      Observacion: ['', Validators.maxLength(2000)],
      Pais: ['']
    });

    this.loadInitialData();
    this.personaForm.controls['TiposDocumento'].disable();
    this.personaForm.controls['NroDocumento'].disable();
  }

  private loadInitialData() {
    this.spinner.show('spPersona');
    forkJoin({
      paises: this.paisService.getAll(),
      estadosCivil: this.estadoCivilService.getAll(),
      tiposDocumentos: this.tipoDocumentoService.getAll()
    }).subscribe(
      ({ paises, estadosCivil, tiposDocumentos }) => {
        this.lstPaises = paises;
        this.lstEstadosCivilModel = estadosCivil;
        this.lstTipoDocumentoDto = tiposDocumentos;
        this.spinner.hide('spPersona');
      },
      error => {
        this.snackBar.showError(error, "Error");
        this.spinner.hide('spPersona');
      }
    );
  }

  ngOnInit(): void {
    this.usuarioCliente = this.loginService.getIdUserRol() === Roles.Clientes;
    const usuarioDto = this.loginService.getCurrentUser();
    this.getPersonaById(usuarioDto.persona.id);
  }

  ngOnDestroy(): void { }

  get f() { return this.personaForm.controls; }

  permitirSalidaDeRuta(): boolean | Promise<boolean> {
    if (this.enviado) return true;
    return confirm("¿Quieres salir del formulario y perder los datos guardados?");
  }

  getPersonaById(id: number): void {
    this.spinner.show();
    this.personasService.getPersonaById(id).subscribe(
      data => {
        this.spinner.hide();
        this.personaDetailDto = data;
        this.store.dispatch(new SetearPersona(this.personaDetailDto));
        this.completaFormulario(data);
      },
      error => {
        this.spinner.hide();
        this.snackBar.showError(error, "Error");
      }
    );
  }

  completaFormulario(data: PersonaDetailDto): void {
    this.personaForm.patchValue({
      IdPersona: data.id,
      NombreCompleto: data.nombreCompleto,
      ApellidoMaterno: data.apellidoMaterno,
      TelefonoPrincial: data.telefonoPrincial,
      TelefonoAlternativo: data.telefonoAlternativo,
      TelefonoLaboral: data.telefonoLaboral,
      Sexo: data.sexo,
      Correo: data.correo,
      NroDocumento: data.nroDocumento,
      FechaNacimiento: data.fechaNacimiento ? this.formatDate(data.fechaNacimiento) : '',
      Ubicacion: data.ubicacion,
      UbicacionLaboral: data.ubicacionLaboral,
      CodigoCpa: data.codigoCpa,
      EstadoCivil: data.estadoCivil?.id,
      TiposDocumento: data.tipoDocumento?.id,
      Pais: data.pais?.id,
      Ocupacion: data.ocupacion,
      Observacion: data.observacion
    });
    this.localidad = data.localidad;
  }

  private formatDate(dateStr: string): string {
    const [anio, mes, dia] = dateStr.split('-');
    return `${anio}-${mes}-${dia.substring(0, 2)}`;
  }

  setupdatePersonaRequest(): UpdatePersonaRequest {
    const { value } = this.personaForm;
    const updateRequest = new UpdatePersonaRequest();

    updateRequest.id = value.IdPersona;
    updateRequest.nombreCompleto = value.NombreCompleto;
    updateRequest.apellidoMaterno = value.ApellidoMaterno;
    updateRequest.nroDocumento = this.personaDetailDto.nroDocumento;
    updateRequest.id_TipoDocumento = this.personaDetailDto.tipoDocumento.id;
    updateRequest.id_EstadoCivil = value.EstadoCivil;
    updateRequest.telefonoPrincial = value.TelefonoPrincial;
    updateRequest.telefonoAlternativo = value.TelefonoAlternativo;
    updateRequest.telefonoLaboral = value.TelefonoLaboral;
    updateRequest.sexo = value.Sexo;
    updateRequest.correo = value.Correo;
    updateRequest.fechaNacimiento = value.FechaNacimiento ? this.formatDate(value.FechaNacimiento) : '';
    updateRequest.ubicacion = value.Ubicacion;
    updateRequest.ubicacionLaboral = value.UbicacionLaboral;
    updateRequest.id_Localidad = this.localidad.id;
    updateRequest.codigoCpa = value.CodigoCpa;
    updateRequest.observacion = value.Observacion;
    updateRequest.ocupacion = value.Ocupacion;
    updateRequest.idPais = value.Pais;

    return updateRequest;
  }

  onSubmit(): void {

    if (this.personaForm.invalid || !this.localidad) return;
    const updateRequest = this.setupdatePersonaRequest();
    this.spinner.show("spPersona");
    this.personasService.updatePersona(updateRequest).subscribe(
      () => {
        this.spinner.hide("spPersona");
        this.snackBar.showSuccess("Datos guardados correctamente.", "Exito");
        this.enviado = true;
      },
      error => {
        this.spinner.hide("spPersona");
        this.snackBar.showError(error, "Error");
      }
    );
  }

  validaPersonaByNroDocYTipoDoc(control: FormControl): Promise<any> {
    const idTipoDocumento = this.f['TiposDocumento'].value;
    if (control.value.length > 7 && idTipoDocumento) {
      return new Promise(resolve => {
        this.personasService.validaPersonaByNroDocYTipoDoc(this.personaDetailDto.id, control.value, idTipoDocumento)
          .subscribe(data => resolve(data ? { existePersona: true } : null));
      });
    }
    return Promise.resolve(null);
  }

  seleccionaLocalidad(data: BaseDto) {
    this.localidad = data
    this.personaForm.controls['Provincia'].setValue(this.localidad);
  }

  onReset(): void {
    this.personaForm.reset();
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }
  cancel() {

  }


}
