<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
<h1 mat-dialog-title>Agregar Caracteristica</h1>

<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <form [formGroup]="formInstance">
        <mat-form-field>
            <ngx-spinner [zIndex]=100 name="spBusqueda" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
            <mat-label>Caracteristica</mat-label>
            <mat-select formControlName="Caracteristica">
                <mat-option cdkFocusInitial [value]="caracteristica" *ngFor="let caracteristica of lstCaracteristicasPropiedad ;let i=index;trackBy:trackByItems">
                    {{caracteristica.descripcion}}</mat-option>
            </mat-select>
            <mat-hint>Lista desplegable</mat-hint>
            <mat-error *ngIf="formInstance.controls['Caracteristica']?.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field>
            <mat-label>Cantidad *</mat-label>
            <input matInput type="number" name="Cantidad" formControlName="Cantidad" />
            <mat-error *ngIf="formInstance.controls['Cantidad']?.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
        </mat-form-field>
    </form>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" *appRole="['Propiedades.Crear']" [disabled]="!formInstance.valid" (click)="agregar()">Guardar</button>
    <button mat-button mat-dialog-close>Cancelar</button>
</div>
