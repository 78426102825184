<div class="row content" *appRole="['Usuarios.Crear']">
  <div class="col-lg-2">
    <app-nav-panel-usuario></app-nav-panel-usuario>
  </div>

  <div class="col-lg-10">
    <mat-expansion-panel expanded=true>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Datos Usuario
        </mat-panel-title>
        <mat-icon style="margin-right: 10px;">account_circle</mat-icon>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom"
        [fullScreen]="false"></ngx-spinner>
      <form [formGroup]="usuarioForm">
        <div class="row">
          <div class="col-3">
            <app-buscar-persona [idPersonaInput]="idPersona" (eventEntity)=selectPersona($event)>
            </app-buscar-persona>
          </div>
          <div class="col-3">
            <mat-form-field>
              <input type="hidden" formControlName="IdPersona" value="{{idPersona}}" />
              <mat-label>Nombre de usuario</mat-label>
              <input matInput type="text" maxlength="50" formControlName="NombreUsuario">
              <mat-error *ngIf="f.IdPersona.errors?.required">El nombre es
                <strong>requerido</strong>
              </mat-error>
              <mat-error *ngIf="f.IdPersona.errors?.maxlength">Supero el maximo de caracteres.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field>
              <mat-label>Nombre Completo</mat-label>
              <input matInput type="text" formControlName="NombreCompleto" [disabled]="true">
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field>
              <mat-label>Nro. Documento</mat-label>
              <input matInput type="text" formControlName="DNI" [disabled]="true">
            </mat-form-field>
          </div>



        </div>
        <div class="row">
          <div class="col-3">
            <span>Seleccione Rol a aplicar al usuario:</span>
          </div>
          <div class="col-3">
            <mat-form-field>
              <mat-label>Rol</mat-label>
              <ngx-spinner name="spRol" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale"
                [fullScreen]="false"></ngx-spinner>
              <input type="hidden" formControlName="IdRol" value="{{idRol}}" />
              <mat-select required #Sele ctRol placeholder="Seleccione opcion" formControlName="Rol"
                (selectionChange)="selectRol($event.value)">
                <mat-option [value]="rol.id" *ngFor="let rol of roles ;let i=index;trackBy:trackByItems">
                  {{rol.descripcion}}</mat-option>
              </mat-select>
              <mat-error *ngIf="f.Rol.errors?.required">Este campo es <strong>requerido</strong>.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-3" [hidden]="isEdition">
            <mat-form-field>
              <mat-label>Clave nueva</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" maxlength="150" id="ClaveNueva"
                formControlName="ClaveNueva">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="f.ClaveNueva.errors?.required">Ingrese <strong>clave
                  nueva.</strong>
              </mat-error>
              <mat-error *ngIf="f.ClaveNueva.errors?.minlength">Debe superar los 10 caracteres.
              </mat-error>
              <mat-error *ngIf="f.ClaveNueva.errors?.maxlength">Supero el maximo de caracteres.
              </mat-error>
              <button matSuffix mat-icon-button color="primary" (click)="generarClaveAleatoria()"
                aria-label="Example icon button with a home icon" #tooltip="matTooltip"
                matTooltip="Genera clave aleatoria.">
                <mat-icon>autorenew</mat-icon>
              </button>
            </mat-form-field>

          </div>

          <div class="col-3" [hidden]="isEdition">
            <mat-form-field>
              <mat-label>Repetir Clave</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" maxlength="150" formControlName="ClaveRepetida">
              <!-- <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button> -->
              <mat-error *ngIf="f.ClaveRepetida.errors?.required">Debe repetir la clave.</mat-error>
              <mat-error *ngIf="f.ClaveRepetida.errors?.minlength">Debe superar los 10 caracteres.</mat-error>
              <mat-error *ngIf="f.ClaveRepetida.errors?.maxlength">Supero el máximo de caracteres.</mat-error>
              <mat-error *ngIf="f.ClaveRepetida.errors?.passwordMismatch">Las claves no coinciden.</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-auto my-1">
          <button mat-raised-button *appRole="['Usuarios.Crear']" color="primary" type="submit" name="Guardar"
            [disabled]="!usuarioForm.valid" (click)="onSubmit()" style="margin-right:3px;" value="Guardar">
            Guardar</button>
          <button mat-button color="warn" name="Guardar" (click)="onCancel();$event.preventDefault()"
            style="margin-right:3px;" value="Guardar">Cancelar</button>
        </div>

      </form>
    </mat-expansion-panel>

  </div>

</div>
