
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PuedeDesactivar } from 'src/app/directives/can-deactivate/can-deactivate.guard';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, EstadosContrato, EstadosPropiedad, PropiedaClase, TiposMontos, TiposPunitorio, TiposContrato, TiposSellado, TiposMonedas, TiposRecibosInquilinos, TiposRecibosPropietarios, FormasPagos, TiposAjusteContrato } from '@models/base/identificadores.model';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { ContratoDto } from '@models/contrato/contratoDto.model';
import { ContratoGarantiaDto } from '@models/contrato/contratoGarantiaDto.model';
import { ContratoPropietarioDto } from '@models/contrato/contratoPropietarioDto.model';
import { CreateContratoRequest } from '@models/contrato/createContratoRequest.model';
import { PeriodoContratoSummaryDto } from '@models/periodos-contrato/periodoContratoSummaryDto.model';
import { GarantiaSummaryDto } from '@models/garantia/garantiaSummaryDto.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { RenterDeliveredNecessaryGuaranteesResponse } from '@models/inquilino/renterDeliveredNecessaryGuaranteesResponse.model';
import { PropiedadDto } from '@models/propiedades/propiedadDto.model';
import { PropietarioDto } from '@models/propietarios/propietarioDto.model';
import { TipoMonedaDto } from '@models/tipo-moneda/tipoMonedaDto.model';
import { TipoPunitorioDto } from '@models/tipo-punitorio/tipoPunitorioDto.model';
import { TipoSelladoDto } from '@models/tipo-sellado/tipoSelladoDto.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { FormasPagoService } from '@services/formas-pago/formas-pago.service';
import { GarantiasService } from '@services/garantias/garantias.service';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { PersonasCuentasContableService } from '@services/personas-cuentas-contable/personas-cuentas-contable.service';
import { PersonasService } from '@services/personas/personas.service';
import { PropiedadesService } from '@services/propiedades/propiedades.service';
import { PropietariosService } from '@services/propietarios/propietarios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposContratoService } from '@services/tipos-contrato/tipos-contrato.service';
import { TiposMonedaService } from '@services/tipos-moneda/tipos-moneda.service';
import { TiposMontoService } from '@services/tipos-monto/tipos-monto.service';
import { TiposPunitorioService } from '@services/tipos-punitorio/tipos-punitorio.service';
import { TiposSelladoService } from '@services/tipos-sellado/tipos-sellado.service';
import { isFalsy } from 'utility-types';
import { DialogNuevoPeriodoComponent } from './dialog-nuevo-periodo/dialog-nuevo-periodo.component';
import { TipoLiquidacionDto } from '@models/tipo-liquidacion/tipoLiquidacionDto.model';
import { duracionContratoExcedePeriodos, planDePagoValidator, validarFechaComienzoContrato, validarFechasContrato } from '@helpers/validations/contrato.validator';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { TipoAjusteContratoService } from '@services/tipo-ajuste-contrato/tipoAjusteContrato.service';
import { TipoAjusteContratoDto } from '@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model';

export interface DialogData {
  ultimoPeriodo: 0;
  accion: string
  cantidadPeriodos: number
  tipoContrato: number
  ultimoMonto: number
}

export class Periodo {
  id: number = 0;
  periodoDesde: number = 0;
  periodoHasta: number = 0;
  montoAlquiler: number = 0;
  descuento: number = 0;
  incremento: number = 0;
  comision: number = 0;
  sellado: number = 0;
  subTotal: number = 0;
  valorAjuste: number = 0;
  idIndiceAjusteContrato: number | null = null;

}
@Component({
  selector: 'app-form-nuevo-contrato',
  templateUrl: './form-nuevo-contrato.component.html',
  styleUrls: ['./form-nuevo-contrato.component.css'],
})
export class FormNuevoContratoComponent implements OnInit, PuedeDesactivar {

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;
  lstTiposAjusteContrato!: TipoAjusteContratoDto[];
  tipoAjusteContrato!: TipoAjusteContratoDto;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  data = false

  //#region  Varaibles
  //Listas
  lstTiposSellado: TipoSelladoDto[] = []
  lstTiposMonto: BaseDto[] = []
  lstTiposMoneda: TipoMonedaDto[] = []
  lstTiposPunitorio: TipoPunitorioDto[] = []
  lstTipoContrato: BaseDto[] = []
  idEstadoPropiedad = EstadosPropiedad.SinContrato
  datosPropiedadFormGroup!: FormGroup;
  datosInquilinoFormGroup!: FormGroup;
  datosContratoFormGroup!: FormGroup;
  montosContratoFormGroup!: FormGroup;
  validatorFormGroup!: FormGroup;
  detalleMontosContratorFormGroup!: FormGroup
  isEditable = false;
  //Propiedad
  propiedad!: PropiedadDto | null
  inquilinoDto: InquilinoDto = new InquilinoDto;
  personaInquilino: PersonaDetailDto = new PersonaDetailDto;
  persona: PersonaDetailDto = new PersonaDetailDto
  propietariosPropiedad: PropietarioDto[] | null = []
  garantiasInquilino: GarantiaSummaryDto[] = []
  tieneGarantias: boolean = false;
  lstFormasPago!: BaseDto[];
  lstTiposLiquidacion!: BaseDto[];
  cuentaContablePropietarioVerificada: boolean = true;
  showAlertCuentaContable: boolean = true;

  //Tabla Periodo
  //periodos: Periodo[] = []
  dataSource = new MatTableDataSource<Periodo>();
  displayedColumns: string[] = []
  //Contrato
  //contrato: Contrato = new Contrato

  //Totales
  totalMontoAlquiler: number = 0
  totalComision: number = 0
  totalDescuento: number = 0
  totalIncrementos: number = 0
  totalSellado: number = 0
  total: number = 0
  contratoOk!: boolean;
  ultimoPeriodo: any;
  idTipoMontoSellado: any;
  tipoMontoPunitorio: any;
  tipoMontoSellado: any;
  tipoMontoAdministrativo: any;
  cantMesesContrato: any;
  minDate!: moment.Moment;
  duracionMesesContrato: any;
  tipoContrato: any;
  ultimoMonto: any;
  lstPeriodos: Periodo[] = [];
  isLoading: boolean = false;
  sinPropietario: boolean = false;
  isShowResumen: boolean = false;
  contratoResumen: ContratoDetailDto = new ContratoDetailDto;
  selectedTiposLiquidacion!: TipoLiquidacionDto


  //Garantias
  renterDeliveredNecessaryGuaranteesResponse!: RenterDeliveredNecessaryGuaranteesResponse;
  garantiasCorrectas: boolean = true;




  get datProp() { return this.datosPropiedadFormGroup.controls; }
  get datInq() { return this.datosInquilinoFormGroup.controls; }
  get datCon() { return this.datosContratoFormGroup.controls; }
  get datMon() { return this.montosContratoFormGroup.controls; }
  get datDetMon() { return this.detalleMontosContratorFormGroup.controls; }
  //#endregion
  stepperOrientation: Observable<StepperOrientation>;


  constructor(
    private formBuilder: FormBuilder,
    private personasService: PersonasService,
    private propiedadService: PropiedadesService,
    private garantiasService: GarantiasService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private router: Router,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private tiposSelladoService: TiposSelladoService,
    private tiposMontosService: TiposMontoService,
    private tiposPunitoriosService: TiposPunitorioService,
    private tiposMonedaService: TiposMonedaService,
    private tiposContratoService: TiposContratoService,
    private contratoServices: ContratosService,
    private propietarioServices: PropietariosService,
    private formasPagosService: FormasPagoService,
    private inquilinosService: InquilinosService,
    private toast: HotToastService,
    private tipoAjusteContratoService: TipoAjusteContratoService,
    private personasCuentasContableService: PersonasCuentasContableService
  ) {

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["periodo", "montoAlquiler", "subtotal", "accion"] :
        ["periodo", "montoAlquiler", "incremento", "descuento", "comision", "subtotal", "accion"];
    });

    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.datosPropiedadFormGroup = this.formBuilder.group({
      PropiedadForm: ['', Validators.compose([Validators.required])],
      TiposLiquidacion: ['', Validators.compose([Validators.required])],
      ComisionPropietario: ['', Validators.compose([Validators.required])],
      FormaPagoPropietario: ['', Validators.compose([Validators.required])],
    });

    this.datosInquilinoFormGroup = this.formBuilder.group({
      garantias: [''],
      NombreInquilino: ['', Validators.compose([Validators.required])],
    });
    this.datosContratoFormGroup = this.formBuilder.group({
      TipoContrato: ['', Validators.compose([Validators.required])],
      FechaComienzo: ['', Validators.compose([Validators.required])],
      FechaFinalizacion: ['', Validators.compose([Validators.required])],
      DiaPago: ['', Validators.compose([Validators.required])],
      DuracionContrato: ['', Validators.compose([Validators.required])],
      FrecuenciaAjusteMensual: ['', Validators.compose([Validators.required])],
      TiposAjusteContrato: ['', Validators.compose([Validators.required])],
    }, {
      //
      validator: [validarFechaComienzoContrato('FechaComienzo'), validarFechasContrato('FechaComienzo', 'FechaFinalizacion')]
    });

    this.montosContratoFormGroup = this.formBuilder.group({
      MontoHonorario: ['', Validators.required],
      MontoPunitorio: ['', Validators.required],
      MontoSellado: ['', Validators.required],
      TipoMontoSellado: ['', Validators.required],
      TipoMontoPunitorio: ['', Validators.required],
      TipoMontoHonorario: ['', Validators.required],
      TipoPunitorio: ['', Validators.required],
      TipoSellado: ['', Validators.required],
      PlanPago: [''],
    },
    );



    this.detalleMontosContratorFormGroup = this.formBuilder.group({
    });

    this.validatorFormGroup = formBuilder.group({
      AcceptTerms: ['', Validators.requiredTrue],
    });
  }

  permitirSalidaDeRuta(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.contratoOk == true)
      return true

    const confirmacion = window.confirm("¿Quieres salir del formulario y perder los cambios realizados?")
    return confirmacion
  };

  ngOnInit() {
    this.getAllTiposPunitorios();
    this.getAllTiposMontos();
    this.getAllTiposSellados();
    this.getAllTiposMoneda();
    this.getAllTiposContratos();
    this.getAllTiposLiquidacion();
    this.getAllTiposFormasPago();
    this.getAllTipoAjusteContrato();
    //const fechaComienzo = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    //this.datosContratoFormGroup.controls["FechaComienzo"].setValue(fechaComienzo.format('YYYY-MM-DD'))
    //this.setDefault()
  }


  setDefault() {
    const fechaComienzo = moment(new Date());
    this.datosContratoFormGroup.controls["FechaComienzo"].setValue(fechaComienzo.format('YYYY-MM-DD'))
    this.montosContratoFormGroup.controls["TipoMontoSellado"].setValue(TiposMontos.PorcentajeAlquiler)
    this.montosContratoFormGroup.controls["TipoMontoHonorario"].setValue(TiposMontos.PorcentajeAlquiler)
    this.montosContratoFormGroup.controls["TipoMontoPunitorio"].setValue(TiposMontos.PorcentajeAlquiler)
    this.montosContratoFormGroup.controls["TipoPunitorio"].setValue(TiposPunitorio.MedioAlto)
    this.datosContratoFormGroup.controls["TipoContrato"].setValue(TiposContrato.ConAjuste)
    //Valores test
    this.montosContratoFormGroup.controls["MontoSellado"].setValue(10)
    this.montosContratoFormGroup.controls["MontoHonorario"].setValue(10)
    this.datosContratoFormGroup.controls["DiaPago"].setValue(10)
    this.montosContratoFormGroup.controls["PlanPago"].setValue(2)
    this.montosContratoFormGroup.controls["FrecuenciaAjusteMensual"].setValue("12")
    this.datosPropiedadFormGroup.controls["TiposLiquidacion"].setValue(1)
    this.datosPropiedadFormGroup.controls["TiposAjusteContrato"].setValue(1)
    this.datosPropiedadFormGroup.controls["FormaPagoPropietario"].setValue(1)

  }



  //#region Metodos Auxiliares

  getAllTiposFormasPago() {
    this.spinner.show("spFormaDePago");
    this.formasPagosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spFormaDePago");
          this.lstFormasPago = data
        },
        error => {
          this.spinner.hide("spFormaDePago");
          this.snackBar.showError(error, "Error");
        });
  }

  getAllTiposMoneda() {
    this.spinner.show("spMontosContrato");
    this.tiposMonedaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spMontosContrato");
          this.lstTiposMoneda = data
        },
        error => {
          this.spinner.hide("spMontosContrato");
          this.snackBar.showError(error, "Error");
        });
  }

  getAllTiposContratos() {
    this.spinner.show("spTipoContrato");
    this.tiposContratoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTipoContrato");
          this.lstTipoContrato = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
        },
        error => {
          this.spinner.hide("spTipoContrato");
          this.snackBar.showError(error, "Error");
        });
  }


  getAllTiposMontos() {
    this.spinner.show("spMontosContrato");
    this.tiposMontosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spMontosContrato");
          this.lstTiposMonto = data
        },
        error => {
          this.spinner.hide("spMontosContrato");
          this.snackBar.showError(error, "Error");
        });
  }

  getAllTiposSellados() {
    this.spinner.show("spMontosContrato");
    this.tiposSelladoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spMontosContrato");
          this.lstTiposSellado = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide("spMontosContrato");
          this.snackBar.showError(error, "Error");
        });
  }

  getAllTiposPunitorios() {
    this.spinner.show("spMontosContrato");
    this.tiposPunitoriosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spMontosContrato");
          this.lstTiposPunitorio = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide("spMontosContrato");
          this.snackBar.showError(error, "Error");
        });
  }


  getAllTipoAjusteContrato() {
    this.spinner.show("spBusquedaTipoAjusteContrato")
    this.tipoAjusteContratoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTipoAjusteContrato")
          this.lstTiposAjusteContrato = data
        },
        error => {
          this.spinner.hide("spBusquedaTipoAjusteContrato")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getPersonaById(id: any) {
    this.spinner.show();
    this.personasService.getPersonaById(id)
      .subscribe(
        data => {
          this.spinner.hide();
          this.persona = data
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        });
  }

  getPersonaInquilinoById(id: any) {
    this.spinner.show("spInquilino");
    this.personasService.getPersonaById(id)
      .subscribe(
        data => {
          this.spinner.hide("spInquilino");
          this.personaInquilino = data
          this.datosInquilinoFormGroup.controls["NombreInquilino"].setValue("Formulario valido")
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spInquilino");
        });
  }

  getAllTiposLiquidacion() {
    this.spinner.show("spTiposLiquidacion");
    this.propietarioServices.getAllTiposLiquidacion()
      .subscribe(
        data => {
          this.spinner.hide("spTiposLiquidacion");
          this.lstTiposLiquidacion = data
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spTiposLiquidacion");
        });
  }

  async getAllPropietariosPropiedad(idPropiedad: number) {
    this.spinner.show("spDatosPropietarios");
    this.propiedadService.getAllPropietariosPropiedad(idPropiedad)
      .subscribe(
        data => {
          this.spinner.hide("spDatosPropietarios");
          this.propietariosPropiedad = data.filter(element => { return element.activo == true })

          if (this.propietariosPropiedad.length == 0) {
            this.sinPropietario = true
          }
          else {
            data.forEach(element => {
              if (element.titular == true) {
                this.datosPropiedadFormGroup.controls["ComisionPropietario"].setValue(element.comision)
                this.selectedTiposLiquidacion = isFalsy(element.tipoLiquidacion) ? new BaseDto : element.tipoLiquidacion
              }
            });
          }
          this.datosPropiedadFormGroup.controls["PropiedadForm"].setValue("Propiedad encontrada")
        },
        error => {
          this.propietariosPropiedad = []
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spDatosPropietarios");
        });
  }

  verficiaCuentaContablePropietario(IdFormaPago: number) {
    if (IdFormaPago == FormasPagos.DepositoBanco ||
      IdFormaPago == FormasPagos.Trasferencia) {
      if (this.cuentaContablePropietarioVerificada == false)
        this.showAlertCuentaContable = false
      else
        this.showAlertCuentaContable = true
    }
    else {
      this.showAlertCuentaContable = true
    }
  }

  verificaCuentaContablePropietario(idPersona: number) {
    this.spinner.show("spFormaDePago");
    this.personasCuentasContableService.getAllByIdPersona(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spFormaDePago");
          this.cuentaContablePropietarioVerificada = data.filter(x => x.activo == true).length > 0 ? true : false
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spFormaDePago");
        });
  }

  getPropiedadById(idPropiedad: number) {
    this.spinner.show("spDatosPropiedad");
    this.propiedadService.getById(idPropiedad)
      .subscribe(
        async data => {
          this.spinner.hide("spDatosPropiedad");
          this.propiedad = data as PropiedadDto
          await this.getAllPropietariosPropiedad(this.propiedad.id)
          this.predeterminaTipoSellado()
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spDatosPropiedad");
        });
  }

  getAllGarantiasByIdInquilino(idPersonaInquilino: number) {
    this.spinner.show("spGarantia");
    this.garantiasService.getAllGarantiasByInquilino(idPersonaInquilino)
      .subscribe(
        data => {
          this.spinner.hide("spGarantia");
          this.garantiasInquilino = data.filter(element => { return element.activo == true })
        },
        error => {
          this.spinner.hide("spGarantia");
          this.garantiasInquilino = []
          this.snackBar.showError(error, "Error");
        });
  }

  //#endregion

  //#region  Metodos Formulario

  actualizaFechas() {
    const duracionContrato = this.datosContratoFormGroup.controls["DuracionContrato"].value
    this.calculaDuracionContrato(duracionContrato)
  }

  editarPropiedad(idPropiedad: number) {
    this.router.navigate(["/propiedades/", idPropiedad])
  }

  checkGarantias(event: any) {
    this.tieneGarantias = event
    if (this.tieneGarantias == true) {
      this.getAllGarantiasByIdInquilino(this.inquilinoDto.persona.id)
      this.inquilinosService.sendIdPersona(this.inquilinoDto.persona.id)
      this.inquilinosService.sendInquilinoDto(this.inquilinoDto)
      this.renterDeliveredNecessaryGuarantees(this.inquilinoDto.id)
    }
    else
      this.garantiasCorrectas = true

    this.predeterminaTipoSellado()
  }

  predeterminaTipoSellado() {
    if (!isFalsy(this.propiedad)) {
      if (this.propiedad.propiedadClase.id == PropiedaClase.CasaVivienda && this.tieneGarantias == true) {
        const sellado = this.lstTiposSellado.find(x => x.id == TiposSellado.CasaViviendaCGarantia)
        this.montosContratoFormGroup.controls["TipoSellado"].setValue(sellado)
        this.montosContratoFormGroup.controls["MontoSellado"].setValue(sellado?.valor)
      }

      if (this.propiedad.propiedadClase.id == PropiedaClase.CasaVivienda && this.tieneGarantias == false) {
        const sellado = this.lstTiposSellado.find(x => x.id == TiposSellado.CasaViviendaSGarantia)
        this.montosContratoFormGroup.controls["TipoSellado"].setValue(sellado)
        this.montosContratoFormGroup.controls["MontoSellado"].setValue(sellado?.valor)
      }

      if (this.propiedad.propiedadClase.id == PropiedaClase.LocalComercial && this.tieneGarantias == true) {
        const sellado = this.lstTiposSellado.find(x => x.id == TiposSellado.ComercialCGarantia)
        this.montosContratoFormGroup.controls["TipoSellado"].setValue(sellado)
        this.montosContratoFormGroup.controls["MontoSellado"].setValue(sellado?.valor)
      }
      if (this.propiedad.propiedadClase.id == PropiedaClase.LocalComercial && this.tieneGarantias == false) {
        const sellado = this.lstTiposSellado.find(x => x.id == TiposSellado.ComercialSGarantia)
        this.montosContratoFormGroup.controls["TipoSellado"].setValue(sellado)
        this.montosContratoFormGroup.controls["MontoSellado"].setValue(sellado?.valor)
      }
    }

  }

  selectInquilino(event: InquilinoDto) {
    this.tieneGarantias = false
    this.personaInquilino = new PersonaDetailDto
    this.garantiasInquilino = []
    this.datosInquilinoFormGroup.controls["garantias"].setValue(false)
    this.inquilinoDto = event;
    this.getPersonaInquilinoById(this.inquilinoDto.persona.id)
  }

  selectTipoMontoHonorarios(event: any) {
    this.tipoMontoAdministrativo = event.value
  }

  selectTipoMontoPunitorios(event: any) {
    this.tipoMontoPunitorio = event.value
  }

  selectTipoMontoSellado(event: any) {
    this.tipoMontoSellado = event.value
  }

  selectTipoPunitorios(event: any) {
    let tipoPunitorio = event.value as TipoPunitorioDto
    this.montosContratoFormGroup.controls["MontoPunitorio"].disable()
    if (tipoPunitorio.id == TiposPunitorio.MontoFijo) {
      this.montosContratoFormGroup.controls["MontoPunitorio"].setValue(0)
      this.montosContratoFormGroup.controls["MontoPunitorio"].enable()
    }
    else {
      this.montosContratoFormGroup.controls["MontoPunitorio"].setValue(tipoPunitorio.valor)
      this.montosContratoFormGroup.controls["MontoPunitorio"].disable()
    }
  }

  selectTipoSellado(event: any) {
    let tipoSellado = event.value
    this.montosContratoFormGroup.controls["MontoSellado"].setValue(tipoSellado.valor)
  }

  selectTipoContrato(event: any) {
    this.tipoContrato = event.value
    if (this.tipoContrato.id == TiposContrato.ConAjuste) {
      this.datosContratoFormGroup.controls["FrecuenciaAjusteMensual"].enable()
      this.datosContratoFormGroup.controls["TiposAjusteContrato"].enable()
    }
    else {
      var tipoAjusteContrato = new TipoAjusteContratoDto;
      tipoAjusteContrato.acronimo = "SIN"
      tipoAjusteContrato.descripcion = "Sin Ajuste"
      tipoAjusteContrato.id = TiposAjusteContrato.SIN
      this.datosContratoFormGroup.controls["TiposAjusteContrato"].setValue(tipoAjusteContrato)
      this.datosContratoFormGroup.controls["TiposAjusteContrato"].disable()
      this.datosContratoFormGroup.controls["FrecuenciaAjusteMensual"].setValue(0)
      this.datosContratoFormGroup.controls["FrecuenciaAjusteMensual"].disable()
    }
  }

  selectTipoAjusteContrato(event: any) {
    this.tipoAjusteContrato = event.value
  }

  selectDuracionContrato(event: any) {
    const duracionContrato = this.datosContratoFormGroup.controls["DuracionContrato"].value
    if (!isFalsy(duracionContrato))
      this.calculaDuracionContrato(duracionContrato)

    this.datosContratoFormGroup.controls["DuracionContrato"].addValidators(duracionContratoExcedePeriodos(this.obtenerUltimoPeriodo() - 1))
    this.datosContratoFormGroup.controls["DuracionContrato"].updateValueAndValidity()
  }

  selectPropiedad(event: BaseDto) {
    this.datosPropiedadFormGroup.controls["PropiedadForm"].valid
    if (!isFalsy(event))
      this.getPropiedadById(event.id)
    else {
      this.propiedad = null
      this.propietariosPropiedad = null
    }
  }

  //#endregion

  calculaDuracionContrato(meses: any) {

    const duracionContrato = this.datosContratoFormGroup.controls["DuracionContrato"].value;
    if (!isFalsy(meses)) {
      // Asegúrate de que 'meses' sea un número
      const duracionMeses = parseInt(meses, 10);
      if (isNaN(duracionMeses)) {
        this.snackBar.showWarn("La duración en meses no es válida:'," + meses + "", "Error");
        return;
      }

      this.duracionMesesContrato = duracionMeses;
      this.cantMesesContrato = duracionMeses;

      const fechaComienzo = this.datosContratoFormGroup.controls["FechaComienzo"].value;
      if (!fechaComienzo) {
        this.snackBar.showWarn("La fecha de comienzo no está establecida.", "Error");
        return;
      }

      const fechaFinalizacion = moment(fechaComienzo).add(duracionMeses, 'months').subtract(1, 'days');

      //const fechaFinalizacion = moment(fechaComienzo).add(duracionMeses, 'months');

      // Establece la fecha de finalización en el formato correcto
      this.datosContratoFormGroup.controls["FechaFinalizacion"].setValue(fechaFinalizacion.format('YYYY-MM-DD'));
    }
  }


  setDuracionContrato() {
    const fechaComienzo = this.datosContratoFormGroup.controls["FechaComienzo"].value
    const fechaFinalizacion = this.datosContratoFormGroup.controls["FechaFinalizacion"].value
    const diferenciaFecha = moment(new Date(fechaFinalizacion)).diff(fechaComienzo, 'M');
    this.datosContratoFormGroup.controls["DuracionContrato"].setValue(diferenciaFecha + 1)
    this.duracionMesesContrato = diferenciaFecha + 1
  }

  setPlanDePago() {
    const duracionContrato = this.datosContratoFormGroup.controls["DuracionContrato"].value
    const planPago = this.montosContratoFormGroup.controls["PlanPago"].value
    this.montosContratoFormGroup.controls["PlanPago"].addValidators(planDePagoValidator(duracionContrato))
    this.montosContratoFormGroup.controls["PlanPago"].updateValueAndValidity()
  }

  obtenerUltimoPeriodo(): number {
    let ultimoPeriodo: number = 0
    this.lstPeriodos.forEach(periodo => {
      if (periodo.periodoHasta > ultimoPeriodo)
        ultimoPeriodo = periodo.periodoHasta
    })
    ultimoPeriodo++
    return ultimoPeriodo
  }

  obtenerUltimoMonto(): number {
    let ultimoMonto: number = 0
    let ultimoPeriodo: number = 0
    this.lstPeriodos.forEach(periodo => {
      if (periodo.periodoHasta > ultimoPeriodo) {
        ultimoPeriodo = periodo.periodoHasta
        ultimoMonto = periodo.montoAlquiler
      }
    })
    return ultimoMonto
  }

  openDialog() {
    let ultimoPeriodo = this.obtenerUltimoPeriodo()
    let ultimoMonto = this.obtenerUltimoMonto()
    const fechaComienzo = this.datosContratoFormGroup.controls["FechaComienzo"].value
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    dialogConfig.data = {
      event: AccionesDialog.Agregar,
      ultimoPeriodo: ultimoPeriodo,
      cantidadPeriodos: this.duracionMesesContrato != null ? parseInt(this.duracionMesesContrato) : 0,
      tipoContrato: this.tipoContrato.id,
      ultimoMonto: ultimoMonto,
      fechaComienzo: fechaComienzo,
      idTipoAjusteContrato: this.tipoAjusteContrato?.id
    }

    const dialogRef = this.dialog.open(DialogNuevoPeriodoComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.agregarPeriodo(result.data);
      }
    });
  }

  agregarPeriodo(periodo: Periodo) {
    let cantidadPeriodos = periodo.periodoHasta - periodo.periodoDesde
    if (periodo.periodoDesde < periodo.periodoHasta && cantidadPeriodos < this.cantMesesContrato) {
      this.lstPeriodos.push(periodo);
      this.dataSource.data = this.lstPeriodos
      this.dataSource = new MatTableDataSource<Periodo>(this.dataSource.data)
      this.calcularTotales()
    }
    else {
      this.snackBar.showWarn("Ingrese periodos validos.", "Error");
      this.openDialog()
    }

    this.data = this.dataSource.data.length >= 1 ? true : false
  }

  calcularTotales() {
    this.totalMontoAlquiler = 0
    this.totalDescuento = 0
    this.totalIncrementos = 0
    this.totalComision = 0
    this.totalSellado = 0
    this.total = 0



    let tipoMontoSellado = this.montosContratoFormGroup.controls["TipoMontoSellado"].value
    let tipoSellado = this.montosContratoFormGroup.controls["TipoSellado"].value
    let tipoMontoHonorarios = this.montosContratoFormGroup.controls["TipoMontoHonorario"].value
    let montoSellado = isFalsy(this.montosContratoFormGroup.controls["MontoSellado"].value) ? 0 : (Math.round(this.montosContratoFormGroup.controls["MontoSellado"].value * 100) / 100)

    let montoHonorarios = isFalsy(this.montosContratoFormGroup.controls["MontoHonorario"].value) ? 0 : (Math.round(this.montosContratoFormGroup.controls["MontoHonorario"].value * 100) / 100)


    this.lstPeriodos.forEach(
      periodo => {
        let periodoCant = periodo.periodoHasta - periodo.periodoDesde + 1
        this.totalMontoAlquiler += (periodo.montoAlquiler + periodo.incremento - periodo.descuento) * periodoCant
        this.totalDescuento += periodo.descuento * periodoCant
        this.totalIncrementos += periodo.incremento * periodoCant
      }
    )

    //Calculo Honorarios
    if (tipoMontoHonorarios.id == TiposMontos.PorcentajeAlquiler) {
      //Porcentaje
      this.totalComision = ((this.totalMontoAlquiler * montoHonorarios) / 100)
    }
    else {
      //Valor Fijo
      this.totalComision = (Math.round(montoHonorarios * 100) / 100)
    }

    //Calculo Sellado
    if (tipoMontoSellado.id == TiposMontos.PorcentajeAlquiler) {
      //Porcentaje
      this.totalSellado = ((this.totalMontoAlquiler * montoSellado) / 100)
    }
    else {
      //Valor Fijo
      this.totalSellado = (Math.round(montoSellado * 100) / 100)
    }

    this.total = (Math.round(this.totalMontoAlquiler * 100) / 100) + (Math.round(this.totalComision * 100) / 100) + (Math.round(this.totalSellado * 100) / 100)
  }

  actualizarPeriodo(periodo: Periodo) { }

  eliminarPeriodo(periodo: Periodo) {

    let existePeridoAnterior = false
    let periodoDesde = periodo.periodoDesde
    this.lstPeriodos.forEach(element => {
      if (periodoDesde < element.periodoDesde)
        existePeridoAnterior = true
    })

    if (existePeridoAnterior == false) {
      this.lstPeriodos = this.lstPeriodos.filter(value => {
        return value != periodo;
      });

      this.dataSource.data = this.lstPeriodos
      this.dataSource = new MatTableDataSource<Periodo>(this.dataSource.data)
      this.calcularTotales()
    }
    else {
      this.snackBar.showWarn("Borre los periodos de manera ordenada.", "Error");
    }

    this.data = this.dataSource.data.length >= 1 ? true : false

  }

  createContrato() {

    let createContratoRequest = this.setCreateContratoRequest()
    this.contratoOk = true

    if (
      !this.datosPropiedadFormGroup.valid ||
      !this.datosInquilinoFormGroup.valid ||
      !this.datosContratoFormGroup.valid ||
      !this.montosContratoFormGroup.valid ||
      !this.validatorFormGroup.valid
    )
      return

    if (
      createContratoRequest.periodosContrato.length == 0 ||
      createContratoRequest.propietariosContrato.length == 0

    ) {
      this.snackBar.showWarn("Cargar periodos nuevamente.", "");
      return
    }


    this.create(createContratoRequest)

  }


  create(createContratoRequest: CreateContratoRequest) {
    this.isLoading = true
    this.contratoServices.create(createContratoRequest)
      .subscribe(
        data => {
          this.snackBar.showSuccess("Contrato creado correctamente.", "Exito");
          this.router.navigate(["/contratos/", data.id])
          this.isLoading = false
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.isLoading = false
          throw error;
        }

      )
  }

  //Validar Garantias Inquilino
  renterDeliveredNecessaryGuarantees(idInquilino: number) {
    this.spinner.show("spGarantia")
    this.inquilinosService.renterDeliveredNecessaryGuarantees(idInquilino)
      .subscribe(
        data => {
          this.spinner.hide("spGarantia")
          this.renterDeliveredNecessaryGuaranteesResponse = data
          this.validaGarantias()
        },
        error => {
          this.spinner.hide("spGarantia")
          this.snackBar.showInfo("No existen validaciones de grupos de garantias para este inquilino.", "Info");
        }
      )
  }

  validaGarantias() {
    if (this.tieneGarantias == true)
      if (this.renterDeliveredNecessaryGuaranteesResponse.allDeviliveredGarantees == true)
        this.garantiasCorrectas = true
      else
        this.garantiasCorrectas = false
    else
      this.garantiasCorrectas = false
  }

  changeAcceptTerms() {
    this.validatorFormGroup.controls["AcceptTerms"].setValue(false)
    this.isShowResumen = false
  }

  setCreateContratoRequest(): CreateContratoRequest {
    let createContratoRequest = new CreateContratoRequest();
    let contrato = new ContratoDto();
    let lstContratoGarantiasDto: ContratoGarantiaDto[] = [];
    let lstPropietariosContrato: ContratoPropietarioDto[] = [];
    let lstPeriodosContrato: PeriodoContratoSummaryDto[] = [];

    // Validaciones de propiedad e inquilino
    if (this.propiedad && this.propiedad.id) {
      contrato.idPropiedad = this.propiedad.id;
    }
    if (this.inquilinoDto && this.inquilinoDto.id) {
      contrato.idInquilino = this.inquilinoDto.id;
    }

    // Validaciones y asignaciones desde datosPropiedadFormGroup
    const comisionPropietario = this.datosPropiedadFormGroup.controls["ComisionPropietario"].value;
    contrato.porcentajeComisionPropietario = comisionPropietario;
    this.contratoResumen.porcentajeComisionPropietario = comisionPropietario;

    const tipoLiquidacion = this.datosPropiedadFormGroup.controls["TiposLiquidacion"].value;
    if (tipoLiquidacion && tipoLiquidacion.id) {
      contrato.idTipoLiquidacion = tipoLiquidacion.id;
      this.contratoResumen.tipoLiquidacion = tipoLiquidacion;
    }

    const tipoFormaPagoPropietario = this.datosPropiedadFormGroup.controls["FormaPagoPropietario"].value;
    if (tipoFormaPagoPropietario && tipoFormaPagoPropietario.id) {
      contrato.idFormaPagoPropietario = tipoFormaPagoPropietario.id;
    }

    // Validaciones y asignaciones desde datosContratoFormGroup
    const tipoContrato = this.datosContratoFormGroup.controls["TipoContrato"].value;
    if (tipoContrato && tipoContrato.id) {
      contrato.idTipoContrato = tipoContrato.id;
      this.contratoResumen.tipoContrato = tipoContrato;
    }

    contrato.fechaComienzo = this.datosContratoFormGroup.controls["FechaComienzo"].value;
    this.contratoResumen.fechaComienzo = contrato.fechaComienzo;

    contrato.fechaVencimiento = this.datosContratoFormGroup.controls["FechaFinalizacion"].value;
    this.contratoResumen.fechaVencimiento = contrato.fechaVencimiento;

    contrato.diaVencimientoPago = this.datosContratoFormGroup.controls["DiaPago"].value;
    this.contratoResumen.diaVencimientoPago = contrato.diaVencimientoPago;

    contrato.frecuenciaAjusteMensual = this.datosContratoFormGroup.controls["FrecuenciaAjusteMensual"].value;
    this.contratoResumen.frecuenciaAjusteMensual = contrato.frecuenciaAjusteMensual;

    const tipoAjusteContrato = this.datosContratoFormGroup.controls["TiposAjusteContrato"].value;
    if (tipoAjusteContrato && tipoAjusteContrato.id) {
      contrato.idTipoAjusteContrato = tipoAjusteContrato.id;
      this.contratoResumen.tipoAjusteContrato = tipoAjusteContrato;
    }

    contrato.fechaRescicion = "";
    contrato.idTipoReciboInquilino = TiposRecibosInquilinos.ReciboSimple;
    contrato.idTipoReciboPropietario = TiposRecibosPropietarios.ReciboSimple;
    contrato.idTipoMonedaMontoContrato = TiposMonedas.Pesos;

    // Validaciones y asignaciones desde montosContratoFormGroup
    const tipoMontoSellado = this.montosContratoFormGroup.controls["TipoMontoSellado"].value;
    if (tipoMontoSellado && tipoMontoSellado.id) {
      contrato.idTipoMontoSellado = tipoMontoSellado.id;
      this.contratoResumen.tipoMontoSellado = tipoMontoSellado;
    }

    const tipoSellado = this.montosContratoFormGroup.controls["TipoSellado"].value;
    if (tipoSellado && tipoSellado.id) {
      contrato.idTipoSellado = tipoSellado.id;
      this.contratoResumen.tipoSellado = tipoSellado;
    }

    const tipoPunitorio = this.montosContratoFormGroup.controls["TipoPunitorio"].value;
    if (tipoPunitorio && tipoPunitorio.id) {
      contrato.idTipoPunitorio = tipoPunitorio.id;
      this.contratoResumen.tipoPunitorio = tipoPunitorio;
    }

    const tipoMontoHonorario = this.montosContratoFormGroup.controls["TipoMontoHonorario"].value;
    if (tipoMontoHonorario && tipoMontoHonorario.id) {
      contrato.idTipoMontoAdministrativo = tipoMontoHonorario.id;
      this.contratoResumen.tipoMontoAdministrativo = tipoMontoHonorario;
    }

    const tipoMontoPunitorio = this.montosContratoFormGroup.controls["TipoMontoPunitorio"].value;
    if (tipoMontoPunitorio && tipoMontoPunitorio.id) {
      contrato.idTipoMontoPunitorio = tipoMontoPunitorio.id;
      this.contratoResumen.tipoMontoPunitorio = tipoMontoPunitorio;
    }

    contrato.montoPunitorio = this.montosContratoFormGroup.controls["MontoPunitorio"].value;
    this.contratoResumen.montoPunitorio = contrato.montoPunitorio;

    contrato.montoSellado = this.montosContratoFormGroup.controls["MontoSellado"].value;
    this.contratoResumen.montoSellado = contrato.montoSellado;

    contrato.montoAdministrativo = this.montosContratoFormGroup.controls["MontoHonorario"].value;
    this.contratoResumen.montoAdministrativo = contrato.montoAdministrativo;

    const planPago = this.montosContratoFormGroup.controls["PlanPago"].value;
    contrato.planDePago = planPago ?? 0; // Default to 0 if falsy
    this.contratoResumen.planDePago = planPago ?? 0;

    contrato.idEstadoContrato = EstadosContrato.Activo;
    contrato.montoTotalContrato = this.total;
    contrato.montoTotalHonorario = this.totalComision;
    contrato.montoTotalSellado = this.totalSellado;

    const aceptaTerminos = this.validatorFormGroup.controls["AcceptTerms"].value;
    contrato.firmado = !!aceptaTerminos;

    // Garantias
    this.garantiasInquilino.forEach(element => {
      let contratoGarantiaDto = new ContratoGarantiaDto();
      contratoGarantiaDto.idGarantia = element.id;
      lstContratoGarantiasDto.push(contratoGarantiaDto);
    });

    // Propietarios
    if (Array.isArray(this.propietariosPropiedad)) {
      this.propietariosPropiedad.forEach(element => {
        let contratoPropietarioDto = new ContratoPropietarioDto();
        contratoPropietarioDto.idPropietario = element.id;
        lstPropietariosContrato.push(contratoPropietarioDto);
      });
    }

    // Periodos
    this.lstPeriodos.forEach(element => {
      let periodoDesde = element.periodoDesde;
      while (periodoDesde <= element.periodoHasta) {
        let periodoContratoSummaryDto = new PeriodoContratoSummaryDto();
        periodoContratoSummaryDto.periodo = periodoDesde;
        periodoContratoSummaryDto.importeAlquiler = element.montoAlquiler + element.incremento - element.descuento;
        periodoContratoSummaryDto.descuento = element.descuento;
        periodoContratoSummaryDto.incremento = element.incremento;
        if (!isFalsy(element.idIndiceAjusteContrato)) {
          periodoContratoSummaryDto.idIndiceAjusteContrato = element.idIndiceAjusteContrato;
        }
        if (element.valorAjuste) {
          periodoContratoSummaryDto.valorAjuste = element.valorAjuste;
        }
        lstPeriodosContrato.push(periodoContratoSummaryDto);
        periodoDesde++;
      }
    });

    this.data = this.dataSource.data.length >= 1;

    createContratoRequest.garantiasContrato = lstContratoGarantiasDto;
    createContratoRequest.propietariosContrato = lstPropietariosContrato;
    createContratoRequest.periodosContrato = lstPeriodosContrato;
    createContratoRequest.contrato = contrato;

    return createContratoRequest;
  }


  showResumen(event: MatSlideToggleChange) {
    if (event.checked == true) {
      this.isShowResumen = true
      this.setCreateContratoRequest()
    }
    else {
      this.isShowResumen = false
    }

  }

  editarPersona(idPersona: number) {
    this.getPersonaByIdAndRoute(idPersona)
  }

  getPersonaByIdAndRoute(idPersona: number) {
    this.spinner.show("spPersona")
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        });
  }

  getDescripcionTipoMonto(idTipoMonto: number): string {
    switch (idTipoMonto) {
      case TiposMontos.PorcentajeAlquiler:
        return "% Porcentaje Alquiler"
      case TiposMontos.MontoFijo:
        return "$ Monto Fijo"
      default:
        return "Sin Detalle"
    }
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}

