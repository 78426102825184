<h1 mat-dialog-title>Enviar email a</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <form [formGroup]="form">
        <mat-form-field class="full-width-x100">
            <mat-label>Destinatarios</mat-label>
            <input cdkFocusInitial type="email" formControlName="Descripcion" matInput>
            <mat-error *ngIf="f.Descripcion.errors?.required">Este campo es <strong>requerido</strong>.
            </mat-error>
            <mat-error *ngIf="f.Descripcion.errors?.email">Correo invalido.</mat-error>
        </mat-form-field>
    </form>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="doAction()" [disabled]="!form.valid"><mat-icon matSuffix>send </mat-icon> Enviar</button>
    <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
</div>
