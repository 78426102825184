import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuditoriaLogDto } from '@models/auditoriaLog/auditoriaLogDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';

@Component({
  selector: 'dialog-view-auditorialog',
  templateUrl: './dialog-view-auditorialog.component.html',
  styleUrls: ['./dialog-view-auditorialog.component.css'],
})
export class DialogViewAuditorialogComponent {
  action: any;
  local_data: any;

  constructor(
    public dialogRef: MatDialogRef<AuditoriaLogDto>,
    @Optional() @Inject(MAT_DIALOG_DATA) public auditoriaLog: AuditoriaLogDto) {

  }

  doAction(){
    this.dialogRef.close({event:this.action,data:this.local_data});
  }

  closeDialog(){
    this.dialogRef.close({event:AccionesDialog.Cancelar});
  }

  ngOnInit(): void {
  }
}
