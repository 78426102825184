import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import {PersonasService} from "@services/personas/personas.service"

@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.css']
})
export class ContratosComponent implements OnInit {
  idPersona: string|null="";
  subMenuActive:boolean=false;

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
  ) {

  }

  ngOnInit(): void {

    this.idPersona = this.aRoute.snapshot.paramMap.get('id');
    if (this.idPersona !="" || this.idPersona != null){
      this.subMenuActive=true;
    }
  }
  goToFormPersona(){
    this.router.navigate(["/personas",this.idPersona])
  }
  goToFormPropietario(){
    this.router.navigate(["/propietario",this.idPersona])
  }
  goToFormGarantia(){
    this.router.navigate(["/garantia",this.idPersona])
  }
  goToFormContrato(){
    this.router.navigate(["/contrato",this.idPersona])
  }
  goToFormInquilino(){
    this.router.navigate(["/inquilino",this.idPersona])
  }
  goToFormDocumento(){
    this.router.navigate(["/form-documento",this.idPersona])
  }

}
