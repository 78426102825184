import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { ParametrosConfiguracion } from '@models/base/identificadores.model';
import { CreateCuentaContableRequest } from '@models/cuentas-contables/createCuentaContableRequest.model';
import { CreateCuentaContableResponse } from '@models/cuentas-contables/createCuentaContableResponse.model';
import { CuentaContableDto } from '@models/cuentas-contables/cuentaContableDto.model';
import { UpdateCuentaContableRequest } from '@models/cuentas-contables/updateCuentaContableRequest.model';
import { ParametroSistemaDto } from '@models/parametros-sistema/parametroSistemaDto.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class CuentasContablesService {

  cuentaContableDto!: CuentaContableDto
  private enviarCuentaContableDto = new Subject<CuentaContableDto>()
  enviarCuentaContableDtoObservable = this.enviarCuentaContableDto.asObservable()


  enviarCuentaContable(cuentaContableDto: CuentaContableDto) {
    this.cuentaContableDto = cuentaContableDto
    this.enviarCuentaContableDto.next(cuentaContableDto)
  }

  constructor(
    private http: HttpClient
  ) { }

  create(createCuentaContableRequest:CreateCuentaContableRequest):Observable<CreateResponseBase>
  {
    return this.http.post<CreateResponseBase>("/api/CuentaContable/Create", createCuentaContableRequest)
  }

  update(updateCuentaContableRequest:UpdateCuentaContableRequest):Observable<any>
  {
    return  this.http.put<any>("/api/CuentaContable/Update",updateCuentaContableRequest);
  }

  getAll():Observable<CuentaContableDto[]>
  {
    return this.http.get<CuentaContableDto[]>("/api/CuentaContable/GetAll")
  }

  getById(id:number):Observable<CuentaContableDto>
  {
    return  this.http.get<CuentaContableDto>("/api/CuentaContable/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/CuentaContable/Delete?id=" + id);
    else
      return of(null)
  }




}
