import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog, TiposContrato } from '@models/base/identificadores.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { Periodo } from '../form-nuevo-contrato.component';
import { validarCantidadPeriodos, validarPeriodoContrato } from '@helpers/validations/contrato.validator';
import { isFalsy } from 'utility-types';
import { IndiceAjusteContratoDto } from '@models/indice-ajuste-contrato/indiceAjusteContratoDto.model';
import { IndiceAjusteContratoService } from '@services/indice-ajuste-contrato/indiceAjusteContrato.service';

export interface DialogData {
  ultimoPeriodo: 0;
  event: string
  cantidadPeriodos: number
  tipoContrato: number
  ultimoMonto: number
  adendar: boolean
  fechaComienzo: string
  idTipoAjusteContrato: number
}

@Component({
  selector: 'app-dialog-nuevo-periodo',
  templateUrl: './dialog-nuevo-periodo.component.html',
  styleUrls: ['./dialog-nuevo-periodo.component.css']
})
export class DialogNuevoPeriodoComponent implements OnInit {
  nuevoPeriodoFormGroup!: FormGroup
  periodo!: Periodo;
  lstIndiceAjusteContrato: IndiceAjusteContratoDto[] = []
  indiceAjusteContrato!: IndiceAjusteContratoDto;
  get f() { return this.nuevoPeriodoFormGroup.controls; }
  precioAlquiler: number = 0
  descuento: number = 0
  incremento: number = 0
  dialogData!: DialogData;
  esContratoFijo: boolean = true

  constructor
    (
      private _formBuilder: FormBuilder,
      private spinner: NgxSpinnerService,
      private snackBar: SnackBarService,
      private indiceAjusteContratoService: IndiceAjusteContratoService,
      public dialogRef: MatDialogRef<DialogNuevoPeriodoComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    this.dialogData = this.data
    if (this.dialogData.adendar)
      this.dialogData.cantidadPeriodos = 1000

    this.nuevoPeriodoFormGroup = this._formBuilder.group({
      PeriodoDesde: ['', Validators.compose([Validators.required, Validators.pattern(/[0-9]/)])],
      PeriodoHasta: ['', Validators.compose([Validators.required, Validators.pattern(/[0-9]/)])],
      MontoAlquiler: [0, Validators.required],
      IncrementoPorcentajeValor: [0],
      Incremento: [0],
      DescuentoPorcentajeValor: [0],
      Descuento: [0],
    },
      {
        validator: [validarPeriodoContrato('PeriodoDesde', 'PeriodoHasta', this.dialogData.cantidadPeriodos), validarCantidadPeriodos('PeriodoHasta', this.dialogData.cantidadPeriodos)]
      });
  }

  ngOnInit(): void {

    this.nuevoPeriodoFormGroup.controls["PeriodoDesde"].disable()
    this.nuevoPeriodoFormGroup.controls["MontoAlquiler"].setValue(this.dialogData.ultimoMonto)
    this.nuevoPeriodoFormGroup.controls["PeriodoDesde"].setValue(this.dialogData.ultimoPeriodo, { emitEvent: true })
    this.nuevoPeriodoFormGroup.controls["PeriodoHasta"].setValue(this.dialogData.cantidadPeriodos)
    this.precioAlquiler = this.nuevoPeriodoFormGroup.controls["MontoAlquiler"].value
    if (this.dialogData.tipoContrato == TiposContrato.ConAjuste) {
      this.esContratoFijo = false;
      //this.getUltimoValorPorTipo(this.dialogData.idTipoAjusteContrato)
    }
  }

  getUltimoValorPorTipo(idTipoAjusteContrato: number) {
    this.spinner.show("spIndiceAjusteContrato");
    this.indiceAjusteContratoService.getUltimoValorPorTipo(idTipoAjusteContrato)
      .subscribe(
        data => {
          this.spinner.hide("spIndiceAjusteContrato");
          this.indiceAjusteContrato = data
        },
        error => {
          this.spinner.hide("spIndiceAjusteContrato");
          this.snackBar.showError(error, "Error");
        });
  }

  ngOnChanges() {
    this.precioAlquiler = this.nuevoPeriodoFormGroup.controls["MontoAlquiler"].value
  }

  agregarPeriodo() {
    let periodo = this.setPeriodo()
    let cantidadPeriodos = periodo.periodoDesde - periodo.periodoDesde
    if (cantidadPeriodos > this.dialogData.cantidadPeriodos)
      this.snackBar.showWarn("PeriodoHasta supera la cantidad máxima.", "Error")
    if (periodo.montoAlquiler == 0)
      this.snackBar.showWarn("Ingrese un monto de alquiler válido.", "Error")
    else {
      this.dialogRef.close({ data: periodo, event: AccionesDialog.Agregar });
    }
  }

  setPeriodo(): Periodo {
    let periodo: Periodo = new Periodo

    if (this.nuevoPeriodoFormGroup.controls["PeriodoDesde"].value != "")
      periodo.periodoDesde = parseInt(this.nuevoPeriodoFormGroup.controls["PeriodoDesde"].value)

    if (this.nuevoPeriodoFormGroup.controls["PeriodoHasta"].value != "")
      periodo.periodoHasta = parseInt(this.nuevoPeriodoFormGroup.controls["PeriodoHasta"].value)

    if (this.nuevoPeriodoFormGroup.controls["MontoAlquiler"].value != "") {
      let montoAlquiler = isFalsy(this.nuevoPeriodoFormGroup.controls["MontoAlquiler"].value) ? 0 : (Math.round(this.nuevoPeriodoFormGroup.controls["MontoAlquiler"].value * 100) / 100)

      let incremento = isFalsy(this.nuevoPeriodoFormGroup.controls["Incremento"].value) ? 0 : (Math.round(this.nuevoPeriodoFormGroup.controls["Incremento"].value * 100) / 100)

      let descuento = isFalsy(this.nuevoPeriodoFormGroup.controls["Descuento"].value) ? 0 : (Math.round(this.nuevoPeriodoFormGroup.controls["Descuento"].value * 100) / 100)

      periodo.montoAlquiler = montoAlquiler + incremento - descuento
    }

    periodo.subTotal = periodo.montoAlquiler + periodo.incremento - periodo.descuento
    if(!isFalsy(this.indiceAjusteContrato)){
      periodo.valorAjuste = 1
      periodo.idIndiceAjusteContrato = this.indiceAjusteContrato.id
    }
    else
    {
      periodo.valorAjuste = 0
      periodo.idIndiceAjusteContrato = null
    }

    return periodo
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  changeMontoAlquiler() {
    const montoAlquiler = this.nuevoPeriodoFormGroup.controls["MontoAlquiler"].value
    this.precioAlquiler = montoAlquiler != null ? (Math.round(montoAlquiler * 100) / 100) : 0
  }

  changeIncremento() {
    const incremento = this.nuevoPeriodoFormGroup.controls["Incremento"].value
    this.incremento = incremento != null ? (Math.round(incremento * 100) / 100) : 0
    this.calculaIncremento()
  }

  changeDescuento() {
    const descuento = (Math.round(this.nuevoPeriodoFormGroup.controls["Descuento"].value * 100) / 100)
    this.descuento = descuento != null ? (Math.round(descuento * 100) / 100) : 0
    this.calculaDescuento()
  }

  calculaIncremento() {

    const incrementoPorcentajeValor = isFalsy(this.nuevoPeriodoFormGroup.controls["IncrementoPorcentajeValor"].value) ? 0 : this.nuevoPeriodoFormGroup.controls["IncrementoPorcentajeValor"].value

    const montoAlquiler = isFalsy(this.nuevoPeriodoFormGroup.controls["MontoAlquiler"].value) ? 0 : (Math.round(this.nuevoPeriodoFormGroup.controls["MontoAlquiler"].value * 100) / 100)

    if (incrementoPorcentajeValor > 0 && montoAlquiler > 0) {
      this.incremento = (montoAlquiler * incrementoPorcentajeValor) / 100
      this.nuevoPeriodoFormGroup.controls["Incremento"].setValue(this.incremento)
    }
    else {
      this.incremento = 0
      this.nuevoPeriodoFormGroup.controls["Incremento"].setValue("")
    }

  }
  calculaDescuento() {
    const descuentoPorcentajeValor = this.nuevoPeriodoFormGroup.controls["DescuentoPorcentajeValor"].value
    const montoAlquiler = (Math.round(this.nuevoPeriodoFormGroup.controls["MontoAlquiler"].value * 100) / 100)

    if (descuentoPorcentajeValor > 0 && montoAlquiler > 0) {
      this.descuento = (montoAlquiler * descuentoPorcentajeValor) / 100
      this.nuevoPeriodoFormGroup.controls["Descuento"].setValue(this.descuento)
    }
    else {
      this.descuento = 0
      this.nuevoPeriodoFormGroup.controls["Descuento"].setValue("")
    }
  }

  calcularPrecioFinalAlquiler(precioAlquiler: number, descuento: number, incremento: number) {
    let total = precioAlquiler - descuento + incremento;
    return (Math.round(total * 100) / 100)
  }


}
