<div class="position-relative">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
    <h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>
    <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">

        <form [formGroup]="form" *ngIf="action != 'Eliminar'; else elseTemplate">

            <div class="row">
                <ngx-spinner [zIndex]="1" bdColor="rgba(255,255,255,0.8)" name="spCrud" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                </ngx-spinner>
                <div class="col-6">
                    <mat-form-field>
                        <ngx-spinner [zIndex]=100 name="spBusqueda" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                        <mat-label>Cantidad Garantias</mat-label>
                        <mat-select placeholder="Seleccione opcion" formControlName="CantidadTipoGarantia">
                            <mat-option [value]="cantidadTipoGarantiaDto.id" *ngFor="let cantidadTipoGarantiaDto of lstCantidadTipoGarantiaDto;let i=index;trackBy:trackByItems">
                                {{cantidadTipoGarantiaDto.cantidad}} - {{cantidadTipoGarantiaDto.tipoGarantia.descripcion}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="f.CantidadTipoGarantia.errors?.required">Este campo es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <input cdkFocusInitial type="number" formControlName="Grupo" placeholder="{{action}} Grupo" matInput>
                        <mat-error *ngIf="f.Grupo.errors?.required">Este campo es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <ng-template #elseTemplate>
            <ngx-spinner [zIndex]="100" bdColor="rgba(255,255,255,0.8)" name="spCrud" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            ¿Esta seguro que desea eliminar el registro <b>{{local_data.descripcion}}</b>?
        </ng-template><br>
    </div>
    <br>
    <mat-divider></mat-divider>
    <div mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="doAction()" [disabled]="!form.valid" *appRole="['GruposGarantias.Crear','GruposGarantias.Modificar','GruposGarantias.Eliminar']">{{action}}</button>
        <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
    </div>
</div>