import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CantidadTipoGarantiaDto } from '@models/cantidades-tipo-garantia/cantidadTipoGarantiaDto.model';
import { CreateGrupoTipoGarantiaRequest } from '@models/grupos-tipo-garantia/createGrupoTipoGarantiaRequest.model';
import { GrupoTipoGarantiaDto } from '@models/grupos-tipo-garantia/grupoTipoGarantiaDto.model';
import { UpdateGrupoTipoGarantiaRequest } from '@models/grupos-tipo-garantia/updateGrupoTipoGarantiaRequest.model';
import { CantidadesTipoGarantiaService } from '@services/cantidades-tipo-garantia/cantidades-tipo-garantia.service';
import { GruposTipoGarantiaService } from '@services/grupos-tipo-garantia/grupos-tipo-garantia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from "utility-types";

@Component({
  selector: 'app-dialog-grupos-tipo-garantia',
  templateUrl: './dialog-grupos-tipo-garantia.component.html',
  styleUrls: ['./dialog-grupos-tipo-garantia.component.css']
})
export class DialogGruposTipoGarantiaComponent implements OnInit {

  action: string = "";
  local_data: any;
  form: any;
  formBuilder: FormBuilder = new FormBuilder;
  lstCantidadTipoGarantiaDto: CantidadTipoGarantiaDto[] = []
  grupoTipoGarantiaDto!: GrupoTipoGarantiaDto
  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogGruposTipoGarantiaComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private gruposTipoGarantiaService: GruposTipoGarantiaService,
    private cantidadesTipoGarantiaService: CantidadesTipoGarantiaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      Grupo: ["", Validators.compose([Validators.required,])],
      CantidadTipoGarantia: ["", Validators.compose([Validators.required,])],
    })
  }

  doAction() {
    let id = this.local_data.id
    let idGrupo = this.form.controls["Grupo"].value
    let idCantidadTipoGarantia = this.form.controls["CantidadTipoGarantia"].value as number

    switch (this.action) {
      case AccionesDialog.Agregar:
        this.addRowData(idGrupo, idCantidadTipoGarantia);
        break;
      case AccionesDialog.Modificar:
        this.updateRowData(id, idGrupo, idCantidadTipoGarantia)
        break;
      case AccionesDialog.Eliminar:
        this.deleteRowData(id)
        break;
      case AccionesDialog.Eliminar:
        this.dialogRef.close({ event: this.action, data: this.local_data });
        break;
      default:
        this.dialogRef.close({ event: AccionesDialog.Cancelar });
        break;
    }

  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  ngOnInit(): void {
    if (this.local_data.action != AccionesDialog.Agregar) {
      this.form.controls["Grupo"].setValue(this.local_data.idGrupo)
      this.form.controls["CantidadTipoGarantia"].setValue(this.local_data.cantidadTipoGarantia.id)
    }

    this.getAllCantidadTipoGarantia()
  }

  async getAllCantidadTipoGarantia() {
    this.spinner.show("spBusqueda")
    await this.cantidadesTipoGarantiaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda")
          this.lstCantidadTipoGarantiaDto = data
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spBusqueda")
        }
      )
  }


  async addRowData(idGrupo: number, idCantidadesTipoGarantia: number) {
    this.spinner.show("spCrud")
    let createGrupoTipoGarantiaRequest = new CreateGrupoTipoGarantiaRequest
    createGrupoTipoGarantiaRequest.idGrupo = idGrupo
    createGrupoTipoGarantiaRequest.idCantidadesTipoGarantia = idCantidadesTipoGarantia

    await this.gruposTipoGarantiaService.create(createGrupoTipoGarantiaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spCrud")
          this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          this.dialogRef.close({ event: this.action, data: this.local_data });
        },
        error => {
          this.spinner.hide("spCrud")
          this.dialogRef.close({ event: AccionesDialog.Cancelar });
          this.snackBar.showError(error, "Error");
        }
      )

  }
  async updateRowData(id: number, idGrupo: number, idCantidadesTipoGarantia: number) {
    this.spinner.show("spCrud")
    let updateGrupoTipoGarantiaRequest = new UpdateGrupoTipoGarantiaRequest
    updateGrupoTipoGarantiaRequest.id = id
    updateGrupoTipoGarantiaRequest.idGrupo = idGrupo
    updateGrupoTipoGarantiaRequest.idCantidadesTipoGarantia = idCantidadesTipoGarantia

    await this.gruposTipoGarantiaService.update(updateGrupoTipoGarantiaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spCrud")
          this.snackBar.showSuccess("Registro actualizado correctamente.", "Exito");
          this.dialogRef.close({ event: this.action, data: this.local_data });
        },
        error => {
          this.spinner.hide("spCrud")
          this.dialogRef.close({ event: AccionesDialog.Cancelar });
          this.snackBar.showError(error, "Error");
        })
  }

  async deleteRowData(id: number) {
    if (!isFalsy(id)) {
      this.spinner.show("spCrud")
      await this.gruposTipoGarantiaService.delete(id)
        .subscribe(
          data => {
            this.spinner.hide("spCrud")
            this.snackBar.showSuccess("Registro eliminado correctamente.", "Exito");
            this.dialogRef.close({ event: this.action, data: this.local_data });
          },
          error => {
            this.spinner.hide("spCrud")
            this.dialogRef.close({ event: AccionesDialog.Cancelar });
            this.snackBar.showError(error, "Error");

          })
    }

  }

  trackByItems(index: number, item: any): any { return item; }

}
