export class UpdateDetalleServicioPropiedadRequest {

    public id : number=0;
    public idServicioPropiedad: number = 0
    public periodoMes: number = 0
    public periodoAnio: number = 0
    public fechaVencimiento1: string | null  = null
    public importeVencimiento1: number | null  = null
    public fechaVencimiento2: string | null  = null
    public importeVencimiento2: number | null  = null
    public fechaVencimiento3: string | null  = null
    public importeVencimiento3: number | null  = null
    public fechaVencimiento4: string | null  = null
    public importeVencimiento4: number | null  = null
    public cuota: number = 0
    public pendientePago = true
    public comision: number = 0
    public periodo: string = ""
    public id_QuienPaga:number=0
    public id_EstadoDetalleServicioPropiedad:number=0
}
