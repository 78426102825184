import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-nuevo-tipo-folio',
  templateUrl: './btn-nuevo-tipo-folio.component.html',
  styleUrls: ['./btn-nuevo-tipo-folio.component.css']
})
export class BtnNuevoTipoFolioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
