import { DatePipe } from "@angular/common";
import { Component, OnInit, Inject, Optional } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { validarFechaAnteriorHoy } from "@helpers/validations/dates.validator";
import { TiposFechaValidaciones, AccionesDialog } from "@models/base/identificadores.model";
import { DetalleServicioPropiedadDetailDto } from "@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadDetailDto.model";
import { UpdateDetalleServicioPropiedadRequest } from "@models/servicios/detalle-servicio-propiedad/updateDetalleServicioPropiedadRequest.model";
import { DetalleServicioPropiedadService } from "@services/servicios/detalle-servicios-propiedad/detalle-servicio-propiedad.service";
import { SnackBarService } from "@services/snackBar/snack-bar-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { isFalsy } from "utility-types";


@Component({
  selector: 'app-dialog-nuevo-vencimiento-servicio',
  templateUrl: './dialog-nuevo-vencimiento-servicio.component.html',
  styleUrls: ['./dialog-nuevo-vencimiento-servicio.component.css'],
  providers: [DatePipe]
})
export class DialogNuevoVencimientoServicioComponent implements OnInit {

  form!: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  detalleServicioPropiedad!: DetalleServicioPropiedadDetailDto;
  fechaMinimaStr: any;
  get f() { return this.form.controls; }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,

    public dialogRef: MatDialogRef<DialogNuevoVencimientoServicioComponent>,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private detalleServicioPropiedadService: DetalleServicioPropiedadService,
    private datePipe: DatePipe
  ) {

    this.form = this.formBuilder.group({
      Fecha: [new Date, Validators.compose([Validators.required])],
      Importe: ["", Validators.compose([Validators.required,])],
    },{
      validator: [validarFechaAnteriorHoy('Fecha',TiposFechaValidaciones.Anterior)]
    })
  }

  ngOnInit(): void {
    const detalleServicioPropiedad = this.data.detalleServicio
    this.getDetalleServicioPropiedad(detalleServicioPropiedad.id)

  }
  getDetalleServicioPropiedad(id: any) {
    this.spinner.show()
    this.detalleServicioPropiedadService.GetById(id).subscribe(
      data => {
        this.spinner.hide()
        this.detalleServicioPropiedad = data
      },
      error => {
        this.spinner.hide()
        this.snackBar.showError(error, "Error");
      }
    )
  }
  onCancel() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  onSubmit() {
    const fecha = this.form.controls["Fecha"].value
    const importe = parseFloat(this.form.controls["Importe"].value)
    let detalleServicioPropiedad: DetalleServicioPropiedadDetailDto = new DetalleServicioPropiedadDetailDto
    detalleServicioPropiedad = this.detalleServicioPropiedad
    let limiteVencimiento = true
    if (isFalsy(this.detalleServicioPropiedad.fechaVencimiento1) || isFalsy(this.detalleServicioPropiedad.fechaVencimiento1) || isFalsy(this.detalleServicioPropiedad.importeVencimiento1) || isFalsy(this.detalleServicioPropiedad.importeVencimiento1)) {
      detalleServicioPropiedad.importeVencimiento1 = importe
      detalleServicioPropiedad.fechaVencimiento1 = fecha
      detalleServicioPropiedad.seleccionPago = true
      limiteVencimiento = false
    }
    else if (isFalsy(this.detalleServicioPropiedad.fechaVencimiento2) || isFalsy(this.detalleServicioPropiedad.fechaVencimiento2) || isFalsy(this.detalleServicioPropiedad.importeVencimiento2) || isFalsy(this.detalleServicioPropiedad.importeVencimiento2)) {
      detalleServicioPropiedad.importeVencimiento2 = importe
      detalleServicioPropiedad.fechaVencimiento2 = fecha
      detalleServicioPropiedad.seleccionPago = true
      limiteVencimiento = false
    }
    else if (isFalsy(this.detalleServicioPropiedad.fechaVencimiento3) || isFalsy(this.detalleServicioPropiedad.fechaVencimiento3) || isFalsy(this.detalleServicioPropiedad.importeVencimiento3) || isFalsy(this.detalleServicioPropiedad.importeVencimiento3)) {
      detalleServicioPropiedad.importeVencimiento3 = importe
      detalleServicioPropiedad.fechaVencimiento3 = fecha
      detalleServicioPropiedad.seleccionPago = true
      limiteVencimiento = false
    }
    else if (isFalsy(this.detalleServicioPropiedad.fechaVencimiento4) || isFalsy(this.detalleServicioPropiedad.fechaVencimiento4) || isFalsy(this.detalleServicioPropiedad.importeVencimiento4) || isFalsy(this.detalleServicioPropiedad.importeVencimiento4)) {
      detalleServicioPropiedad.importeVencimiento4 = importe
      detalleServicioPropiedad.fechaVencimiento4 = fecha
      detalleServicioPropiedad.seleccionPago = true
      limiteVencimiento = false
    }
    else if (limiteVencimiento == true) {
      detalleServicioPropiedad.importeVencimiento4 = importe
      detalleServicioPropiedad.fechaVencimiento4 = fecha
      detalleServicioPropiedad.seleccionPago = true
    }

    let uDSPR = new UpdateDetalleServicioPropiedadRequest
    uDSPR.id = detalleServicioPropiedad.id
    uDSPR.idServicioPropiedad = detalleServicioPropiedad.servicioPropiedad.id
    uDSPR.comision = detalleServicioPropiedad.comision
    uDSPR.periodoMes = detalleServicioPropiedad.periodoMes
    uDSPR.periodoAnio = detalleServicioPropiedad.periodoAnio
    uDSPR.periodo = detalleServicioPropiedad.periodo
    uDSPR.fechaVencimiento1 = detalleServicioPropiedad.fechaVencimiento1
    uDSPR.fechaVencimiento2 = detalleServicioPropiedad.fechaVencimiento2
    uDSPR.fechaVencimiento3 = detalleServicioPropiedad.fechaVencimiento3
    uDSPR.fechaVencimiento4 = detalleServicioPropiedad.fechaVencimiento4
    uDSPR.importeVencimiento1 = detalleServicioPropiedad.importeVencimiento1
    uDSPR.importeVencimiento2 = detalleServicioPropiedad.importeVencimiento2
    uDSPR.importeVencimiento3 = detalleServicioPropiedad.importeVencimiento3
    uDSPR.importeVencimiento4 = detalleServicioPropiedad.importeVencimiento4
    uDSPR.id_QuienPaga = detalleServicioPropiedad.quienPaga.id
    uDSPR.id_EstadoDetalleServicioPropiedad = detalleServicioPropiedad.estado.id
    this.actualizarDetalleServicioPropiedad(uDSPR)


  }

  actualizarDetalleServicioPropiedad(uDSPR: UpdateDetalleServicioPropiedadRequest) {
    this.spinner.show()
    this.detalleServicioPropiedadService.update(uDSPR).subscribe(
      data => {
        this.spinner.hide()
        this.snackBar.showSuccess("Vencimiento agregado correctamente.", "Exito");
        this.dialogRef.close({ data: this.detalleServicioPropiedad, event: AccionesDialog.Modificar });
      },
      error => {
        this.spinner.hide()
        this.snackBar.showError(error, "Error");
      }
    )
  }


  addEvent(event: MatDatepickerInputEvent<Date>) {
    const fecha = event.value;
  }


}
