import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { TiposFolios, TiposGarantia } from '@models/base/identificadores.model';
import { CreateGarantiaRequest } from '@models/garantia/createGarantiaRequest.model';
import { GarantiaDto } from '@models/garantia/garantiaDto.model';
import { GarantiaSummaryDto } from '@models/garantia/garantiaSummaryDto.model';
import { GarantiasService } from '@services/garantias/garantias.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposFolioService } from '@services/tipos-folio/tipos-folio.service';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'app-form-garantia-create',
  templateUrl: './form-garantia-create.component.html',
  styleUrls: ['./form-garantia-create.component.css']
})
export class FormGarantiaCreateComponent implements OnInit {
  data = false
  garantiaDto: GarantiaDto = new GarantiaDto
  validador: boolean = false;

  lstTiposGarantiaModel: BaseDto[] = []
  lstTiposFolios: BaseDto[] = []
  personaDetailDto: PersonaDetailDto = new PersonaDetailDto;
  id_PersonaInquilino!: number;
  formBuilder: FormBuilder = new FormBuilder
  createGarantiaRequest: CreateGarantiaRequest = new CreateGarantiaRequest
  idGarantia: number = 0
  mostrarPersona!: boolean;
  mostrarPropiedad!: boolean;
  mostrarFianza!: boolean;
  formTipoGarantia: FormGroup;
  formPropiedad: FormGroup;
  formPersonaGarantia: FormGroup;
  nuevaGarantia!: boolean;
  formFianza: FormGroup;
  garantiaSummaryDtoTable: GarantiaSummaryDto = new GarantiaSummaryDto
  isEditMode: boolean = false
  indivisa: any
  displayedColumns!: string[];
  idTipoFolio: any;
  idTipoGarantia: any;
  get fPro() { return this.formPropiedad.controls; }
  get fPer() { return this.formPersonaGarantia.controls; }
  get fG() { return this.formTipoGarantia.controls; }
  get fTL() { return this.formFianza.controls; }


  constructor(
    private aRoute: ActivatedRoute,
    private garantiaService: GarantiasService,
    private snackBar: SnackBarService,
    private router: Router,
    private garantiasService: GarantiasService,
    private spinner: NgxSpinnerService,
    private tiposFolioService: TiposFolioService,
    private personasService: PersonasService,
  ) {


    this.formTipoGarantia = this.formBuilder.group({
      TipoGarantia: ['', Validators.compose([Validators.required])],
    });
    this.formPersonaGarantia = this.formBuilder.group({
      Sueldo: [""],
      CuitCuil: ['', Validators.compose([Validators.required,Validators.pattern(/^([0-9]{11}|[0-9]{2}-[0-9]{8}-[0-9]{1})$/)])],
      Empleador: ['', Validators.compose([Validators.maxLength(200), Validators.required])],
    });
    this.formFianza = this.formBuilder.group({
      Cuit: ['', Validators.compose([Validators.required,,Validators.pattern(/^([0-9]{11}|[0-9]{2}-[0-9]{8}-[0-9]{1})$/)])],
      RazonSocial: ['', Validators.compose([Validators.maxLength(200), Validators.required])],
    });
    this.formPropiedad = this.formBuilder.group({
      TipoFolio: ['', Validators.compose([Validators.required],)],
      Tomo: ['', Validators.compose([Validators.maxLength(20)])],
      NroFolio: ['', Validators.compose([Validators.maxLength(20), Validators.required],)],
      Numero: ['', Validators.compose([Validators.maxLength(20)],)],
      Indivisa: ['100', Validators.compose([Validators.maxLength(20)])],
      Ubicacion: ['', Validators.compose([Validators.required,Validators.maxLength(100)],)],
      Localidad: ['', Validators.compose([Validators.maxLength(100)],)],
      PropiedadHorizontal: [''],

    });
  }

  ngOnInit(): void {
    this.personaDetailDto = this.personasService.getPersona()
    this.getAllTipoGarantias()
    this.getAllTipoFolios()
  }

  getAllTipoGarantias() {
    this.spinner.show("spTipoGarantia")
    this.garantiasService.getAllTiposGarantia()
      .subscribe(
        data => {
          this.spinner.hide("spTipoGarantia")
          this.lstTiposGarantiaModel = data
        },
        error => {
          this.spinner.hide("spTipoGarantia")
        }
      )
  }


  getPersonaById(idPersona: any) {
    this.spinner.show()
    this.personasService.getPersonaById(parseInt(idPersona))
      .subscribe(
        data => {
          this.spinner.hide()
          this.personaDetailDto = data
          this.personasService.setPersona(this.personaDetailDto)
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }


  editarGarantia(garantia: GarantiaSummaryDto) {
    this.garantiaService.garantia = garantia
    this.isEditMode = true
    this.idGarantia = garantia.id
    this.formTipoGarantia.controls['TipoGarantia'].disable()
    this.getGarantiaById(garantia.id)
  }

  getAllTipoFolios() {
    this.spinner.show("spTipoFolio")
    this.tiposFolioService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTipoFolio")
          this.lstTiposFolios = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide("spTipoFolio")
        }
      )
  }

  selectTipoFolio(event: any) {
    this.idTipoFolio = event;

  }

  selectTipoGarantia(event: any) {
    this.idTipoGarantia = event;
    //ReciboSueldo
    if (event == TiposGarantia.ReciboSueldo) {
      this.mostrarPersona = true
      this.mostrarPropiedad = false
      this.mostrarFianza = false
      this.formPropiedad.reset();
      this.formFianza.reset();
    }
    //Propiedad
    if (event == TiposGarantia.Propiedad) {
      this.mostrarPersona = false
      this.mostrarPropiedad = true
      this.mostrarFianza = false
      this.formPersonaGarantia.reset();
      this.formFianza.reset();
    }
    //Fianza
    if (event == TiposGarantia.Fianza) {
      this.mostrarPersona = false
      this.mostrarPropiedad = false
      this.mostrarFianza = true
      this.formPersonaGarantia.reset();
      this.formPropiedad.reset();
    }
  }


  getGarantiaById(idGarantia: number) {
    this.spinner.show("spFromGarantia")
    this.garantiasService.getGarantiaById(idGarantia)
      .subscribe(
        async data => {
          this.spinner.hide("spFromGarantia")
          this.garantiaDto = data
          await this.setGarantiaSummaryDto(this.garantiaDto)
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })
  }



  onSubmit() {
    this.spinner.show("spFromGarantia")
    let garantia = this.setCreateGarantiaRequest()
    this.garantiaService.create(garantia)
      .subscribe(
        async data => {
          const createResponseBase: CreateResponseBase = new CreateResponseBase
          createResponseBase.id = data.id
          this.snackBar.showSuccess("Garantia agregada correctamente.", "Exito");
          this.spinner.hide("spFromGarantia")
          //this.router.navigate(["/garantia/editar", data.id])
          this.router.navigate(["/garantia"])
        },
        error => {
          this.spinner.hide("spFromGarantia")
          this.snackBar.showError(error, "Error");
        }
      )
  }


  onReset() {
    this.router.navigate(["/garantia"])
  }

  setCreateGarantiaRequest(): CreateGarantiaRequest {
    this.createGarantiaRequest = new CreateGarantiaRequest
    this.createGarantiaRequest.id_Persona = this.personaDetailDto.id
    this.createGarantiaRequest.id_TipoGrantia = this.idTipoGarantia
    if (this.createGarantiaRequest.id_TipoGrantia == TiposGarantia.ReciboSueldo) {
      //Tipo Persona
      this.createGarantiaRequest.razonSocial = this.formPersonaGarantia.controls["Empleador"].value
      this.createGarantiaRequest.cuitCuil = this.formPersonaGarantia.controls["CuitCuil"].value
      this.createGarantiaRequest.sueldo = this.formPersonaGarantia.controls["Sueldo"].value
      this.createGarantiaRequest.idTipoFolio = TiposFolios.SinFolio
    }
    if (this.createGarantiaRequest.id_TipoGrantia == TiposGarantia.Propiedad) {
      //Tipo Propiedad
      this.createGarantiaRequest.idTipoFolio = this.formPropiedad.controls['TipoFolio'].value
      this.createGarantiaRequest.nroFolio = this.formPropiedad.controls['NroFolio'].value

      if (this.createGarantiaRequest.idTipoFolio == TiposFolios.FolioPersonalCronologico) {
        this.createGarantiaRequest.tomo = this.formPropiedad.controls['Tomo'].value
        this.createGarantiaRequest.numero = this.formPropiedad.controls['Numero'].value
      }
      else {
        this.createGarantiaRequest.tomo = ""
        this.createGarantiaRequest.numero = ""
      }

      this.createGarantiaRequest.indivisa = this.formPropiedad.controls['Indivisa'].value
      this.createGarantiaRequest.ubicacion = this.formPropiedad.controls['Ubicacion'].value
      this.createGarantiaRequest.localidad = this.formPropiedad.controls['Localidad'].value
      this.createGarantiaRequest.horizontal = this.formPropiedad.controls['PropiedadHorizontal'].value
    }
    if (this.createGarantiaRequest.id_TipoGrantia == TiposGarantia.Fianza) {
      //Tipo Fianza
      this.createGarantiaRequest.razonSocial = this.formFianza.controls['RazonSocial'].value
      this.createGarantiaRequest.cuitCuil = this.formFianza.controls['Cuit'].value
      this.createGarantiaRequest.idTipoFolio = TiposFolios.SinFolio
    }

    return this.createGarantiaRequest
  }



  setGarantiaSummaryDto(garantia: GarantiaDto) {
    this.garantiaDto = garantia
    switch (garantia.tipoGarantia.id) {
      case TiposGarantia.ReciboSueldo: {
        this.formTipoGarantia.controls['TipoGarantia'].setValue(garantia.tipoGarantia.id)
        this.formPersonaGarantia.controls['Sueldo'].setValue(garantia.sueldo)
        this.formPersonaGarantia.controls['CuitCuil'].setValue(garantia.cuitCuil)
        this.formPersonaGarantia.controls['Empleador'].setValue(garantia.razonSocial)
        this.idTipoGarantia = garantia.tipoGarantia.id
        this.idTipoFolio = TiposFolios.SinFolio
        this.mostrarPersona = true;
        this.mostrarFianza = false
        this.mostrarPropiedad = false;

        break;
      }
      case TiposGarantia.Propiedad: {
        this.formTipoGarantia.controls['TipoGarantia'].setValue(garantia.tipoGarantia.id)
        this.formPropiedad.controls['NroFolio'].setValue(garantia.nroFolio)
        this.formPropiedad.controls['Localidad'].setValue(garantia.localidad)
        this.formPropiedad.controls['Ubicacion'].setValue(garantia.ubicacion)
        this.formPropiedad.controls['PropiedadHorizontal'].setValue(garantia.horizontal)
        this.formPropiedad.controls['Indivisa'].setValue(garantia.indivisa)
        this.indivisa = garantia.indivisa
        this.formPropiedad.controls['Numero'].setValue(garantia.numero)
        this.formPropiedad.controls['Tomo'].setValue(garantia.tomo)
        this.formPropiedad.controls['TipoFolio'].setValue(garantia.idTipoFolio)
        this.idTipoFolio = garantia.idTipoFolio
        this.idTipoGarantia = garantia.tipoGarantia.id
        this.mostrarPropiedad = true;
        this.mostrarPersona = false;
        this.mostrarFianza = false
        break;
      }
      case TiposGarantia.Fianza: {
        this.formTipoGarantia.controls['TipoGarantia'].setValue(garantia.tipoGarantia.id)
        this.formFianza.controls['Cuit'].setValue(garantia.cuitCuil)
        this.formFianza.controls['RazonSocial'].setValue(garantia.razonSocial)
        this.idTipoGarantia = garantia.tipoGarantia.id
        this.idTipoFolio = TiposFolios.SinFolio
        this.mostrarFianza = true
        this.mostrarPersona = false;
        this.mostrarPropiedad = false;
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  trackByItems(index: number, item: any): any { return item; }

}
