import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { isFalsy } from 'utility-types';
@Component({
  selector: 'app-file-upload-multiple',
  templateUrl: './file-upload-multiple.component.html',
  styleUrls: ['./file-upload-multiple.component.css'],
})
export class FileUploadMultipleComponent {
  @Input() mostrar: boolean = true
  @Output() eventoArchivo = new EventEmitter<ArchivoDto[]>();
  files: File[] = [];
  archivoDto: ArchivoDto = new ArchivoDto
  lstArchivosDto: ArchivoDto[] = []
  constructor(
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
  }

  fileBrowseHandler(event: any): void {
    const target = event.target as HTMLInputElement;
    var files = target.files as FileList;
    this.fileList(files)
  }

  fileList(files: FileList) {
    const max_size = 35000000;
    const allowed_types = ['image/png', 'image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    // const image_types = ['image/png', 'image/png', 'image/jpeg'];
    // const max_height = 15200;
    // const max_width = 25600;

    for (var i = 0; i < files.length; i++) {
      var element = files[i];

      if (!isFalsy(element)) {
        for (const i in allowed_types) {
          if (allowed_types[i] == element.type && element.size < max_size) {
            this.files.push(element as File);
          }
        }
      }
    };

    if (this.files.length >= 1)
      this.convertFileToBase64(this.files)
  }


  cancelUpload() {
    this.archivoDto = new ArchivoDto
    this.archivoDto.descripcion = ""
    this.archivoDto.nombre = ""
    if (this.lstArchivosDto.length >= 1)
      this.eventoArchivo.emit(this.lstArchivosDto);
  }

  onFileDropped(event: any) {
    this.fileList(event)
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.convertFileToBase64(this.files)
  }

  convertFileToBase64(theFile: any) {
    this.archivoDto.descripcion = theFile.name;
    this.archivoDto.nombre = theFile.name;
    this.archivoDto.contentType = theFile.type;
    let reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsDataURL(theFile);
  }


  handleReaderLoaded(e: any) {
    let reader = e.target;
    this.archivoDto.content = reader.result
  }

  formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  trackByItems(index: number, item: any): any { return item; }
}
