<mat-form-field>
    <ngx-spinner [zIndex]="1" name="spBusqueda" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
    <mat-label>Forma Pago</mat-label>
    <input type="search" placeholder="Buscar por nombre." aria-label="Number" matInput [matAutocomplete]="auto" [formControl]="entityControl" />
    <button disabled mat-icon-button matSuffix>
      <mat-icon>{{'search'}}</mat-icon>
  </button>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let item of filteredFormaPago | async ;let i=index;trackBy:trackByItems" [value]="item.descripcion" (click)="selectValue(item)" (onSelectionChange)="selectValue(item)">
            <span [matTooltip]="item.descripcion ">{{item.descripcion | titlecase}}</span>
        </mat-option>
    </mat-autocomplete>
    <button mat-icon-button matSuffix matTooltip="Limpiar" *ngIf="entitySelect" (click)="selectValue(null)">
      <mat-icon>clear</mat-icon>
      </button>
</mat-form-field>
