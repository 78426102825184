<div class="row content">
    <div class="col-sm-10">
        <app-btn-new-garantia *appRole="['MisGarantias.Ver']"></app-btn-new-garantia>
    </div>
</div>
<br>
<div class="row content">
    <div class="col-sm-12">
        <mat-card>
            <router-outlet></router-outlet>
        </mat-card>
    </div>
</div>
