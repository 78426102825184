export class PropiedadFilter{

  public idsTipoPropiedad!:number[] | null
  public idsPropiedadClase!:number[] | null
  public idsEstadoPropiedad!:number[] | null
  public idsTipoFolio!:number| null
  public idLocalidad!:number| null
  public ubicacion!: string| null
  public nroFolio!:string| null
  public activo !: boolean| null
  public horizontal !: boolean| null
  public fechaAltaDesde!: string| null
  public FechaAltaHasta!: string| null
}
