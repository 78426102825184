import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared.module';
import { DialogBonificacionComponent } from '../views/bonificaciones/dialog-bonificacion/dialog-bonificacion.component';
import { BtnNuevoBonificacionComponent } from '../views/bonificaciones/btn-nuevo-bonificacion/btn-nuevo-bonificacion.component';
import { BonificacionesComponent } from '../views/bonificaciones/bonificaciones/bonificaciones.component';



@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    DialogBonificacionComponent,
    BtnNuevoBonificacionComponent,
    BonificacionesComponent,
  ],
  exports: [
    DialogBonificacionComponent,
    BtnNuevoBonificacionComponent,
    BonificacionesComponent,
  ],
  providers: [
  ],
})
export class BonificacionesModule { }
