import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateCaracteristicaRequest } from '@models/caracteristica-propiedad/createCaracteristicaRequest.model';
import { UpdateCaracteristicaRequest } from '@models/caracteristica-propiedad/updateCaracteristicaRequest.model';
import { isFalsy } from 'utility-types';
import { CaracteristicaPropiedad } from '@models/propiedades/caracteristicaPropiedad.model';
import { PropiedadCaracteristicaPropiedadDto } from '@models/propiedades/propiedadCaracteristicaPropiedadDto.model';
import { AsignarCaracteristicaPropiedadResponse } from '@models/propiedades/asignarCaracteristicaPropiedadResponse.mode';
import { UpdateCaracteristicaPropiedadRequest } from '@models/propiedades/updateCaracteristicaPropiedadRequest.model';
import { CreateCaracteristicaPropiedadRequest } from '@models/propiedades/createCaracteristicaPropiedadRequest.model';
import { MessageSuccessfullDto } from '@models/base/MessageSuccessfullDto.model';

@Injectable({
  providedIn: 'root'
})
export class CaracteristicasPropiedadService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http:HttpClient)
  {
  }

  create(createCaracteristicaRequest:CreateCaracteristicaRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/CaracteristicaPropiedad/Create",createCaracteristicaRequest);
  }

  update(updateCaracteristicaRequest:UpdateCaracteristicaRequest):Observable<any>
  {
    return  this.http.put<any>("/api/CaracteristicaPropiedad/Update",updateCaracteristicaRequest);
  }

  getAll():Observable<BaseDto[]>
  {
    return  this.http.get<BaseDto[]>("/api/CaracteristicaPropiedad/GetAll");
  }

  getById(id:number):Observable<BaseDto>
  {
    return  this.http.get<BaseDto>("/api/CaracteristicaPropiedad/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/CaracteristicaPropiedad/Delete?id=" + id);
    else
      return of(null)
  }

  createPropiedadCaracteristicaPropiedad(createCaracteristicaPropiedadRequest: CreateCaracteristicaPropiedadRequest): Observable<AsignarCaracteristicaPropiedadResponse> {
    return this.http.post<AsignarCaracteristicaPropiedadResponse>("/api/Propiedad/CreateCaracteristicaPropiedad", createCaracteristicaPropiedadRequest);
  }

  updatePropiedadCaracteristicaPropiedad(updateCaracteristicaPropiedadRequest: UpdateCaracteristicaPropiedadRequest): Observable<MessageSuccessfullDto> {
    return this.http.post<MessageSuccessfullDto>("/api/Propiedad/UpdateCaracteristicaPropiedad", updateCaracteristicaPropiedadRequest);
  }

  getAllCaracteristicasPropiedad(): Observable<CaracteristicaPropiedad[]> {
    return this.http.get<CaracteristicaPropiedad[]>("/api/Propiedad/GetAllCaracteristicasPropiedad");
  }

  getAllCaracteristicasPropiedadByIdPropiedad(idPropiedad: number): Observable<PropiedadCaracteristicaPropiedadDto[]> {
    return this.http.get<PropiedadCaracteristicaPropiedadDto[]>("/api/Propiedad/GetAllCaracteristicasPropiedadByIdPropiedad?idPropiedad=" + idPropiedad);
  }
}
