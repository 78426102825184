<div *appRole="['Servicios.Ver']">

  <div class="row">
    <div class="col-lg-12">
        <app-btn-create-servicio *appRole="['Servicios.Crear']"></app-btn-create-servicio>&nbsp;
        <app-btn-nuevo-tipo-servicio *appRole="['TiposServicio.Crear']"></app-btn-nuevo-tipo-servicio>&nbsp;
        <app-btn-dialog-actualiza-montos-broches *appRole="['Servicios.Crear']"></app-btn-dialog-actualiza-montos-broches>&nbsp;
        <app-btn-importar-detalles-servicio-propiedad *appRole="['ImportarDetalleServicios.Crear']"></app-btn-importar-detalles-servicio-propiedad>&nbsp;
    </div>
</div>

<br><br>
<mat-card *appRole="['Servicios.Ver']">
    <mat-form-field class="example-full-width">
        <mat-label>Buscar</mat-label>
        <input matInput type="search" (keyup)="applyFilter($event)">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <br>
    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
    <table mat-table [dataSource]="dataSource" [hidden]=!data matSort matSortStart="asc" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->


        <!-- # -->
        <!-- <ng-container matColumnDef="id">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> # </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let servicio">
                {{servicio.id}}
            </mat-cell>
        </ng-container> -->

        <!-- Tipo Servicio Column -->
        <ng-container matColumnDef="tipoServicio">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Servicios </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let servicio">
                <a [routerLink]="['/tiposservicios']">{{servicio.tipoServicio.descripcion}}</a>
            </mat-cell>
        </ng-container>

        <!-- Broche  Column -->
        <ng-container matColumnDef="broche">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Broche </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let servicio">
                <mat-checkbox disabled [checked]="servicio.broche"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <!-- Nro. Broche Column -->
        <ng-container matColumnDef="nroBroche">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Broche </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let servicio"> {{servicio.nroBroche}} </mat-cell>
        </ng-container>

        <!-- Accion Column -->
        <ng-container matColumnDef="accion">
          <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let servicio" class="action-link">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
              <mat-menu #menu="matMenu">
                  <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                  <mat-icon>visibility</mat-icon>
                  <span>Ver</span>
                </button> -->
                  <button *appRole="['Servicios.Modificar']" mat-menu-item [routerLink]="['/servicios/servicio/editar/',servicio.id]" >
                  <mat-icon>edit</mat-icon>
                  <span>Editar</span>
                </button>
                  <button mat-menu-item *appRole="['Servicios.Eliminar']" (click)="openDialogDelete('Eliminar',servicio)">
                  <mat-icon>delete</mat-icon>
                  <span>Eliminar</span>
                </button>
              </mat-menu>
          </mat-cell>
      </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


    </table>
    <div class="no-data-table">
        <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
    </div>
    <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="10" [pageSizeOptions]="[5, 10, 20]">
    </mat-paginator>
</mat-card>
</div>
