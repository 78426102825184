import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateSettlementResponse } from '@models/propietarios/createSettlementResponse.model';
import { DialogGenerarLiquidacionesComponent } from '../dialog-generar-liquidaciones/dialog-generar-liquidaciones.component';

@Component({
  selector: 'app-liquidaciones',
  templateUrl: './liquidaciones.component.html',
  styleUrls: ['./liquidaciones.component.css']
})
export class LiquidacionesComponent implements OnInit {
  createSettlementResponse: CreateSettlementResponse[] = [];

  constructor(
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {

  }

  openDialogNuevaLiquidacion(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;

    const dialogRef = this.dialog.open(DialogGenerarLiquidacionesComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.createSettlementResponse = result
    });
  }
}
