<mat-form-field [class]="width">
    <ngx-spinner [zIndex]="1" name="spBusquedaContratos" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
    <mat-label>Contratos</mat-label>
    <input type="search" placeholder="Buscar por Nro. o Prop. o Inq." aria-label="Busqueda" matInput [matAutocomplete]="auto" [formControl]="entityControl" />
    <button disabled mat-icon-button matSuffix>
      <mat-icon>{{'search'}}</mat-icon>
  </button>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let item of elementFiltered | async;let i=index;trackBy:trackByItems" [value]="item.descripcion" (click)="selectValue(item)" (onSelectionChange)="selectValue(item)">
            <span [matTooltip]="item.descripcion">{{item.descripcion | titlecase}}</span>
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="entityControl.invalid && entityControl.touched">
      {{ esRequerido ? 'Seleccionar un contrato.' : '' }}
    </mat-error>
    <button mat-icon-button matSuffix *ngIf="entitySelect" matTooltip="Ver" (click)="verContrato(entitySelect)">
      <mat-icon color="primary">info</mat-icon>
      </button>

    <button mat-icon-button matSuffix matTooltip="Limpiar" (click)="selectValue(null)">
<mat-icon>clear</mat-icon>
</button>

</mat-form-field>
