import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CaracteristicaPropiedad } from '@models/propiedades/caracteristicaPropiedad.model';
import { PropiedadCaracteristicaPropiedadDto } from '@models/propiedades/propiedadCaracteristicaPropiedadDto.model';
import { PropiedadesService } from '@services/propiedades/propiedades.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { CaracteristicasPropiedadService } from '@services/caracteristicas-propiedad/caracteristicas-propiedad.service';

@Component({
  selector: 'app-update-dialog-caracteristica-propiedad',
  templateUrl: './update-dialog-caracteristica-propiedad.component.html',
  styleUrls: ['./update-dialog-caracteristica-propiedad.component.css']
})
export class UpdateDialogCaracteristicaPropiedadComponent implements OnInit {

  lstCaracteristicasPropiedad: CaracteristicaPropiedad[] = []
  formInstance: FormGroup;
  idPropiedad: number =0;
  formBuilder: FormBuilder = new FormBuilder;

  constructor(
    public dialogRef: MatDialogRef<UpdateDialogCaracteristicaPropiedadComponent>,
    private caracteristicasPropiedadService: CaracteristicasPropiedadService,
    private aRoute: ActivatedRoute,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: PropiedadCaracteristicaPropiedadDto) {

    this.formInstance = this.formBuilder.group({
      Caracteristica: ['', Validators.compose([Validators.required])],
      Cantidad: ['', Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
    this.getAllCaracteristicas()
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idPropiedad = Number(routeParams.get('id'));

    this.formInstance.controls["Caracteristica"].setValue(this.data.caracteristica.id)
    this.formInstance.controls["Caracteristica"].disable()
    this.formInstance.controls["Cantidad"].setValue(this.data.cantidad)
  }

  save(): void {
    const propiedadCaracteristicaPropiedadDto = new  PropiedadCaracteristicaPropiedadDto
    propiedadCaracteristicaPropiedadDto.id = this.data.id
    propiedadCaracteristicaPropiedadDto.caracteristica.id = this.formInstance.controls["Caracteristica"].value
    propiedadCaracteristicaPropiedadDto.cantidad = this.formInstance.controls["Cantidad"].value
    this.dialogRef.close({data:propiedadCaracteristicaPropiedadDto, event:AccionesDialog.Agregar});
  }

  getAllCaracteristicas() {
    this.spinner.show("spCaracteristica");
    this.caracteristicasPropiedadService.getAllCaracteristicasPropiedad().subscribe(
      data => {
        this.spinner.hide("spCaracteristica");
        this.lstCaracteristicasPropiedad = data
      },
      error => {
        this.spinner.hide("spCaracteristica");
        this.snackBar.showError( error, "Error");
      }
    )
  }

  trackByItems(index: number, item: any): any { return item; }
}
