import { BaseDto } from "@models/base/baseDto.model"
import { TipoAjusteContratoDto } from "@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model"

export class IndiceAjusteContratoDto extends BaseDto{
  public valor: number =0
  public fechaAlta!: string
  public fecha!: string
  public fechaModificacion!: string
  public fechaBaja!: string
  public activo!: boolean
  public tipoAjusteContrato!: TipoAjusteContratoDto
}

