import { PeriodoContratoDetailDto } from "../periodos-contrato/periodoContratoDetailDto.model";
import { MovimientoDetailDto } from "../movimiento/movimientoDetailDto.model";
import { DetalleServicioPropiedadDetailDto } from "../servicios/detalle-servicio-propiedad/detalleServicioPropiedadDetailDto.model";
import { DeudaPendienteInquilinoDto } from "./deudaPendienteInquilinoDto.model";

export class GetPendingDebtInquilinoResponse{
  public detallesServiciosPropiedad: DetalleServicioPropiedadDetailDto[] = []
  public periodosContrato: PeriodoContratoDetailDto[]= []
  public movimientos: MovimientoDetailDto[]= []
}
