import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CasoUsoDto } from '@models/seguridad/casosUso/casoUsoDto.model';
import { PermisoDto } from '@models/seguridad/permisos/permisoDto.model';
import { UpdatePermisoRequest } from '@models/seguridad/permisos/updatePermisoRequest.motel';
import { RolDto } from '@models/seguridad/roles/rolDto.model';
import { CasosUsoService } from '@services/seguridad/casos-uso/casos-uso.service';
import { PermisosService } from '@services/seguridad/permisos/permisos.service';
import { RolesService } from '@services/seguridad/roles/roles.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogUpdatePermisoComponent } from './dialog-update-permiso/dialog-update-permiso.component';

@Component({
  selector: 'app-actualizar-permiso',
  templateUrl: './actualizar-permiso.component.html',
  styleUrls: ['./actualizar-permiso.component.css']
})
export class ActualizarPermisoComponent implements OnInit {

  formBuilder = new FormBuilder;
  form: any;
  permisoDto!:PermisoDto;
  casosUso!:CasoUsoDto[]
  roles!:RolDto[]
  casoUsoDto!:CasoUsoDto
  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogUpdatePermisoComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private casosUsoService: CasosUsoService,
    private rolesService: RolesService,
    private permisosService: PermisosService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
      Token: ["", Validators.compose([Validators.required,])],
      CasoUso: ["", Validators.compose([Validators.required])],
      Rol: ["", Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
    this.getAllRol()
    this.getAllCasosUso()
    this.getById(this.data.id)
  }


  getAllRol() {
    this.spinner.show("spBusquedaRoles")
    this.rolesService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaRoles")
          let rolDto = new RolDto()
          rolDto.id = 0
          rolDto.descripcion = "Sin Rol"
          data.push(rolDto)
          this.roles = data
        },
        error => {
          this.spinner.hide("spBusquedaRoles")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getAllCasosUso() {
    this.spinner.show("spBusquedaCasosUso")
    this.casosUsoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaCasosUso")
          let casoUsoVacio = new CasoUsoDto()
          casoUsoVacio.id = 0
          casoUsoVacio.descripcion = "Sin caso uso"
          data.push(casoUsoVacio)
          this.casosUso = data
        },
        error => {
          this.spinner.hide("spBusquedaCasosUso")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getById(id: number) {
    this.spinner.show("spVerPermiso")
    this.permisosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spVerPermiso")
          this.permisoDto = data
          this.seteaForm(data)
        },
        error => {
          this.spinner.hide("spVerPermiso")
          this.snackBar.showError(error, "Error");
        }
      )
  }


  doAction() {

    let updatePermisoRequest: UpdatePermisoRequest = new UpdatePermisoRequest
    updatePermisoRequest.id = this.casoUsoDto.id
    updatePermisoRequest.descripcion = this.form.controls["Descripcion"].value
    updatePermisoRequest.id_CasoUso = this.form.controls["CasoUso"].value
    updatePermisoRequest.token = this.form.controls["Token"].value
    updatePermisoRequest.id_Rol = this.form.controls["Rol"].value
    this.update(updatePermisoRequest)
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  update(updatePermisoRequest: UpdatePermisoRequest) {
    this.spinner.show("spModificarPermiso")
    this.permisosService.update(updatePermisoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spModificarPermiso")
          this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Modificar });
        },
        error => {
          this.spinner.hide("spModificarPermiso")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  seteaForm(permisoDto: PermisoDto) {
    this.form.controls["Descripcion"].setValue(permisoDto.descripcion)
    this.form.controls["Token"].setValue(permisoDto.token)
    this.form.controls["CasoUso"].setValue(isFalsy(permisoDto.casoUso)? 0 : permisoDto.casoUso.id)
    this.form.controls["Rol"].setValue(isFalsy(permisoDto.rol)? 0 :permisoDto.rol.id)
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}
