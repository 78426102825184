import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { EstadosDetalleServicioPropiedad, RangoFechasValores } from '@models/base/identificadores.model';
import { ContadorDetalleServicioPropiedadDto } from '@models/dashboard/contadorDetalleServicioPropiedadDto.model';
import { ContadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto } from '@models/dashboard/contadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto.model';
import { ContadorPropiedadDto } from '@models/dashboard/contadorPropiedadDto.model';
import { ContadorServiciosPorEstadoDetalleServicioPropiedad } from '@models/dashboard/contadorServiciosPorEstadoDetalleServicioPropiedad.mode';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

export class EstadosServiciosPorTipo {
  public servicio!: string
  public cantidadDeuda: number=0
  public cantidadCobrado: number=0
  public cantidadNoVencida: number =0
  public cantidadPagada: number =0
}
@Component({
  selector: 'app-dashboard-propiedades',
  templateUrl: './dashboard-propiedades.component.html',
  styleUrls: ['./dashboard-propiedades.component.css'],
  providers: [DatePipe]
})
export class DashboardPropiedadesComponent implements OnInit {
  contadorPropiedadDto!: ContadorPropiedadDto;
  formBuilder: FormBuilder = new FormBuilder;
  form: FormGroup;
  contadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto!: ContadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto;
  contadorServiciosPorEstadoDetalleServicioPropiedad!: ContadorServiciosPorEstadoDetalleServicioPropiedad;
  estadosDetalleServicioPropiedad = EstadosDetalleServicioPropiedad
  lstEstadosServiciosPorTipo!: EstadosServiciosPorTipo[]

  constructor(
    private dashboardService: DashboardService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private datepipe: DatePipe
  ) {

    this.form = this.formBuilder.group({
      FechaDesde: [new Date((new Date().getTime() - RangoFechasValores.Bimestral)), Validators.compose([Validators.required,])],
      FechaHasta: [new Date(new Date().getTime()), Validators.compose([Validators.required,])],
    })
  }

  ngOnInit(): void {
    let fechaDesde = this.datepipe.transform(new Date().getTime() - RangoFechasValores.Bimestral, 'yyyy-MM-dd') as string;
    let fechaHasta = this.datepipe.transform(new Date().getTime() + RangoFechasValores.Bimestral, 'yyyy-MM-dd') as string;
    this.getContadorDetalleServicioPropiedadFechaDesdeHastaPeriodo(fechaDesde, fechaHasta)
    this.getContadorDetalleServicioPropiedadPorEstadoYServicio(fechaDesde, fechaHasta)
    this.getContadorPropiedades()
  }

  filtrarDetallesServiciosPropiedad() {
    let fechaDesde = this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;
    let fechaHasta = this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;
    // Ajustar la hora para la fecha desde
    fechaDesde = fechaDesde + ' 00:00:00';
    // Ajustar la hora para la fecha hasta
    fechaHasta = fechaHasta + ' 23:59:59';
    this.getContadorDetalleServicioPropiedadFechaDesdeHastaPeriodo(fechaDesde, fechaHasta)
    this.getContadorDetalleServicioPropiedadPorEstadoYServicio(fechaDesde, fechaHasta)
  }

  private getContadorPropiedades() {
    this.spinner.show("spGetContadorPropiedades")
    this.dashboardService.getContadorPropiedades().subscribe(
      data => {
        this.spinner.hide("spGetContadorPropiedades")
        this.contadorPropiedadDto = data
      },
      error => {
        this.spinner.hide("spGetContadorPropiedades")
        this.snackBar.showError(error, "Error");
      })
  }

  private getContadorDetalleServicioPropiedadFechaDesdeHastaPeriodo(fechaDesde: string, fechaHasta: string) {
    this.spinner.show("spGetContadorDetalleServicioPropiedadFechaDesdeHastaPeriodo")
    this.dashboardService.getContadorDetalleServicioPropiedadFechaDesdeHastaPeriodo(fechaDesde, fechaHasta).subscribe(
      data => {
        this.spinner.hide("spGetContadorDetalleServicioPropiedadFechaDesdeHastaPeriodo")
        this.contadorDetalleServicioPropiedadFechaDesdeHastaPeriodoDto = data
      },
      error => {
        this.spinner.hide("spGetContadorDetalleServicioPropiedadFechaDesdeHastaPeriodo")
        this.snackBar.showError(error, "Error");
      })
  }

  private getContadorDetalleServicioPropiedadPorEstadoYServicio(fechaDesde: string, fechaHasta: string) {
    this.spinner.show("spGetContadorDetalleServicioPropiedadPorEstadoYServicio")
    this.dashboardService.getContadorDetalleServicioPropiedadPorEstadoYServicio(fechaDesde, fechaHasta).subscribe(
      data => {
        this.spinner.hide("spGetContadorDetalleServicioPropiedadPorEstadoYServicio")
        this.contadorServiciosPorEstadoDetalleServicioPropiedad = data

        this.lstEstadosServiciosPorTipo = []
        let estadosServiciosPorTipo = new EstadosServiciosPorTipo
        data.serviciosPorEstadoDetalleServicioPropiedad.forEach((element, index) => {

          if (element.servicio.tipoServicio.descripcion == estadosServiciosPorTipo.servicio) {
            estadosServiciosPorTipo.servicio = element.servicio.tipoServicio.descripcion
            switch (element.estado.id) {
              case EstadosDetalleServicioPropiedad.Cobrado:
                estadosServiciosPorTipo.cantidadCobrado = element.cantidad
                break;
              case EstadosDetalleServicioPropiedad.Pagado:
                estadosServiciosPorTipo.cantidadPagada = element.cantidad
                break;
              case EstadosDetalleServicioPropiedad.NoVencido:
                estadosServiciosPorTipo.cantidadNoVencida = element.cantidad
                break;
              case EstadosDetalleServicioPropiedad.Deuda:
                estadosServiciosPorTipo.cantidadDeuda = element.cantidad
                break;
              default:
                break;
            }
          }
          else {
            if (index == 0) {
              estadosServiciosPorTipo = new EstadosServiciosPorTipo
              estadosServiciosPorTipo.servicio = element.servicio.tipoServicio.descripcion
              switch (element.estado.id) {
                case EstadosDetalleServicioPropiedad.Cobrado:
                  estadosServiciosPorTipo.cantidadCobrado = element.cantidad
                  break;
                case EstadosDetalleServicioPropiedad.Pagado:
                  estadosServiciosPorTipo.cantidadPagada = element.cantidad
                  break;
                case EstadosDetalleServicioPropiedad.NoVencido:
                  estadosServiciosPorTipo.cantidadNoVencida = element.cantidad
                  break;
                case EstadosDetalleServicioPropiedad.Deuda:
                  estadosServiciosPorTipo.cantidadDeuda = element.cantidad
                  break;
                default:
                  break;
              }

            }
            else {
              this.lstEstadosServiciosPorTipo.push(estadosServiciosPorTipo)
              estadosServiciosPorTipo = new EstadosServiciosPorTipo
              estadosServiciosPorTipo.servicio = element.servicio.tipoServicio.descripcion
              switch (element.estado.id) {
                case EstadosDetalleServicioPropiedad.Cobrado:
                  estadosServiciosPorTipo.cantidadCobrado = element.cantidad
                  break;
                case EstadosDetalleServicioPropiedad.Pagado:
                  estadosServiciosPorTipo.cantidadPagada = element.cantidad
                  break;
                case EstadosDetalleServicioPropiedad.NoVencido:
                  estadosServiciosPorTipo.cantidadNoVencida = element.cantidad
                  break;
                case EstadosDetalleServicioPropiedad.Deuda:
                  estadosServiciosPorTipo.cantidadDeuda = element.cantidad
                  break;
                default:
                  break;
              }
            }
          }
          if (data.serviciosPorEstadoDetalleServicioPropiedad.length -1 == index) {
            estadosServiciosPorTipo = new EstadosServiciosPorTipo
            estadosServiciosPorTipo.servicio = element.servicio.tipoServicio.descripcion
            switch (element.estado.id) {
              case EstadosDetalleServicioPropiedad.Cobrado:
                estadosServiciosPorTipo.cantidadCobrado = element.cantidad
                break;
              case EstadosDetalleServicioPropiedad.Pagado:
                estadosServiciosPorTipo.cantidadPagada = element.cantidad
                break;
              case EstadosDetalleServicioPropiedad.NoVencido:
                estadosServiciosPorTipo.cantidadNoVencida = element.cantidad
                break;
              case EstadosDetalleServicioPropiedad.Deuda:
                estadosServiciosPorTipo.cantidadDeuda = element.cantidad
                break;
              default:
                break;
            }
            this.lstEstadosServiciosPorTipo.push(estadosServiciosPorTipo)
          }
        });
      },
      error => {
        this.spinner.hide("spGetContadorDetalleServicioPropiedadPorEstadoYServicio")
        this.snackBar.showError(error, "Error");
      })
  }

  trackByItems(index: number, item: any): any { return item; }

}
