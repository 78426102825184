
export class ContratoFilter {
  public idsTipoContrato?:number[] | null
  public idsTipoLiquidacion?:number[] | null
  public idsFormaPagoPropietario?:number[] | null
  public recindido?:boolean | null
  public finalizado?:boolean | null
  public idPropietario?:number | null
  public idPropiedad?:number | null
  public idContrato?:number | null
  public idInquilino?:number | null
  public numeroContrato?:number | null
  public idsEstadoContrato?:number[] | null
  public fechaComienzoDesde?:string | null
  public fechaComienzoHasta?:string | null
  public idsTipoAjusteContrato?:number[] | null
}
