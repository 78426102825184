import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, QuienPaga } from '@models/base/identificadores.model';
import { CreateDetalleServicioPropiedadRequest } from '@models/servicios/detalle-servicio-propiedad/createDetalleServicioPropiedadRequest.model';
import { CreateDetalleServicioPropiedadResponse } from '@models/servicios/detalle-servicio-propiedad/createDetalleServicioPropiedadResponse.model';
import { DetalleServicioPropiedadDto } from '@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadDto.model';
import { ServicioPropiedadDto } from '@models/servicios/servicio-propiedad/servicioPropiedadDto.model';
import { DetalleServicioPropiedadService } from '@services/servicios/detalle-servicios-propiedad/detalle-servicio-propiedad.service';
import { ServiciosPropiedadService } from '@services/servicios/servicios-propiedad/servicios-propiedad.service';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { QuienPagaService } from '@services/quien-paga/quien-paga.service';
import { DetalleServicioPropiedadController } from '@controllers/detalleServicioPropiedad.controller';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-dialog-create-detalle-servicio',
  templateUrl: './dialog-create-detalle-servicio.component.html',
  styleUrls: ['./dialog-create-detalle-servicio.component.css']
})
export class DialogCreateDetalleServicioComponent implements OnInit {

  @Input() idPropiedad!: any
  formDetalleServicioPriopidad: any;
  formBuilder: FormBuilder = new FormBuilder;
  sub: any;
  idQuienPaga!: number;
  idServicio!: number;
  valorComision!: number;
  enableComision: any;
  servicio!: ServicioPropiedadDto;
  idDetalleServicioPropiedad!: CreateDetalleServicioPropiedadResponse;
  get f() { return this.formDetalleServicioPriopidad.controls; }
  lstQuienPaga: BaseDto[] = []
  lstPeriocidad: BaseDto[] = [
    {
      id: 0,
      descripcion: "Unitaria"
    },
    {
      id: 1,
      descripcion: "Mensual"
    },
    {
      id: 2,
      descripcion: "Bimestral"
    },
    {
      id: 3,
      descripcion: "Trimestral"
    },
  ]
  lstservicioPropiedadDto: ServicioPropiedadDto[] = []
  comision: any
  constructor(
    public dialogRef: MatDialogRef<DialogCreateDetalleServicioComponent>,
    private serviciosPropiedadService: ServiciosPropiedadService,
    private serviciosService: ServiciosService,
    private snackBar: SnackBarService,
    private quienPagaService: QuienPagaService,
    private spinner: NgxSpinnerService,
    private detalleServicioPropiedadService: DetalleServicioPropiedadService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formDetalleServicioPriopidad = this.formBuilder.group({
      //Persona
      QuienPaga: ["", Validators.compose([Validators.required])],
      Servicios: ["", Validators.compose([Validators.required])],
      Mes: ["", Validators.compose([Validators.required])],
      Anio: ["", Validators.compose([Validators.required])],
      Periocidad: ["", Validators.compose([Validators.required])],
      Comision: [""],
      Periodo: [""],
      Vencimiento1: ["", Validators.compose([Validators.required])],
      ImporteVencimiento1: ["", Validators.compose([Validators.required])],
      Vencimiento2: [""],
      ImporteVencimiento2: [""],
      Vencimiento3: [""],
      ImporteVencimiento3: [""],
      Vencimiento4: [""],
      ImporteVencimiento4: [""],
    },
      {

      });

    this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].disable();
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].disable();
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].disable();
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].disable();
  }

  ngOnInit() {
    this.idPropiedad = this.data
    this.getAllQuienPaga()
    this.getServiciosPropiedadByIdPropiedad(this.idPropiedad);
    let anioActual = new Date().getFullYear()
    let mesActual = new Date().getMonth() + 1
    this.formDetalleServicioPriopidad.controls["Anio"].setValue(anioActual)
    this.formDetalleServicioPriopidad.controls["Mes"].setValue(mesActual)
    this.formDetalleServicioPriopidad.controls["Periocidad"].setValue(0)
    this.formDetalleServicioPriopidad.controls["QuienPaga"].setValue(QuienPaga.SegunContrato)
  }

  closeDialog() {
    this.dialogRef.close({ data: false, event: AccionesDialog.Cancelar });
  }

  onVencimiento1Change(event: any, vencimiento: number) {
    const selectedDate = (event.target as HTMLInputElement).value;
    let vencimientoFecha= "Vencimiento"+vencimiento
    let importeVencimiento= "ImporteVencimiento"+vencimiento
    this.formDetalleServicioPriopidad.controls[vencimientoFecha].setValue(selectedDate);
        if (selectedDate) {
          this.formDetalleServicioPriopidad.controls[importeVencimiento].enable();
        } else {
          this.formDetalleServicioPriopidad.controls[importeVencimiento].disable();
        }
  }

  agregarDetalle() {
    let lstCDSPR: CreateDetalleServicioPropiedadRequest = new CreateDetalleServicioPropiedadRequest
    let periocidad = this.formDetalleServicioPriopidad.controls["Periocidad"].value

    switch (periocidad) {
      //Unitaria
      case 0:
        let cDSPR: DetalleServicioPropiedadDto = new DetalleServicioPropiedadDto

        cDSPR.idServicioPropiedad = this.formDetalleServicioPriopidad.controls["Servicios"].value
        cDSPR.id_QuienPaga = this.formDetalleServicioPriopidad.controls["QuienPaga"].value
        cDSPR.comision = this.formDetalleServicioPriopidad.controls["Comision"].value
        cDSPR.periodoMes = this.formDetalleServicioPriopidad.controls["Mes"].value
        cDSPR.periodoAnio = this.formDetalleServicioPriopidad.controls["Anio"].value
        cDSPR.periodo = this.formDetalleServicioPriopidad.controls["Periodo"].value
        cDSPR.fechaVencimiento1 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento1"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento1"].value
        cDSPR.fechaVencimiento2 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento2"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento2"].value
        cDSPR.fechaVencimiento3 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento3"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento3"].value
        cDSPR.fechaVencimiento4 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento3"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento3"].value
        cDSPR.importeVencimiento1 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].value)
        cDSPR.importeVencimiento2 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].value)
        cDSPR.importeVencimiento3 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].value)
        cDSPR.importeVencimiento4 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].value)
        lstCDSPR.detallesServiciosPropiedad.push(cDSPR)
        break;
      // Mensual
      case 1:
        let i = parseInt(this.formDetalleServicioPriopidad.controls["Mes"].value)
        let mMensual = 0
        while (i <= 12) {

          let cDSPR: DetalleServicioPropiedadDto = new DetalleServicioPropiedadDto

          cDSPR.idServicioPropiedad = this.formDetalleServicioPriopidad.controls["Servicios"].value
          cDSPR.comision = this.formDetalleServicioPriopidad.controls["Comision"].value == "" ? null : this.formDetalleServicioPriopidad.controls["Comision"].value
          cDSPR.periodoMes = i
          cDSPR.periodo = i.toString()
          cDSPR.id_QuienPaga = this.formDetalleServicioPriopidad.controls["QuienPaga"].value
          cDSPR.periodoAnio = this.formDetalleServicioPriopidad.controls["Anio"].value
          let fecha1 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento1"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento1"].value
          cDSPR.fechaVencimiento1 = this.sumarMesesAFecha(fecha1, mMensual)
          let fecha2 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento2"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento2"].value
          cDSPR.fechaVencimiento2 = this.sumarMesesAFecha(fecha2, mMensual)
          let fecha3 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento3"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento3"].value
          cDSPR.fechaVencimiento3 = this.sumarMesesAFecha(fecha3, mMensual)
          let fecha4 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento4"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento4"].value
          cDSPR.fechaVencimiento4 = this.sumarMesesAFecha(fecha4, mMensual)

          cDSPR.importeVencimiento1 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].value)
          cDSPR.importeVencimiento2 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].value)
          cDSPR.importeVencimiento3 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].value)
          cDSPR.importeVencimiento4 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].value)
          lstCDSPR.detallesServiciosPropiedad.push(cDSPR)
          i++;
          mMensual++
        }
        break;
      //Bimestral
      case 2:
        let j = this.formDetalleServicioPriopidad.controls["Mes"].value
        let mBimestral = 0
        while (j <= 12) {
          let cDSPR: DetalleServicioPropiedadDto = new DetalleServicioPropiedadDto

          cDSPR.idServicioPropiedad = this.formDetalleServicioPriopidad.controls["Servicios"].value
          cDSPR.comision = this.formDetalleServicioPriopidad.controls["Comision"].value == "" ? null : this.formDetalleServicioPriopidad.controls["Comision"].value
          cDSPR.id_QuienPaga = this.formDetalleServicioPriopidad.controls["QuienPaga"].value
          cDSPR.periodoMes = j
          cDSPR.periodo = j.toString()
          cDSPR.periodoAnio = this.formDetalleServicioPriopidad.controls["Anio"].value
          let fecha1 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento1"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento1"].value
          cDSPR.fechaVencimiento1 = this.sumarMesesAFecha(fecha1, mBimestral)
          let fecha2 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento2"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento2"].value
          cDSPR.fechaVencimiento2 = this.sumarMesesAFecha(fecha2, mBimestral)
          let fecha3 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento3"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento3"].value
          cDSPR.fechaVencimiento3 = this.sumarMesesAFecha(fecha3, mBimestral)
          let fecha4 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento4"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento4"].value
          cDSPR.fechaVencimiento4 = this.sumarMesesAFecha(fecha4, mBimestral)

          cDSPR.importeVencimiento1 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].value)
          cDSPR.importeVencimiento2 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].value)
          cDSPR.importeVencimiento3 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].value)
          cDSPR.importeVencimiento4 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].value)
          lstCDSPR.detallesServiciosPropiedad.push(cDSPR)
          j += 2;
          mBimestral += 2
        }
        break;
      //Trimestral
      case 3:
        let k = this.formDetalleServicioPriopidad.controls["Mes"].value
        let mTrimestral = 0
        while (k < 12) {
          let cDSPR: DetalleServicioPropiedadDto = new DetalleServicioPropiedadDto

          cDSPR.idServicioPropiedad = this.formDetalleServicioPriopidad.controls["Servicios"].value
          cDSPR.comision = this.formDetalleServicioPriopidad.controls["Comision"].value == "" ? null : this.formDetalleServicioPriopidad.controls["Comision"].value
          cDSPR.periodoMes = k
          cDSPR.periodo = k.toString()
          cDSPR.periodoAnio = this.formDetalleServicioPriopidad.controls["Anio"].value
          let fecha1 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento1"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento1"].value
          cDSPR.fechaVencimiento1 = this.sumarMesesAFecha(fecha1, mTrimestral)
          let fecha2 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento2"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento2"].value
          cDSPR.fechaVencimiento2 = this.sumarMesesAFecha(fecha2, mTrimestral)
          let fecha3 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento3"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento3"].value
          cDSPR.fechaVencimiento3 = this.sumarMesesAFecha(fecha3, mTrimestral)
          let fecha4 = isFalsy(this.formDetalleServicioPriopidad.controls["Vencimiento4"].value) ? null : this.formDetalleServicioPriopidad.controls["Vencimiento4"].value
          cDSPR.fechaVencimiento4 = this.sumarMesesAFecha(fecha4, mTrimestral)

          cDSPR.importeVencimiento1 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].value)
          cDSPR.importeVencimiento2 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].value)
          cDSPR.importeVencimiento3 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].value)
          cDSPR.importeVencimiento4 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].value) ? null : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].value)
          cDSPR.id_QuienPaga = this.formDetalleServicioPriopidad.controls["QuienPaga"].value
          lstCDSPR.detallesServiciosPropiedad.push(cDSPR)
          k += 3;
          mTrimestral += 3
        }
        break;
      default:
        break;
    }
    let lstDetalleServicioPropiedadDto = DetalleServicioPropiedadController.validaListadoImportesYVencimientos(lstCDSPR.detallesServiciosPropiedad)
    lstCDSPR.detallesServiciosPropiedad = lstDetalleServicioPropiedadDto
    this.create(lstCDSPR)


  }
  sumarMesesAFecha(fecha: string, cantidad: number): string | null {
    if (fecha != "" && fecha != null) {
      let fechaSumaMeses = moment(new Date(fecha)).add(cantidad, 'M').add(1, "days")
      return fechaSumaMeses.format('YYYY-MM-DD')
    }
    else {
      return null
    }
  }



  create(lstCDSPR: CreateDetalleServicioPropiedadRequest) {
    this.spinner.show("spDetServ");
    this.detalleServicioPropiedadService.create(lstCDSPR)
      .subscribe(
        data => {
          this.spinner.hide("spDetServ");
          if (data.ids.length > 0)
          {
            this.snackBar.showSuccess("Detalles de servicios agregado correctamente.", "");
            this.dialogRef.close({ data: true, event: AccionesDialog.Agregar });
          }

          else
            this.snackBar.showInfo("Detalles de servicios duplicados o falla validación.", "Error");
        },
        error => {
          this.spinner.hide("spDetServ");
          this.snackBar.showError(error, "Error");
        }
      )
  }

  checkComision(event: any) {
    this.enableComision = event
    this.valorComision = 0.02
  }

  selectQuienPaga(event: any) {
    this.idQuienPaga = event.value
  }

  selectPeriocidad(event: any) {
    this.idQuienPaga = event.value
  }

  getAllQuienPaga() {
    this.spinner.show("spBusquedaQuienPaga");
    this.quienPagaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaQuienPaga");
          this.lstQuienPaga = data
        },
        error => {
          this.spinner.hide("spBusquedaQuienPaga");
          this.snackBar.showError(error, "Error");
        }
      )
  }

  aplicarComision() {
    let comision = this.formDetalleServicioPriopidad.controls["Comision"].value
    let importeVencimiento1 = this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].value
    let importeVencimiento2 = this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].value
    let importeVencimiento3 = this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].value
    let importeVencimiento4 = this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].value

    if (comision != 0) {
      this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].setValue((comision + 1) * importeVencimiento1)
      this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].setValue((comision + 1) * importeVencimiento2)
      this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].setValue((comision + 1) * importeVencimiento3)
      this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].setValue((comision + 1) * importeVencimiento4)
    }
  }

  getServiciosPropiedadByIdPropiedad(idPropiedad: number) {
    this.spinner.show("spBusquedaServicios");
    this.serviciosPropiedadService.getServiciosPropiedadByIdPropiedad(idPropiedad).subscribe(
      data => {
        this.spinner.hide("spBusquedaServicios");
        this.lstservicioPropiedadDto = data as ServicioPropiedadDto[]
      },
      error => {
        this.spinner.hide("spBusquedaServicios");
        this.snackBar.showError(error, "Error");
      })
  }

  getServiciosPropiedadById(id: number) {
    this.spinner.show("spDetServ");
    this.serviciosPropiedadService.getServicioPropiedadById(id).subscribe(
      async data => {
        this.spinner.hide("spDetServ");
        this.servicio = data as ServicioPropiedadDto
      },
      error => {
        this.spinner.hide("spDetServ");
        this.snackBar.showError(error, "Error");
      })
  }

  trackByItems(index: number, item: any): any { return item; }
}

