import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AccionesDialog } from '@models/base/identificadores.model';
import { NotificacionDto } from '@models/notificaciones/notificacionDto.model';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
  //notificacion!: NotificacionDto
  private notificacion = new Subject<NotificacionDto>()
  enviarNotificacionObservable = this.notificacion.asObservable()


  sendNotificacion(notificacion: NotificacionDto) {
    this.notificacion.next(notificacion)
  }

  clearNotificacion() {
    this.notificacion.next()
  }

  onNotificacion(): Observable<NotificacionDto> {
    return this.notificacion.asObservable();
  }
}
