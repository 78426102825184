import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'contratos-personas',
  templateUrl: './contratos-personas.component.html',
  styleUrls: ['./contratos-personas.component.css']
})
export class ContratosPersonasComponent implements OnInit {

  subMenuActive:boolean=false;
  personaDetailDto!: PersonaDetailDto;

  constructor(
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.subMenuActive=true;
  }
  goToFormPersona(){
    this.router.navigate(["/personas/editar"])
  }
  goToFormPropietario(){
    this.router.navigate(["/propietario"])
  }
  goToFormGarantia(){
    this.router.navigate(["/garantia"])
  }
  goToFormContrato(){
    this.router.navigate(["/contratos-personas"])
  }
  goToFormInquilino(){
    this.router.navigate(["/inquilino"])
  }
  goToFormDocumento(){
    this.router.navigate(["/documentos-personas"])
  }

}
