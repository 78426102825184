import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContadorContratoDto } from '@models/dashboard/contadorContratoDto.model';
import { ContadorContratoPorPropiedadClaseDto } from '@models/dashboard/contadorContratoPorPropiedadClaseDto.model';
import { ContratoPorVencerDto } from '@models/dashboard/contratoPorVencerDto.model';
import { NotificacionDto } from '@models/notificaciones/notificacionDto.model';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { NotificacionesService } from '@services/notificaciones/notificaciones.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { ContratoBasicDto } from '@models/contrato/contratoBasicDto.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { ContratoSummaryDto } from '@models/contrato/contratoSummaryDto.mode';

@Component({
  selector: 'app-dashboard-contratos',
  templateUrl: './dashboard-contratos.component.html',
  styleUrls: ['./dashboard-contratos.component.css']
})
export class DashboardContratosComponent implements OnInit,AfterViewInit {

  contadorContratoDto!: ContadorContratoDto;
  contadorContratoPorPropiedadClaseDto!:ContadorContratoPorPropiedadClaseDto;
  contratoPorVencerDto!: ContratoPorVencerDto[];
  dataContratosPorVencer = false
  dataSourceContratosPorVencer = new MatTableDataSource<ContratoPorVencerDto>();
  displayedColumnsContratosPorVencer: string[] = [];

  dataContratosPorAjustar = false
  dataSourceContratosPorAjustar = new MatTableDataSource<ContratoSummaryDto>();
  displayedColumnsContratosPorAjustar: string[] = [];

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  notificaciones: NotificacionDto[] = []


  constructor(
    private dashboardService: DashboardService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private notificacionesService: NotificacionesService,
    private breakpointObserver: BreakpointObserver,
    private contratosService: ContratosService
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumnsContratosPorVencer = result.matches ?
        ['fechaVencimiento', 'ubicacionPropiedad',  'accion'] :
        ['fechaVencimiento', 'cantidadDiasRestante', 'ubicacionPropiedad', 'accion'];
    });
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumnsContratosPorAjustar = result.matches ?
        ['fechaComienzo', 'numeroContrato',  'accion'] :
        ['fechaComienzo', 'numeroContrato', 'propiedad',  'accion'];
    });
  }

  ngOnInit() {
    this.getContadorContratos()
    this.getContadorContratoPorPripiedadClase()
    this.getContratosPorVencer()
    this.getAllContratoPorActualizarIndice()
  }


  ngAfterViewInit() {
    this.dataSourceContratosPorVencer.paginator = this.paginator;
    this.dataSourceContratosPorVencer.sort = this.sort;
    this.dataSourceContratosPorAjustar.paginator = this.paginator;
    this.dataSourceContratosPorAjustar.sort = this.sort;
  }

  private getContadorContratos() {
    this.spinner.show("spContadores")
    this.dashboardService.getContadorContratos().subscribe(
      data => {
        this.spinner.hide("spContadores")
        this.contadorContratoDto = data
      },
      error => {
        this.spinner.hide("spContadores")
        this.snackBar.showError(error, "Error");
      })
  }

  private getContadorContratoPorPripiedadClase() {
    this.spinner.show("getContadorContratoPorPripiedadClase")
    this.dashboardService.getContadorContratoPorPripiedadClase().subscribe(
      data => {
        this.spinner.hide("getContadorContratoPorPripiedadClase")
        this.contadorContratoPorPropiedadClaseDto = data
      },
      error => {
        this.spinner.hide("getContadorContratoPorPripiedadClase")
        this.snackBar.showError(error, "Error");
      })
  }

  private getContratosPorVencer() {
    this.spinner.show("getContratosPorVencer")
    this.dashboardService.getContratosPorVencer().subscribe(
      data => {
        this.spinner.hide("getContratosPorVencer")
        this.contratoPorVencerDto = data
        this.dataSourceContratosPorVencer.data = data
        this.dataContratosPorVencer = this.dataSourceContratosPorVencer.data.length >= 1 ? true : false
        // if(data.length >0){
        //   let notificacionDto = new NotificacionDto
        //   notificacionDto.mensaje = "Contratos por vencer"
        //   notificacionDto.cantidad = data.length
        //   this.notificaciones.push(notificacionDto)
        //   this.notificacionesService.enviaNotificacion(notificacionDto)
        // }
        let notificacionDto = new NotificacionDto
        notificacionDto.mensaje = "Contratos por vencer"
        notificacionDto.cantidad = data.length
        this.notificaciones.push(notificacionDto)
        this.notificacionesService.sendNotificacion(notificacionDto)

      },
      error => {
        this.spinner.hide("getContratosPorVencer")
        this.snackBar.showError(error, "Error");
      })
  }

  private getAllContratoPorActualizarIndice() {
    this.spinner.show("getContratosPorAjustar")
    this.contratosService.getAllContratoPorActualizarIndice().subscribe(
      data => {
        this.spinner.hide("getContratosPorAjustar")
        this.dataSourceContratosPorAjustar.data = data
        this.dataContratosPorAjustar = this.dataSourceContratosPorAjustar.data.length >= 1 ? true : false
      },
      error => {
        this.spinner.hide("getContratosPorAjustar")
        this.snackBar.showError(error, "Error");
      })
  }


  applyFilterContratosPorVencer(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceContratosPorVencer.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceContratosPorVencer.paginator) {
      this.dataSourceContratosPorVencer.paginator.firstPage();
    }
  }

  applyFilterContratosPorAjustar(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceContratosPorAjustar.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceContratosPorAjustar.paginator) {
      this.dataSourceContratosPorAjustar.paginator.firstPage();
    }
  }

}
