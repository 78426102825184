import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateGarantiaInquilinoRequest } from '@models/garantia-inquilino/createGarantiaInquilinoRequest.model';
import { GarantiaInquilinoDto } from '@models/garantia-inquilino/garantiaInquilinoDto.model';
import { UpdateGarantiaInquilinoRequest } from '@models/garantia-inquilino/updateGarantiaInquilinoRequest.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class GarantiasInquilinosService {

  constructor(
    private http: HttpClient
  ) { }

  create(createGarantiaInquilinoRequest:CreateGarantiaInquilinoRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/GarantiasInquilinos/Create",createGarantiaInquilinoRequest);
  }

  update(updateGarantiaInquilinoRequest:UpdateGarantiaInquilinoRequest):Observable<any>
  {
    return  this.http.put<any>("/api/GarantiasInquilinos/Update",updateGarantiaInquilinoRequest);
  }

  getAll():Observable<GarantiaInquilinoDto[]>
  {
    return  this.http.get<GarantiaInquilinoDto[]>("/api/GarantiasInquilinos/GetAll");
  }

  getById(id:number):Observable<GarantiaInquilinoDto>
  {
    return  this.http.get<GarantiaInquilinoDto>("/api/GarantiasInquilinos/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/GarantiasInquilinos/Delete?id=" + id);
    else
      return of(null)
  }

  getAllGarantiasInquilinosByIdGarantia(idGarantia:number):Observable<GarantiaInquilinoDto[]>
  {
    return  this.http.get<GarantiaInquilinoDto[]>("/api/GarantiasInquilinos/GetAllGarantiasInquilinosByIdGarantia?idGarantia=" + idGarantia);
  }

  getAllGarantiasInquilinosByIdInquilino(idInquilino:number):Observable<GarantiaInquilinoDto[]>
  {
    return  this.http.get<GarantiaInquilinoDto[]>("/api/GarantiasInquilinos/GetAllGarantiasInquilinosByIdInquilino?idInquilino=" + idInquilino);
  }
}
