import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AccionesDialog } from '@models/base/identificadores.model';
import { PeriodoContratoDetailDto } from '@models/periodos-contrato/periodoContratoDetailDto.model';
import { DialogEditarImportePunitorioComponent } from './dialog-editar-importe-punitorio/dialog-editar-importe-punitorio.component';
import { ContratoDto } from '@models/contrato/contratoDto.model';
import { isFalsy } from 'utility-types';
import { ContratoController } from 'src/app/controllers/contratos.controller';
import moment from 'moment';

@Component({
  selector: 'app-dialog-editar-periodos-contrato',
  templateUrl: './dialog-editar-periodos-contrato.component.html',
  styleUrls: ['./dialog-editar-periodos-contrato.component.css']
})
export class DialogEditarPeriodosContratoComponent implements OnInit {
  numSelected: number = 0;
  displayedColumns: string[] = ['select', 'periodo', 'importe', 'punitorio', 'totalAlquiler', 'importeEntrega', 'difAlquiler', 'difPunitorio', 'saldoTotal', 'accion'];
  dataSource = new MatTableDataSource<PeriodoContratoDetailDto>();
  selection = new SelectionModel<PeriodoContratoDetailDto>(true, []);
  defImpTotalConAlquiler = ""
  defImpAlquiler = "Importe del alquiler. Importe el cual fue cargado en el contrato. Dicho importe puede variar si se recibieron entregas anteriores."
  defImpPunitorios = "Importe de punitorios. Los mismos son calculados en base al importe de alquiler."
  defImpEntrega = "Importe de Entrega. Importe de la rendicion a pagar. Si el monto no difiere del importe de alquiler, se considera entregado el total."
  defImpTotal = "Importe total. Es la sumatoria del importe de alquiler mas los punitorios."
  defSaldoTotal = "Saldo total"
  totalAPagarSinPunitorios: number = 0;
  totalConPunitorio: number = 0;
  datas: any;

  dataSinAlterar!: any

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRefPeriodos: MatDialogRef<DialogEditarPeriodosContratoComponent>,
    public dialogRefPunitorio: MatDialogRef<DialogEditarImportePunitorioComponent>,

  ) {

  }

  ngOnInit(): void {

    this.dataSinAlterar = this.data;
    this.datas = this.data;
    this.listarDeuda();
  }


  listarDeuda() {
    this.datas.periodos.forEach((element: PeriodoContratoDetailDto) => {
      // if (element.importeEntrega != 0 && element.importeEntrega != undefined)
      //   element.importeEntrega = element.importeEntrega
      // else
      //   element.importeEntrega = element.importeAlquiler

      element.importePunitorio = 0
      const saldoDeudor = isFalsy(element.saldoDeudor) ? 0 : element.saldoDeudor
      element.importeAlquiler = element.importeAlquiler - saldoDeudor
      if (element.seleccionPago == true)
        this.selection.select(element)
      else
        this.selection.deselect(element)

      this.calcularPunitorioPeriodo(element)
    });

    this.dataSource.data = this.datas.periodos
  }

  getTotalPunitorios() {
    this.totalConPunitorio = this.datas.periodos.map((t: { importePunitorioEntrega: any; }) => Math.round(t.importePunitorioEntrega * 100) / 100).reduce((acc: any, value: any) => acc + value, 0)
    return this.totalConPunitorio;
  }


  getTotalImporteAlquilerSinPunitorio() {
    return this.datas.periodos.map((t: { importeAlquilerSinPunitorio: any; }) => Math.round(t.importeAlquilerSinPunitorio * 100) / 100).reduce((acc: any, value: any) => acc + value, 0);
  }

  getTotalImporteAlquilerConPunitorio() {
    return this.datas.periodos.map((t: { importeAlquilerSinPunitorio: any, importePunitorio: any; }) => t.importeAlquilerSinPunitorio + Math.round(t.importePunitorio * 100) / 100).reduce((acc: any, value: any) => acc + value, 0);
  }


  getTotalImporteAlquiler() {
    this.totalAPagarSinPunitorios = this.datas.periodos.map((t: { importeAlquiler: any; importePunitorio: any; }) => t.importeAlquiler + t.importePunitorio).reduce((acc: any, value: any) => acc + value, 0);
    return Math.round(this.totalAPagarSinPunitorios * 100) / 100
  }

  getTotalImporteEntrega() {
    const total = this.datas.periodos.map((t: { importeAlquilerSinPunitorio: any; importePunitorioEntrega: any; }) => t.importeAlquilerSinPunitorio + t.importePunitorioEntrega).reduce((acc: any, value: any) => acc + value, 0);
    return Math.round(total * 100) / 100
  }

  getTotalDifAlquiler() {
    const total = this.datas.periodos.map((t: { importeAlquiler: any; importeAlquilerSinPunitorio: any; }) => t.importeAlquiler - t.importeAlquilerSinPunitorio).reduce((acc: any, value: any) => acc + value, 0);
    return Math.round(total * 100) / 100
  }

  getTotalDifPunitorio() {
    const total = this.datas.periodos.map((t: { importePunitorio: any; importePunitorioEntrega: any; }) => t.importePunitorio - t.importePunitorioEntrega).reduce((acc: any, value: any) => acc + value, 0);
    return Math.round(total * 100) / 100
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    this.numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numSelected === numRows;
  }


  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodoContratoDetailDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  onSubmit() {
    const lstPeriodosContratosApagar = this.selection.selected
    lstPeriodosContratosApagar.forEach(element => {
      element.seleccionPago = true
    });
    this.dialogRefPeriodos.close({ data: lstPeriodosContratosApagar, event: AccionesDialog.Agregar });
  }

  onCancel() {
    const lstPeriodosContratosApagar = this.selection.selected
    this.dialogRefPeriodos.close({ data: this.dataSinAlterar.periodos, event: AccionesDialog.Cancelar })
  }

  editarMontoPunitorio(periodo: PeriodoContratoDetailDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { periodo: periodo, contrato: this.datas.contrato }
    dialogConfig.autoFocus = false
    dialogConfig.disableClose = false
    dialogConfig.width = "50%"

    const dialogRef = this.dialog.open(DialogEditarImportePunitorioComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Aceptar) {
        this.datas.periodos.forEach((element: any) => {
          if (!isFalsy(result))
            if (element.id == result.data.periodo.id) {
              let fechaVencimiento = moment(new Date(result.data.periodo.fechaVencimiento))
              if (fechaVencimiento >= result.data.periodo.fechaPunitorio)
                element.importePunitorio = 0
              else
                element.importePunitorio = Math.round(result.data.periodo.importePunitorio * 100) / 100;

              element.importePunitorioEntrega = Math.round(result.data.periodo.importePunitorioEntrega * 100) / 100;
              element.importeAlquilerSinPunitorio = Math.round(result.data.periodo.importeAlquilerSinPunitorio * 100) / 100;
            }
        });
        //this.calcularPunitorioPeriodo(periodo)
      }

    });
  }

  calcularPunitorioPeriodo(periodo: PeriodoContratoDetailDto) {
    if (isFalsy(periodo.importeEntrega)) {
      const entregaImporteAlquiler = ContratoController.calculoPunitorio(periodo, periodo.importeAlquiler as number, new Date(), this.datas.contrato as ContratoDto)
      periodo.importeAlquilerSinPunitorio = Math.round(entregaImporteAlquiler.entrega * 100) / 100;
      periodo.importePunitorioEntrega = Math.round(entregaImporteAlquiler.punitorio * 100) / 100;
    }
    else {
      const entregaImporteEntrega = ContratoController.calculoPunitorio(periodo, periodo.importeEntrega as number, new Date(), this.datas.contrato as ContratoDto)
      periodo.importeAlquilerSinPunitorio = Math.round(entregaImporteEntrega.entrega * 100) / 100;
      periodo.importePunitorioEntrega = Math.round(entregaImporteEntrega.punitorio * 100) / 100;
    }
    const entregaAlquiler = ContratoController.calculoPunitorio(periodo, periodo.importeAlquiler as number, new Date(), this.datas.contrato as ContratoDto)
    periodo.importePunitorio = Math.round(entregaAlquiler.punitorio * 100) / 100;
  }
  isFalsy(valor: any) {
    return isFalsy(valor)
  }

}
