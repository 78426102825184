import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateKeyValueRequest } from '@models/base/createKeyValueRequest.model';
import { UpdateKeyValueRequest } from '@models/base/updateKeyValueRequest.model';
import { isFalsy } from 'utility-types';
import { CreateTipoMovimientoRequest } from '@models/tipos-movimiento/createTipoMovimientoRequest.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { UpdateTipoMovimientoRequest } from '@models/tipos-movimiento/updateTipoMovimientoRequest.model';
import { TipoMovimientoDto } from '@models/tipos-movimiento/tipoMovimientoDto.model';

@Injectable({
  providedIn: 'root'
})
export class TiposMoviemientoService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient
  ) { }

  create(createTipoMovimientoRequest:CreateTipoMovimientoRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/TipoMovimiento/Create",createTipoMovimientoRequest);
  }

  update(updateTipoMovimientoRequest:UpdateTipoMovimientoRequest):Observable<any>
  {
    return  this.http.put<any>("/api/TipoMovimiento/Update",updateTipoMovimientoRequest);
  }

  getAll():Observable<TipoMovimientoDto[]>
  {
    return  this.http.get<TipoMovimientoDto[]>("/api/TipoMovimiento/GetAll");
  }

  getById(id:number):Observable<TipoMovimientoDto>
  {
    return  this.http.get<TipoMovimientoDto>("/api/TipoMovimiento/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/TipoMovimiento/Delete?id=" + id);
    else
      return of(null)
  }
}
