import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-panel-configuracion',
  templateUrl: './nav-panel-configuracion.component.html',
  styleUrls: ['./nav-panel-configuracion.component.css']
})
export class NavPanelConfiguracionComponent implements OnInit {

  constructor() { }
  ngOnInit(): void {
  }

}
