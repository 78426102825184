import { IdImporteMovimientoDto } from "@models/movimiento/idImporteMovimientoDto.model"
import { MovimientoBasicDto } from "@models/movimiento/movimientoBasicDto.model"
import { PagoDetailDto } from "@models/pago/pagoDetailDto.model"

export class CreateComprobanteRequest {
  public pagos!: PagoDetailDto[]
  public movimientos!: IdImporteMovimientoDto[]
  public movimientosNuevos!: MovimientoBasicDto[]
  public idEmpresa!: number
  public idUsuario!: number
}
