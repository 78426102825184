<ngx-spinner bdColor="rgba(255,255,255,0.8)" name="spPropiedadesPropietario" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
</ngx-spinner>
<table mat-table [dataSource]="dataSource" [hidden]=!data table matSort matSortStart="asc" class="mat-elevation-z8">

    <ng-container matColumnDef="propiedad">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Propiedad </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element"> {{element.propiedad.ubicacion |uppercase }} {{element.propiedad.piso |uppercase}} - {{element.propiedad.departamento | uppercase}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="localidad">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Localidad </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element"> {{element.propiedad.localidad == undefined? "": element.propiedad.localidad.descripcion |uppercase}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fechaAlta">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Alta </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element"> {{element.propiedad.fechaAlta |date:'short'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="estadoPropiedad">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Estado </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element"> {{element.propiedad.estadoPropiedad == undefined? "": element.propiedad.estadoPropiedad.descripcion | titlecase}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
</table>

<mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="10" [pageSizeOptions]="[5, 10, 20,50,100,200]">
</mat-paginator>
<div class="no-data-table">
    <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
</div>