import { Component, Input, OnInit } from '@angular/core';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { PersonasService } from "@services/personas/personas.service"

@Component({
  selector: 'app-garantia',
  templateUrl: './garantia.component.html',
  styleUrls: ['./garantia.component.css']
})
export class GarantiaComponent implements OnInit {

  personaDetailDto!: PersonaDetailDto;

  constructor(
    private personasService: PersonasService,
  ) {}

  ngOnInit(): void {

    this.personaDetailDto = this.personasService.getPersona()

  }

}
