import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { BonificacionDto } from '@models/bonificaciones/bonificacionDto.model';
import { TiposBonificacionService } from '@services/tipos-bonificacion/tipos-bonificacion.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-dialog-bonificacion',
  templateUrl: './dialog-bonificacion.component.html',
  styleUrls: ['./dialog-bonificacion.component.css']
})
export class DialogBonificacionComponent implements OnInit {


  action: string;
  local_data: any;

  form: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  lstTiposBonificacion!: BaseDto[];

  get f() { return this.form.controls; }

  constructor(
    public tiposBonificacionService: TiposBonificacionService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<DialogBonificacionComponent>,

    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: BonificacionDto) {
    this.local_data = { ...data };
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      Detalle: ["", Validators.compose([Validators.required,])],
      TipoBonificacion: ["", Validators.compose([Validators.required,])],
    })
    if (this.action != AccionesDialog.Agregar){
      this.form.controls["TipoBonificacion"].setValue(this.local_data.tipoBonificacion.id)
      this.form.controls["Detalle"].setValue(this.local_data.detalle)
    }
  }

  doAction() {
    this.local_data.tipoBonificacion = this.form.controls["TipoBonificacion"].value
    this.local_data.detalle = this.form.controls["Detalle"].value
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }



  ngOnInit(): void {
    this.getAllTiposBonificacion()
  }

  getAllTiposBonificacion() {
    this.spinner.show("spBusqueda")
    this.tiposBonificacionService.getAll().subscribe(
      data => {
        this.lstTiposBonificacion = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
        this.spinner.hide("spBusqueda")
      },
      error => {
        this.spinner.hide("spBusqueda")
        this.snackBar.showError(error, "Error");
      }
    )
  }

  trackByItems(index: number, item: any): any { return item; }

}
