import { isFalsy } from "utility-types";
import * as CryptoJS from 'crypto-js';

export class UtilidadesController {
  static isFalsy(valor: any) {
    return isFalsy(valor)
  }

  static decrypt(texto: string) {
    return CryptoJS.AES.decrypt(texto, "Massoud123").toString(CryptoJS.enc.Utf8)
  }

  static encrypt(texto: string) {
    return CryptoJS.AES.encrypt(texto, "Massoud123").toString()
  }

  static unirCadena(array: String[]): string {
    let cadena = array.join()
    return cadena
  }

  static dividirCadena(cadenaADividir: string, separador: string) {
    let cadena = cadenaADividir.split(separador);
    return cadena
  }
}


