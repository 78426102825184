<div class="position-relative">

<h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">

    <form [formGroup]="form" *ngIf="action != 'Eliminar'; else elseTemplate">
        <div class="row">
            <div class="col-lg-6">
                <mat-form-field class="full-width">
                    <ngx-spinner [zIndex]=100 name="spBusquedaTipoMovimientos" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Tipo Movimiento</mat-label>
                    <mat-select tabindex="1" cdkFocusInitial placeholder="Seleccione opcion" formControlName="TiposMovimiento">
                        <mat-option [value]="tipoMovimiento" *ngFor="let tipoMovimiento of lstTiposMovimientos;let i=index;trackBy:trackByItems">
                            {{tipoMovimiento.resta == true ? tipoMovimiento.descripcion + ' (-)' : tipoMovimiento.descripcion + ' (+)'}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.TiposMovimiento.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-lg-6">
                <mat-form-field>
                    <span matPrefix>$ &nbsp;</span>
                    <input tabindex="2" type="text" mask="separator.2" thousandSeparator="," placeholder="{{action}} Importe" formControlName="Importe" [(ngModel)]="local_data.importe" matInput>
                    <mat-error *ngIf="f.Importe.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-12">
              <mat-form-field class="example-chip-list">
                <mat-label>Conceptos</mat-label>
                <mat-chip-list #chipList aria-label="Seleccion de conceptos">
                  <mat-chip
                    *ngFor="let concepto of conceptos"
                    (removed)="remove(concepto)">
                    {{concepto}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    #conceptoInput
                    placeholder="Nuevo concepto"
                    formControlName="Descripcion"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option *ngFor="let concepto of filteredConceptos | async;let i=index;trackBy:trackByItems" [value]="concepto">
                    {{concepto}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
        </div>
    </form>
    <ng-template #elseTemplate>
        ¿Esta seguro que desea eliminar el registro <b>{{local_data.descripcion}}</b>?
    </ng-template><br>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="doAction()" tabindex="3" [disabled]="!form.valid || local_data.importe == 0 ||  local_data.tipoMovimiento == '' || conceptos.length == 0">{{action}}</button>
    <button mat-button (click)="closeDialog()" tabindex="4" color="warn">Cancelar</button>
</div>

</div>
