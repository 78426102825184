import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArchivoTemporalDto } from '@models/archivos-temporales/archivoTemporalDto.model';
import { GetFileContentByIdArchivoTemporalResponse } from '@models/archivos-temporales/getFileContentByIdArchivoTemporalResponse.model';

@Injectable({
  providedIn: 'root'
})
export class ArchivosTemporalesService {

  constructor(private http:HttpClient)  { }

  getAll():Observable<ArchivoTemporalDto[]>
  {
    return  this.http.get<ArchivoTemporalDto[]>("/api/ArchivoTemporal/GetAll");
  }

  getAllByIdContrato(idContrato:number):Observable<ArchivoTemporalDto[]>
  {
    return  this.http.get<ArchivoTemporalDto[]>("/api/ArchivoTemporal/GetAllByIdContrato?idContrato="+idContrato);
  }

  getById(id:number):Observable<ArchivoTemporalDto>
  {
    return  this.http.get<ArchivoTemporalDto>("/api/ArchivoTemporal/GetById?id="+id);
  }

  delete(id:number):Observable<any>
  {
    return  this.http.delete<any>("/api/ArchivoTemporal/Delete?id="+id);
  }

  getFileContentById(id:number):Observable<GetFileContentByIdArchivoTemporalResponse>
  {
    return  this.http.get<GetFileContentByIdArchivoTemporalResponse>("/api/ArchivoTemporal/GetFileContentById?id="+id);
  }

  getAllByIdComprobante(idcomprobante:number):Observable<ArchivoTemporalDto[]>
  {
    return  this.http.get<ArchivoTemporalDto[]>("/api/ArchivoTemporal/GetAllByIdComprobante?idcomprobante="+idcomprobante);
  }

}
