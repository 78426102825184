import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateKeyValueRequest } from '@models/base/createKeyValueRequest.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { UpdateKeyValueRequest } from '@models/base/updateKeyValueRequest.model';
import { CreateEstadoComprobanteRequest } from '@models/estados-comprobante/createEstadoComprobanteRequest.model';
import { UpdateEstadoComprobanteRequest } from '@models/estados-comprobante/updateEstadoComprobanteRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class EstadosComprobanteService {

  constructor(
    private http: HttpClient
  ) { }

  create(createEstadoComprobanteRequest:CreateEstadoComprobanteRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/EstadoComprobante/Create",createEstadoComprobanteRequest);
  }

  update(updateEstadoComprobanteRequest:UpdateEstadoComprobanteRequest):Observable<any>
  {
    return  this.http.put<any>("/api/EstadoComprobante/Update",updateEstadoComprobanteRequest);
  }

  getAll():Observable<BaseDto[]>
  {
    return  this.http.get<BaseDto[]>("/api/EstadoComprobante/GetAll");
  }

  getById(id:number):Observable<BaseDto>
  {
    return  this.http.get<BaseDto>("/api/EstadoComprobante/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/EstadoComprobante/Delete?id=" + id);
    else
      return of(null)
  }
}
