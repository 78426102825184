import { BaseDto } from "@models/base/baseDto.model"

export class ServicioPropiedadDto {
  public id!: number
  public idPropiedad!: number
  public servicio!: BaseDto
  public nroGestionPersonal!: string
  public nroCuenta!: string
  public referenciaPago!: string
  public descripcion!: string
}
