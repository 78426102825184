<mat-card *appRole="['Documentos.Ver']">
    <ngx-spinner name="spListadoDoc" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <mat-form-field class="example-full-width">
        <mat-label>Buscar</mat-label>
        <input matInput type="search" (keyup)="applyFilter($event)">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>


    <table mat-table [dataSource]="dataSource" [hidden]=!data matSort matSortStart="asc" class="mat-elevation-z8">


        <!-- Nombre -->
        <!-- <ng-container matColumnDef="id">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> # </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let documento">
                {{documento.id}}
            </mat-cell>
        </ng-container> -->

        <!-- Nombre -->
        <ng-container matColumnDef="nombre">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let documento">
                {{documento.nombre != null ? documento.nombre : undefined | titlecase}}
            </mat-cell>
        </ng-container>

        <!-- Asunto-->
        <ng-container matColumnDef="asunto">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Asunto </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let documento">
                <div *ngIf="documento.asunto">
                    {{documento.asunto.descripcion != null ? documento.asunto.descripcion: undefined }}
                </div>

            </mat-cell>
        </ng-container>

        <!-- Tipo Entidad-->
        <ng-container matColumnDef="tipoEntidad">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Entidad </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let documento">
                <div *ngIf="documento.tipoEntidad">
                    {{documento.tipoEntidad.descripcion != null ? documento.tipoEntidad.descripcion: undefined }}
                </div>

            </mat-cell>
        </ng-container>

        <!-- Descargar Archivo-->
        <ng-container matColumnDef="descargar">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Archivo </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let documento">
                <button mat-icon-button color="accent" (click)="descargarArchivo(documento)" aria-label="Icono para decargar archivo">
                    <mat-icon #tooltip="matTooltip" matTooltip="Descargar archivo.">file_download</mat-icon>
                  </button>
            </mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="accion">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Acción</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let documento">

                <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item *appRole="['Documentos.Modificar']" [routerLink]="['/documento/',documento.id]">
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                  </button>

                    <button mat-menu-item *appRole="['Documentos.Eliminar']" (click)="openDialog('Eliminar',documento)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>

                </mat-menu>
            </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


    </table>
    <mat-paginator showFirstLastButtons [hidden]=!data class="paginator" [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
    </mat-paginator>
    <br>
    <div class="no-data-table">
        <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
    </div>
</mat-card>
