export class ParametroSistemaDescripcion{
  public Iva = ""
  public Caja = ""
  public CodigoAlquiler = ""
  public UrlLogin = ""
  public Sender = ""
  public SmtpServer = ""
  public Port = ""
  public Username = ""
  public Password = ""
  public SslTls = ""
  public DiasFinalizaContrato = ""
  public ComisionGananciaInmobiliaria = ""
  public ComisionGananciaPropietario = ""
  public FechaGenLiquizacion = ""
  public FechaGenResumen = ""
  public CuentaContableDefecto = ""
  public NotaResumen = ""
  public DarkMode = ""
  public CantidadMesesDeudaPropiedad = ""
  public NotaRecibo = ""
  public NotaLiquidacion = ""
  public DuracionMesesArchivosTemporales = ""
  public DuracionAlertas = ""
}
