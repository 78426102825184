import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { DialogDeleteUserComponent } from '../dialog-delete-user/dialog-delete-user.component';

@Component({
  selector: 'app-btn-delete-user',
  templateUrl: './btn-delete-user.component.html',
  styleUrls: ['./btn-delete-user.component.css']
})
export class BtnDeleteUserComponent implements OnInit {

  @Input() UsuarioDto: UsuarioDto = new UsuarioDto;
  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = this.UsuarioDto

    const dialogRef = this.dialog.open(DialogDeleteUserComponent, dialogConfig);
  }
}
