import { ArchivoTemporalDto } from "@models/archivos-temporales/archivoTemporalDto.model";

export class ComprobantesController {
  constructor( ) {
  }

  static generarDocumentoPdf(archivoTemporalDto: ArchivoTemporalDto) {
    const link = document.createElement("a");
    link.href = "data:application/pdf;base64," + archivoTemporalDto.contenidoArchivo;
    link.download = archivoTemporalDto.nombreArchivo.trim() + ".pdf"
    link.click();
  }

}
