import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-btn-new-garantia',
  templateUrl: './btn-new-garantia.component.html',
  styleUrls: ['./btn-new-garantia.component.css']
})
export class BtnNewGarantiaComponent implements OnInit {

 
  constructor(
  ) { }

  ngOnInit(): void {
  }

  openDialog(): void {
  }

}
