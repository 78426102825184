import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogNuevoMovimientoCajaComponent } from '../views/caja/dialog-nuevo-movimiento-caja/dialog-nuevo-movimiento-caja.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { ListadoCajaComponent } from '../views/caja/listado-caja/listado-caja.component';
import { ListadoAcreditacionesComponent } from '@views/comprobantes/listado-acreditaciones/listado-acreditaciones.component';



@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    DialogNuevoMovimientoCajaComponent,
    ListadoCajaComponent,

  ],
  exports:[
    DialogNuevoMovimientoCajaComponent,
    ListadoCajaComponent,
    ]
})
export class CajaModule { }
