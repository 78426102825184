import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PersonaGaranteDto } from '@models/garantia/personaGaranteDto.model';
import { GarantiasService } from '@services/garantias/garantias.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-buscar-persona-garante',
  templateUrl: './buscar-persona-garante.component.html',
  styleUrls: ['./buscar-persona-garante.component.css']
})
export class BuscarPersonaGaranteComponent implements OnInit {

  garantiaSelect: EventEmitter<PersonaGaranteDto> = new EventEmitter()
  public filteredGarantias!: Observable<PersonaGaranteDto[]>;
  garantias: PersonaGaranteDto[] = []
  garantia: PersonaGaranteDto = new PersonaGaranteDto;
  public entityControl = new FormControl();
  @Output() eventEntity = new EventEmitter<PersonaGaranteDto>();
  @Input() garantiaEntrada: PersonaGaranteDto = new PersonaGaranteDto;
  @Input() idPersonaInput: number = 0;
  @Input() disable: boolean = false
  disabledDocumento: FormControl = new FormControl
  esPersona: boolean = false;
  entitySelect!: null;

  constructor(
    private personasService: PersonasService,
    private garantiasService: GarantiasService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getAllPersonaGarantias();
    if (this.idPersonaInput != 0) {
      this.getPersonaById(this.idPersonaInput)
      this.entityControl.setValue(this.garantiaEntrada.descripcion)
    }
  }


  ngOnChanges(): void {
    this.getPersonaById(this.idPersonaInput)
    this.entityControl.setValue(this.garantiaEntrada.descripcion)
  }

  selectValue(event: any) {
    if (isFalsy(event)){
      this.entitySelect=null
      this.entityControl.setValue("")
    }
    this.eventEntity.emit(event)
  }

  findOption(val: string) {
    this.spinner.show("spBusquedaGarante")
    for (let i = 0; i < this.garantias.length; i++) {
      if (this.garantias[i].descripcion.toUpperCase() == val.toUpperCase()) {
        //this.eventEntity.emit(this.garantias[i])
        this.garantia = this.garantias[i]
        this.esPersona = true
      }
    }
    this.spinner.hide("spBusquedaGarante")
    return this.garantias.filter(garantia => garantia.descripcion.toLowerCase().includes(val.toLowerCase()));
  }


  getAllPersonaGarantias() {
    this.spinner.show("spBusquedaGarante")
    this.personasService.getAllPersonasGarantes()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaGarante")
          this.garantias = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
          this.filteredGarantias = this.entityControl.valueChanges.pipe(
            startWith(''),
            map((term: any) => this.findOption(term))
          );
        },
        error => {
          this.spinner.hide("spBusquedaGarante")
          this.snackBar.showError(error, "Error");
        });
  }

  getPersonaById(idPersona: any) {
    this.garantiasService.getGarantiaById(idPersona)
      .subscribe(
        data => {
          const personaGaranteDto = new PersonaGaranteDto
          personaGaranteDto.id = data.persona.id
          personaGaranteDto.descripcion = data.persona.nombreCompleto
          personaGaranteDto.id_Garantia = data.id
          this.garantiaEntrada = personaGaranteDto
        },
        error => {
          this.snackBar.showError(error, "Error");
        }
      );
  }

  editarPersona(idPersona:number) {
    this.getPersonaByIdAndRoute(idPersona)
  }

  getPersonaByIdAndRoute(idPersona: number) {
    this.spinner.show("spBusquedaGarante")
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaGarante")
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spBusquedaGarante")
          this.snackBar.showError(error, "Error");
        });
  }

  trackByItems(index: number, item: any): any { return item; }
}
