<mat-card>
    <ngx-spinner name="spFromGarantia" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>

    <mat-card-subtitle>Datos garantia</mat-card-subtitle>
    <div class="row">
        <div class="form-group col-md-3">
            <form [formGroup]="formTipoGarantia">
                <mat-form-field>
                    <mat-label>Tipo de Garantia</mat-label>
                    <mat-select (selectionChange)="selectTipoGarantia($event.value)" formControlName="TipoGarantia">
                        <mat-option [value]="tipoGarantia.id" *ngFor="let tipoGarantia of lstTiposGarantiaModel ;let i=index;trackBy:trackByItems">
                            {{tipoGarantia.descripcion | titlecase}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="fG.TipoGarantia.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarPersona">
            <form [formGroup]="formPersonaGarantia">
                <mat-form-field>
                    <mat-label>Empleador</mat-label>
                    <input matInput name="Empleador" type="text " formControlName="Empleador" />
                    <mat-error *ngIf="fPer.Empleador.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>

        <div class="form-group col-md-3" *ngIf="mostrarPersona">
            <form [formGroup]="formPersonaGarantia">
                <mat-form-field class="example-full-width">
                    <mat-label>Empleador CUIT/CUIL</mat-label>
                    <input matInput name="CuitCuil" type="text" [mask]="'00-00000000-0'" placeholder="00-00000000-0" formControlName="CuitCuil" />
                    <mat-error *ngIf="fPer.CuitCuil.errors?.pattern">Formato invalido.</mat-error>
                </mat-form-field>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarPersona">
            <form [formGroup]="formPersonaGarantia">
                <mat-form-field class="example-full-width">
                    <mat-label>Sueldo</mat-label>
                    <span matPrefix>$ &nbsp;</span>
                    <input matInput name="Sueldo" placeholder="0.00" type="text" mask="separator.2" thousandSeparator="," formControlName="Sueldo" />
                    <mat-error *ngIf="fPer.Sueldo.errors?.pattern">Formato invalido.</mat-error>
                </mat-form-field>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
            <form [formGroup]="formPropiedad">
                <mat-form-field>
                    <mat-label>Tipo de Folio</mat-label>
                    <mat-select (selectionChange)="selectTipoFolio($event.value)" formControlName="TipoFolio">
                        <mat-option [value]="tipoFolio.id" *ngFor="let tipoFolio of lstTiposFolios ;let i=index;trackBy:trackByItems">
                            {{tipoFolio.descripcion | titlecase}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="fG.TipoGarantia.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarPropiedad && idTipoFolio===1">
            <form [formGroup]="formPropiedad">
                <mat-form-field class="example-full-width">
                    <mat-label>Tomo</mat-label>
                    <input matInput name="Tomo" type="text" formControlName="Tomo" />
                    <mat-error *ngIf="fPro.Tomo.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>

        <div class="form-group col-md-3" *ngIf="mostrarPropiedad  && idTipoFolio===1 || idTipoFolio===2">
            <form [formGroup]="formPropiedad">
                <mat-form-field class="example-full-width">
                    <mat-label>NroFolio</mat-label>
                    <input matInput name="NroFolio" type="text" formControlName="NroFolio" />
                    <mat-error *ngIf="fPro.NroFolio.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>

        <div class="form-group col-md-3" *ngIf="mostrarPropiedad && idTipoFolio===1">
            <form [formGroup]="formPropiedad">
                <mat-form-field class="example-full-width">
                    <mat-label>Numero</mat-label>
                    <input matInput name="Numero" type="text" formControlName="Numero" />
                </mat-form-field>
            </form>
        </div>

        <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
            <form [formGroup]="formPropiedad">
                <mat-form-field class="example-full-width">
                    <mat-label>Ubicacion</mat-label>
                    <input matInput name="Ubicacion" type="text" formControlName="Ubicacion" />
                </mat-form-field>
            </form>
        </div>

        <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
            <form [formGroup]="formPropiedad">
                <mat-form-field class="example-full-width">
                    <mat-label>Localidad</mat-label>
                    <input matInput name="Localidad" type="text" formControlName="Localidad" />
                </mat-form-field>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
            <form [formGroup]="formPropiedad">
                <section class="example-section">
                    <mat-checkbox formControlName="PropiedadHorizontal">Es propiedad Horizontal?</mat-checkbox>
                </section>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
            <form [formGroup]="formPropiedad">
                <mat-form-field class="example-full-width">
                    <mat-label>Indivisa</mat-label>
                    <span matPrefix>% &nbsp;</span>
                    <input matInput appPercentageDirective min="1" max="100" [(ngModel)]="indivisa" placeholder="100" name="Indivisa" type="number" formControlName="Indivisa" />
                    <mat-hint>Dueño de propiedad al % {{indivisa}}</mat-hint>
                </mat-form-field>
            </form>
        </div>

        <div class="form-group col-md-3" *ngIf="mostrarFianza">
            <form [formGroup]="formFianza">
                <mat-form-field>
                    <mat-label>Razon Social</mat-label>
                    <input matInput name="RazonSocial" type="text " formControlName="RazonSocial" />
                    <mat-error *ngIf="fTL.RazonSocial.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </form>
        </div>
        <div class="form-group col-md-3" *ngIf="mostrarFianza">
            <form [formGroup]="formFianza">
                <mat-form-field class="example-full-width">
                    <mat-label>CUIT</mat-label>
                    <input matInput name="Cuit" type="text" placeholder="00-00000000-0" formControlName="Cuit" />
                    <mat-error *ngIf="fTL.Cuit.errors?.pattern">Formato invalido.</mat-error>
                </mat-form-field>
            </form>
        </div>
    </div>

    <div class="col-auto my-1" >
      <div class="col-auto my-1" *appRoll="['Garantias.Modificar']">
        <ng-container *ngIf="isEditMode">
          <button mat-raised-button color="primary" style="margin-right:3px;" *ngIf="mostrarPropiedad" name="Guardar" [disabled]=" !formPropiedad.valid" (click)="onSubmitUpdate()">Guardar</button>
          <button mat-raised-button color="primary" style="margin-right:3px;" *ngIf="mostrarPersona" name="Guardar" [disabled]="  !formPersonaGarantia.valid" (click)="onSubmitUpdate()">Guardar</button>
          <button mat-raised-button color="primary" style="margin-right:3px;" *ngIf="mostrarFianza" name="Guardar" [disabled]=" !formFianza.valid " (click)="onSubmitUpdate()">Guardar</button>
      </ng-container>
      </div>
      <div class="col-auto my-1" *appRoll="['Garantias.Crear']">
        <ng-container *ngIf="!isEditMode">
          <button mat-raised-button color="primary" style="margin-right:3px;" *ngIf="mostrarPropiedad" name="Agregar" [disabled]="!formTipoGarantia.valid ||  !formPropiedad.valid" (click)="onSubmit()">Agregar</button>
          <button mat-raised-button color="primary" style="margin-right:3px;" *ngIf="mostrarPersona" name="Agregar" [disabled]="!formTipoGarantia.valid ||  !formPersonaGarantia.valid" (click)="onSubmit()">Agregar</button>
          <button mat-raised-button color="primary" style="margin-right:3px;" *ngIf="mostrarFianza" name="Agregar" [disabled]="!formTipoGarantia.valid || !formFianza.valid " (click)="onSubmit()">Agregar</button>
      </ng-container>
      </div>

        <button mat-button color="warn" style="margin-right:3px;" name="Cancelar" (click)="onReset()">Cancelar</button>
    </div>
</mat-card>

<mat-card>
    <ngx-spinner name="spListadoGarantias" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
    <table mat-table [dataSource]="dataSource" matSort matSortStart="asc" [hidden]=!data class="mat-elevation-z8">
        <!-- <ng-container matColumnDef="id">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> # </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let garantia"> {{garantia.id}} </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="tipoGarantia">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Garantia </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let garantia"> {{garantia.tipoGarantia | titlecase}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="legajo">
            <mat-header-cell mat-header-cell *matHeaderCellDef>Legajo </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let garantia">
                <span *ngIf="garantia.idTipoFolio == 1" #tooltip="matTooltip" matTooltipPosition="right" matTooltip="Folio Personal Cronologico">
                FPC &nbsp;
            </span>
                <span *ngIf="garantia.idTipoFolio == 2" #tooltip="matTooltip" matTooltipPosition="right" matTooltip="Folio Personal">
                FP &nbsp;
            </span>
                <span *ngIf="garantia.idTipoFolio == 3" #tooltip="matTooltip" matTooltipPosition="right" matTooltip="Sin Folio">
                SF &nbsp;
            </span>
                <mat-icon *ngIf="garantia.idTipoFolio == 1 || garantia.idTipoFolio == 1" [matTooltip]="'Tomo: ' + garantia.tomo==null?'':garantia.tomo + '\n Folio: '+ garantia.nroFolio==null?'':garantia.nroFolio + '\n Nùmero: '+ garantia.numero==null?'':garantia.numero " [matTooltipClass]="'allow-cr'">info</mat-icon>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="razonSocial">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Razon Social </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let garantia"> {{garantia.razonSocial==null?'':garantia.razonSocial | titlecase}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cuitCuil">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Cuit-Cuil </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let garantia"> {{garantia.cuitCuil==null?'':garantia.cuitCuil}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sueldo">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Sueldo </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let garantia"> {{garantia.sueldo==null?0:garantia.sueldo | currency}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="toggle">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Valida </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let garantia">
                <mat-slide-toggle matTooltip="Validar garantia" [matTooltipDisabled]="garantia.activo" matTooltipPosition="right" [checked]="garantia.activo" (change)="actualizaActivo(garantia)"></mat-slide-toggle>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="accion">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Acción</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let garantia">
                <button mat-icon-button *appRoll="['Garantias.Modificar']" color="primary" aria-label="Example icon button with a filter list icon">
                <a (click)="editarGarantia(garantia)" matTooltip="Editar"
                matTooltipPosition="right"><mat-icon>edit</mat-icon></a>
              </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </table>
    <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="10" [pageSizeOptions]="[5, 10, 20,50,100,200]">
    </mat-paginator>

    <div class="no-data-table">
        <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
    </div>
</mat-card>
