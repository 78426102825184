import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/modules/shared.module";
import { BtnNuevoTipoSelladoComponent } from "../views/tipos-sellados/btn-nuevo-tipo-sellado/btn-nuevo-tipo-sellado.component";
import { DialogTipoSelladoComponent } from "../views/tipos-sellados/dialog-tipo-sellado/dialog-tipo-sellado.component";
import { TiposSelladoComponent } from "../views/tipos-sellados/tipos-sellado/tipos-sellado.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        BtnNuevoTipoSelladoComponent,
        DialogTipoSelladoComponent,
        TiposSelladoComponent,
    ],
    exports: [
        BtnNuevoTipoSelladoComponent,
        DialogTipoSelladoComponent,
        TiposSelladoComponent,
    ],
    providers: [
    ],
})

export class TipoSelladoModule { }
