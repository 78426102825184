import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { DialogDeletePersonaComponent } from '../dialog-delete-persona/dialog-delete-persona.component';

@Component({
  selector: 'app-btn-delete-persona',
  templateUrl: './btn-delete-persona.component.html',
  styleUrls: ['./btn-delete-persona.component.css']
})
export class BtnDeletePersonaComponent implements OnInit {
  @Input() personaDetailDto: PersonaDetailDto = new PersonaDetailDto;

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }


  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = this.personaDetailDto

    const dialogRef = this.dialog.open(DialogDeletePersonaComponent, dialogConfig);
  }

}
