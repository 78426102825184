import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HotToastService, ToastConfig } from '@ngneat/hot-toast';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Helper } from 'src/app/helper/helper';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { CreateSettlementResponse } from '@models/propietarios/createSettlementResponse.model';
import { SettlementByPropertyDto } from '@models/propietarios/settlementByPropertyDto.model';
import { SettlementResponse } from '@models/propietarios/settlementResponse.model';
import { LoginService } from '@services/login/login.service';
import { PropietariosService } from '@services/propietarios/propietarios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { UsuariosService } from '@services/usuarios/usuarios.service';
import { isFalsy } from 'utility-types';
import { DialogLiquidacionesGeneradasComponent } from './dialog-liquidaciones-generadas/dialog-liquidaciones-generadas.component';
import { DialogVisualizaLiquidacionComponent } from './dialog-visualiza-liquidacion/dialog-visualiza-liquidacion.component';

@Component({
  selector: 'app-dialog-nueva-liquidacion',
  templateUrl: './dialog-nueva-liquidacion.component.html',
  styleUrls: ['./dialog-nueva-liquidacion.component.css'],
  providers: [DatePipe]
})
export class DialogNuevaLiquidacionComponent implements OnInit {
  nuevoLiquidacionFormGroup: FormGroup;
  idContrato: any;
  contratoDetailDto!: ContratoDetailDto
  esLiquidacionGenerada: boolean = false;
  settlementResponse!: SettlementResponse;
  get f() { return this.nuevoLiquidacionFormGroup.controls; }

  constructor
    (
      private _formBuilder: FormBuilder,
      public dialogRef: MatDialogRef<DialogNuevaLiquidacionComponent>,
      private propietariosService: PropietariosService,
      private toastService: HotToastService,
      public datepipe: DatePipe,
      private dialog: MatDialog,
      private snackBar: SnackBarService,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    this.contratoDetailDto = data

    this.nuevoLiquidacionFormGroup = this._formBuilder.group({
    });
  }

  ngOnInit(): void {
    let toasConfig = new ToastConfig
    toasConfig.autoClose = false
    toasConfig.reverseOrder = true
    toasConfig.position = 'bottom-right'
    this.toastService.defaultConfig = toasConfig
    let date = Helper.formatDateYYYYMMDD(new Date())
    this.getSettlement(date, [], this.contratoDetailDto.id)
  }



  visualizaLiquidacion() {
    this.openDialogVisualizaLiquidacionComponent(this.settlementResponse)
  }

  getSettlement(date: string, idsPropietarios: number[], idContrato: number) {
    const toastId = this.toastService.loading('Por favor, aguarde', {
      icon: '⏳',  // Puedes usar un emoji o un ícono de librería
      autoClose: false  // Mantener el loading hasta que se actualice manualmente
    });
    this.propietariosService.getSettlement(date, [], idContrato)
      .subscribe(
        data => {
          this.toastService.success('Proceso finalizado con éxito.');
          toastId.close()
          if (data.settlementsByProperty.length != 0) {
            data.settlementsByProperty.forEach(element => {
              if (element.contratos.length >= 1) {
                this.settlementResponse = data
              }
            })
          }
          else
          {
            this.toastService.info("No se registraron datos.");
            toastId.close()
          }

        },error => {
          this.toastService.error('Error en el proceso.');
          toastId.close()
        }
      );
  }


  openDialogVisualizaLiquidacionComponent(settlementResponse: SettlementResponse) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "100%";
    dialogConfig.autoFocus = false;
    dialogConfig.data = { settlementResponse: settlementResponse, contratoDetailDto: this.contratoDetailDto }

    const dialogRef = this.dialog.open(DialogVisualizaLiquidacionComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.dialog.closeAll()
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
