export class PeriodoContratoFilter {
  public id!: number
  public idContrato!: number
  public pagado!: boolean
  public fechaVencimientoDesde!: string | null
  public fechaVencimientoHasta!: string | null
  public fechaVencimientoRetroactiva!: string | null
  public fechaLiquidacionDesde!: string | null
  public fechaLiquidacionHasta!: string | null
  public periodoMes!: number | null
  public periodoAnio!: number | null
  public aplicarFiltroPerAnioMesRetroactivo!: boolean
  public incluirFechaLiquidacionNula!: boolean
  public fechaAjusteDesde!: string | null
  public fechaAjusteHasta!: string | null
  public fechaPagoDesde!: string | null
  public fechaPagoHasta!: string | null
  public idsTipoAjusteContrato!: number[]
  public numeroContrato!: number
}
