import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavSearchPerfilLogoComponent } from '../views/nav-search-perfil-logo/nav-search-perfil-logo.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { MenuItemComponent } from '../views/menu-item/menu-item.component';
import { MenuSettingComponent } from '../views/menu-setting/menu-setting.component';
import { ConfiguracionModule } from './configuracion.module';
import { NotificacionesModule } from './notificaciones.module';
import { ApiVersionModule } from './api-version.module';



@NgModule({
  declarations: [
    NavSearchPerfilLogoComponent,
    MenuItemComponent,
    MenuSettingComponent,

  ],
  imports: [
    CommonModule,
    ConfiguracionModule,
    SharedModule,
    NotificacionesModule,
    ApiVersionModule
  ],
  exports:[
    NavSearchPerfilLogoComponent,
    MenuItemComponent,
    MenuSettingComponent]
})

export class NavSearchPerfilLogoModule  {
}
