import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Roles } from '@models/base/identificadores.model';
import { RolDto } from '@models/seguridad/roles/rolDto.model';
import { LoginService } from '@services/login/login.service';

@Component({
  selector: 'app-menu-setting',
  templateUrl: './menu-setting.component.html',
  styleUrls: ['./menu-setting.component.css']
})
export class MenuSettingComponent implements OnInit {

  Permisos: string = '';
  rol!: RolDto;
  isEditable: boolean = false

  constructor(
    private router: Router,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
  }

  logOut() {
    this.loginService.logout();
  }

}
