
import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, EstadosDetalleServicioPropiedad } from '@models/base/identificadores.model';
import { DetalleServicioPropiedadDetailDto } from '@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadDetailDto.model';
import { UpdateDetalleServicioPropiedadRequest } from '@models/servicios/detalle-servicio-propiedad/updateDetalleServicioPropiedadRequest.model';
import { ServicioPropiedadDto } from '@models/servicios/servicio-propiedad/servicioPropiedadDto.model';
import { DetalleServicioPropiedadService } from '@services/servicios/detalle-servicios-propiedad/detalle-servicio-propiedad.service';
import { ServiciosPropiedadService } from '@services/servicios/servicios-propiedad/servicios-propiedad.service';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import * as moment from 'moment';
import { PropiedadServicio } from '../listado-detalle-servicio-por-propiedad/listado-detalle-servicio-por-propiedad.component';
import { isFalsy } from 'utility-types';
import { QuienPagaService } from '@services/quien-paga/quien-paga.service';
import { DetalleServicioPropiedadController } from '@controllers/detalleServicioPropiedad.controller';



@Component({
  selector: 'app-dialog-editar-detalle-servicio',
  templateUrl: './dialog-editar-detalle-servicio.component.html',
  styleUrls: ['./dialog-editar-detalle-servicio.component.css']
})
export class DialogEditarDetalleServicioComponent implements OnInit {

  idDetalleServicio!: any
  formDetalleServicioPriopidad: any;
  formBuilder: FormBuilder = new FormBuilder;
  sub: any;
  idQuienPaga!: number;
  idServicio!: number;
  valorComision!: number;
  enableComision: any;
  servicio!: ServicioPropiedadDto;
  idPropiedad: any;
  get f() { return this.formDetalleServicioPriopidad.controls; }
  lstQuienPaga: BaseDto[] = []
  lstPeriocidad: BaseDto[] = [
    {
      id: 1,
      descripcion: "Unitaria"
    },
    {
      id: 2,
      descripcion: "Mensual"
    },
    {
      id: 3,
      descripcion: "Bimestral"
    },
    {
      id: 3,
      descripcion: "Trimestral"
    },
  ]
  public detalleServicioPropiedadDetailDto: DetalleServicioPropiedadDetailDto = new DetalleServicioPropiedadDetailDto
  lstservicioPropiedadDto: ServicioPropiedadDto[] = []
  comision: any
  estadoComision: boolean = false
  importeVencimiento1: any
  importeVencimiento2: any
  importeVencimiento3: any
  importeVencimiento4: any
  updateDetalleServicioPropiedadRequest = new UpdateDetalleServicioPropiedadRequest


  constructor(
    public dialogRef: MatDialogRef<DialogEditarDetalleServicioComponent>,
    private serviciosPropiedadService: ServiciosPropiedadService,
    private serviciosService: ServiciosService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

    private quienPagaService: QuienPagaService,
    private detalleServicioPropiedadService: DetalleServicioPropiedadService,
    private aRoute: ActivatedRoute,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PropiedadServicio

  ) {
    this.formDetalleServicioPriopidad = this.formBuilder.group({
      //Persona
      QuienPaga: ["", Validators.compose([Validators.required])],
      Servicios: ["", Validators.compose([Validators.required])],
      Mes: ["", Validators.compose([Validators.required])],
      Anio: ["", Validators.compose([Validators.required])],
      Comision: [""],
      Periodo: [""],
      Vencimiento1: ["", Validators.compose([Validators.required])],
      ImporteVencimiento1: ["", Validators.compose([Validators.required])],
      Vencimiento2: [""],
      ImporteVencimiento2: [""],
      Vencimiento3: [""],
      ImporteVencimiento3: [""],
      Vencimiento4: [""],
      ImporteVencimiento4: [""],
    })
  }

  ngOnInit() {
    this.idDetalleServicio = this.data.idDetalleServicio
    this.idPropiedad = this.data.idPropiedad


    this.getServiciosPropiedadByIdPropiedad(this.idPropiedad);
    this.getAllQuienPaga()

    this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].disable();
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].disable();
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].disable();
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].disable();

  }

  convierteFecha(fechaOrigen: any): string {
    if (fechaOrigen != "" && fechaOrigen != null) {
      const fecha = fechaOrigen.split('-');
      const anio = fecha[0];
      const mes = fecha[1];
      const dia = fecha[2].substring(0, 2);
      const fechaCompleta = anio + "-" + mes + "-" + dia

      return fechaCompleta
    }
    return ""
  }

  setFormularioDetalle() {

    this.formDetalleServicioPriopidad.controls["QuienPaga"].setValue(this.detalleServicioPropiedadDetailDto.quienPaga.id)
    this.formDetalleServicioPriopidad.controls["Servicios"].setValue(this.detalleServicioPropiedadDetailDto.servicioPropiedad.id)
    this.formDetalleServicioPriopidad.controls["Comision"].setValue(this.detalleServicioPropiedadDetailDto.comision)
    this.formDetalleServicioPriopidad.controls["Mes"].setValue(this.detalleServicioPropiedadDetailDto.periodoMes)
    this.formDetalleServicioPriopidad.controls["Anio"].setValue(this.detalleServicioPropiedadDetailDto.periodoAnio)
    this.formDetalleServicioPriopidad.controls["Periodo"].setValue(this.detalleServicioPropiedadDetailDto.periodo)
    this.formDetalleServicioPriopidad.controls["Vencimiento1"].setValue(this.convierteFecha(this.detalleServicioPropiedadDetailDto.fechaVencimiento1))
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].setValue(this.detalleServicioPropiedadDetailDto.importeVencimiento1)
    this.formDetalleServicioPriopidad.controls["Vencimiento2"].setValue(this.convierteFecha(this.detalleServicioPropiedadDetailDto.fechaVencimiento2))
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].setValue(this.detalleServicioPropiedadDetailDto.importeVencimiento2)
    this.formDetalleServicioPriopidad.controls["Vencimiento3"].setValue(this.convierteFecha(this.detalleServicioPropiedadDetailDto.fechaVencimiento3))
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].setValue(this.detalleServicioPropiedadDetailDto.importeVencimiento3)
    this.formDetalleServicioPriopidad.controls["Vencimiento4"].setValue(this.convierteFecha(this.detalleServicioPropiedadDetailDto.fechaVencimiento4))
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].setValue(this.detalleServicioPropiedadDetailDto.importeVencimiento4)


    if(!isFalsy(this.detalleServicioPropiedadDetailDto.importeVencimiento1))
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].enable();
    if(!isFalsy(this.detalleServicioPropiedadDetailDto.importeVencimiento2))
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].enable();
    if(!isFalsy(this.detalleServicioPropiedadDetailDto.importeVencimiento3))
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].enable();
    if(!isFalsy(this.detalleServicioPropiedadDetailDto.importeVencimiento4))
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].enable();


    if (this.detalleServicioPropiedadDetailDto.estado.id == EstadosDetalleServicioPropiedad.Pagado ||
      this.detalleServicioPropiedadDetailDto.estado.id == EstadosDetalleServicioPropiedad.Cobrado)
      this.solovistaLectura()
  }

  getDetallePropiedadById(idDetalleServicio: any) {
    this.spinner.show("spDetServ");
    this.detalleServicioPropiedadService.GetById(idDetalleServicio)
      .subscribe(
        data => {
          this.spinner.hide("spDetServ");
          this.detalleServicioPropiedadDetailDto = data
          this.setFormularioDetalle()
        },
        error => {
          this.spinner.hide("spDetServ");
          this.snackBar.showError(error, "Error");
        }
      )
  }

  onVencimiento1Change(event: any, vencimiento: number) {
    const selectedDate = (event.target as HTMLInputElement).value;
    let vencimientoFecha= "Vencimiento"+vencimiento
    let importeVencimiento= "ImporteVencimiento"+vencimiento
    this.formDetalleServicioPriopidad.controls[vencimientoFecha].setValue(selectedDate);
        if (selectedDate) {
          this.formDetalleServicioPriopidad.controls[importeVencimiento].enable();
        } else {
          this.formDetalleServicioPriopidad.controls[importeVencimiento].disable();
        }
  }

  aplicarComision() {
    const comision = this.formDetalleServicioPriopidad.controls["Comision"].value
    if (!this.estadoComision) {
      this.importeVencimiento1 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].value) ? "" : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].value);
      this.importeVencimiento2 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].value) ? "" : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].value);
      this.importeVencimiento3 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].value) ? "" : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].value);
      this.importeVencimiento4 = isFalsy(this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].value) ? "" : parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].value);
      this.estadoComision = true
    }

    if (comision != "0") {
      if (this.importeVencimiento1 != "")
        this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].setValue((((comision * this.importeVencimiento1) / 100) + this.importeVencimiento1))
      if (this.importeVencimiento2 != "")
        this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].setValue((((comision * this.importeVencimiento2) / 100) + this.importeVencimiento2))
      if (this.importeVencimiento3 != "")
        this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].setValue((((comision * this.importeVencimiento3) / 100) + this.importeVencimiento3))
      if (this.importeVencimiento4 != "")
        this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].setValue((((comision * this.importeVencimiento4) / 100) + this.importeVencimiento4))
    }
    else {
      this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].setValue(this.importeVencimiento1)
      this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].setValue(this.importeVencimiento2)
      this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].setValue(this.importeVencimiento3)
      this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].setValue(this.importeVencimiento4)
    }
  }

  solovistaLectura() {
    this.formDetalleServicioPriopidad.controls["Servicios"].disable()
    this.formDetalleServicioPriopidad.controls["QuienPaga"].disable()
    this.formDetalleServicioPriopidad.controls["Servicios"].disable()
    this.formDetalleServicioPriopidad.controls["Comision"].disable()
    this.formDetalleServicioPriopidad.controls["Mes"].disable()
    this.formDetalleServicioPriopidad.controls["Anio"].disable()
    this.formDetalleServicioPriopidad.controls["Periodo"].disable()
    this.formDetalleServicioPriopidad.controls["Vencimiento1"].disable()
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].disable()
    this.formDetalleServicioPriopidad.controls["Vencimiento2"].disable()
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].disable()
    this.formDetalleServicioPriopidad.controls["Vencimiento3"].disable()
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].disable()
    this.formDetalleServicioPriopidad.controls["Vencimiento4"].disable()
    this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].disable()

  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  editarDetalle() {
    let uDSPR = this.setUpdateDetalleServicioPropiedad()
    this.updateDetalleServicioPropiedad(uDSPR)
  }

  updateDetalleServicioPropiedad(uDSPR: UpdateDetalleServicioPropiedadRequest) {
    this.spinner.show("spDetServ");
    this.detalleServicioPropiedadService.update(uDSPR)
      .subscribe(
        data => {
          this.spinner.hide("spDetServ");
          this.snackBar.showSuccess("Detalle de servicio actualizado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Modificar, data: true });
        },
        error => {
          this.spinner.hide("spDetServ");
          this.snackBar.showError(error, "Error");
          this.dialogRef.close({ event: AccionesDialog.Cancelar, data: false });
        }
      )
  }

  setUpdateDetalleServicioPropiedad(): UpdateDetalleServicioPropiedadRequest {
    let uDSPR = new UpdateDetalleServicioPropiedadRequest
    uDSPR.id = this.idDetalleServicio
    uDSPR.idServicioPropiedad = this.formDetalleServicioPriopidad.controls["Servicios"].value
    uDSPR.comision = this.formDetalleServicioPriopidad.controls["Comision"].value
    uDSPR.periodoMes = this.formDetalleServicioPriopidad.controls["Mes"].value
    uDSPR.periodoAnio = this.formDetalleServicioPriopidad.controls["Anio"].value
    uDSPR.periodo = this.formDetalleServicioPriopidad.controls["Periodo"].value
    uDSPR.fechaVencimiento1 = this.formDetalleServicioPriopidad.controls["Vencimiento1"].value
    uDSPR.fechaVencimiento2 = this.formDetalleServicioPriopidad.controls["Vencimiento2"].value
    uDSPR.fechaVencimiento3 = this.formDetalleServicioPriopidad.controls["Vencimiento3"].value
    uDSPR.fechaVencimiento4 = this.formDetalleServicioPriopidad.controls["Vencimiento4"].value
    uDSPR.importeVencimiento1 = parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento1"].value)
    uDSPR.importeVencimiento2 = parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento2"].value)
    uDSPR.importeVencimiento3 = parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento3"].value)
    uDSPR.importeVencimiento4 = parseFloat(this.formDetalleServicioPriopidad.controls["ImporteVencimiento4"].value)
    uDSPR.id_QuienPaga = this.formDetalleServicioPriopidad.controls["QuienPaga"].value
    uDSPR.id_EstadoDetalleServicioPropiedad = this.detalleServicioPropiedadDetailDto.estado.id

    let fechaVencimiento1 = moment(uDSPR.fechaVencimiento1, "YYYY-MM-DD")
    let fechaVencimiento2 = moment(uDSPR.fechaVencimiento2, "YYYY-MM-DD")
    let fechaVencimiento3 = moment(uDSPR.fechaVencimiento3, "YYYY-MM-DD")
    let fechaVencimiento4 = moment(uDSPR.fechaVencimiento4, "YYYY-MM-DD")
    let hoy = moment(new Date())


    if (fechaVencimiento1 >= hoy ||
      fechaVencimiento2 >= hoy ||
      fechaVencimiento3 >= hoy ||
      fechaVencimiento4 >= hoy &&
      this.detalleServicioPropiedadDetailDto.estado.id == EstadosDetalleServicioPropiedad.Deuda)
      uDSPR.id_EstadoDetalleServicioPropiedad = EstadosDetalleServicioPropiedad.NoVencido

    if (isFalsy(uDSPR.importeVencimiento1) && isFalsy(uDSPR.fechaVencimiento1)) {
      uDSPR.importeVencimiento1 = null
      uDSPR.fechaVencimiento1 = null
    }
    if (isFalsy(uDSPR.importeVencimiento2) && isFalsy(uDSPR.fechaVencimiento2)) {
      uDSPR.importeVencimiento2 = null
      uDSPR.fechaVencimiento2 = null
    }
    if (isFalsy(uDSPR.importeVencimiento3) && isFalsy(uDSPR.fechaVencimiento3)) {
      uDSPR.importeVencimiento3 = null
      uDSPR.fechaVencimiento3 = null
    }
    if (isFalsy(uDSPR.importeVencimiento4) && isFalsy(uDSPR.fechaVencimiento4)) {
      uDSPR.importeVencimiento4 = null
      uDSPR.fechaVencimiento4 = null
    }
    return uDSPR
  }

  checkComision(event: any) {
    this.enableComision = event
    this.valorComision = 0.02
  }

  selectQuienPaga(event: any) {
    this.idQuienPaga = event.value
  }

  selectPeriocidad(event: any) {
    this.idQuienPaga = event.value
  }

  selectServicio(event: any) {
    this.idServicio = event.value
  }

  getAllQuienPaga() {
    this.spinner.show("spBusquedaQuienPaga");
    this.quienPagaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaQuienPaga");
          this.lstQuienPaga = data
        },
        error => {
          this.spinner.hide("spBusquedaQuienPaga");
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getServiciosPropiedadByIdPropiedad(idPropiedad: number) {
    this.spinner.show("spBusquedaServicios");
    this.serviciosPropiedadService.getServiciosPropiedadByIdPropiedad(idPropiedad).subscribe(
      async data => {
        this.spinner.hide("spBusquedaServicios");
        this.lstservicioPropiedadDto = data as ServicioPropiedadDto[]
        await this.getDetallePropiedadById(this.idDetalleServicio)
      },
      error => {
        this.spinner.hide("spBusquedaServicios");
        this.snackBar.showError(error, "Error");
      })
  }

  getServiciosPropiedadById(id: number) {
    this.spinner.show("spDetServ");
    this.serviciosPropiedadService.getServicioPropiedadById(id).subscribe(
      async data => {
        this.spinner.hide("spDetServ");
        this.servicio = data as ServicioPropiedadDto
      },
      error => {
        this.spinner.hide("spDetServ");
        this.snackBar.showError(error, "Error");
      })
  }

  trackByItems(index: number, item: any): any { return item; }
}
