import { AutoMap } from "@automapper/classes";
import { BaseDto } from "../base/baseDto.model";
import { PaisDto } from "../pais/paisDto.model";

export class ProvinciaDto extends BaseDto {
  @AutoMap() public id!: number
  @AutoMap() public nombre: string = "";
  @AutoMap() public nombreCompleto: string = "";
  @AutoMap() public fuente: string = "";
  @AutoMap() public centroideLon: number = 0;
  @AutoMap() public centroideLat: number = 0;
  @AutoMap(() => PaisDto) public pais!: PaisDto

  static fromProvinciaDto(provinciaDto: ProvinciaDto) {
    const dto = new ProvinciaDto();
    dto.id = provinciaDto.id;
    dto.descripcion = provinciaDto.descripcion;
    dto.pais = PaisDto.fromPaisDto(provinciaDto.pais);
    dto.centroideLon = provinciaDto.centroideLon;
    dto.centroideLat = provinciaDto.centroideLat;
    dto.fuente = provinciaDto.fuente;
    dto.nombreCompleto = provinciaDto.nombreCompleto;
    dto.nombre = provinciaDto.nombre;
    return dto;
  }
}
