<div class="row content">
    <div class="col-sm-2">
        <app-nav-panel-propiedad [idPropiedad]="idPropiedad"></app-nav-panel-propiedad>
    </div>
    <div class="col-sm-10">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Contratos</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">gavel</mat-icon>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <app-listado-contratos [idPropiedad]="idPropiedad"></app-listado-contratos>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>