import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-card-contrato',
  templateUrl: './card-contrato.component.html',
  styleUrls: ['./card-contrato.component.css']
})
export class CardContratoComponent implements OnInit {

  @Input() contratoDetailDto!: ContratoDetailDto
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ContratoDetailDto
  ) { }

  ngOnInit(): void {
    if(isFalsy(this.contratoDetailDto))
    this.contratoDetailDto = new ContratoDetailDto
    if(isFalsy(this.data))
      this.contratoDetailDto = this.data
  }

  isFalsy(valor:any){
    return isFalsy(valor)
  }

}
