import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogDeleteComponent } from 'src/app/views/genericos/dialog-delete/dialog-delete.component';
import { AccionesDialog } from '@models/base/identificadores.model';
import { PersonaCuentaContableDto } from '@models/cuentas-contable-persona/personaCuentaContableDto.model';
import { PersonasCuentasContableService } from '@services/personas-cuentas-contable/personas-cuentas-contable.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogCuentaContablePersonaComponent } from '../dialog-cuenta-contable-persona/dialog-cuenta-contable-persona.component';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';


@Component({
  selector: 'app-listado-cuentas-contable-persona',
  templateUrl: './listado-cuentas-contable-persona.component.html',
  styleUrls: ['./listado-cuentas-contable-persona.component.css']
})
export class ListadoCuentasContablePersonaComponent implements OnInit {
  cuentaContablePersonsDto: PersonaCuentaContableDto = new PersonaCuentaContableDto
  data = false
  dataSource = new MatTableDataSource<PersonaCuentaContableDto>();
  cuentaContable: PersonaCuentaContableDto = new PersonaCuentaContableDto
  @Input() personaDetailDto!: PersonaDetailDto

  displayedColumns: string[] = [];

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  constructor(
    public dialog: MatDialog,
    private personasService: PersonasService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private personasCuentasContableService: PersonasCuentasContableService,
    private breakpointObserver: BreakpointObserver
  ) {

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['cuentaContableDescripcion', 'activo', 'accion'] :
        ['cuentaContableDescripcion', 'cuentaContableCbu', 'cuentaContableAlias', 'descripcion', 'activo', 'accion'];
    });

  }

  ngOnInit(): void {

    this.personaDetailDto = this.personasService.getPersona()
    this.getAllPersonasCuentasContableByIdPersona(this.personaDetailDto.id)
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openDialog(action: any, personaCuentaContableDto: PersonaCuentaContableDto) {
    let persona = this.personaDetailDto
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    dialogConfig.data = { action, persona, personaCuentaContableDto }


    const dialogRef = this.dialog.open(DialogCuentaContablePersonaComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar)
        this.getAllPersonasCuentasContableByIdPersona(this.personaDetailDto.id)
    });
  }


  openDialogDelete(action: any, id: number) {
    let obj = { action: action, id: id };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.delete(result.data);
      }
    });
  }


  delete(personaCuentaContableDto: PersonaCuentaContableDto) {
    this.spinner.show()
    this.personasCuentasContableService.delete(personaCuentaContableDto.id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = this.dataSource.data.filter((value: { id: number; }, key: any) => {
            return value.id != personaCuentaContableDto.id;
          });
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError("El registro no se puede eliminar porque tiene referencias asignadas.", "Error");
        }
      )
  }

  getAllPersonasCuentasContableByIdPersona(idPersona: number) {
    this.spinner.show()
    this.personasCuentasContableService.getAllByIdPersona(idPersona)
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }


  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

}
