import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CaracteristicaPropiedad } from '@models/propiedades/caracteristicaPropiedad.model';
import { PropiedadCaracteristicaPropiedadDto } from '@models/propiedades/propiedadCaracteristicaPropiedadDto.model';
import { PropiedadesService } from '@services/propiedades/propiedades.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { CaracteristicasPropiedadService } from '@services/caracteristicas-propiedad/caracteristicas-propiedad.service';

@Component({
  selector: 'app-create-dialog-caracteristica-propiedad',
  templateUrl: './create-dialog-caracteristica-propiedad.component.html',
  styleUrls: ['./create-dialog-caracteristica-propiedad.component.css']
})
export class CreateDialogCaracteristicaPropiedadComponent implements OnInit {

  lstCaracteristicasPropiedad: CaracteristicaPropiedad[] = []
  formInstance: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;

  constructor(
    public dialogRef: MatDialogRef<CreateDialogCaracteristicaPropiedadComponent>,
    private caracteristicasPropiedadService: CaracteristicasPropiedadService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: PropiedadCaracteristicaPropiedadDto) {


    this.formInstance = this.formBuilder.group({
      Caracteristica: ['', Validators.compose([Validators.required])],
      Cantidad: ['', Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
    this.getAllCaracteristicas()
  }

  agregar(): void {
    const propiedadCaracteristicaPropiedadDto = new  PropiedadCaracteristicaPropiedadDto
    const caracteristicaPropiedad =  this.formInstance.controls["Caracteristica"].value
    propiedadCaracteristicaPropiedadDto.caracteristica.id = caracteristicaPropiedad.id
    propiedadCaracteristicaPropiedadDto.caracteristica.descripcion = caracteristicaPropiedad.descripcion
    propiedadCaracteristicaPropiedadDto.cantidad = this.formInstance.controls["Cantidad"].value
    this.dialogRef.close({data:propiedadCaracteristicaPropiedadDto, event:AccionesDialog.Agregar});

  }

  getAllCaracteristicas() {
    this.spinner.show("spBusqueda");
    this.caracteristicasPropiedadService.getAllCaracteristicasPropiedad().subscribe(
      data => {
        this.spinner.hide("spBusqueda");
        this.lstCaracteristicasPropiedad = data
      },
      error => {
        this.spinner.hide("spBusqueda");
        this.snackBar.showError( error, "Error");
      }
    )
  }

  trackByItems(index: number, item: any): any { return item; }

}
