<div *appRole="['Servicios.Ver']">

<mat-slide-toggle [(ngModel)]="ayudaOn" class="example-margin" color="primary">
  Mostrar instructivos de cada servicios para cargar Nro. de cuenta.
</mat-slide-toggle>
<br>
<br>
<div *ngIf="ayudaOn" >
  <!-- Aguas Santafesionas -->
  <mat-expansion-panel>
      <mat-expansion-panel-header>
          <mat-panel-title>
              Aguas Santafesinas
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
      </mat-expansion-panel-header>
      <p>El <b>Nro. de gestion personal </b> asociado a dicha boleta es el Nro. de cuenta contrato. (16) Ej: 20628418</p>
      <p>El <b>Nro. de cuenta </b> asociado a dicha boleta es el Nro. de cuenta contrato. (16) Ej: 20628418</p>
      <br>
      <br>
      <div>
          <img src="https://www.aguassantafesinas.com.ar/portal/wp-content/uploads/2020/05/como-leer-su-factura-1.jpg" srcset="https://www.aguassantafesinas.com.ar/portal/wp-content/uploads/2020/05/como-leer-su-factura-1.jpg 200w,
  https://www.aguassantafesinas.com.ar/portal/wp-content/uploads/2020/05/como-leer-su-factura-1.jpg 400w" sizes="(min-width: 600px) 200px, 50vw" height="800px">
      </div>
  </mat-expansion-panel>
  <!-- Litoral Gas -->
  <mat-expansion-panel>
      <mat-expansion-panel-header>
          <mat-panel-title>
              Litoral Gas
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
      </mat-expansion-panel-header>
      <p>El <b>Nro. de gestion personal </b> asociado a dicha boleta es el "N° CLIENTE" Ej: 0092963301</p>
      <p>El <b>Nro. de cuenta </b> asociado a dicha boleta es el "N° CLIENTE" Ej: 0092963301</p>
      <br>
      <br>
      <div>
          <img src="https://massoudwiki.clientes.mariniti.com.ar/imagenes/LitoralGasFactura.png" srcset="https://massoudwiki.clientes.mariniti.com.ar/imagenes/LitoralGasFactura.png 200w,
      https://massoudwiki.clientes.mariniti.com.ar/imagenes/LitoralGasFactura.png 400w" sizes="(min-width: 600px) 200px, 50vw" height="800px">
      </div>
  </mat-expansion-panel>
  <!-- EPE -->
  <mat-expansion-panel>
      <mat-expansion-panel-header>
          <mat-panel-title>
              EPE
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
      </mat-expansion-panel-header>
      <p>El <b>Nro. de gestion personal </b> asociado a dicha boleta es el "Numero de Cliene" Ej:000962397 </p>
      <p>El <b>Nro. de cuenta </b> asociado a dicha boleta es el "Numero de Cliene" Ej:000962397 </p>
      <br>
      <br>
      <div>
          <img src="https://massoudwiki.clientes.mariniti.com.ar/imagenes/EPEFactura.jpeg" srcset="https://massoudwiki.clientes.mariniti.com.ar/imagenes/EPEFactura.jpeg 200w,
      https://massoudwiki.clientes.mariniti.com.ar/imagenes/EPEFactura.jpeg 400w" sizes="(min-width: 600px) 200px, 50vw" height="800px">
      </div>
  </mat-expansion-panel>
  <!-- TGI -->
  <mat-expansion-panel>
      <mat-expansion-panel-header>
          <mat-panel-title>
              TGI
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
      </mat-expansion-panel-header>
      <p>El <b>Nro. de gestion personal </b> asociado a dicha boleta es el "Cód. Gestión Personal" Ej:70396705 </p>
      <p>El <b>Nro. de cuenta </b> asociado a dicha boleta es el "Cuenta" Ej:22949802 </p>
      <p>El <b>Nro. de referencia de pago </b> asociado a dicha boleta es el "Referencia de Pago" Ej:81225478 </p>
      <br>
      <br>
      <div>
          <img src="https://massoudwiki.clientes.mariniti.com.ar/imagenes/TGIFactura.png" srcset="https://massoudwiki.clientes.mariniti.com.ar/imagenes/TGIFactura.png 200w,
    https://massoudwiki.clientes.mariniti.com.ar/imagenes/TGIFactura.png 400w" sizes="(min-width: 600px) 200px, 50vw" height="800px">
      </div>
  </mat-expansion-panel>
  <!-- API -->
  <mat-expansion-panel>
      <mat-expansion-panel-header>
          <mat-panel-title>
              API
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
      </mat-expansion-panel-header>
      <p>El <b>Nro. de gestion personal </b> asociado a dicha boleta es "CODIGO PARTIDA" Ej: 1650334 </p>
      <p>El <b>Nro. de cuenta </b> asociado a dicha boleta es "PARTIDA" Ej: 160309-280969/0018-8</p>
      <p>El <b>Nro. de referencia de pago </b> asociado a dicha boleta es "PARTIDA" Ej: 160309-280969/0018-8 </p>
      <br>
      <br>
      <div>
          <img src="https://massoudwiki.clientes.mariniti.com.ar/imagenes/APIFactura.png" srcset="https://massoudwiki.clientes.mariniti.com.ar/imagenes/APIFactura.png 200w,
    https://massoudwiki.clientes.mariniti.com.ar/imagenes/APIFactura.png 400w" sizes="(min-width: 600px) 200px, 50vw" height="800px">
      </div>
  </mat-expansion-panel>
</div>
</div>
