
import { ContratoDto } from "./contratoDto.model";
import { ContratoGarantiaDto } from "./contratoGarantiaDto.model";
import { ContratoPropietarioDto } from "./contratoPropietarioDto.model";
import { PeriodoContratoSummaryDto } from "../periodos-contrato/periodoContratoSummaryDto.model";

export class CreateContratoRequest {
    public contrato:ContratoDto = new ContratoDto
    public garantiasContrato:ContratoGarantiaDto[] =[]
    public propietariosContrato: ContratoPropietarioDto[] = []
    public periodosContrato: PeriodoContratoSummaryDto[]= []
}
