import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { UpdateMontosPorBrocheRequest } from '@models/servicios/detalle-servicio-propiedad/updateMontosPorBrocheRequest.mode';
import { UpdateMontosPorBrocheResponse } from '@models/servicios/detalle-servicio-propiedad/updateMontosPorBrocheResponse.model';
import { ServicioDto } from '@models/servicios/servicio/servicioDto.model';
import { DetalleServicioPropiedadService } from '@services/servicios/detalle-servicios-propiedad/detalle-servicio-propiedad.service';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { validarMMYYYY } from '@helpers/validations/dates.validator';

@Component({
  selector: 'app-dialog-actualiza-montos-broches',
  templateUrl: './dialog-actualiza-montos-broches.component.html',
  styleUrls: ['./dialog-actualiza-montos-broches.component.css']
})
export class DialogActualizaMontosBrochesComponent implements OnInit {

  @Input() idPropiedad!: number
  formActualizaMontosBroches: any;
  formBuilder: FormBuilder = new FormBuilder;
  sub: any;
  idQuienPaga!: number;
  idServicio!: number;
  valorComision!: number;
  enableComision: any;
  servicio!: ServicioDto;
  lstUpdateMontosPorBrocheResponse!: UpdateMontosPorBrocheResponse;
  isLoading: boolean = false;
  get f() { return this.formActualizaMontosBroches.controls; }
  lstServicios: ServicioDto[] = []
  PeriodoOringen: any
  constructor(
    public dialogRef: MatDialogRef<DialogActualizaMontosBrochesComponent>,
    private serviciosService: ServiciosService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private detalleServicioPropiedadServoce: DetalleServicioPropiedadService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: number
  ) {
    this.formActualizaMontosBroches = this.formBuilder.group({
      //Persona
      Servicios: ["", Validators.compose([Validators.required])],
      Diferencia: ["", Validators.compose([Validators.required])],
      PeriodoOrigen: ["", Validators.compose([Validators.required, Validators.pattern(/[\d]{2}\/[\d]{4}/)])],
      PeriodoDestino: ["", Validators.compose([Validators.required, Validators.pattern(/[\d]{2}\/[\d]{4}/)])],
      Vencimiento1: ["", Validators.compose([Validators.required])],
      Vencimiento2: ["", Validators.compose([Validators.required])],
      Vencimiento3: [""],
      Vencimiento4: [""],
    },
      {
      });
  }

  ngOnInit() {
    this.idPropiedad = this.data
    this.getServicios();
    const fechaActual = new Date()
    const fechaActualMasMesProximo = new Date(fechaActual.setMonth(fechaActual.getMonth()));
    let periodoOrigen = new Intl.DateTimeFormat('en-US', { month: '2-digit', year: 'numeric' }).format(new Date());
    let periodoDestino = new Intl.DateTimeFormat('en-US', { month: '2-digit', year: 'numeric' }).format(fechaActualMasMesProximo);

    this.formActualizaMontosBroches.controls["PeriodoOrigen"].setValue(periodoOrigen)
    this.formActualizaMontosBroches.controls["PeriodoDestino"].setValue(periodoDestino)
  }

  validaFecha()
  {
    this.formActualizaMontosBroches.controls["PeriodoOrigen"].addValidators(validarMMYYYY())
    this.formActualizaMontosBroches.controls["PeriodoDestino"].addValidators(validarMMYYYY())
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }
  agregarDetalle() {
    let fechaValidas = this.validarFechasVencimientos()

    if (fechaValidas == false)
      (
        this.snackBar.showError("Las fechas son invalidas", "Error")
      )
    else {
      this.isLoading = true
      this.spinner.show("spEjecutaProceso")
      let updateMontosPorBrocheRequest = this.setUpdateMontosPorBrocheRequest()
      this.detalleServicioPropiedadServoce.updateMontosPorBrocheRequest(updateMontosPorBrocheRequest).subscribe(
        data => {
          this.spinner.hide("spEjecutaProceso")
          this.isLoading = false
          let cantidadRegistrosActualizado = data.ids.length
          if(cantidadRegistrosActualizado > 0)
          this.snackBar.showSuccess("Cantidad de registros actualizados: " + cantidadRegistrosActualizado.toString(), "Exito");
          else
          this.snackBar.showWarn("No se copiaron registros o ya existen para el periodo destino.", "Exito");
        },
        error => {
          this.spinner.hide("spEjecutaProceso")
          this.isLoading = false
          this.snackBar.showError(error, "Error");
        })
    }

  }

  validarFechasVencimientos(): boolean {
    let fechaValidas = true

    let vencimiento1 = this.formActualizaMontosBroches.controls["Vencimiento1"].value
    let vencimiento2 = this.formActualizaMontosBroches.controls["Vencimiento2"].value
    let vencimiento3 = this.formActualizaMontosBroches.controls["Vencimiento3"].value
    let vencimiento4 = this.formActualizaMontosBroches.controls["Vencimiento4"].value

    let fechaVencimiento1 = (vencimiento1 == "") ? false : true
    let fechaVencimiento2 = (vencimiento2 == "") ? false : true
    let fechaVencimiento3 = (vencimiento3 == "") ? false : true
    let fechaVencimiento4 = (vencimiento4 == "") ? false : true

    if (fechaVencimiento1 == true && fechaVencimiento2 == true) {
      if (new Date(vencimiento1) > new Date(vencimiento2)) {
        fechaValidas = false
      }
      else {
        fechaValidas = true
        if (fechaVencimiento2 == true && fechaVencimiento3 == true && fechaValidas == true) {
          if (new Date(vencimiento2) > new Date(vencimiento3)) {
            fechaValidas = false
          }
          else {
            fechaValidas = true
            if (fechaVencimiento3 == true && fechaVencimiento4 == true && fechaValidas == true) {
              if (new Date(vencimiento3) > new Date(vencimiento4)) {
                fechaValidas = false
              }
              else {
                fechaValidas = true
              }
            }
          }
        }
      }
    }
    else {
      fechaValidas = false
    }
    return fechaValidas
  }

  setUpdateMontosPorBrocheRequest(): UpdateMontosPorBrocheRequest {
    const updateMontosPorBrocheRequest = new UpdateMontosPorBrocheRequest
    updateMontosPorBrocheRequest.idServicio = this.formActualizaMontosBroches.controls["Servicios"].value
    updateMontosPorBrocheRequest.periodoOrigen = this.formActualizaMontosBroches.controls["PeriodoOrigen"].value
    updateMontosPorBrocheRequest.periodoDestino = this.formActualizaMontosBroches.controls["PeriodoDestino"].value
    updateMontosPorBrocheRequest.porcentaje = this.formActualizaMontosBroches.controls["Diferencia"].value
    updateMontosPorBrocheRequest.fechaVencimiento1 = isFalsy(this.formActualizaMontosBroches.controls["Vencimiento1"].value) ? undefined : this.formActualizaMontosBroches.controls["Vencimiento1"].value
    updateMontosPorBrocheRequest.fechaVencimiento2 = isFalsy(this.formActualizaMontosBroches.controls["Vencimiento2"].value) ? undefined : this.formActualizaMontosBroches.controls["Vencimiento2"].value
    updateMontosPorBrocheRequest.fechaVencimiento3 = isFalsy(this.formActualizaMontosBroches.controls["Vencimiento3"].value) ? undefined : this.formActualizaMontosBroches.controls["Vencimiento3"].value
    updateMontosPorBrocheRequest.fechaVencimiento4 = isFalsy(this.formActualizaMontosBroches.controls["Vencimiento4"].value) ? undefined : this.formActualizaMontosBroches.controls["Vencimiento4"].value

    return updateMontosPorBrocheRequest
  }



  selectServicio(event: any) {
    this.idServicio = event.value
  }


  getServicios() {
    this.spinner.show("spServicios");
    this.serviciosService.getAll().subscribe(
      data => {
        this.spinner.hide("spServicios");
        this.lstServicios = data as ServicioDto[]
      },
      error => {
        this.spinner.hide("spServicios");
        this.snackBar.showError(error, "Error");
      })
  }

  trackByItems(index: number, item: any): any { return item; }

}
