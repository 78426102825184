import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { NgxSpinnerService } from 'ngx-spinner';
import { AsignarGarantiaInquilinoRequest } from '@models/garantia/asignarGarantiaInquilinoRequest.model';
import { GarantiaSummaryDto } from '@models/garantia/garantiaSummaryDto.model';
import { PersonaGaranteDto } from '@models/garantia/personaGaranteDto.model';
import { RenterDeliveredNecessaryGuaranteesResponse } from '@models/inquilino/renterDeliveredNecessaryGuaranteesResponse.model';
import { GarantiasService } from '@services/garantias/garantias.service';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'app-asignar-garantia-a-inquilino',
  templateUrl: './asignar-garantia-a-inquilino.component.html',
  styleUrls: ['./asignar-garantia-a-inquilino.component.css']
})
export class AsignarGarantiaAInquilinoComponent implements OnInit {

  personaGarante!: PersonaGaranteDto
  formBuilder: FormBuilder = new FormBuilder;
  formGarantia!: FormGroup;
  garantiasInquilino: GarantiaSummaryDto[] = []
  garantias: GarantiaSummaryDto[] = [];
  @ViewChild(MatSelectionList) listGarantia!: MatSelectionList;
  @Output() asignaGarantia = new EventEmitter<number>();
  selectedOptions = [];
  checkedValues = [];
  conGarantias = false
  renterDeliveredNecessaryGuaranteesResponse!: RenterDeliveredNecessaryGuaranteesResponse;
  personaDetailDto!: PersonaDetailDto;
  garantiasSeleccionadas!: string[] | null;
  get f() { return this.formGarantia.controls; }

  constructor(
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private garantiasService: GarantiasService,
    private personasService: PersonasService,
    private inquilinosService: InquilinosService
  ) {
    this.formGarantia = this.formBuilder.group({
    });
  }


  ngOnInit(): void {
    this.personaDetailDto = this.personasService.getPersona()
    this.getInquilinoByIdPersona(this.personaDetailDto.id)

  }

  getInquilinoByIdPersona(idPersona: any) {
    this.spinner.show("spGarantia")
    this.inquilinosService.getInquilinoByIdPersona(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spGarantia")
        },
        error => {
          this.spinner.hide("spGarantia")
          this.snackBar.showError(error, "Error");
        }
      )
  }


  seleccionGarantia(event: PersonaGaranteDto) {
    this.personaGarante = event
    this.getAllGarantiasByPersona(event.id)
  }

  selectionChange(option: MatListOption) {
    console.log(option.selected);
  }
  asignarGarantia() {

    this.garantiasSeleccionadas = this.listGarantia._value
    if (this.garantiasSeleccionadas) {
      this.spinner.show("spGarantia")
      this.garantiasSeleccionadas.forEach(garantiasSeleccionadas => {
        const asignarGarantiaInquilinoRequest = new AsignarGarantiaInquilinoRequest
        asignarGarantiaInquilinoRequest.idGarantia = parseInt(garantiasSeleccionadas)
        asignarGarantiaInquilinoRequest.id_PersonaInquilino = this.personaDetailDto.id

        this.garantiasService.asignarGarantiaInquilino(asignarGarantiaInquilinoRequest)
          .subscribe(
            data => {
              this.spinner.hide("spGarantia")
              this.snackBar.showSuccess(data.text, "Exito");
              this.personaGarante = new PersonaGaranteDto
              this.garantias = []
              this.garantiasInquilino = []
              this.conGarantias = false
              this.asignaGarantia.emit(asignarGarantiaInquilinoRequest.idGarantia)
            },
            error => {
              this.spinner.hide("spGarantia");
              this.snackBar.showError("Ya existe una relación entre la garantía y el inquilino especificado.", "Error");
            }
          )
      });
      this.listGarantia.deselectAll();
    }
  }

  getAllGarantiasByPersona(idPersonaInquilino: number) {
    this.spinner.show("spGarantia");
    this.garantiasService.getAllGarantiasByPersona(idPersonaInquilino)
      .subscribe(
        data => {
          this.spinner.hide("spGarantia");
          this.garantiasInquilino = data
          this.garantiasInquilino.forEach(element => {
            element.nroFolio = isFalsy(element.nroFolio) ? "" : element.nroFolio
            element.numero = isFalsy(element.numero) ? "" : element.numero
            element.tomo = isFalsy(element.tomo) ? "" : element.tomo
          });
          if (this.garantiasInquilino.length != 0)
            this.conGarantias = true
        },
        error => {
          this.spinner.hide("spGarantia");
          this.garantiasInquilino = []
          this.snackBar.showError(error, "Error");
        });
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
