export class CreatePersonaRequest {

public nombreCompleto!: string;
public nombre!: string;
public apellido!: string;
public apellidoMaterno!: string;
public id_TipoDocumento!: number;
public nroDocumento!: string;
public telefonoPrincial!: string;
public telefonoAlternativo!: string;
public sexo!: string;
public correo!: string;
public fechaNacimiento!: string;
public ubicacion!: string;
public id_Provincia!: number;
public id_Localidad!: number;
public codigoCpa!: string;
public id_EstadoCivil!: number;
public ocupacion!: string;
public estado!: boolean;
public observacion!: string;
public idPais!: number;
public telefonoLaboral!: string;
public ubicacionLaboral!: string;

}
