import { BaseDto } from "../base/baseDto.model";
import { LocalidadBasicDto } from "../localidad-provincia/localidadBasicDto.model";
import { SubtipoPropiedadDto } from "../subtipoPropiedad/subtipoPropiedadDto.model";
import { TipoPropiedadDto } from "./tipoPropiedadDto.model";

export class PropiedadDto extends BaseDto{
    public estadoPropiedad: BaseDto = new BaseDto;
    public localidad: LocalidadBasicDto = new LocalidadBasicDto;
    public ubicacion : string  = ""
    public piso? : string
    public departamento? : string
    public codigoCpa? : string
    public observacion? : string
    public activo?:boolean
    public precio?: number
    public fechaAlta?:string
    public superficieTotal?: number
    public superficieCubierta?: number
    public tipoPropiedad : TipoPropiedadDto = new TipoPropiedadDto;
    public propietario: BaseDto[] = [];
    public subtipoPropiedad : SubtipoPropiedadDto= new SubtipoPropiedadDto;
    public antiguedad? : string
    public tipoFolio: BaseDto = new BaseDto;
    public tomo? : string
    public numero? :  string
    public numeroFolio? : string
    public horizontal?:boolean
    public propiedadClase: BaseDto = new BaseDto;

    }
