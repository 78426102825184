import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogCreateInquilinoComponent } from '../dialog-create-inquilino/dialog-create-inquilino.component';

@Component({
  selector: 'app-btn-new-inquilino',
  templateUrl: './btn-new-inquilino.component.html',
  styleUrls: ['./btn-new-inquilino.component.css']
})
export class BtnNewInquilinoComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
    }
    const dialogRef = this.dialog.open(DialogCreateInquilinoComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      dialogRef.close();
    });
  }

}
