import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';

import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PeriodoContratoDetailDto } from '@models/periodos-contrato/periodoContratoDetailDto.model';
import { DetalleServicioPropiedadDetailDto } from '@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadDetailDto.model';
import { AccionesDialog, TiposMovimiento } from '@models/base/identificadores.model';
import { CreateReceiptInquilinoRequest } from '@models/inquilino/createReceiptInquilinoRequest.model';
import { MovimientoDetailDto } from '@models/movimiento/movimientoDetailDto.model';
import { LoginService } from '@services/login/login.service';
import { PagoDto } from '@models/pago/pagoDto.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { CreateReceiptInquilinoResponse } from '@models/inquilino/createReceiptInquilinoResponse.mode';
import { DialogDescripcionComponent } from 'src/app/views/dialog-basic/dialog-descripcion/dialog-descripcion.component';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { DescargarComprobantesComponent } from 'src/app/views/comprobantes/descargar-comprobantes/descargar-comprobantes.component';
import { ParametroSistemaDto } from '@models/parametros-sistema/parametroSistemaDto.model';
import { ParametrosSistemaService } from '@services/parametro-sistema/parametros-sistema.service';
import { isFalsy } from 'utility-types';
import { DialogNuevoReciboComponent } from '../dialog-nuevo-recibo/dialog-nuevo-recibo.component';
import { IdImporteMovimientoDto } from '@models/movimiento/idImporteMovimientoDto.model';
import { IdImporteDto } from '@models/base/idImporteDto.model';
import { MovimientoBasicDto } from '@models/movimiento/movimientoBasicDto.model';


export class MovimientoResumen {
  periodo!: string
  descripcion!: string | null
  tipoMovimiento!: string | null
  importeDebe: number = 0
  importeHaber: number = 0
}

@Component({
  selector: 'app-dialog-visualiza-recibo',
  templateUrl: './dialog-visualiza-recibo.component.html',
  styleUrls: ['./dialog-visualiza-recibo.component.css']
})
export class DialogVisualizaReciboComponent implements OnInit {

  @ViewChild('htmlData') htmlData!: ElementRef;

  totalDeudaInquilino: any;
  displayedColumns: string[] = ['periodo', 'tipoMovimiento', 'importeDebe', 'importeHaber', 'descripcion'];
  dataSource = new MatTableDataSource<MovimientoResumen>();
  lstMovimientoResumen: MovimientoResumen[] = []
  showProgressBar = false
  cuentaContableDefecto!: ParametroSistemaDto;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogVisualizaReciboComponent>,
    public dialogRefNuevoRecibo: MatDialogRef<DialogNuevoReciboComponent>,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private inquilinosServices: InquilinosService,
    public dialog: MatDialog,
    private parametrosSistemaService: ParametrosSistemaService
  ) { }

  ngOnInit(): void {
    this.generaMovimientoResumen()
    this.getCuentaContableDefecto()
  }

  openDialogDescripcion(data: string) {
    this.dialog.open(DialogDescripcionComponent, {
      data: data,
    });
  }

  generaMovimientoResumen() {
    //Mostrar Periodos
    let lstPeriodosContrato = this.data.periodosContrato as PeriodoContratoDetailDto[]
    if (lstPeriodosContrato.length > 0)
      lstPeriodosContrato
        .forEach(element => {
          let movimientoResumen = new MovimientoResumen
          movimientoResumen.periodo = element.periodoMes + '/' + element.periodoAnio + '-' + element.periodo
          movimientoResumen.tipoMovimiento = "Alquiler"
          movimientoResumen.importeDebe = !isFalsy(element.importeAlquilerSinPunitorio) ? element.importeAlquilerSinPunitorio : 0
          movimientoResumen.descripcion = !isFalsy(element.descripcion) ? element.descripcion : ""

          if (element.importePunitorioEntrega != 0) {
            let movimientoResumen = new MovimientoResumen
            movimientoResumen.periodo = element.periodoMes + '/' + element.periodoAnio + '-' + element.periodo
            movimientoResumen.tipoMovimiento = "Punitorios alquiler"
            movimientoResumen.importeDebe = !isFalsy(element.importePunitorioEntrega) ? element.importePunitorioEntrega : 0

            this.lstMovimientoResumen.push(movimientoResumen)
          }
          this.lstMovimientoResumen.push(movimientoResumen)
        });

    //Mostrar Detalle Servicios Propiedad
    let detallesServiciosPropiedad = this.data.detallesServiciosPropiedad as DetalleServicioPropiedadDetailDto[]
    if (detallesServiciosPropiedad.length > 0)
      detallesServiciosPropiedad
        .forEach(element => {
          let movimientoResumen = new MovimientoResumen
          movimientoResumen.periodo = element.periodoMes + '/' + element.periodoAnio + '-' + element.periodo
          movimientoResumen.tipoMovimiento = element.servicioPropiedad.servicio.descripcion == "" ? null : element.servicioPropiedad.servicio?.descripcion
          movimientoResumen.descripcion = !isFalsy(element.descripcion) ? element.descripcion : ""

          element.vencimientos.forEach(element2 => {
            if (element2.seleccionPago == true)
              movimientoResumen.importeDebe = element2.importeVencimiento
          });

          this.lstMovimientoResumen.push(movimientoResumen)
        });

    //Mostrar Movimientos
    let movimientos = this.data.movimientos as MovimientoDetailDto[]
    if (movimientos.length > 0)
      movimientos
        .forEach(element => {
          let movimientoResumen = new MovimientoResumen
          movimientoResumen.periodo = element.periodoMes + '/' + element.periodoAnio + '-' + element.periodo
          movimientoResumen.tipoMovimiento = !isFalsy(element.tipoMovimiento) ? element.tipoMovimiento.descripcion : null
          movimientoResumen.importeDebe = !isFalsy(element.importe) ? element.importe : 0
          movimientoResumen.descripcion = !isFalsy(element.descripcion) ? element.descripcion : ""
          this.lstMovimientoResumen.push(movimientoResumen)
        });

    //Mostrar Movimientos Saldo Favor
    let movimientosSaldoFavor = this.data.movimientosSaldoFavor as MovimientoDetailDto[]
    if (movimientosSaldoFavor.length > 0)
      movimientosSaldoFavor.forEach(element => {
        let movimientoResumen = new MovimientoResumen
        movimientoResumen.periodo = element.periodoMes + '/' + element.periodoAnio + '-' + element.periodo
        movimientoResumen.importeHaber = !isFalsy(element.importe) ? element.importe : 0
        movimientoResumen.descripcion = !isFalsy(element.descripcion) ? element.descripcion : ""
        movimientoResumen.tipoMovimiento = !isFalsy(element.tipoMovimiento) ? element.tipoMovimiento.descripcion : null

        this.lstMovimientoResumen.push(movimientoResumen)
      });

    //Mostrar Movimientos Saldo Deuda
    let movimientosSaldoDeuda = this.data.movimientosSaldoDeuda as MovimientoDetailDto[]
    if (movimientosSaldoDeuda.length > 0)
      movimientosSaldoDeuda.forEach(element => {
        let movimientoResumen = new MovimientoResumen
        movimientoResumen.periodo = element.periodoMes + '/' + element.periodoAnio + '-' + element.periodo
        movimientoResumen.importeDebe = !isFalsy(element.importe) ? element.importe : 0
        movimientoResumen.descripcion = !isFalsy(element.descripcion) ? element.descripcion : ""
        movimientoResumen.tipoMovimiento = !isFalsy(element.tipoMovimiento) ? element.tipoMovimiento.descripcion : null

        this.lstMovimientoResumen.push(movimientoResumen)
      });


    //Mostrar Movimientos Nuevos que no son saldos
    let movimientosNuevos = this.data.movimientosNuevos.filter(this.NoEsMovimientoTipoSaldo) as MovimientoDetailDto[]
    if (movimientosNuevos.length > 0)
      movimientosNuevos
        .forEach(element => {
          let movimientoResumen = new MovimientoResumen
          movimientoResumen.periodo = element.periodoMes + '/' + element.periodoAnio + '-' + element.periodo
          movimientoResumen.descripcion = element.descripcion
          movimientoResumen.importeDebe = !isFalsy(element.importe) ? element.importe : 0
          movimientoResumen.descripcion = !isFalsy(element.descripcion) ? element.descripcion : ""
          movimientoResumen.tipoMovimiento = !isFalsy(element.tipoMovimiento) ? element.tipoMovimiento.descripcion : null
          this.lstMovimientoResumen.push(movimientoResumen)
        });


    //Bonificaciones
    let lstBonificacion = this.data.movimientosBonificaciones as MovimientoDetailDto[]
    if (lstBonificacion.length > 0)
      lstBonificacion
        .forEach(element => {
          let movimientoResumen = new MovimientoResumen
          movimientoResumen.periodo = element.periodoMes + '/' + element.periodoAnio + '-' + element.periodo
          movimientoResumen.tipoMovimiento = !isFalsy(element.tipoMovimiento) ? element.tipoMovimiento.descripcion : null
          movimientoResumen.importeHaber = !isFalsy(element.importe) ? element.importe : 0
          movimientoResumen.descripcion = !isFalsy(element.descripcion) ? element.descripcion : ""
          this.lstMovimientoResumen.push(movimientoResumen)
        });

    //Pagos
    let pagos = this.data.pagos as PagoDto[]
    if (pagos.length > 0)
      pagos
        .forEach(element => {
          let movimientoResumen = new MovimientoResumen
          movimientoResumen.periodo = (new Date().getMonth() + 1 + "/" + new Date().getFullYear() + "-")
          movimientoResumen.tipoMovimiento = !isFalsy(element.formaPago) ? element.formaPago.descripcion : "" + " - " + element.descripcion
          movimientoResumen.importeHaber = !isFalsy(element.importe) ? element.importe : 0
          movimientoResumen.descripcion = !isFalsy(element.descripcion) ? element.descripcion : ""
          this.lstMovimientoResumen.push(movimientoResumen)
        });


    this.dataSource.data = this.lstMovimientoResumen
  }

  esMovimientoTipoSaldo(elemento: MovimientoDetailDto) {
    return elemento.tipoMovimiento.id == TiposMovimiento.SaldoAFavor || elemento.tipoMovimiento.id == TiposMovimiento.SaldoAdeuda;
  }

  NoEsMovimientoTipoSaldo(elemento: MovimientoDetailDto) {
    return elemento.tipoMovimiento.id != TiposMovimiento.SaldoAFavor && elemento.tipoMovimiento.id != TiposMovimiento.SaldoAdeuda;
  }

  calcularTotales() {
    this.totalDeudaInquilino = 0
    this.totalDeudaInquilino = this.data.totalMontoAlquiler + this.data.totalMontoPunitorios + this.data.totalMontoAdicionales + this.data.totalMontoMovimientos - this.data.totalMontoBonificaciones
    return this.totalDeudaInquilino
  }

  getTotalCostDebe(): number {
    let valor = 0
    valor = this.lstMovimientoResumen.map(t => t.importeDebe).reduce((acc, value) => acc + value, 0) as number;
    return valor
  }
  getTotalCostHaber(): number {
    let valor = 0
    valor = this.lstMovimientoResumen.map(t => t.importeHaber).reduce((acc, value) => acc + value, 0) as number;
    return valor
  }
  getTotalSaldo(): number {
    let debe,haber = 0
    debe = this.lstMovimientoResumen.map(t => t.importeDebe).reduce((acc, value) => acc + value, 0) as number;
    haber = this.lstMovimientoResumen.map(t => t.importeHaber).reduce((acc, value) => acc + value, 0) as number;
    return haber - debe
  }

  generarReciboFalso() {
    this.spinner.show("spGeneraRecibo")
  }

  generarRecibo() {
    let createReceiptInquilinoRequest = new CreateReceiptInquilinoRequest;
    let user = this.loginService.getCurrentUser();
    let contrato = this.data.contrato as ContratoDetailDto
    let idMovimientoRef: number | null = null
    createReceiptInquilinoRequest.idContrato = contrato.id;
    createReceiptInquilinoRequest.idEmpresa = user.empresa.id
    createReceiptInquilinoRequest.idUsuario = user.id
    createReceiptInquilinoRequest.idInquilino = contrato.inquilino.id

    //Periodos Contrato
    let lstIdImporteDto: IdImporteDto[] = [];
    let lstPeriodosContrato = this.data.periodosContrato as PeriodoContratoDetailDto[];
    if (lstPeriodosContrato.length > 0)
      lstPeriodosContrato.filter(element => element.seleccionPago == true).forEach(element => {
        let idImporteDto = new IdImporteDto;
        idImporteDto.id = element.id;
        idImporteDto.importePunitorio = isFalsy(element.importePunitorioEntrega) ? 0 : parseFloat((Math.round(element.importePunitorioEntrega * 100) / 100).toFixed(2))
        if (isFalsy(element.importeEntrega))
          idImporteDto.importe = isFalsy(element.importeAlquiler) ? 0 : parseFloat((Math.round(element.importeAlquiler * 100) / 100).toFixed(2));
        else
        idImporteDto.importe = isFalsy(element.importeEntrega) ? 0 : parseFloat((Math.round((element.importeEntrega - element.importePunitorioEntrega) * 100) / 100).toFixed(2));
        lstIdImporteDto.push(idImporteDto)
      })


    //Detalle Servicio Propiedad
    let lstIdImporteDto2: IdImporteDto[] = [];
    let detallesServiciosPropiedad = this.data.detallesServiciosPropiedad as DetalleServicioPropiedadDetailDto[];
    if (detallesServiciosPropiedad.length > 0)
      detallesServiciosPropiedad.filter(element => element.seleccionPago == true).forEach(element => {
        let idImporteDto = new IdImporteDto;
        idImporteDto.id = element.id;
        if (element.vencimientos != null && element.vencimientos != undefined)
          element.vencimientos.forEach(element2 => {
            if (element2.seleccionPago == true) {
              idImporteDto.importe = element2.importeVencimiento;
              idImporteDto.importePunitorio = 0;
              lstIdImporteDto2.push(idImporteDto);
            }
          });
      });


    //Movimientos
    let lstIdImporteMovimientoDto: IdImporteMovimientoDto[] = [];
    let lstMovimientos = this.data.movimientos as MovimientoDetailDto[];
    if (lstMovimientos.length > 0)
      lstMovimientos.filter(element => element.seleccionPago == true && element.id != 0).forEach(element => {
        let idImporteMovimientoDto = new IdImporteMovimientoDto;
        idImporteMovimientoDto.id = element.id;
        idImporteMovimientoDto.importe = element.importe;
        idImporteMovimientoDto.importePunitorio = 0;
        lstIdImporteMovimientoDto.push(idImporteMovimientoDto);
      });

    //Movimientos Saldo Favor
    let movimientosSaldoFavor = this.data.movimientosSaldoFavor as MovimientoDetailDto[]
    if (movimientosSaldoFavor.length > 0)
      movimientosSaldoFavor.forEach(element => {
        let idImporteMovimientoDto = new IdImporteMovimientoDto;
        idMovimientoRef = element.id
        idImporteMovimientoDto.id = element.id;
        idImporteMovimientoDto.importe = element.importe;
        idImporteMovimientoDto.anulado = element.anulado
        idImporteMovimientoDto.idTipoMovimiento = element.tipoMovimiento.id
        idImporteMovimientoDto.importePunitorio = 0
        lstIdImporteMovimientoDto.push(idImporteMovimientoDto);
      });

    //Movimientos Saldo Deuda
    let movimientosSaldoDeuda = this.data.movimientosSaldoDeuda as MovimientoDetailDto[]
    if (movimientosSaldoDeuda.length > 0)
      movimientosSaldoDeuda.forEach(element => {
        let idImporteMovimientoDto = new IdImporteMovimientoDto;
        idMovimientoRef = element.id
        idImporteMovimientoDto.id = element.id;
        idImporteMovimientoDto.importe = element.importe;
        idImporteMovimientoDto.anulado = element.anulado
        idImporteMovimientoDto.idTipoMovimiento = element.tipoMovimiento.id
        idImporteMovimientoDto.importePunitorio = 0
        lstIdImporteMovimientoDto.push(idImporteMovimientoDto);
      });

    //Movimientos Nuevos
    let lstMovimientoBasicDto: MovimientoBasicDto[] = [];
    let lstMovimientosNuevos = this.data.movimientosNuevos as MovimientoDetailDto[];
    if (lstMovimientosNuevos.length > 0)
      lstMovimientosNuevos.forEach(element => {
        let movimientoBasicDto = new MovimientoBasicDto;
        movimientoBasicDto.id = element.id;
        movimientoBasicDto.importe = element.importe;
        movimientoBasicDto.descripcion = isFalsy(element.descripcion) ? "" : element.descripcion;
        movimientoBasicDto.idTipoMovimiento = element.tipoMovimiento.id;
        movimientoBasicDto.idBonificacion = isFalsy(element.bonificacion) ? null : element.bonificacion.id;
        movimientoBasicDto.periodo = element.periodo
        movimientoBasicDto.periodoMes = element.periodoMes
        movimientoBasicDto.periodoAnio = element.periodoAnio
        movimientoBasicDto.idPeriodoContrato = null
        movimientoBasicDto.anulado = element.anulado
        lstMovimientoBasicDto.push(movimientoBasicDto)
      });

    //Movimientos Nuevos Saldo
    let lstMovimientosNuevosSaldo = this.data.movimientosNuevosSaldo as MovimientoDetailDto[];
    if (lstMovimientosNuevosSaldo.length > 0)
      lstMovimientosNuevosSaldo.forEach(element => {
        let movimientoBasicDto = new MovimientoBasicDto;
        movimientoBasicDto.id = element.id;
        movimientoBasicDto.importe = element.importe;
        movimientoBasicDto.descripcion = isFalsy(element.descripcion) ? "" : element.descripcion;
        movimientoBasicDto.idTipoMovimiento = element.tipoMovimiento.id;
        movimientoBasicDto.idBonificacion = isFalsy(element.bonificacion) ? null : element.bonificacion.id;
        movimientoBasicDto.periodo = element.periodo
        movimientoBasicDto.periodoMes = element.periodoMes
        movimientoBasicDto.periodoAnio = element.periodoAnio
        movimientoBasicDto.idPeriodoContrato = null
        movimientoBasicDto.anulado = element.anulado
        movimientoBasicDto.idMovimientoRef = idMovimientoRef
        lstMovimientoBasicDto.push(movimientoBasicDto)
      });

    //Bonificaciones
    let lstBonificaciones = this.data.movimientosBonificaciones as MovimientoDetailDto[];
    if (lstBonificaciones.length > 0)
      lstBonificaciones.filter(element => element.seleccionPago == true).forEach(element => {
        let movimientoBasicDto = new MovimientoBasicDto;
        movimientoBasicDto.id = element.id;
        movimientoBasicDto.importe = element.importe;
        movimientoBasicDto.descripcion = isFalsy(element.descripcion) ? "" : element.descripcion;
        movimientoBasicDto.idTipoMovimiento = element.tipoMovimiento.id;
        movimientoBasicDto.idBonificacion = isFalsy(element.bonificacion) ? null : element.bonificacion.id;
        movimientoBasicDto.periodo = element.periodo
        movimientoBasicDto.periodoMes = element.periodoMes
        movimientoBasicDto.periodoAnio = element.periodoAnio
        movimientoBasicDto.idPeriodoContrato = null
        movimientoBasicDto.anulado = element.anulado
        lstMovimientoBasicDto.push(movimientoBasicDto);
      });


    //Pagos
    let pagos: PagoDto[] = []
    let pagosOrigen: PagoDto[] = this.data.pagos
    pagosOrigen.forEach(element => {
      let pago = new PagoDto
      pago.id = 0
      pago.descripcion = isFalsy(element.descripcion) ? "" : element.descripcion
      pago.idFormaPago = element.idFormaPago
      pago.idEstadoPago = element.idEstadoPago
      pago.idCuentaContableDestino = isFalsy(this.cuentaContableDefecto) ? null : parseInt(this.cuentaContableDefecto.valor)
      pago.importe = element.importe
      pago.fechaPago = element.fechaPago
      pago.fechaAcreditacion = element.fechaAcreditacion
      pago.fechaVencimiento = element.fechaVencimiento
      pagos.push(pago)
    });

    createReceiptInquilinoRequest.periodosContrato = lstIdImporteDto;
    createReceiptInquilinoRequest.detalleServicioPropiedad = lstIdImporteDto2;
    createReceiptInquilinoRequest.movimientos = lstIdImporteMovimientoDto;
    createReceiptInquilinoRequest.movimientosNuevos = lstMovimientoBasicDto;
    createReceiptInquilinoRequest.pagos = pagos

    if (createReceiptInquilinoRequest.periodosContrato.length > 0 ||
      createReceiptInquilinoRequest.detalleServicioPropiedad.length > 0 ||
      createReceiptInquilinoRequest.movimientos.length > 0 ||
      createReceiptInquilinoRequest.movimientosNuevos.length > 0 ||
      isFalsy(createReceiptInquilinoRequest.idContrato) ||
      isFalsy(createReceiptInquilinoRequest.idEmpresa) ||
      isFalsy(createReceiptInquilinoRequest.idInquilino) ||
      isFalsy(createReceiptInquilinoRequest.idUsuario) ||
      createReceiptInquilinoRequest.pagos.length > 0)
      {
        this.createReceiptInquilinoRequest(createReceiptInquilinoRequest)
        //var test = createReceiptInquilinoRequest
      }

    else
      this.snackBar.showWarn("No hay items seleccionados.", "Atención");
  }

  getCuentaContableDefecto() {
    this.parametrosSistemaService.getValueByName("CuentaContableDefecto").subscribe(
      data => {
        this.cuentaContableDefecto = data
      },
      error => {
        this.snackBar.showError(error, "Error");
      }
    )
  }

  createReceiptInquilinoRequest(createReceiptInquilinoRequest: CreateReceiptInquilinoRequest) {
    this.showProgressBar = true
    this.inquilinosServices.createReceiptInquilino(createReceiptInquilinoRequest).subscribe(
      data => {
        this.showProgressBar = false
        if (!isFalsy(data)) {
          this.openDialogDescargarRecibo(data)
          this.dialogRefNuevoRecibo.close({ event: AccionesDialog.Aceptar })
          this.dialogRef.close({ event: AccionesDialog.Aceptar });
        }
      },
      error => {
        this.showProgressBar = false
        this.snackBar.showError(error, "Error");
        this.dialogRef.close({ event: AccionesDialog.Aceptar });
      }
    )
  }

  openDialogDescargarRecibo(createReceiptInquilinoResponse: CreateReceiptInquilinoResponse) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    let archivoDto = new ArchivoDto
    archivoDto.content = createReceiptInquilinoResponse.content
    archivoDto.nombre = createReceiptInquilinoResponse.fileName
    dialogConfig.data = archivoDto
    dialogConfig.width = "400px";
    let dialogRef = this.dialog.open(DescargarComprobantesComponent,
      dialogConfig);
    this.dialogRef.close({ event: AccionesDialog.Aceptar })
    this.dialogRefNuevoRecibo.close({ event: AccionesDialog.Aceptar })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.event == AccionesDialog.Aceptar) {
        this.dialogRef.close({ event: AccionesDialog.Aceptar })
      }
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar })
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

}

