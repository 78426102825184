import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateEmpresaRequest } from '@models/empresa/createEmpresaRequest.model';
import { EmpresaDto } from '@models/empresa/empresaDto.model';
import { UpdateEmpresaRequest } from '@models/empresa/updateEmpresaRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http:HttpClient
  ) { }

  create(createEmpresaRequest: CreateEmpresaRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>('/api/Empresa/Create', createEmpresaRequest)
  }

  update(updateEmpresaRequest: UpdateEmpresaRequest): Observable<any> {
    return this.http.put<any>('/api/Empresa/Update', updateEmpresaRequest)
  }

  getById(id: number): Observable<EmpresaDto> {
    return this.http.get<EmpresaDto>('/api/Empresa/GetById?id='+id)
  }

  getAll(): Observable<EmpresaDto[]> {
    return this.http.get<EmpresaDto[]>('/api/Empresa/GetById?id=')
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Empresa/Delete?id=" + id);
    else
      return of(null)
  }
}
