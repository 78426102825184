import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RolDto } from '@models/seguridad/roles/rolDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateRolRequest } from '@models/seguridad/roles/createRolRequest.model';
import { UpdateRolRequest } from '@models/seguridad/roles/updateRolRequest.model';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http:HttpClient) {
  }

  create(createRolRequest: CreateRolRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Roles/Create", createRolRequest);
  }

  update(updateRolRequest: UpdateRolRequest): Observable<any> {
    return this.http.put<any>("/api/Roles/Update", updateRolRequest);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>("/api/Roles/Delete?id="+id);
  }

  getAll():Observable<RolDto[]> {
    return   this.http.get<RolDto[]>("/api/Roles/GetAll");
  }

  getById(id:number):Observable<RolDto> {
    return   this.http.get<RolDto>("/api/Roles/GetById?id="+id);
  }

}
