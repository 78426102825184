import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RenterDeliveredNecessaryGuaranteesResponse } from '@models/inquilino/renterDeliveredNecessaryGuaranteesResponse.model';
import { GruposTipoGarantiaInquilinoService } from '@services/grupos-tipo-garantia-inquilino/grupos-tipo-garantia-inquilino.service';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-validar-garantias-pendientes',
  templateUrl: './validar-garantias-pendientes.component.html',
  styleUrls: ['./validar-garantias-pendientes.component.css'],
})
export class ValidarGarantiasPendientesComponent implements OnInit,OnChanges {
  renterDeliveredNecessaryGuaranteesResponse!: RenterDeliveredNecessaryGuaranteesResponse;
  @Input() idInquilino!: number

  constructor(public inquilinosService: InquilinosService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private inquilinoService: InquilinosService
  ) {
    if (this.idInquilino) {
      this.renterDeliveredNecessaryGuarantees(this.idInquilino)
    }
  }
  ngOnInit(): void {

  }
  ngOnChanges(): void {
    if (this.idInquilino) {
      this.renterDeliveredNecessaryGuarantees(this.idInquilino)
    }
  }


  renterDeliveredNecessaryGuarantees(idInquilino: number) {
    this.spinner.show("spCargarEstado")
    if (!isFalsy(idInquilino))
      this.inquilinosService.renterDeliveredNecessaryGuarantees(idInquilino)
        .subscribe(
          data => {
            this.spinner.hide("spCargarEstado")
            this.renterDeliveredNecessaryGuaranteesResponse = data
          },
          error => {
            this.spinner.hide("spCargarEstado")
          }
        )
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
