import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateSubtipoPropiedadRequest } from '@models/subtipoPropiedad/createSubtipoPropiedadRequest.model';
import { SubtipoPropiedadDto } from '@models/subtipoPropiedad/subtipoPropiedadDto.model';
import { UpdateSubtipoPropiedadRequest } from '@models/subtipoPropiedad/updateSubtipoPropiedadRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class SubtipoPropiedadService {

  constructor(
    private http: HttpClient
  ) { }

  create(createSubtipoPropiedadRequest: CreateSubtipoPropiedadRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/Pais/Create",createSubtipoPropiedadRequest);
  }

  update(updateSubtipoPropiedadRequest: UpdateSubtipoPropiedadRequest):Observable<any>
  {
    return  this.http.put<any>("/api/Pais/Update",updateSubtipoPropiedadRequest);
  }

  getAll():Observable<SubtipoPropiedadDto[]>
  {
    return  this.http.get<SubtipoPropiedadDto[]>("/api/Pais/GetAll");
  }

  getById(id:number):Observable<SubtipoPropiedadDto>
  {
    return  this.http.get<SubtipoPropiedadDto>("/api/Pais/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Pais/Delete?id=" + id);
    else
      return of(null)
  }

  getAllByIdTipoPropiedad(idTipoPropiedad: number): Observable<BaseDto[]> {
    return this.http.get<BaseDto[]>("/api/SubtipoPropiedad/GetAllByIdTipoPropiedad?idTipoPropiedad=" + idTipoPropiedad);
  }

}
