<div class="contenedor">
  <div class="container ">
    <div class="row">
      <div class="col-lg-6">
        <h1>500</h1>
        <h3>Esto es un error</h3>
        <br>
        <p>Hubo un error. Por favor, inténtelo de nuevo más tarde. Eso es todo lo que sabemos</p>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-12">
            <mat-icon class="icono-house">maps_home_work</mat-icon>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <button mat-stroked-button color="primary" class="button" [routerLink]="['/dashboard']">Ir a Dashboard</button>
            <button mat-stroked-button color="primary" class="button" [routerLink]="['/login']">Iniciar Sesion</button>
          </div>
      </div>
    </div>
  </div>
</div>
