import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PersonasService } from '@services/personas/personas.service';
import { PropietariosService } from '@services/propietarios/propietarios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { UpdatePropietarioRequest } from '@models/propietarios/updatePropietarioRequest.model';
import { CreatePropietarioRequest } from '@models/propietarios/createPropietarioRequest.model';
import { CreatePropietarioResponse } from '@models/propietarios/createPropietarioResponse.model';
import { BaseDto } from '@models/base/baseDto.model';
import { PropietarioDto } from '@models/propietarios/propietarioDto.model';
import { isFalsy } from 'utility-types';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'app-form-propietario-update',
  templateUrl: './form-propietario-update.component.html',
  styleUrls: ['./form-propietario-update.component.css']
})
export class FormPropietarioUpdateComponent implements OnInit {
  propietarioForm!: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  idTipoLiquidacion: number = 0;
  createPropietarioRequest: CreatePropietarioRequest = new CreatePropietarioRequest;
  updatePropietarioRequest: UpdatePropietarioRequest = new UpdatePropietarioRequest;
  propietario!: PropietarioDto;
  banco: BaseDto = new BaseDto;
  idPropietario: number = 0;
  idBanco: number = 0;
  createPropietarioResponse: CreatePropietarioResponse = new CreatePropietarioResponse
  personaDetailDto!: PersonaDetailDto;
  get f() { return this.propietarioForm.controls; }
  lstTipoLiquidacionModel: BaseDto[] = [];
  tipoLiquidacion = new BaseDto
  esPropietarioNuevo: boolean = false;
  agregaPropietario: boolean = false;
  nuevoPropietario: boolean = false
  editaPropietario: boolean = false
  comision: any

  constructor(
    private propietariosService: PropietariosService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private aRoute: ActivatedRoute,
    private personasService: PersonasService,
    private router: Router
  ) {

    this.propietarioForm = this.formBuilder.group({
      Comision: ['', Validators.compose([Validators.required])],
      CuitCuil: ['', Validators.compose([Validators.pattern(/^([0-9]{11}|[0-9]{2}-[0-9]{8}-[0-9]{1})$/)])],
      NroCuenta: [''],
      Cbu: ['', [Validators.pattern(/([0-9]{21})\w+/)]],
      Alias: [''],
      TipoLiquidacion: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.personaDetailDto = this.personasService.getPersona()
    this.getAllTipoLiquidacion()
    this.getPropietarioByIdPersona(this.personaDetailDto.id)
  }

  ngOnChange() {
  }
  getAllTipoLiquidacion() {
    this.spinner.show('spTipoLiquidacion')
    this.propietariosService.getAllTiposLiquidacion()
      .subscribe(
        async data => {
          this.spinner.hide('spTipoLiquidacion')
          this.lstTipoLiquidacionModel = data
        },
        error => {
          this.spinner.hide('spTipoLiquidacion')
          this.snackBar.showError(error, "Error");
        }
      );
  }

  selectTipoLiquidacion(event: any) {
    this.idTipoLiquidacion = event.value
  }

  selectBanco(event: any) {
    this.banco = event;
  }

  onReset() {
    this.propietarioForm.reset();
  }

  onSubmit() {
    if (this.propietarioForm.invalid) {
      return;
    }
    this.setUpdatePropietarioRequest();
    this.updatePropietario(this.updatePropietarioRequest);
  }


  updatePropietario(updatePropietarioRequest: UpdatePropietarioRequest) {
    this.spinner.show('spUpdatePropietario')
    this.propietariosService.update(updatePropietarioRequest)
      .subscribe(
        data => {
          this.spinner.hide('spUpdatePropietario')
          this.snackBar.showSuccess('Se actualizo el propietario de manera correcta.', "Exito");
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        });
  }

  setUpdatePropietarioRequest() {
    this.updatePropietarioRequest = new UpdatePropietarioRequest();

    this.updatePropietarioRequest.id = this.idPropietario;
    this.updatePropietarioRequest.id_Persona = this.personaDetailDto.id;
    if (this.banco.id > 0) {
      this.updatePropietarioRequest.id_Banco = this.banco.id
    }
    else {
      this.updatePropietarioRequest.id_Banco = null
    }
    if (this.idTipoLiquidacion > 0) {
      this.updatePropietarioRequest.id_TipoLiquidacion = this.idTipoLiquidacion
    }
    else {
      this.updatePropietarioRequest.id_TipoLiquidacion = null
    }
    this.updatePropietarioRequest.comision = this.propietarioForm.controls['Comision'].value
    this.updatePropietarioRequest.alias = this.propietarioForm.controls['Alias'].value
    this.updatePropietarioRequest.cbu = this.propietarioForm.controls['Cbu'].value
    this.updatePropietarioRequest.nroCuenta = this.propietarioForm.controls['NroCuenta'].value
    this.updatePropietarioRequest.cuit = this.propietarioForm.controls['CuitCuil'].value
  }

  setFormulario(propietario: PropietarioDto) {
    this.personaDetailDto.id = propietario.persona.id;
    if (isFalsy(propietario.banco)) {
      this.banco = new BaseDto;
    }
    else {
      this.banco = propietario.banco;
    }
    if (isFalsy(propietario.tipoLiquidacion)) {
      const tipoLiquidacion = new BaseDto
      this.propietarioForm.controls['TipoLiquidacion'].setValue(tipoLiquidacion.id);
    }
    else {
      this.propietarioForm.controls['TipoLiquidacion'].setValue(propietario.tipoLiquidacion.id);
    }

    this.idPropietario = propietario.id;
    this.propietarioForm.controls['Comision'].setValue(propietario.comision);
    this.propietarioForm.controls['Alias'].setValue(propietario.alias);
    this.propietarioForm.controls['Cbu'].setValue(propietario.cbu);
    this.propietarioForm.controls['NroCuenta'].setValue(propietario.nroCuenta);
    this.propietarioForm.controls['CuitCuil'].setValue(propietario.cuit);
  }

  getPropietarioByIdPersona(idPersona: number) {
    this.spinner.show('spUpdatePropietario')
    this.propietariosService.getByIdPersona(idPersona)
      .subscribe(
        async data => {
          this.spinner.hide('spUpdatePropietario')
          this.propietario = data
          this.setFormulario(this.propietario);
          this.propietariosService.setPropietario(data)
        },
        error => {
          this.spinner.hide('spUpdatePropietario')
          this.snackBar.showError(error, "Error");
        })
  }

  eliminar() {
    this.spinner.show('spUpdatePropietario')
    this.propietariosService.delete(this.propietario.id)
      .subscribe(
        async data => {
          this.spinner.hide('spUpdatePropietario')
          this.personaDetailDto = this.personasService.getPersona()
          this.personaDetailDto.esPropietario = false
          this.personasService.setPersona(this.personaDetailDto)
          this.router.navigate(["/propietario"])
        },
        error => {
          this.spinner.hide('spUpdatePropietario')
          this.snackBar.showError(error, "Error");
        })
  }

  trackByItems(index: number, item: any): any { return item; }
}

