import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared.module';
import { BtnNuevoTipoBonificacionComponent } from '../views/tipos-bonificacion/btn-nuevo-tipo-bonificacion/btn-nuevo-tipo-bonificacion.component';
import { DialogTipoBonificacionComponent } from '../views/tipos-bonificacion/dialog-tipo-bonificacion/dialog-tipo-bonificacion.component';
import { TiposBonificacionComponent } from '../views/tipos-bonificacion/tipos-bonificacion/tipos-bonificacion.component';



@NgModule({
  declarations: [
    DialogTipoBonificacionComponent,
    BtnNuevoTipoBonificacionComponent,
    TiposBonificacionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[
    DialogTipoBonificacionComponent,
    BtnNuevoTipoBonificacionComponent,
    TiposBonificacionComponent,
  ]
})
export class TiposBonificacionModule { }
