<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>

    Seleccione servicios</h1>
<div mat-dialog-content>
    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <mat-card>
        <div class="row">
            <div class="col-lg-6">
                <span><b>Subtotal</b></span>
            </div>
            <div class="col-lg-6">
                <span>{{subtotal | currency}}</span>
            </div>
        </div>
    </mat-card>
</div>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <mat-card>
        <div class="row">
            <div class="col-lg-12">
                <div class="example-container">
                    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox disabled="true" (change)="$event ? masterToggle() : null" [checked]="dspdDtoSelection.hasValue() && isAllSelected()" [indeterminate]="dspdDtoSelection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-checkbox (click)="$event.stopPropagation();" (change)="$event ? dspdDtoSelection.toggle(element) : null; checkSelectServicio($event.checked,element)" [checked]="dspdDtoSelection.isSelected(element)" [aria-label]="checkboxLabel(element)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="periodo">
                            <th mat-header-cell *matHeaderCellDef> Periodo </th>
                            <td mat-cell *matCellDef="let element"> {{element.periodoMes}}/ {{element.periodoAnio}}- {{element.periodo}} </td>
                        </ng-container>

                        <ng-container matColumnDef="servicio">
                            <th mat-header-cell *matHeaderCellDef> Servicio </th>
                            <td mat-cell *matCellDef="let element"> {{isFalsy(element.servicioPropiedad.servicio) ? "" : element.servicioPropiedad.servicio.descripcion}} </td>
                        </ng-container>

                        <ng-container matColumnDef="desplegar">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="primary" [disabled]="true" matTooltip="Desplegar vencimientos" (click)="expandedElement = expandedElement === element ? null : element">
        <mat-icon>expand_more</mat-icon>
      </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                                    <div class="example-element-description ">
                                        <mat-radio-group name={{element.id}} id={{element.id}} aria-labelledby="example-radio-group-label" class="example-radio-group">
                                            <mat-radio-button class="example-radio-button" #vencimiento *ngFor="let vencimiento of element.vencimientos; let ultimo = last;let i=index;trackBy:trackByItems" [disabled]="esFechaVencida(vencimiento)" [checked]="isFalsy(vencimiento.seleccionPago) ? false: vencimiento.seleccionPago"
                                                [value]="vencimiento" (change)="selectVencimientoPago(element,vencimiento);dspdDtoSelection.isSelected(element)">
                                                <span><b>Vencimiento: </b> {{vencimiento.fechaVencimiento | date:("dd/MM/yyyy")}} </span> <b style="padding-left: 10%;"> Importe: </b> <span> {{vencimiento.importeVencimiento | currency}}</span>
                                                <button mat-icon-button class="btn-flotante" (click)="openDialogNuevoVencimiento(element)" *ngIf="esFechaVencida(vencimiento) && ultimo" color="primary" matTooltip="Agregar vencimiento" matTooltipPosition="above">
                                <mat-icon style="padding-bottom: 29px;">add</mat-icon>
                              </button>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>

                                </div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef> </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;" matTooltip="Desplegar" matTooltipPosition="above" (click)="expandedElement=expandedElement===element ? null : element" class="example-element-row" [class.example-expanded-row]="expandedElement === element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                </div>
            </div>
        </div>

    </mat-card>

</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()">Aceptar</button>
    <button mat-button mat-dialog-close color="warn" (click)="onCancel()">Cancelar</button>
</div>
