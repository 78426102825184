import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-create-propietario',
  templateUrl: './dialog-create-propietario.component.html',
  styleUrls: ['./dialog-create-propietario.component.css']
})
export class DialogCreatePropietarioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
