<div class="row content" *appRole="['Propietarios.Ver']">
    <div class="col-sm-2">
        <app-nav-panel-persona></app-nav-panel-persona>
    </div>
    <div class="col-sm-10">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false">
                <mat-expansion-panel-header>
                    <ngx-spinner [zIndex]="100" name="spPropietario" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
                    <mat-panel-title>
                        <b>Datos Propietario</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">apartment</mat-icon>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <router-outlet></router-outlet>
                    <div *ngIf="enableReactivar">
                        <button mat-raised-button color="primary" style="margin-right:3px;" value="Reactivar" (click)="reactivar()"><mat-icon>toggle_on</mat-icon>Reactivar</button>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
        <br>
        <mat-accordion class="example-headers-align" multi *ngIf="propietarioDto">
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Propiedades del propietario</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">roofing</mat-icon>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <app-list-propiedades-propietario></app-list-propiedades-propietario>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>