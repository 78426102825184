import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreateServicioRequest } from '@models/servicios/servicio/createServicioRequest.model';

@Component({
  selector: 'app-btn-create-servicio',
  templateUrl: './btn-create-servicio.component.html',
  styleUrls: ['./btn-create-servicio.component.css']
})
export class BtnCreateServicioComponent implements OnInit {

  servicioModel: CreateServicioRequest = new CreateServicioRequest();
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  nuevoServicio(){
    this.router.navigate(["/servicios/servicio/nuevo"])
  }

}
