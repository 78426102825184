import { IndiceAjusteContratoDto } from "@models/indice-ajuste-contrato/indiceAjusteContratoDto.model"

export class PeriodoContratoDetailDto{
    public id: number = 0
    public descripcion: string = ""
    public periodo: number = 0
    public periodoMes: number = 0
    public periodoAnio: number = 0
    public descuento: number = 0
    public incremento: number = 0
    public importeAlquiler: number = 0
    public importePunitorio: number = 0
    public importeAlquilerSinPunitorio: number = 0
    public importeEntrega: number = 0
    public importePunitorioEntrega: number = 0
    public importeDifPunitorio: number = 0
    public valorAjuste: number = 0
    public saldoDeudor: number = 0
    public seleccionPago:boolean = true
    public ajusteProcesado:boolean = true
    public fechaPago: string = ""
    public fechaAdenda: string = ""
    public fechaAjuste: string = ""
    public fechaModificacion: string = ""
    public fechaAlta: string = ""
    public indiceAjusteContrato!: IndiceAjusteContratoDto

}

