<div class="container-fluid">
    <mat-card *appRole="['Acreditaciones.Ver']">
        <ngx-spinner [zIndex]="100" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">

        </ngx-spinner>
        <div class="row">
            <div class="col-12">
                <form [formGroup]="form">
                    <mat-card>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-3">
                                    <mat-form-field class="full-width">
                                        <mat-label>Ingrese rango de fechas</mat-label>
                                        <mat-date-range-input [rangePicker]="picker">
                                            <input matStartDate [disabled]="true" formControlName="FechaDesde" placeholder="Fecha desde" />
                                            <input matEndDate [disabled]="true" formControlName="FechaHasta" placeholder="Fecha hasta" />
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <mat-form-field class="example-full-width">
                                        <ngx-spinner [zIndex]="100" name="spBusquedaFormasPago" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                        <mat-label>Formas de pago</mat-label>
                                        <mat-select placeholder="Seleccione opcion" multiple formControlName="FormasPago">
                                            <mat-option [value]="formaPago.id" *ngFor="let formaPago of lstFormasPago;let i=index;trackBy:trackByItems">
                                                {{ formaPago.descripcion | titlecase }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <mat-form-field class="example-full-width">
                                        <ngx-spinner [zIndex]="100" name="spBusquedaEstadosPagos" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                        <mat-label>Estados Pago</mat-label>
                                        <mat-select placeholder="Seleccione opcion" multiple formControlName="EstadosPago">
                                            <mat-option [value]="estadoPago.id" *ngFor="let estadoPago of lstEstadosPago;let i=index;trackBy:trackByItems">
                                                {{ estadoPago.descripcion | titlecase }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <button mat-stroked-button color="primary" (click)="filtrar()">
                    <mat-icon>filter_alt</mat-icon>
                    Filtrar
                  </button>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </form>
            </div>
        </div>
        <div class="row" [hidden]=!data>
            <div class="col-12">
                <mat-form-field class="example-full-width">
                    <mat-label>Buscar</mat-label>
                    <input matInput type="search" (keyup)="applyFilter($event)">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <br>
                <table mat-table [dataSource]="dataSource" table matSort matSortStart="asc" class="mat-elevation-z8">

                    <ng-container matColumnDef="fecha">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Fecha de pago"> Fecha pago </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> <span>{{element.pago == undefined ? "": element.pago.fechaPago | date:"dd/MM/yyyy"}} </span></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="fechaAcreditacion">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Fecha de acreditación"> Fecha acred. </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> <span>{{element.pago == undefined ? "": element.pago.fechaAcreditacion | date:"dd/MM/yyyy"}}</span> </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="numeroComprobante">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Número de comprobante">Nro. Comprobante </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> <span>{{element.comprobante == undefined ? "": element.comprobante.nroComprobante}}</span> </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="contrato">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Contrato"> Contrato</mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> <span>{{element.comprobante == undefined ? "" : element.comprobante.contrato == undefined ? "" : element.comprobante.contrato.descripcion | titlecase}}</span> </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="estadoPago">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Estado del pago"> Estado pago </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> <span>{{element.pago.estadoPago == undefined ? "" : element.pago.estadoPago.descripcion}} </span></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="formaPago">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Forma de pago"> Forma pago </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> <span>{{element.pago == undefined ? "" : element.pago.formaPago == undefined ? "" : element.pago.formaPago.descripcion}}</span> </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="importe">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Importe"> Importe </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> <span>{{element.pago == undefined ? "" : element.pago.importe | currency}}</span> </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="descripcion">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Descripción"> Descripción </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> <span>{{ element.pago == undefined ? "" : element.pago.descripcion }} </span></mat-cell>
                    </ng-container>

                    <!-- <ng-container matColumnDef="accion">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element" class="action-link">

                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
                            <mat-menu #menu="matMenu">
                            </mat-menu>
                        </mat-cell>
                    </ng-container> -->

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </table>

                <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="50" [pageSizeOptions]="[5, 10, 20,50,100,200]">
                </mat-paginator>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="no-data-table">
                    <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
      datos para mostrar.</span>
                </div>
            </div>
        </div>
    </mat-card>
</div>
