import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-delete-tipo-movimiento',
  templateUrl: './dialog-delete-tipo-movimiento.component.html',
  styleUrls: ['./dialog-delete-tipo-movimiento.component.css']
})
export class DialogDeleteTipoMovimientoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
