import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssignPropiedadPropietarioRequest } from '@models/propiedades/assignPropiedadPropietarioRequest.model';
import { AssingPropietarioTitularRequest } from '@models/propiedades/assingPropietarioTitularRequest.model';
import { RemoveAssignmentPropiedadPropietarioRequest } from '@models/propiedades/removeAssignmentPropiedadPropietarioRequest.model';
import { PersonaPropietarioDto } from '@models/propietarios/personaPropietarioDto.model';
import { PropietarioDto } from '@models/propietarios/propietarioDto.model';
import { PersonasService } from '@services/personas/personas.service';
import { PropiedadesService } from '@services/propiedades/propiedades.service';
import { PropietariosService } from '@services/propietarios/propietarios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { DialogCreatePropietarioComponent } from '../../propietarios/dialog-create-propietario/dialog-create-propietario.component';
import { AccionesDialog } from '@models/base/identificadores.model';

@Component({
  selector: 'app-add-propietario-propiedades',
  templateUrl: './add-propietario-propiedades.component.html',
  styleUrls: ['./add-propietario-propiedades.component.css']
})
export class AddPropietarioPropiedadesComponent implements OnInit {
  data = false
  agregaPropietario: boolean = false;
  enableAddPersona: boolean = true;
  personaPropietarioDto!: PersonaPropietarioDto;
  lstPropietariosPropiedad: PropietarioDto[] = [];
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<PropietarioDto> = new MatTableDataSource;
  idPropiedad: number = 0;
  propietario!: PropietarioDto;

  constructor(
    private dialog: MatDialog,
    private propietariosService: PropietariosService,
    private propiedadesService: PropiedadesService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private personasService: PersonasService
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["nombreCompleto", "titular", "accion"] :
        ["nombreCompleto", "comision", "titular", "accion"];
    });
  }


  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  applyFilter(event: any) {
    if (event && event.target && event.target.value !== undefined) {
const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
}
  }


  ngOnInit(): void {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idPropiedad = Number(routeParams.get('id'));
    this.getAllPersonasPropietarioByIdPropiedad(this.idPropiedad)
  }

  onSubmit() {

  }

  selectPropietario(event: PersonaPropietarioDto) {
    this.personaPropietarioDto = event;
    this.agregaPropietario = true
  }

  getAllPersonasPropietarioByIdPropiedad(idPropiedad: number) {
    this.spinner.show("spAddPropietarioPropiedad")
    this.propiedadesService.getAllPropietariosPropiedad(this.idPropiedad)
      .subscribe(
        data => {
          this.spinner.hide("spAddPropietarioPropiedad")
          this.dataSource = new MatTableDataSource(data);
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide("spAddPropietarioPropiedad")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  editarPersona(idPersona: number) {
    this.getPersonaByIdAndRoute(idPersona)
  }


  getPersonaByIdAndRoute(idPersona: number) {
    this.spinner.show("spPersona")
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        });
  }

  agregarPropietario() {
    this.spinner.show("spAddPropietarioPropiedad")
    let propietario = this.dataSource.data.find(x => x.persona.id === this.personaPropietarioDto.id)
    if (propietario?.persona.id != this.personaPropietarioDto.id) {
      this.agregaPropietario = false
      this.propietariosService.getByIdPersona(this.personaPropietarioDto.id).subscribe(
        data => {
          this.spinner.hide("spAddPropietarioPropiedad")
          this.propietario = data
          this.assignPropiedadPropietario(this.idPropiedad, data)
        },
        error => {
          this.spinner.hide("spAddPropietarioPropiedad")
          this.snackBar.showError(error, "Error");
        }

      )
    }
    else {
      this.spinner.hide()
      this.snackBar.showWarn("Ya se encuentra agregado.", "Error");
    }

  }

  assignPropiedadPropietario(idPropiedad: number, PropietarioDto: PropietarioDto) {
    this.spinner.show("spAddPropietarioPropiedad")
    const assignPropiedadPropietarioRequest = new AssignPropiedadPropietarioRequest
    if (idPropiedad > 0 && PropietarioDto.id > 0) {
      assignPropiedadPropietarioRequest.id_Propiedad = idPropiedad
      assignPropiedadPropietarioRequest.id_Propietario = PropietarioDto.id
      assignPropiedadPropietarioRequest.titular = this.dataSource.data.length == 0 ? true : false
      PropietarioDto.titular = assignPropiedadPropietarioRequest.titular
      this.propiedadesService.assignPropiedadPropietario(assignPropiedadPropietarioRequest)
        .subscribe(
          async data => {
            this.spinner.hide("spAddPropietarioPropiedad")

            this.dataSource.data.push(PropietarioDto as PropietarioDto)
            this.dataSource.data = this.dataSource.data.filter((value: PropietarioDto, key: any) => {
              return true;
            });
            this.data = this.dataSource.data.length >= 1 ? true : false
            this.snackBar.showSuccess(data.text, "Exito");
          },
          error => {
            this.spinner.hide("spAddPropietarioPropiedad")
            this.snackBar.showError(error, "Error");
          }
        )
    }

  }

  openDialogNuevoPropietario() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "80%"
    dialogConfig.maxWidth = "80%"


    const dialogRef = this.dialog.open(DialogCreatePropietarioComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (!isFalsy(result.data) && result.event == AccionesDialog.Aceptar)
        this.assignPropiedadPropietario(this.idPropiedad, result.data)
    });
  }

  removeAssignmentPropiedadPropietario(PropietarioDto: PropietarioDto) {
    this.spinner.show("spAddPropietarioPropiedad")
    if (this.idPropiedad > 0 && PropietarioDto.id > 0) {
      const propietarioPropiedadRequest = new RemoveAssignmentPropiedadPropietarioRequest
      propietarioPropiedadRequest.id_Propiedad = this.idPropiedad
      propietarioPropiedadRequest.id_Propietario = PropietarioDto.id
      this.propiedadesService.removeAssignmentPropiedadPropietario(propietarioPropiedadRequest)
        .subscribe(
          data => {
            this.spinner.hide("spAddPropietarioPropiedad")
            this.dataSource.data.forEach((element, index) => {
              if (element == PropietarioDto)
                this.dataSource.data.splice(index, 1);
            });

            this.data = this.dataSource.data.length >= 1 ? true : false
            this.dataSource = new MatTableDataSource(this.dataSource.data.filter(item => item.id != PropietarioDto.id));
            this.snackBar.showSuccess(data.text, "Exito");
          },
          error => {
            this.spinner.hide("spAddPropietarioPropiedad")
            this.snackBar.showError(error, "Error");
          }
        )
    }

  }

  assignmentPropietarioTitular(PropietarioDto: PropietarioDto) {
    const assingPropietarioTitularRequest: AssingPropietarioTitularRequest = new AssingPropietarioTitularRequest()
    assingPropietarioTitularRequest.id_PropiedadPropietario = PropietarioDto.id
    this.propiedadesService.assingPropietarioTitularRequest(assingPropietarioTitularRequest)
      .subscribe(
        data => {
          this.spinner.hide("spAddPropietarioPropiedad")
        },
        error => {
          this.spinner.hide("spAddPropietarioPropiedad")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  editarPropietario(PropietarioDto: PropietarioDto) {
    this.router.navigate(['/propietario', PropietarioDto.id])
  }



}
