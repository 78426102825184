<div class="row content" *appRole="['Contratos.Ver']">
  <ngx-spinner [zIndex]=100 name="spContratoMain" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
  type="ball-atom" [fullScreen]="false">
</ngx-spinner>
    <div class="col-sm-2">
        <app-nav-panel-contrato [idContrato]="idContrato"></app-nav-panel-contrato>
    </div>
    <div class="col-sm-10">
        <mat-card>

            <mat-card-header>
                <mat-card-title>
                    Contrato
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-form-editar-contrato [idContrato]="idContrato"></app-form-editar-contrato>
            </mat-card-content>
        </mat-card>
    </div>
</div>
