import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-archivos-temporales-contrato',
  templateUrl: './archivos-temporales-contrato.component.html',
  styleUrls: ['./archivos-temporales-contrato.component.css']
})
export class ArchivosTemporalesContratoComponent implements OnInit {

  numeroContrato!: number
  idContrato!: number;
  contratoDetailDto: ContratoDetailDto = new ContratoDetailDto;

  constructor(
    public dialog: MatDialog,
    private aRoute: ActivatedRoute,
    private contratoService: ContratosService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idContrato = Number(routeParams.get('id'));
    if (this.contratoService.getContratoDto() != null) {
      this.contratoDetailDto = this.contratoService.getContratoDto()
      this.idContrato = this.contratoDetailDto.id
    }
    else
      this.getContratoById(this.idContrato)
  }

  ngAfterViewInit() {
  }



  getContratoById(idContrato: number) {
    this.spinner.show("spContrato");
    this.contratoService.getById(idContrato).subscribe(
      data => {
        this.spinner.hide("spContrato");
        this.contratoDetailDto = data
        this.contratoService.setContratoDto(data);

      },
      error => {
        this.spinner.hide("spContrato");
        this.snackBar.showError(error, "Error");
      }
    )
  }

}
