import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AccionesDialog, TiposMovimiento } from '@models/base/identificadores.model';
import { BonificacionDto } from '@models/bonificaciones/bonificacionDto.model';
import { MovimientoDetailDto } from '@models/movimiento/movimientoDetailDto.model';
import { MovimientoDto } from '@models/movimiento/movimientoDto.model';
import { TipoMovimientoDto } from '@models/tipos-movimiento/tipoMovimientoDto.model';
import { isFalsy } from 'utility-types';
import { DialogEditarMovimientosComponent } from '../dialog-editar-movimientos/dialog-editar-movimientos.component';
import { DialogNuevoMovimientoBonificacionComponent } from './dialog-nuevo-movimiento-bonificacion/dialog-nuevo-movimiento-bonificacion.component';

@Component({
  selector: 'app-dialog-editar-bonificaciones',
  templateUrl: './dialog-editar-bonificaciones.component.html',
  styleUrls: ['./dialog-editar-bonificaciones.component.css'],
  providers: [
    [DatePipe],
  ],
})
export class DialogEditarBonificacionesComponent implements OnInit {

  //#region Variables

  numSelected: number = 0;
  displayedColumns: string[] = ['select', 'tipoBonificacion', 'detalle', 'importe', 'accion'];
  dataSource = new MatTableDataSource<MovimientoDetailDto>();
  selection = new SelectionModel<MovimientoDetailDto>(true, []);
  movimientosBonificaciones: MovimientoDetailDto[] = []

  //#endregion
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogEditarMovimientosComponent>,
    public datepipe: DatePipe,
  ) { }


  ngOnInit(): void {
    this.movimientosBonificaciones = this.data
    this.movimientosBonificaciones.forEach(element => {
      if (element.seleccionPago == true)
        this.selection.select(element)
    });
    this.dataSource.data = this.movimientosBonificaciones
  }

  onSubmit() {
    this.movimientosBonificaciones.forEach(element => {
      element.seleccionPago = false
    })
    let movimientosBonificaciones = this.selection.selected
    movimientosBonificaciones.forEach(element => {
      this.movimientosBonificaciones.forEach(element2 => {
        if (element.id == element2.id) {
          element2.bonificacion = element.bonificacion
          element2.descripcion = isFalsy(element.descripcion) ? "" : element.descripcion
          element2.id = 0
          element2.seleccionPago = true
          element2.periodoMes = new Date().getMonth() + 1
          element2.periodoAnio = new Date().getFullYear()
          element2.periodo = 0
          element2.importe = element.importe
          element2.tipoMovimiento = { id: TiposMovimiento.Bonificaciones, descripcion: "Bonificación", resta: true, editable: false,esAnulable: true }
          element2.seleccionPago = true
          let fechaEmision = isFalsy(this.datepipe.transform(new Date, 'yyyy-MM-dd')) ? "" : this.datepipe.transform(new Date, 'yyyy-MM-dd')
          element2.fechaEmision = isFalsy(fechaEmision) ? "" : fechaEmision;
          let fechaPago = this.datepipe.transform(new Date, 'yyyy-MM-dd') ? "" : this.datepipe.transform(new Date, 'yyyy-MM-dd')
          element2.fechaPago = isFalsy(fechaPago) ? "" : fechaPago;
        }
      })
    });
    this.dialogRef.close({ data: this.movimientosBonificaciones, event: AccionesDialog.Aceptar });
  }

  onCancel() {
    this.dialogRef.close({ data: this.movimientosBonificaciones, event: AccionesDialog.Cancelar });
  }

  openDialogNuevoBonificacion() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%"
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      event: AccionesDialog.Agregar,
      importe: 0,
      bonificaciones: new BonificacionDto,
    }

    let dialogRef = this.dialog.open(DialogNuevoMovimientoBonificacionComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        let bonificacion = result.data.bonificacion as BonificacionDto
        let importe = result.data.importe as number
        let descripcion = result.data.descripcion
        let movimientoDetailDto = new MovimientoDetailDto
        let fechaEmision = isFalsy(this.datepipe.transform(new Date, 'yyyy-MM-dd')) ? "" : this.datepipe.transform(new Date, 'yyyy-MM-dd')
        let fechaPago = isFalsy(this.datepipe.transform(new Date, 'yyyy-MM-dd')) ? "" : this.datepipe.transform(new Date, 'yyyy-MM-dd')

        let tipoMovimiento = new TipoMovimientoDto
        movimientoDetailDto.fechaEmision = isFalsy(fechaEmision) ? "" : fechaEmision;
        movimientoDetailDto.fechaPago = isFalsy(fechaPago) ? "" : fechaPago;
        movimientoDetailDto.id = Math.floor(Math.random() * (10000 - 10 + 1) + 1)
        movimientoDetailDto.bonificacion = bonificacion
        tipoMovimiento.id = TiposMovimiento.Bonificaciones
        tipoMovimiento.descripcion = "Bonificaciones"
        tipoMovimiento.resta = false
        movimientoDetailDto.tipoMovimiento = tipoMovimiento
        movimientoDetailDto.importe = importe
        movimientoDetailDto.descripcion = descripcion

        this.agregarBonificacionAMovimiento(movimientoDetailDto)
      }

    });
  }

  agregarBonificacionAMovimiento(movimientoDetailDto: MovimientoDetailDto) {
    this.movimientosBonificaciones.push(movimientoDetailDto)
    this.selection.select(movimientoDetailDto)
    this.movimientosBonificaciones.forEach(element => {
      if (element.seleccionPago == true)
        this.selection.select(element)
    });
    this.dataSource.data = this.movimientosBonificaciones
  }

  eliminarBonifiacion(element: any) {
    this.dataSource.data = this.dataSource.data.filter((value, key: any) => {
      return value != element
    });
    this.movimientosBonificaciones.forEach((movimiento, index) => {
      if (movimiento == element)
        this.movimientosBonificaciones.splice(index, 1);
    });
    this.data = this.dataSource.data.length >= 1 ? true : false
  }

  isAllSelected() {
    this.numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numSelected === numRows;
  }


  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: MovimientoDetailDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }
}


