import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { PersonaDto } from '@models/personas/personaDto.model';

@Component({
  selector: 'app-buscar-persona',
  templateUrl: './buscar-persona.component.html',
  styleUrls: ['./buscar-persona.component.css']
})
export class BuscarPersonaComponent implements OnInit {

  public filteredPersonas!: Observable<PersonaDto[]>;
  personas: PersonaDto[] = []
  personaDetailDto: PersonaDetailDto = new PersonaDetailDto;
  public entityControl = new FormControl();
  @Output() eventEntity = new EventEmitter<PersonaDetailDto>();
  @Input() personaEntrada: PersonaDetailDto = new PersonaDetailDto;
  @Input() idPersonaInput: number = 0;
  @Input() disable: boolean = false
  private idPersona?: number;
  entitySelect!: number | null;
  disabledDocumento: FormControl = new FormControl
  esPersona: boolean = false;

  constructor(
    private personasService: PersonasService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAllPersonas();

    if (this.idPersonaInput !== 0) {
      this.getPersonaById(this.idPersonaInput);
      this.entityControl.setValue(this.personaEntrada.nombreCompleto);
    }

    this.filteredPersonas = this.entityControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        if (value) {
          return this.findOption(value);
        } else {
          return of(this.personas);
        }
      })
    );
  }

  ngOnChanges(): void {
    if (this.idPersonaInput !== 0) {
      this.getPersonaById(this.idPersonaInput);
    }
    this.entityControl.setValue(this.personaEntrada.nombreCompleto);
  }

  selectValue(event: any): void {
    if (isFalsy(event)) {
      this.entitySelect = null;
      this.entityControl.setValue("");
      this.filteredPersonas = of(this.personas);
      this.eventEntity.emit(undefined);
    } else {
      this.entitySelect = event.id;
      if (!event.correo) {
        this.snackBar.showWarn("La persona seleccionada no tiene correo electrónico. Por favor, actualícela o seleccione otra persona.", "Advertencia");
        return;
      }
      this.eventEntity.emit(event);
    }
  }

  private findOption(value: string): Observable<PersonaDto[]> {
    const filterValue = value.toLowerCase();
    return of(this.personas).pipe(
      map(personas => personas
        .filter(persona => persona.nombreCompleto.toLowerCase().includes(filterValue))
        .sort((a, b) => a.nombreCompleto.localeCompare(b.nombreCompleto))
      ),
      catchError(error => {
        this.snackBar.showError(error, "Error");
        return of([]);
      })
    );
  }

  getAllPersonas(): void {
    this.spinner.show("spBusqueda");
    this.personasService.getAll().subscribe(
      data => {
        this.spinner.hide("spBusqueda");
        this.personas = data.sort((a, b) => a.nombreCompleto.localeCompare(b.nombreCompleto));
        this.filteredPersonas = this.entityControl.valueChanges.pipe(
          startWith(''),
          debounceTime(300),
          distinctUntilChanged(),
          switchMap(value => {
            if (value) {
              return this.findOption(value);
            } else {
              return of(this.personas);
            }
          })
        );
      },
      error => {
        this.spinner.hide("spBusqueda");
        this.snackBar.showError(error, "Error");
      }
    );
  }

  getPersonaById(id: number): void {
    this.spinner.show("spBusqueda");
    this.personasService.getPersonaById(id).subscribe(
      data => {
        this.spinner.hide("spBusqueda");
        this.personaEntrada = data;
        this.eventEntity.emit(data);
        this.personaDetailDto = data;
        this.esPersona = true;
      },
      error => {
        this.spinner.hide("spBusqueda");
        this.snackBar.showError(error, "Error");
      }
    );
  }

  editarPersona(idPersona: number): void {
    this.getPersonaByIdAndRoute(idPersona);
  }

  private getPersonaByIdAndRoute(idPersona: number): void {
    this.spinner.show("spPersona");
    this.personasService.getPersonaById(idPersona).subscribe(
      data => {
        this.spinner.hide("spPersona");
        this.personasService.setPersona(data);
        this.router.navigate(["/personas/editar"]);
      },
      error => {
        this.spinner.hide("spPersona");
        this.snackBar.showError(error, "Error");
      }
    );
  }

  trackByItems(index: number, item: PersonaDto): number {
    return item.id;
  }
}
