import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AppTestComponent } from '@views/app-test/app-test.component';
import { CanDeactivateGuard } from '@directives/can-deactivate/can-deactivate.guard';
import { AuthGuardService } from '@guards/auth-guard/auth-guard.service';
import { RolClientGuard } from '@guards/rol-client-guard/rol-client.guard';
import { RolUserGuard } from '@guards/rol-user-guard/rol-user.guard';
import { ListadoArchivosTemporalesComponent } from '@views/archivos-temporales/listado-archivos-temporales/listado-archivos-temporales.component';
import { AsuntosComponent } from '@views/asuntos/asuntos/asuntos.component';
import { BancosComponent } from '@views/bancos/bancos/bancos.component';
import { BonificacionesComponent } from '@views/bonificaciones/bonificaciones/bonificaciones.component';
import { CantidadesTipoGarantiaComponent } from '@views/cantidades-tipo-garantia/cantidades-tipo-garantia/cantidades-tipo-garantia.component';
import { ConfiguracionComponent } from '@views/configuracion/configuracion/configuracion.component';
import { EmpresaComponent } from '@views/configuracion/empresa/empresa.component';
import { ArchivosTemporalesContratoComponent } from '@views/contratos/archivos-temporales-contrato/archivos-temporales-contrato.component';
import { ListadoComprobantesContratoComponent } from '@views/contratos/comprobantes-contrato/listado-comprobantes-contrato/listado-comprobantes-contrato.component';
import { ContratosPersonasComponent } from '@views/contratos/contratos-personas/contratos-personas.component';
import { ContratoComponent } from '@views/contratos/form-contrato/contrato.component';
import { FormNuevoContratoComponent } from '@views/contratos/form-nuevo-contrato/form-nuevo-contrato.component';
import { ListadoContratosComponent } from '@views/contratos/listado-contrato/listado-contratos.component';
import { MovimientosContratoComponent } from '@views/contratos/movimientos-contrato/movimientos-contrato.component';
import { PeriodoshistoricosComponent } from '@views/contratos/periodoshistoricos/periodoshistoricos.component';
import { TipocontratoQuienpagaServicioComponent } from '@views/contratos/tipocontrato-quienpaga-servicio/tipocontrato-quienpaga-servicio/tipocontrato-quienpaga-servicio.component';
import { CuentasContablePersonaComponent } from '@views/cuentas-contables/cuentas-contable-persona/cuentas-contable-persona.component';
import { CuentasContablesComponent } from '@views/cuentas-contables/cuentas-contables/cuentas-contables.component';
import { DashboardComponent } from '@views/dashboard/dashboard/dashboard.component';
import { DetalleServicioPropiedadComponent } from '@views/detalle-servicios-propiedad/detalle-servicio-propiedad/detalle-servicio-propiedad.component';
import { CreateDocumentComponent } from '@views/documentos/create-document/create-document.component';
import { DocumentosContratosComponent } from '@views/documentos/documentos-entidades/documentos-contratos/documentos-contratos.component';
import { DocumentosPersonasComponent } from '@views/documentos/documentos-entidades/documentos-personas/documentos-personas.component';
import { DocumentosPropiedadesComponent } from '@views/documentos/documentos-entidades/documentos-propiedades/documentos-propiedades.component';
import { DocumentosComponent } from '@views/documentos/documentos.component';
import { EditarDocumentoComponent } from '@views/documentos/editar-documento/editar-documento.component';
import { FormDocumentoComponent } from '@views/documentos/form-documento/form-documento.component';
import { BtnSendEmailComponent } from '@views/email/btn-send-email/btn-send-email.component';
import { FormasPagoComponent } from '@views/formas-pago/formas-pago/formas-pago.component';
import { FormGarantiaCreateComponent } from '@views/garantias/form-garantia-create/form-garantia-create.component';
import { FormGarantiaUpdateComponent } from '@views/garantias/form-garantia-update/form-garantia-update.component';
import { GarantiaComponent } from '@views/garantias/form-garantia/garantia.component';
import { GarantiasComponent } from '@views/garantias/garantias/garantias.component';
import { ListadoGarantiasComponent } from '@views/garantias/listado-garantias/listado-garantias.component';
import { FormGarantiaCreatePublicComponent } from '@views/garantias/publico/form-garantia-create-public/form-garantia-create-public.component';
import { FormGarantiaUpdatePublicComponent } from '@views/garantias/publico/form-garantia-update-public/form-garantia-update-public.component';
import { GarantiasInquilinoPublicComponent } from '@views/garantias/publico/garantias-inquilino-public/garantias-inquilino-public.component';
import { GenerarResumenMensualComponent } from '@views/generar-resumen-mensual/generar-resumen-mensual.component';
import { GruposTipoGarantiaInquilinoComponent } from '@views/grupos-tipo-garantia-inquilino/grupos-tipo-garantia-inquilino/grupos-tipo-garantia-inquilino.component';
import { GruposTipoGarantiaComponent } from '@views/grupos-tipo-garantia/grupos-tipo-garantia/grupos-tipo-garantia.component';
import { InicioComponent } from '@views/inicio/inicio.component';
import { FormCreateInquilinoComponent } from '@views/inquilinos/form-create-inquilino/form-create-inquilino.component';
import { InquilinoComponent } from '@views/inquilinos/form-inquilino/inquilino.component';
import { FormUpdateInquilinoComponent } from '@views/inquilinos/form-update-inquilino/form-update-inquilino.component';
import { LiquidacionesComponent } from '@views/liquidaciones/liquidaciones/liquidaciones.component';
import { LoginComponent } from '@views/login/login/login.component';
import { ListMovimientosComponent } from '@views/movimientos/list-movimientos/list-movimientos.component';
import { PerfilUsuarioComponent } from '@views/perfil-usuario/perfil-usuario.component';
import { FormPersonaCreateComponent } from '@views/personas/form-persona-create/form-persona-create.component';
import { FormPersonaUpdateComponent } from '@views/personas/form-persona-update/form-persona-update.component';
import { PersonasComponent } from '@views/personas/personas/personas.component';
import { ContratosPropiedadComponent } from '@views/propiedades/contratos-propiedad/contratos-propiedad.component';
import { FormCreatePropiedadComponent } from '@views/propiedades/propiedades/form-create-propiedad/form-create-propiedad.component';
import { FormUpdatePropiedadComponent } from '@views/propiedades/propiedades/form-update-propiedad/form-update-propiedad.component';
import { ListadoPropiedadesComponent } from '@views/propiedades/propiedades/listado-propiedades/listado-propiedades.component';
import { FormCreateServicioPropiedadComponent } from '@views/propiedades/servicios-propiedad/form-create-servicio-propiedad/form-create-servicio-propiedad.component';
import { FormUpdateServicioPropiedadComponent } from '@views/propiedades/servicios-propiedad/form-update-servicio-propiedad/form-update-servicio-propiedad.component';
import { ListadoServiciosPropiedadComponent } from '@views/propiedades/servicios-propiedad/listado-servicios-propiedad/listado-servicios-propiedad.component';
import { ServicioPropiedadComponent } from '@views/propiedades/servicios-propiedad/servicio-propiedad/servicio-propiedad.component';
import { ServiciosPropiedadComponent } from '@views/propiedades/servicios-propiedad/servicios-propiedad/servicios-propiedad.component';
import { FormPropietarioCreateComponent } from '@views/propietarios/form-propietario-create/form-propietario-create.component';
import { FormPropietarioUpdateComponent } from '@views/propietarios/form-propietario-update/form-propietario-update.component';
import { PropietarioComponent } from '@views/propietarios/form-propietario/propietario.component';
import { ImportarDetallesServicioPropiedadComponent } from '@views/servicios/importar-detalles-servicio-propiedad/importar-detalles-servicio-propiedad/importar-detalles-servicio-propiedad.component';
import { ListadoDetalleServicioPropiedadComponent } from '@views/detalle-servicios-propiedad/listado-detalle-servicio-propiedad/listado-detalle-servicio-propiedad.component';
import { ListadoServiciosComponent } from '@views/servicios/listado-servicios/listado-servicios.component';
import { FormServicioCreateComponent } from '@views/servicios/servicio/form-servicio-create/form-servicio-create.component';
import { FormServicioUpdateComponent } from '@views/servicios/servicio/form-servicio-update/form-servicio-update.component';
import { ServicioComponent } from '@views/servicios/servicio/servicio.component';
import { ServiciosComponent } from '@views/servicios/servicios/servicios.component';
import { TiposBonificacionComponent } from '@views/tipos-bonificacion/tipos-bonificacion/tipos-bonificacion.component';
import { TiposCaracteristicaComponent } from '@views/tipos-caracteristicas/tipos-caracteristica/tipos-caracteristica.component';
import { TiposComprobanteComponent } from '@views/tipos-comprobante/tipos-comprobante/tipos-comprobante.component';
import { TiposCondicionContableComponent } from '@views/tipos-condicion-contable/tipos-condicion-contable/tipos-condicion-contable.component';
import { TiposGarantiaComponent } from '@views/tipos-garantia/tipos-garantia/tipos-garantia.component';
import { TiposPunitorioComponent } from '@views/tipos-punitorios/tipos-punitorio/tipos-punitorio.component';
import { TiposSelladoComponent } from '@views/tipos-sellados/tipos-sellado/tipos-sellado.component';
import { TiposServiciosComponent } from '@views/tipos-servicios/tipos-servicios/tipos-servicios.component';
import { TiposFolioComponent } from '@views/tiposFolio/tipos-folio/tipos-folio.component';
import { FormUsuarioCreateComponent } from '@views/usuarios/form-usuario-create/form-usuario-create.component';
import { FormUsuarioUpdateComponent } from '@views/usuarios/form-usuario-update/form-usuario-update.component';
import { UsuariosComponent } from '@views/usuarios/listado-usuarios/usuarios.component';
import { Error500Component } from '@views/errors/error500/error500.component';
import { ListadoMenuComponent } from '@views/seguridad/menus/listar-menu/listado-menu.component';
import { ListadoPermisoComponent } from '@views/seguridad/permisos/listar-permiso/listado-permiso.component';
import { ListadoCasoUsoComponent } from '@views/seguridad/casos-uso/listar-caso-uso/listado-caso-uso.component';
import { ListadoRolComponent } from '@views/seguridad/roles/listado-rol/listado-rol.component';
import { ListarTipoMovimientoComponent } from '@views/tipos-movimientos/listar-tipo-movimiento/listar-tipo-movimiento.component';
import { ListadoCajaComponent } from '@views/caja/listado-caja/listado-caja.component';
import { ListadoComprobantesComponent } from '@views/comprobantes/listado-comprobantes/listado-comprobantes.component';
import { ListadoAuditoriaLogComponent } from '@views/auditoria-log/listado-auditoria-log/listado-auditoria-log.component';
import { TiposAjusteContratoComponent } from '@views/tipos-ajuste-contrato/tipos-ajuste-contrato/tipos-ajuste-contrato.component';
import { CalculaIndiceAjustePeriodosContratoComponent } from '@views/procesos/calcula-indice-ajuste-periodos-contrato/calcula-indice-ajuste-periodos-contrato/calcula-indice-ajuste-periodos-contrato.component';
import { IndicesAjusteContratoComponent } from '@views/indices-ajuste-contrato/indices-ajuste-contrato/indices-ajuste-contrato.component';
import { PeriodoContratoComponent } from '@views/periodos-contrato/periodos-contrato/periodo-contrato.component';
import { DashboardClienteComponent } from '@views/dashboard/dashboard-cliente/dashboard-cliente.component';
import { FormGarantiaViewPublicComponent } from '@views/garantias/publico/form-garantia-view-public/form-garantia-view-public.component';
import { TemplatesComponent } from '@views/templates/templates/templates.component';

const routes: Routes = [

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: '500', component: Error500Component },
  {
    path: '', component: InicioComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Inicio',
      breadcrumb: [
        {
          label: 'Inicio',
          url: ''
        }
      ]
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuardService],
        data: {
          title: 'Dashboard',
          breadcrumb: [
            {
              label: 'Dashboard',
              url: ''
            }
          ]
        },
      },
      {
        path: 'miPanel',
        component: DashboardClienteComponent,
        canActivate: [AuthGuardService],
        data: {
          title: 'Panel Principal',
          breadcrumb: [
            {
              label: 'Panel Principal',
              url: ''
            }
          ]
        },
      },
      {
        path: 'perfilUsuarios',
        component: PerfilUsuarioComponent,
        canActivate: [AuthGuardService],
        data: {
          title: 'Perfil Usuario',
          breadcrumb: [
            {
              label: 'Perfil Usuario',
              url: ''
            }
          ]
        },
      },
      {
        path: 'configuracion',
        component: ConfiguracionComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Configuración',
          breadcrumb: [
            {
              label: 'Configuración',
              url: ''
            }
          ]
        },
      },
      {
        path: 'auditorialog',
        component: ListadoAuditoriaLogComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Auditoria de Logs',
          breadcrumb: [
            {
              label: 'Auditoria de Logs',
              url: ''
            }
          ]
        },
      },
      {
        path: 'personas',
        canActivate: [AuthGuardService],
        data: {
          title: 'Listado Personas',
          breadcrumb: [
            {
              label: 'Listado Personas',
              url: ''
            }
          ]
        },
        children: [
          {
            path: '',
            component: PersonasComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Listado Personas',
              breadcrumb: [
                {
                  label: 'Listado Personas',
                  url: ''
                }
              ]
            }
          },
          {
            path: 'nuevo',
            component: FormPersonaCreateComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Nueva persona',
              breadcrumb: [
                {
                  label: 'Listado de personas',
                  url: 'personas'
                },
                {
                  label: 'Nueva persona',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'editar',
            component: FormPersonaUpdateComponent,
            canActivate: [AuthGuardService],
            canDeactivate: [CanDeactivateGuard],
            data: {
              title: 'Editar personas',
              breadcrumb: [
                {
                  label: 'Listado de personas',
                  url: 'personas'
                },
                {
                  label: 'Persona',
                  url: ''
                }
              ]
            },
          }
        ]
      },
      {
        path: 'propietario',
        component: PropietarioComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Propietario',
          breadcrumb: [
            {
              label: 'Listado de personas',
              url: 'personas'
            },
            {
              label: 'Propietario ',
              url: ''
            }
          ]
        },
        children: [
          {
            path: 'editar',
            component: FormPropietarioUpdateComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Editar propietario',
              breadcrumb: [
                {
                  label: 'Listado de personas',
                  url: 'personas'
                },
                {
                  label: 'Editar ropietario',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'nuevo',
            component: FormPropietarioCreateComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Nuevo propietario',
              breadcrumb: [
                {
                  label: 'Listado de personas',
                  url: 'personas'
                },
                {
                  label: 'Nuevo propietario',
                  url: ''
                }
              ]
            },
          },

        ]
      },
      {
        path: 'inquilino',
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Inquilino',
          breadcrumb: [
            {
              label: 'Listado de personas',
              url: 'personas'
            },
            {
              label: 'Inquilino',
              url: ''
            }
          ]
        },
        children: [
          {
            path: '',
            component: InquilinoComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'Inquilino',
              breadcrumb: [
                {
                  label: 'Listado de personas',
                  url: 'personas'
                },
                {
                  label: 'Inquilino',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'nuevo',
            component: FormCreateInquilinoComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'Nuevo inquilino',
              breadcrumb: [
                {
                  label: 'Listado de personas',
                  url: 'personas'
                },
                {
                  label: 'Nuevo inquilino',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'editar',
            component: FormUpdateInquilinoComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'Editar inquilino',
              breadcrumb: [
                {
                  label: 'Listado de personas',
                  url: 'personas'
                },
                {
                  label: 'Editar inquilino',
                  url: ''
                }
              ]
            },
          }]
      },
      {
        path: 'garantias',
        component: GarantiasComponent,
        canActivate: [AuthGuardService],
        children: [
          {
            path: 'misGarantias',
            component: GarantiasInquilinoPublicComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'Listado de Garantias',
              breadcrumb: [
                {
                  label: 'Listado de Garantias',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'personaDocumentos',
            component: DocumentosPersonasComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'Documentos personas',
              breadcrumb: [
                {
                  label: '',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'nuevo',
            component: FormGarantiaCreatePublicComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'Nueva Garantia',
              breadcrumb: [
                {
                  label: 'Listado de Garantias',
                  url: 'garantias/misGarantias'
                },

                {
                  label: 'Nueva garantia',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'editar',
            component: FormGarantiaUpdatePublicComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'Editar Garantia',
              breadcrumb: [
                {
                  label: 'Listado de Garantias',
                  url: 'garantias/misGarantias'
                },
                {
                  label: 'Editar garantia',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'ver',
            component: FormGarantiaViewPublicComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'Ver Garantia',
              breadcrumb: [
                {
                  label: 'Listado de Garantias',
                  url: 'garantias/misGarantias'
                },
                {
                  label: 'Ver garantia',
                  url: ''
                }
              ]
            },
          },
        ]
      },

      {
        path: 'garantia',
        component: GarantiaComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Garantia',
          breadcrumb: [
            {
              label: 'Listado de personas',
              url: 'personas'
            },
            {
              label: 'Garantia - Persona',
              url: ''
            }
          ]
        },
        children: [
          {
            path: '',
            component: ListadoGarantiasComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'Listado Garantias',
              breadcrumb: [
                {
                  label: 'Listado Garantias',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'nuevo',
            component: FormGarantiaCreateComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'Garantia',
              breadcrumb: [
                {
                  label: 'Nueva garantia',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'editar/:idGarantia',
            component: FormGarantiaUpdateComponent,
            canActivate: [AuthGuardService],
            data: {
              title: 'Editar garantia',
              breadcrumb: [
                {
                  label: 'Editar garantia - #{{idGarantia}}',
                  url: ''
                }
              ]
            },
          },
        ]
      },
      {
        path: 'tipos-garantia',
        component: TiposGarantiaComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Tipos de Garantia',
          breadcrumb: [
            {
              label: 'Tipos de Garantias',
              url: ''
            }
          ]
        },
      },
      {
        path: 'cantidad-tipo-garantia',
        component: CantidadesTipoGarantiaComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Cantidad de Tipos de Garantia',
          breadcrumb: [
            {
              label: 'Cantidad de Tipos de Garantias',
              url: ''
            }
          ]
        },
      },
      {
        path: 'grupos-tipo-garantia',
        component: GruposTipoGarantiaComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Grupos de garantias',
          breadcrumb: [
            {
              label: 'Grupos de garantias',
              url: ''
            }
          ]
        },
      },
      {
        path: 'grupos-tipo-garantia-inquilino',
        component: GruposTipoGarantiaInquilinoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Grupos de garantias por Inquilino',
          breadcrumb: [
            {
              label: 'Grupos de garantias por Inquilino',
              url: ''
            }
          ]
        },
      },
      {
        path: 'documentospersona',
        component: DocumentosPersonasComponent,
        canActivate: [AuthGuardService],
        data: {
          title: 'Documentos Persona',
          breadcrumb: [
            {
              label: 'Listado de personas',
              url: 'personas'
            },
            {
              label: 'Documentos -  Persona',
              url: ''
            }
          ]
        },
      },
      {
        path: 'contratos-personas',
        component: ContratosPersonasComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Contratos Persona',
          breadcrumb: [
            {
              label: 'Listado de personas',
              url: 'personas'
            },
            {
              label: 'Contratos - Persona',
              url: ''
            }
          ]
        },
      },
      {
        path: 'usuarios',
        children: [
          {
            path: '',
            component: UsuariosComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Listado Usuarios',
              breadcrumb: [
                {
                  label: 'Listado Usuarios',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'nuevo',
            component: FormUsuarioCreateComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Usuario nuevo',
              breadcrumb: [
                {
                  label: 'Listado de usuarios',
                  url: '/usuarios'
                },
                {
                  label: 'Usuario nuevo',
                  url: ''
                }
              ]
            },
          },
          {
            path: ':id',
            component: FormUsuarioUpdateComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Usuario ',
              breadcrumb: [
                {
                  label: 'Listado de usuarios',
                  url: '/usuarios'
                },
                {
                  label: 'Editar usuario ',
                  url: ''
                }
              ]
            },
          },
        ]
      },
      {
        path: 'propiedades',
        canActivate: [AuthGuardService, RolClientGuard],
        children: [
          {
            path: '',
            component: ListadoPropiedadesComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Propiedad',
              breadcrumb: [
                {
                  label: 'Listado de propiedades',
                  url: 'propiedades'
                }
              ]
            },
          },
          {
            path: 'nuevo',
            component: FormCreatePropiedadComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Propiedad',
              breadcrumb: [
                {
                  label: 'Listado de propiedades',
                  url: '/propiedades'
                },
                {
                  label: 'Propiedad nueva',
                  url: ''
                }
              ]
            },
          },
          {
            path: ':id',
            component: FormUpdatePropiedadComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Editar propiedad ',
              breadcrumb: [
                {
                  label: 'Listado de propiedades',
                  url: '/propiedades'
                },
                {
                  label: 'Editar propiedad',
                  url: ''
                }
              ]
            },
          },
        ]
      },
      {
        path: 'documentospropiedad/:id',
        component: DocumentosPropiedadesComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Documento propiedad ',
          breadcrumb: [
            {
              label: 'Listado de propiedades',
              url: '/propiedades'
            },
            {
              label: 'Documento propiedad',
              url: ''
            }
          ]
        },
      },
      {
        path: 'contratospropiedad/:id',
        component: ContratosPropiedadComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Contratos de la propiedad',
          breadcrumb: [
            {
              label: 'Contratos de la propiedad ',
              url: ''
            }
          ]
        },
      },
      {
        path: 'documentoscontrato/:id',
        component: DocumentosContratosComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Documento contrato ',
          breadcrumb: [
            {
              label: 'Listado de contratos',
              url: 'contratos'
            },
            {
              label: 'Documento contrato',
              url: ''
            }
          ]
        },
      },
      {
        path: 'archivostemporalescontrato/:id',
        component: ArchivosTemporalesContratoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Archivos temporales ',
          breadcrumb: [
            {
              label: 'Listado de contratos',
              url: 'contratos'
            },
            {
              label: 'Archivos temporales',
              url: ''
            }
          ]
        },
      },
      {
        path: 'archivostemporales',
        component: ListadoArchivosTemporalesComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Archivos temporales',
          breadcrumb: [
            {
              label: 'Archivos temporales',
              url: ''
            }
          ]
        },
      },

      {
        path: 'servicios',
        component: ServiciosComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Servicios',
          breadcrumb: [
            {
              label: 'Servicios',
              url: ''
            }
          ]
        },
        children:
          [
            {
              path: '',
              component: ListadoServiciosComponent,
              canActivate: [AuthGuardService, RolClientGuard],
              data: {
                title: 'Servicios',
                breadcrumb: [
                  {
                    label: 'Listado de servicios',
                    url: ''
                  }
                ]
              },
            },
            {
              path: 'servicio',
              component: ServicioComponent,
              canActivate: [AuthGuardService, RolClientGuard],
              data: {
                title: 'Servicio',
                breadcrumb: [
                  {
                    label: 'Servicio',
                    url: ''
                  }
                ]
              },
              children: [
                {
                  path: 'nuevo',
                  component: FormServicioCreateComponent,
                  canActivate: [AuthGuardService, RolClientGuard],
                  data: {
                    title: 'Nuevo servicio',
                    breadcrumb: [
                      {
                        label: 'Listado servicios',
                        url: 'servicios'
                      },
                      {
                        label: 'Nuevo ',
                        url: ''
                      }
                    ]
                  },
                },
                {
                  path: 'editar/:id',
                  component: FormServicioUpdateComponent,
                  canActivate: [AuthGuardService, RolClientGuard],
                  data: {
                    title: 'Editar servicio',
                    breadcrumb: [
                      {
                        label: 'Listado servicios',
                        url: 'servicios'
                      },
                      {
                        label: 'Editar servicio',
                        url: ''
                      }
                    ]
                  },
                }
              ]
            },
          ]
      },
      {
        path: 'serviciospropiedad',
        component: ServiciosPropiedadComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Servicios propiedad',
          breadcrumb: [
            {
              label: 'Listado de propiedades',
              url: '/propiedades'
            },
            {
              label: 'Servicios propiedad',
              url: ''
            }
          ]
        },
        children:
          [
            {
              path: 'servicio/:id',
              component: ServicioPropiedadComponent,
              canActivate: [AuthGuardService, RolClientGuard],
              data: {
                title: 'Servicio propiedad',
                breadcrumb: [
                  {
                    label: 'Listado de propiedades',
                    url: '/propiedades'
                  },
                  {
                    label: 'Servicio propiedad',
                    url: ''
                  }
                ]
              },
              children: [
                {
                  path: '',
                  component: ListadoServiciosPropiedadComponent,
                  canActivate: [AuthGuardService, RolClientGuard],
                  data: {
                    title: 'Servicios propiedad',
                    breadcrumb: [
                      {
                        label: 'Listado de propiedades',
                        url: '/propiedades'
                      },
                      {
                        label: 'Listado de servicios propiedad',
                        url: ''
                      }
                    ]
                  },
                },
                {
                  path: 'nuevo/:idPropiedad',
                  component: FormCreateServicioPropiedadComponent,
                  canActivate: [AuthGuardService, RolClientGuard],
                  data: {
                    title: 'Nuevo servicio propiedad',
                    breadcrumb: [
                      {
                        label: 'Nuevo ',
                        url: ''
                      }
                    ]
                  },
                },
                {
                  path: 'editar/:id',
                  component: FormUpdateServicioPropiedadComponent,
                  canActivate: [AuthGuardService, RolClientGuard],
                  data: {
                    title: 'Editar servicio propiedad',
                    breadcrumb: [
                      {
                        label: 'Editar servicio propiedad',
                        url: ''
                      }
                    ]
                  },
                }
              ]
            },
          ]
      },

      {
        path: 'tiposservicios',
        component: TiposServiciosComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Tipos de servicios',
          breadcrumb: [
            {
              label: 'Servicios',
              url: 'servicios'
            },
            {
              label: 'Tipos de servicios',
              url: ''
            }
          ]
        }
      },
      {
        path: 'bancos',
        component: BancosComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Bancos',
          breadcrumb: [
            {
              label: 'Bancos',
              url: ''
            }
          ]
        }
      },
      {
        path: 'tiposfolio',
        component: TiposFolioComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Tipos de Folio',
          breadcrumb: [
            {
              label: 'Tipos de Folio',
              url: ''
            }
          ]
        }
      },
      {
        path: 'asuntos',
        component: AsuntosComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Asuntos',
          breadcrumb: [
            {
              label: 'Asuntos',
              url: ''
            }
          ]
        }
      },
      {
        path: 'tiposcaracteristicaspropiedad',
        component: TiposCaracteristicaComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Tipos de caracteristicas',
          breadcrumb: [
            {
              label: 'Propiedades',
              url: 'propiedades'
            },
            {
              label: 'Tipos de caracteristicas',
              url: ''
            }
          ]
        }
      },
      {
        path: 'detalleservicios',
        component: ListadoDetalleServicioPropiedadComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Listado de Detalle de Servicios',
          breadcrumb: [
            {
              label: 'Listado de Detalle de Servicios',
              url: ''
            }
          ]
        }
      },
      {
        path: 'detalleservicios/:id',
        component: DetalleServicioPropiedadComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Detalle de Servicios',
          breadcrumb: [
            {
              label: 'Listado de propiedades',
              url: '/propiedades'
            },
            {
              label: 'Detalle de Servicios',
              url: ''
            }
          ]
        }
      },
      //Contratos
      {
        path: 'contratos',
        canActivate: [AuthGuardService, RolClientGuard],
        children: [
          {
            path: '',
            component: ListadoContratosComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Listado de contratos',
              breadcrumb: [
                {
                  label: 'Listado de contratos',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'nuevo',
            component: FormNuevoContratoComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            canDeactivate: [CanDeactivateGuard],
            data: {
              title: 'Nuevo contrato',
              breadcrumb: [
                {
                  label: 'Listado de contratos',
                  url: 'contratos'
                }, {
                  label: 'Nuevo contrato',
                  url: ''
                }
              ]
            },
          },
          {
            path: ':id',
            component: ContratoComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Contrato',
              breadcrumb: [
                {
                  label: 'Listado de contratos',
                  url: 'contratos'
                },
                {
                  label: 'Contrato',
                  url: ''
                }
              ]
            }
          },
        ]
      },
      {
        path: 'generarliquidaciones',
        component: LiquidacionesComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Generación de liquidaciones automaticas',
          breadcrumb: [
            {
              label: 'Generación de liquidaciones automaticas',
              url: ''
            }
          ]
        },
      },
      {
        path: 'tipospunitorio',
        component: TiposPunitorioComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Tipos de Punitorios',
          breadcrumb: [
            {
              label: 'Tipos de Punitorios',
              url: ''
            }
          ]
        },
      },
      {
        path: 'tipossellado',
        component: TiposSelladoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Tipos de Sellados',
          breadcrumb: [
            {
              label: 'Tipos de Sellados',
              url: ''
            }
          ]
        },
      },
      {
        path: 'calcularajustescontratos',
        component: CalculaIndiceAjustePeriodosContratoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Calcula Ajustes de Contratos',
              url: ''
            }
          ]
        },
      },
      {
        path: 'periodoscontrato',
        component: PeriodoContratoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Periodos contrato',
              url: ''
            }
          ]
        },
      },
      {
        path: 'empresa',
        component: EmpresaComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Empresa',
              url: ''
            }
          ]
        },
      },
      {
        path: 'formaspago',
        component: FormasPagoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Formas de Pagos',
              url: ''
            }
          ]
        },
      },
      {
        path: 'cuentascontables',
        component: CuentasContablesComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Cuentas contables',
              url: ''
            }
          ]
        },
      },
      {
        path: 'cuentascontablepersonas',
        component: CuentasContablePersonaComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Cuentas contables',
              url: ''
            }
          ]
        },
      },

      {
        path: 'bonificaciones',
        component: BonificacionesComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Bonificaciones',
              url: ''
            }
          ]
        },
      },
      {
        path: 'tiposbonificacion',
        component: TiposBonificacionComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Tipos de Bonificaciones',
              url: ''
            }
          ]
        },
      },
      {
        path: 'tiposmovimiento',
        component: ListarTipoMovimientoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Tipos de Movimientos',
              url: ''
            }
          ]
        },
      },
      {
        path: 'importardetalleserviciopropiedad',
        component: ImportarDetallesServicioPropiedadComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Importar Detalles de Servicios',
              url: ''
            }
          ]
        },
      },
      {
        path: 'caja',
        canActivate: [AuthGuardService, RolClientGuard],
        children: [
          {
            path: '',
            component: ListadoCajaComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Administracion de Caja',
              breadcrumb: [
                {
                  label: 'Administracion de Caja',
                  url: 'caja'
                }
              ]
            },
          },
        ]
      },
      {
        path: 'generacionresumenmensual',
        component: GenerarResumenMensualComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Generacion de Resumenes Mensuales',
              url: ''
            }
          ]
        },
      },
      {
        path: 'tipocontratoquienpagaservicios',
        component: TipocontratoQuienpagaServicioComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Quien paga servicio segun tipo de contrato',
              url: ''
            }
          ]
        },
      },
      {
        path: 'tiposcondicioncontable',
        component: TiposCondicionContableComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Tipos de Condicion Contable',
              url: ''
            }
          ]
        },
      },
      {
        path: 'movimientos',
        component: ListMovimientosComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Movimientos',
              url: ''
            }
          ]
        },
      },
      {
        path: 'tiposcomprobante',
        component: TiposComprobanteComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Tipos de comprobantes',
              url: ''
            }
          ]
        },
      },
      {
        path: 'tiposajustecontrato',
        component: TiposAjusteContratoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Tipos de Ajustes Contratos',
              url: ''
            }
          ]
        },
      },
      {
        path: 'indicesajustecontrato',
        component: IndicesAjusteContratoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Indices de Ajustes Contratos',
              url: ''
            }
          ]
        },
      },
      {
        path: 'comprobantes',
        component: ListadoComprobantesComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Comprobantes',
              url: ''
            }
          ]
        },
      },
      {
        path: 'comprobantesdetallados',
        component: ListadoComprobantesComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: '',
          breadcrumb: [
            {
              label: 'Comprobantes detallados',
              url: ''
            }
          ]
        },
      },
      {
        path: 'periodoshistoricos/:id',
        component: PeriodoshistoricosComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Periodos Historicos Contrato',
          breadcrumb: [
            {
              label: 'Listado de contratos',
              url: 'contratos'
            },
            {
              label: 'Periodos Historicos',
              url: ''
            }
          ]
        },
      },
      {
        path: 'comprobantescontrato/:id',
        component: ListadoComprobantesContratoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Comprobantes',
          breadcrumb: [
            {
              label: 'Listado de contratos',
              url: 'contratos'
            },
            {
              label: 'Comprobantes',
              url: ''
            }
          ]
        },
      },
      {
        path: 'movimientosscontrato/:id',
        component: MovimientosContratoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Movimientos',
          breadcrumb: [
            {
              label: 'Listado de contratos',
              url: 'contratos'
            },
            {
              label: 'Movimientos',
              url: ''
            }
          ]
        },
      },
      {
        path: 'fileupload',
        component: BtnSendEmailComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {

        },
      },
      {
        path: 'documentos',
        component: DocumentosComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Documentos',
          breadcrumb: [
            {
              label: 'Documentos',
              url: ''
            }
          ]
        },
      },
      {
        path: 'documento',
        component: FormDocumentoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Documento',
          breadcrumb: [
            {
              label: 'Documento',
              url: ''
            }
          ]
        },
        children: [
          {
            path: 'nuevo',
            component: CreateDocumentComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Nuevo documento',
              breadcrumb: [
                {
                  label: 'Listado documentos',
                  url: 'documentos'
                },
                {
                  label: 'Nuevo ',
                  url: ''
                }
              ]
            },
          },
          {
            path: ':id',
            component: EditarDocumentoComponent,
            canActivate: [AuthGuardService, RolClientGuard],
            data: {
              title: 'Editar documento',
              breadcrumb: [
                {
                  label: 'Editar documento',
                  url: ''
                }
              ]
            },
          }
        ]
      },
      {
        path: 'menus',
        component: ListadoMenuComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Listado de menu',
          breadcrumb: [
            {
              label: 'Listado de menu',
              url: ''
            }
          ]
        },
      },
      {
        path: 'permisos',
        component: ListadoPermisoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Listado de permisos',
          breadcrumb: [
            {
              label: 'Listado de permisos',
              url: ''
            }
          ]
        },
      },
      {
        path: 'casosuso',
        component: ListadoCasoUsoComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Listado de casos de uso',
          breadcrumb: [
            {
              label: 'Listado de casos de uso',
              url: ''
            }
          ]
        },
      },
      {
        path: 'templates',
        component: TemplatesComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Listado de plantillas',
          breadcrumb: [
            {
              label: 'Listado de plantillas',
              url: ''
            }
          ]
        },
      },
      {
        path: 'roles',
        component: ListadoRolComponent,
        canActivate: [AuthGuardService, RolClientGuard],
        data: {
          title: 'Listado de roles',
          breadcrumb: [
            {
              label: 'Listado de roles',
              url: ''
            }
          ]
        },
      },
      // {
      //   path: "**", redirectTo: "/dashboard", pathMatch: 'full'
      // },
       {
         path: "**", redirectTo: "/dashboard", pathMatch: 'full'
       }
    ]
  }
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]

})
export class AppRoutingModule { }
