<div *appRole="['Comprobantes.Ver']">

    <button mat-icon-button tabindex="1" class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
    <div class="row">
        <div class="col-lg-10">
            <h1 mat-dialog-title>
                Detalle comprobante</h1>
        </div>
    </div>
    <div mat-dialog-content>
        <mat-card>
            <div class="row">
                <div class="col">
                    <p><strong>Nro. Comprobante: &nbsp;</strong>{{comprobanteDetailDto.cabeceraComprobante.numeroComprobante}}</p>
                </div>
                <div class="col">
                    <p><strong>Fecha Comprobante: &nbsp;</strong> {{comprobanteDetailDto.cabeceraComprobante.fechaEmision | date:("dd/MM/yyyy")}}</p>
                </div>
                <div class="col">
                    <p><strong>Tipo Comprobante: &nbsp;</strong>{{!isFalsy(comprobanteDetailDto.cabeceraComprobante.tipoComprobante) ? comprobanteDetailDto.cabeceraComprobante.tipoComprobante.descripcion : "" | titlecase}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><strong>Contrato: &nbsp;</strong> {{!isFalsy(comprobanteDetailDto.cabeceraComprobante.contrato) ? comprobanteDetailDto.cabeceraComprobante.contrato.numeroContrato : ""}}</p>
                </div>
                <div class="col">
                    <p><strong>Estado: &nbsp;</strong> {{!isFalsy(comprobanteDetailDto.cabeceraComprobante.estadoComprobante) ? comprobanteDetailDto.cabeceraComprobante.estadoComprobante.descripcion : "" | titlecase}}</p>
                </div>
                <div class="col">
                    <p><strong>Importe Total: &nbsp;</strong> {{totalImporteHaber - totalImporteDebe | currency}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><strong>Propiedad: &nbsp; </strong>{{!isFalsy(comprobanteDetailDto.cabeceraComprobante.propiedad) ? comprobanteDetailDto.cabeceraComprobante.propiedad.ubicacion : "" | titlecase}}</p>
                </div>
                <div class="col">
                    <p><strong>Propietario: &nbsp;</strong> {{!isFalsy(comprobanteDetailDto.cabeceraComprobante.propietario) ? comprobanteDetailDto.cabeceraComprobante.propietario.persona.descripcion : "" | titlecase}}</p>
                </div>
                <div class="col">
                    <p><strong>Inquilino: &nbsp;</strong> {{!isFalsy(comprobanteDetailDto.cabeceraComprobante.inquilino) ? comprobanteDetailDto.cabeceraComprobante.inquilino.persona.descripcion : "" | titlecase}}</p>
                </div>
            </div>


        </mat-card>
        <mat-tab-group>
            <mat-tab label="Detalle comprobantes">
                <table mat-table [dataSource]="dataSource" [hidden]=!data table matSort matSortStart="asc" class="mat-elevation-z8">

                    <!-- Concepto Column -->
                    <ng-container matColumnDef="concepto">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Concepto </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.concepto | titlecase}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> <strong>Subtotal</strong> </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> <strong>Total</strong> </mat-cell>
                    </ng-container>

                    <!-- Periodo Column -->
                    <ng-container matColumnDef="periodo">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Periodo </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.periodo}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                    </ng-container>

                    <!-- Fecha Pago Column -->
                    <ng-container matColumnDef="fechaPago">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Fecha Pago </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.fechaPago | date:'dd/MM/yyyy'}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                    </ng-container>

                    <!-- Tipo Movimiento Column -->
                    <ng-container matColumnDef="tipoMovimiento">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Tipo Concepto </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.tipoMovimiento | titlecase}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                    </ng-container>

                    <!-- Nro. Cuenta Column -->
                    <ng-container matColumnDef="numeroCuenta">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Nro. Cuenta </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.numeroCuenta}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                    </ng-container>

                    <!-- Descripcion Column -->
                    <ng-container matColumnDef="descripcion">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Descripción </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.descripcion}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                    </ng-container>

                    <!-- Nro. ImporteDebe -->
                    <ng-container matColumnDef="importeDebe">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Importe Debe </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.importeDebe | currency}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef><strong> {{totalImporteDebe | currency}}</strong> </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                    </ng-container>

                    <!-- Nro. Importe Haber -->
                    <ng-container matColumnDef="importeHaber">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Importe Haber</mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.importeHaber | currency}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef><strong> {{totalImporteHaber | currency}}</strong> </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> {{totalImporteHaber - totalImporteDebe | currency}}</mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    <mat-row mat-footer-row *matFooterRowDef="displayedColumns"></mat-row>
                </table>
                <div class="importeTotal"><strong>Total:</strong> {{totalImporteHaber - totalImporteDebe | currency}}</div>

                <br>
                <div class="no-data-table">
                    <span class="with-icon" *ngIf="comprobanteDetailDto.detalleComprobante.length  <= 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
                </div>
            </mat-tab>
            <mat-tab label="Detalle pagos">
                <table mat-table [dataSource]="comprobanteDetailDto.cabeceraComprobante.pagos" table matSort matSortStart="asc" class="mat-elevation-z8">

                    <!-- Concepto Column -->
                    <ng-container matColumnDef="fechaPago">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Fecha Pago </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.fechaPago | date:('dd/MM/yyyy')}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> <strong>Total</strong> </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="fechaAcreditacion">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Fecha Acreditacion </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.fechaAcreditacion | date:('dd/MM/yyyy')}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="importe">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Importe </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.importe | currency}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> <strong>{{getTotalPagos() | currency }}</strong>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="formaPago">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Forma Pago </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.formaPago != null ? element.formaPago.descripcion : "" | titlecase}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="descripcion">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> Descripción </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let element"> {{element.descripcion | titlecase}} </mat-cell>
                        <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsPagos"></mat-header-row>
                    <mat-row matRipple *matRowDef="let element; columns: displayedColumnsPagos;"></mat-row>
                    <mat-footer-row *matFooterRowDef="displayedColumnsPagos"></mat-footer-row>
                </table>
                <br>
                <div class="no-data-table">
                    <span class="with-icon" *ngIf="comprobanteDetailDto.cabeceraComprobante.pagos.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
