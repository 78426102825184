import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-nuevo-servicio-propiedad',
  templateUrl: './btn-nuevo-servicio-propiedad.component.html',
  styleUrls: ['./btn-nuevo-servicio-propiedad.component.css']
})
export class BtnNuevoServicioPropiedadComponent implements OnInit {
@Input() idPropiedad!:number
  constructor() { }

  ngOnInit(): void {
  }

}
