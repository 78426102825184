import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './DateRangePicker.component.html',
  styleUrls: ['./DateRangePicker.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DateRangePickerComponent {
  form!: FormGroup;
  activeField: 'desde' | 'hasta' | null = null; // Agregar esta variable

  @Output() fechasSeleccionadas = new EventEmitter<any>();
  @Output() borrarFechas = new EventEmitter<void>();
  @Input() reset: boolean = false; // Propiedad para recibir la señal de reinicio

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      FechaDesde: [null],
      FechaHasta: [null],
    });
  }

  ngOnChanges() {
    // Limpiar el formulario si reset es true
    if (this.reset) {
      this.form.reset();
    }
  }

  get f() {
    return this.form.controls;
  }

  onSelectFechas() {
    if (this.form.valid) {
      this.fechasSeleccionadas.emit({
        desde: this.form.value.FechaDesde,
        hasta: this.form.value.FechaHasta
      });
    }
  }

  setPeriodoDesdeMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.form.get('FechaDesde')?.value ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.form.get('FechaDesde')?.value.setValue(ctrlValue);
    this.emitSelectedDates()
    datepicker.close();
  }

  setPeriodoHastaMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.form.get('FechaHasta')?.value ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.form.get('FechaHasta')?.value.setValue(ctrlValue);
    this.emitSelectedDates()
    datepicker.close();
  }

  // Manejar la selección del campo de fecha
  setActiveField(field: 'desde' | 'hasta') {
    this.activeField = field;
  }


  // Método para emitir las fechas seleccionadas
  private emitSelectedDates() {
    const startDate = this.form.get('FechaDesde')?.value as Date;
    const endDate = this.form.get('FechaHasta')?.value  as Date;
    // Verificar que ambas fechas no sean nulas antes de emitir
    if (startDate && endDate) {
      this.fechasSeleccionadas.emit({startDate,endDate });
    }
  }


// Manejar la selección de año
chosenYearHandler(event: Moment) {
  if (this.activeField === 'desde') {
    const ctrlValue = this.form.get('FechaDesde')?.value || moment();
    ctrlValue.year(event.year());
    this.form.get('FechaDesde')?.setValue(ctrlValue);
  } else if (this.activeField === 'hasta') {
    const ctrlValue = this.form.get('FechaHasta')?.value || moment();
    ctrlValue.year(event.year());
    this.form.get('FechaHasta')?.setValue(ctrlValue);
  }
  this.emitSelectedDates();
}

// Manejar la selección de mes
chosenMonthHandler(event: Moment, datepicker: any) {
  if (this.activeField === 'desde') {
    const ctrlValue = this.form.get('FechaDesde')?.value || moment();
    ctrlValue.month(event.month());
    this.form.get('FechaDesde')?.setValue(ctrlValue);
  } else if (this.activeField === 'hasta') {
    const ctrlValue = this.form.get('FechaHasta')?.value || moment();
    ctrlValue.month(event.month());
    this.form.get('FechaHasta')?.setValue(ctrlValue);
  }
  datepicker.close();
  this.emitSelectedDates();
}

// Resetear el formulario
resetForm() {
  this.form.reset();
  this.borrarFechas.emit();
}
}
