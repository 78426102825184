import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { SendEmailRequest } from '@models/email/sendEmailRequest.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-descargar-comprobantes',
  templateUrl: './descargar-comprobantes.component.html',
  styleUrls: ['./descargar-comprobantes.component.css']
})
export class DescargarComprobantesComponent implements OnInit {
  local_data: any;

  constructor(
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<DescargarComprobantesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
  }

  ngOnInit(): void {
  }

  descargarRecibo() {
    this.downloadFile(this.local_data)
  }

  enviarEmail(){
    let sendEmailRequest: SendEmailRequest
  }

  downloadFile(archivoDto: ArchivoDto) {
    const link = document.createElement("a");
    link.href = "data:application/pdf;base64," + archivoDto.content
    link.download = archivoDto.nombre + ".pdf"
    link.click();
    this.dialogRef.close({ event: AccionesDialog.Aceptar });
  }
}
