import { AutoMap } from "@automapper/classes";
import { IBaseDto } from "./iBaseDto.model";

export class BaseDto implements IBaseDto{
  @AutoMap() public id:number = 0;
  @AutoMap() public descripcion:string = ""

    static fromBaseDto(baseDto: BaseDto) {
      const dto = new BaseDto();
      dto.id = baseDto.id;
      dto.descripcion = baseDto.descripcion;
      return dto;
    }
}



