import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BtnNuevoTipoAjusteContratoComponent } from "@views/tipos-ajuste-contrato/btn-nuevo-tipo-ajuste-contrato/btn-nuevo-tipo-ajuste-contrato.component";
import { SharedModule } from "./shared.module";
import { DialogTipoAjusteContratoComponent } from "@views/tipos-ajuste-contrato/dialog-tipo-ajuste-contrato/dialog-tipo-ajuste-contrato.component";
import { TiposAjusteContratoComponent } from "@views/tipos-ajuste-contrato/tipos-ajuste-contrato/tipos-ajuste-contrato.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
      DialogTipoAjusteContratoComponent,
      TiposAjusteContratoComponent,
      BtnNuevoTipoAjusteContratoComponent
    ],
    exports: [
        DialogTipoAjusteContratoComponent,
        TiposAjusteContratoComponent,
        BtnNuevoTipoAjusteContratoComponent
    ],
    providers: [
    ],
})

export class TipoAjusteContratoModule { }
