import { TipoMovimientoDto } from "@models/tipos-movimiento/tipoMovimientoDto.model"
import { isFalsy } from "utility-types"
import { BaseDto } from "../base/baseDto.model"
import { BonificacionDto } from "../bonificaciones/bonificacionDto.model"
import { PeriodoContratoDto } from "../periodos-contrato/periodoContratoDto.model"
import { InquilinoDto } from "../inquilino/inquilinoDto.model"

export class MovimientoDetailDto extends BaseDto {
  public tipoMovimiento!: TipoMovimientoDto
  public contrato!: BaseDto
  public inquilino!: InquilinoDto
  public propiedad!: BaseDto
  public propietario!: BaseDto
  public bonificacion!: BonificacionDto
  public periodo!: number
  public periodoMes!: number
  public periodoAnio!: number
  public fechaEmision!: string
  public fechaPago!: string
  public fechaVencimiento!: string
  public periodoContrato !: PeriodoContratoDto
  public seleccionPago: boolean = false
  public anulado: boolean = false
  public fechaModificacion!: string
  public fechaCreacion!: string


  private _importe: number = 0;
  public get importe(): number {
    return this._importe;
  }
  public set importe(v: number) {
    if (!isFalsy(this.tipoMovimiento.resta))
      this._importe = v * -1;
    else
      this._importe = v;
  }

}
