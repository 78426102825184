import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-create-movimiento',
  templateUrl: './dialog-create-movimiento.component.html',
  styleUrls: ['./dialog-create-movimiento.component.css']
})
export class DialogCreateMovimientoComponent implements OnInit {
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
  }

}
