import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[ngxLoading]'
})
export class LoadingBtnDirective implements OnInit, OnChanges {

  @Input('textLoading') loadingText!: string;
  @Input('textInitial') initialText!: string;
  @Input('loadingFlag') condition: boolean | undefined = undefined

  constructor(private elem: ElementRef) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any): void {
    if (changes.condition && changes.condition.currentValue) {
      this.condition = changes.condition.currentValue
    }
    this.elem.nativeElement.innerText = (this.condition) ? this.loadingText : this.initialText;
    if (this.condition !== undefined) {
      this.elem.nativeElement.disabled = this.condition
    }

  }
}
