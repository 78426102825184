import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermisoDto } from '@models/seguridad/permisos/permisoDto.model';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { LoginService } from '@services/login/login.service';

@Directive({
  selector: '[appRole]'
})

export class AppRoleDirective {

  private currentUser!: UsuarioDto
  private permissions!: string[];

  constructor(
    private loginService: LoginService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {

  }
  ngOnInit(): void {
    this.currentUser = this.loginService.getCurrentUser()
    this.updateView()
  }

  private updateView(): void {
    //Limpia toda la pantalla y oculta
    this.viewContainerRef.clear();
    if (this.checkPermission()) {
      this.viewContainerRef.createEmbeddedView(this.templateRef)
    }
  }

  checkPermission(): boolean {
    let hasPermission = false
    if (this.currentUser && this.currentUser.rol) {
      for (const checkPermission of this.permissions) {
        const permissionFound = this.currentUser.rol.permisos.find((p: PermisoDto) => {
          return (p.token.toUpperCase()) == checkPermission.toUpperCase()
        })

        if (permissionFound){
          hasPermission = true
          break;
        }
      }
    }
    return hasPermission
  }

  @Input()
  set appRole(val: Array<string>) {
    //Renderiza el contenido de la porcion de codigo o componente que esta contenida dentro del appRole o implementada por esta directiva
    this.viewContainerRef.createEmbeddedView(this.templateRef);
    this.permissions = val;
    this.updateView();
  }




}

