<div class="list-group" *appRole="['Movimientos.Ver']">
    <button type="button" [routerLinkActive]="['active']" routerLink='/movimiento/{{idMovimiento}}' class="list-group-item list-group-item-action">
<div class="with-icon">
  <mat-icon class="padding-icon">person_pin</mat-icon><span > Movimiento</span>
</div>
  </button>

    <button type="button" [routerLinkActive]="['active']" routerLink='/' class="list-group-item list-group-item-action">
    <div class="with-icon">
      <mat-icon class="padding-icon">folder</mat-icon> <span >Documentos</span>
    </div>
</button>
</div>
