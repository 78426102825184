<br>
<mat-card *appRole="['Personas.Listado']">
  <ngx-spinner [zIndex]="100" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom"
    [fullScreen]="false">
  </ngx-spinner>

  <form [formGroup]="form">
    <div class="row">
      <div class="col">
        <mat-form-field class="example-full-width">
          <mat-label>Nombre completo</mat-label>
          <input matInput cdkFocusInitial name="NombreCompleto" style="text-transform: capitalize;" maxlength="50"
            type="text" formControlName="NombreCompleto" />
        </mat-form-field>
      </div>
      <!-- <div class="col-lg-3">
                  <mat-form-field>
                      <ngx-spinner name="spTipoDocumento" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                      <mat-label>Tipo Documento</mat-label>
                      <mat-select #SelectTipoDocuemtno placeholder="Seleccione opcion" formControlName="TiposDocumento">
                          <mat-option [value]="tipoDocumento.id" *ngFor="let tipoDocumento of tiposDocumentos ;let i=index;trackBy:trackByItems">
                              {{tipoDocumento.descripcion}}</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div> -->
      <div class="col">
        <mat-form-field class="example-full-width">
          <mat-label>Nro. Documento</mat-label>
          <input matInput name="NroDocumento" style="text-transform: capitalize;" maxlength="50" type="text"
            formControlName="NroDocumento" />

        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="example-full-width">
          <mat-label>Correo</mat-label>
          <input matInput name="Correo" style="text-transform: capitalize;" maxlength="50" type="text"
            formControlName="Correo" />

        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="example-full-width">
          <mat-label>Tipo de Persona</mat-label>
          <mat-select formControlName="TiposPersona">
            <mat-option value="Todos">Todos </mat-option>
            <mat-option value="Propietarios">Propietarios </mat-option>
            <mat-option value="Inquilinos">Inquilinos </mat-option>
            <mat-option value="Garantias">Garantias </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="example-full-width">
          <mat-label>Estado</mat-label>
          <mat-select formControlName="Estado">
            <mat-option value="">Todos </mat-option>
            <mat-option value=false>Activos </mat-option>
            <mat-option value=true>Baja </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Rango de fecha de alta</mat-label>
          <mat-date-range-input [rangePicker]="campaignOnePicker">
            <input matStartDate placeholder="Fecha inicio" formControlName="FechaAltaDesde">
            <input matEndDate placeholder="Fecha fin" formControlName="FechaAltaHasta">
          </mat-date-range-input>
          <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="checkedFiltroAvanzado">


      <!-- <div class="col" *ngIf="checkedFiltroAvanzado">
                  <app-buscar-localidad (eventoLocalidad)="seleccionaLocalidad($event)"></app-buscar-localidad>
              </div> -->


    </div>
    <div class="row">
      <div class="col-lg-3">
        <button mat-stroked-button color="primary" matTooltip="Aplicar filtros" (click)="filtrar()">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </button>&nbsp; &nbsp;
        <button mat-stroked-button color="primary" matTooltip="Borrar filtros" (click)="clearFilter()">
          <mat-icon>clear</mat-icon>
          Limpiar
        </button>
      </div>
      <!-- <div class="col-lg-3">
                  <mat-slide-toggle class="example-margin" (change)="filtrosAvanzados($event)" color="primary">
                      <mat-icon>tune</mat-icon>Filtros Avanzados </mat-slide-toggle>
              </div> -->
    </div>
  </form>
  <div [hidden]=!data>
    <div class="row">
      <div class="col-lg-2">
        <mat-form-field class="example-full-width">
          <mat-label>Buscar</mat-label>
          <input type="search" matInput (keyup)="applyFilter($event)">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" [hidden]=!data matSort matSortStart="asc" class="mat-elevation-z8">

      <!-- Name Column -->
      <ng-container matColumnDef="nombreCompleto">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Completo </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let persona"> {{persona.nombreCompleto | titlecase}}
        </mat-cell>
      </ng-container>

      <!-- Tipo Persona Column -->
      <ng-container matColumnDef="tipoPersona">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Tipo Persona </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let persona">
          <mat-icon aria-hidden="false" aria-label="Example home icon" *ngIf="persona.esPropietario"
            matTooltip="Propietario">apartment</mat-icon>
          <mat-icon aria-hidden="false" aria-label="Example home icon" *ngIf="persona.esInquilino"
            matTooltip="Inquilino">
            vpn_key</mat-icon>
          <mat-icon aria-hidden="false" aria-label="Example home icon" *ngIf="persona.esGarante" matTooltip="Garantia">
            person</mat-icon>
        </mat-cell>
      </ng-container>

      <!-- Documento-->
      <ng-container matColumnDef="documento">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Documento </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let persona"> {{persona.tipoDocumento}} - {{persona.nroDocumento}}</mat-cell>
      </ng-container>

      <!-- Correo  Column -->
      <ng-container matColumnDef="correo">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Correo </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let persona">
          {{persona.correo}}
          <div *appRole="['Personas.Acciones']">
            <div *ngIf="!isFalsy(persona.correo)">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button disabled mat-menu-item>
                  <mat-icon>send</mat-icon>
                  <span>Enviar correo</span>
                </button>
              </mat-menu>
            </div>
          </div>

        </mat-cell>
      </ng-container>

      <!-- Estado  Column -->
      <ng-container matColumnDef="estado">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Estado </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let persona">
          <div *ngIf="persona.estado == false">
            <span class="badge bg-success text-light">Activo</span>
          </div>
          <div *ngIf="persona.estado == true">
            <span class="badge bg-danger text-light">Baja</span>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Accion Column -->
      <ng-container matColumnDef="accion">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let persona" class="action-link">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                    <mat-icon>visibility</mat-icon>
                    <span>Ver</span>
                  </button> -->
            <div *appRole="['Personas.Modificar']">
              <button [disabled]="persona.estado" mat-menu-item (click)="editarPersona(persona)">
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
            </div>
            <div *appRole="['Personas.Eliminar']">
              <button [disabled]="persona.estado" mat-menu-item (click)="openDialog(persona)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
            </div>
            <div *appRole="['Personas.Eliminar']">
              <button [disabled]="!persona.estado" mat-menu-item (click)="reactivar(persona)">
                <mat-icon>toggle_on</mat-icon>
                <span>Reactivar</span>
              </button>
            </div>

          </mat-menu>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


    </table>
    <div class="no-data-table">
      <span class="with-icon" *ngIf="dataSource.data.length == 0">
        <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
      </span>
    </div>
    <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20,50,100,200]">
    </mat-paginator>
  </div>


</mat-card>
