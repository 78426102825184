
<div class="position-relative">
  <ngx-spinner name="spCargarEstado" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom"
    [fullScreen]="false"></ngx-spinner>
  <div class="alert alert-danger" role="alert"
    *ngIf="isFalsy(renterDeliveredNecessaryGuaranteesResponse)  ? false : renterDeliveredNecessaryGuaranteesResponse.allDeviliveredGarantees == false ? true : false">
    <div *ngIf="!isFalsy(renterDeliveredNecessaryGuaranteesResponse.pendingDeliverGarantees)">
      <strong>Garantias pendientes de validar: </strong>
      <span
        *ngFor="let item of renterDeliveredNecessaryGuaranteesResponse.pendingDeliverGarantees;let i=index;trackBy:trackByItems">{{item}}
        - </span>
    </div>
    <div *ngIf="isFalsy(renterDeliveredNecessaryGuaranteesResponse.pendingDeliverGarantees)">
      <strong>Inquilino no asignado a Grupos de Garantias </strong>
    </div>

  </div>
  <div class="alert alert-success" role="alert"
    *ngIf="isFalsy(renterDeliveredNecessaryGuaranteesResponse)  ? false : renterDeliveredNecessaryGuaranteesResponse.allDeviliveredGarantees == false ? false : true">
    <strong>Garantias enviadas y validadas 👍</strong>
  </div>
</div>
