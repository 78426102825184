<div *appRole="['TiposGarantias.Ver']">

  <button *appRole="['TiposGarantias.Crear']" mat-raised-button disabled (click)="openDialog('Agregar',{})" color="primary">
    <mat-icon>add</mat-icon>
    Nuevo tipo garantia
  </button>
  <br><br>
  <mat-card *appRole="['TiposGarantias.Ver']">
      <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
      </ngx-spinner>
      <mat-form-field class="example-full-width">
          <mat-label>Buscar</mat-label>
          <input matInput type="search" (keyup)="applyFilter($event)">
          <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <br>
      <table mat-table [dataSource]="dataSource" [hidden]=!data table matSort matSortStart="asc" class="mat-elevation-z8">

          <!-- Descripcion Column -->
          <ng-container matColumnDef="descripcion">
              <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element"> {{element.descripcion}} </mat-cell>
          </ng-container>

          <!-- Accion Column -->
          <ng-container matColumnDef="accion">
              <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element" class="action-link">

                  <button mat-icon-button disabled [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                    <mat-menu #menu="matMenu">
                        <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                        <mat-icon>visibility</mat-icon>
                        <span>Ver</span>
                      </button> -->
                        <button  *appRole="['TiposGarantias.Modificar']" mat-menu-item (click)="openDialog('Modificar',element)">
                        <mat-icon>edit</mat-icon>
                        <span>Editar</span>
                      </button>
                        <button  *appRole="['TiposGarantias.Eliminar']" mat-menu-item (click)="openDialog('Eliminar',element)">
                        <mat-icon>delete</mat-icon>
                        <span>Eliminar</span>
                      </button>
                    </mat-menu>
              </mat-cell>
          </ng-container>



          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </table>
      <div class="no-data-table">
          <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
      </div>
      <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="10" [pageSizeOptions]="[5, 10, 20,50,100,200]">
      </mat-paginator>
  </mat-card>
</div>
