import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ListadoDetalleServicioPropiedadComponent } from '@views/detalle-servicios-propiedad/listado-detalle-servicio-propiedad/listado-detalle-servicio-propiedad.component';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, EstadosDetalleServicioPropiedad } from '@models/base/identificadores.model';
import { DetalleServicioPropiedadDetailDto } from '@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadDetailDto.model';
import { ServicioDto } from '@models/servicios/servicio/servicioDto.model';
import { DetalleServicioPropiedadService } from '@services/servicios/detalle-servicios-propiedad/detalle-servicio-propiedad.service';
import { EstadoDetalleServicioPropiedadService } from '@services/servicios/estadoDetalleServicioPropiedad/estado-detalle-servicio-propiedad.service';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogCreateDetalleServicioComponent } from '../dialog-create-detalle-servicio/dialog-create-detalle-servicio.component';
import { DialogEditarDetalleServicioComponent } from '../dialog-editar-detalle-servicio/dialog-editar-detalle-servicio.component';
import { isFalsy } from 'utility-types';


export class PropiedadServicio {
  public idPropiedad: number = 0
  public idDetalleServicio: number = 0
  public idServicio!: number
}

export class DetalleServicioLista {
  public id: number = 0
  public servicioPropiedad: string = ""
  public quienPaga: string = ""
  public periodo: string = ""
  public importeVencimiento: number = 0
  public importeVencimiento1: number = 0
  public importeVencimiento2: number = 0
  public importeVencimiento3: number = 0
  public importeVencimiento4: number = 0
  public detalleImportes: string = ""
  public fechaVencimiento1: string | null = null
  public fechaVencimiento2: string | null = null
  public fechaVencimiento3: string | null = null
  public fechaVencimiento4: string | null = null
  public estadoDescripcion: string | null = null
  public estadoId: number = 0
  public periodoMes: number = 0
  public periodoAnio: number = 0

}

@Component({
  selector: 'app-listado-detalle-servicio-por-propiedad',
  templateUrl: './listado-detalle-servicio-por-propiedad.component.html',
  styleUrls: ['./listado-detalle-servicio-por-propiedad.component.css'],
  providers: [
    [DatePipe],
  ],
})

export class ListadoDetalleServicioPorPropiedadComponent implements OnInit {
  data = false
  displayedColumns: string[] = [];
  public lstDetalleServicioPropiedadDetailDto: DetalleServicioPropiedadDetailDto[] = [];
  dataSource = new MatTableDataSource<DetalleServicioLista>();
  errorMessage: string = "";
  idPropiedad!: number;
  idDetalleServicioPropiedad: any;
  filterValues: any = {};
  filterSelectObjEstados: { name: string; columnProp: string; options: any[]; }[];
  formBuilder: FormBuilder = new FormBuilder;
  formDetalle: any;
  @Input() update: boolean = false
  estadosDetalleServicioPropiedad!: typeof EstadosDetalleServicioPropiedad;
  lstEstadoDetalleServicioPropiedad!: BaseDto[];
  lstServicio!: ServicioDto[];
  @ViewChild(ListadoDetalleServicioPropiedadComponent) listadoDetalleServicioPropiedadComponent!: ListadoDetalleServicioPropiedadComponent;

  constructor(
    private detalleServicioPropiedadService: DetalleServicioPropiedadService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private aRoute: ActivatedRoute,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private serviciosService: ServiciosService,
    private estadoDetalleServicioPropiedadService: EstadoDetalleServicioPropiedadService,
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["id", "servicioPropiedad", "periodo", "estadoDescripcion", "accion"] :
        ["id", "servicioPropiedad", "periodo", "monto", "estadoDescripcion", "accion"];
    });


    this.filterSelectObjEstados = [
      {
        name: 'Servicio',
        columnProp: 'servicioPropiedad',
        options: []
      },
      {
        name: 'Estado',
        columnProp: 'estadoDescripcion',
        options: []
      }
    ]
  }

  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idPropiedad = Number(routeParams.get('id'));
    this.estadosDetalleServicioPropiedad = EstadosDetalleServicioPropiedad
    this.dataSource.filterPredicate = this.createFilter();
  }

  getDetalleServiciosPropiedadByIdPropiedad(idPropiedad: number) {
    this.spinner.show("spListadoDetalle");
    this.detalleServicioPropiedadService.getAllByIdPropiedad(idPropiedad)
      .subscribe(
        data => {
          this.spinner.hide("spListadoDetalle");
          this.lstDetalleServicioPropiedadDetailDto = data
          this.lstDetalleServicioPropiedadDetailDto.sort((a, b) => a.periodoMes.toString().padStart(9, "0").localeCompare(b.periodoMes.toString().padStart(9, "0")));

          let lstDetalleServicioLista: DetalleServicioLista[] = [];
          this.lstDetalleServicioPropiedadDetailDto.forEach(element => {
            let detalleServicioLista = new DetalleServicioLista
            detalleServicioLista.id = element.id
            detalleServicioLista.servicioPropiedad = element.servicioPropiedad.servicio.descripcion
            detalleServicioLista.quienPaga = element.quienPaga.descripcion
            detalleServicioLista.periodo = element.periodo
            detalleServicioLista.importeVencimiento1 = element.importeVencimiento1
            detalleServicioLista.importeVencimiento2 = element.importeVencimiento2
            detalleServicioLista.importeVencimiento3 = element.importeVencimiento3
            detalleServicioLista.importeVencimiento3 = element.importeVencimiento4
            detalleServicioLista.fechaVencimiento1 = isFalsy(element.fechaVencimiento1) ? "" : this.datepipe.transform(new Date, 'yyyy-MM-dd');
            detalleServicioLista.fechaVencimiento2 = isFalsy(element.fechaVencimiento2) ? "" : this.datepipe.transform(new Date, 'yyyy-MM-dd');
            detalleServicioLista.fechaVencimiento3 = isFalsy(element.fechaVencimiento3) ? "" : this.datepipe.transform(new Date, 'yyyy-MM-dd');
            detalleServicioLista.fechaVencimiento4 = isFalsy(element.fechaVencimiento4) ? "" : this.datepipe.transform(new Date, 'yyyy-MM-dd');
            detalleServicioLista.estadoDescripcion = element.estado.descripcion
            detalleServicioLista.estadoId = element.estado.id
            detalleServicioLista.periodoMes = element.periodoMes
            detalleServicioLista.periodoAnio = element.periodoAnio

            element.fechaVencimiento1 = isFalsy(element.fechaVencimiento1) ? "" : new Date(element.fechaVencimiento1).toLocaleDateString('es-AR')
            element.fechaVencimiento2 = isFalsy(element.fechaVencimiento2) ? "" : new Date(element.fechaVencimiento2).toLocaleDateString('es-AR')
            element.fechaVencimiento3 = isFalsy(element.fechaVencimiento3) ? "" : new Date(element.fechaVencimiento3).toLocaleDateString('es-AR')
            element.fechaVencimiento4 = isFalsy(element.fechaVencimiento4) ? "" : new Date(element.fechaVencimiento4).toLocaleDateString('es-AR')

            if (!isFalsy(element.fechaVencimiento1) && !isFalsy(element.importeVencimiento1))
              detalleServicioLista.detalleImportes = element.fechaVencimiento1.concat(" - $").concat(element.importeVencimiento1.toString()).concat("\n")

            if (!isFalsy(element.fechaVencimiento2) && !isFalsy(element.fechaVencimiento2) && !isFalsy(element.importeVencimiento2) && !isFalsy(element.importeVencimiento2))
              detalleServicioLista.detalleImportes += element.fechaVencimiento2.concat(" - $").concat(element.importeVencimiento2.toString()).concat("\n")

            if (!isFalsy(element.fechaVencimiento3) && !isFalsy(element.fechaVencimiento3) && isFalsy(element.importeVencimiento3) && isFalsy(element.importeVencimiento3))
              detalleServicioLista.detalleImportes += element.fechaVencimiento3.concat(" - $").concat(element.importeVencimiento3.toString()).concat("\n")

            if (!isFalsy(element.fechaVencimiento4) && !isFalsy(element.fechaVencimiento4) && !isFalsy(element.importeVencimiento4) && !isFalsy(element.importeVencimiento4))
              detalleServicioLista.detalleImportes += element.fechaVencimiento4.concat(" - $").concat(element.importeVencimiento4.toString())

            lstDetalleServicioLista.push(detalleServicioLista)

          });
          this.dataSource.data = lstDetalleServicioLista
          this.data = this.dataSource.data.length >= 1 ? true : false


          this.filterSelectObjEstados.filter((o) => {
            o.options = this.getFilterObject(lstDetalleServicioLista, o.columnProp);
          });
        },
        error => {
          this.spinner.hide("spListadoDetalle");
          this.snackBar.showError(error, "Error");
        }

      )
  }


  // Get Uniqu values from columns to build filter
  getFilterObject(fullObj: any, key: any) {
    const uniqChk: any = [];
    fullObj.filter((obj: any) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  openDialogEditarDetalleServicio(idDetalleServicioPropiedad: number) {
    let propiedadServicio = new PropiedadServicio
    propiedadServicio.idDetalleServicio = idDetalleServicioPropiedad
    propiedadServicio.idPropiedad = this.idPropiedad

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = propiedadServicio

    const dialogRef = this.dialog.open(DialogEditarDetalleServicioComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.data == true && result.event != AccionesDialog.Cancelar)
        this.listadoDetalleServicioPropiedadComponent.buscarServicios()

    })
  }

  openDialogCreateDetalleServicio() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = this.idPropiedad

    const dialogRef = this.dialog.open(DialogCreateDetalleServicioComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar) {
        if (result.data == true) {
          this.listadoDetalleServicioPropiedadComponent.buscarServicios()
        }
      }
    })
  }

  // Custom filter method fot Angular Material Datatable
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().split(' ').forEach((word: any) => {
              if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                found = true
              }
            });
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }


  // Called on Filter change
  filterChange(filter: any, event: any) {
    this.lstDetalleServicioPropiedadDetailDto.sort((a, b) => a.periodoMes.toString().padStart(9, "0").localeCompare(b.periodoMes.toString().padStart(9, "0")));
    this.dataSource.filter = event.value.servicio.descripcion.toString().trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // Called on Filter change
  filterChangeEstados(filter: any, event: any) {
    //let filterValues = {}
    this.filterValues[filter.columnProp] = event.value.trim().toLowerCase()
    this.dataSource.filter = JSON.stringify(this.filterValues)
  }

  // Reset table filters
  resetFilters() {
    this.filterValues = {}
    // this.filterSelectObjEstados.forEach((value, key) => {
    //   value.name = "";
    // })
    this.dataSource.filter = "";
  }




}
