

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "./shared.module";
import { ListadoAuditoriaLogComponent } from "@views/auditoria-log/listado-auditoria-log/listado-auditoria-log.component";
import { DialogViewAuditorialogComponent } from "@views/auditoria-log/dialog-view-auditorialog/dialog-view-auditorialog.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
      ListadoAuditoriaLogComponent,
      DialogViewAuditorialogComponent
    ],
    exports: [
      ListadoAuditoriaLogComponent,
      DialogViewAuditorialogComponent
    ]
})

export class AuditoriaLogModule { }
