import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { CreateSettlementResponse } from '@models/propietarios/createSettlementResponse.model';
import { SettlementFileDto } from '@models/propietarios/settlementFileDto.model';
@Component({
  selector: 'app-liquidaciones-generadas',
  templateUrl: './liquidaciones-generadas.component.html',
  styleUrls: ['./liquidaciones-generadas.component.css']
})
export class LiquidacionesGeneradasComponent implements OnInit {
  @Input() createSettlementResponse: CreateSettlementResponse[] = []
  data = true
  dataSource = new MatTableDataSource<CreateSettlementResponse>();
  displayedColumns: string[] = ['contrato', 'propietario', 'archivo'];


  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  applyFilter(event: any) {
    if (event && event.target && event.target instanceof HTMLInputElement) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public datos: any
  ) {

  }

  ngOnInit(): void {
    this.dataSource.data = this.createSettlementResponse
    this.data = this.dataSource.data.length >= 1 ? true : false
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  descargarRecibo(archivoLiquidacion: SettlementFileDto) {
    let file = new ArchivoDto
    file.content = archivoLiquidacion.content
    file.nombre = archivoLiquidacion.fileName
    this.downloadFile(file)
  }

  downloadFile(archivoDto: ArchivoDto) {
    const link = document.createElement("a");
    link.href = "data:application/pdf;base64," + archivoDto.content
    link.download = archivoDto.nombre + ".pdf"
    link.click();
  }

}
