import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LoginService } from '@services/login/login.service';
import { AccionesDialog, ParametrosConfiguracion, Roles } from '@models/base/identificadores.model';
import { FormControl } from '@angular/forms';
import { ProcesosService } from '@services/procesos/procesos.service';
import { HotToastService, ToastConfig } from '@ngneat/hot-toast';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { ContadorContratoDto } from '@models/dashboard/contadorContratoDto.model';
import { ParametrosSistemaService } from '@services/parametro-sistema/parametros-sistema.service';
import { NotificacionDto } from '@models/notificaciones/notificacionDto.model';
import { NotificacionesService } from '@services/notificaciones/notificaciones.service';
import { Route, Router } from '@angular/router';

export class ContadorEntidades {
  public propiedades!: number
  public propietarios!: number
  public inquilinos!: number
  public garantias!: number
  public contratos!: number
  public usuarios!: number
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public mostrarDashboard = false
  matButtonToggleGroup = new FormControl();
  contadoresEntidades!: any[];
  contadorContratoDto!: ContadorContratoDto;
  notificaciones: NotificacionDto[] = [];
  constructor(
    private loginService: LoginService,
    private procesosService: ProcesosService,
    private toastService: HotToastService,
    private spinner: NgxSpinnerService,
    private parametrosSistemaService: ParametrosSistemaService,
    private snackBar: SnackBarService,
    private notificacionesService: NotificacionesService,
    private router: Router
  ) {
    let toasConfig = new ToastConfig
    toasConfig.autoClose = false
    toasConfig.reverseOrder = true,
      toasConfig.position = 'bottom-right'
    this.toastService.defaultConfig = toasConfig

  }

  ngOnInit() {

    const roles = this.loginService.getIdUserRol()
    this.matButtonToggleGroup.setValue('general')
    switch (roles) {
      case Roles.Usuarios:
        this.mostrarDashboard = true
        break;
      case Roles.Administradores:
        this.mostrarDashboard = true
        break;
      case Roles.Clientes:
        this.mostrarDashboard = false
        this.router.navigate(['miPanel']);
        break;
      case Roles.SuperAdministradores:
        this.mostrarDashboard = true
        break;
      default:
        this.mostrarDashboard = true
        break;
    }

    if (this.loginService.getIdUserRol() != Roles.Clientes) {
      this.procesosIniciales()
      this.notificacionesParametrosConfiguracion()
      this.setParametrosSistemaLocalStorage()
    }
  }

  //Procesos Iniciales
  private procesosIniciales() {
    // Iniciar el toast de loading
    const toastId = this.toastService.loading('Por favor, aguarde. Se esta procesando los contratos finalizados.', {
      icon: '⏳',  // Icono de loading
      autoClose: false  // Mantener el loading hasta que se cierre manualmente
    });

    this.procesosService.endContratos()
      .subscribe(
        data => {
          this.toastService.success('Proceso finalizado con éxito.',{autoClose:true});
          toastId.close()
        },
        error => {
          this.toastService.error('Error al finalizar los contratos.',{autoClose:true});
          toastId.close()
        }
      );
  }


  notificacionesParametrosConfiguracion() {
    this.getValorParametro(ParametrosConfiguracion.FechaGenResumen)
    this.getValorParametro(ParametrosConfiguracion.FechaGenLiquizacion)
  }

  setParametrosSistemaLocalStorage() {
    this.parametrosSistemaService.setParametrosSistemaLocalStorage();
  }

  getValorParametro(id: number) {
    this.spinner.show()
    this.parametrosSistemaService.getById(id)
      .subscribe(
        data => {
          const fecha = new Date();
          let hoy = moment(fecha).format("D");
          let notificacionDto = new NotificacionDto
          if (data.valor == hoy) {
            switch (id) {
              case ParametrosConfiguracion.FechaGenResumen:
                notificacionDto.mensaje = "Generar resúmen para inquilinos."
                break;
              case ParametrosConfiguracion.FechaGenLiquizacion:
                notificacionDto.mensaje = "Generar liquidación para propietarios."
                break;
              default:
                break;
            }
            this.notificacionesService.sendNotificacion(notificacionDto)
          }
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      );
  }


}

