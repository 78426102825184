import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent, MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { Editor } from 'ngx-editor';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { EmailDto } from '@models/email/emailDto.model';
import { ReceiverDto } from '@models/email/receiverDto.model';
import { EmailService } from '@services/email/email.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css'],
  providers: [
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA]
      }
    }
  ]
})
export class SendEmailComponent implements OnInit {
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  action!: string;
  local_data: any;
  form: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  editorBody: Editor = new Editor();
  @Input() imputAsunto!: string
  @Input() imputBody!: string
  @Input() imputDestinatarios!: ReceiverDto[]
  @Input() archivosAdjuntos!: EmailDto[]

  destinatarios = new Set([" "]);
  get f() { return this.form.controls; }
  constructor(
    private emailService: EmailService

  ) {
    this.form = this.formBuilder.group({
      Asunto: ['', Validators.compose([Validators.required])],
      Body: [''],
      Destinatarios: [null, Validators.compose([Validators.required])]

    })
  }

  ngOnInit(): void {
    if (!isFalsy(this.imputAsunto))
      this.form.controls["Asunto"].setValue(this.imputAsunto);

    if (!isFalsy(this.imputBody))
      this.form.controls["Body"].setValue(this.imputBody);

    if (!isFalsy(this.imputDestinatarios)) {
      this.imputDestinatarios.forEach(element => {
        this.destinatarios.add(element.receiverAddress)
      });
      var destinatarios = this.destinatarios.toString();
      this.form.controls["Destinatarios"].setValue(destinatarios);
    }

  }

  ngOnDestroy(): void {
    this.editorBody.destroy();
  }

  sendEmail() { }


  addKeywordFromInput(event: MatChipInputEvent) {
    if (event.value) {
      this.destinatarios.add(event.value);
      event.chipInput!.clear();
      var destinatarios = this.destinatarios.values()
      this.form.controls["Destinatarios"].setValue(destinatarios);
    }
  }

  removeKeyword(destinatario: string) {
    this.destinatarios.delete(destinatario);
  }

  seleccionArchivo(archivo: ArchivoDto) {
    var emailDto = new EmailDto()
    if (archivo.content != "") {
      emailDto.fileContent = archivo.content
      emailDto.fileName = archivo.descripcion
      emailDto.fileContent = archivo.contentType
    }
  }

  archivosCargados(event: ArchivoDto[]) {
  }

  trackByItems(index: number, item: any): any { return item; }


}
