<div *appRole="['Movimientos.Crear']">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
    <h1 mat-dialog-title> Crear imputación a propietario</h1>
    <div mat-dialog-content cdkTrapFocus >

        <div class="position-relative">
            <ngx-spinner [zIndex]=100 name="spMovimiento" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
                type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <form [formGroup]="movimientoForm">
                <mat-card>
                    <div class="row" *ngIf="!contratoDetailDto">
                        <div class="col-lg-6">
                            <app-buscar-persona-propietaria (eventEntity)="selectPropietario($event)"
                                [showDocumento]="false">
                            </app-buscar-persona-propietaria>
                        </div>
                        <div class="col-lg-6">
                            <ngx-spinner [zIndex]=100 name="spAllContratosPropietario" bdColor="rgba(255,255,255,0.8)"
                                size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                            </ngx-spinner>
                            <mat-selection-list #contrato [multiple]="false" formControlName="Contrato">
                                <mat-list-option *ngFor="let contrato of contratosPropietario" [value]="contrato">
                                    <p matListItemLine> <mat-icon >gavel</mat-icon> {{contrato.numeroContrato}}  -   <mat-icon >roofing</mat-icon> {{contrato.propiedad.descripcion}}</p>
                                </mat-list-option>
                            </mat-selection-list>

                        </div>
                    </div>
                    <div class="row" *ngIf="contratoDetailDto">
                        <div class="col-lg-6">
                            <p><strong>Propiedad: </strong> {{(isFalsy(contratoDetailDto.propiedad.ubicacion) ? '' : contratoDetailDto.propiedad.ubicacion) +
                              (isFalsy(contratoDetailDto.propiedad.piso) ? '' : ' ' + contratoDetailDto.propiedad.piso) +
                              (isFalsy(contratoDetailDto.propiedad.departamento) ? '' : ' ' + contratoDetailDto.propiedad.departamento)}}</p>
                        </div>
                        <div class="col-lg-6">
                            <p><strong>Propietario titular: </strong> <span
                                    *ngFor="let propietario of contratoDetailDto.propietarios">
                                    {{propietario.titular == true ? propietario.persona.descripcion : ""}}
                                </span></p>
                        </div>
                    </div>
                </mat-card>


                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <ngx-spinner [zIndex]=100 name="spBusquedaTiposMovimiento" bdColor="rgba(255,255,255,0.8)"
                                size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                            <mat-label>Tipo Movimiento</mat-label>
                            <mat-select placeholder="Seleccione opcion" formControlName="TipoMovimiento" [ngClass]="{'is-invalid':f.TipoMovimiento.touched && f.TipoMovimiento.errors}">
                                <mat-option [value]="tipoMovimiento.id"
                                    *ngFor="let tipoMovimiento of lstTiposMovimiento;let i=index;trackBy:trackByItems">
                                    {{tipoMovimiento.resta == true ? tipoMovimiento.descripcion + ' (-)' :
                                    tipoMovimiento.descripcion + ' (+)'}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="f.TipoMovimiento.errors?.required && f.TipoMovimiento.touched">Este campo es
                              <strong>requerido</strong>.
                          </mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field>
                            <mat-label>Mes y Año</mat-label>
                            <input matInput [matDatepicker]="dp" formControlName="Periodo">
                            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                                (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <span matPrefix>$ &nbsp;</span>
                            <mat-label>Importe</mat-label>
                            <input type="text" mask="separator.2" thousandSeparator="," placeholder="Importe"
                                formControlName="Importe" matInput>
                            <mat-error *ngIf="f.Importe.errors?.required">Este campo es <strong>requerido</strong>.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                      <mat-checkbox  formControlName="Comisionable">Comisión</mat-checkbox>
                  </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <mat-form-field class="full-width-x100">
                            <mat-label>Descripción</mat-label>
                            <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
                            <mat-error *ngIf="f.Descripcion.errors?.maxlength">Supero el maximo de
                                caracteres.</mat-error>
                            <mat-hint align="start"><strong>Amplie información</strong> </mat-hint>
                            <mat-hint align="end">{{message.value.length}} / 2000</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

            </form>
        </div>

    </div>
    <div mat-dialog-actions>
        <button mat-raised-button color="primary" *appRole="['Movimientos.Crear']" [disabled]="!movimientoForm.valid"
            (click)="onSubmit()">Agregar</button>
        <button mat-button mat-dialog-close color="warn" (click)="closeDialog()">Cancelar</button>
    </div>
</div>
