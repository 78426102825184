<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div class="row">
    <h1 mat-dialog-title>
        <strong>Visualización de liquidación por propietario</strong>
    </h1>
</div>
<mat-divider></mat-divider>
<br />
<div mat-dialog-content>
    <div *ngIf="settlementByPropertyDto.length >= 1">
        <br>
        <div class="mat-accordeon-separate" *ngFor="let settlementsByProperty of settlementByPropertyDto">
            <mat-accordion *ngIf="settlementsByProperty.contratos.length > 0">
                <mat-expansion-panel *ngFor="let contratos of settlementsByProperty.contratos;let i=index;trackBy:trackByItems">

                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="col0">
                <mat-checkbox *ngIf="contratoDetailDto != null" color="primary"
                  [checked]="contratoDetailDto.numeroContrato== contratos.contrato.numeroContrato" [disabled]="true"
                  (click)="$event.stopPropagation()">



                </mat-checkbox>
                <mat-checkbox *ngIf="contratoDetailDto == null" color="primary" [checked]="true" [disabled]="true"
                  (click)="$event.stopPropagation()">
                </mat-checkbox>
              </span>&nbsp;&nbsp;
                            <span class="col1 headLiquidacion"><strong>Propietario:</strong>
                {{
                isFalsy(contratos.propietario)
                ? ""
                : (contratos.propietario.persona.descripcion | titlecase)
                }}</span>&nbsp;&nbsp;
                            <span class="col2 headLiquidacion"><strong>Propiedad:</strong>
                {{
                isFalsy(contratos.propiedad)
                ? ""
                : isFalsy(contratos.propiedad.ubicacion)? "" : (contratos.propiedad.ubicacion | titlecase)
                }}
                -
                {{
                isFalsy(contratos.propiedad)
                ? ""
                : isFalsy(contratos.propiedad.piso) ? "" : (" "+contratos.propiedad.piso | uppercase)
                }}
                {{
                isFalsy(contratos.propiedad)
                ? ""
                : isFalsy(contratos.propiedad.departamento) ? "" : (" "+ contratos.propiedad.departamento
                | uppercase)
                }}</span>&nbsp;&nbsp;
                            <span class="col3 headLiquidacion"><strong>Contrato:</strong>
                {{
                isFalsy(contratos.contrato)
                ? ""
                : contratos.contrato.numeroContrato
                }}</span>&nbsp;&nbsp;
                            <span class="col4 headLiquidacion"><strong>Importe a liquidar:</strong>
                {{ isFalsy(getImporteLiquidacion(contratos)) ? 0 : getImporteLiquidacion(contratos) | currency }}</span>

                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngFor="let settlement of contratos.settlements;let i=index;trackBy:trackByItems" class="container">
                        <h2>
                            <p>Detalle liquidación</p>
                        </h2>
                        <strong>
              <h3>Servicios Propiedad</h3>
            </strong>
                        <table mat-table [dataSource]="settlement.detallesServiciosPropiedad" *ngIf="settlement.detallesServiciosPropiedad.length != 0" class="mat-elevation-z8">
                            <ng-container matColumnDef="periodo">
                                <th mat-header-cell *matHeaderCellDef>Periodo</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.periodoMes }}/{{ element.periodoAnio }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef>Total</td>
                            </ng-container>
                            <ng-container matColumnDef="servicio">
                                <th mat-header-cell *matHeaderCellDef>Servicio</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ isFalsy(element.servicioPropiedad) ? "" : isFalsy(element.servicioPropiedad.servicio) ? "" : (element.servicioPropiedad.servicio.descripcion | titlecase) }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
                            <ng-container matColumnDef="importe">
                                <th mat-header-cell *matHeaderCellDef>Importe</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.importeATenerEnCuenta | currency }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalServicioPropiedad( settlement.detallesServiciosPropiedad ) | currency }}
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsDetallesServicios"></tr>
                            <tr mat-row *matRowDef="
                  let row;
                  columns: displayedColumnsDetallesServicios
                "></tr>
                            <tr mat-footer-row *matFooterRowDef="
                  displayedColumnsDetallesServicios;
                  sticky: true
                "></tr>
                        </table>

                        <div class="no-data-table">
                            <span class="with-icon" *ngIf="settlement.detallesServiciosPropiedad.length == 0">
                <mat-icon>search_off</mat-icon>No se encontraron datos para
                mostrar.
              </span>
                        </div>

                        <strong>
              <h3>Periodos</h3>
            </strong>
                        <table mat-table [dataSource]="settlement.periodosContrato" *ngIf="settlement.periodosContrato.length != 0" class="mat-elevation-z8">
                            <ng-container matColumnDef="periodo">
                                <th mat-header-cell *matHeaderCellDef>Periodo</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.periodoMes }}/{{ element.periodoAnio }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef><strong>Total</strong></td>
                            </ng-container>
                            <ng-container matColumnDef="importeAlquiler">
                                <th mat-header-cell *matHeaderCellDef>Importe Alquiler</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.importeAlquiler | currency }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalPeriodosContrato(settlement.periodosContrato) | currency }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="importeComision">
                                <th mat-header-cell *matHeaderCellDef>Importe Comisión</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.comisionInmobiliara | currency }} ( {{ isFalsy(contratos.contrato) ? "" : contratos.contrato.porcentajeComisionPropietario }}%)
                                </td>
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalImporteComisionPeriodo(settlement.periodosContrato) | currency }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="importePunitorio">
                                <th mat-header-cell *matHeaderCellDef>Importe Punitorio</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.importePunitorio | currency }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalImporPunitorio(settlement.periodosContrato) | currency }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="importeTotal">
                                <th mat-header-cell *matHeaderCellDef>Importe Total</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.importeAlquiler + element.importePunitorio - element.comisionInmobiliara | currency }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalImporte(settlement.periodosContrato) | currency }}
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsPeriodos"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsPeriodos"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumnsPeriodos; sticky: true"></tr>
                        </table>
                        <div class="no-data-table">
                            <span class="with-icon" *ngIf="settlement.periodosContrato.length == 0">
                <mat-icon>search_off</mat-icon>No se encontraron datos para
                mostrar.
              </span>
                        </div>

                        <strong>
              <h3>Movimientos</h3>
            </strong>
                        <table mat-table [dataSource]="settlement.movimientos" *ngIf="settlement.movimientos.length != 0" class="mat-elevation-z8">
                            <ng-container matColumnDef="mov_periodo">
                                <th mat-header-cell *matHeaderCellDef>Periodo</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.periodoMes }}/{{ element.periodoAnio }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef>Total</td>
                            </ng-container>
                            <ng-container matColumnDef="mov_tipoMovimiento">
                                <th mat-header-cell *matHeaderCellDef>Tipo Movimiento</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ isFalsy(element.tipoMovimiento) ? "" : (element.tipoMovimiento.descripcion | titlecase) }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
                            <ng-container matColumnDef="mov_descripcion">
                                <th mat-header-cell *matHeaderCellDef>Descripción</th>
                                <td mat-cell *matCellDef="let element">
                                    {{isFalsy(element.descripcion) ? "" : element.descripcion }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>
                            <ng-container matColumnDef="mov_importe">
                                <th mat-header-cell *matHeaderCellDef>Importe</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.importe | currency }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ getTotalMovimientos(settlement.movimientos) | currency }}
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsMovimientos"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsMovimientos"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumnsMovimientos; sticky: true"></tr>
                        </table>
                        <div class="no-data-table">
                            <span class="with-icon" *ngIf="settlement.movimientos.length == 0">
                <mat-icon>search_off</mat-icon>No se encontraron datos para
                mostrar.
              </span>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div *ngIf="settlementByPropertyDto.length == 0">
        <div class="no-data-table">
            <span class="with-icon">
        <mat-icon>search_off</mat-icon>No se encontraron datos para
        mostrar.
      </span>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <div *ngIf="contratoDetailDto != null">
        <button mat-raised-button *appRole="['Contratos.GeneraLiquidacion']" [disabled]="esLiquidacionGenerada" (click)="agregarLiquidacion()" color="primary">
      Generar Liquidacion
    </button>
    </div>

</div>