import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { TiposFechaValidaciones } from "@models/base/identificadores.model";
import moment from "moment";

/**
 * *Valida si la fecha enviada es anterior,posterior o igual a Hoy.
 *
 * @param nombreControl  String
 * @param tipoFecha TiposFechaValidaciones
 * @returns FormGroup
 */
export function validarFechaAnteriorHoy(nombreControl: string, tipoFecha: TiposFechaValidaciones) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[nombreControl];

    let texto = control.value;
    let invalido: boolean = false;
    let aux: Date = new Date(texto);

    let fechaSeleccionada: Date = new Date(aux.getFullYear(), aux.getMonth(), aux.getDay());

    if (TiposFechaValidaciones.Anterior == tipoFecha)
      invalido = (fechaSeleccionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay()));
    if (TiposFechaValidaciones.Posterior == tipoFecha)
      invalido = (fechaSeleccionada < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay()));
    if (TiposFechaValidaciones.Hoy == tipoFecha)
      invalido = (fechaSeleccionada == new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay()));

    if (invalido) {
      control.setErrors({ fechaInvalida: true });
    }
    else {
      control.setErrors(null);
    }
  }
}

/**
 * Valida si una fecha tiene el formato MMYYYY
 * @returns
 */
export function validarMMYYYY(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const fecha = control.value;
    let fechaInvalida = false
    if (!fecha) {
      return null;
    }
    if (!moment(fecha, 'MM/yyyy', true).isValid()) {
      fechaInvalida = true
    }
    return fechaInvalida ? { fechaInvalida: true } : null;
  }
}


export function validarFechaDiferenciaUnMes(periodoOrigen: string, periodoDestino: string) {
  return (formGroup: FormGroup) => {
    const controlPeriodoOrigen = formGroup.controls[periodoOrigen];
    const controlPeriodoDestino = formGroup.controls[periodoDestino];
    let invalido: boolean = false;
    let meses = 0
    if (controlPeriodoDestino.value != "" && controlPeriodoOrigen.value != "") {
      var stringPeriodoDestino = controlPeriodoDestino.value.split("/");
      var stringPeriodoOrigen = controlPeriodoOrigen.value.split("/");

      let fechaDestinoMes = stringPeriodoDestino[0].toString()
      let fechaDestinoAnio = stringPeriodoDestino[1].toString()

      let fechaOrigenMes = stringPeriodoOrigen[0].toString()
      let fechaOrigenAnio = stringPeriodoOrigen[1].toString()

      let fechaDestino = moment(new Date(fechaDestinoAnio.concat("/", fechaDestinoMes.concat("/", "01"))))
      let fechaOrigen = moment(new Date(fechaOrigenAnio.concat("/", fechaOrigenMes.concat("/", "01"))))

      meses = fechaDestino.diff(fechaOrigen, 'months');
    }

    if (meses > 1) {
      controlPeriodoDestino.setErrors({ fechaFueraRango: true });
    }
    else {
      controlPeriodoDestino.setErrors(null);
    }
  }
}

