import { AutoMap } from "@automapper/classes";
import { DepartamentoDto } from "../usuarios/departamentoDto";

export class LocalidadDto {

  @AutoMap() public id : number=0;
  @AutoMap() public categoria : string="";
  @AutoMap() public centroideLon : number=0;
  @AutoMap() public centroideLat : number=0;
  @AutoMap(() => DepartamentoDto)  public departamento : DepartamentoDto =  new DepartamentoDto;
  @AutoMap() public fuente : string="";
  @AutoMap() public municipio : string="";
  @AutoMap() public nombreCompleto : string="";
  @AutoMap() public descripcion : string="";

    static fromLocalidadDto(localidadDto: LocalidadDto) {
      const dto = new LocalidadDto();
      dto.id = localidadDto.id;
      dto.descripcion = localidadDto.descripcion;
      dto.categoria = localidadDto.categoria;
      dto.centroideLon = localidadDto.centroideLon;
      dto.centroideLat = localidadDto.centroideLat;
      dto.fuente = localidadDto.fuente;
      dto.municipio = localidadDto.municipio;
      dto.nombreCompleto = localidadDto.nombreCompleto;
      dto.descripcion = localidadDto.descripcion;
      dto.departamento = DepartamentoDto.fromDepartamentoDto(localidadDto.departamento);
      return dto;
    }

}
