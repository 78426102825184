export class CreatePropietarioRequest {

    public id_Persona: number = 0;
    public comision: number = 0;
    public id_Banco: number|null = 0 ;
    public id_TipoLiquidacion: number|null = 0;
    public cbu: string = "";
    public alias: string = "";
    public nroCuenta: string = "";
    public cuit: string = "";
    public activo: boolean = false;
    public titular : boolean=false;

}
