<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-lg-12">
                <mat-form-field *ngIf="action != 'Eliminar'; else elseTemplate">
                    <input cdkFocusInitial formControlName="Descripcion" placeholder="{{action}} Descripcion" matInput [(ngModel)]="local_data.descripcion">
                    <mat-error *ngIf="f.Descripcion.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <ng-template #elseTemplate>
        ¿Esta seguro que desea eliminar el registro <b>{{local_data.descripcion}}</b>?
    </ng-template><br>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button *appRole="['Asuntos.Crear','Asuntos.Modificar','Asuntos.Eliminar']" color="primary" (click)="doAction()" [disabled]="!form.valid">{{action}}</button>
    <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
</div>
