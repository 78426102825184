import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-nuevo-propiedad',
  templateUrl: './btn-nuevo-propiedad.component.html',
  styleUrls: ['./btn-nuevo-propiedad.component.css']
})
export class BtnNuevoPropiedadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
