<div *appRole="['GruposGarantiasInquilino.Ver']">

  <button *appRole="['GruposGarantiasInquilino.Crear']" mat-raised-button (click)="openDialog('Agregar',{})"
    color="primary">
    <mat-icon>add</mat-icon>
    Grupos de Garantias por Inquilino
  </button>

  <br><br>
  <mat-card *appRole="['GruposGarantiasInquilino.Ver']">
    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <mat-form-field class="example-full-width">
      <mat-label>Buscar</mat-label>
      <input matInput type="search" (keyup)="applyFilter($event)">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <br>
    <table mat-table [dataSource]="dataSource" [hidden]=!data table matSort matSortStart="asc" class="mat-elevation-z8">

      <!-- Inquilino Column -->
      <ng-container matColumnDef="inquilino">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Inquilino </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element"><span>
          {{isFalsy(element.inquilino) ? "" :
            isFalsy(element.inquilino.persona) ? "" : element.inquilino.persona.descripcion | titlecase}}
            <button mat-icon-button matTooltip="Editar persona" (click)="editarPersona(element.inquilino.persona)" color="primary">
            <mat-icon>rate_review</mat-icon>
          </button></span>
        </mat-cell>
      </ng-container>

      <!-- Tipo Garantia Column -->
      <ng-container matColumnDef="grupoTipoGarantia">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Grupo Tipo Garantia </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <div *ngIf="element.gruposTipoGarantia.length > 0">
            <mat-list class="matList" *ngFor="let grupo of element.gruposTipoGarantia;let i=index;trackBy:trackByItems">
              <mat-list-item class="matList"> <span>{{isFalsy(grupo.cantidadTipoGarantia) ? "" :
                grupo.cantidadTipoGarantia.cantidad }} {{isFalsy(grupo.cantidadTipoGarantia) ? "" :
                grupo.cantidadTipoGarantia.tipoGarantia.descripcion | titlecase }}</span> </mat-list-item>
            </mat-list>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Descripcion Column -->
      <ng-container matColumnDef="idGrupo">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Grupo </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <div *ngIf="element.gruposTipoGarantia.length > 0">
            <mat-list class="matList" *ngFor="let grupo of element.gruposTipoGarantia;let i=index;trackBy:trackByItems">
              <mat-list-item class="matList"> <span>Grupo N° {{grupo.idGrupo}}</span> </mat-list-item>
            </mat-list>
          </div>
        </mat-cell>
      </ng-container>



      <!-- Accion Column -->
      <ng-container matColumnDef="accion">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element" class="action-link">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                      <mat-icon>visibility</mat-icon>
                      <span>Ver</span>
                    </button> -->
            <button *appRole="['GruposGarantiasInquilino.Modificar']" mat-menu-item
              (click)="openDialog('Modificar',element)">
              <mat-icon>edit</mat-icon>
              <span>Editar grupo</span>
            </button>
            <button *appRole="['GruposGarantiasInquilino.Eliminar']" mat-menu-item
              (click)=" openDialog( 'Eliminar',element)">
              <mat-icon>delete</mat-icon>
              <span>Eliminar grupo</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>



      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </table>
    <div class="no-data-table">
      <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos
        para mostrar.</span>
    </div>
    <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20,50,100,200]">
    </mat-paginator>
  </mat-card>

</div>
