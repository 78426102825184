import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateKeyValueRequest } from '@models/base/createKeyValueRequest.model';
import { UpdateKeyValueRequest } from '@models/base/updateKeyValueRequest.model';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class TiposComprobantesService {

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient
  ) { }

  create(createKeyValueRequest: CreateKeyValueRequest): Observable<any> {
    return this.http.post<any>("/api/TipoComprobante/Create", createKeyValueRequest);
  }

  update(updateKeyValueRequest: UpdateKeyValueRequest): Observable<any> {
    return this.http.put<any>("/api/TipoComprobante/Update", updateKeyValueRequest);
  }

  getAll(): Observable<BaseDto[]> {
    return this.http.get<BaseDto[]>("/api/TipoComprobante/GetAll");
  }

  getById(id: number): Observable<BaseDto> {
    return this.http.get<BaseDto>("/api/TipoComprobante/GetById?id=" + id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/TipoComprobante/Delete?id=" + id);
    else
      return of(null)
  }
}
