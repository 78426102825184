import { AutoMap } from "@automapper/classes";
import { PersonaDetailDto } from "./personaDetailDto.model";

export class UpdatePersonaRequest {

  @AutoMap() public id : number=0;
  @AutoMap() public nombreCompleto : string="";
  @AutoMap() public apellidoMaterno : string="";
  @AutoMap() public id_TipoDocumento : number | null = null;
  @AutoMap() public nroDocumento : string="";
  @AutoMap() public telefonoPrincial : string="";
  @AutoMap() public telefonoAlternativo : string="";
  @AutoMap() public sexo : string="";
  @AutoMap() public correo : string="";
  @AutoMap() public fechaNacimiento  : string="" ;
  @AutoMap() public ubicacion : string="";
  @AutoMap() public id_Localidad : number | null =null;
  @AutoMap() public id_Provincia : number | null =null;
  @AutoMap() public codigoCpa : string="";
  @AutoMap() public id_EstadoCivil : number | null =null;
  @AutoMap() public ocupacion : string="";
  @AutoMap() public estado : boolean= false;
  @AutoMap() public observacion : string="";
  @AutoMap() public idPais : number| null  = null;
  @AutoMap() public telefonoLaboral : string="";
  @AutoMap() public ubicacionLaboral : string="";

static fromPersonaDetailDto(personaDetailDto: PersonaDetailDto) {
  const dto = new UpdatePersonaRequest()
  dto.id = personaDetailDto.id
  dto.nombreCompleto= personaDetailDto.nombreCompleto
  dto.apellidoMaterno = personaDetailDto.apellidoMaterno
  dto.id_TipoDocumento = personaDetailDto.tipoDocumento.id
  dto.nroDocumento = personaDetailDto.nroDocumento
  dto.telefonoPrincial = personaDetailDto.telefonoPrincial
  dto.telefonoAlternativo = personaDetailDto.telefonoAlternativo
  dto.sexo =personaDetailDto.sexo
  dto.correo = personaDetailDto.correo
  dto.fechaNacimiento =personaDetailDto.fechaNacimiento
  dto.ubicacion = personaDetailDto.ubicacion
  dto.id_Localidad = personaDetailDto.localidad ? personaDetailDto.localidad.id : null
  dto.id_Provincia = personaDetailDto.provincia ? personaDetailDto.localidad.departamento ? personaDetailDto.localidad.departamento.provincia.id :  null : null
  dto.codigoCpa = personaDetailDto.codigoCpa
  dto.id_EstadoCivil = personaDetailDto.estadoCivil ? personaDetailDto.estadoCivil.id : null
  dto.ocupacion = personaDetailDto.ocupacion
  dto.estado= personaDetailDto.estado
  dto.observacion= personaDetailDto.observacion
  dto.telefonoLaboral= personaDetailDto.ubicacion
  dto.ubicacionLaboral= personaDetailDto.ubicacionLaboral
  dto.idPais= personaDetailDto.pais ? personaDetailDto.pais.id : null

  return dto;
}

}
