import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { LoginService } from '@services/login/login.service';
import { UsuariosService } from '@services/usuarios/usuarios.service';

@Component({
  selector: 'app-garantias',
  templateUrl: './garantias.component.html',
  styleUrls: ['./garantias.component.css']
})
export class GarantiasComponent implements OnInit {
  idPersona!: number;
  usuarioDto!: UsuarioDto;

  constructor(
    private aRoute: ActivatedRoute,
    private loginService:LoginService,
    private userService:UsuariosService
  ) { }

  ngOnInit(): void {
    const user = this.loginService.getCurrentUser()
    this.idPersona = user.persona.id
  }

}
