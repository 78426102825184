import { Component } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AccionesDialog } from "@models/base/identificadores.model";
import { DialogNuevoMovimientoCajaComponent } from "@views/caja/dialog-nuevo-movimiento-caja/dialog-nuevo-movimiento-caja.component";


@Component({
  selector: 'app-btn-create-movimiento-caja',
  templateUrl: './btn-create-movimiento-caja.component.html',
  styleUrls: ['./btn-create-movimiento-caja.component.css'],
})
export class BtnCreateMovimientoCajaComponent {

  constructor(
    public dialog: MatDialog,
  ) {

  }
  openDialogNuevoMovimientoCaja() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "100%"
    dialogConfig.maxWidth = "95%";
    dialogConfig.data = { event: AccionesDialog.Agregar }
    dialogConfig.autoFocus = false
    dialogConfig.disableClose = true

    const dialogRef = this.dialog.open(DialogNuevoMovimientoCajaComponent,
      dialogConfig);
  }
 }
