import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { BaseDto } from '@models/base/baseDto.model';
import { TiposFolios, TiposGarantia } from '@models/base/identificadores.model';
import { GarantiaDto } from '@models/garantia/garantiaDto.model';
import { UpdateGarantiaRequest } from '@models/garantia/updateGarantiaRequest.model';
import { TipoDocumentoDto } from '@models/tipo-documento/tipoDocumentoDto.model';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { GarantiasService } from '@services/garantias/garantias.service';
import { LoginService } from '@services/login/login.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposDocumentoService } from '@services/tipos-documento/tipos-documento.service';
import { TiposFolioService } from '@services/tipos-folio/tipos-folio.service';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormOnlyPersonaUpdateComponent } from '@views/personas/form-only-persona-update/form-only-persona-update.component';
import { DialogFormOnlyPersonaUpdateComponent } from '@views/personas/dialog-form-only-persona-update/dialog-form-only-persona-update.component';

@Component({
  selector: 'app-form-garantia-view-public',
  templateUrl: './form-garantia-view-public.component.html',
  styleUrls: ['./form-garantia-view-public.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGarantiaViewPublicComponent implements OnInit {

  lstTiposGarantiaModel: BaseDto[] = []
  lstTiposFolios: BaseDto[] = []
  titulo: string = '';
  subMenuActive: boolean = false;
  stringObject: any;
  stringJson: any;
  lstTipoDocumentoDto: TipoDocumentoDto[] = [];
  submitted = false;
  parametro: string = '';
  idTipoDocumento: number = 0;
  idPersona!: number;
  valor: any
  errorMessage: string = "";
  accordionList: any;
  formBuilder: FormBuilder = new FormBuilder;
  matDialogClose!: boolean;
  mostrarPersona!: boolean;
  mostrarPropiedad!: boolean;
  mostrarFianza!: boolean;
  formTipoGarantia: FormGroup;
  formPersonaGarantia: FormGroup;
  formPropiedad: FormGroup;
  idTipoGarantia: any;
  tipoGarantiaSelect: number = 0;
  updateGarantiaRequest = new UpdateGarantiaRequest();
  formFianza: FormGroup;
  idTipoFolio!: number;
  archivoDto!: ArchivoDto;
  archivoDtoDNIDorso!: ArchivoDto;
  archivoDtoDNIFrente!: ArchivoDto;
  garantia!: GarantiaDto;
  archivosFrenteCorrectos: boolean = false
  archivosDorsoCorrectos: boolean = false
  indivisa: number = 100
  garantiaDto!: GarantiaDto;
  idGarantia!: number;
  personaDetailDto!: PersonaDetailDto;
  user!: UsuarioDto;
  idPersonaInquilino!: number;

  get fPro() { return this.formPropiedad.controls; }
  get fPer() { return this.formPersonaGarantia.controls; }
  get fG() { return this.formTipoGarantia.controls; }
  get fTL() { return this.formFianza.controls; }


  constructor(
    public dialog: MatDialog,
    private personasService: PersonasService,
    private tiposFoliosServices: TiposFolioService,
    private tipoDocumentoService: TiposDocumentoService,
    private garantiasService: GarantiasService,
    private router: Router,
    private loginService: LoginService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private aRoute: ActivatedRoute,
  ) {

    this.formTipoGarantia = this.formBuilder.group({
      TipoGarantia: [{ value: '', disabled: true }],
    });

    this.formPersonaGarantia = this.formBuilder.group({
      Sueldo: [{ value: "", disabled: true }],
      CuitCuil: [
        { value: '', disabled: true },
        Validators.compose([
          Validators.pattern(/^([0-9]{11}|[0-9]{2}-[0-9]{8}-[0-9]{1})$/)
        ])
      ],
      Empleador: [
        { value: '', disabled: true },
        Validators.compose([Validators.maxLength(200), Validators.required])
      ],
    });

    this.formFianza = this.formBuilder.group({
      Cuit: [
        { value: '', disabled: true },
        Validators.compose([Validators.pattern(/^[0-9]{2}-[0-9]{8}-[0-9]$/)])
      ],
      RazonSocial: [
        { value: '', disabled: true },
        Validators.compose([Validators.maxLength(200), Validators.required])
      ],
    });

    this.formPropiedad = this.formBuilder.group({
      TipoFolio: [
        { value: '', disabled: true },
        Validators.compose([Validators.required])
      ],
      Tomo: [
        { value: '', disabled: true },
        Validators.compose([Validators.maxLength(20)])
      ],
      NroFolio: [
        { value: '', disabled: true },
        Validators.compose([Validators.maxLength(20)])
      ],
      Numero: [
        { value: '', disabled: true },
        Validators.compose([Validators.maxLength(20)])
      ],
      Indivisa: [{ value: 100, disabled: true }],
      Ubicacion: [
        { value: '', disabled: true },
        Validators.compose([Validators.maxLength(100)])
      ],
      Localidad: [
        { value: '', disabled: true },
        Validators.compose([Validators.maxLength(100)])
      ],
    });
  }


  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser()
    this.idPersonaInquilino = this.user.persona.id
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idGarantia = Number(routeParams.get('id'));

    this.garantiasService.datos$.subscribe((datos) => {
      this.idGarantia = datos.id; // Recibir el objeto
    });

    this.getPersonasById(this.idPersonaInquilino, false, false)
    //this.getAllTipoDocumento();
    this.getAllTiposGarantia();
    this.getAllTiposFolio()
    this.getGarantiaById(this.idGarantia)
    this.formTipoGarantia.controls["TipoGarantia"].disable()

  }

  getPersonasById(idPersona: number, nuevoEnlace: boolean, verDocumentos: boolean) {
    this.spinner.show("spInquilino")
    this.personasService.getPersonaById(idPersona).subscribe(
      data => {

        this.spinner.hide("spInquilino")
        this.personaDetailDto = data
        this.personasService.setPersona(data)

         if (nuevoEnlace)
           this.openDialog(data)
         if (verDocumentos)
           this.router.navigate(["/garantias/personaDocumentos"])
      },
      error => {
        this.spinner.hide("spInquilino")
        this.snackBar.showError(error, "Error");
      });
  }


  openDialog(data:PersonaDetailDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = data
    dialogConfig.width = "90%"
    dialogConfig.height = "90%"

    const dialogRef = this.dialog.open(DialogFormOnlyPersonaUpdateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getGarantiaById(idGarantia: number) {
    this.spinner.show("spGarante")
    this.garantiasService.getGarantiaById(idGarantia)
      .subscribe(
        async data => {
          this.spinner.hide("spGarante")
          this.garantiaDto = data
          await this.setGarantiaSummaryDto(this.garantiaDto)
        },
        error => {
          this.spinner.hide("spGarante")
          this.snackBar.showError(error, "Error");
        })
  }

  setGarantiaSummaryDto(garantia: GarantiaDto) {
    this.garantiaDto = garantia
    switch (garantia.tipoGarantia.id) {
      case TiposGarantia.ReciboSueldo: {
        this.formTipoGarantia.controls['TipoGarantia'].setValue(garantia.tipoGarantia.id)
        this.formPersonaGarantia.controls['Sueldo'].setValue(garantia.sueldo)
        this.formPersonaGarantia.controls['CuitCuil'].setValue(garantia.cuitCuil)
        this.formPersonaGarantia.controls['Empleador'].setValue(garantia.razonSocial)
        this.idTipoGarantia = garantia.tipoGarantia.id
        this.idTipoFolio = TiposFolios.SinFolio
        this.mostrarPersona = true;
        this.mostrarFianza = false
        this.mostrarPropiedad = false;

        break;
      }
      case TiposGarantia.Propiedad: {
        this.formTipoGarantia.controls['TipoGarantia'].setValue(garantia.tipoGarantia.id)
        this.formPropiedad.controls['NroFolio'].setValue(garantia.nroFolio)
        this.formPropiedad.controls['Localidad'].setValue(garantia.localidad)
        this.formPropiedad.controls['Ubicacion'].setValue(garantia.ubicacion)
        this.formPropiedad.controls['Indivisa'].setValue(garantia.indivisa)
        this.indivisa = garantia.indivisa
        this.formPropiedad.controls['Numero'].setValue(garantia.numero)
        this.formPropiedad.controls['Tomo'].setValue(garantia.tomo)
        this.formPropiedad.controls['TipoFolio'].setValue(garantia.idTipoFolio)
        this.idTipoFolio = garantia.idTipoFolio
        this.idTipoGarantia = garantia.tipoGarantia.id
        this.mostrarPropiedad = true;
        this.mostrarPersona = false;
        this.mostrarFianza = false
        break;
      }
      case TiposGarantia.Fianza: {
        this.formTipoGarantia.controls['TipoGarantia'].setValue(garantia.tipoGarantia.id)
        this.formFianza.controls['Cuit'].setValue(garantia.cuitCuil)
        this.formFianza.controls['RazonSocial'].setValue(garantia.razonSocial)
        this.idTipoGarantia = garantia.tipoGarantia.id
        this.idTipoFolio = TiposFolios.SinFolio
        this.mostrarFianza = true
        this.mostrarPersona = false;
        this.mostrarPropiedad = false;
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  getAllTiposGarantia() {
    this.spinner.show()
    this.garantiasService.getAllTiposGarantia()
      .subscribe(
        data => {
          this.spinner.hide()
          this.lstTiposGarantiaModel = data
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        });
  }

  getAllTiposFolio() {
    this.spinner.show("spDtoGta")
    this.tiposFoliosServices.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spDtoGta")
          this.lstTiposFolios = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide("spDtoGta")
          this.snackBar.showError(error, "Error");
        });
  }

  selectTipoGarantia(event: any) {
    this.idTipoGarantia = event;
    //ReciboSueldo
    if (event == TiposGarantia.ReciboSueldo) {
      this.mostrarPersona = true
      this.mostrarPropiedad = false
      this.mostrarFianza = false
      this.formPropiedad.reset();
      this.formFianza.reset();
    }
    //Propiedad
    if (event == TiposGarantia.Propiedad) {
      this.getAllTiposFolio()
      this.mostrarPersona = false
      this.mostrarPropiedad = true
      this.mostrarFianza = false
      this.formPersonaGarantia.reset();
      this.formFianza.reset();
    }
    //Fianza
    if (event == TiposGarantia.Fianza) {
      this.mostrarPersona = false
      this.mostrarPropiedad = false
      this.mostrarFianza = true
      this.formPersonaGarantia.reset();
      this.formPropiedad.reset();
    }
  }


  selectTipoFolio(event: any) {
    this.idTipoFolio = event;
    switch (this.idTipoFolio) {
      case TiposFolios.FolioPersonalCronologico:
        this.formPropiedad.controls['NroFolio'].setValidators([Validators.required]);
        break;
      case TiposFolios.FolioReal:
        this.formPropiedad.controls['NroFolio'].setValidators([Validators.required]);
        break;
      case TiposFolios.SinFolio:
          this.formPropiedad.controls['NroFolio'].clearValidators();
          break;
      default:
        break;
    }
  }

  getAllTipoDocumento() {
    this.spinner.show()
    this.tipoDocumentoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.lstTipoDocumentoDto = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        });
  }

  onSubmit() {
    this.setupdateGarantiaRequest();
    this.updateGarantia(this.updateGarantiaRequest)
  }

  updateGarantia(updateGarantiaRequest: UpdateGarantiaRequest) {
    this.spinner.show()
    this.garantiasService.update(updateGarantiaRequest)
      .subscribe(
        async data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Se actualizo garantia de manera exitosa.", "Exito");
          this.router.navigate(["/garantias/misGarantias"])
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        },
      )
  }

  setupdateGarantiaRequest() {
    this.updateGarantiaRequest = new UpdateGarantiaRequest();
    this.updateGarantiaRequest.id = this.garantiaDto.id
    this.updateGarantiaRequest.id_Persona = this.garantiaDto.persona.id
    //Tipo Garantia
    this.updateGarantiaRequest.id_TipoGrantia = this.formTipoGarantia.controls['TipoGarantia'].value
    if (this.updateGarantiaRequest.id_TipoGrantia == TiposGarantia.ReciboSueldo) {
      //Tipo Persona
      this.updateGarantiaRequest.cuitCuil = this.formPersonaGarantia.controls['CuitCuil'].value
      this.updateGarantiaRequest.sueldo = this.formPersonaGarantia.controls['Sueldo'].value
      this.updateGarantiaRequest.razonSocial = this.formPersonaGarantia.controls['Empleador'].value
      this.updateGarantiaRequest.idTipoFolio = TiposFolios.SinFolio
    }
    if (this.updateGarantiaRequest.id_TipoGrantia == TiposGarantia.Propiedad) {
      //Tipo Propiedad
      this.updateGarantiaRequest.idTipoFolio = this.formPropiedad.controls['TipoFolio'].value
      this.updateGarantiaRequest.nroFolio = this.formPropiedad.controls['NroFolio'].value
      if (this.updateGarantiaRequest.idTipoFolio == TiposFolios.FolioPersonalCronologico) {
        if (this.updateGarantiaRequest.tomo != "")
          this.updateGarantiaRequest.tomo = this.formPropiedad.controls['Tomo'].value
        else {
          this.updateGarantiaRequest.tomo = "0"
        }

        if (this.updateGarantiaRequest.numero != "")
          this.updateGarantiaRequest.numero = this.formPropiedad.controls['Numero'].value
        else {
          this.updateGarantiaRequest.numero = "0"
        }

      }
      else {
        this.updateGarantiaRequest.tomo = ""
        this.updateGarantiaRequest.numero = ""
      }

      this.updateGarantiaRequest.indivisa = this.formPropiedad.controls['Indivisa'].value
      this.updateGarantiaRequest.ubicacion = this.formPropiedad.controls['Ubicacion'].value
      this.updateGarantiaRequest.localidad = this.formPropiedad.controls['Localidad'].value
    }
    if (this.updateGarantiaRequest.id_TipoGrantia == TiposGarantia.Fianza) {
      //Tipo Fianza
      this.updateGarantiaRequest.razonSocial = this.formFianza.controls['RazonSocial'].value
      this.updateGarantiaRequest.cuitCuil = this.formFianza.controls['Cuit'].value
      this.updateGarantiaRequest.idTipoFolio = TiposFolios.SinFolio
    }

  }

  onReset() {
    this.formPersonaGarantia.reset();
    this.formTipoGarantia.reset();
    this.formPropiedad.reset();
    this.formFianza.reset();
    this.formTipoGarantia.controls["TipoGarantia"].setValue(0)
    this.formTipoGarantia.controls["TipoFolio"].setValue(0)
    this.mostrarPersona = false
    this.mostrarPropiedad = false
  }

  //Validaciones
  validaLargoNumerico(control: FormControl) {
    if (control.value != null && control.value != "") {
      if (<number>control.value > 999999999999) {
        return { validaLargoNumerico: true }
      }
      else
        return { validaLargoNumerico: false }
    }
    else
      return { validaLargoNumerico: false }
  }

  //Fin Validaciones

  editaPersonaGarante(idPersona: number) {
    this.getPersonasById(idPersona, true, false)
  }

  editaDocumentosGarante(idPersona: number) {
    this.getPersonasById(idPersona, false, true)
  }

  trackByItems(index: number, item: any): any { return item; }

}
