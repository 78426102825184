import { BreakpointObserver } from '@angular/cdk/layout';
import { formatDate } from '@angular/common';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { IndiceAjusteContratoDto } from '@models/indice-ajuste-contrato/indiceAjusteContratoDto.model';
import { TemplateDto } from '@models/template/templateDto.model';
import { TipoAjusteContratoDto } from '@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoAjusteContratoService } from '@services/tipo-ajuste-contrato/tipoAjusteContrato.service';
import { Editor, Toolbar } from 'ngx-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-template',
  templateUrl: './dialog-template.component.html',
  styleUrls: ['./dialog-template.component.css']
})
export class DialogTemplateComponent implements OnInit {

  action: string;
  local_data: any;
  form: FormGroup;
  editorHtml!: Editor
  formBuilder: FormBuilder = new FormBuilder;
  lstTiposAjusteContrato!: TipoAjusteContratoDto[];
  toolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  get f() { return this.form.controls; }
  constructor(
    public dialogRef: MatDialogRef<DialogTemplateComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TemplateDto) {
    this.local_data = { ...data };
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      Nombre: [null, Validators.compose([Validators.required,])],
      Descripcion: [null, Validators.compose([Validators.required,])],
      Contenido: [null, Validators.compose([Validators.required,])],
    })

    if (this.action == AccionesDialog.Modificar) {
      this.form.controls['Nombre'].disable();
    }


  }

  doAction() {
    this.local_data.nombre = this.form.controls['Nombre'].value
    this.local_data.descripcion = this.form.controls['Descripcion'].value
    this.local_data.contenido = this.form.controls['Contenido'].value
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  ngOnInit(): void {
    this.editorHtml = new Editor();

    this.form.controls['Nombre'].setValue(this.data.nombre);
    this.form.controls['Descripcion'].setValue(this.data.descripcion);
    this.form.controls['Contenido'].setValue(this.data.content);

  }

  ngOnDestroy(): void {
    this.editorHtml.destroy();
  }

  convierteFecha(fechaOrigen: any): string {
    const fecha = fechaOrigen.split('-');
    const anio = fecha[0];
    const mes = fecha[1];
    const dia = fecha[2].substring(0, 2);
    const fechaCompleta = anio + "-" + mes + "-" + dia
    return fechaCompleta
  }

  //#region Helper

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
  //#endregion

}
