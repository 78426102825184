import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, forkJoin } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { EstadoCivilDto } from '@models/estado-civil/estadoCivilDto.model';
import { CreatePersonaRequest } from '@models/personas/createPersonaRequest.model';
import { CreatePropietarioRequest } from '@models/propietarios/createPropietarioRequest.model';
import { TipoDocumentoDto } from '@models/tipo-documento/tipoDocumentoDto.model';
import { EstadosCivilService } from '@services/estados-civil/estados-civil.service';
import { PaisService } from '@services/pais/pais.service';
import { PersonasService } from '@services/personas/personas.service';
import { PropietariosService } from '@services/propietarios/propietarios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposDocumentoService } from '@services/tipos-documento/tipos-documento.service';
import { isFalsy } from 'utility-types';
import { DialogCreatePropietarioComponent } from '../dialog-create-propietario/dialog-create-propietario.component';

@Component({
  selector: 'app-form-persona-propietaria-create',
  templateUrl: './form-persona-propietaria-create.component.html',
  styleUrls: ['./form-persona-propietaria-create.component.css']
})
export class FormPersonaPropietariaCreateComponent implements OnInit {

  formBuilder: FormBuilder = new FormBuilder;
  idTipoLiquidacion!: number;
  lstTipoLiquidacionModel: BaseDto[] = [];
  comision: any
  personaForm!: FormGroup
  lstEstadosCivilModel: EstadoCivilDto[] = [];
  lstTipoDocumentoDto: TipoDocumentoDto[] = [];
  lstPaises: BaseDto[] = [];
  localidad!: BaseDto
  submitted = false;
  idTipoDocumento: number = 0;
  idPersona: number = 0;

  get f() { return this.personaForm.controls; }

  constructor(
    private personasService: PersonasService,
    private tipoDocumentoService: TiposDocumentoService,
    private estadoCivilService: EstadosCivilService,
    private paisService: PaisService,
    private router: Router,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private propietariosService: PropietariosService,
    public dialogRef: MatDialogRef<DialogCreatePropietarioComponent>,
  ) {

    this.personaForm = this.formBuilder.group({
      //Persona
      NombreCompleto: ["", Validators.compose([Validators.required, Validators.maxLength(50)])],
      NroDocumento: ['',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
        this.validaPersonaByNroDocYTipoDoc.bind(this)],
      TelefonoPrincial: ['', Validators.compose([Validators.maxLength(20)])],
      Sexo: ['', Validators.compose([Validators.required])],
      Correo: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(50)])],
      Ubicacion: ['', Validators.compose([Validators.maxLength(50)])],
      EstadoCivil: ['', Validators.compose([Validators.required])],
      TiposDocumento: ['', Validators.compose([Validators.required])],
      Comision: ['', Validators.compose([Validators.required])],
      TipoLiquidacion: ['', Validators.compose([Validators.required])],
      CuitCuil: ['', Validators.compose([Validators.required,,Validators.pattern(/^([0-9]{11}|[0-9]{2}-[0-9]{8}-[0-9]{1})$/)])],
    },
      {
      });


  }

  ngOnInit(): void {
    this.spinner.show("spPersonaCombo")
    forkJoin({
      paises: this.paisService.getAll(),
      estadosCivil: this.estadoCivilService.getAll(),
      tiposDocumentos: this.tipoDocumentoService.getAll(),
      lstTipoLiquidacionModel: this.propietariosService.getAllTiposLiquidacion()
    })
      .subscribe(
        ({ paises, estadosCivil, tiposDocumentos, lstTipoLiquidacionModel }) => {

          this.lstPaises = paises;
          this.lstEstadosCivilModel = estadosCivil
          this.lstTipoDocumentoDto = tiposDocumentos
          this.lstTipoLiquidacionModel = lstTipoLiquidacionModel
          this.spinner.hide("spPersonaCombo")
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spPersonaCombo")
        });
  }


  selectTipoDocumento(event: any) {
    this.idTipoDocumento = event
    if (this.idTipoDocumento != 0) {
      this.personaForm.controls["NroDocumento"].enable();
      this.personaForm.controls["NroDocumento"].setValue("");
    }
    else
      this.personaForm.controls["NroDocumento"].disable();
  }

  seleccionaLocalidad(data: BaseDto) {
    this.localidad = data
  }

  onSubmit() {
    this.submitted = true;
    if (this.personaForm.invalid) {
      return;
    }
    const createPersonaRequest: CreatePersonaRequest = this.setPersonaDetailDto();
    this.spinner.show("spPersona")
    this.personasService.addPersona(createPersonaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.idPersona = data.idPersona
          this.snackBar.showSuccess(this.personaForm.value['NombreCompleto'] +
            ' se registro correctamente.', "");
          var createPropietarioRequest = this.setCreatePropietarioRequest(this.idPersona)
          this.createPropietario(createPropietarioRequest);
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        })
  }


  setPersonaDetailDto(): CreatePersonaRequest {

    const createPersonaRequest = new CreatePersonaRequest();

    createPersonaRequest.nombreCompleto = this.personaForm.controls['NombreCompleto'].value;
    createPersonaRequest.apellidoMaterno = "";
    createPersonaRequest.nroDocumento = this.personaForm.controls['NroDocumento'].value;
    createPersonaRequest.id_TipoDocumento = this.personaForm.controls['TiposDocumento'].value;
    createPersonaRequest.id_EstadoCivil = this.personaForm.controls['EstadoCivil'].value;
    createPersonaRequest.telefonoPrincial = this.personaForm.controls['TelefonoPrincial'].value
    createPersonaRequest.telefonoAlternativo = "";
    createPersonaRequest.telefonoLaboral = "";
    createPersonaRequest.sexo = this.personaForm.controls['Sexo'].value;
    createPersonaRequest.correo = this.personaForm.controls['Correo'].value;
    createPersonaRequest.fechaNacimiento = "";;
    createPersonaRequest.ubicacion = this.personaForm.controls['Ubicacion'].value;
    createPersonaRequest.ubicacionLaboral = "";
    createPersonaRequest.idPais = 11;
    createPersonaRequest.id_Localidad = this.localidad.id
    createPersonaRequest.codigoCpa = "";
    createPersonaRequest.observacion = "";
    createPersonaRequest.ocupacion = "";
    createPersonaRequest.estado = false;

    return createPersonaRequest
  }

  onReset() {
    this.submitted = false;
    this.personaForm.reset();
  }

  validaPersonaByNroDocYTipoDoc(controlNroDoc: FormControl) {
    let idTipoDocumento = this.personaForm.controls['TiposDocumento'].value;
    if (controlNroDoc.value.toString().length > 7  && !isFalsy(idTipoDocumento) ) {
      let promesa = new Promise((resolve, reject) => {
        this.spinner.show("spNroDocumento")
        this.personasService.validaPersonaByNroDocYTipoDoc(this.idPersona, controlNroDoc.value, idTipoDocumento)
          .subscribe(data => {
            if (data == true) {
              this.spinner.hide("spNroDocumento")
              resolve({ existePersona: true })
            }
            else {
              this.spinner.hide("spNroDocumento")
              resolve(true)
            }
          })
      });
      //
      return promesa;
    }
    else {
      let promesa = new Promise((resolve, reject) => {
        resolve(true)
      });
      return promesa;
    }

  }

  selectTipoLiquidacion(event: any) {
    this.idTipoLiquidacion = event.value
  }


  setCreatePropietarioRequest(idPersona: number) {

    var createPropietarioRequest = new CreatePropietarioRequest();

    createPropietarioRequest.id_Persona = idPersona;
    if (this.idTipoLiquidacion > 0) {
      createPropietarioRequest.id_TipoLiquidacion = this.idTipoLiquidacion
    }
    else {
      createPropietarioRequest.id_TipoLiquidacion = null
    }

    createPropietarioRequest.comision = parseInt(this.personaForm.controls['Comision'].value)
    createPropietarioRequest.cuit = this.personaForm.controls['CuitCuil'].value
    createPropietarioRequest.activo = true
    createPropietarioRequest.id_Banco = null
    return createPropietarioRequest;
  }

  createPropietario(createPropietarioRequest: CreatePropietarioRequest) {
    this.spinner.show("spPersona")
    this.propietariosService.create(createPropietarioRequest)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.getPropietarioById(data.id)
          this.snackBar.showSuccess('Se creo el propietario de manera correcta.', "");
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        })
  }

  getPropietarioById(idPropietario:number){
    this.spinner.show("spPersona")
    this.propietariosService.getById(idPropietario)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.dialogRef.close({event:AccionesDialog.Aceptar,data:data});
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        })
  }

  isFalsy(valor:any){
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}


