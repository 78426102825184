<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="example-container" *ngIf="action != 'Eliminar'">
        <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-4">
                    <mat-form-field class="full-width">
                        <input cdkFocusInitial required placeholder="Descripción" formControlName="Descripcion" matInput>
                        <mat-error *ngIf="f.Descripcion.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field>
                        <input placeholder="Número" required matInput formControlName="Numero">
                        <mat-error *ngIf="f.Numero.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field>
                        <mat-select placeholder="Seleccione opcion" required formControlName="Tipo">
                            <mat-option [value]="cuenta" *ngFor="let cuenta of tiposCuentas ;let i=index;trackBy:trackByItems">
                                {{cuenta}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="f.Tipo.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>


            </div>
            <div class="row">
                <div class="col-4">
                    <app-buscar-banco (eventoBanco)="selectBanco($event)" [bancoEntrada]="banco"></app-buscar-banco>
                </div>
                <div class="col-4">
                    <mat-form-field>
                        <input placeholder="CBU" formControlName="CBU" type="text" mask="0000000000000000000000" matInput>
                        <mat-error *ngIf="f.CBU.errors?.maxlength">Maximo 22 caracteres.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field>
                        <input placeholder="Alias" formControlName="Alias" matInput>
                        <mat-error *ngIf="f.Alias.errors?.maxlength">Maximo 22 caracteres.</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <div class="example-container" *ngIf="action == 'Eliminar'">
        <b>¿Esta seguro que desea eliminar el registro?</b>
    </div>
    <br>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" *appRole="['CuentasContable.Crear','CuentasContable.Modificar','CuentasContable.Eliminar']" (click)="doAction()" [disabled]="!form.valid">{{action}}</button>
    <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancelar</button>
</div>
