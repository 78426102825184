<div class="row content" *appRole="['Empresas.Ver']">
    <div class="col-sm-2">
        <app-nav-panel-configuracion></app-nav-panel-configuracion>
    </div>
    <div class="col-sm-10">
        <mat-card>
            <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <form [formGroup]="formEmpresa">
                <div class="row">
                    <div class="col-md-3 mb-3">
                        <mat-form-field class="full-width">
                            <mat-label>Nombre</mat-label>
                            <input matInput type="text" maxlength="100" style="text-transform: capitalize;" formControlName="Nombre" />
                            <mat-error *ngIf="f.Nombre.errors?.required">Este campo es <strong>requerido</strong>.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 mb-3">
                        <mat-form-field class="full-width">
                            <mat-label>Ubicación</mat-label>
                            <input matInput type="text" maxlength="100" style="text-transform: capitalize;" formControlName="Ubicacion" />
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 mb-3">
                        <mat-form-field class="full-width">
                            <mat-label>Piso</mat-label>
                            <input matInput type="text" maxlength="10" formControlName="Piso" />
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 mb-3">
                        <mat-form-field class="full-width">
                            <mat-label>Departamento</mat-label>
                            <input matInput type="text" maxlength="10" formControlName="Departamento" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-3">
                        <mat-form-field class="full-width">
                            <ngx-spinner name="spCondicionContable" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                            <mat-label>Condicion Contable</mat-label>
                            <mat-select placeholder="Seleccione opcion" formControlName="TipoCondicionContable">
                                <mat-option [value]="condicionContable.id" *ngFor="let condicionContable of lstCondicionesContables;let i=index;trackBy:trackByItems ">
                                    {{condicionContable.descripcion}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="f.TipoCondicionContable.errors?.required">Este campo es <strong>requerido</strong>.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 mb-3">
                        <mat-form-field class="full-width">
                            <mat-label>Fecha inicio actividad</mat-label>
                            <input matInput type="date" maxlength="50" formControlName="FechaInicioActividad" />
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 mb-3">
                        <mat-form-field class="full-width">
                            <mat-label>CUIT</mat-label>
                            <input matInput type="text" maxlength="13" formControlName="CUIT" />
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 mb-3">
                        <mat-form-field class="full-width">
                            <mat-label>IIBB</mat-label>
                            <input matInput type="text" maxlength="50" formControlName="IIBB" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <app-buscar-localidad (eventoLocalidad)="seleccionaLocalidad($event)" [localidadBasicDto]="localidadBasicDto"></app-buscar-localidad>
                    </div>
                    <div class="col-md-3 mb-3">
                        <mat-form-field class="full-width">
                            <mat-label>Telefono</mat-label>

                            <input matInput type="tel" maxlength="50" formControlName="Telefono" />
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 mb-3">
                        <mat-form-field class="full-width">
                            <mat-label>Correo</mat-label>
                            <input matInput type="email" maxlength="50" formControlName="Correo" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3 mb-3">
                        <mat-form-field class="full-width">
                            <mat-label>Web</mat-label>
                            <input matInput type="text" formControlName="Web" />
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 mb-3">
                      <mat-form-field class="full-width">
                          <mat-label>Web App</mat-label>
                          <input matInput type="text" formControlName="WebApp" />
                      </mat-form-field>
                  </div>
                </div>
                <br>
                <mat-card>
                    <mat-card-header>
                        <b>Seleccione su logo</b>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-lg-6">
                                <app-file-upload (statusFileUpload)="changeStatusFileUpload($event)" (eventoImagen)="eventoImagen($event)" (eventoArchivo)="seleccionArchivo($event)"></app-file-upload>
                            </div>
                            <div class="col-lg-4">
                                <app-logo [archivoDto]="archivo"></app-logo>
                            </div>
                            <div class="col-lg-2">
                                <button mat-raised-button color="primary" *ngIf="statusFileUpload" style="margin-right:3px;" name="Guardar" (click)="guardarArchivo()"> <mat-icon>save </mat-icon> Guardar</button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

            </form>
            <div class="col-auto my-1">
                <button *appRole="['Empresas.Modificar']" mat-raised-button color="primary" style="margin-right:3px;" name="Guardar" [disabled]=" !formEmpresa.valid " (click)="onSubmit()">Guardar</button>
                <button mat-button color="warn" style="margin-right:3px;" name="Cancelar" [routerLink]="['/']">Cancelar</button>
            </div>
        </mat-card>
    </div>
</div>
